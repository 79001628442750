import { useEffect, useState } from "react";
import { AccountUserCrudDto } from "../../api/admin/dtos/AccountUserCrudDto";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminProviderAccountUserLinkedAccount } from "../../api/shared/hooks/useApiAdminProviders";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AccountUserEditForm from "./AccountUserEditPopup.Form";
import { useAccountUserCrudValidation } from "./Validations/useAccountUserCrudValidation";

const AccountUserDetailsPopup = (props: { id: string }) => {
  const [accountUserCrud, setAccountUserCrud] = useState<AccountUserCrudDto>();
  const [modal] = useModal();
  const [apiAdminProviderAccountUserLinkedAccount] = useApiAdminProviderAccountUserLinkedAccount();

  const save = async (accountUser: AccountUserCrudDto) => {
    const created = await apiAdminProviderAccountUserLinkedAccount.editUser(accountUser);
    modal.closeAsSuccess(created, "El usuario se editó con éxito");
  };

  useEffect(() => {
    const load = async () => {
      setAccountUserCrud(await apiAdminProviderAccountUserLinkedAccount.getToEdit(props.id));
      modal.setVisible("Editar usuario", ModalSize.small);
    };
    load();
  }, []);

  if (!accountUserCrud) {
    return <></>;
  }

  return (
    <Container>
      <StateProvider
        model={accountUserCrud}
        onSubmit={save}
        validationSchema={useAccountUserCrudValidation()}>
        {(p) => <AccountUserEditForm onSave={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default AccountUserDetailsPopup;
