import { useEffect, useState } from "react";
import { AdminTemplateDto } from "../../../api/admin/dtos/AdminTemplateDto";
import { AdminTemplateGenericSimpleDto } from "../../../api/admin/dtos/AdminTemplateGenericSimpleDto";
import { AppSize } from "../../AppSize";
import { useApiAdminAccountPublicTemplateGeneric } from "../../../api/shared/hooks/useApiAdmin";
import { useContextModelState } from "../../modelState/useContextModelState";
import { TextAlign } from "../../TextAlign";
import { ThemeVariant } from "../../ThemeVariant";
import { templateGetGroup, templateTypeIsAdmin } from "../../utils/utilTemplate";
import ButtonIcon from "../Button/ButtonIcon";
import { TypeIcon } from "../Icon";
import InfoText from "../InfoText";
import InputSwitch from "../inputs/InputSwitch";
import InputText from "../inputs/InputText";
import ComponentGroup from "../Layout/ComponentsGroup";
import Row from "../Layout/GRow";
import ScrollableArea from "../Layout/ScrollableArea";
import { PropsTemplateEditorFields } from "./TemplateEditorFields";
import "./TemplateEditorFields.scss";
import TemplateEditorGeneric from "./TemplateEditorGeneric";

const TemplateEditorGenerics = (props: PropsTemplateEditorFields) => {
  const modelState = useContextModelState();
  const template = modelState.model as AdminTemplateDto;
  const [textSearch, setTextSearch] = useState("");
  const [templateGenerics, setTemplateGenerics] = useState<AdminTemplateGenericSimpleDto[]>();
  const [showingTemplates, setShowingTemplates] = useState<AdminTemplateGenericSimpleDto[]>();
  const [apiTemplateGeneric] = useApiAdminAccountPublicTemplateGeneric();
  const [onlyAccountDefined, setOnlyAccountDefined] = useState(false);
  const templateGroup = templateGetGroup(template.type);

  const loadTemplates = () => {
    const load = async () => {
      let result = null;

      if (templateTypeIsAdmin(template.type)) {
        result = await apiTemplateGeneric.getGenericsForAdmin(templateGroup);
      } else if (template.isGlobal) {
        result = await apiTemplateGeneric.getGenericsForGlobal(templateGroup, template.country);
      } else {
        result = await apiTemplateGeneric.getGenericsForAccount(
          templateGroup,
          template.adminAccountId,
          onlyAccountDefined
        );
      }

      setTemplateGenerics(result);
      applyFilters(result, textSearch);
    };
    load();
  };

  useEffect(() => {
    loadTemplates();
  }, [onlyAccountDefined]);

  const applyFilters = (templates: AdminTemplateGenericSimpleDto[], textSearch: string) => {
    setShowingTemplates(templates.filter((f) => f.name.indexOf(textSearch) !== -1));
  };

  if (!templateGenerics) {
    return <></>;
  }

  return (
    <>
      <Row>
        <InfoText>Aquí se muestran las plantillas genéricas disponibles</InfoText>
      </Row>
      <Row minHeightIgnore={true} align={TextAlign.right} paddingTop={1}>
        <ButtonIcon
          icon={TypeIcon.refresh}
          size={AppSize.small}
          tooltip="Recargar plantillas"
          noPaddingY={true}
          onClick={() => loadTemplates()}
        />
      </Row>
      <Row paddingTop={1}>
        <InputText
          value={textSearch}
          label="Buscar plantilla"
          endIcon={TypeIcon.search}
          variant={ThemeVariant.standard}
          onChange={(text) => {
            setTextSearch(text);
            applyFilters(templateGenerics, text);
          }}
        />
      </Row>
      <Row paddingTop={0} minHeightIgnore={true}>
        <ComponentGroup>
          {template.adminAccountId && (
            <InputSwitch
              value={onlyAccountDefined}
              onChange={(value) => setOnlyAccountDefined(value ?? false)}
              label="Sólo definidos por mí"
            />
          )}
        </ComponentGroup>
      </Row>
      <Row>
        <ScrollableArea maxHeight={500}>
          {!showingTemplates ||
            (showingTemplates.length == 0 && (
              <InfoText>No hay plantillas genéricas para mostrar</InfoText>
            ))}
          {showingTemplates &&
            showingTemplates.map((template) => (
              <TemplateEditorGeneric
                key={template.id}
                canAddField={props.canAddField}
                template={template}
                onClick={props.onAddField}
              />
            ))}
        </ScrollableArea>
      </Row>
    </>
  );
};

export default TemplateEditorGenerics;
