import { ReportTaxesPeriodTaxCurrencyDto } from "../../../api/app/dtos/ReportTaxesPeriodTaxCurrencyDto";
import { Currency } from "../../../api/shared/enums/Currency";
import useUtils from "../../../shared/hooks/useUtils";
import ReportLine from "../ReportLine";
import ReportTaxesPeriodTaxCurrencyInvoiceType from "./ReportTaxes.PeriodTaxCurrencyInvoiceType";

const ReportTaxesPeriodTaxCurrency = (props: {
  periodTaxCurrency: ReportTaxesPeriodTaxCurrencyDto;
  defaultCurrency: Currency;
}) => {
  const { utils } = useUtils();

  let originalTotal = "";
  if (props.periodTaxCurrency.originalTotal) {
    originalTotal = utils.currency.toString(
      props.periodTaxCurrency.currency,
      props.periodTaxCurrency.originalTotal
    );
  }

  return (
    <ReportLine
      label={utils.enum.translateCurrencySymbol(props.periodTaxCurrency.currency)}
      values={[
        originalTotal,
        utils.currency.toString(props.defaultCurrency, props.periodTaxCurrency.total),
      ]}
      expandButtonShow={true}
      expandButtonDisabled={!props.periodTaxCurrency.byInvoiceTypes?.length}>
      {props.periodTaxCurrency.byInvoiceTypes.map((p, index) => (
        <ReportTaxesPeriodTaxCurrencyInvoiceType
          key={index}
          periodTaxCurrencyInvoiceType={p}
          defaultCurrency={props.defaultCurrency}
          taxCurrency={props.periodTaxCurrency.currency}
        />
      ))}
    </ReportLine>
  );
};

export default ReportTaxesPeriodTaxCurrency;
