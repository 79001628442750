import { ButtonAccept } from "../../../shared/components/Button/ButtonAccept";
import Row from "../../../shared/components/Layout/GRow";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import TemplateEditorInfoSection from "../../../shared/components/Templates/TemplateEditorInfoSection";
import TemplateEditorMain from "../../../shared/components/Templates/TemplateEditorMain";

const AdminTemplateGenericForm = (props: { onSave(): void }) => {
  return (
    <>
      <Row>
        <TemplateEditorInfoSection />
      </Row>

      <TemplateEditorMain />

      <RowButtons>
        <ButtonAccept onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default AdminTemplateGenericForm;
