// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AccountBranchDto } from "../../app/dtos/AccountBranchDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiCrossShardAccountBranch {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<FiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AccountBranchDto>> {
    return this.apiService.apiPost("/cross-shard/account-branches/paged", searchDto, apiOptions);
  }

}
