import { useEffect, useState } from "react";
import { ProductDto } from "../../api/app/dtos/ProductDto";
import { enumName } from "../../api/shared/enums/_enumName";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Container from "../../shared/components/Layout/GContainer";
import { useApiProduct } from "../../api/shared/hooks/useApiApp";
import { useModal } from "../../shared/hooks/useModal";
import useUtils from "../../shared/hooks/useUtils";

interface PropsProductEditPopup {
  productId: string;
}

const ProductDetailsPopup = (props: PropsProductEditPopup) => {
  const [modal] = useModal();
  const [product, setProduct] = useState<ProductDto>();
  const [apiProduct] = useApiProduct();
  const { utils } = useUtils();

  useEffect(() => {
    const loadProduct = async () => {
      setProduct(await apiProduct.getById(props.productId));
      modal.setVisible("Detalles de producto");
    };
    loadProduct();
  }, [props.productId, modal, apiProduct]);

  if (!product) {
    return <></>;
  }

  return (
    <Container>
      <ColumnDisplay label="Código">{product.code}</ColumnDisplay>
      <ColumnDisplay label="Nombre">{product.name}</ColumnDisplay>
      <ColumnDisplay label="Impuesto">
        {utils.enum.translate(enumName.Tax, product.info.defaultTax)}
      </ColumnDisplay>
      <ColumnDisplay label="Precio">
        {utils.enum.translate(enumName.Currency, product.info.defaultCurrency)}{" "}
        {utils.number.toString(product.info.referencePrice)}
      </ColumnDisplay>
    </Container>
  );
};

export default ProductDetailsPopup;
