import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Row from "../../shared/components/Layout/GRow";
import RowTitle from "../../shared/components/Layout/GRowTitle";
import Section from "../../shared/components/Layout/GSection";
import useUtils from "../../shared/hooks/useUtils";
import { TextAlign } from "../../shared/TextAlign";
import { AppThemeColor } from "../../styles/color";

const PurchaseDetailsTotals = (props: { purchase: PurchaseDto }) => {
  const { utils } = useUtils();
  const purchase = props.purchase;

  return (
    <>
      <RowTitle>Totales</RowTitle>
      <Row>
        <Section background={AppThemeColor.info}>
          <ColumnDisplay md={2} align={TextAlign.right} label="Total">
            {utils.number.toString(purchase.total)}
          </ColumnDisplay>
          <ColumnDisplay md={2} align={TextAlign.right} label="Tipo de cambio">
            {utils.number.toString(purchase.currencyExchangeRate)}
          </ColumnDisplay>
          <ColumnDisplay md={2} align={TextAlign.right} label="Créditos fiscales">
            {utils.number.toString(purchase.totalFiscalCredits)}
          </ColumnDisplay>
          <ColumnDisplay md={2} align={TextAlign.right} label="No facturable">
            {utils.number.toString(purchase.totalNoBillable)}
          </ColumnDisplay>
          <ColumnDisplay md={2} align={TextAlign.right} label="Retenciones">
            {utils.number.toString(purchase.totalRetentions)}
          </ColumnDisplay>
          <ColumnDisplay md={2} align={TextAlign.right} label="A pagar" fontSize={20}>
            {utils.currency.toString(purchase.currency, purchase.totalToPay)}
          </ColumnDisplay>
        </Section>
      </Row>
    </>
  );
};

export default PurchaseDetailsTotals;
