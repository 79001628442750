import { ContactFilterGroup } from "../../../api/shared/enums/ContactFilterGroup";
import FilterAutocompleteAccountBranch from "../forms/Filters/FilterAutocomplete/FilterAutocompleteAccountBranch";
import FilterAutocompleteAccountUser from "../forms/Filters/FilterAutocomplete/FilterAutocompleteAccountUser";
import FilterAutocompleteAdminAccount from "../forms/Filters/FilterAutocomplete/FilterAutocompleteAdminAccount";
import FilterAutocompleteAdminProvider from "../forms/Filters/FilterAutocomplete/FilterAutocompleteAdminProvider";
import FilterAutocompleteContact from "../forms/Filters/FilterAutocomplete/FilterAutocompleteContact";
import FilterDateRange from "../forms/Filters/FilterDateRange";
import FilterNumberRange from "../forms/Filters/FilterNumberRange";
import FilterRadioBoolean from "../forms/Filters/FilterRadioBoolean";
import FilterSelectCountry from "../forms/Filters/FilterSelectCountry";
import FilterSelectCurrency from "../forms/Filters/FilterSelectCurrency";
import FilterSelectEnum from "../forms/Filters/FilterSelectEnum";
import FilterSelectEnumMultiple from "../forms/Filters/FilterSelectEnumMultiple";
import FilterText from "../forms/Filters/FilterText";
import { GridFilterType, IGridItemTemplateFilter } from "./ItemTemplate/IGridItemTemplate";

const GridFiltersItem = (props: { filter: IGridItemTemplateFilter; label: string }) => {
  if (props.filter.type === GridFilterType.date || props.filter.type === GridFilterType.dateTime) {
    return (
      <FilterDateRange
        label={props.label}
        includeTime={props.filter.type === GridFilterType.dateTime}
        propertyName={props.filter.propertyName ?? ""}
      />
    );
  }

  if (props.filter.type === GridFilterType.enum) {
    if (!props.filter.enumName) {
      throw new Error("enumName is required for filter type enum");
    }
    return (
      <FilterSelectEnum
        label={props.label}
        propertyName={props.filter.propertyName ?? ""}
        enumTypeName={props.filter.enumName}
      />
    );
  }

  if (props.filter.type === GridFilterType.enumList) {
    if (!props.filter.enumName) {
      throw new Error("enumName is required for filter type enumList");
    }
    return (
      <FilterSelectEnumMultiple
        label={props.label}
        propertyName={props.filter.propertyName ?? ""}
        enumTypeName={props.filter.enumName}
      />
    );
  }

  if (
    props.filter.type === GridFilterType.customers ||
    props.filter.type === GridFilterType.providers
  ) {
    return (
      <FilterAutocompleteContact
        label={props.label}
        propertyName={props.filter.propertyName ?? ""}
        contactFilterGroup={
          props.filter.type === GridFilterType.customers
            ? ContactFilterGroup.Customers
            : ContactFilterGroup.Providers
        }
      />
    );
  }

  if (props.filter.type === GridFilterType.accountUser) {
    return (
      <FilterAutocompleteAccountUser
        label={props.label}
        propertyName={props.filter.propertyName ?? ""}
      />
    );
  }

  if (props.filter.type === GridFilterType.accountBranch) {
    return (
      <FilterAutocompleteAccountBranch
        label={props.label}
        propertyName={props.filter.propertyName ?? ""}
      />
    );
  }

  if (props.filter.type === GridFilterType.account) {
    return (
      <FilterAutocompleteAdminAccount
        label={props.label}
        propertyName={props.filter.propertyName ?? ""}
      />
    );
  }

  if (props.filter.type === GridFilterType.adminProviders) {
    return (
      <FilterAutocompleteAdminProvider
        label={props.label}
        propertyName={props.filter.propertyName ?? ""}
      />
    );
  }

  if (props.filter.type === GridFilterType.currency) {
    return (
      <FilterSelectCurrency label={props.label} propertyName={props.filter.propertyName ?? ""} />
    );
  }

  if (
    props.filter.type === GridFilterType.numberRange ||
    props.filter.type === GridFilterType.numberRangeInteger
  ) {
    return (
      <FilterNumberRange
        label={props.label}
        propertyName={props.filter.propertyName ?? ""}
        intOnly={props.filter.type === GridFilterType.numberRangeInteger}
      />
    );
  }

  if (props.filter.type === GridFilterType.boolean) {
    return (
      <FilterRadioBoolean label={props.label} propertyName={props.filter.propertyName ?? ""} />
    );
  }

  if (
    props.filter.type === GridFilterType.country ||
    props.filter.type === GridFilterType.countryAvailable
  ) {
    return (
      <FilterSelectCountry
        label={props.label}
        propertyName={props.filter.propertyName ?? ""}
        onlyAvailable={props.filter.type == GridFilterType.countryAvailable}
      />
    );
  }

  if (props.filter.type === GridFilterType.text || !props.filter.element) {
    return <FilterText label={props.label} propertyName={props.filter.propertyName ?? ""} />;
  }

  return <>{props.filter.element}</>;
};

export default GridFiltersItem;
