import { Outlet } from "react-router";
import AdminProviderLogoLanding from "../../../shared/components/AdminProviderLogoLanding";
import Container from "../../../shared/components/Layout/GContainer";
import NotificationArea from "../../../shared/components/Notifications/NotificationArea";
import PublicContent from "./PublicContent";
import "./PublicLayout.scss";

const PublicLayout = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <div className="public-layout">
        <div className="public-layout-left">
          <AdminProviderLogoLanding />
        </div>
        <div className="public-layout-right">
          <PublicContent>
            <Container>
              <Outlet />
            </Container>
          </PublicContent>
        </div>
      </div>
      <NotificationArea />
    </div>
  );
};

export default PublicLayout;
