// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminCurrencyExchangeRateDto } from "../dtos/AdminCurrencyExchangeRateDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { CurrencyExchangeFiltersDto } from "../dtos/CurrencyExchangeFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { dateToIsoString } from "../../shared/utils/apiUtilsDate";

export class ApiAdminCurrencyExchangeRate {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<CurrencyExchangeFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AdminCurrencyExchangeRateDto>> {
    return this.apiService.apiPost("/admin/currency-exchange-rates/paged", searchDto, apiOptions);
  }

  saveExchangeRatesFromExternalService(
    country: number,
    date: Date,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/admin/currency-exchange-rates/save-rates-from-external-service?" + 
      (country ? "&country=" + country : "") + 
      (date ? "&date=" + dateToIsoString(date) : ""), null, apiOptions);
  }

}
