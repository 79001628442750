import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import { enumName } from "../../api/shared/enums/_enumName";
import InfoText, { InfoTextType } from "../../shared/components/InfoText";
import Row from "../../shared/components/Layout/GRow";
import useUtils from "../../shared/hooks/useUtils";
import PurchaseDetailsColumn from "./PurchaseDetails.Column";
import PurchaseDetailsLines from "./PurchaseDetails.Lines";
import PurchaseDetailsTaxes from "./PurchaseDetails.Taxes";
import PurchaseDetailsTotals from "./PurchaseDetails.Totals";

const PurchaseDetailsHeader = (props: { purchase: PurchaseDto }) => {
  const { utils } = useUtils();
  const purchase = props.purchase;

  return (
    <>
      {purchase.taxesFixedApplied && (
        <Row>
          <InfoText type={InfoTextType.warning}>
            Se aplicó ajuste automática de valores de los impuestos
          </InfoText>
        </Row>
      )}
      {purchase.rejectDetails && (
        <Row>
          <InfoText type={InfoTextType.error}>{purchase.rejectDetails}</InfoText>
        </Row>
      )}
      <PurchaseDetailsColumn label="Tipo">
        {utils.enum.translate(enumName.InvoiceType, purchase.invoiceType)}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Número">{purchase.serieAndNumber}</PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Fecha">
        {utils.date.toString(purchase.date)}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Forma de pago">
        {utils.enum.translate(enumName.PaymentType, purchase.paymentType)}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Fecha de vencimiento">
        {utils.date.toString(purchase.paymentExpirationDate)}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Id Compra">
        {purchase.purchaseInfo.receiverPurchaseId}
      </PurchaseDetailsColumn>

      <PurchaseDetailsLines purchase={purchase} />

      <PurchaseDetailsTaxes purchase={purchase} />

      <PurchaseDetailsTotals purchase={purchase} />
    </>
  );
};

export default PurchaseDetailsHeader;
