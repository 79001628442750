import ButtonIcon from "../Button/ButtonIcon";
import { TypeIcon } from "../Icon";

const GridButtonExpand = (props: {
  isExpanded: boolean;
  onClick(item: any): void;
  disabled: boolean;
}) => {
  return (
    <ButtonIcon
      icon={props.isExpanded ? TypeIcon.chevronUp : TypeIcon.chevronDown}
      noPaddingX={true}
      onClick={props.onClick}
      disabled={props.disabled}
    />
  );
};

export default GridButtonExpand;
