import { nameof } from "ts-simple-nameof";
import { AdminPointOfSaleClientDto } from "../../../api/admin/dtos/AdminPointOfSaleClientDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

export function useAdminPointOfSaleClientCreateValidation() {
  const schema = new ValidationSchema();

  schema[nameof<AdminPointOfSaleClientDto>((p) => p.account)] = validate("Cuenta").required();

  return schema;
}
