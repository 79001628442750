import { useState } from "react";
import { AdminTemplateDto } from "../../../api/admin/dtos/AdminTemplateDto";
import { AdminTemplatePdfDto } from "../../../api/admin/dtos/AdminTemplatePdfDto";
import { PaperOrientation } from "../../../api/shared/enums/PaperOrientation";
import { PaperSize } from "../../../api/shared/enums/PaperSize";
import { AppRoute } from "../../../AppRoutes";
import Page from "../../../shared/components/Layout/Page";
import { useApiAdminAccountPublicTemplatePdf } from "../../../api/shared/hooks/useApiAdmin";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import { useNotifier } from "../../../shared/hooks/useNotifier";
import { StateProvider } from "../../../shared/modelState/ModelStateContext";
import AdminTemplateCreateFirstStep from "../AdminTemplate/AdminTemplateCreateFirstStep";
import AdminTemplatePdfForm from "./AdminTemplatePdfForm";
import { useAdminTemplatePdfValidation } from "./Validations/useAdminTemplatePdfValidation";

const AdminTemplatePdfCreate = () => {
  const [adminTemplatePdf, setAdminTemplatePdf] = useState<AdminTemplatePdfDto>(() => {
    const adminTemplatePdf = new AdminTemplatePdfDto();
    adminTemplatePdf.paperSize = PaperSize.A4;
    adminTemplatePdf.orientation = PaperOrientation.Portrait;
    adminTemplatePdf.isGlobal = false;
    return adminTemplatePdf;
  });

  const navigation = useNavigation();
  const [apiTemplatePdf] = useApiAdminAccountPublicTemplatePdf();
  const [displayFirstPage, setDisplayFirstPage] = useState(true);
  const notifier = useNotifier();

  const save = async (adminTemplatePdf: AdminTemplatePdfDto) => {
    await apiTemplatePdf.create(adminTemplatePdf);
    notifier.showSuccess("La plantilla de pdf se creó con éxito");
    navigation.go(AppRoute.admin.templates.pdf.base);
  };

  return (
    <Page title="Crear plantilla de pdf">
      {displayFirstPage && (
        <AdminTemplateCreateFirstStep
          adminTemplate={adminTemplatePdf}
          onContinue={(adminTemplate: AdminTemplateDto) => {
            setAdminTemplatePdf(adminTemplate as AdminTemplatePdfDto);
            setDisplayFirstPage(false);
          }}
        />
      )}
      {!displayFirstPage && (
        <StateProvider
          model={adminTemplatePdf}
          onSubmit={save}
          validationSchema={useAdminTemplatePdfValidation()}>
          {(p) => <AdminTemplatePdfForm onSave={p.handleSubmit} />}
        </StateProvider>
      )}
    </Page>
  );
};

export default AdminTemplatePdfCreate;
