// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminTaxValueDto } from "../dtos/AdminTaxValueDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { AdminTaxValueFiltersDto } from "../dtos/AdminTaxValueFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminTaxValue {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<AdminTaxValueFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AdminTaxValueDto>> {
    return this.apiService.apiPost("/admin/tax-values/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AdminTaxValueDto> {
    return this.apiService.apiGet("/admin/tax-values/" + id, apiOptions);
  }

  create(
    adminTaxValueDto: AdminTaxValueDto,
    apiOptions?: ApiOptions
  ): Promise<AdminTaxValueDto> {
    return this.apiService.apiPost("/admin/tax-values", adminTaxValueDto, apiOptions);
  }

  edit(
    adminTaxValueDto: AdminTaxValueDto,
    apiOptions?: ApiOptions
  ): Promise<AdminTaxValueDto> {
    return this.apiService.apiPut("/admin/tax-values", adminTaxValueDto, apiOptions);
  }

}
