import Row from "../Layout/GRow";
import ErrorPageLayout from "./ErrorPageLayout";

const Unauthorized = () => {
  return (
    <ErrorPageLayout>
      <Row>No tienes permisos para ver esta página :(</Row>
    </ErrorPageLayout>
  );
};

export default Unauthorized;
