// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminShardDto } from "../dtos/AdminShardDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminShard {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<FiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AdminShardDto>> {
    return this.apiService.apiPost("/admin/shards/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AdminShardDto> {
    return this.apiService.apiGet("/admin/shards/" + id, apiOptions);
  }

  getAll(
    apiOptions?: ApiOptions
  ): Promise<AdminShardDto[]> {
    return this.apiService.apiGet("/admin/shards/all", apiOptions);
  }

  create(
    adminShardDto: AdminShardDto,
    apiOptions?: ApiOptions
  ): Promise<AdminShardDto> {
    return this.apiService.apiPost("/admin/shards", adminShardDto, apiOptions);
  }

  edit(
    adminShardDto: AdminShardDto,
    apiOptions?: ApiOptions
  ): Promise<AdminShardDto> {
    return this.apiService.apiPut("/admin/shards", adminShardDto, apiOptions);
  }

}
