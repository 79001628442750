import { AppSize } from "../../shared/AppSize";
import Icon, { TypeIcon } from "../../shared/components/Icon";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Row from "../../shared/components/Layout/GRow";
import { FlexDirection, TextAlign } from "../../shared/TextAlign";
import { AppThemeColor } from "../../styles/color";

const SaleFormLinesEmptyList = () => {
  return (
    <Row align={TextAlign.center} fontColor={AppThemeColor.gray} fontSize="2em" paddingTop={5}>
      <ComponentGroup direction={FlexDirection.column} align={TextAlign.center}>
        <Icon type={TypeIcon.emptyProductList} size={AppSize.large} style={{ display: "block" }} />
        <div>Agregá un producto para comenzar</div>
      </ComponentGroup>
    </Row>
  );
};
export default SaleFormLinesEmptyList;
