import { nameof } from "ts-simple-nameof";
import { AdminProviderDto } from "../../../../api/admin/dtos/AdminProviderDto";
import { AdminProviderFiltersDto } from "../../../../api/admin/dtos/AdminProviderFiltersDto";
import { AdminProviderSimpleDto } from "../../../../api/admin/dtos/AdminProviderSimpleDto";
import { SuggestItemDto } from "../../../../api/shared/dtos/SuggestItemDto";
import { SuggestSearchDto } from "../../../../api/shared/dtos/SuggestSearchDto";
import { useApiAdminProvider } from "../../../../api/shared/hooks/useApiAdmin";
import InputAutocompleteBase, { PropsInputAutocompleteBase } from "./InputAutocompleteBase";

export type PropsInputAutocompleteAdminProvider = Omit<
  PropsInputAutocompleteBase<AdminProviderDto | null>,
  "onGetEntityById"
>;

const InputAutocompleteAdminProvider = (props: PropsInputAutocompleteAdminProvider) => {
  const [apiAdminProvider] = useApiAdminProvider();

  const apiRequest = (echoId: number, term: string, isInitialLoad: boolean) => {
    const searchDto = new SuggestSearchDto<AdminProviderFiltersDto>();
    searchDto.echoId = echoId;
    searchDto.filters = new AdminProviderFiltersDto();
    searchDto.filters.defaultTextSearch = term;
    searchDto.isInitialLoad = isInitialLoad;
    searchDto.orderBy = [{ columnName: nameof<AdminProviderDto>((p) => p.name), asc: true }];
    const apiOptions = {
      preventSpinner: true,
    };

    return apiAdminProvider.suggest(searchDto, apiOptions);
  };

  return (
    <InputAutocompleteBase
      {...props}
      value={props.value}
      inputTextValue={props.inputTextValue ?? props.value?.name ?? ""}
      onGetEntityById={(id: string) => apiAdminProvider.getById(id, { preventSpinner: true })}
      onServerSideSuggest={apiRequest}
      onMapFromItemSuggest={(item: SuggestItemDto) => {
        const adminProviderSimpleDto = new AdminProviderSimpleDto();
        adminProviderSimpleDto.id = item.id;
        adminProviderSimpleDto.name = item.label;
        return adminProviderSimpleDto;
      }}
    />
  );
};

export default InputAutocompleteAdminProvider;
