import { useEffect, useState } from "react";
import { PointOfSaleDto } from "../../../api/app/dtos/PointOfSaleDto";
import {
  useApiAdminProviderAccessAccountBranch,
  useApiAdminProviderAccessPointOfSale,
} from "../../../api/shared/hooks/useApiAdminProviderAccess";
import Container from "../../../shared/components/Layout/GContainer";
import { ModalSize } from "../../../shared/context/ModalProvider";
import { useModal } from "../../../shared/hooks/useModal";
import { StateProvider } from "../../../shared/modelState/ModelStateContext";
import AdminPointOfSaleForm from "./AdminPointOfSaleForm";
import { useAdminPointOfSaleValidator } from "./Validations/useAdminPointOfSaleValidations";

const AdminPointOfSaleCreatePopup = (props: { accountId: string; accountBranchId: string }) => {
  const [pointOfSaleDto, setPointOfSaleDto] = useState<PointOfSaleDto>(() => {
    const pointOfSale = new PointOfSaleDto();
    pointOfSale.accountId = props.accountId;
    return pointOfSale;
  });
  const [modal] = useModal();
  const [apiAdminAccessPointOfSale] = useApiAdminProviderAccessPointOfSale();
  const [apiAdminAccessAccountBranch] = useApiAdminProviderAccessAccountBranch();
  const save = async (pointOfSale: PointOfSaleDto) => {
    const created = await apiAdminAccessPointOfSale.create(pointOfSale.accountId, pointOfSale);
    modal.closeAsSuccess(created, "El punto de venta se creó con éxito");
  };

  useEffect(() => {
    const request = async () => {
      const accountBranch = await apiAdminAccessAccountBranch.getById(
        props.accountId,
        props.accountBranchId
      );
      setPointOfSaleDto({
        ...pointOfSaleDto,
        accountBranchId: accountBranch.id,
        accountBranch: accountBranch,
      });
      modal.setVisible("Crear punto de venta", ModalSize.small);
    };
    request();
  }, [modal]);

  if (!pointOfSaleDto?.accountBranch) {
    return null;
  }

  return (
    <Container>
      <StateProvider
        model={pointOfSaleDto}
        onSubmit={save}
        validationSchema={useAdminPointOfSaleValidator()}>
        {(p) => <AdminPointOfSaleForm onSave={p.handleSubmit} isEdit={false} />}
      </StateProvider>
    </Container>
  );
};

export default AdminPointOfSaleCreatePopup;
