import { forwardRef } from "react";
import InputSelectTimeZone, {
  PropsInputSelectTimeZone,
} from "../..//inputs/select/InputSelectTimeZone";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

interface PropsFormSelectTimeZone
  extends PropsFormInput<string | null>,
    Omit<PropsInputSelectTimeZone, "value" | "onChange"> {}

const FormSelectTimeZone = forwardRef((props: PropsFormSelectTimeZone, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputSelectTimeZone {...props} {...inputProps} />;
});

export default FormSelectTimeZone;
