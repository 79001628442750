import { ButtonAccept } from "../../../shared/components/Button/ButtonAccept";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import TemplateEditorInfoSection from "../../../shared/components/Templates/TemplateEditorInfoSection";
import TemplateEditorMain from "../../../shared/components/Templates/TemplateEditorMain";
import TemplatePdfFormSettings from "./AdminTemplatePdfForm.Setting";

const AdminTemplatePdfForm = (props: { onSave(): void }) => {
  return (
    <>
      <TemplateEditorInfoSection />

      <TemplatePdfFormSettings />

      <TemplateEditorMain />

      <RowButtons>
        <ButtonAccept onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default AdminTemplatePdfForm;
