import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { ProductDto } from "../../api/app/dtos/ProductDto";
import { ButtonCreate } from "../../shared/components/Button/ButtonCreate";
import Grid, { GridColumnAlign } from "../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
  MobileSpecialType,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../shared/components/Layout/Page";
import { useApiProduct } from "../../api/shared/hooks/useApiApp";
import { useModal } from "../../shared/hooks/useModal";
import ProductCreatePopup from "./ProductCreatePopup";
import getProductListItemMenu from "./_ProductListItemMenu";
import { GridName } from "../../api/shared/enums/GridName";
import { ProductFiltersDto } from "../../api/app/dtos/ProductFiltersDto";
import { enumName } from "../../api/shared/enums/_enumName";

const ProductList = () => {
  const [modal] = useModal();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [apiProduct] = useApiProduct();

  const itemTemplates: IGridItemTemplate<ProductDto>[] = [
    {
      header: "Nombre",
      field: nameof<ProductDto>((p) => p.name),
      filter: {
        propertyName: nameof<ProductFiltersDto>((p) => p.name),
        type: GridFilterType.text,
      },
      width: 40,
      mobileSpecialType: MobileSpecialType.Main,
    },
    {
      header: "Tipo",
      field: nameof<ProductDto>((p) => p.type),
      cellTypeEnumName: enumName.ProductType,
      filter: {
        propertyName: nameof<ProductFiltersDto>((p) => p.type),
        type: GridFilterType.enum,
        enumName: enumName.ProductType,
      },
      width: 10,
    },
    {
      header: "Código",
      field: nameof<ProductDto>((p) => p.code),
      filter: {
        propertyName: nameof<ProductFiltersDto>((p) => p.code),
        type: GridFilterType.text,
      },
      width: 15,
      mobileSpecialType: MobileSpecialType.Secondary,
    },
    {
      header: "Ventas",
      field: nameof<ProductDto>((p) => p.isForSale),
      filter: {
        propertyName: nameof<ProductFiltersDto>((p) => p.isForSales),
        type: GridFilterType.boolean,
      },
      width: 15,
      cellType: GridCellType.iconCheck,
    },
    {
      header: "Compras",
      field: nameof<ProductDto>((p) => p.isForPurchase),
      filter: {
        propertyName: nameof<ProductFiltersDto>((p) => p.isForPurchases),
        type: GridFilterType.boolean,
      },
      width: 15,
      cellType: GridCellType.iconCheck,
    },
    {
      header: "Precio",
      field: nameof<ProductDto>((p) => p.info.referencePrice),
      align: GridColumnAlign.right,
      relatedFields: [nameof<ProductDto>((p) => p.info.defaultCurrency)],
      width: 15,
      cellType: GridCellType.currencyValue,
      cellTypeCurrencyField: nameof<ProductDto>((p) => p.info.defaultCurrency),
    },
  ];

  return (
    <Page
      title="Productos"
      titleButton={
        <ButtonCreate
          text="Nuevo producto"
          onClick={() => modal.open(<ProductCreatePopup />, () => setReloadGrid(new Date()))}
        />
      }>
      <Grid
        itemTemplates={itemTemplates}
        gridName={GridName.Contacts}
        reloadGrid={reloadGrid}
        onSearch={(search, options) => apiProduct.getPaged(search, options)}
        onDisplayItemMenu={(product: ProductDto) =>
          getProductListItemMenu(product, modal, () => setReloadGrid(new Date()))
        }
      />
    </Page>
  );
};

export default ProductList;
