import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminTermAndConditionsFiltersDto } from "../../api/admin/dtos/AdminTermAndConditionsFiltersDto";
import { AdminTermsAndConditionsDto } from "../../api/admin/dtos/AdminTermsAndConditionsDto";
import { useApiAdminProviderTermsAndConditions } from "../../api/shared/hooks/useApiAdminProviders";
import { AppRoute } from "../../AppRoutes";
import ButtonCreate from "../../shared/components/Button/ButtonCreate";
import Grid from "../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../shared/components/Layout/Page";
import { AppInfoSiteType } from "../../shared/context/AppInfoProvider";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { useNavigation } from "../../shared/hooks/useNavigation";
import getAdminTermsAndConditionListItemMenu from "./AdminTermsAndConditionsList.ItemMenu";

const AdminTermsAndConditionsList = () => {
  const [apiAdminTermsAndConditions] = useApiAdminProviderTermsAndConditions();
  const { appInfo } = useContextAppInfo();

  const [initialFilters] = useState(() => {
    const filters = new AdminTermAndConditionsFiltersDto();
    filters.crossShardIncludeAllProviders = appInfo?.siteType == AppInfoSiteType.SuperAdmin;
    return filters;
  });
  const navigation = useNavigation();

  const itemTemplates: IGridItemTemplate<AdminTermsAndConditionsDto>[] = [
    {
      header: "Proveedor",
      field: nameof<AdminTermsAndConditionsDto>((p) => p.adminProvider.name),
      filter: {
        propertyName: nameof<AdminTermAndConditionsFiltersDto>((p) => p.adminProvider),
        type: GridFilterType.adminProviders,
      },
      width: 30,
    },
    {
      header: "Notificar desde",
      field: nameof<AdminTermsAndConditionsDto>((p) => p.notifySince),
      cellType: GridCellType.date,
      width: 20,
    },
    {
      header: "Obligatoria desde",
      field: nameof<AdminTermsAndConditionsDto>((p) => p.mandatorySince),
      width: 20,
      cellType: GridCellType.date,
    },

    {
      header: "Versión",
      field: nameof<AdminTermsAndConditionsDto>((p) => p.version),
      width: 30,
      cellType: GridCellType.integer,
    },
  ];

  if (!appInfo) {
    return <></>;
  }

  return (
    <Page
      title="Términos y condiciones"
      titleButton={
        <ButtonCreate
          text="Nuevos términos y condiciones"
          onClick={() =>
            navigation.go(
              appInfo?.siteType == AppInfoSiteType.SuperAdmin
                ? AppRoute.admin.termsAndConditions.create
                : AppRoute.adminProvider.termsAndConditions.create
            )
          }
        />
      }>
      <Grid
        itemTemplates={itemTemplates}
        defaultSortBy={[
          { columnName: nameof<AdminTermsAndConditionsDto>((p) => p.mandatorySince), asc: false },
        ]}
        hideIncludeInactiveButton={true}
        preventShowDefaultSearch={true}
        initialFilters={initialFilters}
        onSearch={(search, options) => apiAdminTermsAndConditions.getPaged(search, options)}
        onDisplayItemMenu={(adminTermsAndConditions: AdminTermsAndConditionsDto) =>
          getAdminTermsAndConditionListItemMenu(
            adminTermsAndConditions,
            appInfo.siteType,
            navigation
          )
        }
      />
    </Page>
  );
};

export default AdminTermsAndConditionsList;
