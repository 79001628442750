import { useEffect, useState } from "react";
import { EInvoiceDailyReportCrossShardDto } from "../../../api/app/dtos/EInvoiceDailyReportCrossShardDto";
import { enumName } from "../../../api/shared/enums/_enumName";
import { useApiCrossShardEInvoiceDailyReport } from "../../../api/shared/hooks/useApiCrossShard";
import ButtonFileDownload from "../../../shared/components/Button/ButtonFileDownload";
import ColumnDisplay from "../../../shared/components/Layout/GColumnDisplay";
import Container from "../../../shared/components/Layout/GContainer";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import RowDisplay from "../../../shared/components/Layout/GRowDisplay";
import { ModalSize } from "../../../shared/context/ModalProvider";
import { useModal } from "../../../shared/hooks/useModal";
import useUtils from "../../../shared/hooks/useUtils";
import { DateFormatType } from "../../../shared/utils/localizedUtil/localizedUtilDates";

const AdminEInvoiceDailyReportDetailsPopup = (props: { adminAccountId: string; id: string }) => {
  const [modal] = useModal();
  const [dailyReport, setDailyReport] = useState<EInvoiceDailyReportCrossShardDto>();
  const [apiCrossShardEInvoiceDailyReport] = useApiCrossShardEInvoiceDailyReport();
  const { utils } = useUtils();

  useEffect(() => {
    const loadAdminAccount = async () => {
      setDailyReport(
        await apiCrossShardEInvoiceDailyReport.getById(props.adminAccountId, props.id)
      );
      modal.setVisible("Detalles de reporte diario", ModalSize.normal);
    };
    loadAdminAccount();
  }, []);

  if (!dailyReport) {
    return <></>;
  }

  return (
    <Container>
      <ColumnDisplay label="Cuenta">{dailyReport.account?.name}</ColumnDisplay>
      <ColumnDisplay label="Fecha">{utils.date.toString(dailyReport.date)}</ColumnDisplay>
      <ColumnDisplay label="Fecha de envío">
        {utils.date.toString(dailyReport.sentDate, DateFormatType.DateHourMinutesSeconds)}
      </ColumnDisplay>
      <ColumnDisplay label="Id interno">{dailyReport.internalSenderId}</ColumnDisplay>
      <ColumnDisplay label="No secuencia">{dailyReport.secuencialNumber}</ColumnDisplay>
      <ColumnDisplay label="Estado">
        {utils.enum.translate(enumName.EInvoiceDailyReportStatus, dailyReport.status)}
      </ColumnDisplay>
      {!!dailyReport.errorMessage && (
        <RowDisplay label="Error" multilineAllow={true}>
          {dailyReport.errorMessage}
        </RowDisplay>
      )}
      <RowButtons>
        <ButtonFileDownload text="Xml" fileUrl={dailyReport.xmlUrl} />
        <ButtonFileDownload text="Request" fileUrl={dailyReport.wsLogRequestUrl} />
        <ButtonFileDownload text="Response" fileUrl={dailyReport.wsLogRequestUrl} />
      </RowButtons>
    </Container>
  );
};

export default AdminEInvoiceDailyReportDetailsPopup;
