import { ReactElement, useContext, useState } from "react";
import { useNotifier } from "../hooks/useNotifier";
import {
  ModalAction,
  ModalActionType,
  ModalSize,
  ModalInfoContext,
} from "../context/ModalProvider";
import {
  NotificationAction,
  NotificationActionType,
  NotificationsContextSet,
} from "../components/Notifications/NotificationsProvider";

export class ModalService {
  constructor(
    private _modalDispatcher: React.Dispatch<ModalAction>,
    private _notificationDispatcher: React.Dispatch<NotificationAction>,
    private _notifySuccess: (message: string) => void
  ) {}

  open(
    content: ReactElement,
    onCloseAsSuccess?: (info?: any) => void,
    onCloseAsCancel?: (info?: any) => void
  ) {
    this._modalDispatcher({
      type: ModalActionType.open,
      content: content,
      onCloseAsSuccess: onCloseAsSuccess,
      onCloseAsCancel: onCloseAsCancel,
    });
  }

  replace(
    content: ReactElement,
    onCloseAsSuccess?: (info?: any) => void,
    onCloseAsCancel?: (info?: any) => void
  ) {
    this._modalDispatcher({
      type: ModalActionType.replace,
      content: content,
      onCloseAsSuccess: onCloseAsSuccess,
      onCloseAsCancel: onCloseAsCancel,
    });
  }

  setVisible(title: string, size?: ModalSize, options?: { preventCloseOnOutsideClick: boolean }) {
    this._modalDispatcher({
      type: ModalActionType.setVisible,
      title: title,
      size: size ?? ModalSize.normal,
      preventCloseOnOutsideClick: options?.preventCloseOnOutsideClick,
    });
    this._notificationDispatcher({
      type: NotificationActionType.closeAll,
    } as NotificationAction);
  }

  closeAsSuccess(info: any, successMessage?: string) {
    this._modalDispatcher({ type: ModalActionType.closeAsSuccess, info: info });
    if (successMessage) {
      this._notifySuccess(successMessage);
    }
  }

  closeAsCancel(info?: any) {
    this._modalDispatcher({ type: ModalActionType.closeAsCancel, info: info });
  }

  confirmClose() {
    this._modalDispatcher({ type: ModalActionType.confirmClose });
  }
}

export function useModal() {
  const { modalDispatcher } = useContext(ModalInfoContext);
  const { notificationDispatcher } = useContext(NotificationsContextSet);
  const notifier = useNotifier();
  return useState(new ModalService(modalDispatcher, notificationDispatcher, notifier.showSuccess));
}
