import { useEffect, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AccountBranchSimpleDto } from "../../api/app/dtos/AccountBranchSimpleDto";
import { AuthSwitchPointOfSaleDto } from "../../api/app/dtos/AuthSwitchPointOfSaleDto";
import { PointOfSaleDto } from "../../api/app/dtos/PointOfSaleDto";
import { useApiSwitchPointOfSale } from "../../api/shared/hooks/useApiApp";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormAutocompleteAdminAccount from "../../shared/components/forms/Autocomplete/FormAutocompleteAdminAccount";
import FormAutocompleteSwitchAccountBranch from "../../shared/components/forms/Autocomplete/FormAutocompleteSwitchAccountBranch";
import FormAutocompleteSwitchAdminAccount from "../../shared/components/forms/Autocomplete/FormAutocompleteSwitchAdminAccount";
import FormAutocompleteSwitchPointOfSale from "../../shared/components/forms/Autocomplete/FormAutocompleteSwitchPointOfSale";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import { useContextModelState } from "../../shared/modelState/useContextModelState";

const SwitchPointOfSalePopUpForm = (props: { onSave: () => void }) => {
  const { loginInfo } = useContextLoginInfo();
  const modelState = useContextModelState();
  const model = modelState.model as AuthSwitchPointOfSaleDto;
  const [apiSwitchPointOfSale] = useApiSwitchPointOfSale();
  const authSwitchPointOfSale = model as AuthSwitchPointOfSaleDto;
  const [mustReloadBranch, setMustReloadBranch] = useState(false);
  const [mustReloadPointOfSale, setMustReloadPointOfSale] = useState(false);

  useEffect(() => {
    if (mustReloadBranch) {
      setMustReloadBranch(false);
    }

    if (mustReloadPointOfSale) {
      setMustReloadPointOfSale(false);
    }
  }, [mustReloadBranch, mustReloadPointOfSale]);

  useEffect(() => {
    const request = async () => {
      let accountBranch = null;

      if (model.adminAccount) {
        try {
          accountBranch = await apiSwitchPointOfSale.getDefaultAccountBranch(model.adminAccountId);
        } catch (error) {
          //Ignore
        }
      }

      modelState.updateProperty(
        nameof<AuthSwitchPointOfSaleDto>((p) => p.accountBranchId),
        accountBranch != null ? (accountBranch as AccountBranchSimpleDto).id : null
      );

      modelState.updateProperty(
        nameof<AuthSwitchPointOfSaleDto>((p) => p.accountBranch),
        accountBranch
      );
    };
    request();

    setMustReloadBranch(true);
  }, [model.adminAccountId]);

  useEffect(() => {
    const request = async () => {
      let pointOfSale = null;

      if (model.accountBranchId && model.accountBranchId != loginInfo.authInfo.accountBranch.id) {
        try {
          pointOfSale = await apiSwitchPointOfSale.getDefaultPointOfSale(
            model.adminAccountId,
            model.accountBranchId
          );
        } catch (error) {
          //Ignore
        }
      } else {
        pointOfSale = loginInfo.authInfo.pointOfSale;
      }

      modelState.updateProperty(
        nameof<AuthSwitchPointOfSaleDto>((p) => p.pointOfSaleId),
        pointOfSale != null ? (pointOfSale as PointOfSaleDto).id : null
      );

      modelState.updateProperty(
        nameof<AuthSwitchPointOfSaleDto>((p) => p.pointOfSale),
        pointOfSale
      );
    };
    request();

    setMustReloadPointOfSale(true);
  }, [model.accountBranchId]);

  return (
    <>
      <Row>
        {loginInfo?.authInfo.isSuperAdmin && (
          <FormAutocompleteAdminAccount
            label="Cuenta (SuperAdmin)"
            propertyName={nameof<AuthSwitchPointOfSaleDto>((p) => p.adminAccount)}
            preventClearButton={true}
          />
        )}
        {!loginInfo?.authInfo.isSuperAdmin && (
          <FormAutocompleteSwitchAdminAccount
            label="Cuenta"
            propertyName={nameof<AuthSwitchPointOfSaleDto>((p) => p.adminAccount)}
          />
        )}
      </Row>
      <Row>
        {!mustReloadBranch && (
          <FormAutocompleteSwitchAccountBranch
            label="Sucursal"
            adminAccountId={authSwitchPointOfSale.adminAccountId}
            readOnly={!authSwitchPointOfSale.adminAccountId}
            propertyName={nameof<AuthSwitchPointOfSaleDto>((p) => p.accountBranch)}
          />
        )}
      </Row>
      <Row>
        {!mustReloadPointOfSale && (
          <FormAutocompleteSwitchPointOfSale
            label="Punto de venta"
            adminAccountId={authSwitchPointOfSale.adminAccountId}
            accountBranchId={authSwitchPointOfSale.accountBranchId}
            readOnly={
              !authSwitchPointOfSale.adminAccountId || !authSwitchPointOfSale.accountBranchId
            }
            propertyName={nameof<AuthSwitchPointOfSaleDto>((p) => p.pointOfSale)}
          />
        )}
      </Row>
      <RowButtons>
        <ButtonAccept onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default SwitchPointOfSalePopUpForm;
