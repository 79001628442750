import { createContext, PropsWithChildren, useEffect, useReducer } from "react";
import { AdminProviderInfoDto } from "../../api/admin/dtos/AdminProviderInfoDto";
import { AdminProviderSettingsDto } from "../../api/admin/dtos/AdminProviderSettingsDto";
import { useApiPublicAdminProviderInfo } from "../../api/shared/hooks/useApiPublicAdmin";
import { AppEnvironment } from "../../AppEnvironment";
import ServerCommunicationError from "../components/LoginFlow/ServerCommunicationError";
import { ThemeVariant } from "../ThemeVariant";

export enum AppInfoSiteType {
  Account,
  SuperAdmin,
  AdminProvider,
}

export type AppInfo = {
  currentPageTitle: string;
  currentArea: string;
  siteType: AppInfoSiteType;
  developShowLinesBoxes: boolean;
  adminProviderInfo: AdminProviderInfoDto;
  muiVariant: ThemeVariant;
  showServerCommunicationError: boolean;
  requestedExportListIsOpen: boolean;
};

const getInitialState = (): AppInfo | null => {
  return null;
};

const AppInfoContext = createContext<{
  appInfo: AppInfo | null;
  dispatchAppInfo: React.Dispatch<AppInfoAction>;
}>({
  appInfo: null,
  dispatchAppInfo: () => null,
});

export enum AppInfoActionType {
  changePage,
  changeMuiVariant,
  developShowLinesBoxes,
  adminProviderInfoLoaded,
  requestedExportListOpen,
  showServerCommunicationError,
  setSiteType,
}

export type AppInfoAction =
  | {
      type: AppInfoActionType.changePage;
      title: string;
      location: string;
    }
  | {
      type: AppInfoActionType.adminProviderInfoLoaded;
      adminProviderInfo: AdminProviderInfoDto;
    }
  | {
      type: AppInfoActionType.showServerCommunicationError;
    }
  | {
      type: AppInfoActionType.changeMuiVariant;
      variant: ThemeVariant;
    }
  | {
      type: AppInfoActionType.developShowLinesBoxes;
      show: boolean;
    }
  | { type: AppInfoActionType.requestedExportListOpen; open: boolean }
  | { type: AppInfoActionType.setSiteType; siteType: AppInfoSiteType };

export const appInfoReducer = (state: AppInfo | null, action: AppInfoAction): AppInfo | null => {
  switch (action.type) {
    case AppInfoActionType.adminProviderInfoLoaded:
      return {
        ...state,
        muiVariant: ThemeVariant.filled,
        adminProviderInfo: action.adminProviderInfo,
      } as AppInfo;
    case AppInfoActionType.changePage: {
      let currentArea = action.location.split("/")[1];
      if (currentArea == "admin") {
        currentArea = "admin/" + action.location.split("/")[2];
      }
      return {
        ...state,
        currentPageTitle: action.title,
        currentArea: "/" + currentArea,
      } as AppInfo;
    }
    case AppInfoActionType.changeMuiVariant:
      return {
        ...state,
        muiVariant: action.variant,
      } as AppInfo;
    case AppInfoActionType.showServerCommunicationError:
      return {
        showServerCommunicationError: true,
      } as AppInfo;
    case AppInfoActionType.developShowLinesBoxes:
      return {
        ...state,
        developShowLinesBoxes: action.show,
      } as AppInfo;
    case AppInfoActionType.requestedExportListOpen:
      return {
        ...state,
        requestedExportListIsOpen: action.open,
      } as AppInfo;
    case AppInfoActionType.setSiteType:
      return {
        ...state,
        siteType: action.siteType,
      } as AppInfo;
    default:
      return state;
  }
};

const AppInfoProvider: React.FC<
  PropsWithChildren<{
    children: any;
  }>
> = ({ children }: any) => {
  const [appInfo, dispatchAppInfo] = useReducer(appInfoReducer, getInitialState());
  const [apiPublicAdminProvider] = useApiPublicAdminProviderInfo();

  useEffect(() => {
    const loadPubicResources = async () => {
      try {
        const adminProviderInfo = await apiPublicAdminProvider.getAdminProviderInfo({
          preventNotifications: true,
        });
        dispatchAppInfo({
          type: AppInfoActionType.adminProviderInfoLoaded,
          adminProviderInfo: adminProviderInfo,
        });
      } catch {
        if (AppEnvironment.ENVIRONMENT === "Development") {
          const simulatedAdminProviderInfo = new AdminProviderInfoDto();
          simulatedAdminProviderInfo.settings = new AdminProviderSettingsDto();
          dispatchAppInfo({
            type: AppInfoActionType.adminProviderInfoLoaded,
            adminProviderInfo: simulatedAdminProviderInfo,
          });
          return;
        }
        dispatchAppInfo({
          type: AppInfoActionType.showServerCommunicationError,
        });
      }
    };
    loadPubicResources();
  }, []);

  return (
    <AppInfoContext.Provider value={{ appInfo, dispatchAppInfo }}>
      {appInfo?.showServerCommunicationError && <ServerCommunicationError />}
      {appInfo?.adminProviderInfo && children}
    </AppInfoContext.Provider>
  );
};

export { AppInfoContext, AppInfoProvider };
