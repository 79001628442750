import { useEffect, useState } from "react";
import { AdminTemplateDto } from "../../api/admin/dtos/AdminTemplateDto";
import { TemplateConfigDto } from "../../api/app/dtos/TemplateConfigDto";
import { TemplateGroup } from "../../api/shared/enums/TemplateGroup";
import { TemplateType } from "../../api/shared/enums/TemplateType";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import Column from "../../shared/components/Layout/GColumn";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import Section from "../../shared/components/Layout/GSection";
import Page from "../../shared/components/Layout/Page";
import ModalDialog from "../../shared/components/Modal/ModalDialog";
import { useApiTemplateConfig } from "../../api/shared/hooks/useApiApp";
import { useModal } from "../../shared/hooks/useModal";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { useNotifier } from "../../shared/hooks/useNotifier";
import TemplateConfigType from "./TemplateConfig.Type";

const TemplateConfig = () => {
  const [templateConfigModel, setTemplateConfigModel] = useState<any>();
  const [apiTemplateConfig] = useApiTemplateConfig();
  const notifier = useNotifier();
  const navigation = useNavigation();
  const [modal] = useModal();

  const save = async () => {
    const templateConfigList: TemplateConfigDto[] = [];

    Object.keys(templateConfigModel).forEach((key) => {
      const templateConfigDto = templateConfigModel[key];
      templateConfigList.push(templateConfigDto);
    });

    await apiTemplateConfig.update(templateConfigList);
    notifier.showSuccessFast("La configuración se actualizó con éxito");
    navigation.back();
  };

  const confirmSave = () => {
    modal.open(
      <ModalDialog text="¿Estás seguro de actualizar la configuración de las plantillas?" />,
      save
    );
  };

  useEffect(() => {
    const load = async () => {
      const list = await apiTemplateConfig.getAll();
      const model: any = {};
      list.forEach((c) => {
        model[c.templateType] = c;
      });
      setTemplateConfigModel(model);
    };
    load();
  }, []);

  const setConfig = (templateType: TemplateType, template: AdminTemplateDto) => {
    const templateConfig = new TemplateConfigDto();
    templateConfig.templateType = templateType;
    templateConfig.templateId = template?.id;
    templateConfig.isGlobal = template?.isGlobal;

    setTemplateConfigModel({
      ...templateConfigModel,
      [templateType]: templateConfig,
    });
  };

  if (!templateConfigModel) {
    return <></>;
  }

  return (
    <Page title="Configuración de plantillas">
      <Row>
        <Section title="Ventas">
          <Column>
            <TemplateConfigType
              label="PDF"
              templateConfig={templateConfigModel[TemplateType.PdfSaleInvoice]}
              templateGroup={TemplateGroup.Pdf}
              templateType={TemplateType.PdfSaleInvoice}
              onChange={(value: AdminTemplateDto) => setConfig(TemplateType.PdfSaleInvoice, value)}
            />
          </Column>
          <Column>
            <TemplateConfigType
              label="Correo para contacto"
              templateConfig={templateConfigModel[TemplateType.EmailSaleInvoiceToContact]}
              templateGroup={TemplateGroup.Email}
              templateType={TemplateType.EmailSaleInvoiceToContact}
              onChange={(value: AdminTemplateDto) =>
                setConfig(TemplateType.EmailSaleInvoiceToContact, value)
              }
            />
          </Column>
        </Section>
      </Row>
      <RowButtons preventValidation={true}>
        <ButtonAccept onClick={confirmSave} />
      </RowButtons>
    </Page>
  );
};

export default TemplateConfig;
