import { createTheme } from "@mui/material";
import { AppThemePalette } from "./AppThemePalette";

export function loginMobileCreateThemeForAdminProvider(palette: AppThemePalette) {
  return createTheme({
    palette: {
      primary: {
        main: palette.primaryContrast,
      },
      secondary: {
        main: palette.primary,
      },
      error: {
        main: palette.black,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: palette.primary,
            borderRadius: 24,
            fontSize: 18,
            boxShadow: "none",
            "&:focus": {
              boxShadow: "none",
            },
          },
          textPrimary: {
            color: palette.primaryContrast,
            textTransform: "none",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: 24,
            color: palette.white,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            "&.Mui-error": {
              marginLeft: "15px!important",
            },
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            background: palette.primary,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: palette.white,
            fontSize: 24,
            borderRadius: 24,
            "&.Mui-error": {
              borderColor: palette.black,
            },
          },
          notchedOutline: {
            borderColor: palette.white,
          },
        },
      },
    },
  });
}
