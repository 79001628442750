import { CSSProperties } from "react";
import { ISaleLineRetentionColumnRenderProps } from "./SaleFormLines";

const SaleLineRetentionColumn = (props: ISaleLineRetentionColumnRenderProps) => {
  const style: CSSProperties = {
    flex: props.saleLineRetentionColumn.width,
    textAlign: props.saleLineRetentionColumn.align,
  };

  return <div style={style}>{props.saleLineRetentionColumn.render(props)}</div>;
};

export default SaleLineRetentionColumn;
