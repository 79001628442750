import { useState } from "react";
import { AuthForgotPasswordDto } from "../../api/app/dtos/AuthForgotPasswordDto";
import { AppRoute } from "../../AppRoutes";
import { useApiAuth } from "../../shared/hooks/useApiAuth";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import ForgotPasswordForm from "./ForgotPassword.Form";
import LoginCard from "./Layout/LoginCard";
import { useLoginLayout } from "./Layout/LoginLayoutContext";
import LoginLink from "./Layout/LoginLink";
import LoginRow from "./Layout/LoginRow";
import LoginRowBottom from "./Layout/LoginRowBottom";
import LoginRowError from "./Layout/LoginRowError";
import LoginTitle from "./Layout/LoginTitle";
import { useForgotPasswordValidation } from "./Validations/useForgotPasswordValidation";

const ForgotPassword = () => {
  const loginLayout = useLoginLayout();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [authForgotPasswordDto] = useState(new AuthForgotPasswordDto());
  const [apiAuth] = useApiAuth();

  const sendResetEmail = async (authForgotPasswordDto: AuthForgotPasswordDto) => {
    setIsLoading(true);
    try {
      await apiAuth.sendForgotPasswordEmail(authForgotPasswordDto, {
        preventNotifications: true,
        preventSpinner: true,
      });
      loginLayout.navigateTo(AppRoute.login.forgotPasswordEmailSent);
    } catch (error: any) {
      setError(error.toString());
      setIsLoading(false);
    }
  };

  return (
    <LoginCard isLoading={isLoading}>
      <LoginTitle>Olvidé mi contraseña</LoginTitle>
      <LoginRow marginBottom={3}>
        Ingresá tu correo y te enviaremos un link para restablecer tu contraseña
      </LoginRow>

      <StateProvider
        model={authForgotPasswordDto}
        onSubmit={(authForgotPasswordDto) => {
          sendResetEmail(authForgotPasswordDto);
        }}
        validationSchema={useForgotPasswordValidation()}>
        {(p) => <ForgotPasswordForm isLoading={isLoading} onSubmit={p.handleSubmit} />}
      </StateProvider>

      <LoginRowError message={error} />
      <LoginRowBottom>
        <div>
          Ingresé por error,
          <LoginLink
            text="quiero regresar"
            onClick={() => {
              loginLayout.navigateBack();
            }}
          />
        </div>
      </LoginRowBottom>
    </LoginCard>
  );
};

export default ForgotPassword;
