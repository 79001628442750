// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { EInvoiceDailyReportCrossShardDto } from "../../app/dtos/EInvoiceDailyReportCrossShardDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { EInvoiceDailyReportsFiltersDto } from "../../app/dtos/EInvoiceDailyReportsFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiCrossShardEInvoiceDailyReport {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<EInvoiceDailyReportsFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<EInvoiceDailyReportCrossShardDto>> {
    return this.apiService.apiPost("/cross-shard/e-invoice-daily-report/paged", searchDto, apiOptions);
  }

  getById(
    adminAccountId: string,
    id: string,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceDailyReportCrossShardDto> {
    return this.apiService.apiGet("/cross-shard/e-invoice-daily-report/" + adminAccountId + "/" + id, apiOptions);
  }

}
