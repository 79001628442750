import { nameof } from "ts-simple-nameof";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import { enumName } from "../../api/shared/enums/_enumName";
import FormSelectEnum from "../../shared/components/forms/Select/FormSelectEnum";
import Row from "../../shared/components/Layout/GRow";

const SaleFormInfoInvoiceDeliveryNote = () => {
  return (
    <>
      <Row>
        <FormSelectEnum
          label="Tipo de traslado"
          enumTypeName={enumName.EInvoiceTransportationIndicatorType}
          propertyName={nameof<SaleDto>((p) => p.saleInfo.transportationIndicatorType)}
        />
      </Row>
    </>
  );
};

export default SaleFormInfoInvoiceDeliveryNote;
