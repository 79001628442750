import AdminProviderEmailConfigPopup from "../../admin/AdminProvider/AdminProviderEmailConfig/AdminProviderEmailConfigPopup";
import { AppRoute } from "../../AppRoutes";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Row from "../../shared/components/Layout/GRow";
import Section from "../../shared/components/Layout/GSection";
import Page from "../../shared/components/Layout/Page";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { useModal } from "../../shared/hooks/useModal";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { TextAlign } from "../../shared/TextAlign";
import { ThemeSize } from "../../shared/ThemeSize";

const AdminProviderHome = () => {
  const { appInfo } = useContextAppInfo();
  const navigation = useNavigation();
  const [modal] = useModal();

  return (
    <Page title={"Inicio Administración " + appInfo?.adminProviderInfo.name}>
      <Section>
        <Row>
          <ComponentGroup align={TextAlign.center}>
            <ButtonSecondary
              text="Configuración de proveedor"
              icon={TypeIcon.config}
              size={ThemeSize.medium}
              onClick={() => navigation.go(AppRoute.adminProvider.config)}
            />

            <ButtonSecondary
              text="Configuración de SMPT"
              icon={TypeIcon.config}
              size={ThemeSize.medium}
              onClick={() => modal.open(<AdminProviderEmailConfigPopup />)}
            />

            <ButtonSecondary
              text="Términos y condiciones"
              icon={TypeIcon.legal}
              size={ThemeSize.medium}
              onClick={() => navigation.go(AppRoute.adminProvider.termsAndConditions.base)}
            />

            <ButtonSecondary
              text="Aceptación de Términos y condiciones"
              tooltip="Información acerca de cada usuario y su aceptación de los términos y condiciones "
              icon={TypeIcon.config}
              size={ThemeSize.medium}
              onClick={() => navigation.go(AppRoute.adminProvider.userTermsAccepted)}
            />
          </ComponentGroup>
        </Row>
      </Section>
    </Page>
  );
};

export default AdminProviderHome;
