import { TextAlign } from "../../TextAlign";
import FormValidationSummary from "../forms/FormValidationSummary";
import ComponentGroup from "./ComponentsGroup";
import Row from "./GRow";

const RowButtons = (props: { preventValidation?: boolean; children: any }) => {
  return (
    <>
      {!props.preventValidation && <FormValidationSummary />}
      <Row minHeightIgnore={true} align={TextAlign.right}>
        <ComponentGroup align={TextAlign.right}>{props.children}</ComponentGroup>
      </Row>
    </>
  );
};

export default RowButtons;
