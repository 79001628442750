import { useEffect, useState } from "react";
import { AdminShardDto } from "../../../../api/admin/dtos/AdminShardDto";
import { useApiAdminProviderShard } from "../../../../api/shared/hooks/useApiAdminProviders";
import { IdGuidName } from "../../../IdGuidName";
import InputSelectGuid from "./InputSelectGuid";

export interface PropsInputSelectAdminShard {
  label: string;
  value: string;
  selectDefault?: boolean;
  onChange: (value: string) => void;
}

const InputSelectAdminShard = (props: PropsInputSelectAdminShard) => {
  const [apiAdminShard] = useApiAdminProviderShard();
  const [shards, setShards] = useState<AdminShardDto[]>();

  const loadShards = async () => {
    const list = await apiAdminShard.getAll();
    setShards(list);
    if (list.length > 0 && props.selectDefault && !props.value) {
      props.onChange(list[0].id);
    }
  };

  useEffect(() => {
    loadShards();
  }, []);

  return (
    <InputSelectGuid
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      options={shards?.map((c) => new IdGuidName(c.id, c.name)) ?? []}
    />
  );
};

export default InputSelectAdminShard;
