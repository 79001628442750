// File generated by Gesta.Build.PostEvents
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { AdminProviderSimpleDto } from "./AdminProviderSimpleDto";
import { UserSimpleDto } from "./UserSimpleDto";

export class UserTermAcceptedFiltersDto extends FiltersDto {
  adminProviderId: string;
  adminProvider: AdminProviderSimpleDto;
  userId: string = "";
  user: UserSimpleDto;
}
