import { nameof } from "ts-simple-nameof";
import { SaleLineDto } from "../../api/app/dtos/SaleLineDto";
import FormNumber from "../../shared/components/forms/FormNumber";
import useUtils from "../../shared/hooks/useUtils";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import { ISaleLineColumnRenderProps } from "./SaleFormLines";

const SaleLineColumnQuantity = (props: ISaleLineColumnRenderProps) => {
  const { utils } = useUtils();
  const saleModelState = useContextModelStateSale();

  if (!props.isEditing) {
    return <>{utils.number.toIntString(props.saleLine.quantity)}</>;
  }

  return (
    <>
      <FormNumber
        arrayItemHelper={props.arrayItemHelper}
        label={props.showLabel ? props.saleLineColumn.header : ""}
        propertyName={nameof<SaleLineDto>((p) => p.quantity)}
        ref={props.quantityRef}
        showZeroAsEmpty={true}
        variant={props.variant}
        onValueChange={() => saleModelState.onLineQuantityChange(props.arrayItemHelper.index)}
      />
    </>
  );
};

export default SaleLineColumnQuantity;
