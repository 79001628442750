import { useState } from "react";
import { AdminProviderDto } from "../../api/admin/dtos/AdminProviderDto";
import { AdminTermsAndConditionsDto } from "../../api/admin/dtos/AdminTermsAndConditionsDto";
import { useApiAdminProviderTermsAndConditions } from "../../api/shared/hooks/useApiAdminProviders";
import Page from "../../shared/components/Layout/Page";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { useNotifier } from "../../shared/hooks/useNotifier";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AdminTermsAndConditionsForm from "./AdminTermsAndConditionsForm";
import { useAdminTermsAndConditionsValidation } from "./Validations/useAdminTermsAndConditionsValidation";

const AdminTermsAndConditionsCreate = () => {
  const navigation = useNavigation();
  const notifier = useNotifier();
  const { appInfo } = useContextAppInfo();
  const [adminTermsAndConditions] = useState(() => {
    const adminTermsAndConditions = new AdminTermsAndConditionsDto();
    adminTermsAndConditions.adminProvider = appInfo?.adminProviderInfo ?? new AdminProviderDto();
    return adminTermsAndConditions;
  });

  const [apiAdminTermsAndConditions] = useApiAdminProviderTermsAndConditions();

  const save = async (adminTermsAndConditions: AdminTermsAndConditionsDto) => {
    await apiAdminTermsAndConditions.create(adminTermsAndConditions);
    notifier.showSuccess("Términos y condiciones creados con éxito");
    navigation.back();
  };

  if (!adminTermsAndConditions) {
    return <></>;
  }

  return (
    <Page title="Nuevos términos y condiciones">
      <StateProvider
        model={adminTermsAndConditions}
        onSubmit={save}
        validationSchema={useAdminTermsAndConditionsValidation()}>
        {(p) => <AdminTermsAndConditionsForm onSave={p.handleSubmit} />}
      </StateProvider>
    </Page>
  );
};

export default AdminTermsAndConditionsCreate;
