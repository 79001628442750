import { AppLocale } from "../../../api/shared/enums/AppLocale";
import { LocalizedUtilCurrency } from "./localizedUtilCurrency";
import { LocalizedUtilDate } from "./localizedUtilDates";
import { LocalizedUtilEnum } from "./localizedUtilEnum";
import { LocalizedUtilNumber } from "./localizedUtilNumbers";
import { LocalizedUtilBoolean } from "./localizedUtilBoolean";

export class LocalizedUtils {
  enum: LocalizedUtilEnum;
  date: LocalizedUtilDate;
  currency: LocalizedUtilCurrency;
  number: LocalizedUtilNumber;
  boolean: LocalizedUtilBoolean;
  currentAppLocale: AppLocale;

  async loadLocale(appLocale: AppLocale) {
    this.currentAppLocale = appLocale;

    this.enum = new LocalizedUtilEnum();
    await this.enum.load(appLocale);

    this.date = new LocalizedUtilDate();
    await this.date.load(appLocale);

    this.number = new LocalizedUtilNumber();
    await this.number.load(appLocale);

    this.number = new LocalizedUtilNumber();
    await this.number.load(appLocale);

    this.currency = new LocalizedUtilCurrency();
    await this.currency.load(appLocale, this.enum, this.number);

    this.boolean = new LocalizedUtilBoolean();
  }
}
