import { useContextModelState } from "./useContextModelState";

const ModelStateArray = (props: {
  propertyName: string;
  children: (arrayItem: ModelStateArrayItemHelper) => JSX.Element;
}) => {
  const modelState = useContextModelState();
  const collection = modelState.getValue(props.propertyName);

  if (!collection || collection.length == 0) {
    return <></>;
  }
  return collection.map((item: any, index: number) =>
    props.children({ collectionPropertyName: props.propertyName, item: item, index: index })
  );
};

export default ModelStateArray;

export class ModelStateArrayItemHelper {
  collectionPropertyName: string;
  item: any;
  index: number;
}
