import { newGuid } from "../utils/utilGuid";
import { cloneObject, getObjectValue } from "../utils/utilObject";
import ValidationContextRoot from "../validation/context/ValidationContextRoot";
import ValidationFieldsTrack from "../validation/context/ValidationFieldsTrack";
import {
  ModelState,
  ModelStateContextAction,
  ModelStateContextActionType,
} from "./ModelStateContext";

const modelStateContextReducer = (
  state: ModelState,
  action: ModelStateContextAction
): ModelState => {
  switch (action.type) {
    case ModelStateContextActionType.arrayItemAdd: {
      const newState = cloneObject<ModelState>(state);
      const collection = getObjectValue(newState.model, action.propertyFullName) as any[];

      if (action.insertAt === undefined) {
        collection.push(action.item);
      } else {
        collection.splice(action.insertAt, 0, action.item);
      }

      if (action.customReducer) {
        newState.model = action.customReducer(newState.model, action);
      }

      return modelStateValidate(newState);
    }
    case ModelStateContextActionType.arrayItemRemove: {
      const newState = cloneObject<ModelState>(state);
      const collection = getObjectValue(newState.model, action.propertyFullName) as any[];

      collection.splice(action.deleteAt, action.deleteCount ?? 1);

      if (action.customReducer) {
        newState.model = action.customReducer(newState.model, action);
      }

      newState.validationFieldsTrack?.removeArrayItem(
        action.propertyFullName,
        action.deleteAt,
        action.deleteCount
      );

      return modelStateValidate(newState);
    }
    case ModelStateContextActionType.arrayItemReplace: {
      const newState = cloneObject<ModelState>(state);
      const collection = getObjectValue(newState.model, action.propertyFullName) as any[];

      collection.splice(action.insertAt ?? collection.length - 1, 1);
      collection.splice(action.insertAt ?? collection.length - 1, 0, action.item);

      if (action.customReducer) {
        newState.model = action.customReducer(newState.model, action);
      }
      return modelStateValidate(newState);
    }
    case ModelStateContextActionType.custom: {
      const newState = cloneObject<ModelState>(state);
      if (!action.customReducer) {
        throw Error(
          "Custom reducer must be defined for action in order to invoke custom action types"
        );
      }
      newState.model = action.customReducer(newState.model, action);
      return modelStateValidate(newState);
    }
    case ModelStateContextActionType.init: {
      if (!action.model) {
        throw Error("Model is required to initialize ModelState");
      }

      const fieldsTrack = new ValidationFieldsTrack();

      const initState: ModelState = {
        initKey: newGuid(),
        model: action.model,
        validationSchema: action.validationSchema,
        validationFieldsTrack: fieldsTrack,
        tryFormSubmitCount: 0,
      };
      return modelStateValidate(initState);
    }
    case ModelStateContextActionType.fieldTrackInit: {
      const newState = cloneObject<ModelState>(state);
      newState.validationFieldsTrack?.init(action.propertyFullName);
      return newState;
    }
    case ModelStateContextActionType.fieldTrackSetNotTouched: {
      const newState = cloneObject<ModelState>(state);
      newState.validationFieldsTrack?.setNotTouched(action.propertyFullName);
      return modelStateValidate(newState);
    }
    case ModelStateContextActionType.fieldTrackSetTouched: {
      const newState = cloneObject<ModelState>(state);
      newState.validationFieldsTrack?.setTouched(action.propertyFullName);
      return modelStateValidate(newState);
    }
    case ModelStateContextActionType.fieldTrackSetChildrenTouched: {
      const newState = cloneObject<ModelState>(state);
      newState.validationFieldsTrack?.setChildrenAsTouched(action.propertyFullName);
      return modelStateValidate(newState);
    }
    case ModelStateContextActionType.replaceModel: {
      const newState = cloneObject<ModelState>(state);
      newState.model = action.model;
      return modelStateValidate(newState);
    }
    case ModelStateContextActionType.submit: {
      const newState = cloneObject<ModelState>(state);
      newState.tryFormSubmitCount++;
      newState.validationFieldsTrack?.incrementTrySubmitAll();
      return modelStateValidate(newState);
    }
    case ModelStateContextActionType.updateProperties: {
      const newState = cloneObject<ModelState>(state);
      action.properties.forEach((p) => updateProperty(newState, p, action));
      return modelStateValidate(newState);
    }
  }
};

export function modelStateValidate(newState: ModelState): ModelState {
  if (!newState.validationSchema) {
    return newState;
  }

  if (!newState.validationFieldsTrack) {
    throw Error("ValidationFieldsTrack can not be null when invoking modelStateValidate");
  }

  const validationContext = new ValidationContextRoot(
    newState.validationSchema,
    newState.model,
    newState.validationFieldsTrack
  );

  newState.validation = validationContext.getValidationResult(newState.tryFormSubmitCount);

  return newState;
}

function updateProperty(
  newState: ModelState,
  property: {
    propertyFullName: string;
    value: any;
    setTouched?: boolean;
    updateIdLinkedProperty?: boolean;
  },
  action: ModelStateContextAction
) {
  const propertyFullName: string = property.propertyFullName;
  const propertyBaseFullName: string = propertyFullName.substring(
    0,
    propertyFullName.lastIndexOf(".")
  );

  const baseModel: any = getObjectValue(newState.model, propertyBaseFullName);

  const propertyName = propertyFullName.substring(propertyFullName.lastIndexOf(".") + 1);

  baseModel[propertyName] = property.value;

  if (property.updateIdLinkedProperty) {
    baseModel[propertyName + "Id"] = property.value?.id;
  }

  if (property.setTouched) {
    newState.validationFieldsTrack?.setTouched(property.propertyFullName);
  }

  if (action.type == ModelStateContextActionType.updateProperties && action.customReducer) {
    action.customReducer(newState.model, action);
  }
}

export default modelStateContextReducer;
