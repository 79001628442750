import { CSSProperties, useState } from "react";
import { FileUploadExtensionsAllowed } from "../../api/shared/enums/FileUploadExtensionsAllowed";
import { LocalFolderType } from "../../api/shared/enums/LocalFolderType";
import { StorageContainerType } from "../../api/shared/enums/StorageContainerType";
import { AppEnvironment } from "../../AppEnvironment";
import { fileExtension, fileInputAccept, fileIsImage } from "../utils/utilFiles";
import ButtonIcon from "./Button/ButtonIcon";
import { ButtonSecondary } from "./Button/ButtonSecondary";
import { TypeIcon } from "./Icon";
import IconCheck from "./IconCheck";
import Img from "./Img";

export enum FileUploadType {
  ToStorageAccount = 0,
  ToStorageAdmin = 1,
  ToStorageAdminShared = 2,
  ToTempAccount = 3,
  ToTempAdmin = 4,
}

export interface PropsFileUpload {
  storageContainerType?: StorageContainerType;
  localFolderType?: LocalFolderType;
  style?: CSSProperties;
  extensionsAllowed: FileUploadExtensionsAllowed;
  uploadType: FileUploadType;
  value: string;
  onChange: (value: string) => void;
}

const FileUpload = (props: PropsFileUpload) => {
  let inputFileRef = null as any;
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [errorDescription, setErrorDescription] = useState("");

  const postFile = (file: any) => {
    let url = null;

    switch (props.uploadType) {
      case FileUploadType.ToStorageAccount:
        url = "/file-upload";
        break;
      case FileUploadType.ToStorageAdmin:
        url = "/admin/file-upload";
        break;
      case FileUploadType.ToStorageAdminShared:
        url = "/admin/file-upload/shared";
        break;
      case FileUploadType.ToTempAccount:
        url = "/file-upload/temp";
        break;
      case FileUploadType.ToTempAdmin:
        url = "/admin/file-upload/temp";
        break;
    }

    if (
      props.uploadType === FileUploadType.ToStorageAccount ||
      props.uploadType === FileUploadType.ToStorageAdmin ||
      props.uploadType === FileUploadType.ToStorageAdminShared
    ) {
      if (!props.storageContainerType) {
        throw Error(
          "Se debe especificar el valor de storageContainerType del componente para subir un archivo al storage"
        );
      }
      url = url + "?containerType=" + props.storageContainerType;
    }

    if (
      props.uploadType === FileUploadType.ToTempAccount ||
      props.uploadType === FileUploadType.ToTempAdmin
    ) {
      if (!props.localFolderType) {
        throw Error(
          "Se debe especificar el valor de localFolderType del componente para subir un archivo al storage"
        );
      }
      url = url + "?localFolderType=" + props.localFolderType;
    }

    url =
      url + "&extensionsAllowed= " + (props.extensionsAllowed ?? FileUploadExtensionsAllowed.Any);

    const apiUrl = new URL(AppEnvironment.API_BASE_URL + url);
    const formData = new FormData();
    formData.append("file", file);

    const request = new XMLHttpRequest();
    request.open("POST", apiUrl.toString());
    request.upload.addEventListener("progress", function (e) {
      setIsLoading(true);
      setProgress((e.loaded / e.total) * 100);
    });

    request.addEventListener("load", () => {
      if (request.status !== 200) {
        setError("Error subiendo el archivo");
        setErrorDescription("Error interno en el servidor");
        setIsLoading(false);
        setProgress(0);
        return;
      }
      setIsLoading(false);
      setProgress(0);
      props.onChange(request.response);
    });

    request.addEventListener("error", (error) => {
      setIsLoading(false);
      setProgress(0);
      setError("Error subiendo el archivo");
      setErrorDescription(error.toString());
    });

    request.setRequestHeader("Authorization", `Bearer ${localStorage.getItem("auth-token")}`);
    request.send(formData);
  };

  const onChangeHandler = (e: any) => {
    postFile(e.target.files[0]);
  };

  const defaultStyles: CSSProperties = {
    display: "inline-block",
    width: "100%",
  };

  const styles = { ...defaultStyles, ...props.style };

  if (isLoading) {
    return (
      <div style={{ fontSize: "1.25em" }}>
        <strong>{Math.floor(progress)}%</strong>
        {progress >= 100 && <IconCheck value={true} />}
      </div>
    );
  }

  const canPreview =
    fileIsImage(props.value) &&
    props.uploadType !== FileUploadType.ToTempAccount &&
    props.uploadType !== FileUploadType.ToTempAdmin;

  return (
    <div style={styles}>
      {props.value && !error && canPreview && (
        <Img
          src={props.value}
          style={{
            float: "left",
            maxHeight: 120,
            maxWidth: "100%",
            marginRight: 12,
          }}
        />
      )}
      {props.value && !error && !canPreview && (
        <div
          style={{
            display: "inline-block",
            border: "2px solid gray",
            borderRadius: 4,
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 6,
            paddingLeft: 6,
            fontSize: "1.25em",
            color: "gray",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(props.value);
          }}>
          {fileExtension(props.value)}
        </div>
      )}
      {props.value && <ButtonIcon icon={TypeIcon.edit} onClick={() => inputFileRef.click()} />}
      {!props.value && !error && (
        <ButtonSecondary
          text={"Subir archivo"}
          icon={TypeIcon.uploadFile}
          onClick={() => inputFileRef.click()}
        />
      )}
      {error && (
        <div style={{ color: "red" }} title={errorDescription}>
          {error}
        </div>
      )}
      <input
        type="file"
        name="file"
        accept={fileInputAccept(props.extensionsAllowed)}
        hidden
        onChange={(e) => onChangeHandler(e)}
        ref={(ref) => (inputFileRef = ref)}
      />
    </div>
  );
};

export default FileUpload;
