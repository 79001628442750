import { forwardRef } from "react";
import { TaxValueDto } from "../../../../api/app/dtos/TaxValueDto";
import InputSelectTaxValue, {
  PropsInputSelectTaxValue,
} from "../../inputs/select/InputSelectTaxValue";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

interface PropsFormSelectTaxValue
  extends PropsFormInput<TaxValueDto | null>,
    Omit<PropsInputSelectTaxValue, "value" | "onChange"> {}

const FormSelectTaxValue = forwardRef((props: PropsFormSelectTaxValue, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputSelectTaxValue {...props} {...inputProps} />;
});

export default FormSelectTaxValue;
