import { useEffect, useState } from "react";
import { LoggingConfigCategoryDto } from "../../api/admin/dtos/LoggingConfigCategoryDto";
import { LoggingLevel } from "../../api/shared/enums/LoggingLevel";
import { enumName } from "../../api/shared/enums/_enumName";
import { ButtonSecondary } from "../../shared/components/Button/ButtonSecondary";
import InfoText from "../../shared/components/InfoText";
import InputCheck from "../../shared/components/inputs/InputCheck";
import InputSelectEnum from "../../shared/components/inputs/select/InputSelectEnum";
import Column from "../../shared/components/Layout/GColumn";
import Row from "../../shared/components/Layout/GRow";
import Section from "../../shared/components/Layout/GSection";
import Page from "../../shared/components/Layout/Page";
import { useApiAdminLoggingConfig } from "../../api/shared/hooks/useApiAdmin";
import { useNotifier } from "../../shared/hooks/useNotifier";
import { TextAlign } from "../../shared/TextAlign";
import AdminLoggingCategory from "./AdminLogging.Category";
import RowButtonsToolbar from "../../shared/components/Layout/GRowButtonsToolbar";
import Button from "../../shared/components/Button/Button";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { AppRoute } from "../../AppRoutes";

const AdminLogging = () => {
  const [apiAdminLoggingConfig] = useApiAdminLoggingConfig();
  const notifier = useNotifier();
  const navigation = useNavigation();
  const [reloadPage, setReloadPage] = useState(new Date());
  const [logEfQuery, setLogEfQuery] = useState(false);
  const [defaultLoggingLevel, setDefaultLoggingLevel] = useState<LoggingLevel>();
  const [gestaAll, setGestaAll] = useState<LoggingConfigCategoryDto[]>();

  const logTests = async () => {
    await apiAdminLoggingConfig.logTest();
    notifier.showSuccessFast("Logs de pruebas enviados con éxito");
  };

  const resetAll = async () => {
    await apiAdminLoggingConfig.resetAll();
    notifier.showSuccessFast("LogLevels reiniciados con éxito");
    setReloadPage(new Date());
  };

  useEffect(() => {
    const loadLogLevels = async () => {
      setDefaultLoggingLevel((await apiAdminLoggingConfig.getDefaultLogLevel()).level);
      setGestaAll(await apiAdminLoggingConfig.getGestaAll());
      setLogEfQuery(await apiAdminLoggingConfig.getSqlQueryIsEnabled());
    };
    loadLogLevels();
  }, [reloadPage]);

  if (!defaultLoggingLevel || !gestaAll) {
    return null;
  }

  return (
    <Page title="Logging">
      <RowButtonsToolbar>
        <ButtonSecondary
          text="Test Logs"
          tooltip="Loguea una lista de logs de pruebas para diferentes categorías y diferentes niveles"
          styles={{ marginRight: 15 }}
          onClick={() => {
            logTests();
          }}
        />

        <ButtonSecondary
          text="Test Lanzar excepción"
          styles={{ marginRight: 15 }}
          onClick={() => {
            apiAdminLoggingConfig.throwTestException();
          }}
        />
        <Button text="Log Viewer" onClick={() => navigation.go(AppRoute.admin.logViewer)} />
      </RowButtonsToolbar>
      <Row>
        <Section title="Default" border={true}>
          <Row>
            <InfoText>
              Desde aquí se pueden modificar el nivel de logs por categoría y proveedor sin tener
              que reiniciar la aplicación. <br />
              Los valores definidos en la sección Logging de appSettings puede tomar precedencia si
              no se sobre escribe la categoría con el nombre completo
            </InfoText>
          </Row>
          <Column>
            <InputSelectEnum
              label="Default level"
              enumTypeName={enumName.LoggingLevel}
              value={defaultLoggingLevel}
              onChange={(value: LoggingLevel) => {
                setDefaultLoggingLevel(value);
                const request = async () => {
                  await apiAdminLoggingConfig.setDefaultLevel(value);
                };
                request();
              }}
            />
          </Column>
          <Column>
            <InputCheck
              value={logEfQuery}
              label="Loguear queries de Entity Framework"
              onChange={(value) => {
                setLogEfQuery(value);
                const request = async () => {
                  await apiAdminLoggingConfig.setSqlQueryIsEnabled(value);
                };
                request();
              }}
            />
          </Column>
        </Section>
      </Row>

      <Row>
        <Section title="Categorías de Gesta" border={true}>
          {gestaAll.map((loggingCategory, index) => (
            <AdminLoggingCategory
              key={index}
              loggingCategory={loggingCategory}
              onChange={(value) => {
                const newArr = [...gestaAll];
                newArr[index] = value;
                setGestaAll(newArr);

                const request = async () => {
                  await apiAdminLoggingConfig.setCategoryLevel(
                    loggingCategory.category,
                    value.level
                  );
                };
                request();
              }}
            />
          ))}
          <Row align={TextAlign.right}>
            <ButtonSecondary
              text="Reiniciar LogLevels"
              tooltip=""
              onClick={() => {
                resetAll();
              }}
            />
          </Row>
        </Section>
      </Row>
    </Page>
  );
};

export default AdminLogging;
