// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { Country } from "../../shared/enums/Country";
import { InvoiceRetentionType } from "../../shared/enums/InvoiceRetentionType";

export class AdminRetentionDto extends BaseEntityDto {
  country: Country;
  code: string = "";
  details: string = "";
  type: InvoiceRetentionType;
}
