import { createContext, PropsWithChildren, useContext, useReducer } from "react";
import { useNavigation } from "../../../shared/hooks/useNavigation";

type LoginLayoutInfo = {
  currentPage: string;
  isLoaded?: boolean;
  isLeavingPage?: boolean;
};

const getInitialState = (): LoginLayoutInfo => {
  return { currentPage: "" };
};

const LoginLayoutContext = createContext<{
  loginLayoutInfo: LoginLayoutInfo;
  loginLayoutDispatcher: React.Dispatch<LoginLayoutAction>;
}>({
  loginLayoutInfo: {} as LoginLayoutInfo,
  loginLayoutDispatcher: () => null,
});

export enum LoginLayoutActionType {
  navigateTo,
  notifyLoaded,
}

export type LoginLayoutAction =
  | {
      type: LoginLayoutActionType.navigateTo;
      to: string;
    }
  | {
      type: LoginLayoutActionType.notifyLoaded;
    };

export const loginLayoutReducer = (
  state: LoginLayoutInfo,
  action: LoginLayoutAction
): LoginLayoutInfo => {
  switch (action.type) {
    case LoginLayoutActionType.navigateTo:
      return {
        ...state,
        isLeavingPage: true,
        currentPage: action.to,
      };
    case LoginLayoutActionType.notifyLoaded:
      return {
        ...state,
        isLoaded: true,
        isLeavingPage: false,
      };
  }
};

const LoginLayoutProvider: React.FC<
  PropsWithChildren<{
    children: any;
  }>
> = ({ children }) => {
  const [loginLayoutInfo, loginLayoutDispatcher] = useReducer(
    loginLayoutReducer,
    getInitialState()
  );

  return (
    <LoginLayoutContext.Provider value={{ loginLayoutInfo, loginLayoutDispatcher }}>
      {children}
    </LoginLayoutContext.Provider>
  );
};

export { LoginLayoutContext, LoginLayoutProvider };

export function useLoginLayout() {
  const navigation = useNavigation();
  const { loginLayoutInfo, loginLayoutDispatcher } = useContext(LoginLayoutContext);

  const navigateTo = (url: string) => {
    loginLayoutDispatcher({ type: LoginLayoutActionType.navigateTo, to: url });
    setTimeout(() => {
      navigation.go(url);
    }, 600);
  };

  const navigateBack = (count?: number) => {
    loginLayoutDispatcher({ type: LoginLayoutActionType.navigateTo, to: "" });
    setTimeout(() => {
      navigation.back(count);
    }, 600);
  };

  const notifyPageLoaded = () => {
    loginLayoutDispatcher({ type: LoginLayoutActionType.notifyLoaded });
  };

  return { loginLayoutInfo, navigateTo, navigateBack, notifyPageLoaded };
}
