import { nameof } from "ts-simple-nameof";
import { SaleTaxDto } from "../../api/app/dtos/SaleTaxDto";
import { InvoiceTypeGroup } from "../../api/shared/enums/InvoiceTypeGroup";
import { enumName } from "../../api/shared/enums/_enumName";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormSwitch from "../../shared/components/forms/FormSwitch";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Column from "../../shared/components/Layout/GColumn";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import Section from "../../shared/components/Layout/GSection";
import { useIsMobile } from "../../shared/hooks/useIsMobile";
import useUtils from "../../shared/hooks/useUtils";
import { FlexDirection, TextAlign } from "../../shared/TextAlign";
import { AppThemeColor } from "../../styles/color";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import { SaleViewModel } from "./ViewModels/SaleViewModel";

const SaleTotals = (props: { onSave: () => void; onSaveDraft: () => void }) => {
  const modelState = useContextModelStateSale();
  const sale = modelState.sale as SaleViewModel;
  const { utils } = useUtils();
  const isMobile = useIsMobile();

  const taxes = sale.taxes.sort((saleTax1, saleTax2) => (saleTax1.tax > saleTax2.tax ? 1 : -1));
  const isReceipt = sale.invoiceTypeGroup === InvoiceTypeGroup.EResguardo;

  return (
    <Container>
      {sale.invoiceTypeGroup != InvoiceTypeGroup.ERemito && (
        <>
          <Row minHeightIgnore={true} align={TextAlign.right}>
            <FormSwitch
              style={{ fontSize: ".5em" }}
              propertyName={nameof<SaleViewModel>((p) => p.autoRounding)}
              label="Aplicar redondeo"
              onValueChange={modelState.onAutoRoundingChange}
            />
          </Row>

          <Row>
            <Section background={AppThemeColor.infoLight}>
              <Row align={TextAlign.right} minHeightIgnore={true}>
                <ComponentGroup
                  align={TextAlign.right}
                  direction={isMobile ? FlexDirection.column : FlexDirection.row}>
                  {!isReceipt && (
                    <ColumnDisplay
                      xs={12}
                      md={2}
                      label="Subtotal"
                      fontSize="1.25em"
                      minHeightIgnore={true}>
                      {utils.number.toString(sale.subtotal)}
                    </ColumnDisplay>
                  )}

                  {taxes &&
                    taxes
                      .filter((saleTax) => saleTax.amount > 0)
                      .map((saleTax: SaleTaxDto) => (
                        <ColumnDisplay
                          align={TextAlign.right}
                          fontSize="1.25em"
                          label={
                            utils.enum.translate(enumName.Tax, saleTax.tax) +
                            " " +
                            saleTax.percentage +
                            "%"
                          }
                          key={saleTax.tax}
                          minHeightIgnore={true}
                          md={2}
                          xs={12}>
                          {utils.number.toString(saleTax.amount)}
                        </ColumnDisplay>
                      ))}

                  {!!sale.totalRetentions && (
                    <ColumnDisplay label="Retenciones" md={2} minHeightIgnore={true}>
                      {utils.number.toString(sale.totalRetentions)}
                    </ColumnDisplay>
                  )}

                  {!!sale.totalFiscalCredits && (
                    <ColumnDisplay label="Créditos fiscales" md={2} minHeightIgnore={true}>
                      {utils.number.toString(sale.totalFiscalCredits)}
                    </ColumnDisplay>
                  )}

                  {!!((sale.totalNoBillable ?? 0) - (sale.rounding ?? 0)) && (
                    <ColumnDisplay label="No facturable" md={2} minHeightIgnore={true}>
                      {utils.number.toString((sale.totalNoBillable ?? 0) - (sale.rounding ?? 0))}
                    </ColumnDisplay>
                  )}

                  {!!sale.rounding && (
                    <ColumnDisplay label="Redondeo" md={2} minHeightIgnore={true}>
                      {utils.number.toString(sale.rounding)}
                    </ColumnDisplay>
                  )}

                  <ColumnDisplay
                    xs={12}
                    md={2}
                    label="Total"
                    fontSize="1.5em"
                    minHeightIgnore={true}>
                    {utils.currency.toString(sale.currency, sale.totalToPay)}
                  </ColumnDisplay>
                </ComponentGroup>
              </Row>
            </Section>
          </Row>
        </>
      )}

      <Row>
        <Container>
          <Column md={2} xs={12}>
            {/* <ButtonSecondary text="Guardar borrador" onClick={props.onSaveDraft} /> */}
          </Column>
          <Column md={10} xs={12} align={TextAlign.right}>
            <ButtonAccept onClick={props.onSave} />
          </Column>
        </Container>
      </Row>
    </Container>
  );
};

export default SaleTotals;
