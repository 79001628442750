import { nameof } from "ts-simple-nameof";
import { AdminRetentionDto } from "../../../api/admin/dtos/AdminRetentionDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

export function useAdminRetentionValidation() {
  const schema = new ValidationSchema();

  schema[nameof<AdminRetentionDto>((p) => p.country)] = validate("País").required();
  schema[nameof<AdminRetentionDto>((p) => p.code)] = validate("Código").required();
  schema[nameof<AdminRetentionDto>((p) => p.type)] = validate("Tipo").required();
  schema[nameof<AdminRetentionDto>((p) => p.details)] = validate("Detalles").required();

  return schema;
}
