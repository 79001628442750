import { forwardRef } from "react";
import { PropsInputRadio } from "../inputs/InputRadio";
import InputRadioBoolean from "../inputs/InputRadioBoolean";
import { PropsFormInput, useFormInputInit } from "./_formInputProps";

export interface PropsFormRadioBoolean
  extends PropsFormInput<boolean | null>,
    Omit<PropsInputRadio, "value" | "onChange" | "name" | "options"> {
  labelAsDefault?: string;
  showAsDefaultOption?: boolean;
}

const FormRadioBoolean = forwardRef((props: PropsFormRadioBoolean, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return (
    <InputRadioBoolean
      labelTrue={"Sí"}
      labelFalse={"No"}
      {...props}
      {...inputProps}
      allowNull={true}
      name={props.propertyName}
    />
  );
});

export default FormRadioBoolean;
