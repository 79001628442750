// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminSentEmailDto } from "../dtos/AdminSentEmailDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { AdminSentEmailFiltersDto } from "../dtos/AdminSentEmailFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminProviderSentEmail {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<AdminSentEmailFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AdminSentEmailDto>> {
    return this.apiService.apiPost("/admin-provider/sent-emails/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AdminSentEmailDto> {
    return this.apiService.apiGet("/admin-provider/sent-emails/" + id, apiOptions);
  }

}
