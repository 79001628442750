import { useEffect, useRef } from "react";

export function useClickOutside(callback: any) {
  const callbackRef = useRef();
  const innerRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };

    function handleClick(e: any) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !(innerRef.current as any).contains(e.target)
      ) {
        (callbackRef.current as any)(e);
      }
    }
  }, []);

  return innerRef;
}
