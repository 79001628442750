import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ErrorBoundary } from "react-error-boundary";
import { ApiError } from "./api/shared/apis/ApiService";
import { ClientLogDto } from "./api/shared/dtos/ClientLogDto";
import { AppEnvironment } from "./AppEnvironment";
import AppRoutes from "./AppRoutes";
import AppThemeProvider from "./AppThemeProvider";
import AdminProviderLogoLanding from "./shared/components/AdminProviderLogoLanding";
import Modal from "./shared/components/Modal/ModalContainer";
import { NotificationsProvider } from "./shared/components/Notifications/NotificationsProvider";
import { AppInfoProvider } from "./shared/context/AppInfoProvider";
import { GlobalSpinnerProvider } from "./shared/context/GlobalSpinnerProvider";
import LocaleDateProvider from "./shared/context/LocaleDateProvider";
import LocaleProvider from "./shared/context/LocaleProvider";
import { checkStoredAuthToken, LoginInfoProvider } from "./shared/context/LoginInfoProvider";
import { ModalInfoProvider } from "./shared/context/ModalProvider";
import "./styles/app-styles.scss";
//import { setUseWhatChange } from "@simbathesailor/use-what-changed";

// // This enable useWhatChange plugin for debug useEffect dependencies array check
//setUseWhatChange(process.env.NODE_ENV === "development");

const App = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorBoundaryHandler}>
    <GlobalSpinnerProvider>
      <AppInfoProvider>
        <AppThemeProvider>
          <NotificationsProvider>
            <LoginInfoProvider>
              <LocaleProvider>
                <LocaleDateProvider>
                  <ModalInfoProvider>
                    <Modal></Modal>
                    <AppRoutes />
                  </ModalInfoProvider>
                </LocaleDateProvider>
              </LocaleProvider>
            </LoginInfoProvider>
          </NotificationsProvider>
        </AppThemeProvider>
      </AppInfoProvider>
    </GlobalSpinnerProvider>
  </ErrorBoundary>
);

export default App;

const ErrorFallback = ({ error }: any) => {
  return (
    <div style={{ width: "100%", paddingTop: "3rem", textAlign: "center" }}>
      <div>
        <AdminProviderLogoLanding />
      </div>
      <h1>Oh no! Parece que encontramos un error que no esperábamos :(</h1>
      <h2>{error.message}</h2>
      <button
        style={{
          backgroundColor: "gray",
          color: "white",
          padding: "15px 30px",
          border: 0,
          borderRadius: 30,
          marginTop: 30,
          cursor: "pointer",
        }}
        onClick={() => window.location.reload()}>
        Recargar
      </button>
    </div>
  );
};

window.onunhandledrejection = (error: any) => {
  if (error.reason instanceof ApiError) {
    return;
  }

  const clientLogDto = new ClientLogDto();
  clientLogDto.errorMessage = error?.reason?.message;
  clientLogDto.stacktrace = error?.reason?.stack ?? "";
  alert(clientLogDto.errorMessage);
  logClientError(clientLogDto);
};

const errorBoundaryHandler = (error: Error, info: { componentStack: string }) => {
  const clientLogDto = new ClientLogDto();
  clientLogDto.errorMessage = error.message;
  clientLogDto.stacktrace = error.stack ?? "";
  clientLogDto.componentStack = info?.componentStack ?? "";
  logClientError(clientLogDto);
};

const logClientError = async (clientLogDto: ClientLogDto) => {
  const token = checkStoredAuthToken();
  await fetch(AppEnvironment.API_BASE_URL + "/client-logger/log-error", {
    headers: new Headers({
      authorization: `Bearer ${token}`,
      accept: "application/json",
      "content-type": "application/json",
    }),
    method: "POST",
    body: JSON.stringify(clientLogDto),
  }).catch(() => {
    // Do nothing
  });
};
