import { nameof } from "ts-simple-nameof";
import { AddressDto } from "../../../api/app/dtos/AddressDto";
import { ContactDto } from "../../../api/app/dtos/ContactDto";
import { ContactInfoDto } from "../../../api/app/dtos/ContactInfoDto";
import { ContactType } from "../../../api/shared/enums/ContactType";
import { Country } from "../../../api/shared/enums/Country";
import { LoginInfo } from "../../../shared/context/LoginInfoProvider";
import { validate } from "../../../shared/validation/schema/ValidationField";
import { validateSchema } from "../../../shared/validation/schema/ValidationFieldSchema";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

function useContactInfoAddressValidation() {
  const addressSchema = new ValidationSchema();
  addressSchema[nameof<AddressDto>((p) => p.addressLine)] = validate("dirección").required();
  addressSchema[nameof<AddressDto>((p) => p.country)] = validate("país").required();
  addressSchema[nameof<AddressDto>((p) => p.state)] = validate("departamento").required();
  addressSchema[nameof<AddressDto>((p) => p.city)] = validate("ciudad").required();

  return addressSchema;
}

function useContactInfoValidation() {
  const contactInfoSchema = new ValidationSchema();

  contactInfoSchema[nameof<ContactInfoDto>((p) => p.address)] = validateSchema(
    useContactInfoAddressValidation(),
    "Dirección"
  ).required();

  return contactInfoSchema;
}

export function useContactValidation(loginInfo: LoginInfo) {
  const contactSchema = new ValidationSchema();

  contactSchema[nameof<ContactDto>((p) => p.contactType)] = validate("Tipo de contacto");

  contactSchema[nameof<ContactDto>((p) => p.firstName)] = validate("Nombre")
    .when((contact: ContactDto) => contact.contactType == ContactType.Person)
    .required();

  contactSchema[nameof<ContactDto>((p) => p.lastName)] = validate("Apellido")
    .when((contact: ContactDto) => contact.contactType == ContactType.Person)
    .required();

  contactSchema[nameof<ContactDto>((p) => p.email)] = validate("Correo").email();

  contactSchema[nameof<ContactDto>((p) => p.name)] = validate("Nombre")
    .when((contact: ContactDto) => contact.contactType == ContactType.Company)
    .required();

  contactSchema[nameof<ContactDto>((p) => p.companyName)] = validate("Razón social")
    .when((contact: ContactDto) => contact.contactType == ContactType.Company)
    .required();

  const taxPayerValidations = validate("RUT")
    .when((contact: ContactDto) => contact.contactType == ContactType.Company)
    .required();

  if (loginInfo.authInfo.adminAccount.country == Country.Uruguay) {
    taxPayerValidations.verify(
      (taxPayerId: string) => taxPayerId?.length == 12,
      "El RUT debe tener 12 dígitos"
    );
  }

  contactSchema[nameof<ContactDto>((p) => p.taxPayerId)] = taxPayerValidations;

  contactSchema[nameof<ContactDto>((p) => p.info)] = validateSchema(
    useContactInfoValidation(),
    "Información"
  ).required();

  return contactSchema;
}
