import { useEffect, useState } from "react";
import { AdminShardDto } from "../../api/admin/dtos/AdminShardDto";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminShard } from "../../api/shared/hooks/useApiAdmin";
import { useModal } from "../../shared/hooks/useModal";

interface PropsAdminShardDetailsPopup {
  adminShardId: string;
}

const AdminShardDetailsPopup = (props: PropsAdminShardDetailsPopup) => {
  const [modal] = useModal();
  const [adminShard, setAdminShard] = useState<AdminShardDto>();
  const [apiAdminShard] = useApiAdminShard();

  useEffect(() => {
    const loadAdminShard = async () => {
      setAdminShard(await apiAdminShard.getById(props.adminShardId));
      modal.setVisible("Detalles del shard", ModalSize.small);
    };
    loadAdminShard();
  }, []);

  if (!adminShard) {
    return <></>;
  }

  return (
    <Container>
      <ColumnDisplay label="Nombre">{adminShard.name}</ColumnDisplay>
    </Container>
  );
};

export default AdminShardDetailsPopup;
