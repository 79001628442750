import { CSSProperties } from "react";
import { useAppTheme } from "../../AppThemeProvider";
import emptyImage from "../../assets/img/empty-image.png";
import { AppThemeColor } from "../../styles/color";
import { AppSize } from "../AppSize";
import Props from "./Props";

export interface PropsImage extends Props {
  alt?: string;
  color?: AppThemeColor;
  defaultImage?: any;
  disabled?: boolean;
  hideNoImage?: boolean;
  size?: AppSize;
  widthSize?: AppSize;
  src: string;
  onClick?: (e: any) => void;
}

const Img = (props: PropsImage) => {
  const size = props.size ?? AppSize.medium;

  const appTheme = useAppTheme();

  const defaultStyles: CSSProperties = {
    width: props?.widthSize ?? size,
    height: size,
    display: "inline-block",
    color: appTheme.toHexColor(props.color),
    filter: props.disabled ? "grayscale(1)" : undefined,
    stroke: "red",
  };

  const styles = { ...defaultStyles, ...props.style };

  return (
    <img
      src={props.src ? props.src : props.defaultImage ? props.defaultImage : emptyImage}
      alt={props.alt ? props.alt : ""}
      style={styles}
      className={props.className}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    />
  );
};

export default Img;
