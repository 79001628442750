import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { IdName } from "../../IdName";
import Field from "./Field";
import PropsInputBase from "./PropsInputBase";

export interface PropsInputRadio extends PropsInputBase<number | null> {
  name: string;
  options: IdName[];
  alignRow?: boolean;
}

const InputRadio = (props: PropsInputRadio) => {
  return (
    <Field childrenProps={props}>
      <RadioGroup
        value={props.value ?? ""}
        name={props.name}
        row={props.alignRow}
        onChange={(e: any) => {
          const parsedNumber = parseFloat(e.target.value);
          if (isNaN(parsedNumber)) {
            props.onChange(null);
            return;
          }
          props.onChange(parsedNumber);
        }}>
        {props.options.map((option: IdName) => (
          <FormControlLabel
            checked={option.id === props.value}
            control={<Radio />}
            disabled={props.readOnly}
            label={option.name}
            key={option.id}
            value={option.id}
            style={{ marginBottom: 4 }}
          />
        ))}
      </RadioGroup>
    </Field>
  );
};

export default InputRadio;
