import { useEffect, useState } from "react";
import { ContactDto } from "../../api/app/dtos/ContactDto";
import Column from "../../shared/components/Layout/GColumn";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiContact } from "../../api/shared/hooks/useApiApp";
import { useModal } from "../../shared/hooks/useModal";

const ContactDetailsPopup = (props: { id: string }) => {
  const [modal] = useModal();
  const [contact, setContact] = useState<ContactDto>();
  const [apiContact] = useApiContact();

  const title = "Detalles de contacto";

  useEffect(() => {
    const loadContact = async () => {
      setContact(await apiContact.getById(props.id));
      modal.setVisible(title, ModalSize.big);
    };
    loadContact();
  }, [props.id, modal, apiContact]);

  if (!contact) {
    return null;
  }

  return (
    <Container>
      <ColumnDisplay label="Nombre">{contact.name}</ColumnDisplay>
      <ColumnDisplay label="RUT">{contact.taxPayerId}</ColumnDisplay>
      <ColumnDisplay label="Id externo">{contact.openApiExternalId}</ColumnDisplay>
      <Column label="Vendedor">{contact.info.salesOwner?.email}</Column>
    </Container>
  );
};

export default ContactDetailsPopup;
