// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { TemplateConfigDto } from "../dtos/TemplateConfigDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { TemplateConfigEmailTestDto } from "../dtos/TemplateConfigEmailTestDto";

export class ApiTemplateConfig {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getAll(
    apiOptions?: ApiOptions
  ): Promise<TemplateConfigDto[]> {
    return this.apiService.apiGet("/template-config", apiOptions);
  }

  update(
    templateConfigDtos: TemplateConfigDto[],
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/template-config", templateConfigDtos, apiOptions);
  }

  pdfConfigTestDownload(
    templateType: number,
    templateId: string | null,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiGet("/template-config/pdf-config-test-download?" + 
      (templateType ? "&templateType=" + templateType : "") + 
      (templateId ? "&templateId=" + templateId : ""), apiOptions);
  }

  emailConfigSendTest(
    templateConfigEmailTestDto: TemplateConfigEmailTestDto,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/template-config/email-config-send-test", templateConfigEmailTestDto, apiOptions);
  }

}
