import { createContext, PropsWithChildren, useEffect, useReducer } from "react";
import { AppLocale } from "../../api/shared/enums/AppLocale";
import SpinnerBlock from "../components/SpinnerBlock";
import { useContextLoginInfo } from "../hooks/useContextLoginInfo";
import { LocalizedUtils } from "../utils/localizedUtil/localizedUtils";

export enum localeName {
  EsCo = "es-CO",
  EsUy = "es-UY",
  EnUs = "en-US",
}

export const appLocaleMapName: any = {
  EsCo: localeName.EsCo,
  EsUy: localeName.EsUy,
  EnUs: localeName.EnUs,
};

export enum AppLocaleStateStatus {
  PendingSet,
  PendingLoadResources,
  Done,
}

export class AppLocaleState {
  appLocale: AppLocale;
  utils: LocalizedUtils;
  status: AppLocaleStateStatus;

  constructor() {
    this.status = AppLocaleStateStatus.PendingSet;
  }
}

export enum AppLocalActionType {
  setLocale,
  loadResources,
}

export const LocaleContext = createContext<{
  appLocaleState: AppLocaleState;
  dispatchAppLocale: React.Dispatch<AppLocaleAction>;
}>({
  appLocaleState: new AppLocaleState(),
  dispatchAppLocale: () => null,
});

export type AppLocaleAction =
  | {
      type: AppLocalActionType.setLocale;
      appLocale: AppLocale;
    }
  | {
      type: AppLocalActionType.loadResources;
      localizedUtils: LocalizedUtils;
    };

export const appLocaleReducer = (
  state: AppLocaleState,
  action: AppLocaleAction
): AppLocaleState => {
  switch (action.type) {
    case AppLocalActionType.setLocale:
      return {
        ...state,
        appLocale: action.appLocale,
        status: AppLocaleStateStatus.PendingLoadResources,
      };
    case AppLocalActionType.loadResources:
      return { ...state, utils: action.localizedUtils, status: AppLocaleStateStatus.Done };
  }
};

const LocaleProvider: React.FC<
  PropsWithChildren<{
    children: any;
  }>
> = ({ children }: any) => {
  const [appLocaleState, dispatchAppLocale] = useReducer(appLocaleReducer, new AppLocaleState());
  const { loginInfo } = useContextLoginInfo();

  useEffect(() => {
    if (appLocaleState.status !== AppLocaleStateStatus.PendingSet) {
      return;
    }

    const appLocale = loginInfo.authInfo?.adminAccount?.locale ?? AppLocale.EsUy;
    dispatchAppLocale({ type: AppLocalActionType.setLocale, appLocale: appLocale });
  }, []);

  useEffect(() => {
    if (appLocaleState.status == AppLocaleStateStatus.PendingLoadResources) {
      const loadResources = async () => {
        const localizedUtils = new LocalizedUtils();
        await localizedUtils.loadLocale(appLocaleState.appLocale);
        dispatchAppLocale({
          type: AppLocalActionType.loadResources,
          localizedUtils: localizedUtils,
        });
      };
      loadResources();
    }
  }, [appLocaleState.status]);

  const loadingMessage =
    appLocaleState.status != AppLocaleStateStatus.Done
      ? "Cargando recursos.." +
        (appLocaleState.status == AppLocaleStateStatus.PendingLoadResources ? "." : "")
      : null;

  return (
    <LocaleContext.Provider value={{ appLocaleState, dispatchAppLocale }}>
      {!!loadingMessage && <SpinnerBlock label={loadingMessage} />}
      {!loadingMessage && children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
