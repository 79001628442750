import { nameof } from "ts-simple-nameof";
import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import { PurchaseSubtotalInfoDto } from "../../api/app/dtos/PurchaseSubtotalInfoDto";
import { GridColumnAlign } from "../../shared/components/grid/Grid";
import GridClientSide from "../../shared/components/grid/GridClientSide";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Row from "../../shared/components/Layout/GRow";

const PurchaseDetailsSubtotals = (props: { purchase: PurchaseDto }) => {
  const templates: IGridItemTemplate<PurchaseSubtotalInfoDto>[] = [
    { header: "Detalle", field: nameof<PurchaseSubtotalInfoDto>((p) => p.name), width: 30 },
    {
      header: "Monto",
      field: nameof<PurchaseSubtotalInfoDto>((p) => p.amount),
      cellType: GridCellType.decimal,
      align: GridColumnAlign.right,
      width: 10,
    },
  ];

  return (
    <Row>
      <GridClientSide items={props.purchase.subtotalInfo} templates={templates} />
    </Row>
  );
};

export default PurchaseDetailsSubtotals;
