import { ReactNode } from "react";
import { nameof } from "ts-simple-nameof";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import ButtonLink from "../../shared/components/Button/ButtonLink";
import FormSwitch from "../../shared/components/forms/FormSwitch";
import { TypeIcon } from "../../shared/components/Icon";
import Row from "../../shared/components/Layout/GRow";
import { useIsMobile } from "../../shared/hooks/useIsMobile";
import ModelStateArray, {
  ModelStateArrayItemHelper,
} from "../../shared/modelState/ModelStateArray";
import { TextAlign } from "../../shared/TextAlign";
import { ThemeVariant } from "../../shared/ThemeVariant";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import SaleFormLineRow from "./SaleFormLines.Row";
import SaleFormReferenceColumnButtons from "./SaleFormReferenceColumn.Buttons";
import SaleFormReferenceColumnDate from "./SaleFormReferenceColumn.Date";
import SaleFormReferenceColumnDocumentType from "./SaleFormReferenceColumn.DocumentType";
import SaleFormReferenceColumnNumber from "./SaleFormReferenceColumn.Number";
import SaleFormReferenceColumnReason from "./SaleFormReferenceColumn.Reason";
import SaleFormReferenceColumnSerie from "./SaleFormReferenceColumn.Serie";
import SaleFormReferenceHeader from "./SaleFormReferences.Header";
import SaleFormReferencesItem from "./SaleFormReferences.Item";
import { getDefaultReference, getEmptyReference } from "./SalePage";
import { SaleViewModel } from "./ViewModels/SaleViewModel";

export interface ISaleReferenceColumn {
  type: SaleLineReferenceColumnType;
  header: string;
  width: number;
  align?: TextAlign;
  render: (props: ISaleReferenceColumnRenderProps) => ReactNode;
}

export interface ISaleReferenceColumnRenderProps {
  arrayItemHelper: ModelStateArrayItemHelper;
  saleReferenceColumn: ISaleReferenceColumn;
  showLabel: boolean;
  variant: ThemeVariant;
}

export enum SaleLineReferenceColumnType {
  buttons,
  documentType,
  date,
  number,
  reason,
  serie,
}

const SaleFormReferences = () => {
  const saleModelState = useContextModelStateSale();
  const isMobile = useIsMobile();

  const changeRetentionsAddDefault = (value: boolean) => {
    saleModelState.referenceRemoveAll();
    if (value) {
      saleModelState.referenceAdd(getDefaultReference());
    } else {
      saleModelState.referenceAdd(getEmptyReference());
    }
  };

  if (!saleModelState.sale.enableReferenceTable) {
    return <></>;
  }

  return (
    <Row>
      <h2>Referencias</h2>
      {(saleModelState.sale.retentionsAddDefault || !saleModelState.sale.references.length) && (
        <FormSwitch
          label="Incluir referencia por defecto"
          propertyName={nameof<SaleViewModel>((p) => p.retentionsAddDefault)}
          onValueChange={(value) => changeRetentionsAddDefault(value)}
        />
      )}
      {saleModelState.sale.references.length > 0 && !saleModelState.sale.retentionsAddDefault && (
        <>
          <SaleFormLineRow>
            {referenceColumns.map((saleReferenceColumn, index) => (
              <SaleFormReferenceHeader key={index} saleReferenceColumn={saleReferenceColumn} />
            ))}
          </SaleFormLineRow>
          <ModelStateArray propertyName={nameof<SaleDto>((p) => p.references)}>
            {(arrayItemHelper) => (
              <SaleFormReferencesItem
                key={arrayItemHelper.index}
                arrayItemHelper={arrayItemHelper}
                showLabel={isMobile}
                referenceColumns={referenceColumns}
                variant={isMobile ? ThemeVariant.filled : ThemeVariant.outlined}
              />
            )}
          </ModelStateArray>
        </>
      )}

      {!saleModelState.sale.retentionsAddDefault && (
        <div style={{ width: "100%", textAlign: TextAlign.right, marginBottom: 20 }}>
          <ButtonLink
            icon={TypeIcon.plus}
            text="agregar referencia"
            onClick={() => saleModelState.referenceAdd(getEmptyReference())}
          />
        </div>
      )}
    </Row>
  );
};

export default SaleFormReferences;

const referenceColumns: ISaleReferenceColumn[] = [
  {
    header: "Tipo de documento",
    type: SaleLineReferenceColumnType.documentType,
    width: 25,
    render: (props) => <SaleFormReferenceColumnDocumentType {...props} />,
  },
  {
    header: "Serie",
    type: SaleLineReferenceColumnType.serie,
    width: 10,
    render: (props) => <SaleFormReferenceColumnSerie {...props} />,
  },
  {
    header: "Número",
    type: SaleLineReferenceColumnType.number,
    width: 10,
    render: (props) => <SaleFormReferenceColumnNumber {...props} />,
  },
  {
    header: "Fecha",
    type: SaleLineReferenceColumnType.date,
    width: 20,
    render: (props) => <SaleFormReferenceColumnDate {...props} />,
  },
  {
    header: "Razón",
    type: SaleLineReferenceColumnType.reason,
    width: 50,
    render: (props) => <SaleFormReferenceColumnReason {...props} />,
  },
  {
    header: "",
    type: SaleLineReferenceColumnType.buttons,
    align: TextAlign.right,
    width: 5,
    render: (props) => <SaleFormReferenceColumnButtons {...props} />,
  },
];
