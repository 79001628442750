import { ReactElement } from "react";
import Grid from "./Grid";
import { IGridItemTemplate } from "./ItemTemplate/IGridItemTemplate";
import { GridMode } from "./PropsGrid";

export interface PropsGridClientSide<T> {
  items?: T[];
  templates: IGridItemTemplate<T>[];
  pageSize?: number;
  hideFooter?: boolean;
  onReload?(): void;
  onExpandRowShouldDisableButton?(item: T): boolean;
  onExpandRow?(item: T): Promise<ReactElement>;
}

const GridClientSide = (props: PropsGridClientSide<any>) => {
  return (
    <Grid
      clientSideItems={props.items}
      itemTemplates={props.templates}
      hideIncludeInactiveButton={true}
      hideFooter={props.hideFooter}
      mode={GridMode.ClientSide}
      preventShowDefaultSearch={true}
      pageSize={props.pageSize}
      onClientSideReload={props.onReload}
      onExpandRow={props.onExpandRow}
      onExpandRowShouldDisableButton={props.onExpandRowShouldDisableButton}
    />
  );
};

export default GridClientSide;
