// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiCurrency {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getAll(
    apiOptions?: ApiOptions
  ): Promise<number[]> {
    return this.apiService.apiGet("/currencies", apiOptions);
  }

  getForConfigMode(
    configCurrencyMode: number,
    apiOptions?: ApiOptions
  ): Promise<number[]> {
    return this.apiService.apiGet("/currencies/for-config-mode?" + 
      (configCurrencyMode ? "&configCurrencyMode=" + configCurrencyMode : ""), apiOptions);
  }

}
