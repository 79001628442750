import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { RequestedExportDto } from "../../../api/app/dtos/RequestedExportDto";
import { RequestedExportFiltersDto } from "../../../api/app/dtos/RequestedExportFiltersDto";
import { RequestedExportStatus } from "../../../api/shared/enums/RequestedExportStatus";
import { useApiRequestedExport } from "../../../api/shared/hooks/useApiApp";
import { AppSize } from "../../../shared/AppSize";
import ButtonIconFileDownload from "../../../shared/components/Button/ButtonIconFileDownload";
import Grid, { GridColumnAlign } from "../../../shared/components/grid/Grid";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Icon, { TypeIcon } from "../../../shared/components/Icon";
import IconError from "../../../shared/components/IconError";
import Page from "../../../shared/components/Layout/Page";
import Spinner from "../../../shared/components/Spinner";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import { useNotifier } from "../../../shared/hooks/useNotifier";
import { AppThemeColor } from "../../../styles/color";
import { getRequestedReportListItemMenu } from "./RequestedExportList.ItemMenu";

const RequestedExportList = () => {
  const [initialFilters] = useState(new RequestedExportFiltersDto());
  const [apiRequestedExport] = useApiRequestedExport();
  const [reload, setReload] = useState(new Date());
  const navigation = useNavigation();
  const notifier = useNotifier();

  const itemTemplates: Array<IGridItemTemplate<RequestedExportDto>> = [
    {
      header: "Fecha",
      field: nameof<RequestedExportDto>((p) => p.requestedDate),
      cellType: GridCellType.dateTime,
      // filter: {
      //   propertyName: nameof<RequestedExportDto>((p) => p.name),
      //   type: GridFilterType.text,
      // },
      width: 30,
    },
    {
      field: nameof<RequestedExportDto>((p) => p.requestedFileName),
      header: "Nombre",
      width: 25,
    },
    {
      field: nameof<RequestedExportDto>((p) => p.requestedBy.email),
      header: "Solicitado por",
      width: 25,
    },
    {
      field: nameof<RequestedExportDto>((p) => p.fileUrl),
      header: "Archivo",
      orderByPrevent: true,
      width: 5,
      align: GridColumnAlign.center,
      render: (item: RequestedExportDto) => {
        if (item.fileUrl) {
          return <ButtonIconFileDownload fileUrl={item.fileUrl} noPadding={true} />;
        }
        if (item.status == RequestedExportStatus.Error) {
          return <IconError tooltip={item.errorMessage} />;
        }
        if (item.status == RequestedExportStatus.Canceled) {
          return <Icon type={TypeIcon.deleteCross} color={AppThemeColor.gray} />;
        }
        return <Spinner size={AppSize.small} />;
      },
    },
  ];

  return (
    <Page title="Archivos exportados">
      <Grid
        itemTemplates={itemTemplates}
        initialFilters={initialFilters}
        reloadGrid={reload}
        defaultSortBy={[
          { columnName: nameof<RequestedExportDto>((p) => p.requestedDate), asc: false },
        ]}
        mustIncludeFields={[
          nameof<RequestedExportDto>((p) => p.fileUrl),
          nameof<RequestedExportDto>((p) => p.status),
          nameof<RequestedExportDto>((p) => p.errorMessage),
        ]}
        onSearch={(search, options) => apiRequestedExport.getPaged(search, options)}
        onDisplayItemMenu={(contact: RequestedExportDto) =>
          getRequestedReportListItemMenu(
            contact,
            navigation,
            apiRequestedExport,
            notifier,
            setReload
          )
        }
      />
    </Page>
  );
};

export default RequestedExportList;
