// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { EmailConfigType } from "../../shared/enums/EmailConfigType";
import { EmailConfigValidationStatus } from "../../shared/enums/EmailConfigValidationStatus";

export class EmailConfigDto extends BaseEntityDto {
  smtpServer: string = "";
  smtpPort: number = 0;
  smtpSsl: boolean = false;
  imapServer: string = "";
  imapPort: number = 0;
  imapSsl: boolean = false;
  monitorLastRead: Date | null = null;
  monitorLastUIRead: number = 0;
  password: string = "";
  type: EmailConfigType;
  user: string = "";
  useOAuth: boolean = false;
  validationStatus: EmailConfigValidationStatus;
  validationToken: string = "";
}
