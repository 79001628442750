import { addMonths } from "date-fns";
import { useEffect, useState } from "react";
import { AdminCertifiedProviderReportGenerateDto } from "../../../api/admin/dtos/AdminCertifiedProviderReportGenerateDto";
import { DateOnlyDto } from "../../../api/shared/dtos/DateOnlyDto";
import { Country } from "../../../api/shared/enums/Country";
import { useApiAdminCertifiedProviderReport } from "../../../api/shared/hooks/useApiAdmin";
import { toDateOnly } from "../../../api/shared/utils/apiUtilsDate";
import Button from "../../../shared/components/Button/Button";
import InputDateMonth from "../../../shared/components/inputs/InputDateMonth";
import InputSelectCountry from "../../../shared/components/inputs/select/InputSelectCountry";
import Container from "../../../shared/components/Layout/GContainer";
import Row from "../../../shared/components/Layout/GRow";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import { ModalSize } from "../../../shared/context/ModalProvider";
import { useModal } from "../../../shared/hooks/useModal";

const AdminCertifiedProviderReportDetailsPopup = () => {
  const [modal] = useModal();
  const [apiAdminCertifiedProviderReport] = useApiAdminCertifiedProviderReport();
  const [generateDto, setGenerateDto] = useState<AdminCertifiedProviderReportGenerateDto>(() => {
    const dto = new AdminCertifiedProviderReportGenerateDto();
    dto.country = Country.Uruguay;
    const date = addMonths(new Date(), -1);
    date.setDate(1);
    dto.dateStart = toDateOnly(date);
    return dto;
  });

  const generate = async () => {
    if (!generateDto.country || !generateDto.dateStart) {
      return;
    }

    const report = await apiAdminCertifiedProviderReport.generate(generateDto);
    modal.closeAsSuccess(report, "El reporte se generó con éxito");
  };

  useEffect(() => {
    modal.setVisible("Generar reporte de proveedor habilitado", ModalSize.small);
  }, [modal]);

  return (
    <Container>
      <Row>
        <InputSelectCountry
          label="País"
          onlyAvailable={true}
          value={generateDto.country}
          onChange={(country: Country) => setGenerateDto({ ...generateDto, country: country })}
        />
      </Row>
      <Row>
        <InputDateMonth
          label="Mes"
          value={generateDto.dateStart}
          onChange={(dateStart) =>
            setGenerateDto({ ...generateDto, dateStart: dateStart ?? new DateOnlyDto() })
          }
        />
      </Row>
      <RowButtons>
        <Button
          text="Generar reporte"
          disabled={!generateDto.country || !generateDto.dateStart}
          onClick={generate}
        />
      </RowButtons>
    </Container>
  );
};

export default AdminCertifiedProviderReportDetailsPopup;
