// File generated by: Gesta.Build.PostEvents
export enum LocalFolderType {
	ContentRoot = 0,
	Temp = 1,
	TempCertificates = 3,
	ContentTemplateDefaults = 4,
	TempEInvoices = 5,
	TempEInvoiceAuthorizations = 6,
	TempEInvoiceDailyReports = 7,
	TempEInvoiceEnvelopes = 8,
	TempCompanySentEnvelopes = 9,
	TempPdf = 10,
	TempPdfImageCache = 11,
	TempEInvoicePurchases = 12,
	TempEInvoiceEnvelopesAck = 13,
	TempExcel = 14,
	TempTest = 15,
	TempPublic = 1000,
}
