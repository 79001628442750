import { useState } from "react";
import { Currency } from "../../../../api/shared/enums/Currency";
import { enumName } from "../../../../api/shared/enums/_enumName";
import { useContextLoginInfo } from "../../../hooks/useContextLoginInfo";
import useUtils from "../../../hooks/useUtils";
import { IdName } from "../../../IdName";
import { PropsInputSelectBase } from "./InputSelectBase";
import InputSelectEnumBase from "./InputSelectEnumBase";

export interface PropsInputSelectCurrency
  extends Omit<PropsInputSelectBase<Currency | null>, "options"> {
  allCurrencies?: boolean;
}

const InputSelectCurrency = (props: PropsInputSelectCurrency) => {
  const { utils } = useUtils();
  const { loginInfo } = useContextLoginInfo();

  const allCurrencies = Object.keys(Currency)
    .map((k) => Number(k))
    .filter((c) => !!c);

  const [currencyOptions] = useState(() => {
    const currencies: IdName[] = [];

    const currencyList = props.allCurrencies
      ? allCurrencies
      : loginInfo.authInfo.accountConfig.currencies;

    for (const currency of currencyList) {
      currencies.push(
        new IdName(
          currency,
          utils.enum.translate(enumName.Currency, currency) +
            " - " +
            utils.enum.translate(enumName.CurrencyName, currency)
        )
      );
    }
    return currencies;
  });

  if (!currencyOptions) {
    return null;
  }

  return <InputSelectEnumBase {...props} options={currencyOptions} />;
};

export default InputSelectCurrency;
