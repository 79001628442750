import { useAppTheme } from "../../AppThemeProvider";
import { AppThemeColor } from "../../styles/color";
import { AppSize } from "../AppSize";
import Icon, { TypeIcon } from "./Icon";

export enum InfoTextType {
  info = 1,
  warning = 2,
  error = 3,
}

const InfoText = (props: { type?: InfoTextType; children: any }) => {
  const appTheme = useAppTheme();

  let backgroundColor = appTheme.toHexColor(AppThemeColor.infoLight);
  let fontColor = AppThemeColor.gray;
  let icon = TypeIcon.info;

  if (props.type == InfoTextType.error) {
    backgroundColor = appTheme.toHexColor(AppThemeColor.alertLight);
    fontColor = AppThemeColor.black;
    icon = TypeIcon.error;
  } else if (props.type == InfoTextType.warning) {
    backgroundColor = appTheme.toHexColor(AppThemeColor.warningLight);
    icon = TypeIcon.warning;
  }

  return (
    <div
      style={{
        position: "relative",
        textAlign: "justify",
        whiteSpace: "break-spaces",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: ".8rem",
        color: appTheme.toHexColor(fontColor),
        backgroundColor: backgroundColor,
        borderRadius: 6,
        padding: 15,
        paddingLeft: 30,
      }}>
      <Icon
        size={AppSize.small}
        color={fontColor}
        type={icon}
        style={{ position: "absolute", left: 5, top: 5 }}
      />
      {props.children}
    </div>
  );
};

export default InfoText;
