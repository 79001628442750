import TabPanel from "@mui/lab/TabPanel";
import Container from "./GContainer";

const TabView = (props: { value: number | string; children: any }) => {
  return (
    <TabPanel value={props.value.toString()} style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Container>{props.children}</Container>
    </TabPanel>
  );
};

export default TabView;
