import { useEffect, useState } from "react";
import { ContactDto } from "../../../api/app/dtos/ContactDto";
import { EInvoiceEnvelopePurchaseProcessUploadedDto } from "../../../api/app/dtos/EInvoiceEnvelopePurchaseProcessUploadedDto";
import { EmailConfigDto } from "../../../api/app/dtos/EmailConfigDto";
import { ContactFilterGroup } from "../../../api/shared/enums/ContactFilterGroup";
import { EmailConfigType } from "../../../api/shared/enums/EmailConfigType";
import { FileUploadExtensionsAllowed } from "../../../api/shared/enums/FileUploadExtensionsAllowed";
import {
  useApiEInvoiceEnvelopePurchase,
  useApiEmailConfig,
} from "../../../api/shared/hooks/useApiApp";
import { AppRoute } from "../../../AppRoutes";
import Button from "../../../shared/components/Button/Button";
import ButtonAccept from "../../../shared/components/Button/ButtonAccept";
import FileImport from "../../../shared/components/FileImport";
import InfoText, { InfoTextType } from "../../../shared/components/InfoText";
import InputAutocompleteContact from "../../../shared/components/inputs/autocomplete/InputAutocompleteContact";
import InputText from "../../../shared/components/inputs/InputText";
import Container from "../../../shared/components/Layout/GContainer";
import Row from "../../../shared/components/Layout/GRow";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import { ModalSize } from "../../../shared/context/ModalProvider";
import { useModal } from "../../../shared/hooks/useModal";
import { useNavigation } from "../../../shared/hooks/useNavigation";

const EInvoiceEnvelopePurchaseUploadPopup = () => {
  const [modal] = useModal();
  const [apiEmailConfig] = useApiEmailConfig();
  const [apiEInvoiceEnvelopePurchase] = useApiEInvoiceEnvelopePurchase();
  const [loaded, setLoaded] = useState(false);
  const [emailConfig, setEmailConfig] = useState<EmailConfigDto>();
  const [model, setModel] = useState(new EInvoiceEnvelopePurchaseProcessUploadedDto());
  const [contact, setContact] = useState<ContactDto | null>(null);
  const navigation = useNavigation();

  useEffect(() => {
    const load = async () => {
      setEmailConfig(await apiEmailConfig.getByType(EmailConfigType.EInvoice));
      setLoaded(true);
    };
    load();
    modal.setVisible("Importar CFE", ModalSize.small);
  }, []);

  const accept = async () => {
    try {
      const processResponse = await apiEInvoiceEnvelopePurchase.uploadedProcess(model);
      modal.closeAsSuccess(
        model,
        "Comprobantes importados con éxito: " + processResponse.eInvoicesCount
      );
    } catch {
      modal.closeAsCancel();
    }
  };

  if (!loaded) {
    return <></>;
  }

  if (!emailConfig) {
    return (
      <Container>
        <Row>
          <InfoText type={InfoTextType.error}>
            Se debe configurar el correo electrónico para facturación electrónica
          </InfoText>
        </Row>
        <RowButtons>
          <Button
            text="Configurar correo"
            onClick={() => navigation.newTab(AppRoute.configuration.email.base)}
          />
        </RowButtons>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <InfoText>
          Los proveedores que son emisores electrónicos deben mandar los comprobantes por correo a
          la casilla configurada <strong>{emailConfig.user}</strong>, utilizar esta funcionalidad
          únicamente para solucionar problemas de comunicación entre los servidores de correo
        </InfoText>
      </Row>
      <Row>
        <InputAutocompleteContact
          label="Proveedor"
          contactFilterGroup={ContactFilterGroup.Providers}
          value={contact}
          onChange={(contact: ContactDto) => {
            setContact(contact);
            setModel({
              ...model,
              ackEmailTo: contact?.eInvoiceEmail,
            });
          }}
        />
      </Row>
      <Row>
        <InputText
          label="Correo para acuse de recibo"
          readOnly={!!contact}
          value={model.ackEmailTo}
          onChange={(value) => setModel({ ...model, ackEmailTo: value })}
        />
      </Row>
      <RowButtons>
        <FileImport
          endpointUrl="/e-invoice-envelopes-purchase/upload"
          extensionsAllowed={FileUploadExtensionsAllowed.Xml}
          onUploadComplete={(response) => {
            setModel({ ...model, xmlUrl: response.xmlUrl });
          }}
        />
      </RowButtons>
      <RowButtons>
        <ButtonAccept disabled={!model.ackEmailTo || !model.xmlUrl} onClick={accept} />
      </RowButtons>
    </Container>
  );
};

export default EInvoiceEnvelopePurchaseUploadPopup;
