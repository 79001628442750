import { useEffect, useState } from "react";
import { ExportGridColumnOption } from "../../../api/shared/enums/ExportGridColumnOption";
import { GridName } from "../../../api/shared/enums/GridName";
import { enumName } from "../../../api/shared/enums/_enumName";
import { ModalSize } from "../../context/ModalProvider";
import { useModal } from "../../hooks/useModal";
import useUtils from "../../hooks/useUtils";
import Button from "../Button/Button";
import InfoText from "../InfoText";
import InputRadioEnum from "../inputs/InputRadioEnum";
import InputText from "../inputs/InputText";
import Container from "../Layout/GContainer";
import Row from "../Layout/GRow";
import RowButtons from "../Layout/GRowButtons";

const GridExportPopup = (props: {
  totalItemCount: number;
  gridName: GridName;
  onExport(columnOption: ExportGridColumnOption, fileName: string): void;
}) => {
  const { utils } = useUtils();
  const [modal] = useModal();
  const [fileName, setFileName] = useState(
    utils.enum.translate(enumName.GridName, props.gridName) +
      "_" +
      utils.date.toFileNameString(new Date()) +
      ".xlsx"
  );
  const [columnOption, setColumnOption] = useState<ExportGridColumnOption | null>(
    ExportGridColumnOption.SameAsGrid
  );

  useEffect(() => {
    modal.setVisible("Exportar", ModalSize.small);
  }, [modal]);

  return (
    <Container>
      <Row>
        <InfoText>
          Registros a exportar: <strong>{utils.number.toIntString(props.totalItemCount)}</strong>
        </InfoText>
      </Row>
      <Row>
        <InputRadioEnum
          name="columnOption"
          value={columnOption}
          onChange={setColumnOption}
          enumTypeName={enumName.ExportGridColumnOption}
        />
      </Row>
      <Row>
        <InputText label="Nombre del archivo" value={fileName} onChange={setFileName} />
      </Row>

      <RowButtons>
        <Button
          text="Exportar"
          disabled={columnOption == ExportGridColumnOption.Custom}
          onClick={() => {
            if (!columnOption) {
              return;
            }

            modal.closeAsSuccess(null, "");
            props.onExport(columnOption, fileName);
          }}
        />
      </RowButtons>
    </Container>
  );
};

export default GridExportPopup;
