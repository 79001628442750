// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ProductDto } from "../dtos/ProductDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { ProductFiltersDto } from "../dtos/ProductFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { ProductSimpleDto } from "../dtos/ProductSimpleDto";
import { SuggestResponseDto } from "../../shared/dtos/SuggestResponseDto";
import { SuggestSearchDto } from "../../shared/dtos/SuggestSearchDto";

export class ApiProduct {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    productSearchDto: PagedSearchDto<ProductFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<ProductDto>> {
    return this.apiService.apiPost("/products/paged", productSearchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<ProductDto> {
    return this.apiService.apiGet("/products/" + id, apiOptions);
  }

  getSimpleById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<ProductSimpleDto> {
    return this.apiService.apiGet("/products/simple/" + id, apiOptions);
  }

  getNextProductCode(
    apiOptions?: ApiOptions
  ): Promise<number> {
    return this.apiService.apiGet("/products/next-code", apiOptions);
  }

  create(
    productDto: ProductDto,
    apiOptions?: ApiOptions
  ): Promise<ProductDto> {
    return this.apiService.apiPost("/products", productDto, apiOptions);
  }

  edit(
    productDto: ProductDto,
    apiOptions?: ApiOptions
  ): Promise<ProductDto> {
    return this.apiService.apiPut("/products", productDto, apiOptions);
  }

  suggestAll(
    suggestSearch: SuggestSearchDto<ProductFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/products/suggest-all", suggestSearch, apiOptions);
  }

  suggestForSale(
    suggestSearch: SuggestSearchDto<ProductFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/products/suggest-for-sale", suggestSearch, apiOptions);
  }

  suggestForPurchase(
    suggestSearch: SuggestSearchDto<ProductFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/products/suggest-for-purchase", suggestSearch, apiOptions);
  }

}
