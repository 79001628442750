import { useContext } from "react";
import LayoutSideMenuArea from "../../shared/components/Layout/LayoutSideMenu.Area";
import { TypeIcon } from "../../shared/components/Icon";
import { AppInfoContext } from "../../shared/context/AppInfoProvider";
import { AppRoute } from "../../AppRoutes";
import Props from "../../shared/components/Props";

export interface PropsAdminProviderMenu extends Props {
  isExpanded: boolean;
  onNavigate(url: string): void;
}

const AdminProviderMenu = (props: PropsAdminProviderMenu) => {
  const { appInfo } = useContext(AppInfoContext);

  const areas = [
    {
      name: "Inicio",
      icon: TypeIcon.home,
      navigateUrl: AppRoute.adminProvider.base,
    },
    {
      name: "Cuentas",
      icon: TypeIcon.adminAccount,
      navigateUrl: AppRoute.adminProvider.accounts,
    },
    {
      name: "Usuarios",
      icon: TypeIcon.user,
      navigateUrl: AppRoute.adminProvider.users,
    },
    {
      name: "Plantillas",
      icon: TypeIcon.templates,
      navigateUrl: AppRoute.adminProvider.templates.base,
    },
    {
      name: "Correo enviados",
      icon: TypeIcon.email,
      navigateUrl: AppRoute.adminProvider.sentEmails,
    },
    {
      name: "eFactura",
      icon: TypeIcon.eInvoice,
      navigateUrl: AppRoute.adminProvider.eInvoices.base,
    },
  ];

  return (
    <>
      {areas.map((area) => (
        <LayoutSideMenuArea
          isExpanded={props.isExpanded}
          key={area.name}
          title={area.name}
          icon={area.icon}
          isSelected={appInfo?.currentArea === area.navigateUrl}
          onClick={() => {
            props.onNavigate(area.navigateUrl);
          }}
        />
      ))}
    </>
  );
};

export default AdminProviderMenu;
