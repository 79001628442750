import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { VaultCertificateDto } from "../../../api/app/dtos/VaultCertificateDto";
import { enumName } from "../../../api/shared/enums/_enumName";
import Button from "../../../shared/components/Button/Button";
import Grid from "../../../shared/components/grid/Grid";
import {
  GridCellType,
  IGridItemTemplate,
  MobileSpecialType,
} from "../../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../../shared/components/Layout/Page";
import { useApiVaultCertificate } from "../../../api/shared/hooks/useApiApp";
import { useModal } from "../../../shared/hooks/useModal";
import { enumColorVaultCertificateStatus } from "../../../shared/utils/utilEnumColors";
import VaultCertificateInstallPopup from "./VaultCertificateInstallPopup";
import getVaultCertificateListItemMenu from "./VaultCertificateList.ItemMenu";

const VaultCertificateList = () => {
  const [modal] = useModal();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [apiVaultCertificate] = useApiVaultCertificate();

  const itemTemplates: IGridItemTemplate<VaultCertificateDto>[] = [
    {
      header: "Subject",
      field: nameof<VaultCertificateDto>((p) => p.subject),
      mobileSpecialType: MobileSpecialType.Main,
      width: 30,
    },
    {
      header: "Válido desde",
      field: nameof<VaultCertificateDto>((p) => p.activationDate),
      width: 20,
      cellType: GridCellType.date,
    },
    {
      header: "Válido hasta",
      field: nameof<VaultCertificateDto>((p) => p.expirationDate),
      width: 20,
      cellType: GridCellType.date,
    },
    {
      header: "Estado",
      field: nameof<VaultCertificateDto>((p) => p.status),
      width: 10,
      cellType: GridCellType.statusWithColor,
      cellTypeEnumName: enumName.VaultCertificateStatus,
      cellTypeMapColor: enumColorVaultCertificateStatus,
    },
    {
      header: "Por defecto",
      field: nameof<VaultCertificateDto>((p) => p.isDefault),
      width: 10,
      cellType: GridCellType.iconCheck,
    },
  ];

  return (
    <Page
      title="Certificados digitales"
      titleButton={
        <Button
          text="Instalar certificado"
          onClick={() =>
            modal.open(<VaultCertificateInstallPopup />, () => {
              setReloadGrid(new Date());
            })
          }
        />
      }>
      <Grid
        itemTemplates={itemTemplates}
        reloadGrid={reloadGrid}
        onSearch={(search, options) => apiVaultCertificate.getPaged(search, options)}
        onDisplayItemMenu={(vaultCertificateDto: VaultCertificateDto) =>
          getVaultCertificateListItemMenu(vaultCertificateDto, apiVaultCertificate, modal, () => {
            setReloadGrid(new Date());
          })
        }
      />
    </Page>
  );
};

export default VaultCertificateList;
