import { useEffect, useState } from "react";
import { ServiceBusOrchestratorItemStatusDto } from "../../api/admin/dtos/ServiceBusOrchestratorItemStatusDto";
import { useApiAdminServiceBus } from "../../api/shared/hooks/useApiAdmin";
import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import { TypeIcon } from "../../shared/components/Icon";
import Column from "../../shared/components/Layout/GColumn";
import Page from "../../shared/components/Layout/Page";
import Spinner from "../../shared/components/Spinner";
import AdminServiceBusStatusCard from "./AdminServiceBusStatusCard";

const AdminServiceBusDashboard = () => {
  const [apiAdminServiceBus] = useApiAdminServiceBus();
  const [serviceBusStatus, setServiceBusStatus] = useState<ServiceBusOrchestratorItemStatusDto[]>();

  const load = async () => {
    const statusResponse = await apiAdminServiceBus.getAllStatus();
    setServiceBusStatus(statusResponse);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <Page title="Service Bus" titleButton={<ButtonIcon icon={TypeIcon.refresh} onClick={load} />}>
      {!serviceBusStatus && <Spinner />}
      {serviceBusStatus &&
        serviceBusStatus.map((status) => (
          <Column key={status.name}>
            <AdminServiceBusStatusCard serviceBusStatus={status} onStatusChange={load} />
          </Column>
        ))}
    </Page>
  );
};

export default AdminServiceBusDashboard;
