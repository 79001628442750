import { ApiAdminAccountPublicTemplateEmail } from "../../../api/admin/apis/ApiAdminAccountPublicTemplateEmail";
import { AdminTemplateEmailDto } from "../../../api/admin/dtos/AdminTemplateEmailDto";
import { AppRoute } from "../../../AppRoutes";
import { ContextMenuItem } from "../../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../../shared/components/Icon";
import ModalDialog from "../../../shared/components/Modal/ModalDialog";
import TemplateSendEmailTestPopup from "../../../shared/components/Templates/TemplateSendEmailTestPopup";
import { AppInfoSiteType } from "../../../shared/context/AppInfoProvider";
import { ModalService } from "../../../shared/hooks/useModal";
import { Navigation } from "../../../shared/hooks/useNavigation";
import { Notifier } from "../../../shared/hooks/useNotifier";

export function getAdminTemplateEmailListItemMenu(
  adminTemplateEmailDto: AdminTemplateEmailDto,
  apiTemplateEmail: ApiAdminAccountPublicTemplateEmail,
  appInfoSiteType: AppInfoSiteType,
  navigation: Navigation,
  modal: ModalService,
  notifier: Notifier,
  setReloadGrid: (date: Date) => void
): ContextMenuItem[] {
  return [
    {
      text: "Enviar correo de prueba",
      icon: TypeIcon.email,
      hide: adminTemplateEmailDto.inactive,
      onClick: () => {
        modal.open(<TemplateSendEmailTestPopup templateId={adminTemplateEmailDto.id} />);
      },
    },
    {
      text: "Editar",
      icon: TypeIcon.edit,
      hide: appInfoSiteType != AppInfoSiteType.SuperAdmin || adminTemplateEmailDto.inactive,
      onClick: () => {
        navigation.go(AppRoute.admin.templates.email.edit, adminTemplateEmailDto.id);
      },
    },
    {
      text: "Inactivar",
      icon: TypeIcon.delete,
      hide: appInfoSiteType != AppInfoSiteType.SuperAdmin || adminTemplateEmailDto.inactive,
      onClick: () => {
        modal.open(<ModalDialog text="¿Seguro desea eliminar la plantilla?" />, () => {
          apiTemplateEmail.inactivate(adminTemplateEmailDto.id);
          notifier.showSuccessFast("La plantilla se inactivó con éxito");
          setReloadGrid(new Date());
        });
      },
    },
    {
      text: "Reactivar",
      icon: TypeIcon.undo,
      hide: appInfoSiteType != AppInfoSiteType.SuperAdmin || !adminTemplateEmailDto.inactive,
      onClick: () => {
        modal.open(<ModalDialog text="¿Seguro desea reactivar la plantilla?" />, () => {
          apiTemplateEmail.reactivate(adminTemplateEmailDto.id);
          notifier.showSuccessFast("La plantilla se reactivó con éxito");
          setReloadGrid(new Date());
        });
      },
    },
  ];
}
