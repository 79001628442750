// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminAccountDto } from "../dtos/AdminAccountDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { AdminAccountFiltersDto } from "../dtos/AdminAccountFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { SuggestResponseDto } from "../../shared/dtos/SuggestResponseDto";
import { SuggestSearchDto } from "../../shared/dtos/SuggestSearchDto";

export class ApiAdminProviderAdminAccount {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<AdminAccountFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AdminAccountDto>> {
    return this.apiService.apiPost("/admin-provider/accounts/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AdminAccountDto> {
    return this.apiService.apiGet("/admin-provider/accounts/" + id, apiOptions);
  }

  create(
    adminAccountDto: AdminAccountDto,
    apiOptions?: ApiOptions
  ): Promise<AdminAccountDto> {
    return this.apiService.apiPost("/admin-provider/accounts", adminAccountDto, apiOptions);
  }

  edit(
    adminAccountDto: AdminAccountDto,
    apiOptions?: ApiOptions
  ): Promise<AdminAccountDto> {
    return this.apiService.apiPut("/admin-provider/accounts", adminAccountDto, apiOptions);
  }

  suggest(
    suggestSearch: SuggestSearchDto<AdminAccountFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/admin-provider/accounts/suggest", suggestSearch, apiOptions);
  }

}
