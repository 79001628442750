import { AppRoute } from "../../AppRoutes";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Row from "../../shared/components/Layout/GRow";
import Section from "../../shared/components/Layout/GSection";
import { useNavigation } from "../../shared/hooks/useNavigation";

const ConfigurationTemplates = () => {
  const navigation = useNavigation();

  return (
    <Row>
      <Section title="Plantillas">
        <Row>
          <ComponentGroup wrap={true}>
            <ButtonSecondary
              text="Configurar"
              icon={TypeIcon.config}
              onClick={() => navigation.go(AppRoute.templates.config)}
            />
            <ButtonSecondary
              text="Plantillas genéricas"
              disabled={true}
              tooltip="Mejora tu plan para poder editar las plantillas genéricas"
              icon={TypeIcon.file}
              onClick={() => navigation.go(AppRoute.templates.generic.base)}
            />
            <ButtonSecondary
              text="Plantillas de correo"
              disabled={true}
              tooltip="Mejora tu plan para poder editar las plantillas de correo"
              icon={TypeIcon.email}
              onClick={() => navigation.go(AppRoute.templates.email.base)}
            />
            <ButtonSecondary
              text="Plantillas de pdf"
              disabled={true}
              tooltip="Mejora tu plan para poder editar las plantillas de documentos en pdf"
              icon={TypeIcon.pdf}
              onClick={() => navigation.go(AppRoute.templates.pdf.base)}
            />
          </ComponentGroup>
        </Row>
      </Section>
    </Row>
  );
};

export default ConfigurationTemplates;
