import { MutableRefObject, ReactElement } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminTemplatePdfDto } from "../../../api/admin/dtos/AdminTemplatePdfDto";
import { TextAlign } from "../../TextAlign";
import CodeEditor, { CodeEditorLang } from "../CodeEditor";
import FormSwitch from "../forms/FormSwitch";
import InfoText from "../InfoText";
import Column from "../Layout/GColumn";
import Row from "../Layout/GRow";

const TemplateEditorHeader = (props: {
  headerRef?: MutableRefObject<any>;
  expandPreviewPanelButton?: ReactElement;
  height?: number;
  scrollPosX?: number;
  scrollPosY?: number;
  value: string;
  onChange: (value: string) => void;
  onScroll?: (positionX: number, positionY: number) => void;
}) => {
  return (
    <>
      <Row>
        <InfoText>
          Aquí podés ingresar el código <strong>HTML</strong> del encabezado.
        </InfoText>
      </Row>
      <Row>
        <CodeEditor
          height={props.height}
          lang={CodeEditorLang.html}
          ref={props.headerRef}
          value={props.value ?? ""}
          scrollPosX={props.scrollPosX}
          scrollPosY={props.scrollPosY}
          onChange={props.onChange}
          onScroll={props.onScroll}
        />
      </Row>
      <Column md={props.expandPreviewPanelButton ? 10 : 12}>
        <FormSwitch
          propertyName={nameof<AdminTemplatePdfDto>((p) => p.headerIgnoreFirstPage)}
          label="No imprimir en la primer página"
        />
      </Column>
      {props.expandPreviewPanelButton && (
        <Column md={2} align={TextAlign.right}>
          {props.expandPreviewPanelButton}
        </Column>
      )}
    </>
  );
};

export default TemplateEditorHeader;
