import { ApiService } from "../../api/shared/apis/ApiService";
import { AppEnvironment } from "../../AppEnvironment";
import { useNotifier } from "../hooks/useNotifier";
import { useContextLoginInfo } from "./useContextLoginInfo";
import useSpinner from "./useSpinner";

export function useApi() {
  const { loginInfo } = useContextLoginInfo();
  const notifier = useNotifier();
  const spinner = useSpinner();

  return new ApiService(
    AppEnvironment,
    { authToken: loginInfo.authToken ?? "" },
    notifier.showError,
    spinner
  );
}
