import { CSSProperties } from "react";
import { ISaleReferenceColumnRenderProps } from "./SaleFormReferences";

const SaleFormReferenceColumn = (props: ISaleReferenceColumnRenderProps) => {
  const style: CSSProperties = {
    flex: props.saleReferenceColumn.width,
    textAlign: props.saleReferenceColumn.align,
  };

  return <div style={style}>{props.saleReferenceColumn.render(props)}</div>;
};

export default SaleFormReferenceColumn;
