import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminCurrencyExchangeRateDto } from "../../api/admin/dtos/AdminCurrencyExchangeRateDto";
import { CurrencyExchangeFiltersDto } from "../../api/admin/dtos/CurrencyExchangeFiltersDto";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiAdminCurrencyExchangeRate } from "../../api/shared/hooks/useApiAdmin";
import { Constants } from "../../Constants";
import ButtonCreate from "../../shared/components/Button/ButtonCreate";
import Grid from "../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
  MobileSpecialType,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import InfoText from "../../shared/components/InfoText";
import Row from "../../shared/components/Layout/GRow";
import Page from "../../shared/components/Layout/Page";
import { useModal } from "../../shared/hooks/useModal";
import AdminCurrencyExchangeRatePopup from "./AdminCurrencyExchangePopup";

const AdminCurrencyExchangeRateList = () => {
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [apiAdminCurrencyExchangeRate] = useApiAdminCurrencyExchangeRate();
  const [modal] = useModal();

  const itemTemplates: IGridItemTemplate<AdminCurrencyExchangeRateDto>[] = [
    {
      header: "País",
      field: nameof<AdminCurrencyExchangeRateDto>((p) => p.country),
      filter: {
        propertyName: nameof<CurrencyExchangeFiltersDto>((p) => p.country),
        type: GridFilterType.enumList,
        enumName: enumName.Country,
      },
      width: 10,
      cellTypeEnumName: enumName.Country,
    },
    {
      header: "Moneda",
      field: nameof<AdminCurrencyExchangeRateDto>((p) => p.currency),
      filter: {
        propertyName: nameof<CurrencyExchangeFiltersDto>((p) => p.currency),
        type: GridFilterType.currency,
      },
      mobileSpecialType: MobileSpecialType.Main,
      width: 10,
      cellTypeEnumName: enumName.Currency,
    },
    {
      header: "Nombre",
      field: nameof<AdminCurrencyExchangeRateDto>((p) => p.currency),
      width: 20,
      cellTypeEnumName: enumName.CurrencyName,
    },
    {
      header: "Fecha",
      field: nameof<AdminCurrencyExchangeRateDto>((p) => p.date),
      filter: {
        propertyName: nameof<CurrencyExchangeFiltersDto>((p) => p.date),
        type: GridFilterType.date,
      },
      width: 20,
      cellType: GridCellType.dateOnlyLocal,
    },
    {
      header: "Publicación",
      field: nameof<AdminCurrencyExchangeRateDto>((p) => p.publishDate),
      width: 20,
      cellType: GridCellType.dateOnlyLocal,
    },
    {
      header: "Valor",
      field: nameof<AdminCurrencyExchangeRateDto>((p) => p.exchangeRate),
      width: 20,
      cellType: GridCellType.decimal,
      cellTypeDecimals: Constants.CURRENCY_EXCHANGE_DECIMALS,
      cellStyle: { fontWeight: 600 },
    },
  ];

  return (
    <Page
      title="Cotización de monedas"
      titleButton={
        <ButtonCreate
          text="Ver cotizaciones"
          onClick={() => {
            modal.open(<AdminCurrencyExchangeRatePopup />, () => setReloadGrid(new Date()));
          }}
        />
      }>
      <Row>
        <InfoText>
          <strong>Uruguay:</strong> Se toman las cotizaciones del día anterior publicadas por el
          BCU. Por esta razón la fecha que aplica la cotización y la fecha de publicación son
          diferentes.
          <br />
          <strong>Colombia: </strong> Todavía pendiente de implementar
        </InfoText>
      </Row>
      <Grid
        itemTemplates={itemTemplates}
        reloadGrid={reloadGrid}
        hideIncludeInactiveButton={true}
        onSearch={(search, options) => apiAdminCurrencyExchangeRate.getPaged(search, options)}
      />
    </Page>
  );
};

export default AdminCurrencyExchangeRateList;
