// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { EInvoiceEnvelopeStatusResponseSaleToCompanyDto } from "../dtos/EInvoiceEnvelopeStatusResponseSaleToCompanyDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiEInvoiceEnvelopeStatusResponseSaleToCompany {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getAllByEnvelopeId(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceEnvelopeStatusResponseSaleToCompanyDto[]> {
    return this.apiService.apiGet("/e-invoice-envelope-status-response-sale-to-company/for-envelope-all/" + id, apiOptions);
  }

}
