import { nameof } from "ts-simple-nameof";
import { AdminAccountDto } from "../../api/admin/dtos/AdminAccountDto";
import { enumName } from "../../api/shared/enums/_enumName";
import FormAutocompleteAdminProvider from "../../shared/components/forms/Autocomplete/FormAutocompleteAdminProvider";
import FormSelectAdminShard from "../../shared/components/forms/Select/FormSelectAdminShard";
import FormSelectLocale from "../../shared/components/forms/Select/FormSelectLocale";
import FormSelectTimeZone from "../../shared/components/forms/Select/FormSelectTimeZone";
import Row from "../../shared/components/Layout/GRow";
import RowDisplay from "../../shared/components/Layout/GRowDisplay";
import { AppInfoSiteType } from "../../shared/context/AppInfoProvider";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import useUtils from "../../shared/hooks/useUtils";
import { useContextModelState } from "../../shared/modelState/useContextModelState";

const AdminAccountFormAccount = (props: { isEdit: boolean }) => {
  const modelState = useContextModelState();
  const adminAccount = modelState.model as AdminAccountDto;
  const { appInfo } = useContextAppInfo();
  const { utils } = useUtils();

  return (
    <>
      {appInfo?.siteType == AppInfoSiteType.SuperAdmin && (
        <Row>
          <FormAutocompleteAdminProvider
            label="Proveedor"
            propertyName={nameof<AdminAccountDto>((p) => p.provider)}
          />
        </Row>
      )}
      {appInfo?.siteType == AppInfoSiteType.AdminProvider && (
        <RowDisplay label="Proveedor">{adminAccount.provider.name}</RowDisplay>
      )}
      <RowDisplay label="País">
        {utils.enum.translate(enumName.Country, adminAccount.country)}
      </RowDisplay>
      {!props.isEdit && (
        <>
          <Row>
            <FormSelectAdminShard
              label="Shard"
              propertyName={nameof<AdminAccountDto>((p) => p.shardId)}
              selectDefault={true}
            />
          </Row>
        </>
      )}
      {props.isEdit && <RowDisplay label="Shard">{adminAccount.shardName}</RowDisplay>}

      <Row>
        <FormSelectTimeZone
          label="Zona horaria"
          propertyName={nameof<AdminAccountDto>((p) => p.timeZoneId)}
          country={adminAccount.country}
        />
      </Row>

      <Row>
        <FormSelectLocale
          label="Locale"
          propertyName={nameof<AdminAccountDto>((p) => p.locale)}
          country={adminAccount.country}
          isEdit={props.isEdit}
        />
      </Row>
    </>
  );
};

export default AdminAccountFormAccount;
