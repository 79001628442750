import { AdminTemplateGenericSimpleDto } from "../../../api/admin/dtos/AdminTemplateGenericSimpleDto";
import { TemplateGenericType } from "../../../api/shared/enums/TemplateGenericType";
import { TemplateType } from "../../../api/shared/enums/TemplateType";
import { enumName } from "../../../api/shared/enums/_enumName";
import { AppRoute } from "../../../AppRoutes";
import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import useUtils from "../../hooks/useUtils";
import { templateTypeIsAdmin } from "../../utils/utilTemplate";
import ButtonIcon from "../Button/ButtonIcon";
import Icon, { TypeIcon } from "../Icon";
import { TemplateConstants, TemplateGetTagClose, TemplateGetTagOpen } from "./TemplateConstants";
import "./TemplateEditorFields.scss";

const TemplateEditorGeneric = (props: {
  canAddField: boolean;
  template: AdminTemplateGenericSimpleDto;
  onClick(tag: string): void;
}) => {
  const color = AppThemeColor.gray;
  const { utils } = useUtils();

  let iconType = TypeIcon.text;
  if (props.template.genericType == TemplateGenericType.Container) {
    iconType = TypeIcon.templates;
  } else if (props.template.genericType == TemplateGenericType.InlineStyles) {
    iconType = TypeIcon.css;
  }

  return (
    <div className="template-field template-generic">
      <div className="template-field-name">{props.template.name}</div>
      <div className="template-field-code">{props.template.code}</div>
      <div>
        <Icon
          size={AppSize.small}
          type={iconType}
          tooltip={utils.enum.translate(enumName.TemplateGenericType, props.template.genericType)}
        />
        {props.template.helpText && (
          <Icon
            size={AppSize.small}
            type={TypeIcon.help}
            color={color}
            tooltip={props.template.helpText}
          />
        )}
        {props.template.isGlobal && (
          <Icon
            color={color}
            size={AppSize.small}
            type={TypeIcon.certificates}
            tooltip="Plantilla genérica global"
          />
        )}
        {templateTypeIsAdmin(props.template.type) && (
          <Icon
            color={color}
            size={AppSize.small}
            type={TypeIcon.superAdmin}
            tooltip="Únicamente para plantillas de Admin"
          />
        )}
        {(props.template.type == TemplateType.AdminGeneric ||
          props.template.type == TemplateType.Generic) && (
          <Icon
            color={color}
            size={AppSize.small}
            type={TypeIcon.file}
            tooltip="Aplica para todos los tipos de documentos"
          />
        )}
        {(props.template.type == TemplateType.AdminGenericPdf ||
          props.template.type == TemplateType.GenericPdf) && (
          <Icon
            color={color}
            size={AppSize.small}
            type={TypeIcon.pdf}
            tooltip="Aplica únicamente para plantillas de pdf"
          />
        )}
        {(props.template.type == TemplateType.AdminGenericEmail ||
          props.template.type == TemplateType.GenericEmail) && (
          <Icon
            color={color}
            size={AppSize.small}
            type={TypeIcon.email}
            tooltip="Aplica únicamente para plantillas de correo"
          />
        )}
      </div>
      {props.canAddField && (
        <ButtonIcon
          noPadding={true}
          icon={TypeIcon.arrowRight}
          tooltip="Agregar campo a la plantilla"
          className="template-field-icon"
          onClick={() =>
            props.onClick(
              fieldTag(
                props.template.code,
                props.template.genericType == TemplateGenericType.Container
              )
            )
          }
        />
      )}
      {!props.template.isGlobal && (
        <ButtonIcon
          noPadding={true}
          icon={TypeIcon.edit}
          tooltip="Editar"
          size={AppSize.small}
          className="template-field-icon"
          style={{ top: "auto", bottom: 6, right: 6 }}
          onClick={() => {
            if (props.template.isGlobal) {
              window.open(AppRoute.admin.templates.generic.edit + "/" + props.template.id);
              return;
            }
            window.open(AppRoute.templates.generic.edit + "/" + props.template.id);
          }}
        />
      )}
    </div>
  );
};

export default TemplateEditorGeneric;

const fieldTag = (genericCode: string, genericIsContainer: boolean): string => {
  if (genericIsContainer) {
    return (
      TemplateGetTagOpen(TemplateConstants.TAG_OPERATOR_TEMPLATE_CONTAINER, genericCode) +
      "\n\n" +
      TemplateGetTagClose(TemplateConstants.TAG_OPERATOR_TEMPLATE_CONTAINER, genericCode)
    );
  }
  return TemplateGetTagOpen(TemplateConstants.TAG_OPERATOR_TEMPLATE_GENERIC, genericCode);
};
