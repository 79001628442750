import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdminTermsAndConditionsDto } from "../../api/admin/dtos/AdminTermsAndConditionsDto";
import { useApiAdminProviderTermsAndConditions } from "../../api/shared/hooks/useApiAdminProviders";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Row from "../../shared/components/Layout/GRow";
import Page from "../../shared/components/Layout/Page";
import TabGroup from "../../shared/components/Layout/TabGroup";
import TabView from "../../shared/components/Layout/TabView";
import useUtils from "../../shared/hooks/useUtils";
import AdminTermsAndConditionsFormPreview from "./AdminTermsAndConditionsForm.Preview";

const TABS = {
  CHANGES: "CHANGES",
  TERMS: "TERMS",
  PRIVACY: "PRIVACY",
};

type TParams = { id: string };

const AdminTermsAndConditionsDetails = () => {
  const { id } = useParams<TParams>();
  const [apiAdminTermsAndConditions] = useApiAdminProviderTermsAndConditions();
  const [adminTermsAndConditions, setAdminTermsAndConditions] =
    useState<AdminTermsAndConditionsDto>();
  const { utils } = useUtils();
  const tabs = [
    { title: "Notificación de cambios", value: TABS.CHANGES },
    { title: "Términos y condiciones", value: TABS.TERMS },
    { title: "Política de privacidad", value: TABS.PRIVACY },
  ];
  useEffect(() => {
    if (!id) {
      return;
    }

    const load = async () => {
      setAdminTermsAndConditions(await apiAdminTermsAndConditions.getById(id));
    };
    load();
  }, [id]);

  if (!adminTermsAndConditions) {
    return <></>;
  }

  return (
    <Page title="Detalles de términos y condiciones">
      <ColumnDisplay label="Proveedor">{adminTermsAndConditions.adminProvider.name}</ColumnDisplay>
      <ColumnDisplay label="Notificar desde">
        {utils.date.toString(adminTermsAndConditions.notifySince)}
      </ColumnDisplay>
      <ColumnDisplay label="Obligatoria desde">
        {utils.date.toString(adminTermsAndConditions.mandatorySince)}
      </ColumnDisplay>
      <Row>
        <TabGroup tabs={tabs} selectedTab={TABS.CHANGES}>
          <TabView value={TABS.CHANGES}>
            <Row>
              <AdminTermsAndConditionsFormPreview
                value={adminTermsAndConditions.notificationChanges}
              />
            </Row>
          </TabView>
          <TabView value={TABS.TERMS}>
            <Row>
              <AdminTermsAndConditionsFormPreview
                value={adminTermsAndConditions.termsAndConditions}
              />
            </Row>
          </TabView>
          <TabView value={TABS.PRIVACY}>
            <Row>
              <AdminTermsAndConditionsFormPreview value={adminTermsAndConditions.privacyPolice} />
            </Row>
          </TabView>
        </TabGroup>
      </Row>
    </Page>
  );
};

export default AdminTermsAndConditionsDetails;
