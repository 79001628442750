import { ReactElement, useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { AppInfoActionType, AppInfoContext } from "../../context/AppInfoProvider";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useNavigation } from "../../hooks/useNavigation";
import { TextAlign } from "../../TextAlign";
import ButtonIcon from "../Button/ButtonIcon";
import { TypeIcon } from "../Icon";
import Column from "./GColumn";
import Container from "./GContainer";
import Row from "./GRow";
import Section from "./GSection";
import { LAYOUT_SPACING_PIXELS } from "./LayoutConstants";

export class PropsPage {
  children?: any;
  title: string;
  fixedHeight?: boolean;
  titleButton?: ReactElement;
}

const Page = (props: PropsPage) => {
  const { appInfo, dispatchAppInfo } = useContext(AppInfoContext);
  const navigation = useNavigation();
  const location = useLocation();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!appInfo || appInfo.currentPageTitle !== props.title) {
      window.document.title =
        appInfo?.adminProviderInfo.name + (props.title ? " - " + props.title : "");
      dispatchAppInfo({
        type: AppInfoActionType.changePage,
        title: props.title,
        location: location.pathname,
      });
    }
  }, [appInfo, dispatchAppInfo, props.title, location]);

  const titleRow = (
    <>
      <Column md={9} fontSize="2em" minHeightIgnore={true}>
        <ButtonIcon
          noPaddingX={true}
          style={{ marginRight: LAYOUT_SPACING_PIXELS * 2 }}
          icon={TypeIcon.arrowLeft}
          onClick={() => {
            navigation.back();
          }}
        />
        {props.title}
      </Column>
      {props.titleButton && (
        <Column md={3} xs={12} align={TextAlign.right} minHeightIgnore={true}>
          {props.titleButton}
        </Column>
      )}
    </>
  );

  return (
    <Container
      className={
        (props.fixedHeight && !isMobile ? "layout-page-fixed-height" : "") + " layout-page fade-in"
      }>
      {isMobile && (
        <Row style={{ width: 100 }}>
          <Section paddingTop={0}>{titleRow}</Section>
        </Row>
      )}
      {!isMobile && props.title && <>{titleRow}</>}
      <Row>
        <Section paddingTop={1}>{props.children}</Section>
      </Row>
    </Container>
  );
};

export default Page;
