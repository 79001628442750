import { useState } from "react";
import { ApiAuth } from "../../api/app/apis/ApiAuth";
import { ApiService } from "../../api/shared/apis/ApiService";
import { AppEnvironment } from "../../AppEnvironment";
import { useNotifier } from "./useNotifier";
import useSpinner from "./useSpinner";

function useApiAuthService(tempToken?: string) {
  const notifier = useNotifier();
  const spinner = useSpinner();

  return new ApiService(
    AppEnvironment,
    { authToken: tempToken ?? "" },
    notifier.showError,
    spinner
  );
}

export function useApiAuth(tempToken?: string) {
  return useState(new ApiAuth(useApiAuthService(tempToken)));
}
