import { useState } from "react";
import { VaultCertificateDto } from "../../../api/app/dtos/VaultCertificateDto";
import { VaultCertificateInstallDto } from "../../../api/app/dtos/VaultCertificateInstallDto";
import { FileUploadExtensionsAllowed } from "../../../api/shared/enums/FileUploadExtensionsAllowed";
import { LocalFolderType } from "../../../api/shared/enums/LocalFolderType";
import Button from "../../../shared/components/Button/Button";
import { FileUploadType } from "../../../shared/components/FileUpload";
import InputFile from "../../../shared/components/inputs/InputFile";
import InputPassword from "../../../shared/components/inputs/InputPassword";
import Row from "../../../shared/components/Layout/GRow";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import { useApiVaultCertificate } from "../../../api/shared/hooks/useApiApp";

const VaultCertificateInstallPopupSelectFile = (props: {
  onInfoLoaded(
    vaultCertificateDto: VaultCertificateDto,
    vaultCertificateInstallDto: VaultCertificateInstallDto
  ): void;
}) => {
  const [vaultCertificateInstallDto, setVaultCertificateInstallDto] = useState(
    new VaultCertificateInstallDto()
  );
  const [apiVaultCertificate] = useApiVaultCertificate();

  const getCertificateFileInfo = async () => {
    const vaultCertificate = await apiVaultCertificate.getInfoFromLocalFile(
      vaultCertificateInstallDto
    );
    props.onInfoLoaded(vaultCertificate, vaultCertificateInstallDto);
  };

  return (
    <>
      <Row>
        <InputFile
          label="Certificado (.pfx)"
          value={vaultCertificateInstallDto.localFilePath}
          uploadType={FileUploadType.ToTempAccount}
          localFolderType={LocalFolderType.TempCertificates}
          extensionsAllowed={FileUploadExtensionsAllowed.Certificates}
          onChange={(value) => {
            setVaultCertificateInstallDto({
              ...vaultCertificateInstallDto,
              localFilePath: value,
            });
          }}
        />
      </Row>
      <Row>
        <InputPassword
          label="Password"
          value={vaultCertificateInstallDto.password}
          onChange={(value) => {
            setVaultCertificateInstallDto({
              ...vaultCertificateInstallDto,
              password: value,
            });
          }}
        />
      </Row>
      <RowButtons>
        <Button
          text="Verificar"
          onClick={() => getCertificateFileInfo()}
          disabled={!vaultCertificateInstallDto.localFilePath}
        />
      </RowButtons>
    </>
  );
};

export default VaultCertificateInstallPopupSelectFile;
