import { nameof } from "ts-simple-nameof";
import { SaleSenderBehalfDto } from "../../../api/app/dtos/SaleSenderBehalfDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

export function useSaleSenderBehalfValidation() {
  const saleSenderBehalfSchema = new ValidationSchema();

  saleSenderBehalfSchema[nameof<SaleSenderBehalfDto>((p) => p.name)] =
    validate("Nombre").required();
  saleSenderBehalfSchema[nameof<SaleSenderBehalfDto>((p) => p.country)] =
    validate("País").required();
  saleSenderBehalfSchema[nameof<SaleSenderBehalfDto>((p) => p.idCardType)] =
    validate("Tipo documento").required();
  saleSenderBehalfSchema[nameof<SaleSenderBehalfDto>((p) => p.idCardNumber)] =
    validate("Número documento").required();
  return saleSenderBehalfSchema;
}
