import { AppThemeColor } from "../../../styles/color";
import { useContextLoginInfo } from "../../hooks/useContextLoginInfo";
import Button from "../Button/Button";
import Row from "../Layout/GRow";
import ErrorPageLayout from "./ErrorPageLayout";

const ErrorGettingInfo = () => {
  const contextLoginInfo = useContextLoginInfo();
  return (
    <ErrorPageLayout>
      <Row>Ups, ocurrió un problema al obtener la información del usuario</Row>
      <Row fontSize={".7em"} fontColor={AppThemeColor.alert}>
        {contextLoginInfo.loginInfo.errorMessage}
      </Row>
      <Row>
        <Button text="Recargar" onClick={() => window.location.reload()} />
      </Row>
      <Row>
        <button
          style={{
            padding: "15px 30px",
            border: 1,
            borderRadius: 30,
            marginTop: 30,
            cursor: "pointer",
          }}
          onClick={contextLoginInfo.logout}>
          Cerrar sesión
        </button>
      </Row>
    </ErrorPageLayout>
  );
};

export default ErrorGettingInfo;
