import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { PurchaseDto } from "../../../api/app/dtos/PurchaseDto";
import { PurchaseFiltersDto } from "../../../api/app/dtos/PurchaseFiltersDto";
import { EInvoicePurchaseApprovalStatus } from "../../../api/shared/enums/EInvoicePurchaseApprovalStatus";
import { GridName } from "../../../api/shared/enums/GridName";
import { enumName } from "../../../api/shared/enums/_enumName";
import { useApiExportPurchase, useApiPurchase } from "../../../api/shared/hooks/useApiApp";
import { AppRoute } from "../../../AppRoutes";
import ButtonGrouped from "../../../shared/components/Button/ButtonGrouped";
import ButtonSecondary from "../../../shared/components/Button/ButtonSecondary";
import Grid, { GridColumnAlign } from "../../../shared/components/grid/Grid";
import {
  GridCellType,
  GridColumnActionType,
  GridFilterType,
  IGridItemTemplate,
  MobileSpecialType,
} from "../../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Icon, { TypeIcon } from "../../../shared/components/Icon";
import Page from "../../../shared/components/Layout/Page";
import StatusTextColor from "../../../shared/components/StatusTextColor";
import { useModal } from "../../../shared/hooks/useModal";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import { useNotifier } from "../../../shared/hooks/useNotifier";
import { enumColorEInvoicePurchaseApprovalStatus } from "../../../shared/utils/utilEnumColors";
import { AppThemeColor } from "../../../styles/color";
import GridCellContact from "../../Contact/GridCellContact";
import EInvoiceEnvelopePurchaseUploadPopup from "./EInvoiceEnvelopePurchaseUploadPopup";
import { getEInvoicePurchasePendingListItemMenu } from "./EInvoicePurchasePendingList.ItemMenu";
import EInvoicePurchasePendingRefreshPopup from "./EInvoicePurchasePendingRefreshPopup";

const EInvoicePurchasePendingList = () => {
  const [initialFilters] = useState(() => {
    const filters = new PurchaseFiltersDto();
    filters.onlyPendingApprove = true;
    return filters;
  });

  const [apiPurchase] = useApiPurchase();
  const [apiExportPurchase] = useApiExportPurchase();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [modal] = useModal();
  const navigation = useNavigation();
  const notifier = useNotifier();

  const itemTemplates: IGridItemTemplate<PurchaseDto>[] = [
    {
      field: nameof<PurchaseDto>((p) => p.date),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.operationDate),
        type: GridFilterType.date,
      },
      header: "Fecha",
      width: 10,
      cellType: GridCellType.date,
    },
    {
      field: nameof<PurchaseDto>((p) => p.createdDate),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.createdDate),
        type: GridFilterType.dateTime,
      },
      header: "Creación",
      width: 10,
      hideByDefault: true,
      cellType: GridCellType.dateTime,
    },
    {
      field: nameof<PurchaseDto>((p) => p.invoiceType),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.invoiceTypeList),
        type: GridFilterType.enumList,
        enumName: enumName.InvoiceType,
      },
      header: "Tipo",
      isMandatory: true,
      width: 10,
      cellTypeEnumName: enumName.InvoiceType,
    },
    {
      field: nameof<PurchaseDto>((p) => p.envelopeOrder),
      header: "Orden sobre",
      orderBy: nameof<PurchaseDto>((p) => p.envelopeOrder),
      width: 10,
    },
    {
      field: nameof<PurchaseDto>((p) => p.serieAndNumber),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.number),
        type: GridFilterType.numberRangeInteger,
      },
      header: "Número",
      isMandatory: true,
      mobileSpecialType: MobileSpecialType.Main,
      orderBy: nameof<PurchaseDto>((p) => p.serie) + " " + nameof<PurchaseDto>((p) => p.number),
      width: 10,
    },
    {
      field: nameof<PurchaseDto>((p) => p.sender.taxPayerId),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.envelopeSenderTaxPayerId),
        type: GridFilterType.text,
      },
      isMandatory: true,
      header: "RUT Proveedor",
      width: 10,
    },
    {
      field: nameof<PurchaseDto>((p) => p.sender.displayBothNames),
      orderBy:
        nameof<PurchaseDto>((p) => p.contact.name) +
        " " +
        nameof<PurchaseDto>((p) => p.sender.companyName),
      relatedFields: [
        nameof<PurchaseDto>((p) => p.contactId),
        nameof<PurchaseDto>((p) => p.contact.name),
        nameof<PurchaseDto>((p) => p.sender.displayBothNames),
      ],
      header: "Proveedor",
      width: 30,
      render: (item: PurchaseDto) => {
        return (
          <GridCellContact
            contactId={item.contactId}
            contactName={item.contact?.name ?? item.sender?.displayBothNames}
            taxPayerId={item.sender.taxPayerId}
            onContactCreated={() => {
              setReloadGrid(new Date());
            }}
          />
        );
      },
    },
    {
      field: nameof<PurchaseDto>((p) => p.currency),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.currency),
        type: GridFilterType.currency,
      },
      header: "Moneda",
      width: 5,
      cellTypeEnumName: enumName.Currency,
    },
    {
      field: nameof<PurchaseDto>((p) => p.totalToPay),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.totalToPay),
        type: GridFilterType.numberRange,
      },
      header: "A pagar",
      width: 10,
      align: GridColumnAlign.right,
      cellStyle: { fontWeight: 600 },
      cellType: GridCellType.decimal,
    },
    {
      field: nameof<PurchaseDto>((p) => p.rejectDetails),
      header: "",
      headerAlt: "Error de validación",
      relatedFields: [
        nameof<PurchaseDto>((p) => p.taxesFixedApplied),
        nameof<PurchaseDto>((p) => p.eInvoiceEnvelopePurchase.errorMessage),
      ],
      width: 5,
      render: (item: PurchaseDto) => {
        const iconFixed = item.taxesFixedApplied ? (
          <Icon type={TypeIcon.info} tooltip={"Se aplicó corrección de valores de los impuestos"} />
        ) : null;

        const iconAckError = item.eInvoiceEnvelopePurchase.errorMessage ? (
          <Icon
            type={TypeIcon.email}
            color={AppThemeColor.alert}
            tooltip={
              "Error al enviar el acuse de recibo: " + item.eInvoiceEnvelopePurchase.errorMessage
            }
          />
        ) : null;

        const iconValidationMessage = item.rejectDetails ? (
          <Icon
            type={TypeIcon.warning}
            color={AppThemeColor.warning}
            tooltip={item.rejectDetails}
          />
        ) : null;

        return (
          <>
            {iconFixed}
            {iconAckError}
            {iconValidationMessage}
          </>
        );
      },
    },
    {
      field: nameof<PurchaseDto>((p) => p.signatureError),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.hasSignatureVerificationMessage),
        type: GridFilterType.boolean,
      },
      header: "",
      headerAlt: "Error al validar firma electrónica",
      width: 5,
      render: (item: PurchaseDto) => {
        return item.signatureError ? (
          <>
            <Icon type={TypeIcon.user} color={AppThemeColor.alert} tooltip={item.signatureError} />
          </>
        ) : null;
      },
    },
    {
      field: "",
      actionType: GridColumnActionType.CustomActionButton,
      header: "",
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.approvalStatusList),
        type: GridFilterType.enumList,
        enumName: enumName.EInvoicePurchaseApprovalStatus,
      },
      mobileSpecialType: MobileSpecialType.Main,
      width: 10,
      relatedFields: [
        nameof<PurchaseDto>((p) => p.approvalStatus),
        nameof<PurchaseDto>((p) => p.eInvoiceEnvelopePurchaseId),
        nameof<PurchaseDto>((p) => p.id),
      ],
      render: (item: PurchaseDto) =>
        item.approvalStatus == EInvoicePurchaseApprovalStatus.Pending ? (
          <ButtonSecondary
            icon={TypeIcon.check}
            text="Aceptar"
            onClick={() => {
              const request = async () => {
                await apiPurchase.approve(item.id);
                notifier.showSuccessFast("El comprobante se aceptó con éxito");
                setReloadGrid(new Date());
              };
              request();
            }}
            styles={{ marginTop: -6 }}
          />
        ) : (
          <StatusTextColor
            enumType={enumName.EInvoicePurchaseApprovalStatus}
            enumKey={item.approvalStatus}
            color={enumColorEInvoicePurchaseApprovalStatus}
            tooltip={item.rejectDetails}
          />
        ),
    },
  ];

  return (
    <Page
      title="CFE recibidos"
      titleButton={
        <ButtonGrouped
          text="Importar CFE"
          icon={TypeIcon.uploadFile}
          onClick={() => {
            modal.open(<EInvoiceEnvelopePurchaseUploadPopup />, () => setReloadGrid(new Date()));
          }}
          actions={[
            {
              text: "Forzar actualización",
              icon: TypeIcon.refresh,
              onClick: () => {
                modal.open(<EInvoicePurchasePendingRefreshPopup />, () =>
                  setReloadGrid(new Date())
                );
              },
            },
            {
              text: "Sobres recibidos",
              icon: TypeIcon.email,
              onClick: () => navigation.go(AppRoute.purchases.envelopes.base),
            },
          ]}
        />
      }>
      <Grid
        hideIncludeInactiveButton={true}
        itemTemplates={itemTemplates}
        mustIncludeFields={[nameof<PurchaseDto>((p) => p.eInvoiceEnvelopePurchase.xmlUrl)]}
        initialFilters={initialFilters}
        gridName={GridName.EInvoicePurchasesPendingApprove}
        reloadGrid={reloadGrid}
        onSearch={(search, options) => apiPurchase.getPagedEInvoicePendingApprove(search, options)}
        onExport={(search, options) => apiExportPurchase.default(search as any, options)}
        onDisplayItemMenu={(purchaseDto: PurchaseDto) =>
          getEInvoicePurchasePendingListItemMenu(
            purchaseDto,
            apiPurchase,
            modal,
            notifier,
            navigation,
            () => {
              setReloadGrid(new Date());
            }
          )
        }
      />
    </Page>
  );
};

export default EInvoicePurchasePendingList;
