import { ApiEInvoiceAuthorization } from "../../../../api/app/apis/ApiEInvoiceAuthorization";
import { EInvoiceAuthorizationDto } from "../../../../api/app/dtos/EInvoiceAuthorizationDto";
import { ContextMenuItem } from "../../../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../../../shared/components/Icon";
import ModalDialog from "../../../../shared/components/Modal/ModalDialog";
import { ModalService } from "../../../../shared/hooks/useModal";
import { Notifier } from "../../../../shared/hooks/useNotifier";
import EInvoiceAuthorizationDetailsPopup from "./EInvoiceAuthorizationDetailsPopup";

export default function getEInvoiceAuthorizationListItemMenu(
  eInvoiceAuthorizationDto: EInvoiceAuthorizationDto,
  apiEInvoiceAuthorization: ApiEInvoiceAuthorization,
  modal: ModalService,
  notifier: Notifier,
  onGridReload: () => void
): ContextMenuItem[] {
  const setAsNoCurrent = () => {
    modal.open(<ModalDialog text="¿Seguro desea desactivar el CAE?  " />, () => {
      const setAsNoCurrentRequest = async () => {
        await apiEInvoiceAuthorization.setAsNoCurrent(eInvoiceAuthorizationDto.id);
        notifier.showSuccessFast("La autorización se inactivó con éxito");
        onGridReload();
      };
      setAsNoCurrentRequest();
    });
  };

  const setAsCurrent = () => {
    modal.open(<ModalDialog text="¿Seguro desea utilizar este CAE?  " />, () => {
      const setAsNoCurrentRequest = async () => {
        await apiEInvoiceAuthorization.setAsCurrent(eInvoiceAuthorizationDto.id);
        notifier.showSuccessFast("La autorización se activó con éxito");
        onGridReload();
      };
      setAsNoCurrentRequest();
    });
  };

  return [
    {
      text: "Desactivar",
      icon: TypeIcon.delete,
      hide: !eInvoiceAuthorizationDto.isCurrent,
      onClick: () => {
        setAsNoCurrent();
      },
    },
    {
      icon: TypeIcon.certificates,
      text: "Utilizar CAE",
      hide: eInvoiceAuthorizationDto.isCurrent || eInvoiceAuthorizationDto.lastUsedNumber > 0,
      onClick: () => {
        setAsCurrent();
      },
    },
    {
      icon: TypeIcon.details,
      text: "Detalles",
      onClick: () => {
        modal.open(
          <EInvoiceAuthorizationDetailsPopup id={eInvoiceAuthorizationDto.id} />,
          onGridReload
        );
      },
    },
    {
      text: "Descargar",
      icon: TypeIcon.download,
      onClick: () => {
        window.open(eInvoiceAuthorizationDto.xmlUrl);
      },
    },
  ];
}
