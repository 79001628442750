import { useAppTheme } from "../../AppThemeProvider";
import { AppThemeColor } from "../../styles/color";
import useUtils from "../hooks/useUtils";
import Tooltip from "./Tooltip";

const StatusTextColor = (props: {
  enumType: string;
  enumKey: number;
  tooltip?: string;
  color: (enumKey: number) => AppThemeColor;
}) => {
  const { utils } = useUtils();
  const value = utils.enum.translate(props.enumType, props.enumKey);
  const appTheme = useAppTheme();

  return (
    <Tooltip text={props.tooltip ? props.tooltip : value}>
      <strong
        style={{
          color: appTheme.toHexColor(props.color(props.enumKey)),
          textTransform: "uppercase",
        }}>
        {value}
      </strong>
    </Tooltip>
  );
};

export default StatusTextColor;
