// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { UserDto } from "./UserDto";
import { AdminAccountSimpleDto } from "../../shared/dtos/AdminAccountSimpleDto";
import { EditItemStatus } from "../../shared/enums/EditItemStatus";

export class UserLinkedAccountDto extends BaseEntityDto {
  userId: string = "";
  user: UserDto;
  accountId: string;
  account: AdminAccountSimpleDto;
  accountLastLogin: Date | null = null;
  accountUserId: string;
  isAccountAdmin: boolean = false;
  editItemStatus: EditItemStatus;
}
