import { nameof } from "ts-simple-nameof";
import { AdminEmailContactPublicSalesDto } from "../../api/admin/dtos/AdminEmailContactPublicSalesDto";
import Button from "../../shared/components/Button/Button";
import FormText from "../../shared/components/forms/FormText";
import InputRadioBoolean from "../../shared/components/inputs/InputRadioBoolean";
import { useContextModelState } from "../../shared/modelState/useContextModelState";
import LoginRow from "./Layout/LoginRow";

const RegisterForm = (props: { isLoading: boolean }) => {
  const modelState = useContextModelState();
  const registerLeadDto = modelState.model as AdminEmailContactPublicSalesDto;

  return (
    <>
      <LoginRow>
        <FormText
          label="Nombre"
          propertyName={nameof<AdminEmailContactPublicSalesDto>((p) => p.name)}
        />
      </LoginRow>
      <LoginRow>
        <FormText
          label="Empresa"
          propertyName={nameof<AdminEmailContactPublicSalesDto>((p) => p.companyName)}
        />
      </LoginRow>
      <LoginRow>
        <InputRadioBoolean
          value={registerLeadDto.contactByWhatsApp}
          labelTrue={"Por whatsApp"}
          labelFalse={"Por correo"}
          alignRow={true}
          hintAutoHeight={true}
          onChange={(value: boolean) => {
            modelState.updateProperty(
              nameof<AdminEmailContactPublicSalesDto>((p) => p.contactByWhatsApp),
              value
            );
          }}
        />
      </LoginRow>
      {!registerLeadDto.contactByWhatsApp && (
        <LoginRow>
          <FormText
            label="Correo"
            propertyName={nameof<AdminEmailContactPublicSalesDto>((p) => p.email)}
          />
        </LoginRow>
      )}
      {registerLeadDto.contactByWhatsApp && (
        <LoginRow>
          <FormText
            label="Teléfono"
            propertyName={nameof<AdminEmailContactPublicSalesDto>((p) => p.phone)}
          />
        </LoginRow>
      )}
      <LoginRow>
        <Button
          text="Aceptar"
          disabled={props.isLoading}
          fullWidth={true}
          onClick={() => modelState.submit()}
        />
      </LoginRow>
    </>
  );
};

export default RegisterForm;
