import { createContext, PropsWithChildren, useMemo, useReducer } from "react";
import { ErrorResultDto } from "../../../api/shared/dtos/ErrorResultDto";

const MAX_NOTIFICATIONS = 3;

export enum NotificationType {
  error = 0,
  info = 1,
  warning = 2,
  success = 3,
}

export class Notification {
  type: NotificationType;
  title?: string;
  message: string;
  closeSeconds?: number;
  forcingClose?: boolean;
  id?: number;
  errorResult?: ErrorResultDto;
}

const getInitialState = (): Notification[] => {
  return [];
};

const NotificationsContextGet = createContext<{
  notifications: Notification[];
}>({
  notifications: [],
});

const NotificationsContextSet = createContext<{
  notificationDispatcher: React.Dispatch<NotificationAction>;
}>({
  notificationDispatcher: () => null,
});

export enum NotificationActionType {
  showNotification,
  removeNotification,
  closeAll,
}

export type NotificationAction =
  | {
      type: NotificationActionType.showNotification;
      notification: Notification;
    }
  | {
      type: NotificationActionType.removeNotification;
      index: number;
    }
  | {
      type: NotificationActionType.closeAll;
    };

export const notificationsReducer = (state: Notification[], action: NotificationAction) => {
  switch (action.type) {
    case NotificationActionType.showNotification: {
      if (state.length > MAX_NOTIFICATIONS) {
        state.splice(state.length - MAX_NOTIFICATIONS, MAX_NOTIFICATIONS);
      }

      state.forEach((c) => {
        c.forcingClose = true;
        c.closeSeconds = 1;
      });
      action.notification.id = state.length > 0 ? (state[state.length - 1].id ?? 0) + 1 : 0;

      return [...state, action.notification];
    }
    case NotificationActionType.removeNotification: {
      const indexOf = state.findIndex((c) => {
        return c.id === action.index;
      });
      const copy = [...state];
      copy.splice(indexOf, 1);
      return copy;
    }
    case NotificationActionType.closeAll: {
      state.forEach((c) => {
        c.forcingClose = true;
        c.closeSeconds = 1;
      });
      return [...state];
    }
    default:
      return state;
  }
};

const NotificationsProvider: React.FC<
  PropsWithChildren<{
    children: any;
  }>
> = ({ children }: any) => {
  const [notifications, notificationDispatcher] = useReducer(
    notificationsReducer,
    getInitialState()
  );

  const memoNotifications = useMemo(() => {
    return { notifications: notifications };
  }, [notifications]);

  const memoNotificationDispatcher = useMemo(() => {
    return { notificationDispatcher: notificationDispatcher };
  }, [notificationDispatcher]);

  return (
    <NotificationsContextGet.Provider value={memoNotifications}>
      <NotificationsContextSet.Provider value={memoNotificationDispatcher}>
        {children}
      </NotificationsContextSet.Provider>
    </NotificationsContextGet.Provider>
  );
};

export { NotificationsContextGet, NotificationsContextSet, NotificationsProvider };
