// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminProviderWithSecureInfoDto } from "../dtos/AdminProviderWithSecureInfoDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminProviderConfig {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  get(
    apiOptions?: ApiOptions
  ): Promise<AdminProviderWithSecureInfoDto> {
    return this.apiService.apiGet("/admin-provider/config", apiOptions);
  }

  edit(
    adminProviderDto: AdminProviderWithSecureInfoDto,
    apiOptions?: ApiOptions
  ): Promise<AdminProviderWithSecureInfoDto> {
    return this.apiService.apiPut("/admin-provider/config", adminProviderDto, apiOptions);
  }

}
