// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ReportTaxesDto } from "../dtos/ReportTaxesDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiReportTaxes {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getSimple(
    startPeriodYear: number,
    startPeriodMonth: number,
    periods: number,
    apiOptions?: ApiOptions
  ): Promise<ReportTaxesDto> {
    return this.apiService.apiGet("/reports/taxes/simple?" + 
      (startPeriodYear ? "&startPeriodYear=" + startPeriodYear : "") + 
      (startPeriodMonth ? "&startPeriodMonth=" + startPeriodMonth : "") + 
      (periods ? "&periods=" + periods : ""), apiOptions);
  }

}
