// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { RequestedExportDto } from "../dtos/RequestedExportDto";
import { ContactExportRequestDto } from "../dtos/ContactExportRequestDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiExportContact {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  default(
    exportRequest: ContactExportRequestDto,
    apiOptions?: ApiOptions
  ): Promise<RequestedExportDto> {
    return this.apiService.apiPost("/export/contact", exportRequest, apiOptions);
  }

}
