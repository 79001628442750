// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { RequestedExportDto } from "../dtos/RequestedExportDto";
import { ReportPartialOperationRequestDto } from "../dtos/ReportPartialOperationRequestDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiReportPartialOperation {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  requestDownload(
    request: ReportPartialOperationRequestDto,
    apiOptions?: ApiOptions
  ): Promise<RequestedExportDto> {
    return this.apiService.apiPost("/reports/partial-operations/request-download", request, apiOptions);
  }

}
