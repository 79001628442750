import { nameof } from "ts-simple-nameof";
import { AccountBranchDto } from "../../../../api/app/dtos/AccountBranchDto";
import { FiltersDto } from "../../../../api/shared/dtos/FiltersDto";
import { SuggestItemDto } from "../../../../api/shared/dtos/SuggestItemDto";
import { SuggestSearchDto } from "../../../../api/shared/dtos/SuggestSearchDto";
import { useApiAccountBranch } from "../../../../api/shared/hooks/useApiApp";
import InputAutocompleteBase, { PropsInputAutocompleteBase } from "./InputAutocompleteBase";

export type PropsInputAutocompleteAccountBranch = Omit<
  PropsInputAutocompleteBase<AccountBranchDto>,
  "onGetEntityById"
>;

const InputAutocompleteAccountBranch = (props: PropsInputAutocompleteAccountBranch) => {
  const [apiAccountBranch] = useApiAccountBranch();

  const apiRequest = (echoId: number, term: string, isInitialLoad: boolean) => {
    const searchDto = new SuggestSearchDto<FiltersDto>();
    searchDto.echoId = echoId;
    searchDto.filters = new FiltersDto();
    searchDto.filters.defaultTextSearch = term;
    searchDto.isInitialLoad = isInitialLoad;
    searchDto.orderBy = [{ columnName: nameof<AccountBranchDto>((p) => p.branchName), asc: true }];
    const apiOptions = {
      preventSpinner: true,
    };
    return apiAccountBranch.suggestAll(searchDto, apiOptions);
  };

  return (
    <InputAutocompleteBase
      {...props}
      value={props.value}
      onGetEntityById={(id: string) => apiAccountBranch.getById(id, { preventSpinner: true })}
      onMapFromItemSuggest={(item: SuggestItemDto) => {
        const accountBranchDto = new AccountBranchDto();
        accountBranchDto.id = item.id;
        accountBranchDto.branchName = item.label;
        return accountBranchDto;
      }}
      onServerSideSuggest={apiRequest}
    />
  );
};

export default InputAutocompleteAccountBranch;
