import { forwardRef } from "react";
import { nameof } from "ts-simple-nameof";
import { AccountBranchSimpleDto } from "../../../../api/app/dtos/AccountBranchSimpleDto";
import { OmitFormAutocompleteProps } from "../../inputs/autocomplete/InputAutocompleteBase";
import InputAutocompleteSwitchAccountBranch, {
  PropsInputAutocompleteSwitchAccountBranch,
} from "../../inputs/autocomplete/InputAutocompleteSwitchAccountBranch";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormAutocompleteSwitchAccountBranch
  extends PropsFormInput<AccountBranchSimpleDto>,
    Omit<PropsInputAutocompleteSwitchAccountBranch, OmitFormAutocompleteProps> {}

const FormAutocompleteSwitchAccountBranch = forwardRef(
  (props: PropsFormAutocompleteSwitchAccountBranch, ref?: any) => {
    const inputProps = useFormInputInit(props, ref, {
      updateIdLinkedProperty: true,
      inputTextValuePropertyName: nameof<AccountBranchSimpleDto>((p) => p.displayName),
    });
    return (
      <InputAutocompleteSwitchAccountBranch preventClearButton={true} {...props} {...inputProps} />
    );
  }
);

export default FormAutocompleteSwitchAccountBranch;
