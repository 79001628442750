import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminAccountDto } from "../../api/admin/dtos/AdminAccountDto";
import { AdminAccountFiltersDto } from "../../api/admin/dtos/AdminAccountFiltersDto";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiAdminProviderAdminAccount } from "../../api/shared/hooks/useApiAdminProviders";
import { AppRoute } from "../../AppRoutes";
import { ButtonCreate } from "../../shared/components/Button/ButtonCreate";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import FormSelectAdminShard from "../../shared/components/forms/Select/FormSelectAdminShard";
import Grid from "../../shared/components/grid/Grid";
import {
  GridFilterType,
  IGridItemTemplate,
  MobileSpecialType,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import { TypeIcon } from "../../shared/components/Icon";
import RowButtonsToolbar from "../../shared/components/Layout/GRowButtonsToolbar";
import Page from "../../shared/components/Layout/Page";
import LogoAccount from "../../shared/components/LogoAccount";
import { AppInfoSiteType } from "../../shared/context/AppInfoProvider";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { useModal } from "../../shared/hooks/useModal";
import { useNavigation } from "../../shared/hooks/useNavigation";
import AdminAccountCreatePopup from "./AdminAccountCreatePopup";
import getAdminAccountListItemMenu from "./AdminAccountList.ItemMenu";

const AdminAccountList = () => {
  const [modal] = useModal();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [apiAdminProviderAdminAccount] = useApiAdminProviderAdminAccount();
  const { appInfo } = useContextAppInfo();
  const navigation = useNavigation();
  const [initialFilters] = useState<AdminAccountFiltersDto>(() => {
    const filters = new AdminAccountFiltersDto();
    filters.crossShardIncludeAllProviders = appInfo?.siteType == AppInfoSiteType.SuperAdmin;
    return filters;
  });

  if (!appInfo) {
    return <></>;
  }

  const itemTemplates: IGridItemTemplate<AdminAccountDto>[] = [
    {
      header: "País",
      field: nameof<AdminAccountDto>((p) => p.country),
      filter: {
        propertyName: nameof<AdminAccountFiltersDto>((p) => p.country),
        type: GridFilterType.countryAvailable,
      },
      width: 10,
      cellTypeEnumName: enumName.Country,
    },
    {
      header: "Logo",
      field: nameof<AdminAccountDto>((p) => p.logoSmallUrl),
      width: 10,
      mobileSpecialType: MobileSpecialType.Secondary,
      relatedFields: [nameof<AdminAccountDto>((p) => p.name)],
      render: (adminAccount: AdminAccountDto) => (
        <LogoAccount style={{ marginTop: -9 }} adminAccount={adminAccount} />
      ),
    },
    {
      header: "Empresa",
      field: nameof<AdminAccountDto>((p) => p.name),
      filter: {
        propertyName: nameof<AdminAccountFiltersDto>((p) => p.name),
        type: GridFilterType.text,
      },
      mobileSpecialType: MobileSpecialType.Main,
      width: 30,
    },
    {
      header: "RUT",
      field: nameof<AdminAccountDto>((p) => p.company.taxPayerId),
      filter: {
        propertyName: nameof<AdminAccountFiltersDto>((p) => p.taxPayerId),
        type: GridFilterType.text,
      },
      width: 30,
    },
    {
      header: "Proveedor",
      field: nameof<AdminAccountDto>((p) => p.provider.name),
      hideByDefault: appInfo.siteType != AppInfoSiteType.SuperAdmin,
      filter: {
        propertyName: nameof<AdminAccountFiltersDto>((p) => p.provider),
        type: GridFilterType.adminProviders,
      },
      width: 30,
    },
    {
      header: "Zona horaria",
      field: nameof<AdminAccountDto>((p) => p.timeZoneId),
      width: 20,
    },
    {
      header: "Locale",
      field: nameof<AdminAccountDto>((p) => p.locale),
      width: 20,
      cellTypeEnumName: enumName.AppLocale,
    },
    {
      header: "Sucursales",
      field: nameof<AdminAccountDto>((p) => p.activeBranchesCount),
      width: 10,
    },
    {
      header: "Shard",
      filter: {
        propertyName: nameof<AdminAccountFiltersDto>((p) => p.shardId),
        type: GridFilterType.custom,
        element: (
          <FormSelectAdminShard
            label="Shard"
            propertyName={nameof<AdminAccountFiltersDto>((p) => p.shardId)}
          />
        ),
      },
      field: nameof<AdminAccountDto>((p) => p.shard.name),
      width: 30,
    },
  ];

  return (
    <Page
      title="Cuentas"
      titleButton={
        <ButtonCreate
          text="Nueva cuenta"
          onClick={() => modal.open(<AdminAccountCreatePopup />, () => setReloadGrid(new Date()))}
        />
      }>
      <RowButtonsToolbar>
        <ButtonSecondary
          icon={TypeIcon.details}
          text="Ver sucursales"
          onClick={() =>
            navigation.go(
              appInfo.siteType == AppInfoSiteType.SuperAdmin
                ? AppRoute.admin.accountBranches.base
                : AppRoute.adminProvider.accountBranches.base
            )
          }
        />
      </RowButtonsToolbar>
      <Grid
        itemTemplates={itemTemplates}
        reloadGrid={reloadGrid}
        initialFilters={initialFilters}
        onSearch={(search, options) => apiAdminProviderAdminAccount.getPaged(search, options)}
        onDisplayItemMenu={(adminAccountDto: AdminAccountDto) =>
          getAdminAccountListItemMenu(adminAccountDto, modal, appInfo.siteType, navigation, () =>
            setReloadGrid(new Date())
          )
        }
      />
    </Page>
  );
};

export default AdminAccountList;
