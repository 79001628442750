import ValidationField from "./ValidationField";
import ValidationFieldCollectionItem from "./ValidationFieldCollectionItem";
import ValidationSchema from "./ValidationSchema";

export default class ValidationFieldCollection extends ValidationField {
  constructor(
    public validationFieldCollectionItem: ValidationFieldCollectionItem,
    propertyFriendlyName: string
  ) {
    super(propertyFriendlyName);
  }
}

export function validateCollection(
  itemSchema: ValidationSchema,
  propertyFriendlyName: string
): ValidationFieldCollection {
  return new ValidationFieldCollection(
    new ValidationFieldCollectionItem(itemSchema),
    propertyFriendlyName
  );
}
