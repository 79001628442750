import { AppRoute } from "../../AppRoutes";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Row from "../../shared/components/Layout/GRow";
import Section from "../../shared/components/Layout/GSection";
import Page from "../../shared/components/Layout/Page";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { TextAlign } from "../../shared/TextAlign";
import { ThemeSize } from "../../shared/ThemeSize";

const AdminHome = () => {
  const navigation = useNavigation();
  return (
    <Page title="Inicio SuperAdmin">
      <Section>
        <Row>
          <ComponentGroup align={TextAlign.center}>
            <ButtonSecondary
              text="Proveedores"
              icon={TypeIcon.adminProvider}
              size={ThemeSize.medium}
              onClick={() => navigation.go(AppRoute.admin.providers.base)}
            />

            <ButtonSecondary
              text="Config"
              icon={TypeIcon.config}
              size={ThemeSize.medium}
              onClick={() => navigation.go(AppRoute.admin.config)}
            />

            <ButtonSecondary
              text="Procesos"
              icon={TypeIcon.server}
              size={ThemeSize.medium}
              onClick={() => navigation.go(AppRoute.admin.longProcessLog)}
            />

            <ButtonSecondary
              text="ServiceBus"
              icon={TypeIcon.config}
              size={ThemeSize.medium}
              onClick={() => navigation.go(AppRoute.admin.serviceBus)}
            />

            <ButtonSecondary
              text="Clientes POS"
              icon={TypeIcon.pointOfSale}
              size={ThemeSize.medium}
              onClick={() => navigation.go(AppRoute.admin.pointOfSaleClients)}
            />
          </ComponentGroup>
        </Row>
      </Section>
    </Page>
  );
};

export default AdminHome;
