// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminTimeZoneDto } from "../dtos/AdminTimeZoneDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminProviderTimeZone {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getAll(
    apiOptions?: ApiOptions
  ): Promise<AdminTimeZoneDto[]> {
    return this.apiService.apiGet("/admin-provider/time-zone/all", apiOptions);
  }

}
