import { useContext, useEffect } from "react";
import { nameof } from "ts-simple-nameof";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import { Currency } from "../../api/shared/enums/Currency";
import { InvoiceTypeGroup } from "../../api/shared/enums/InvoiceTypeGroup";
import { PaymentType } from "../../api/shared/enums/PaymentType";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiCurrencyExchangeRate } from "../../api/shared/hooks/useApiApp";
import { Constants } from "../../Constants";
import { AppSize } from "../../shared/AppSize";
import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import FormAutocompleteSaleSenderBehalf from "../../shared/components/forms/Autocomplete/FormAutocompleteSaleSenderBehalf";
import FormDate from "../../shared/components/forms/FormDate";
import FormSelectCurrency from "../../shared/components/forms/Select/FormSelectCurrency";
import FormSelectEnum from "../../shared/components/forms/Select/FormSelectEnum";
import { TypeIcon } from "../../shared/components/Icon";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Row from "../../shared/components/Layout/GRow";
import RowDisplay from "../../shared/components/Layout/GRowDisplay";
import { TaxValueContext } from "../../shared/components/TaxValue/TaxValueProvider";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import useUtils from "../../shared/hooks/useUtils";
import SaleFormInfoCustomer from "./SaleForm.Info.Customer";
import SaleFormInfoInvoiceDeliveryNote from "./SaleForm.Info.DeliveryNote";
import SaleFormInfoInvoiceExport from "./SaleForm.Info.Export";
import SaleFormInfoInvoiceType from "./SaleForm.Info.InvoiceType";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import { SalePageType } from "./SalePage";
import { SaleViewModel } from "./ViewModels/SaleViewModel";

const SaleFormInfo = (props: { pageType: SalePageType }) => {
  const taxValueContext = useContext(TaxValueContext) as any;
  const { loginInfo } = useContextLoginInfo();
  const [apiCurrencyExchangeName] = useApiCurrencyExchangeRate();
  const modelState = useContextModelStateSale();
  const sale = modelState.sale as SaleViewModel;
  const { utils } = useUtils();

  const getCurrencyExchange = async (
    currency: Currency | null,
    draftIsNotCurrentDate: boolean,
    date?: Date | null
  ) => {
    if (!draftIsNotCurrentDate) {
      date = new Date();
    }

    if (!date || !currency || currency == loginInfo.authInfo.accountConfig.nationalCurrency) {
      modelState.updateCurrencyExchangeRate(1);
      return;
    }

    const exchangeRate = await apiCurrencyExchangeName.getForCurrency(currency, date);
    modelState.updateCurrencyExchangeRate(exchangeRate?.exchangeRate);
  };

  useEffect(() => {
    getCurrencyExchange(sale.currency, sale.draftIsNotCurrentDate, sale.date);
  }, []);

  return (
    <>
      <SaleFormInfoInvoiceType
        invoiceType={sale.invoiceType}
        invoiceTypeGroup={sale.invoiceTypeGroup}
      />
      {sale.draftIsNotCurrentDate && (
        <>
          <Row>
            <ComponentGroup>
              <FormDate
                includeTime={true}
                label="Fecha"
                maxDate={new Date()}
                propertyName={nameof<SaleDto>((p) => p.date)}
                onValueChange={(date) => {
                  taxValueContext.setCurrentDate(date);
                  getCurrencyExchange(sale.currency, sale.draftIsNotCurrentDate, date);
                }}
              />
              <ButtonIcon
                icon={TypeIcon.undo}
                tooltip="Utilizar fecha actual del sistema"
                onClick={() => {
                  modelState.updateDate(new Date());
                  modelState.updateDraftIsNotCurrentDate(false);
                }}
              />
            </ComponentGroup>
          </Row>
        </>
      )}
      {!sale.draftIsNotCurrentDate && (
        <RowDisplay
          label="Fecha"
          minHeightIgnore={true}
          buttonIcon={
            <ButtonIcon
              icon={TypeIcon.edit}
              size={AppSize.small}
              onClick={() => {
                modelState.updateDate(new Date());
                modelState.updateDraftIsNotCurrentDate(true);
              }}
            />
          }>
          {utils.date.toString(new Date())}
        </RowDisplay>
      )}
      {sale.isSaleSenderBehalf && (
        <Row>
          <FormAutocompleteSaleSenderBehalf
            label="Emisor cuenta ajena"
            propertyName={nameof<SaleDto>((p) => p.senderBehalf)}
          />
        </Row>
      )}
      <Row>
        <FormSelectCurrency
          label="Moneda"
          propertyName={nameof<SaleDto>((p) => p.currency)}
          onValueChange={(currency) => {
            getCurrencyExchange(currency, sale.draftIsNotCurrentDate, sale.date);
          }}
          hint={
            sale.currencyExchangeRate != 1
              ? "Cotización " +
                utils.number.toString(
                  sale.currencyExchangeRate,
                  Constants.CURRENCY_EXCHANGE_DECIMALS
                )
              : ""
          }
        />
      </Row>
      <Row>
        <FormSelectEnum
          label="Pago"
          enumTypeName={enumName.PaymentType}
          propertyName={nameof<SaleDto>((p) => p.paymentType)}
          onValueChange={(value) => {
            if (value == PaymentType.Cash) {
              modelState.updatePaymentExpirationDate(null);
            }
          }}
        />
      </Row>

      {sale.paymentType == PaymentType.Credit && (
        <Row>
          <FormDate
            label="Vencimiento"
            readOnly={sale.paymentType != PaymentType.Credit}
            propertyName={nameof<SaleDto>((p) => p.paymentExpirationDate)}
          />
        </Row>
      )}

      <SaleFormInfoCustomer pageType={props.pageType} />

      {sale.saleInfo.isExport && <SaleFormInfoInvoiceExport />}
      {sale.invoiceTypeGroup == InvoiceTypeGroup.ERemito && <SaleFormInfoInvoiceDeliveryNote />}
    </>
  );
};

export default SaleFormInfo;
