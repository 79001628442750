import { nameof } from "ts-simple-nameof";
import { AdminTemplateDesignDto } from "../../../api/admin/dtos/AdminTemplateDesignDto";
import { AdminTemplateEmailDto } from "../../../api/admin/dtos/AdminTemplateEmailDto";
import { TemplateDesignFieldType } from "../../../api/shared/enums/TemplateDesignFieldType";
import FormText from "../../../shared/components/forms/FormText";
import Column from "../../../shared/components/Layout/GColumn";
import Section from "../../../shared/components/Layout/GSection";
import InputSwitch from "../inputs/InputSwitch";
import { TemplateConstants } from "./TemplateConstants";

const TemplateEditorEmailInfo = (props: {
  attachments: string;
  designDto: AdminTemplateDesignDto;
  onAttachmentChange(value: string): void;
}) => {
  return (
    <Section border={true}>
      <Column md={6}>
        <FormText label="Asunto" propertyName={nameof<AdminTemplateEmailDto>((p) => p.subject)} />
      </Column>
      <Column label="Archivos adjuntos" md={6}>
        {props.designDto.fields
          .filter((f) => f.fieldType == TemplateDesignFieldType.EmailAttachment)
          .map((f) => (
            <InputSwitch
              key={f.code}
              label={f.name.replace("Adjunto: ", "")}
              value={
                (props.attachments ?? "").indexOf(
                  TemplateConstants.TAG_VALUE + f.code + TemplateConstants.TAG_VALUE
                ) != -1
              }
              onChange={(value) => {
                if (value) {
                  props.onAttachmentChange(
                    (props.attachments ?? "") +
                      TemplateConstants.TAG_VALUE +
                      f.code +
                      TemplateConstants.TAG_VALUE
                  );
                } else {
                  props.onAttachmentChange(
                    (props.attachments ?? "").replace(
                      TemplateConstants.TAG_VALUE + f.code + TemplateConstants.TAG_VALUE,
                      ""
                    )
                  );
                }
              }}
            />
          ))}
      </Column>
    </Section>
  );
};

export default TemplateEditorEmailInfo;
