import { nameof } from "ts-simple-nameof";
import { AdminEmailContactSupportDto } from "../../api/admin/dtos/AdminEmailContactSupportDto";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormText from "../../shared/components/forms/FormText";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";

const EmailContactSupportPopupForm = (props: { onSubmit: () => void }) => {
  return (
    <>
      <Row>
        <FormText
          label="Asunto"
          propertyName={nameof<AdminEmailContactSupportDto>((p) => p.subject)}
        />
      </Row>
      <Row>
        <FormText
          label="Mensaje"
          multiline={true}
          propertyName={nameof<AdminEmailContactSupportDto>((p) => p.message)}
        />
      </Row>
      <RowButtons>
        <ButtonAccept text="Enviar mensaje" onClick={props.onSubmit} />
      </RowButtons>
    </>
  );
};

export default EmailContactSupportPopupForm;
