import { ButtonVariant } from "../../ButtonVariant";
import { ThemeSize } from "../../ThemeSize";
import Button, { PropsButton } from "./Button";

export const ButtonSecondary = (props: PropsButton) => {
  return (
    <Button
      size={ThemeSize.small}
      variant={ButtonVariant.outlined}
      {...props}
    />
  );
};

export default ButtonSecondary;
