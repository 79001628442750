import { ContactDto } from "../../api/app/dtos/ContactDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import { ModalService } from "../../shared/hooks/useModal";
import ContactDetailsPopup from "./ContactDetailsPopup";
import ContactEditPopup from "./ContactEditPopup";

export function getContactListItemMenu(
  contactDto: ContactDto,
  modal: ModalService,
  onGridReload: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        modal.open(<ContactDetailsPopup id={contactDto.id} />, onGridReload);
      },
    },
    {
      text: "Editar",
      icon: TypeIcon.edit,
      onClick: () => {
        modal.open(<ContactEditPopup id={contactDto.id} />, onGridReload);
      },
    },
  ];
}
