import { forwardRef } from "react";
import InputFile, { PropsInputFile } from "../inputs/InputFile";
import { PropsFormInput, useFormInputInit } from "./_formInputProps";

interface PropsFormFile
  extends PropsFormInput<string | null>,
    Omit<PropsInputFile, "value" | "onChange"> {}

const FormFile = forwardRef((props: PropsFormFile, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputFile {...props} {...inputProps} />;
});

export default FormFile;
