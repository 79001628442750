import ValidationExpression from "./ValidationExpression";
import ValidationField from "./ValidationField";
import { ValidationMessages } from "./ValidationMessages";

export default class ValidationExpressionTree {
  whenExpression?: (baseModel: any, baseModelList: any[]) => boolean;
  nestedExpressionsTrees: ValidationExpressionTree[] = [];
  expressions: ValidationExpression[] = [];

  constructor(public validationField: ValidationField) {}

  private continue(): ValidationExpressionTree {
    this.validationField.continueBuilding(this);
    return this;
  }

  when(condition: (baseModel: any, baseModelList: any[]) => boolean): ValidationExpressionTree {
    const nestedTree = new ValidationExpressionTree(this.validationField);
    nestedTree.whenExpression = condition;
    this.nestedExpressionsTrees.push(nestedTree);
    return nestedTree.continue();
  }

  verify(
    expression: (value: any, baseModel: any, baseModelList: any[]) => boolean,
    message?: string
  ): ValidationExpressionTree {
    this.expressions.push(
      new ValidationExpression(
        expression,
        message ??
          ValidationMessages.MessageFieldInvalid(this.validationField.propertyNameFriendly ?? "")
      )
    );
    return this.continue();
  }

  email(message?: string): ValidationExpressionTree {
    if (!message && !this.validationField.propertyNameFriendly) {
      throw Error(ValidationMessages.DevelopErrorFriendlyNameRequired);
    }

    const errorMessage =
      message ??
      ValidationMessages.MessageFieldInvalid(this.validationField.propertyNameFriendly ?? "");

    this.expressions.push(
      new ValidationExpression((value?: any) => {
        return !value || /^\S+@\S+\.\S+$/.test(value);
      }, errorMessage)
    );

    return this.continue();
  }

  required(message?: string): ValidationExpressionTree {
    if (!message && !this.validationField.propertyNameFriendly) {
      throw Error(ValidationMessages.DevelopErrorFriendlyNameRequired);
    }

    const errorMessage =
      message ??
      ValidationMessages.MessageFieldRequired(this.validationField.propertyNameFriendly ?? "");

    this.expressions.push(
      new ValidationExpression((value?: any) => {
        if (value instanceof Array) {
          return value.length > 0;
        }
        return value !== null && value !== undefined && value !== "" && value !== 0;
      }, errorMessage)
    );

    return this.continue();
  }
}
