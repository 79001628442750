import { nameof } from "ts-simple-nameof";
import { AuthSwitchPointOfSaleDto } from "../../../api/app/dtos/AuthSwitchPointOfSaleDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

export function useSwitchPointOfSaleValidation() {
  const schema = new ValidationSchema();

  schema[nameof<AuthSwitchPointOfSaleDto>((p) => p.pointOfSale)] =
    validate("Punto de venta").required();

  schema[nameof<AuthSwitchPointOfSaleDto>((p) => p.adminAccount)] = validate("Cuenta").required();

  schema[nameof<AuthSwitchPointOfSaleDto>((p) => p.accountBranch)] =
    validate("Sucursal").required();

  return schema;
}
