import { useEffect, useState } from "react";
import { AdminProviderDto } from "../../api/admin/dtos/AdminProviderDto";
import { AdminProviderSecureSettingsDto } from "../../api/admin/dtos/AdminProviderSecureSettingsDto";
import { AdminProviderSettingsDto } from "../../api/admin/dtos/AdminProviderSettingsDto";
import { AdminProviderWithSecureInfoDto } from "../../api/admin/dtos/AdminProviderWithSecureInfoDto";
import { useApiAdminProvider } from "../../api/shared/hooks/useApiAdmin";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AdminProviderForm from "./AdminProviderForm";
import { useAdminProviderValidator } from "./Validations/useAdminProviderValidations";

const AdminProviderCreatePopup = () => {
  const [adminProviderDto] = useState<AdminProviderDto>(() => {
    const adminProvider = new AdminProviderWithSecureInfoDto();
    adminProvider.providerSettings = new AdminProviderSettingsDto();
    adminProvider.providerSecureSettings = new AdminProviderSecureSettingsDto();
    return adminProvider;
  });

  const [modal] = useModal();
  const [apiAdminProvider] = useApiAdminProvider();
  const save = async (adminProviderDto: AdminProviderWithSecureInfoDto) => {
    const created = await apiAdminProvider.create(adminProviderDto);
    modal.closeAsSuccess(created, "El proveedor se creó con éxito");
  };

  useEffect(() => modal.setVisible("Crear proveedor", ModalSize.small), [modal]);

  if (!adminProviderDto) {
    return null;
  }

  return (
    <Container>
      <StateProvider
        model={adminProviderDto}
        onSubmit={save}
        validationSchema={useAdminProviderValidator()}>
        {(p) => <AdminProviderForm onSave={p.handleSubmit} isEdit={false} />}
      </StateProvider>
    </Container>
  );
};

export default AdminProviderCreatePopup;
