// File generated by Gesta.Build.PostEvents
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { Country } from "../../shared/enums/Country";
import { AdminProviderDto } from "./AdminProviderDto";

export class AdminAccountFiltersDto extends FiltersDto {
  name: string = "";
  country: Country;
  shardId: string;
  providerId: string;
  provider: AdminProviderDto;
  taxPayerId: string = "";
}
