// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { RequestedExportDto } from "../dtos/RequestedExportDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { RequestedExportFiltersDto } from "../dtos/RequestedExportFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";

export class ApiRequestedExport {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  cancel(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/requested-export/cancel/" + id, null, apiOptions);
  }

  getForDashboard(
    includedDownloaded: boolean,
    apiOptions?: ApiOptions
  ): Promise<RequestedExportDto[]> {
    return this.apiService.apiGet("/requested-export/for-dashboard?" + 
      (includedDownloaded ? "&includedDownloaded=" + includedDownloaded : ""), apiOptions);
  }

  getPaged(
    searchDto: PagedSearchDto<RequestedExportFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<RequestedExportDto>> {
    return this.apiService.apiPost("/requested-export/paged", searchDto, apiOptions);
  }

  download(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiGet("/requested-export/download/" + id, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<RequestedExportDto> {
    return this.apiService.apiGet("/requested-export/" + id, apiOptions);
  }

  inactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<RequestedExportDto> {
    return this.apiService.apiPut("/requested-export/inactivate/" + id, null, apiOptions);
  }

}
