import { nameof } from "ts-simple-nameof";
import { FilterRangeNumberDto } from "../../../../api/shared/dtos/FilterRangeNumberDto";
import { useContextModelState } from "../../../modelState/useContextModelState";
import { ThemeVariant } from "../../../ThemeVariant";
import InputNumber from "../../inputs/InputNumber";
import PropsInputBase from "../../inputs/PropsInputBase";
import ComponentGroup from "../../Layout/ComponentsGroup";

interface PropsFilterRangeNumber
  extends Omit<PropsInputBase<FilterRangeNumberDto | null>, "value" | "onChange"> {
  intOnly?: boolean;
  propertyName: string;
}

const FilterNumberRange = (props: PropsFilterRangeNumber) => {
  const modelState = useContextModelState();
  const filterValue = modelState.getValue(props.propertyName) as FilterRangeNumberDto;
  return (
    <ComponentGroup>
      <InputNumber
        {...props}
        variant={props.variant ?? ThemeVariant.standard}
        label={props.label + " min."}
        value={filterValue?.min ?? null}
        onChange={(value) => {
          if (value === null && filterValue.max === null) {
            modelState.updateProperty(props.propertyName, null);
            return;
          }

          if (!filterValue) {
            modelState.updateProperty(props.propertyName, new FilterRangeNumberDto());
          }
          modelState.updateProperty(
            props.propertyName + "." + nameof<FilterRangeNumberDto>((p) => p.min),
            value
          );
        }}
      />
      <InputNumber
        variant={ThemeVariant.standard}
        {...props}
        label={props.label + " max."}
        value={filterValue?.max ?? null}
        onChange={(value) => {
          if (value === null && filterValue.min === null) {
            modelState.updateProperty(props.propertyName, null);
            return;
          }

          if (!filterValue) {
            modelState.updateProperty(props.propertyName, new FilterRangeNumberDto());
          }

          modelState.updateProperty(
            props.propertyName + "." + nameof<FilterRangeNumberDto>((p) => p.max),
            value
          );
        }}
      />
    </ComponentGroup>
  );
};

export default FilterNumberRange;
