// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { LongProcessStatusInfoDto } from "../../shared/dtos/LongProcessStatusInfoDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminLongProcess {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getByProcessTypeName(
    accountId: string,
    type: number,
    apiOptions?: ApiOptions
  ): Promise<LongProcessStatusInfoDto> {
    return this.apiService.apiGet("/admin/long-process?" + 
      (accountId ? "&accountId=" + accountId : "") + 
      (type ? "&type=" + type : ""), apiOptions);
  }

  getByEInvoiceCreateTestingDataSetLongProcess(
    accountId: string,
    apiOptions?: ApiOptions
  ): Promise<LongProcessStatusInfoDto> {
    return this.apiService.apiGet("/admin/long-process/for-einvoice-create-testing-data-set?" + 
      (accountId ? "&accountId=" + accountId : ""), apiOptions);
  }

}
