import { TypeIcon } from "../Icon";
import ButtonIcon from "./ButtonIcon";

const ButtonIconFileDownload = (props: {
  icon?: any;
  fileUrl: string;
  noPadding?: boolean;
  tooltip?: string;
}) => {
  return (
    <ButtonIcon
      disabled={!props.fileUrl}
      icon={props.icon ?? TypeIcon.download}
      noPadding={props.noPadding}
      tooltip={
        props.fileUrl
          ? props.tooltip ?? "Descargar archivo"
          : (props.tooltip ? props.tooltip + " - " : null) + "Archivo no disponible para descargar"
      }
      onClick={() => {
        if (props.fileUrl) {
          window.open(props.fileUrl);
        }
      }}
    />
  );
};

export default ButtonIconFileDownload;
