import { useEffect, useState } from "react";
import { AdminAccountCompanyDto } from "../../api/admin/dtos/AdminAccountCompanyDto";
import { AdminAccountContactDto } from "../../api/admin/dtos/AdminAccountContactDto";
import { AdminAccountDto } from "../../api/admin/dtos/AdminAccountDto";
import { AdminAddressDto } from "../../api/admin/dtos/AdminAddressDto";
import { AdminProviderDto } from "../../api/admin/dtos/AdminProviderDto";
import { Country } from "../../api/shared/enums/Country";
import { useApiAdminProviderAdminAccount } from "../../api/shared/hooks/useApiAdminProviders";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AdminAccountForm from "./AdminAccountForm";
import { useAdminAccountValidation } from "./Validations/useAdminAccountValidation";

const AdminAccountCreatePopup = () => {
  const [modal] = useModal();
  const [apiAdminProviderAdminAccount] = useApiAdminProviderAdminAccount();
  const { appInfo } = useContextAppInfo();

  const [adminAccount] = useState<AdminAccountDto>(() => {
    const account = new AdminAccountDto();
    if (appInfo) {
      account.country = appInfo.adminProviderInfo.country ?? Country.Uruguay;
      account.provider = new AdminProviderDto();
      account.provider.id = appInfo.adminProviderInfo.id;
      account.provider.name = appInfo.adminProviderInfo.name;
    }
    account.company = new AdminAccountCompanyDto();
    account.company.address = new AdminAddressDto();
    account.contact = new AdminAccountContactDto();
    account.company.eInvoiceIsTaxUpToDate = true;
    return account;
  });

  const save = async (adminAccount: AdminAccountDto) => {
    await apiAdminProviderAdminAccount.create(adminAccount);
    modal.closeAsSuccess(null, "La cuenta se creó con éxito");
  };

  useEffect(() => {
    modal.setVisible("Crear cuenta", ModalSize.small);
  }, [modal]);

  if (!adminAccount) {
    return null;
  }

  return (
    <Container>
      <StateProvider
        model={adminAccount}
        onSubmit={save}
        validationSchema={useAdminAccountValidation()}>
        {(p) => <AdminAccountForm onSave={p.handleSubmit} isEdit={false} />}
      </StateProvider>
    </Container>
  );
};

export default AdminAccountCreatePopup;
