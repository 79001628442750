import ValidationExpressionTree from "../schema/ValidationExpressionTree";
import ValidationField from "../schema/ValidationField";
import ValidationContextRoot from "./ValidationContextRoot";
import ValidationFieldsTrackBase from "./ValidationFieldsTrackBase";
import ValidationResultBase from "./ValidationResultBase";

export default abstract class ValidationContext {
  isValid = true;
  validations: string[] = [];

  constructor(
    public parent: ValidationContext | undefined,
    public propertyName: string,
    public collectionItemIndex: number | undefined,
    public propertyFriendlyName?: string,
    public fieldsTrack?: ValidationFieldsTrackBase,
    public modelValue?: any
  ) {}

  getModelValueList(): any[] {
    if (this.parent) {
      return [this.modelValue].concat(this.parent.getModelValueList());
    }
    return [this.modelValue];
  }

  getPropertyFullName(): string {
    const base = this.parent?.getPropertyFullName();
    return (
      (base ? base + "." : "") +
      this.propertyName +
      (this.collectionItemIndex !== undefined ? "[" + this.collectionItemIndex + "]" : "")
    );
  }

  getPropertyFullFriendlyName(): string {
    if (!this.propertyName && this.collectionItemIndex === undefined) {
      return this.parent?.getPropertyFullFriendlyName() ?? "";
    }

    const base = this.parent?.getPropertyFullFriendlyName();
    if (this.collectionItemIndex !== undefined) {
      return base + " [" + (this.collectionItemIndex + 1) + "]";
    }
    const friendlyName =
      (base ? base + " - " : "") + (this.propertyFriendlyName ?? this.propertyName);
    return friendlyName;
  }

  getRoot(): ValidationContextRoot {
    return this.parent ? this.parent.getRoot() : (this as any as ValidationContextRoot);
  }

  abstract getValidationResult(trySubmitCount: number): ValidationResultBase;

  private validateExpressionTree(expressionTree: ValidationExpressionTree) {
    if (expressionTree.whenExpression) {
      if (
        !expressionTree.whenExpression(
          this.parent?.modelValue,
          this.parent?.getModelValueList() ?? []
        )
      ) {
        return;
      }
    }

    for (const expression of expressionTree.expressions) {
      if (
        !expression.validate(
          this.modelValue,
          this.parent?.modelValue,
          this.parent?.getModelValueList() ?? []
        )
      ) {
        this.validations.push(expression.validationMessage);
      }
    }

    for (const nestedExpressionTree of expressionTree.nestedExpressionsTrees) {
      this.validateExpressionTree(nestedExpressionTree);
    }
  }

  protected validateExpressions(validationField: ValidationField) {
    this.validateExpressionTree(validationField.expressionsTree);
  }
}
