import { useCallback, useEffect, useState } from "react";
import { EmailConfigDto } from "../../../api/app/dtos/EmailConfigDto";
import { EmailConfigValidationStatus } from "../../../api/shared/enums/EmailConfigValidationStatus";
import { enumName } from "../../../api/shared/enums/_enumName";
import { useApiEmailConfig } from "../../../api/shared/hooks/useApiApp";
import ButtonIcon from "../../../shared/components/Button/ButtonIcon";
import ButtonSecondary from "../../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../../shared/components/Icon";
import IconText from "../../../shared/components/IconText";
import Column from "../../../shared/components/Layout/GColumn";
import ColumnDisplay from "../../../shared/components/Layout/GColumnDisplay";
import Row from "../../../shared/components/Layout/GRow";
import Section from "../../../shared/components/Layout/GSection";
import Page from "../../../shared/components/Layout/Page";
import { useModal } from "../../../shared/hooks/useModal";
import useUtils from "../../../shared/hooks/useUtils";
import { TextAlign } from "../../../shared/TextAlign";
import { AppThemeColor } from "../../../styles/color";
import ConfigEmailCreatePopup from "./ConfigEmailCreatePopup";
import ConfigEmailEditPopup from "./ConfigEmailEditPopup";
import ConfigEmailEInvoicePending from "./ConfigEmailEInvoicePending";

const ConfigEmailList = () => {
  const [modal] = useModal();
  const [emailConfigList, setEmailConfigList] = useState<EmailConfigDto[]>([]);
  const [apiEmailConfig] = useApiEmailConfig();
  const { utils } = useUtils();
  const [isVerifying, setIsVerifying] = useState(false);

  const edit = (emailConfig: EmailConfigDto) => {
    modal.open(<ConfigEmailEditPopup id={emailConfig.id} />, () => loadEmailConfigList());
  };

  const create = () => {
    modal.open(<ConfigEmailCreatePopup />, () => loadEmailConfigList());
  };

  const verifyRead = async (id: string) => {
    setIsVerifying(true);
    await apiEmailConfig.checkValidationAckEmail(id, { preventSpinner: true });
    setEmailConfigList(await apiEmailConfig.getAll({ preventSpinner: true }));
    setIsVerifying(false);
  };

  const verifySend = async (id: string) => {
    setIsVerifying(true);
    await apiEmailConfig.checkValidationEmail(id, { preventSpinner: true });
    setEmailConfigList(await apiEmailConfig.getAll({ preventSpinner: true }));
    setIsVerifying(false);
  };

  const loadEmailConfigList = useCallback(() => {
    const load = async () => {
      setEmailConfigList(await apiEmailConfig.getAll());
    };
    load();
  }, [apiEmailConfig]);

  useEffect(() => {
    loadEmailConfigList();
  }, [loadEmailConfigList]);

  return (
    <Page title="Correos">
      {emailConfigList?.map((emailConfig: EmailConfigDto) => (
        <Row key={emailConfig.id}>
          <Section
            background={AppThemeColor.grayLight}
            title={utils.enum.translate(enumName.EmailConfigType, emailConfig.type)}>
            <ColumnDisplay md={2} label="Usuario">
              {emailConfig.user}
            </ColumnDisplay>
            <ColumnDisplay md={1} label="Servidor smtp">
              {emailConfig.smtpServer}
            </ColumnDisplay>
            <ColumnDisplay md={1} label="Servidor imap">
              {emailConfig.imapServer}
            </ColumnDisplay>
            <ColumnDisplay md={1} label="Modo autenticación">
              {emailConfig.useOAuth ? "OAuth" : "Usuario y contraseña"}
            </ColumnDisplay>
            <Column md={2} label="Validación">
              <IconText
                icon={TypeIcon.email}
                text={utils.enum.translate(
                  enumName.EmailConfigValidationStatus,
                  emailConfig.validationStatus
                )}
              />
            </Column>
            <Column md={2} label="">
              {emailConfig.validationStatus == EmailConfigValidationStatus.SendPending && (
                <ButtonSecondary
                  loading={isVerifying}
                  text="Enviar mensaje de verificación"
                  icon={TypeIcon.email}
                  onClick={() => {
                    verifySend(emailConfig.id);
                  }}
                />
              )}
              {emailConfig.validationStatus == EmailConfigValidationStatus.ReadPending && (
                <ButtonSecondary
                  loading={isVerifying}
                  text="Comprobar mensaje de verificación"
                  icon={TypeIcon.email}
                  onClick={() => {
                    verifyRead(emailConfig.id);
                  }}
                />
              )}
            </Column>
            <Column md={3} align={TextAlign.right}>
              <ButtonIcon icon={TypeIcon.edit} onClick={() => edit(emailConfig)} />
            </Column>
          </Section>
        </Row>
      ))}
      {emailConfigList?.length == 0 && <ConfigEmailEInvoicePending onCreate={create} />}
    </Page>
  );
};

export default ConfigEmailList;
