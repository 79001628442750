import { CSSProperties } from "react";
import "./Login.scss";

const LoginRow = (props: {
  children: any;
  className?: string;
  style?: CSSProperties;
  fontSize?: number | string;
  isBottom?: boolean;
  marginBottom?: 0 | 1 | 2 | 3;
}) => {
  const styles = {
    ...props.style,
    fontSize: props.fontSize ?? "",
    marginBottom: (props.isBottom ? 0 : props.marginBottom ?? 1) * 15,
  } as CSSProperties;

  return (
    <div
      className={
        "login-layout-row " +
        (props.isBottom ? "login-layout-row-bottom " : " ") +
        (props.className ?? "")
      }
      style={styles}>
      {props.children}
    </div>
  );
};

export default LoginRow;
