import { useEffect, useState } from "react";
import { AdminAccountDto } from "../../api/admin/dtos/AdminAccountDto";
import { useApiAdminProviderAdminAccount } from "../../api/shared/hooks/useApiAdminProviders";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AdminAccountForm from "./AdminAccountForm";
import { useAdminAccountValidation } from "./Validations/useAdminAccountValidation";

const AdminAccountEditPopup = (props: { id: string }) => {
  const [adminAccount, setAdminAccount] = useState<AdminAccountDto>();
  const [apiAdminProviderAdminAccount] = useApiAdminProviderAdminAccount();
  const [modal] = useModal();

  useEffect(() => {
    const loadAdminAccount = async () => {
      setAdminAccount(await apiAdminProviderAdminAccount.getById(props.id));
      modal.setVisible("Editar cuenta", ModalSize.small);
    };
    loadAdminAccount();
  }, []);

  const save = async (adminAccount: AdminAccountDto) => {
    await apiAdminProviderAdminAccount.edit(adminAccount);
    modal.closeAsSuccess(null, "La cuenta se editó con éxito");
  };

  if (!adminAccount) {
    return <></>;
  }

  return (
    <Container>
      <StateProvider
        model={adminAccount}
        onSubmit={save}
        validationSchema={useAdminAccountValidation()}>
        {(p) => <AdminAccountForm onSave={p.handleSubmit} isEdit={true} />}
      </StateProvider>
    </Container>
  );
};

export default AdminAccountEditPopup;
