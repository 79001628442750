import { useEffect, useState } from "react";
import { AuthPasswordResetDto } from "../../api/app/dtos/AuthPasswordResetDto";
import { AppRoute } from "../../AppRoutes";
import { useApiAuth } from "../../shared/hooks/useApiAuth";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import { usePathQueryString } from "../../shared/hooks/usePathQueryString";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import LoginCard from "./Layout/LoginCard";
import { useLoginLayout } from "./Layout/LoginLayoutContext";
import LoginLink from "./Layout/LoginLink";
import LoginRow from "./Layout/LoginRow";
import LoginRowBottom from "./Layout/LoginRowBottom";
import LoginRowError from "./Layout/LoginRowError";
import LoginTitle from "./Layout/LoginTitle";
import ResetPasswordForm from "./ResetPassword.Form";

const ResetPassword = () => {
  const { login } = useContextLoginInfo();
  const pathQueryString = usePathQueryString();
  const loginLayout = useLoginLayout();
  const [apiAuth] = useApiAuth();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [authPasswordResetDto, setAuthPasswordResetDto] = useState<AuthPasswordResetDto>();

  const resetPassword = (authPasswordResetDto: AuthPasswordResetDto) => {
    setIsLoading(true);
    const resetPassword = async () => {
      try {
        await apiAuth.resetPassword(authPasswordResetDto, {
          preventSpinner: true,
          preventNotifications: true,
        });

        const loginResponse = await apiAuth.login(
          {
            email: authPasswordResetDto.userEmail,
            password: authPasswordResetDto.password,
          },
          {
            preventSpinner: true,
            preventNotifications: true,
          }
        );

        login(loginResponse.authInfo, loginResponse.authToken);
      } catch (error: any) {
        setError(error.toString());
        setIsLoading(false);
      }
    };
    resetPassword();
  };

  useEffect(() => {
    const userId = pathQueryString.get("userId");
    const token = pathQueryString.get("token");

    if (!userId) {
      setError("El identificador del usuario no es válido");
      return;
    }

    if (!token) {
      setError("El token de seguridad no es válido");
      return;
    }

    setIsLoading(true);
    setTimeout(() => {
      const loadUser = async () => {
        try {
          const authPasswordResetDto = await apiAuth.getUserForResetPassword(userId, {
            preventSpinner: true,
            preventNotifications: true,
          });
          authPasswordResetDto.token = token;
          authPasswordResetDto.password = "";
          authPasswordResetDto.confirmPassword = "";
          setAuthPasswordResetDto(authPasswordResetDto);

          setIsLoading(false);
        } catch (error: any) {
          setError(error.toString());
          setIsLoading(false);
        }
      };
      loadUser();
    }, 2000);
  }, []);

  const rowBottom = (
    <LoginRowBottom>
      <div>
        No sé porque estoy aquí,
        <LoginLink
          text="necesito ayuda"
          onClick={() => {
            loginLayout.navigateTo(AppRoute.login.register.base);
          }}
        />
      </div>
    </LoginRowBottom>
  );

  if (!authPasswordResetDto) {
    return (
      <LoginCard isLoading={isLoading}>
        <LoginTitle>Hola!</LoginTitle>
        <LoginRow>Por tu seguridad estamos verificando que todo esté correcto.</LoginRow>
        <LoginRowError message={error} />
        {rowBottom}
      </LoginCard>
    );
  }

  return (
    <LoginCard>
      <LoginTitle>Ahora sí {authPasswordResetDto.userFirstName},</LoginTitle>
      <LoginRow marginBottom={2}>Ingresá tu nueva contraseña!</LoginRow>
      <StateProvider
        model={authPasswordResetDto}
        onSubmit={(authPasswordResetDto) => resetPassword(authPasswordResetDto)}>
        {(p) => (
          <ResetPasswordForm
            isLoading={isLoading}
            onIsLoading={() => setIsLoading(true)}
            onSubmit={p.handleSubmit}
          />
        )}
      </StateProvider>
      <LoginRowError message={error} />
      {rowBottom}
    </LoginCard>
  );
};

export default ResetPassword;
