// File generated by Gesta.Build.PostEvents
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { AdminAccountSimpleDto } from "../../shared/dtos/AdminAccountSimpleDto";
import { FilterRangeNumberDto } from "../../shared/dtos/FilterRangeNumberDto";
import { FilterRangeDateDto } from "../../shared/dtos/FilterRangeDateDto";
import { EInvoiceDailyReportStatus } from "../../shared/enums/EInvoiceDailyReportStatus";

export class EInvoiceDailyReportsFiltersDto extends FiltersDto {
  accountId: string;
  account: AdminAccountSimpleDto;
  secuencialNumber: FilterRangeNumberDto;
  internalSenderId: FilterRangeNumberDto;
  reportDate: FilterRangeDateDto;
  sentDate: FilterRangeDateDto;
  errorMessage: string = "";
  statusList: EInvoiceDailyReportStatus[] = [];
}
