import { useEffect, useState } from "react";
import { Country } from "../../api/shared/enums/Country";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import InputDate from "../../shared/components/inputs/InputDate";
import InputSelectCountry from "../../shared/components/inputs/select/InputSelectCountry";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminCurrencyExchangeRate } from "../../api/shared/hooks/useApiAdmin";
import { useModal } from "../../shared/hooks/useModal";

const AdminCurrencyExchangeRatePopup = () => {
  const [country, setCountry] = useState<Country | null>(null);
  const [date, setDate] = useState<Date | null>(null);
  const [apiAdminCurrencyExchangeRate] = useApiAdminCurrencyExchangeRate();
  const [modal] = useModal();

  const getRates = async () => {
    if (date && country) {
      await apiAdminCurrencyExchangeRate.saveExchangeRatesFromExternalService(country, date);
      modal.closeAsSuccess(null, "Las cotizaciones se actualizaron con éxito");
    }
  };

  useEffect(() => {
    modal.setVisible("Cotizaciones", ModalSize.small);
  }, [modal]);

  return (
    <Container>
      <Row>
        <InputSelectCountry label="País" onlyAvailable value={country} onChange={setCountry} />
      </Row>
      <Row>
        <InputDate label="Fecha" value={date} onChange={setDate} />
      </Row>
      <RowButtons preventValidation={true}>
        <ButtonAccept onClick={getRates} />
      </RowButtons>
    </Container>
  );
};

export default AdminCurrencyExchangeRatePopup;
