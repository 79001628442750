import { nameof } from "ts-simple-nameof";
import { UserLinkedAccountDto } from "../../api/admin/dtos/UserLinkedAccountDto";
import { ButtonAccept } from "../../shared/components/Button/ButtonAccept";
import FormAutocompleteAdminAccount from "../../shared/components/forms/Autocomplete/FormAutocompleteAdminAccount";
import FormAutocompleteAdminUser from "../../shared/components/forms/Autocomplete/FormAutocompleteAdminUser";
import FormSwitch from "../../shared/components/forms/FormSwitch";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";

const UserLinkedAccountForm = (props: { onSave(): void }) => {
  return (
    <>
      <Row>
        <FormAutocompleteAdminUser
          label="Usuario"
          propertyName={nameof<UserLinkedAccountDto>((p) => p.user)}
        />
      </Row>

      <Row>
        <FormAutocompleteAdminAccount
          label="Cuenta"
          propertyName={nameof<UserLinkedAccountDto>((p) => p.account)}
        />
      </Row>

      <Row>
        <FormSwitch
          label="Es administrador"
          propertyName={nameof<UserLinkedAccountDto>((p) => p.isAccountAdmin)}
        />
      </Row>

      <RowButtons>
        <ButtonAccept onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default UserLinkedAccountForm;
