import ValidationFieldsTrackBase, { ParamSetItemValues } from "./ValidationFieldsTrackBase";

export default class ValidationFieldsTrackField extends ValidationFieldsTrackBase {
  isTouched = false;
  trySubmitCount = 0;

  getValue() {
    return this;
  }

  setChildrenValue(params: ParamSetItemValues): void {
    this.setValue("", params);
  }

  setValue(_propertyName: string, params: ParamSetItemValues) {
    if (params.isTouched !== undefined) {
      this.isTouched = params.isTouched;
    }

    if (params.incrementTryFieldSubmit !== undefined) {
      this.trySubmitCount++;
    }
  }

  public incrementTrySubmitAll(): void {
    this.trySubmitCount++;
  }

  removeArrayItem() {
    // do nothing
  }
}
