// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { UserTermsAcceptedDto } from "../dtos/UserTermsAcceptedDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { UserTermAcceptedFiltersDto } from "../dtos/UserTermAcceptedFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminProviderUserTermsAccepted {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<UserTermAcceptedFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<UserTermsAcceptedDto>> {
    return this.apiService.apiPost("/admin-provider/user-terms-accepted/paged", searchDto, apiOptions);
  }

  delete(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiDelete("/admin-provider/user-terms-accepted/delete/" + id, apiOptions);
  }

  acceptVersionUndo(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/user-terms-accepted/accept-version-undo/" + id, null, apiOptions);
  }

}
