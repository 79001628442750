import { Badge } from "@mui/material";
import { CSSProperties } from "react";
import { useAppTheme } from "../../../AppThemeProvider";
import { deepEqual } from "../../../shared/utils/utilObject";
import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import { TextAlign } from "../../TextAlign";
import { arrayStringConcat } from "../../utils/utilArrays";
import ButtonIcon from "../Button/ButtonIcon";
import ButtonLink from "../Button/ButtonLink";
import Icon, { TypeIcon } from "../Icon";
import InputCheck from "../inputs/InputCheck";
import Row from "../Layout/GRow";
import GridHeaderFilter from "./GridHeader.Filter";
import {
  getGridColumnAlign,
  GridColumnActionType,
  IGridItemTemplate,
} from "./ItemTemplate/IGridItemTemplate";
import { GridMode } from "./PropsGrid";
import { useContextGrid } from "./useContextGrid";

const GridHeader = (props: {
  hideIncludeInactiveButton?: boolean;
  onApplyFilters: () => void;
  onClientSideReload?(): void;
}) => {
  const grid = useContextGrid();
  const appTheme = useAppTheme();

  const refresh = () => {
    if (grid.gridState.mode == GridMode.ServerSide) {
      grid.refresh();
      return;
    }

    if (!props.onClientSideReload) {
      throw Error("onClientSideReload must be defined to allow refresh grid at Mode.ClientSide");
    }
    props.onClientSideReload();
  };

  const totalWidth = grid.gridState.visibleColumns
    .map((c) => c.width)
    .reduce((sum, current) => sum + current);

  const columnStyle = (column: IGridItemTemplate<any>): CSSProperties => {
    const align = getGridColumnAlign(column);
    return {
      width: (column.width / totalWidth) * 100 + "%",
      minWidth: column.actionType ? 30 : undefined,
      textAlign: align,
      paddingLeft: column.filter && align != TextAlign.right ? AppSize.medium : 2,
      paddingRight: column.filter && align == TextAlign.right ? AppSize.medium : 2,
    };
  };

  const iconSorting = (column: IGridItemTemplate<any>) => {
    const sorting = grid.gridState.searchDto.orderBy.find(
      (c) => (column.orderBy ?? column.field) === c.columnName
    );

    const indexSorting = sorting
      ? grid.gridState.searchDto.orderBy.findIndex((c) => c == sorting)
      : -1;

    return (
      <>
        <Icon
          className="sort-icon"
          color={sorting ? AppThemeColor.success : AppThemeColor.white}
          type={!sorting ? TypeIcon.sortAsc : sorting.asc ? TypeIcon.sortAsc : TypeIcon.sortDsc}
          size={AppSize.small}
          style={{ marginTop: -3 }}
        />
        {grid.gridState.searchDto.orderBy.length > 1 && (
          <Badge badgeContent={indexSorting + 1} style={{ marginLeft: 10 }} color="primary" />
        )}
      </>
    );
  };

  const sort = (column: IGridItemTemplate<any>, ctrlPressed: boolean) => {
    if (column.orderByPrevent) {
      return;
    }

    const indexSorting = grid.gridState.searchDto.orderBy.findIndex(
      (c) => c.columnName == (column.orderBy ?? column.field)
    );
    if (indexSorting >= 0) {
      if (ctrlPressed && grid.gridState.searchDto.orderBy.length > 1) {
        grid.orderByRemove(column.orderBy ?? column.field);
        return;
      }
      grid.orderByToggleAsc(column.orderBy ?? column.field);
      return;
    }

    if (ctrlPressed) {
      grid.orderByAdd(column.orderBy ?? column.field);
      return;
    }

    grid.orderByChange(column.orderBy ?? column.field);
  };

  const sortable = (column: IGridItemTemplate<any>) => {
    const sortable =
      !column.orderByPrevent && !column.actionType && grid.gridState.mode != GridMode.ClientSide;
    return sortable;
  };

  const header = (column: IGridItemTemplate<any>, index: number, onApplyFilters: () => void) => (
    <div
      key={index}
      className={arrayStringConcat(
        "grid-column",
        column.className,
        sortable(column) ? "sortable" : "",
        column.actionType === GridColumnActionType.Selection ? "grid-column-selection" : ""
      )}
      style={columnStyle(column)}
      onClick={(event) => {
        if (sortable(column)) {
          sort(column, event.ctrlKey);
        }
      }}>
      {getGridColumnAlign(column) == TextAlign.right && sortable(column) && iconSorting(column)}
      {column.actionType === GridColumnActionType.Selection && (
        <InputCheck
          value={grid.gridState.selection.allSelected ?? false}
          onChange={() => {
            if (grid.gridState.selection.allSelected) {
              grid.unselectAll();
              return;
            }
            grid.selectAll();
          }}
        />
      )}
      <GridHeaderFilter column={column} onApplyFilter={onApplyFilters} />
      <span className="header-label">{column.header}</span>
      {getGridColumnAlign(column) != TextAlign.right && sortable(column) && iconSorting(column)}
    </div>
  );

  const hasFilters =
    grid.gridState.filtersPendingApply ||
    !deepEqual(grid.gridState.filtersEditing, grid.gridState.filtersDefault);

  return (
    <>
      {grid.gridState.mode == GridMode.ServerSide && (
        <Row minHeightIgnore={true} align={TextAlign.right}>
          {hasFilters && (
            <ButtonLink
              icon={TypeIcon.eraser}
              text="Limpiar filtros"
              onClick={() => grid.updateFilters(grid.gridState.filtersDefault)}
            />
          )}
          <ButtonIcon
            icon={TypeIcon.refresh}
            onClick={refresh}
            color={AppThemeColor.gray}
            noPadding={true}
          />
        </Row>
      )}
      <div
        className="grid-header"
        style={{
          borderBottomColor: appTheme.toHexColor(AppThemeColor.gray),
          borderTopColor: appTheme.toHexColor(AppThemeColor.gray),
        }}>
        {grid.gridState.visibleColumns.map((column: IGridItemTemplate<any>, index: number) =>
          header(column, index, props.onApplyFilters)
        )}
      </div>
    </>
  );
};

export default GridHeader;
