import { useRef } from "react";
import { nameof } from "ts-simple-nameof";
import { SaleLineDto } from "../../api/app/dtos/SaleLineDto";
import { useIsMobile } from "../../shared/hooks/useIsMobile";
import { ModelStateArrayItemHelper } from "../../shared/modelState/ModelStateArray";
import { ThemeVariant } from "../../shared/ThemeVariant";
import { ISaleLineRowConfig } from "./SaleFormLines";
import SaleFormLineRow from "./SaleFormLines.Row";
import SaleLineRetentions from "./SaleLineRetentions";
import SaleLineColumn from "./SaleLineColumn";
import { SaleViewModel } from "./ViewModels/SaleViewModel";
import { InvoiceTypeGroup } from "../../api/shared/enums/InvoiceTypeGroup";

const SaleFormLinesItem = (props: {
  arrayItemHelper: ModelStateArrayItemHelper;
  sale: SaleViewModel;
  saleLine: SaleLineDto;
  saleLineRowConfig: ISaleLineRowConfig;
  isEditing: boolean;
}) => {
  const quantityRef = useRef<HTMLInputElement>();
  const isMobile = useIsMobile();
  return (
    <SaleFormLineRow key={props.arrayItemHelper.index}>
      <div style={{ width: "100%", display: "flex", gap: 10, flexDirection: "column" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: 5,
            flexDirection: isMobile ? "column" : "row",
          }}>
          {props.saleLineRowConfig.columns.map((saleLineColumn, index) => (
            <SaleLineColumn
              key={index}
              arrayItemHelper={props.arrayItemHelper}
              saleLineColumn={saleLineColumn}
              quantityRef={quantityRef}
              isEditing={props.isEditing}
              showLabel={isMobile}
              variant={isMobile ? ThemeVariant.filled : ThemeVariant.outlined}
            />
          ))}
        </div>
        {props.sale.enableLineRetentions && (
          <div
            style={{
              width: "100%",
              paddingLeft:
                props.sale.invoiceTypeGroup != InvoiceTypeGroup.EResguardo ? "10%" : undefined,
            }}>
            <SaleLineRetentions
              lineIndex={props.arrayItemHelper.index}
              propertyBaseName={
                nameof<SaleViewModel>((p) => p.lines) + "[" + props.arrayItemHelper.index + "]"
              }
              variant={isMobile ? ThemeVariant.filled : ThemeVariant.outlined}
              retentionColumns={props.saleLineRowConfig.retentionsColumns}
              showLabel={isMobile}
              saleLine={props.saleLine}
            />
          </div>
        )}
      </div>
    </SaleFormLineRow>
  );
};

export default SaleFormLinesItem;
