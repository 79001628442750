import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";

const PurchaseDetailsColumn = (props: { label: string; children: any }) => {
  return (
    <ColumnDisplay label={props.label} md={2}>
      {props.children}
    </ColumnDisplay>
  );
};

export default PurchaseDetailsColumn;
