import { AppSize } from "../../../shared/AppSize";
import Button from "../../../shared/components/Button/Button";
import Icon, { TypeIcon } from "../../../shared/components/Icon";
import ComponentGroup from "../../../shared/components/Layout/ComponentsGroup";
import Row from "../../../shared/components/Layout/GRow";
import { FlexDirection, TextAlign } from "../../../shared/TextAlign";
import { AppThemeColor } from "../../../styles/color";

const ConfigEmailEInvoicePending = (props: { onCreate: () => void }) => {
  return (
    <Row align={TextAlign.center} fontColor={AppThemeColor.gray} fontSize="2em" paddingTop={5}>
      <ComponentGroup direction={FlexDirection.column} align={TextAlign.center}>
        <Icon type={TypeIcon.emptyProductList} size={AppSize.large} style={{ display: "block" }} />
        <Row>Configurá un correo para la facturación electrónica</Row>
        <Row align={TextAlign.center}>
          <Button text="Configurar correo" icon={TypeIcon.create} onClick={props.onCreate} />
        </Row>
      </ComponentGroup>
    </Row>
  );
};

export default ConfigEmailEInvoicePending;
