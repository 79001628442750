import "./Login.scss";
import LoginRow from "./LoginRow";

const LoginRowBottom = (props: { children: any }) => {
  return (
    <LoginRow isBottom={true} fontSize={11}>
      {props.children}
    </LoginRow>
  );
};

export default LoginRowBottom;
