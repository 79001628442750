import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router";
import { useSwipeable } from "react-swipeable";
import AppMenu from "../../../app/AppMenu/AppMenu";
import { EMPTY_GUID } from "../../../Constants";
import { AppInfoSiteType } from "../../context/AppInfoProvider";
import { LoginInfoStatus } from "../../context/LoginInfoProvider";
import { useContextAppInfo } from "../../hooks/useContextAppInfo";
import { useContextLoginInfo } from "../../hooks/useContextLoginInfo";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useNavigation } from "../../hooks/useNavigation";
import { ThemeVariant } from "../../ThemeVariant";
import NotificationArea from "../Notifications/NotificationArea";
import "./Layout.scss";
import LayoutHeader from "./LayoutHeader";
import LayoutHeaderStickerAdmin from "./LayoutHeader.StickerAdmin";
import LayoutMainContainer from "./LayoutMainContainer";
import LayoutSideMenu from "./LayoutSideMenu";

const Layout = () => {
  const navigation = useNavigation();
  const { appInfo, appInfoChangeThemeVariant, setSiteType } = useContextAppInfo();
  const { loginInfo } = useContextLoginInfo();
  const isMobile = useIsMobile();
  const [menuIsExpanded, setMenuIsExpanded] = useState(!isMobile);
  const handlers = useSwipeable({
    onSwipedRight: () => {
      setMenuIsExpanded(true);
    },
  });

  useEffect(() => {
    if (appInfo?.muiVariant != ThemeVariant.filled) {
      appInfoChangeThemeVariant(ThemeVariant.filled);
    }

    setSiteType(AppInfoSiteType.Account);
  }, []);

  if (loginInfo.status === LoginInfoStatus.LoggedOut) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  if (!appInfo || !loginInfo.isLoggedIn) {
    return null;
  }

  return (
    <div style={{ overflow: "hidden" }} {...handlers}>
      {(!loginInfo.authInfo.accountUserId || loginInfo.authInfo.accountUserId == EMPTY_GUID) &&
        appInfo.siteType != AppInfoSiteType.SuperAdmin && <LayoutHeaderStickerAdmin />}
      <NotificationArea />
      <div className="layout">
        <LayoutHeader
          onToggleMenu={() => setMenuIsExpanded(!menuIsExpanded)}
          sideMenuIsExpanded={menuIsExpanded}
        />
        <div className="layout-body">
          <LayoutSideMenu
            onCollapseRequested={() => setMenuIsExpanded(false)}
            onExpandRequested={() => setMenuIsExpanded(true)}
            expanded={menuIsExpanded}>
            <AppMenu
              isExpanded={menuIsExpanded}
              onNavigate={(url) => {
                setMenuIsExpanded(false);
                navigation.go(url);
              }}
            />
          </LayoutSideMenu>
          <LayoutMainContainer>
            <Outlet />
          </LayoutMainContainer>
        </div>
      </div>
    </div>
  );
};

export default Layout;
