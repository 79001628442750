import { useEffect, useState } from "react";
import { AppSize } from "../../AppSize";
import { TextAlign } from "../../TextAlign";
import Row from "../Layout/GRow";
import Spinner from "../Spinner";

const TemplateEditorPreviewFrame = (props: { html: string; isLoading: boolean }) => {
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);
  const [htmlPreview, setHtmlPreview] = useState("");

  const getPreviewHtml = (posX: number, posY: number) => {
    let preview = props.html;
    preview =
      "<script>" +
      "window.addEventListener('load', function() { window.scrollTo(" +
      posX +
      ", " +
      posY +
      "); });" +
      "document.addEventListener('scroll', " +
      "function (event) { " +
      "window.parent.postMessage({type: 'frame-scroll', posX: window.scrollX, posY: window.scrollY }, '*', );" +
      "});" +
      "</script>" +
      preview;

    return "data:text/html;charset=utf-8," + encodeURIComponent(preview ? preview : "");
  };

  const handleFrameMessage = (e: any) => {
    if (e.data.type === "frame-scroll") {
      setPosX(e.data.posX);
      setPosY(e.data.posY);
      return;
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleFrameMessage);
    return () => {
      window.removeEventListener("message", handleFrameMessage);
    };
  }, []);

  useEffect(() => {
    setHtmlPreview(getPreviewHtml(posX, posY));
  }, [props.html]);

  if (props.isLoading) {
    return (
      <Row align={TextAlign.center} paddingTop={20}>
        <Spinner size={AppSize.bigger} />
      </Row>
    );
  }

  if (!htmlPreview) {
    return <></>;
  }

  return <iframe id="previewFrame" className="editor-code-preview" src={htmlPreview} />;
};

export default TemplateEditorPreviewFrame;
