// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminTemplateEmailDto } from "../dtos/AdminTemplateEmailDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { AdminTemplateEmailFiltersDto } from "../dtos/AdminTemplateEmailFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { AdminTemplateDto } from "../dtos/AdminTemplateDto";

export class ApiAdminAccountPublicTemplateEmail {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<AdminTemplateEmailFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AdminTemplateEmailDto>> {
    return this.apiService.apiPost("/admin/account-public/templates/email/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplateEmailDto> {
    return this.apiService.apiGet("/admin/account-public/templates/email/" + id, apiOptions);
  }

  create(
    adminTemplateEmailDto: AdminTemplateEmailDto,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplateEmailDto> {
    return this.apiService.apiPost("/admin/account-public/templates/email", adminTemplateEmailDto, apiOptions);
  }

  edit(
    adminTemplateEmailDto: AdminTemplateEmailDto,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplateEmailDto> {
    return this.apiService.apiPut("/admin/account-public/templates/email", adminTemplateEmailDto, apiOptions);
  }

  inactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin/account-public/templates/email/inactivate/" + id, null, apiOptions);
  }

  reactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin/account-public/templates/email/reactivate/" + id, null, apiOptions);
  }

  getByType(
    templateType: number,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplateDto[]> {
    return this.apiService.apiGet("/admin/account-public/templates/email/by-type/" + templateType, apiOptions);
  }

}
