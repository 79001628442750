import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminPointOfSaleClientDto } from "../../api/admin/dtos/AdminPointOfSaleClientDto";
import { useApiAdminPointOfSaleClient } from "../../api/shared/hooks/useApiAdmin";
import ButtonCreate from "../../shared/components/Button/ButtonCreate";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import Grid from "../../shared/components/grid/Grid";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Page from "../../shared/components/Layout/Page";
import { useModal } from "../../shared/hooks/useModal";
import AdminPointOfSaleClientCreatePopup from "./AdminPointOfSaleClientCreatePopup";
import { getAdminPointOfSaleClientListItemMenu } from "./AdminPointOfSaleClientList.ItemMenu";

const AdminPointOfSaleClientList = () => {
  const [apiAdminPointOfSaleClient] = useApiAdminPointOfSaleClient();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [modal] = useModal();

  const itemTemplates: IGridItemTemplate<AdminPointOfSaleClientDto>[] = [
    {
      header: "Cuenta",
      field: nameof<AdminPointOfSaleClientDto>((p) => p.account.name),
      width: 10,
    },
    {
      header: "Client Pos Id",
      field: nameof<AdminPointOfSaleClientDto>((p) => p.id),
      width: 10,
    },
    {
      header: "Token",
      field: nameof<AdminPointOfSaleClientDto>((p) => p.token),
      width: 10,
    },
    {
      header: "Id conexión actual",
      field: nameof<AdminPointOfSaleClientDto>((p) => p.currentConnectionId),
      width: 10,
    },
    {
      header: "Última conexión",
      field: nameof<AdminPointOfSaleClientDto>((p) => p.lastConnection),
      width: 10,
      cellType: GridCellType.dateTimeWithSeconds,
    },
    {
      header: "Última desconexión",
      field: nameof<AdminPointOfSaleClientDto>((p) => p.lastDisconnection),
      width: 10,
      cellType: GridCellType.dateTimeWithSeconds,
    },
  ];

  return (
    <Page
      title="Clientes Pos"
      titleButton={
        <ComponentGroup>
          <ButtonSecondary
            text="Ping a todos los clientes"
            onClick={() => apiAdminPointOfSaleClient.pingAll()}
          />
          <ButtonCreate
            text="Nuevo cliente Pos"
            onClick={() => {
              modal.open(<AdminPointOfSaleClientCreatePopup />, () => {
                setReloadGrid(new Date());
              });
            }}
          />
        </ComponentGroup>
      }>
      <Grid
        itemTemplates={itemTemplates}
        onSearch={(search, options) => apiAdminPointOfSaleClient.getPaged(search, options)}
        reloadGrid={reloadGrid}
        onDisplayItemMenu={(AdminPointOfSaleClientDto: AdminPointOfSaleClientDto) =>
          getAdminPointOfSaleClientListItemMenu(
            AdminPointOfSaleClientDto,
            apiAdminPointOfSaleClient,
            modal
          )
        }
      />
    </Page>
  );
};

export default AdminPointOfSaleClientList;
