import { useEffect } from "react";
import { AppRoute } from "../../AppRoutes";
import Button from "../../shared/components/Button/Button";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useModal } from "../../shared/hooks/useModal";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { TextAlign } from "../../shared/TextAlign";

const SaleChoseTypeAdjustmentPopup = () => {
  const [modal] = useModal();
  const navigation = useNavigation();
  useEffect(() => {
    modal.setVisible("", ModalSize.dialog);
  }, []);

  const navigate = (url: string) => {
    modal.closeAsSuccess(null);
    navigation.go(url);
  };

  return (
    <Container>
      <Row align={TextAlign.center}>
        <h2>Selecciona el tipo de ajuste</h2>
      </Row>
      <Row>
        <Button
          text="Factura/Ticket"
          onClick={() => navigate(AppRoute.sales.createAdjustment)}
          fullWidth={true}
        />
      </Row>
      <Row>
        <Button
          text="Exportación"
          onClick={() => navigate(AppRoute.sales.createAdjustmentExport)}
          fullWidth={true}
        />
      </Row>
      <Row>
        <Button
          text="Venta por cuenta ajena"
          onClick={() => navigate(AppRoute.sales.createAdjustmentSenderInBehalf)}
          fullWidth={true}
        />
      </Row>
    </Container>
  );
};

export default SaleChoseTypeAdjustmentPopup;
