import { AppThemeColor } from "../../../styles/color";
import { TextAlign } from "../../TextAlign";
import ComponentGroup from "./ComponentsGroup";
import Row from "./GRow";
import Section from "./GSection";

const RowButtonsToolbar = (props: { children: any }) => {
  return (
    <Row>
      <Section borderColor={AppThemeColor.info}>
        <Row align={TextAlign.right} paddingTop={0} minHeightIgnore={true}>
          <ComponentGroup align={TextAlign.right}>{props.children}</ComponentGroup>
        </Row>
      </Section>
    </Row>
  );
};

export default RowButtonsToolbar;
