import { nameof } from "ts-simple-nameof";
import { SaleReferenceDto } from "../../api/app/dtos/SaleReferenceDto";
import { enumName } from "../../api/shared/enums/_enumName";
import FormSelectEnum from "../../shared/components/forms/Select/FormSelectEnum";
import { ISaleReferenceColumnRenderProps } from "./SaleFormReferences";

const SaleFormReferenceColumnDocumentType = (props: ISaleReferenceColumnRenderProps) => {
  return (
    <FormSelectEnum
      arrayItemHelper={props.arrayItemHelper}
      label={props.showLabel ? props.saleReferenceColumn.header : ""}
      propertyName={nameof<SaleReferenceDto>((p) => p.invoiceType)}
      variant={props.variant}
      enumTypeName={enumName.InvoiceType}
    />
  );
};

export default SaleFormReferenceColumnDocumentType;
