import { nameof } from "ts-simple-nameof";
import { PointOfSaleDto } from "../../../api/app/dtos/PointOfSaleDto";
import ButtonAccept from "../../../shared/components/Button/ButtonAccept";
import FormSwitch from "../../../shared/components/forms/FormSwitch";
import FormText from "../../../shared/components/forms/FormText";
import Row from "../../../shared/components/Layout/GRow";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import RowDisplay from "../../../shared/components/Layout/GRowDisplay";
import { useContextModelState } from "../../../shared/modelState/useContextModelState";

const AdminPointOfSaleForm = (props: { onSave(): void; isEdit: boolean }) => {
  const modelState = useContextModelState();
  const pointOfSale = modelState.model as PointOfSaleDto;
  return (
    <>
      <RowDisplay label="Sucursal">{pointOfSale.accountBranch.displayName}</RowDisplay>
      <Row>
        <FormText label="Nombre" propertyName={nameof<PointOfSaleDto>((p) => p.name)} />
      </Row>
      <Row>
        <FormSwitch label="Por defecto" propertyName={nameof<PointOfSaleDto>((p) => p.isDefault)} />
      </Row>
      <RowButtons>
        <ButtonAccept onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default AdminPointOfSaleForm;
