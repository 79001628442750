import { useEffect, useState } from "react";
import { ProductDto } from "../../api/app/dtos/ProductDto";
import { EMPTY_GUID } from "../../Constants";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiProduct } from "../../api/shared/hooks/useApiApp";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import ProductForm from "./Product.Form";
import { useProductValidation } from "./Validations/useProductValidation";
import { ProductInfoDto } from "../../api/app/dtos/ProductInfoDto";
import { ProductViewModel } from "./ViewModels/SaleViewModel";

const ProductCreatePopup = (props: { initName?: string; copyProductId?: string }) => {
  const [product, setProduct] = useState<ProductDto>();
  const [modal] = useModal();
  const [apiProduct] = useApiProduct();
  const { loginInfo } = useContextLoginInfo();

  useEffect(() => {
    const loadNextCode = async () => {
      const nextCode = await apiProduct.getNextProductCode();

      let product = new ProductViewModel();
      product.info = new ProductInfoDto();
      product.type = loginInfo.authInfo.accountConfigPreferences.defaultProductType;
      product.isForPurchase = true;
      product.isForSale = true;
      if (props.copyProductId) {
        product = (await apiProduct.getById(props.copyProductId)) as ProductViewModel;
        product.id = EMPTY_GUID;
      } else {
        product.info.defaultCurrency = loginInfo.authInfo.accountConfig.defaultCurrency;
        product.info.defaultTax = loginInfo.authInfo.accountConfig.defaultTax;
      }
      product.name = props.initName ?? product.name;
      product.code = nextCode;

      setProduct(product);
      modal.setVisible("Crear producto", ModalSize.small);
    };
    loadNextCode();
  }, []);

  const save = async (product: ProductDto) => {
    const created = await apiProduct.create(product);
    modal.closeAsSuccess(created, "El producto se creó con éxito");
  };

  if (!product) {
    return <></>;
  }
  return (
    <StateProvider model={product} validationSchema={useProductValidation()} onSubmit={save}>
      {(p) => <ProductForm onSubmit={p.handleSubmit} />}
    </StateProvider>
  );
};

export default ProductCreatePopup;
