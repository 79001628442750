import { nameof } from "ts-simple-nameof";
import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import { PurchaseRetentionDto } from "../../api/app/dtos/PurchaseRetentionDto";
import GridClientSide from "../../shared/components/grid/GridClientSide";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Row from "../../shared/components/Layout/GRow";

const PurchaseDetailsRetentions = (props: { purchase: PurchaseDto }) => {
  const templates: IGridItemTemplate<PurchaseRetentionDto>[] = [
    {
      header: "Código",
      field: nameof<PurchaseRetentionDto>((p) => p.code),
      width: 10,
    },
    {
      header: "Monto",
      field: nameof<PurchaseRetentionDto>((p) => p.amount),
      width: 10,
      cellType: GridCellType.decimal,
    },
  ];

  return (
    <Row>
      <GridClientSide items={props.purchase.retentions} templates={templates} />
    </Row>
  );
};

export default PurchaseDetailsRetentions;
