import { createTheme } from "@mui/material";
import { AppThemePalette } from "./AppThemePalette";
import { LAYOUT_HINT_HEIGHT } from "./shared/components/Layout/LayoutConstants";
import { AppThemeColor } from "./styles/color";

export const toHexColorFromPalette = (
  appThemeColor: AppThemeColor,
  appThemePalette: AppThemePalette
): string => {
  const name = AppThemeColor[appThemeColor];
  if (!name) {
    throw new Error(`Could not get AppThemeColor name: ${appThemeColor}`);
  }

  const color = (appThemePalette as any)[name];
  if (!color) {
    throw new Error(`Missing color for palette: ${name}`);
  }

  return color;
};

export function createThemeForAdminProvider(palette: AppThemePalette) {
  return createTheme({
    typography: {
      body1: {
        fontSize: 14,
      },
    },
    palette: {
      primary: {
        main: toHexColorFromPalette(AppThemeColor.primary, palette),
        contrastText: toHexColorFromPalette(AppThemeColor.primaryContrast, palette),
      },
      secondary: {
        main: toHexColorFromPalette(AppThemeColor.secondary, palette),
        contrastText: toHexColorFromPalette(AppThemeColor.secondaryContrast, palette),
      },
    },
    components: {
      MuiUseMediaQuery: {
        defaultProps: {
          // Prevents useMediaQuery to render twice
          noSsr: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            whiteSpace: "nowrap",
          },
          outlinedSizeSmall: {
            textTransform: "none",
          },
          textSizeSmall: {
            textTransform: "none",
          },
          iconSizeSmall: {
            marginTop: -8,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            width: "fit-content",
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            maxHeight: LAYOUT_HINT_HEIGHT,
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          labelIcon: {
            minHeight: 0,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          nativeInput: {
            backGround: toHexColorFromPalette(AppThemeColor.white, palette),
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: toHexColorFromPalette(AppThemeColor.primaryLight, palette),
            color: toHexColorFromPalette(AppThemeColor.primaryContrast, palette),
            fontSize: 14,
            padding: 8,
            boxShadow: "2px 2px 2px " + toHexColorFromPalette(AppThemeColor.gray, palette),
          },
        },
      },
    },
  });
}
