import { ApiAdminProviderEInvoiceDailyReportProcess } from "../../../api/admin/apis/ApiAdminProviderEInvoiceDailyReportProcess";
import { EInvoiceDailyReportCrossShardDto } from "../../../api/app/dtos/EInvoiceDailyReportCrossShardDto";
import { EInvoiceDailyReportStatus } from "../../../api/shared/enums/EInvoiceDailyReportStatus";
import { ContextMenuItem } from "../../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../../shared/components/Icon";
import ModalDialog from "../../../shared/components/Modal/ModalDialog";
import { ModalService } from "../../../shared/hooks/useModal";
import { Navigation } from "../../../shared/hooks/useNavigation";
import { Notifier } from "../../../shared/hooks/useNotifier";
import AdminEInvoiceDailyReportDetailsPopup from "./AdminEInvoiceDailyReportDetailsPopup";

export default function getAdminEInvoiceDailyReportListItemMenu(
  eInvoiceDailyReportDto: EInvoiceDailyReportCrossShardDto,
  apiAdminEInvoiceDailyReportProcess: ApiAdminProviderEInvoiceDailyReportProcess,
  modal: ModalService,
  notifier: Notifier,
  navigation: Navigation,
  reloadGrid: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        modal.open(
          <AdminEInvoiceDailyReportDetailsPopup
            adminAccountId={eInvoiceDailyReportDto.accountId}
            id={eInvoiceDailyReportDto.id}
          />
        );
      },
    },
    {
      text: "Descargar Xml",
      hide: !eInvoiceDailyReportDto.xmlUrl,
      icon: TypeIcon.xml,
      onClick: () => {
        navigation.newTab(eInvoiceDailyReportDto.xmlUrl);
      },
    },
    {
      text: "Cancelar",
      icon: TypeIcon.delete,
      hide:
        eInvoiceDailyReportDto.status != EInvoiceDailyReportStatus.Pending &&
        eInvoiceDailyReportDto.status != EInvoiceDailyReportStatus.Processing &&
        eInvoiceDailyReportDto.status != EInvoiceDailyReportStatus.Accepted,
      onClick: () => {
        modal.open(
          <ModalDialog text="¿Seguro desea cancelar la generación del reporte diario?" />,
          () => {
            const request = async () => {
              apiAdminEInvoiceDailyReportProcess.cancelDailyReport(
                eInvoiceDailyReportDto.accountId,
                eInvoiceDailyReportDto.id
              );
              notifier.showSuccessFast("El reporte se canceló con éxito");
              reloadGrid();
            };
            request();
          }
        );
      },
    },
    {
      text: "Procesar nuevamente",
      icon: TypeIcon.refresh,
      onClick: () => {
        modal.open(
          <ModalDialog text="¿Seguro desear volver a generar el reporte diario?" />,
          () => {
            const request = async () => {
              apiAdminEInvoiceDailyReportProcess.regenerateDailyReport(
                eInvoiceDailyReportDto.accountId,
                eInvoiceDailyReportDto.id
              );
              notifier.showSuccessFast("El reporte se envió a procesar nuevamente");
              reloadGrid();
            };
            request();
          }
        );
      },
    },
  ];
}
