import { useEffect, useState } from "react";
import { AccountConfigCurrenciesCrudDto } from "../../../api/app/dtos/AccountConfigCurrenciesCrudDto";
import { ConfigCurrencyMode } from "../../../api/shared/enums/ConfigCurrencyMode";
import { Currency } from "../../../api/shared/enums/Currency";
import { enumName } from "../../../api/shared/enums/_enumName";
import ButtonAccept from "../../../shared/components/Button/ButtonAccept";
import InfoTextServiceLevel from "../../../shared/components/InfoTextServiceLevel";
import InputSelectEnum from "../../../shared/components/inputs/select/InputSelectEnum";
import Column from "../../../shared/components/Layout/GColumn";
import ColumnDisplay from "../../../shared/components/Layout/GColumnDisplay";
import Container from "../../../shared/components/Layout/GContainer";
import Row from "../../../shared/components/Layout/GRow";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import Page from "../../../shared/components/Layout/Page";
import ModalMessage from "../../../shared/components/Modal/ModalMessage";
import { loginBroadCastChannel } from "../../../shared/context/LoginInfoProvider";
import { useApiAccountConfig, useApiCurrency } from "../../../api/shared/hooks/useApiApp";
import useUtils from "../../../shared/hooks/useUtils";
import { useModal } from "../../../shared/hooks/useModal";
import { useNotifier } from "../../../shared/hooks/useNotifier";

const ConfigCurrencies = () => {
  const notifier = useNotifier();
  const [apiAccountConfig] = useApiAccountConfig();
  const [apiCurrency] = useApiCurrency();
  const { utils } = useUtils();
  const [modal] = useModal();
  const [currenciesByMode, setCurrenciesByMode] = useState<Currency[]>();
  const [configDto, setConfigDto] = useState<AccountConfigCurrenciesCrudDto>();

  const getCurrenciesByMode = async (configCurrencyMode: ConfigCurrencyMode) => {
    if (!configCurrencyMode) {
      return;
    }
    setCurrenciesByMode(await apiCurrency.getForConfigMode(configCurrencyMode));
  };

  const save = async () => {
    if (!configDto) {
      return;
    }

    await apiAccountConfig.configCurrencies(configDto);
    notifier.showSuccessFast("La configuración de las monedas se actualizó con éxito");
    modal.open(<ModalMessage text="Este cambio requiere recargar la página" />, () => {
      loginBroadCastChannel.postMessage("ReloadPage");
      window.location.reload();
    });
  };

  useEffect(() => {
    const getConfig = async () => {
      const config = await apiAccountConfig.getForCurrencies();
      setConfigDto(config);
      getCurrenciesByMode(config.currencyMode);
    };
    getConfig();
  }, []);

  if (!configDto || !currenciesByMode) {
    return <></>;
  }

  return (
    <Page title="Configurar monedas">
      <Column md={4}></Column>
      <Column md={4}>
        <Container>
          <Row>
            <InputSelectEnum
              enumTypeName={enumName.ConfigCurrencyMode}
              value={configDto.currencyMode}
              onChange={(value: ConfigCurrencyMode) => {
                setConfigDto({ ...configDto, currencyMode: value });
                getCurrenciesByMode(value);
              }}
            />
          </Row>

          {configDto.currencyMode == ConfigCurrencyMode.Custom && (
            <Row>
              <InfoTextServiceLevel />
            </Row>
          )}

          <ColumnDisplay md={6} label="Moneda nacional">
            {utils.enum.translate(enumName.CurrencyName, configDto.nationalCurrency)}
          </ColumnDisplay>

          <Column md={6}>
            <InputSelectEnum
              label="Moneda por defecto"
              enumTypeName={enumName.CurrencyName}
              value={configDto.defaultCurrency}
              optionValues={currenciesByMode}
              onChange={(value: Currency) => setConfigDto({ ...configDto, defaultCurrency: value })}
            />
          </Column>

          <RowButtons preventValidation={true}>
            <ButtonAccept
              onClick={save}
              disabled={
                !configDto.currencyMode || configDto.currencyMode == ConfigCurrencyMode.Custom
              }
            />
          </RowButtons>
        </Container>
      </Column>
    </Page>
  );
};

export default ConfigCurrencies;
