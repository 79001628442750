import { useEffect, useState } from "react";
import { AdminTemplateDesignEmailTestDto } from "../../../api/admin/dtos/AdminTemplateDesignEmailTestDto";
import { ModalSize } from "../../context/ModalProvider";
import { useApiAdminAccountPublicTemplateDesign } from "../../../api/shared/hooks/useApiAdmin";
import { useModal } from "../../hooks/useModal";
import ButtonAccept from "../Button/ButtonAccept";
import InputText from "../inputs/InputText";
import Container from "../Layout/GContainer";
import Row from "../Layout/GRow";
import RowButtons from "../Layout/GRowButtons";

const TemplateEditorSendEmailTestPopup = (props: {
  designTestDto: AdminTemplateDesignEmailTestDto;
}) => {
  const [modal] = useModal();
  const [designTestDto, setDesignTestDto] = useState(props.designTestDto);
  const [apiTemplateDesign] = useApiAdminAccountPublicTemplateDesign();

  useEffect(() => {
    modal.setVisible("Probar envío de plantilla", ModalSize.small);
  }, [modal]);

  const send = () => {
    const request = async () => {
      await apiTemplateDesign.emailSendTest(designTestDto);
      modal.closeAsSuccess(null, "Email de prueba de plantilla enviado");
    };
    request();
    return;
  };

  return (
    <Container>
      <Row>
        <InputText
          label="Correo"
          value={designTestDto.testEmailTo}
          onChange={(value) => {
            setDesignTestDto({ ...designTestDto, testEmailTo: value });
          }}
        />
      </Row>
      <Row>
        <InputText
          label="Nombre"
          value={designTestDto.testEmailToName}
          onChange={(value) => {
            setDesignTestDto({ ...designTestDto, testEmailToName: value });
          }}
        />
      </Row>
      <RowButtons preventValidation={true}>
        <ButtonAccept text="Enviar" disabled={!designTestDto.testEmailTo} onClick={send} />
      </RowButtons>
    </Container>
  );
};

export default TemplateEditorSendEmailTestPopup;
