import { AppThemePalette } from "./AppThemePalette";

export const appThemePaletteDefault: AppThemePalette = {
  alert: "#ff4040",
  alertLight: "#ffabab",
  black: "#1f1f1f",
  gray: "#8a8a8a",
  grayLight: "#f0f0f0",
  header: "#506176",
  inactive: "#c7c7c7",
  info: "#4e8bcf",
  infoLight: "#e7f0fa",
  layoutBackground: "#f3f4f7",
  primary: "#67c09c",
  primaryContrast: "#fefefe",
  primaryLight: "#7fcfaf",
  secondary: "#fcfcfc",
  secondaryLight: "#f0f0f0",
  secondaryContrast: "#1f1f1f",
  success: "#2c7535",
  successLight: "#7fcfaf",
  warning: "#caa000",
  warningLight: "#fbf2e9",
  white: "#fcfcfc",
};
