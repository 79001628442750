import { useEffect, useState } from "react";
import { AdminTaxValueDto } from "../../api/admin/dtos/AdminTaxValueDto";
import { Country } from "../../api/shared/enums/Country";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminTaxValue } from "../../api/shared/hooks/useApiAdmin";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AdminTaxValueForm from "./AdminTaxValueForm";
import { useAdminTaxValueValidation } from "./Validations/useAdminTaxValueValidation";

const AdminTaxValueCreatePopup = () => {
  const [adminTaxValue] = useState(() => {
    const adminTaxValue = new AdminTaxValueDto();
    adminTaxValue.country = Country.Uruguay;
    return adminTaxValue;
  });

  const [modal] = useModal();
  const [apiAdminTaxValue] = useApiAdminTaxValue();

  const save = async (adminTaxValue: AdminTaxValueDto) => {
    const created = await apiAdminTaxValue.create(adminTaxValue);
    modal.closeAsSuccess(created, "El impuesto se creó con éxito");
  };

  useEffect(() => modal.setVisible("Crear impuesto", ModalSize.small), []);

  if (!adminTaxValue) {
    return <></>;
  }

  return (
    <Container>
      <StateProvider
        model={adminTaxValue}
        onSubmit={save}
        validationSchema={useAdminTaxValueValidation()}>
        {(p) => <AdminTaxValueForm onSave={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default AdminTaxValueCreatePopup;
