export enum AppThemeColor {
  alert,
  alertLight,
  black,
  gray,
  grayLight,
  header,
  inactive,
  info,
  infoLight,
  layoutBackground,
  primary,
  primaryLight,
  primaryContrast,
  secondary,
  secondaryLight,
  secondaryContrast,
  success,
  successLight,
  warning,
  warningLight,
  white,
}
