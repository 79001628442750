import { nameof } from "ts-simple-nameof";
import { AdminShardDto } from "../../../api/admin/dtos/AdminShardDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

export function useAdminShardValidation() {
  const schema = new ValidationSchema();

  schema[nameof<AdminShardDto>((p) => p.name)] = validate("Nombre").required();

  return schema;
}
