import {
  Button as MuiButton,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { useRef, useState } from "react";
import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import { sizeFromTheme } from "../../utils/utilSize";
import Icon, { TypeIcon } from "../Icon";
import ComponentGroup from "../Layout/ComponentsGroup";
import Tooltip from "../Tooltip";
import { PropsButton } from "./Button";

export interface PropButtonGroupedAction {
  disabled?: boolean;
  icon?: any;
  text: string;
  tooltip?: string;
  onClick: (e: any) => void;
}

export interface PropsButtonGrouped extends PropsButton {
  actions: PropButtonGroupedAction[];
}

const ButtonGrouped = (props: PropsButtonGrouped) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const appTheme = useAppTheme();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };
  return (
    <Tooltip text={props.tooltip}>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        style={{ borderRadius: 12 }}>
        <MuiButton
          onClick={props.onClick}
          startIcon={
            <>
              {props.icon && (
                <Icon type={props.icon} size={sizeFromTheme(props.size)} color={props.iconColor} />
              )}
            </>
          }>
          {props.text}
        </MuiButton>
        <MuiButton
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}>
          <Icon type={TypeIcon.chevronDown} />
        </MuiButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              marginTop: 5,
              background: appTheme.toHexColor(AppThemeColor.primaryLight),
              color: appTheme.toHexColor(AppThemeColor.white),
              transformOrigin: placement === "bottom-end" ? "center top" : "center bottom",
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {props.actions.map((option, index) => (
                    <MenuItem key={index} disabled={option.disabled} onClick={option.onClick}>
                      <ComponentGroup>
                        {option.icon && <Icon type={option.icon} />}
                        <Tooltip text={option.tooltip}>
                          <span>{option.text}</span>
                        </Tooltip>
                      </ComponentGroup>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Tooltip>
  );
};

export default ButtonGrouped;
