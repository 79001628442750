import { forwardRef } from "react";
import InputCheck, { PropsInputCheck } from "../inputs/InputCheck";
import { PropsFormInput, useFormInputInit } from "./_formInputProps";

export interface PropsFormCheck
  extends PropsFormInput<boolean>,
    Omit<PropsInputCheck, "value" | "onChange"> {}

const FormCheck = forwardRef((props: PropsFormCheck, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputCheck {...props} {...inputProps} />;
});

export default FormCheck;
