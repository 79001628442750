import { ReactElement } from "react";
import { AppThemeColor } from "../../../styles/color";
import Icon from "../Icon";

const LayoutHeaderLoginMenuItem = (props: {
  name: string;
  icon: any;
  onClick(): void;
}): ReactElement => {
  return (
    <div className="layout-header-login-menu-item" onClick={props.onClick}>
      <Icon type={props.icon} color={AppThemeColor.white} />
      <div className="layout-header-login-menu-item-text">{props.name}</div>
    </div>
  );
};

export default LayoutHeaderLoginMenuItem;
