import { CSSProperties } from "react";
import { ISaleReferenceColumn } from "./SaleFormReferences";

const SaleFormReferenceHeader = (props: { saleReferenceColumn: ISaleReferenceColumn }) => {
  const style: CSSProperties = {
    flex: props.saleReferenceColumn.width,
    gap: 5,
    textAlign: props.saleReferenceColumn.align,
    whiteSpace: "nowrap",
  };

  return <div style={style}>{props.saleReferenceColumn.header}</div>;
};

export default SaleFormReferenceHeader;
