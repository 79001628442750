import { useContext } from "react";
import { AppInfoActionType, AppInfoContext, AppInfoSiteType } from "../context/AppInfoProvider";
import { ThemeVariant } from "../ThemeVariant";

export function useContextAppInfo() {
  const { appInfo, dispatchAppInfo } = useContext(AppInfoContext);

  const appInfoChangeThemeVariant = (variant: ThemeVariant) => {
    dispatchAppInfo({
      type: AppInfoActionType.changeMuiVariant,
      variant: variant,
    });
  };

  const appInfoDevelopBoxLines = (show: boolean) => {
    dispatchAppInfo({
      type: AppInfoActionType.developShowLinesBoxes,
      show: show,
    });
  };

  const requestedExportListOpen = (open: boolean) => {
    dispatchAppInfo({
      type: AppInfoActionType.requestedExportListOpen,
      open: open,
    });
  };
  const setSiteType = (sitType: AppInfoSiteType) => {
    dispatchAppInfo({
      type: AppInfoActionType.setSiteType,
      siteType: sitType,
    });
  };

  return {
    appInfo,
    appInfoChangeThemeVariant,
    appInfoDevelopBoxLines,
    requestedExportListOpen,
    setSiteType,
  };
}
