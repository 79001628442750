// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { EInvoiceProcessLogDto } from "../dtos/EInvoiceProcessLogDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiEInvoiceProcessLog {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getAllBySaleId(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceProcessLogDto[]> {
    return this.apiService.apiGet("/e-invoice-process-logs/for-sale-all/" + id, apiOptions);
  }

  getCompanyBySaleId(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceProcessLogDto[]> {
    return this.apiService.apiGet("/e-invoice-process-logs/for-sale-company/" + id, apiOptions);
  }

  getProcessBySaleId(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceProcessLogDto[]> {
    return this.apiService.apiGet("/e-invoice-process-logs/for-sale-process/" + id, apiOptions);
  }

}
