import { useEffect, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminEInvoiceDailyReportStartProcessPreventStartResultDto } from "../../../api/admin/dtos/AdminEInvoiceDailyReportStartProcessPreventStartResultDto";
import { AdminEInvoiceDailyReportStartProcessResultDto } from "../../../api/admin/dtos/AdminEInvoiceDailyReportStartProcessResultDto";
import { useApiAdminProviderEInvoiceDailyReportProcess } from "../../../api/shared/hooks/useApiAdminProviders";
import ButtonAccept from "../../../shared/components/Button/ButtonAccept";
import GridClientSide from "../../../shared/components/grid/GridClientSide";
import { IGridItemTemplate } from "../../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import InputDate from "../../../shared/components/inputs/InputDate";
import ColumnDisplay from "../../../shared/components/Layout/GColumnDisplay";
import Container from "../../../shared/components/Layout/GContainer";
import Row from "../../../shared/components/Layout/GRow";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import { ModalSize } from "../../../shared/context/ModalProvider";
import { useModal } from "../../../shared/hooks/useModal";
import useUtils from "../../../shared/hooks/useUtils";

const AdminEInvoiceDailyReportGenerateAllPopup = () => {
  const [modal] = useModal();
  const { utils } = useUtils();
  const [apiAdminEInvoiceDailyReportProcess] = useApiAdminProviderEInvoiceDailyReportProcess();
  const [date, setDate] = useState<Date | null>(null);
  const [result, setResult] = useState<AdminEInvoiceDailyReportStartProcessResultDto>();

  const columns: IGridItemTemplate<AdminEInvoiceDailyReportStartProcessPreventStartResultDto>[] = [
    {
      header: "Cuenta",
      field: nameof<AdminEInvoiceDailyReportStartProcessPreventStartResultDto>(
        (o) => o.accountName
      ),
      width: 20,
    },
    {
      header: "Error",
      field: nameof<AdminEInvoiceDailyReportStartProcessPreventStartResultDto>(
        (o) => o.preventStartReason
      ),
      width: 80,
    },
  ];

  useEffect(() => {
    modal.setVisible("Generar reportes", ModalSize.small);
  }, []);

  const startProcess = async () => {
    const result = await apiAdminEInvoiceDailyReportProcess.startForAll(date);
    setResult(result);
  };

  return (
    <Container>
      <Row>
        <InputDate readOnly={!!result} label="Fecha" value={date} onChange={setDate} />
      </Row>
      <RowButtons>
        <ButtonAccept disabled={!!result} text="Generar reportes" onClick={startProcess} />
      </RowButtons>
      {result && (
        <>
          <ColumnDisplay label="Fecha">{utils.date.toString(result.date)}</ColumnDisplay>
          <ColumnDisplay label="Cantidad habilitados">
            {result.adminAccountTotalCount}
          </ColumnDisplay>
          <ColumnDisplay label="Cantidad iniciados">{result.startedProcessesCount}</ColumnDisplay>
          <Row label="Errores">
            <GridClientSide
              templates={columns}
              items={result.preventStartReasons}
              hideFooter={true}
            />
          </Row>
        </>
      )}
    </Container>
  );
};

export default AdminEInvoiceDailyReportGenerateAllPopup;
