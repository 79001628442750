import { CSSProperties, useState } from "react";
import { TypeIcon } from "../Icon";
import ButtonIcon from "../Button/ButtonIcon";
import ContextMenu, { ContextMenuItem } from "./ContextMenu";

const ButtonContextMenu = (props: {
  disabled?: boolean;
  noPadding?: boolean;
  noPaddingX?: boolean;
  noPaddingY?: boolean;
  icon?: any;
  items: ContextMenuItem[];
  tooltip?: string;
  style?: CSSProperties;
}) => {
  const [open, setOpen] = useState(false);
  const [buttonRef, setButtonRef] = useState();

  return (
    <>
      <ButtonIcon
        noPadding={props.noPadding}
        noPaddingX={props.noPaddingX}
        noPaddingY={props.noPaddingY}
        icon={props.icon ?? TypeIcon.menu}
        style={props.style}
        tooltip={props.tooltip}
        disabled={props.disabled}
        onClick={(event) => {
          setButtonRef(event.currentTarget);
          setOpen(true);
        }}
      />
      <ContextMenu
        items={props.items}
        open={open}
        anchorElement={buttonRef}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default ButtonContextMenu;
