// File generated by: Gesta.Build.PostEvents
export enum FilterDateRangeType {
	Today = 1,
	Yesterday = 2,
	ThisWeek = 3,
	ThisMonth = 4,
	ThisQuarter = 5,
	ThisYear = 6,
	LastWeek = 7,
	LastMonth = 8,
	LastQuarter = 9,
	LastYear = 10,
	LastMinute = 11,
	LastHourQuarter = 12,
	LastHourHalf = 13,
	LastHour = 14,
	Range = 15,
	Date = 16,
}
