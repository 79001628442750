// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { SaleDto } from "../dtos/SaleDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiPublicQrQuery {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  querySale(
    country: number,
    taxPayerId: string,
    invoiceType: string,
    serie: string,
    number: number,
    total: number,
    date: string,
    code: string,
    apiOptions?: ApiOptions
  ): Promise<SaleDto> {
    return this.apiService.apiGet("/public/qr-query?" + 
      (country ? "&country=" + country : "") + 
      (taxPayerId ? "&taxPayerId=" + taxPayerId : "") + 
      (invoiceType ? "&invoiceType=" + invoiceType : "") + 
      (serie ? "&serie=" + serie : "") + 
      (number ? "&number=" + number : "") + 
      (total ? "&total=" + total : "") + 
      (date ? "&date=" + date : "") + 
      (code ? "&code=" + code : ""), apiOptions);
  }

}
