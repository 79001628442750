// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminProviderEmailConfigDto } from "../dtos/AdminProviderEmailConfigDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminProviderEmailConfig {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  get(
    apiOptions?: ApiOptions
  ): Promise<AdminProviderEmailConfigDto> {
    return this.apiService.apiGet("/admin-provider/email-config", apiOptions);
  }

  create(
    adminProviderEmailConfigDto: AdminProviderEmailConfigDto,
    apiOptions?: ApiOptions
  ): Promise<AdminProviderEmailConfigDto> {
    return this.apiService.apiPost("/admin-provider/email-config", adminProviderEmailConfigDto, apiOptions);
  }

  edit(
    adminProviderEmailConfigDto: AdminProviderEmailConfigDto,
    apiOptions?: ApiOptions
  ): Promise<AdminProviderEmailConfigDto> {
    return this.apiService.apiPut("/admin-provider/email-config", adminProviderEmailConfigDto, apiOptions);
  }

}
