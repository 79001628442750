import { useEffect, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AccountBranchDto } from "../../api/app/dtos/AccountBranchDto";
import { EInvoiceAccountBranchInfoDto } from "../../api/app/dtos/EInvoiceAccountBranchInfoDto";
import AddressDisplay from "../../shared/components/Display/AddressDisplay";
import GridClientSide from "../../shared/components/grid/GridClientSide";
import { GridCellType } from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Row from "../../shared/components/Layout/GRow";
import RowTitle from "../../shared/components/Layout/GRowTitle";
import Section from "../../shared/components/Layout/GSection";
import { useApiAccountBranch } from "../../api/shared/hooks/useApiApp";
import { PointOfSaleDto } from "../../api/app/dtos/PointOfSaleDto";
import IconCheck from "../../shared/components/IconCheck";
import Column from "../../shared/components/Layout/GColumn";

const MyAdminAccountBranches = () => {
  const [branches, setBranches] = useState<AccountBranchDto[]>();
  const [apiAccountBranch] = useApiAccountBranch();
  useEffect(() => {
    const load = async () => {
      setBranches(await apiAccountBranch.getAllComplete(false));
    };
    load();
  }, []);

  return (
    <>
      {branches &&
        branches.map((branch) => (
          <Row key={branch.id}>
            <Section title={"Sucursal " + branch.branchCode + " " + branch.branchName}>
              <AddressDisplay address={branch.address} />
              <Column md={6}>
                <RowTitle>eFactura</RowTitle>
                <GridClientSide
                  templates={[
                    {
                      field: nameof<EInvoiceAccountBranchInfoDto>((p) => p.startDate),
                      header: "Inicio",
                      width: 10,
                      cellType: GridCellType.date,
                    },
                    {
                      field: nameof<EInvoiceAccountBranchInfoDto>((p) => p.endDate),
                      header: "Fin",
                      width: 10,
                      cellType: GridCellType.date,
                    },
                    {
                      field: nameof<EInvoiceAccountBranchInfoDto>((p) => p.code),
                      header: "Código",
                      width: 10,
                    },
                    {
                      field: nameof<EInvoiceAccountBranchInfoDto>((p) => p.name),
                      header: "Nombre",
                      width: 70,
                    },
                  ]}
                  items={branch.eInvoiceInfoHistory}
                />
              </Column>
              <Column md={1}></Column>
              <Column md={5}>
                <RowTitle>Puntos de venta</RowTitle>
                <GridClientSide
                  templates={[
                    {
                      field: nameof<PointOfSaleDto>((p) => p.name),
                      header: "Nombre",
                      width: 90,
                    },
                    {
                      field: nameof<PointOfSaleDto>((p) => p.isDefault),
                      header: "Por defecto",
                      width: 10,
                      render: (item: PointOfSaleDto) => (
                        <IconCheck value={item.isDefault} showHyphen={true} />
                      ),
                    },
                  ]}
                  items={branch.pointOfSaleList}
                />
              </Column>
            </Section>
          </Row>
        ))}
    </>
  );
};

export default MyAdminAccountBranches;
