import TabGroup from "../Layout/TabGroup";
import TabView from "../Layout/TabView";
import TemplateEditorFields, { PropsTemplateEditorFields } from "./TemplateEditorFields";
import TemplateEditorGenerics from "./TemplateEditorGenerics";

const TABS = {
  FIELDS: "FIELDS",
  GENERICS: "GENERICS",
};

const TemplateEditorPanelLeft = (props: PropsTemplateEditorFields) => {
  return (
    <TabGroup
      selectedTab={TABS.FIELDS}
      tabs={[
        { title: "Campos", value: TABS.FIELDS },
        { title: "Genéricas", value: TABS.GENERICS },
      ]}>
      <TabView value={TABS.FIELDS}>
        <TemplateEditorFields {...props} />
      </TabView>
      <TabView value={TABS.GENERICS}>
        <TemplateEditorGenerics {...props} />
      </TabView>
    </TabGroup>
  );
};

export default TemplateEditorPanelLeft;
