import { AdminShardDto } from "../../api/admin/dtos/AdminShardDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import { ModalService } from "../../shared/hooks/useModal";
import AdminShardDetailsPopup from "./AdminShardDetailsPopup";
import AdminShardEditPopup from "./AdminShardEditPopup";

export default function getAdminShardListItemMenu(
  adminShardDto: AdminShardDto,
  modal: ModalService,
  onReloadGrid: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        modal.open(
          <AdminShardDetailsPopup adminShardId={adminShardDto.id} />,
          onReloadGrid
        );
      },
    },
    {
      text: "Editar",
      icon: TypeIcon.edit,
      onClick: () => {
        modal.open(
          <AdminShardEditPopup adminShardId={adminShardDto.id} />,
          onReloadGrid
        );
      },
    },
  ];
}
