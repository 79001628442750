import { forwardRef } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminAccountSimpleDto } from "../../../../api/shared/dtos/AdminAccountSimpleDto";
import InputAutocompleteAdminAccount, {
  PropsInputAutocompleteAdminAccount,
} from "../../inputs/autocomplete/InputAutocompleteAdminAccount";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormAutocompleteAdminAccount
  extends PropsFormInput<AdminAccountSimpleDto>,
    Omit<
      PropsInputAutocompleteAdminAccount,
      "value" | "onChange" | "onCreateNewClick" | "onGetEntity" | "onServerSideSuggest"
    > {}

const FormAutocompleteAdminAccount = forwardRef(
  (props: PropsFormAutocompleteAdminAccount, ref?: any) => {
    const inputProps = useFormInputInit(props, ref, {
      updateIdLinkedProperty: true,
      inputTextValuePropertyName: nameof<AdminAccountSimpleDto>((p) => p.name),
    });
    return <InputAutocompleteAdminAccount {...props} {...inputProps} />;
  }
);

export default FormAutocompleteAdminAccount;
