import { forwardRef } from "react";
import { Currency } from "../../../../api/shared/enums/Currency";
import InputSelectCurrency, {
  PropsInputSelectCurrency,
} from "../..//inputs/select/InputSelectCurrency";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormSelectCurrency
  extends PropsFormInput<Currency | null>,
    Omit<PropsInputSelectCurrency, "value" | "onChange"> {}

const FormSelectCurrency = forwardRef((props: PropsFormSelectCurrency, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputSelectCurrency {...props} {...inputProps} />;
});

export default FormSelectCurrency;
