import { AppSize } from "../AppSize";
import { useContextAppInfo } from "../hooks/useContextAppInfo";
import Img from "./Img";
import Props from "./Props";

export interface PropsAdminProviderIsoLogo extends Props {
  onlyWhite?: boolean;
  size?: AppSize;
  onClick?: () => void;
}

const PropsAdminProviderLogoProductName = (props: PropsAdminProviderIsoLogo) => {
  const { appInfo } = useContextAppInfo();

  if (!appInfo?.adminProviderInfo.settings.logoProductNameUrl) {
    return <></>;
  }
  return (
    <Img
      {...props}
      widthSize={AppSize.biggest}
      src={
        props.onlyWhite
          ? appInfo?.adminProviderInfo.settings.logoProductNameWhiteUrl
          : appInfo?.adminProviderInfo.settings.logoProductNameUrl
      }
    />
  );
};

export default PropsAdminProviderLogoProductName;
