import { nameof } from "ts-simple-nameof";
import { ContactDto } from "../../../api/app/dtos/ContactDto";
import { SaleDto } from "../../../api/app/dtos/SaleDto";
import { SaleInfoDto } from "../../../api/app/dtos/SaleInfoDto";
import { SaleLineDto } from "../../../api/app/dtos/SaleLineDto";
import { SaleLineRetentionDto } from "../../../api/app/dtos/SaleLineRetentionDto";
import { SaleReferenceDto } from "../../../api/app/dtos/SaleReferenceDto";
import { InvoiceTypeGroup } from "../../../api/shared/enums/InvoiceTypeGroup";
import { PaymentType } from "../../../api/shared/enums/PaymentType";
import { LoginInfo } from "../../../shared/context/LoginInfoProvider";
import { validate } from "../../../shared/validation/schema/ValidationField";
import { validateCollection } from "../../../shared/validation/schema/ValidationFieldCollection";
import { validateSchema } from "../../../shared/validation/schema/ValidationFieldSchema";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";
import { SaleViewModel } from "../ViewModels/SaleViewModel";

function useSaleReferenceValidation() {
  const schema = new ValidationSchema();
  schema[nameof<SaleReferenceDto>((p) => p.invoiceType)] = validate("Tipo documento")
    .when((saleReference: SaleReferenceDto) => !saleReference.isGlobal)
    .required();
  schema[nameof<SaleReferenceDto>((p) => p.serie)] = validate("Serie").required();
  schema[nameof<SaleReferenceDto>((p) => p.number)] = validate("Numero")
    .when((saleReference: SaleReferenceDto) => !saleReference.isGlobal)
    .required();
  schema[nameof<SaleReferenceDto>((p) => p.referenceDate)] = validate("Fecha").required();
  schema[nameof<SaleReferenceDto>((p) => p.reason)] = validate("Razón")
    .when((saleReference: SaleReferenceDto) => saleReference.isGlobal)
    .required("La razón es requerida si la referencia es global");

  return schema;
}

function useSaleLineRetentionValidation() {
  const schema = new ValidationSchema();

  schema[nameof<SaleLineRetentionDto>((p) => p.code)] = validate("Código").required();
  schema[nameof<SaleLineRetentionDto>((p) => p.detail)] = validate("Detalles").required();
  schema[nameof<SaleLineRetentionDto>((p) => p.amount)] = validate("Monto").required();
  schema[nameof<SaleLineRetentionDto>((p) => p.totalAmount)] =
    validate("Monto imponible").required();
  return schema;
}

function useSaleLineValidation() {
  const schema = new ValidationSchema();

  schema[nameof<SaleLineDto>((p) => p.product)] = validate("Producto")
    .when(
      (_: SaleInfoDto, baseModel: any[]) =>
        (baseModel[2] as SaleDto).invoiceTypeGroup !== InvoiceTypeGroup.EResguardo
    )
    .required();

  schema[nameof<SaleLineDto>((p) => p.lineDetail)] = validate("Detalle")
    .when(
      (_: SaleInfoDto, baseModel: any[]) =>
        (baseModel[2] as SaleDto).invoiceTypeGroup !== InvoiceTypeGroup.EResguardo
    )
    .required();

  schema[nameof<SaleLineDto>((p) => p.quantity)] = validate("Cantidad")
    .when(
      (_: SaleInfoDto, baseModel: any[]) =>
        (baseModel[2] as SaleDto).invoiceTypeGroup !== InvoiceTypeGroup.EResguardo
    )
    .required();

  schema[nameof<SaleLineDto>((p) => p.tax)] = validate("Impuesto")
    .when(
      (_: SaleInfoDto, baseModel: any[]) =>
        (baseModel[2] as SaleDto).invoiceTypeGroup !== InvoiceTypeGroup.EResguardo
    )
    .required();

  schema[nameof<SaleLineDto>((p) => p.unitPrice)] = validate("Precio unitario")
    .when(
      (_: SaleInfoDto, baseModel: any[]) =>
        (baseModel[2] as SaleDto).invoiceTypeGroup !== InvoiceTypeGroup.ERemito &&
        (baseModel[2] as SaleDto).invoiceTypeGroup !== InvoiceTypeGroup.EResguardo
    )
    .required();

  schema[nameof<SaleLineDto>((p) => p.subtotal)] = validate("Subtotal")
    .when(
      (_: SaleInfoDto, baseModel: any[]) =>
        (baseModel[2] as SaleDto).invoiceTypeGroup !== InvoiceTypeGroup.ERemito &&
        (baseModel[2] as SaleDto).invoiceTypeGroup !== InvoiceTypeGroup.EResguardo
    )
    .required();

  schema[nameof<SaleLineDto>((p) => p.calculatedTotal)] = validate("Total")
    .when(
      (_: SaleLineDto, baseModel: any[]) =>
        (baseModel[2] as SaleDto).invoiceTypeGroup !== InvoiceTypeGroup.ERemito &&
        (baseModel[2] as SaleDto).invoiceTypeGroup !== InvoiceTypeGroup.EResguardo
    )
    .required();

  schema[nameof<SaleLineDto>((p) => p.retentions)] = validateCollection(
    useSaleLineRetentionValidation(),
    "Retenciones"
  );

  return schema;
}

function useSaleInfoValidation() {
  const schema = new ValidationSchema();

  schema[nameof<SaleInfoDto>((p) => p.saleClause)] = validate("Cláusula de venta")
    .when((saleInfoDto: SaleInfoDto) => saleInfoDto.isExport)
    .required();

  schema[nameof<SaleInfoDto>((p) => p.saleMode)] = validate("Modo de venta")
    .when((saleInfoDto: SaleInfoDto) => saleInfoDto.isExport)
    .required();

  schema[nameof<SaleInfoDto>((p) => p.transportationType)] = validate("Tipo de transporte")
    .when((saleInfoDto: SaleInfoDto) => saleInfoDto.isExport)
    .required();

  schema[nameof<SaleInfoDto>((p) => p.transportationIndicatorType)] = validate("Tipo de traslado")
    .when(
      (_: SaleInfoDto, baseModel: any[]) =>
        (baseModel[1] as SaleDto).invoiceTypeGroup === InvoiceTypeGroup.ERemito
    )
    .required();

  return schema;
}

export function useSaleValidation(loginInfo: LoginInfo) {
  const schema = new ValidationSchema();

  schema[nameof<SaleDto>((p) => p.date)] = validate("Fecha")
    .when((sale: SaleDto) => sale.draftIsNotCurrentDate)
    .required();

  schema[nameof<SaleDto>((p) => p.currency)] = validate("Moneda").required();
  schema[nameof<SaleDto>((p) => p.paymentType)] = validate("Tipo de pago").required();

  schema[nameof<SaleDto>((p) => p.currencyExchangeRate)] = validate("Cotización")
    .when((sale: SaleDto) => sale.currency != loginInfo.authInfo.accountConfig.nationalCurrency)
    .required();

  schema[nameof<SaleDto>((p) => p.paymentExpirationDate)] = validate("Vencimiento")
    .when((sale: SaleDto) => sale.paymentType == PaymentType.Credit)
    .required();

  schema[nameof<SaleDto>((p) => p.contact)] = validate("Cliente").verify(
    (contact: ContactDto, saleDto: SaleDto) => !!contact || saleDto.isFinalConsumer,
    "Seleccione el cliente o marque consumidor final"
  );

  schema[nameof<SaleDto>((p) => p.senderBehalf)] = validate("Emisor por cuenta ajena")
    .when((saleDto: SaleViewModel) => saleDto.isSaleSenderBehalf)
    .required();

  schema[nameof<SaleDto>((p) => p.saleInfo)] = validateSchema(useSaleInfoValidation(), "Info");

  schema[nameof<SaleDto>((p) => p.lines)] = validateCollection(
    useSaleLineValidation(),
    "Líneas de la factura"
  ).required("Ingresá al menos una línea de factura");

  schema[nameof<SaleDto>((p) => p.references)] = validateCollection(
    useSaleReferenceValidation(),
    "Referencias"
  )
    .when((sale: SaleViewModel) => sale.enableReferenceTable)
    .required("Ingresá al menos una referencia");

  return schema;
}
