import { AdminTemplateDesignFieldDto } from "../../../api/admin/dtos/AdminTemplateDesignFieldDto";
import { TemplateDesignFieldType } from "../../../api/shared/enums/TemplateDesignFieldType";
import ButtonIcon from "../Button/ButtonIcon";
import Icon, { TypeIcon } from "../Icon";
import { TemplateConstants, TemplateGetTagClose, TemplateGetTagOpen } from "./TemplateConstants";

interface PropsField {
  canAddField: boolean;
  field: AdminTemplateDesignFieldDto;
  toggleExpand(expanded: boolean): void;
  onClick(tag: string): void;
}

const TemplateEditorField = (props: PropsField) => {
  return (
    <div
      onClick={() => {
        // Do nothing
      }}
      className="template-field">
      <div className="template-field-name">{props.field.name}</div>

      <div className="template-field-code">{props.field.code}</div>
      {props.field.fieldType === TemplateDesignFieldType.Field && props.canAddField && (
        <ButtonIcon
          noPadding={true}
          icon={TypeIcon.arrowRight}
          tooltip="Agregar campo a la plantilla"
          className="template-field-icon"
          onClick={() => props.onClick(fieldTag(props.field))}
        />
      )}
      {props.field.fieldType === TemplateDesignFieldType.ConditionalField && (
        <>
          <ButtonIcon
            noPadding={true}
            icon={TypeIcon.check}
            className="template-field-icon second"
            onClick={() => {
              if (!props.canAddField) {
                return;
              }
              props.onClick(fieldTag(props.field, true));
            }}
          />
          <ButtonIcon
            noPadding={true}
            icon={TypeIcon.close}
            className="template-field-icon"
            onClick={() => {
              if (!props.canAddField) {
                return;
              }
              props.onClick(fieldTag(props.field, false));
            }}
          />
        </>
      )}
      {props.field.fieldType === TemplateDesignFieldType.RepeatableField && (
        <ButtonIcon
          noPadding={true}
          icon={TypeIcon.table}
          className="template-field-icon second"
          onClick={() => {
            if (!props.canAddField) {
              return;
            }
            props.onClick(getTableForRepeat(props.field));
          }}
        />
      )}
      {props.field.childFields && !props.field.expanded && (
        <ButtonIcon
          noPadding={true}
          icon={TypeIcon.plus}
          className="template-field-icon"
          onClick={() => props.toggleExpand(true)}
        />
      )}
      {props.field.childFields && props.field.expanded && (
        <ButtonIcon
          noPadding={true}
          icon={TypeIcon.minus}
          className="template-field-icon"
          onClick={() => props.toggleExpand(false)}
        />
      )}
      {props.field.helpText && <Icon type={TypeIcon.help} tooltip={props.field.helpText} />}
      {props.field.childFields &&
        props.field.expanded &&
        props.field.childFields.map((f) => (
          <TemplateEditorField
            key={f.name}
            canAddField={props.canAddField}
            field={f}
            onClick={props.onClick}
            toggleExpand={props.toggleExpand}
          />
        ))}
    </div>
  );
};

export default TemplateEditorField;

const fieldTag = (field: AdminTemplateDesignFieldDto, isConditional?: boolean): string => {
  switch (field.fieldType) {
    case TemplateDesignFieldType.Field:
      return startTag(field);
    case TemplateDesignFieldType.ConditionalField:
      return startTag(field, isConditional) + "\n\n" + endTag(field, isConditional);
    case TemplateDesignFieldType.RepeatableField:
      return getTableForRepeat(field);
    case TemplateDesignFieldType.RelatedSchema:
    case TemplateDesignFieldType.EmailAttachment:
      return "";
  }
};

const startTag = (field: AdminTemplateDesignFieldDto, isConditional?: boolean): string => {
  switch (field.fieldType) {
    case TemplateDesignFieldType.Field:
      return TemplateConstants.TAG_VALUE + field.code + TemplateConstants.TAG_VALUE;
    case TemplateDesignFieldType.RepeatableField:
      return TemplateGetTagOpen(TemplateConstants.TAG_OPERATOR_REPEAT, field.code);
    case TemplateDesignFieldType.ConditionalField:
      return isConditional
        ? TemplateGetTagOpen(TemplateConstants.TAG_OPERATOR_IF, field.code)
        : TemplateGetTagOpen(TemplateConstants.TAG_OPERATOR_IF_NOT, field.code);
    case TemplateDesignFieldType.RelatedSchema:
    case TemplateDesignFieldType.EmailAttachment:
      return "";
  }
};

const endTag = (field: AdminTemplateDesignFieldDto, isConditional?: boolean): string => {
  switch (field.fieldType) {
    case TemplateDesignFieldType.Field:
    case TemplateDesignFieldType.RelatedSchema:
    case TemplateDesignFieldType.EmailAttachment:
      return "";
    case TemplateDesignFieldType.ConditionalField:
      return isConditional
        ? TemplateGetTagClose(TemplateConstants.TAG_OPERATOR_IF, field.code)
        : TemplateGetTagClose(TemplateConstants.TAG_OPERATOR_IF_NOT, field.code);
    case TemplateDesignFieldType.RepeatableField:
      return TemplateGetTagClose(TemplateConstants.TAG_OPERATOR_REPEAT, field.code);
  }
};

const getTableForRepeat = (field: AdminTemplateDesignFieldDto): string => {
  let headers = "";
  let row = "";

  field.childFields.forEach((c) => {
    const tag = TemplateConstants.TAG_VALUE + c.code + TemplateConstants.TAG_VALUE;
    headers += "      <div>" + c.name + "</div>\n";
    row += "      <div>" + tag + "</div>\n";
  });

  return (
    " <div class='table'>\n" +
    "   <div class='table-header'>\n" +
    headers +
    "   </div>\n" +
    startTag(field) +
    "\n" +
    "   <div class='table-row'>\n" +
    row +
    "   </div>\n" +
    endTag(field) +
    "\n" +
    " </div>\n"
  );
};
