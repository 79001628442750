import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { UserDto } from "../../api/admin/dtos/UserDto";
import { UserFiltersDto } from "../../api/admin/dtos/UserFiltersDto";
import { useApiAdminProviderUser } from "../../api/shared/hooks/useApiAdminProviders";
import { AppRoute } from "../../AppRoutes";
import { ButtonCreate } from "../../shared/components/Button/ButtonCreate";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import Grid from "../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import { TypeIcon } from "../../shared/components/Icon";
import RowButtonsToolbar from "../../shared/components/Layout/GRowButtonsToolbar";
import Page from "../../shared/components/Layout/Page";
import { AppInfoSiteType } from "../../shared/context/AppInfoProvider";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { useModal } from "../../shared/hooks/useModal";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { useNotifier } from "../../shared/hooks/useNotifier";
import UserCreate from "./UserCreatePopup";
import getUserListItemMenu from "./UserList.ItemMenu";

const UserList = () => {
  const [modal] = useModal();
  const notifier = useNotifier();
  const navigation = useNavigation();
  const { appInfo } = useContextAppInfo();
  const [filters] = useState(() => {
    const filters = new UserFiltersDto();
    filters.crossShardIncludeAllProviders = appInfo?.siteType == AppInfoSiteType.SuperAdmin;
    return filters;
  });
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [apiUser] = useApiAdminProviderUser();

  const itemTemplates = [
    {
      header: "Usuario",
      field: nameof<UserDto>((p) => p.email),
      filter: { propertyName: nameof<UserFiltersDto>((p) => p.email), type: GridFilterType.text },
      width: 40,
    },
    {
      header: "Nombre",
      field: nameof<UserDto>((p) => p.firstName),
      width: 30,
    },
    {
      header: "Apellido",
      field: nameof<UserDto>((p) => p.lastName),
      width: 30,
    },
    {
      header: "Creación",
      field: nameof<UserDto>((p) => p.createdDate),
      filter: {
        propertyName: nameof<UserFiltersDto>((p) => p.createdDate),
        type: GridFilterType.dateTime,
      },
      width: 30,
      cellType: GridCellType.dateTime,
    },
    {
      header: "Último login",
      field: nameof<UserDto>((p) => p.lastLogin),
      filter: {
        propertyName: nameof<UserFiltersDto>((p) => p.lastLogin),
        type: GridFilterType.dateTime,
      },
      width: 30,
      cellType: GridCellType.dateTime,
    },
  ];

  return (
    <Page
      title="Usuarios"
      titleButton={
        <>
          <ButtonCreate
            text="Nuevo usuario"
            onClick={() => {
              modal.open(<UserCreate />, () => setReloadGrid(new Date()));
            }}
          />
        </>
      }>
      <RowButtonsToolbar>
        <ButtonSecondary
          text="Ver asociaciones a cuentas"
          icon={TypeIcon.details}
          onClick={() =>
            navigation.go(
              appInfo?.siteType == AppInfoSiteType.SuperAdmin
                ? AppRoute.admin.userLinkedAccounts()
                : AppRoute.adminProvider.userLinkedAccounts()
            )
          }
        />
      </RowButtonsToolbar>
      <Grid
        initialFilters={filters}
        itemTemplates={itemTemplates}
        onSearch={(search, options) => apiUser.getPaged(search, options)}
        reloadGrid={reloadGrid}
        onDisplayItemMenu={(userDto: UserDto) =>
          getUserListItemMenu(userDto, apiUser, modal, notifier, navigation, () => {
            setReloadGrid(new Date());
          })
        }
      />
    </Page>
  );
};

export default UserList;
