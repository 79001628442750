import { ApiAdminProviderAccountUserLinkedAccount } from "../../api/admin/apis/ApiAdminProviderAccountUserLinkedAccount";
import { UserLinkedAccountDto } from "../../api/admin/dtos/UserLinkedAccountDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import ModalDialog from "../../shared/components/Modal/ModalDialog";
import { ModalService } from "../../shared/hooks/useModal";
import { Notifier } from "../../shared/hooks/useNotifier";
import AccountUserDetailsPopup from "./AccountUserDetailsPopup";
import AccountUserEditPopup from "./AccountUserEditPopup";

export default function getAccountUserListItemMenu(
  userLinkedAccountDto: UserLinkedAccountDto,
  apiAdminProviderAccountUserLinkedAccount: ApiAdminProviderAccountUserLinkedAccount,
  modal: ModalService,
  notifier: Notifier,
  gridReload: () => void
): ContextMenuItem[] {
  const menu: ContextMenuItem[] = [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => modal.open(<AccountUserDetailsPopup id={userLinkedAccountDto.id} />),
    },
    {
      text: "Editar",
      icon: TypeIcon.edit,
      onClick: () => modal.open(<AccountUserEditPopup id={userLinkedAccountDto.id} />, gridReload),
    },
    {
      text: "Hacer administrador",
      icon: TypeIcon.adminAccount,
      hide: userLinkedAccountDto.isAccountAdmin,
      onClick: () =>
        modal.open(<ModalDialog text="¿Seguro quiere hacer administrador al usuario?" />, () => {
          const setAsAdminAccount = async () => {
            await apiAdminProviderAccountUserLinkedAccount.setAsAccountAdmin(
              userLinkedAccountDto.id
            );
            notifier.showSuccess("El usuario es administrador de la cuenta");
            gridReload();
          };
          setAsAdminAccount();
        }),
    },
    {
      text: "Remover permisos de administrador",
      icon: TypeIcon.undo,
      hide: !userLinkedAccountDto.isAccountAdmin,
      onClick: () =>
        modal.open(
          <ModalDialog text="¿Seguro quiere sacar los permisos de administrador al usuario?" />,
          () => {
            const remove = async () => {
              await apiAdminProviderAccountUserLinkedAccount.removeAccountAdmin(
                userLinkedAccountDto.id
              );
              notifier.showSuccess("El usuario ya no es administrador de la cuenta");
              gridReload();
            };
            remove();
          }
        ),
    },
    {
      text: "Inactivar",
      icon: TypeIcon.delete,
      hide: userLinkedAccountDto.inactive,
      onClick: () =>
        modal.open(<ModalDialog text="¿Seguro desea inactivar el usuario?" />, () => {
          const request = async () => {
            await apiAdminProviderAccountUserLinkedAccount.inactivate(userLinkedAccountDto.id);
            notifier.showSuccess("El usuario se inactivó con éxito");
            gridReload();
          };
          request();
        }),
    },
    {
      text: "Reactivar",
      icon: TypeIcon.undo,
      hide: !userLinkedAccountDto.inactive,
      onClick: () =>
        modal.open(<ModalDialog text="¿Seguro desea reactivar el usuario?" />, () => {
          const request = async () => {
            await apiAdminProviderAccountUserLinkedAccount.reactivate(userLinkedAccountDto.id);
            notifier.showSuccess("El usuario se reactivó con éxito");
            gridReload();
          };
          request();
        }),
    },
  ];
  return menu;
}
