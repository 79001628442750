import AppNotificationHeaderIcon from "../../../app/AppNotifications/AppNotificationHeaderIcon";
import RequestedExportHeaderIcon from "../../../app/RequestedExports/RequestedExportHeaderIcon";
import { AppRoute } from "../../../AppRoutes";
import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import { AppInfoSiteType } from "../../context/AppInfoProvider";
import { useContextAppInfo } from "../../hooks/useContextAppInfo";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useNavigation } from "../../hooks/useNavigation";
import AdminProviderLogoIsoType from "../AdminProviderLogoIsoType";
import AdminProviderLogoProductName from "../AdminProviderLogoProductName";
import ButtonIcon from "../Button/ButtonIcon";
import { TypeIcon } from "../Icon";
import LayoutHeaderLogin from "./LayoutHeader.Login";

interface IPropsHeader {
  sideMenuIsExpanded: boolean;
  onToggleMenu(): void;
}

const LayoutHeader = (props: IPropsHeader) => {
  const navigation = useNavigation();
  const isMobile = useIsMobile();
  const appTheme = useAppTheme();
  const { appInfo } = useContextAppInfo();
  let headerMiddleColor = undefined;
  if (appInfo?.siteType == AppInfoSiteType.AdminProvider) {
    headerMiddleColor = appTheme.toHexColor(AppThemeColor.primary);
  } else if (appInfo?.siteType == AppInfoSiteType.SuperAdmin) {
    headerMiddleColor = appTheme.toHexColor(AppThemeColor.alert);
  }

  if (appInfo && !appInfo.adminProviderInfo) {
    throw new Error(
      "No se ha encontrado información del proveedor del servicio :(. ¿Está bien la dirección en el navegador?"
    );
  }

  return (
    <>
      <div
        className="layout-header"
        style={{
          backgroundColor: appTheme.toHexColor(AppThemeColor.header),
          color: appTheme.toHexColor(AppThemeColor.primaryContrast),
        }}>
        <div
          className="layout-header-left"
          style={{ backgroundColor: appTheme.toHexColor(AppThemeColor.header) }}>
          {isMobile && (
            <ButtonIcon
              size={AppSize.large}
              icon={props.sideMenuIsExpanded ? TypeIcon.chevronLeft : TypeIcon.mainMenu}
              color={AppThemeColor.white}
              onClick={() => props.onToggleMenu()}
            />
          )}
          {!isMobile && (
            <>
              <AdminProviderLogoIsoType
                className="layout-header-left-logo clickable"
                size={AppSize.large}
                onClick={() => {
                  navigation.go(
                    appInfo?.siteType == AppInfoSiteType.AdminProvider
                      ? AppRoute.admin.base
                      : AppRoute.base
                  );
                }}
              />
              <AdminProviderLogoProductName
                className="layout-header-left-logo clickable"
                onClick={() => {
                  navigation.go(
                    appInfo?.siteType == AppInfoSiteType.AdminProvider
                      ? AppRoute.admin.base
                      : AppRoute.base
                  );
                }}
              />
            </>
          )}
        </div>
        <div
          className="layout-header-middle"
          style={{
            backgroundColor: headerMiddleColor,
            color: appTheme.toHexColor(AppThemeColor.primaryContrast),
          }}>
          {appInfo?.siteType == AppInfoSiteType.AdminProvider && (
            <>Administración {appInfo.adminProviderInfo.name}</>
          )}
          {appInfo?.siteType == AppInfoSiteType.SuperAdmin && (
            <>SuperAdmin: {appInfo.adminProviderInfo.name}</>
          )}
        </div>
        <div className="layout-header-notifications">
          <RequestedExportHeaderIcon />
          <AppNotificationHeaderIcon />
        </div>
        <div className="layout-header-right">
          <LayoutHeaderLogin />
        </div>
      </div>
    </>
  );
};

export default LayoutHeader;
