import { useEffect, useState } from "react";
import { EInvoiceAuthorizationDto } from "../../../../api/app/dtos/EInvoiceAuthorizationDto";
import { FileUploadExtensionsAllowed } from "../../../../api/shared/enums/FileUploadExtensionsAllowed";
import { ButtonAccept } from "../../../../shared/components/Button/ButtonAccept";
import FileImport from "../../../../shared/components/FileImport";
import InfoText from "../../../../shared/components/InfoText";
import Container from "../../../../shared/components/Layout/GContainer";
import Row from "../../../../shared/components/Layout/GRow";
import { ModalSize } from "../../../../shared/context/ModalProvider";
import { useApiEInvoiceAuthorization } from "../../../../api/shared/hooks/useApiApp";
import { useModal } from "../../../../shared/hooks/useModal";
import { TextAlign } from "../../../../shared/TextAlign";
import EInvoiceAuthorizationDetails from "./EInvoiceAuthorizationDetails";

const EInvoiceAuthorizationImportPopup = () => {
  const [modal] = useModal();
  const [apiEInvoiceAuthorization] = useApiEInvoiceAuthorization();
  const [eInvoiceAuthorization, setEInvoiceAuthorization] = useState<EInvoiceAuthorizationDto>();

  const accept = async (authorization: EInvoiceAuthorizationDto) => {
    await apiEInvoiceAuthorization.createFromValidated(authorization);
    modal.closeAsSuccess(null, "El CAE se importó con éxito");
  };

  useEffect(() => {
    modal.setVisible("Importar CAE", ModalSize.small);
  }, [modal]);

  return (
    <Container>
      {!eInvoiceAuthorization && (
        <>
          <Row>
            <InfoText>
              Se debe importar el archivo en formato XML que se solicita en la página de la DGI
            </InfoText>
          </Row>
          <Row>
            <FileImport
              endpointUrl="/e-invoice-authorization/get-info"
              extensionsAllowed={FileUploadExtensionsAllowed.Xml}
              onUploadComplete={(response: EInvoiceAuthorizationDto) => {
                setEInvoiceAuthorization(response);
              }}
            />
          </Row>
        </>
      )}
      {eInvoiceAuthorization && (
        <>
          <EInvoiceAuthorizationDetails eInvoiceAuthorization={eInvoiceAuthorization} />
          <Row align={TextAlign.right}>
            <ButtonAccept text="Aceptar" onClick={() => accept(eInvoiceAuthorization)} />
          </Row>
        </>
      )}
    </Container>
  );
};

export default EInvoiceAuthorizationImportPopup;
