import { useEffect, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminTaxValueDto } from "../../api/admin/dtos/AdminTaxValueDto";
import { Tax } from "../../api/shared/enums/Tax";
import { enumName } from "../../api/shared/enums/_enumName";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormDate from "../../shared/components/forms/FormDate";
import FormNumber from "../../shared/components/forms/FormNumber";
import FormSwitch from "../../shared/components/forms/FormSwitch";
import FormSelectCountry from "../../shared/components/forms/Select/FormSelectCountry";
import FormSelectEnum from "../../shared/components/forms/Select/FormSelectEnum";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import RowDisplay from "../../shared/components/Layout/GRowDisplay";
import { useApiAdminTax } from "../../api/shared/hooks/useApiAdmin";
import useUtils from "../../shared/hooks/useUtils";
import { useContextModelState } from "../../shared/modelState/useContextModelState";

const AdminTaxValueForm = (props: { editMode?: boolean; onSave(): void }) => {
  const [taxes, setTaxes] = useState<Tax[]>([]);
  const [apiAdminTax] = useApiAdminTax();
  const modelState = useContextModelState();
  const adminTaxValue = modelState.model as AdminTaxValueDto;
  const { utils } = useUtils();

  useEffect(() => {
    if (!adminTaxValue.country) {
      setTaxes([]);
      return;
    }
    const request = async () => {
      const taxes = await apiAdminTax.getByCountry(adminTaxValue.country);
      setTaxes(taxes);
    };
    request();
  }, [adminTaxValue.country]);

  return (
    <>
      {!props.editMode && (
        <>
          <Row>
            <FormSelectCountry
              label="País"
              onlyAvailable={true}
              propertyName={nameof<AdminTaxValueDto>((p) => p.country)}
            />
          </Row>
          <Row>
            <FormSelectEnum
              label="Impuesto"
              propertyName={nameof<AdminTaxValueDto>((p) => p.tax)}
              optionValues={taxes}
              enumTypeName={enumName.Tax}
            />
          </Row>
        </>
      )}
      {props.editMode && (
        <RowDisplay label="Impuesto">
          {utils.enum.translate(enumName.Tax, adminTaxValue.tax)}
        </RowDisplay>
      )}

      <Row>
        <FormNumber label="Valor" propertyName={nameof<AdminTaxValueDto>((p) => p.value)} />
      </Row>

      <Row>
        <FormDate
          label="Válido desde"
          propertyName={nameof<AdminTaxValueDto>((p) => p.validSince)}
        />
      </Row>

      <Row>
        <FormDate
          label="Válido hasta"
          propertyName={nameof<AdminTaxValueDto>((p) => p.validUntil)}
        />
      </Row>

      <Row>
        <FormSwitch
          label="Definir en cada operación"
          propertyName={nameof<AdminTaxValueDto>((p) => p.operationMustSpecify)}
        />
      </Row>

      <RowButtons>
        <ButtonAccept onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default AdminTaxValueForm;
