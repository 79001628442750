import { CSSProperties, ReactElement, useEffect, useState } from "react";
import { SearchResultItemDto } from "../../../api/shared/dtos/SearchResultItemDto";
import { useIsMobile } from "../../hooks/useIsMobile";
import { ContextMenuItem } from "../ContextMenu/ContextMenu";
import Spinner from "../Spinner";
import GridRowColumns from "./GridRow.Columns";
import GridRowColumnsMobile from "./GridRow.ColumnsMobile";

const GridRow = (props: {
  item: SearchResultItemDto<any>;
  index: number;
  onGetRowStyle?(item: any): CSSProperties;
  onDisplayItemMenu?(item: any): ContextMenuItem[];
  onExpandRow?(item: any): Promise<ReactElement>;
  onExpandRowShouldDisableButton?(item: any): boolean;
  onSelect(item: any): void;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedLoading, setIsExpandedLoading] = useState(false);
  const [expandedRowContent, setExpandedRowContent] = useState<ReactElement>();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isExpanded) {
      const load = async () => {
        if (!props.onExpandRow) {
          return;
        }
        setIsExpandedLoading(true);
        try {
          setExpandedRowContent(await props.onExpandRow(props.item.item));
        } catch {
          setIsExpanded(false);
        }
        setIsExpandedLoading(false);
      };
      load();
    } else {
      setExpandedRowContent(undefined);
    }
  }, [isExpanded]);

  return (
    <>
      <div
        className={
          "grid-row " +
          (props.item.item.inactive ? "grid-row-inactive " : " ") +
          (isExpanded ? "row-is-expanded " : " ") +
          ((props.index + 1) % 2 === 0 ? " grid-row-even " : "")
        }
        style={props.onGetRowStyle ? props.onGetRowStyle(props.item.item) : undefined}>
        {!isMobile ? (
          <GridRowColumns
            isRowExpanded={isExpanded}
            item={props.item}
            onDisplayItemMenu={props.onDisplayItemMenu}
            onRowToggleExpand={() => {
              setIsExpanded(!isExpanded);
            }}
            onExpandRowShouldDisableButton={props.onExpandRowShouldDisableButton}
            onSelect={props.onSelect}
          />
        ) : (
          <GridRowColumnsMobile
            isRowExpanded={isExpanded}
            item={props.item}
            onDisplayItemMenu={props.onDisplayItemMenu}
            onExpandRowShouldDisableButton={props.onExpandRowShouldDisableButton}
            onRowToggleExpand={() => setIsExpanded(!isExpanded)}
            onSelect={props.onSelect}
          />
        )}
      </div>
      {isExpandedLoading && (
        <div className="grid-row-expanded-content">
          <Spinner />
        </div>
      )}
      {expandedRowContent && <div className="grid-row-expanded-content">{expandedRowContent}</div>}
    </>
  );
};

export default GridRow;
