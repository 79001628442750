// File generated by: Gesta.Build.PostEvents
export enum SentEmailStatus {
	Pending = 1,
	Sent = 2,
	Processed = 3,
	Delivered = 4,
	Opened = 5,
	Clicked = 6,
	Dropped = 7,
	Deferred = 8,
	Bounce = 9,
	Spam = 10,
	Error = 11,
}
