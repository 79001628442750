// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminEInvoiceUruguay {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  createTestingDataSet(
    accountId: string,
    apiOptions?: ApiOptions
  ): Promise<string> {
    return this.apiService.apiGet("/admin/e-invoice/uruguay/create-testing-data-set?" + 
      (accountId ? "&accountId=" + accountId : ""), apiOptions);
  }

  createCertificationDataSet(
    accountId: string,
    includeReceipts: boolean,
    apiOptions?: ApiOptions
  ): Promise<string> {
    return this.apiService.apiGet("/admin/e-invoice/uruguay/create-certification-data-set?" + 
      (accountId ? "&accountId=" + accountId : "") + 
      (includeReceipts ? "&includeReceipts=" + includeReceipts : ""), apiOptions);
  }

  createSimulationDataSet(
    accountId: string,
    includeReceipts: boolean,
    apiOptions?: ApiOptions
  ): Promise<string> {
    return this.apiService.apiGet("/admin/e-invoice/uruguay/create-simulation-data-set?" + 
      (accountId ? "&accountId=" + accountId : "") + 
      (includeReceipts ? "&includeReceipts=" + includeReceipts : ""), apiOptions);
  }

  createCertifiedProviderDataSet(
    accountId: string,
    apiOptions?: ApiOptions
  ): Promise<string> {
    return this.apiService.apiGet("/admin/e-invoice/uruguay/create-certified-provider-data-set?" + 
      (accountId ? "&accountId=" + accountId : ""), apiOptions);
  }

  downloadAllEnvelopes(
    accountId: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiGet("/admin/e-invoice/uruguay/download-all-envelopes?" + 
      (accountId ? "&accountId=" + accountId : ""), apiOptions);
  }

}
