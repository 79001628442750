import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminShardDto } from "../../api/admin/dtos/AdminShardDto";
import Grid from "../../shared/components/grid/Grid";
import {
  GridCellType,
  IGridItemTemplate,
  MobileSpecialType,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../shared/components/Layout/Page";
import { useApiAdminShard } from "../../api/shared/hooks/useApiAdmin";
import { useModal } from "../../shared/hooks/useModal";
import getAdminShardListItemMenu from "./AdminShardList.ItemMenu";

const AdminShardList = () => {
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [apiAdminShard] = useApiAdminShard();
  const [modal] = useModal();

  const itemTemplates: IGridItemTemplate<AdminShardDto>[] = [
    {
      header: "Nombre",
      field: nameof<AdminShardDto>((p) => p.name),
      mobileSpecialType: MobileSpecialType.Main,
      width: 20,
    },
    {
      header: "Cadena de conexión",
      field: nameof<AdminShardDto>((p) => p.connectionString),
      width: 60,
    },
    {
      header: "Obtener del KeyVault",
      field: nameof<AdminShardDto>((p) => p.getConnectionFromSecret),
      width: 15,
      cellType: GridCellType.iconCheck,
    },
  ];

  return (
    <Page title="Shards">
      <Grid
        itemTemplates={itemTemplates}
        reloadGrid={reloadGrid}
        hideIncludeInactiveButton={true}
        onSearch={(search, options) => apiAdminShard.getPaged(search, options)}
        onDisplayItemMenu={(adminShard: AdminShardDto) =>
          getAdminShardListItemMenu(adminShard, modal, () => {
            setReloadGrid(new Date());
          })
        }
      />
    </Page>
  );
};

export default AdminShardList;
