import { ReactElement, useState } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import ButtonAccept from "../Button/ButtonAccept";
import Icon, { TypeIcon } from "../Icon";
import Row from "./GRow";
import RowButtons from "./GRowButtons";

export class TabStep {
  title: string;
  icon?: any;
  value: string;
}

const TabStepper = (props: {
  children: ReactElement | ReactElement[];
  isEditMode?: boolean;
  tabs: TabStep[];
  onTabChange?(value: number): void;
  onLastTabAccept?(): void;
}) => {
  const [currentTab, setCurrentTab] = useState(props.tabs[0].value);
  const isLastTab = props.tabs.length == props.tabs.findIndex((c) => c.value == currentTab) + 1;
  const showNextButton = !isLastTab && !props.isEditMode;

  return (
    <>
      <Row>
        <TabContext value={currentTab}>
          <TabList
            onChange={(_e: any, value: string) => {
              setCurrentTab(value);
              if (props.onTabChange) {
                props.onTabChange(parseInt(value));
              }
            }}>
            {props.tabs.map((tabStep: TabStep) => (
              <Tab
                iconPosition="start"
                key={tabStep.value}
                label={tabStep.title}
                icon={
                  tabStep.icon && (
                    <Icon
                      type={tabStep.icon}
                      style={{ marginTop: 0, marginBottom: 0, marginRight: 10 }}
                    />
                  )
                }
                value={tabStep.value.toString()}
              />
            ))}
          </TabList>
          {props.children}
        </TabContext>
      </Row>
      <RowButtons preventValidation={false}>
        <ButtonAccept
          icon={showNextButton ? TypeIcon.arrowRight : undefined}
          text={showNextButton ? "Siguiente" : "Aceptar"}
          onClick={() => {
            if (!showNextButton && props.onLastTabAccept) {
              props.onLastTabAccept();
              return;
            }
            const currentTabIndex = props.tabs.findIndex((c) => c.value == currentTab);
            setCurrentTab(props.tabs[currentTabIndex + 1].value);
          }}
        />
      </RowButtons>
    </>
  );
};

export default TabStepper;
