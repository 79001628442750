import { AdminSentEmailDto } from "../../api/admin/dtos/AdminSentEmailDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import { ModalService } from "../../shared/hooks/useModal";
import AdminSentEmailDetailsPopup from "./AdminSentEmailDetailsPopup";

export function getAdminSentEmailListItemMenu(
  adminSentEmailDto: AdminSentEmailDto,
  modal: ModalService
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        modal.open(<AdminSentEmailDetailsPopup id={adminSentEmailDto.id} />);
      },
    },
  ];
}
