// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { EInvoiceEnvelopePurchaseDto } from "../dtos/EInvoiceEnvelopePurchaseDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { EInvoiceEnvelopePurchaseFiltersDto } from "../dtos/EInvoiceEnvelopePurchaseFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { EInvoiceEnvelopePurchaseProcessUploadedDto } from "../dtos/EInvoiceEnvelopePurchaseProcessUploadedDto";
import { dateToIsoString } from "../../shared/utils/apiUtilsDate";

export class ApiEInvoiceEnvelopePurchase {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    eInvoicePurchaseSearchDto: PagedSearchDto<EInvoiceEnvelopePurchaseFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<EInvoiceEnvelopePurchaseDto>> {
    return this.apiService.apiPost("/e-invoice-envelopes-purchase/paged", eInvoicePurchaseSearchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceEnvelopePurchaseDto> {
    return this.apiService.apiGet("/e-invoice-envelopes-purchase/" + id, apiOptions);
  }

  upload(
    apiOptions?: ApiOptions
  ): Promise<EInvoiceEnvelopePurchaseProcessUploadedDto> {
    return this.apiService.apiPost("/e-invoice-envelopes-purchase/upload", null, apiOptions);
  }

  uploadedProcess(
    options: EInvoiceEnvelopePurchaseProcessUploadedDto,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceEnvelopePurchaseDto> {
    return this.apiService.apiPost("/e-invoice-envelopes-purchase/upload-process", options, apiOptions);
  }

  refreshFromEmailDateRange(
    startDate: Date,
    endDate: Date,
    apiOptions?: ApiOptions
  ): Promise<string> {
    return this.apiService.apiGet("/e-invoice-envelopes-purchase/refresh-from-email-date-range?" + 
      (startDate ? "&startDate=" + dateToIsoString(startDate) : "") + 
      (endDate ? "&endDate=" + dateToIsoString(endDate) : ""), apiOptions);
  }

  refreshFromEmailLastUIRead(
    apiOptions?: ApiOptions
  ): Promise<string> {
    return this.apiService.apiGet("/e-invoice-envelopes-purchase/refresh-from-email-last-read", apiOptions);
  }

}
