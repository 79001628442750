// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminRetentionDto } from "../dtos/AdminRetentionDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { AdminRetentionFiltersDto } from "../dtos/AdminRetentionFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminRetention {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<AdminRetentionFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AdminRetentionDto>> {
    return this.apiService.apiPost("/admin/retention/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AdminRetentionDto> {
    return this.apiService.apiGet("/admin/retention/" + id, apiOptions);
  }

  create(
    adminRetentionDto: AdminRetentionDto,
    apiOptions?: ApiOptions
  ): Promise<AdminRetentionDto> {
    return this.apiService.apiPost("/admin/retention", adminRetentionDto, apiOptions);
  }

  edit(
    adminRetentionDto: AdminRetentionDto,
    apiOptions?: ApiOptions
  ): Promise<AdminRetentionDto> {
    return this.apiService.apiPut("/admin/retention", adminRetentionDto, apiOptions);
  }

}
