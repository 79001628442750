// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { EmailConfigDto } from "../dtos/EmailConfigDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { OAuthTokenResponseDto } from "../dtos/OAuthTokenResponseDto";
import { OAuthUriDto } from "../dtos/OAuthUriDto";

export class ApiEmailConfig {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getAll(
    apiOptions?: ApiOptions
  ): Promise<EmailConfigDto[]> {
    return this.apiService.apiGet("/config/email", apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<EmailConfigDto> {
    return this.apiService.apiGet("/config/email/" + id, apiOptions);
  }

  getByType(
    configType: number,
    apiOptions?: ApiOptions
  ): Promise<EmailConfigDto> {
    return this.apiService.apiGet("/config/email/by-type?" + 
      (configType ? "&configType=" + configType : ""), apiOptions);
  }

  create(
    emailConfigDto: EmailConfigDto,
    apiOptions?: ApiOptions
  ): Promise<EmailConfigDto> {
    return this.apiService.apiPost("/config/email", emailConfigDto, apiOptions);
  }

  edit(
    emailConfigDto: EmailConfigDto,
    apiOptions?: ApiOptions
  ): Promise<EmailConfigDto> {
    return this.apiService.apiPut("/config/email", emailConfigDto, apiOptions);
  }

  smtpVerify(
    emailConfig: EmailConfigDto,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/config/email/smtp-verify", emailConfig, apiOptions);
  }

  imapVerify(
    emailConfig: EmailConfigDto,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/config/email/imap-verify", emailConfig, apiOptions);
  }

  checkValidationEmail(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/config/email/check-validation-email/" + id, null, apiOptions);
  }

  checkValidationAckEmail(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/config/email/check-validation-ack-email/" + id, null, apiOptions);
  }

  oAuthAccessGrantedGmail(
    code: string,
    apiOptions?: ApiOptions
  ): Promise<OAuthTokenResponseDto> {
    return this.apiService.apiGet("/config/email/oauth-access-granted-gmail?" + 
      (code ? "&code=" + code : ""), apiOptions);
  }

  oAuthReceiveAccessTokenGmail(
    apiOptions?: ApiOptions
  ): Promise<OAuthUriDto> {
    return this.apiService.apiGet("/config/email/oauth-get-uri-gmail", apiOptions);
  }

}
