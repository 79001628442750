import { useEffect, useState } from "react";
import useUtils from "../../../hooks/useUtils";
import { IdGuidName } from "../../../IdGuidName";
import InputSelectBaseMultiple from "./InputSelectBaseMultiple";
import { PropsInputSelectEnumBase } from "./InputSelectEnumBase";

export interface PropsInputSelectEnumMultiple
  extends Omit<PropsInputSelectEnumBase<number[] | null>, "options"> {
  enumTypeName: string;
  optionValues?: any[];
}

const InputSelectEnumMultiple = (props: PropsInputSelectEnumMultiple) => {
  const { utils } = useUtils();
  const [options, setOptions] = useState<IdGuidName[]>();

  useEffect(() => {
    const mappedOptions = (
      props.optionValues
        ? utils.enum.valuesToIdName(props.enumTypeName, props.optionValues)
        : utils.enum.toIdName(props.enumTypeName)
    ).map((c) => new IdGuidName(c.id.toString(), c.name));

    setOptions(mappedOptions);
  }, [props.optionValues]);

  if (!options) {
    return <></>;
  }

  return (
    <InputSelectBaseMultiple
      {...props}
      options={options}
      onChange={(e) => {
        const values = e.target.value as any[];
        if (values.length == 0) {
          props.onChange([]);
        }

        let parsedValues = values.map((v: any) => parseInt(v));
        const lastValue = parsedValues[parsedValues.length - 1];
        if (parsedValues.indexOf(lastValue) != parsedValues.length - 1) {
          parsedValues = parsedValues.filter((v) => v != lastValue);
        }
        props.onChange(parsedValues);
      }}
    />
  );
};

export default InputSelectEnumMultiple;
