import { useEffect, useState } from "react";
import { UserDto } from "../../api/admin/dtos/UserDto";
import { UserLinkedAdminProviderDto } from "../../api/admin/dtos/UserLinkedAdminProviderDto";
import { useApiAdminUserLinkedAdminProvider } from "../../api/shared/hooks/useApiAdmin";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import InputAutocompleteAdminUser from "../../shared/components/inputs/autocomplete/InputAutocompleteAdminUser";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useModal } from "../../shared/hooks/useModal";

const AdminProviderLinkUserPopup = (props: { adminProviderId: string }) => {
  const [modal] = useModal();
  const [adminUser, setAdminUser] = useState<UserDto | null>(null);
  const [apiAdminUserLinkedAdminProvider] = useApiAdminUserLinkedAdminProvider();

  useEffect(() => {
    modal.setVisible("Agregar administrador", ModalSize.small);
  }, [modal]);

  const save = () => {
    const request = async () => {
      const userLinkedAdminProviderDto = new UserLinkedAdminProviderDto();
      userLinkedAdminProviderDto.userId = adminUser?.id ?? "";
      userLinkedAdminProviderDto.adminProviderId = props.adminProviderId;
      await apiAdminUserLinkedAdminProvider.create(userLinkedAdminProviderDto);
      modal.closeAsSuccess(userLinkedAdminProviderDto, "El administrador se agregó con éxito");
    };
    request();
  };
  return (
    <Container>
      <Row>
        <InputAutocompleteAdminUser label="Usuario" value={adminUser} onChange={setAdminUser} />
      </Row>
      <RowButtons>
        <ButtonAccept onClick={save} />
      </RowButtons>
    </Container>
  );
};

export default AdminProviderLinkUserPopup;
