import { ApiAdminPointOfSaleClient } from "../../api/admin/apis/ApiAdminPointOfSaleClient";
import { AdminPointOfSaleClientDto } from "../../api/admin/dtos/AdminPointOfSaleClientDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import { ModalService } from "../../shared/hooks/useModal";
import AdminPointOfSaleClientDetailsPopup from "./AdminPointOfSaleClientDetailsPopup";

export function getAdminPointOfSaleClientListItemMenu(
  adminPointOfSaleClientDto: AdminPointOfSaleClientDto,
  apiAdminPointOfSaleClient: ApiAdminPointOfSaleClient,
  modal: ModalService
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        modal.open(<AdminPointOfSaleClientDetailsPopup id={adminPointOfSaleClientDto.id} />);
      },
    },
    {
      text: "Enviar mensaje de prueba",
      icon: TypeIcon.arrowRight,
      onClick: () => {
        apiAdminPointOfSaleClient.sendTestMessage(adminPointOfSaleClientDto.id);
      },
    },
  ];
}
