import { nameof } from "ts-simple-nameof";
import { AdminAccountDto } from "../../../../api/admin/dtos/AdminAccountDto";
import { AdminAccountFiltersDto } from "../../../../api/admin/dtos/AdminAccountFiltersDto";
import { AdminAccountSimpleDto } from "../../../../api/shared/dtos/AdminAccountSimpleDto";
import { SuggestItemDto } from "../../../../api/shared/dtos/SuggestItemDto";
import { SuggestSearchDto } from "../../../../api/shared/dtos/SuggestSearchDto";
import { useApiAdminProviderAdminAccount } from "../../../../api/shared/hooks/useApiAdminProviders";
import { AppInfoSiteType } from "../../../context/AppInfoProvider";
import { useContextAppInfo } from "../../../hooks/useContextAppInfo";
import InputAutocompleteBase, { PropsInputAutocompleteBase } from "./InputAutocompleteBase";

export type PropsInputAutocompleteAdminAccount = Omit<
  PropsInputAutocompleteBase<AdminAccountDto | null>,
  "onGetEntityById"
>;

const InputAutocompleteAdminAccount = (props: PropsInputAutocompleteAdminAccount) => {
  const [apiAdminAdminProviderAccount] = useApiAdminProviderAdminAccount();
  const { appInfo } = useContextAppInfo();

  const apiRequest = (echoId: number, term: string, isInitialLoad: boolean) => {
    const searchDto = new SuggestSearchDto<AdminAccountFiltersDto>();
    searchDto.echoId = echoId;
    searchDto.filters = new AdminAccountFiltersDto();
    searchDto.filters.defaultTextSearch = term;
    searchDto.filters.crossShardIncludeAllProviders =
      appInfo?.siteType == AppInfoSiteType.SuperAdmin;
    searchDto.isInitialLoad = isInitialLoad;
    searchDto.orderBy = [{ columnName: nameof<AdminAccountDto>((p) => p.name), asc: true }];
    const apiOptions = {
      preventSpinner: true,
    };

    return apiAdminAdminProviderAccount.suggest(searchDto, apiOptions);
  };

  return (
    <InputAutocompleteBase
      {...props}
      value={props.value}
      inputTextValue={props.inputTextValue ?? props.value?.name ?? ""}
      onGetEntityById={(id: string) =>
        apiAdminAdminProviderAccount.getById(id, { preventSpinner: true })
      }
      onServerSideSuggest={apiRequest}
      onMapFromItemSuggest={(item: SuggestItemDto) => {
        const adminAccountSimpleDto = new AdminAccountSimpleDto();
        adminAccountSimpleDto.id = item.id;
        adminAccountSimpleDto.name = item.label;
        return adminAccountSimpleDto;
      }}
    />
  );
};

export default InputAutocompleteAdminAccount;
