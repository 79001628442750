import { ApiAdminProviderAccessPointOfSale } from "../../../api/admin/apis/ApiAdminProviderAccessPointOfSale";
import { PointOfSaleDto } from "../../../api/app/dtos/PointOfSaleDto";
import { ContextMenuItem } from "../../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../../shared/components/Icon";
import ModalDialog from "../../../shared/components/Modal/ModalDialog";
import { ModalService } from "../../../shared/hooks/useModal";
import { Notifier } from "../../../shared/hooks/useNotifier";
import AdminPointOfSaleCreatePopup from "./AdminPointOfSaleCreatePopup";
import AdminPointOfSaleDetailsPopup from "./AdminPointOfSaleDetailsPopup";
import AdminPointOfSaleEditPopup from "./AdminPointOfSaleEditPopup";

export default function getAdminPointOfSaleListItemMenu(
  pointOfSaleDto: PointOfSaleDto,
  apiAdminAccessPointOfSale: ApiAdminProviderAccessPointOfSale,
  modal: ModalService,
  notifier: Notifier,
  reloadGrid: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () =>
        modal.open(
          <AdminPointOfSaleDetailsPopup
            accountId={pointOfSaleDto.accountId}
            pointOfSaleId={pointOfSaleDto.id}
          />,
          reloadGrid
        ),
    },
    {
      text: "Editar",
      icon: TypeIcon.edit,
      onClick: () =>
        modal.open(
          <AdminPointOfSaleEditPopup
            accountId={pointOfSaleDto.accountId}
            pointOfSaleId={pointOfSaleDto.id}
          />,
          reloadGrid
        ),
    },
    {
      text: "Crear para la misma sucursal",
      icon: TypeIcon.create,
      onClick: () =>
        modal.open(
          <AdminPointOfSaleCreatePopup
            accountId={pointOfSaleDto.accountId}
            accountBranchId={pointOfSaleDto.accountBranchId}
          />,
          reloadGrid
        ),
    },
    {
      text: "Inactivar",
      icon: TypeIcon.delete,
      hide: pointOfSaleDto.inactive,
      onClick: () => {
        modal.open(<ModalDialog text="¿Seguro desea inactivar el punto de venta?" />, () => {
          const request = async () => {
            apiAdminAccessPointOfSale.inactivate(pointOfSaleDto.accountId, pointOfSaleDto.id);
            notifier.showSuccess("El punto de venta se inactivó con éxito");
            reloadGrid();
          };
          request();
        });
      },
    },
    {
      text: "Reactivar",
      icon: TypeIcon.undo,
      hide: !pointOfSaleDto.inactive,
      onClick: () => {
        modal.open(<ModalDialog text="¿Seguro desea reactivar el punto de venta?" />, () => {
          const request = async () => {
            apiAdminAccessPointOfSale.reactivate(pointOfSaleDto.accountId, pointOfSaleDto.id);
            notifier.showSuccess("El punto de venta se reactivó con éxito");
            reloadGrid();
          };
          request();
        });
      },
    },
  ];
}
