import { TemplateGroup } from "../../api/shared/enums/TemplateGroup";
import { TemplateType } from "../../api/shared/enums/TemplateType";

export function templateTypeIsGeneric(templateType: TemplateType): boolean {
  return templateType <= TemplateType.AdminGenericEmail;
}

export function templateGetGroup(templateType: TemplateType): TemplateGroup {
  switch (templateType) {
    case TemplateType.Generic:
    case TemplateType.AdminGeneric:
      return TemplateGroup.Generic;
    case TemplateType.GenericEmail:
    case TemplateType.AdminGenericEmail:
      return TemplateGroup.Email;
    case TemplateType.GenericPdf:
    case TemplateType.AdminGenericPdf:
      return TemplateGroup.Pdf;
  }

  if (
    templateType >= TemplateType.PdfSaleInvoice &&
    templateType < TemplateType.AdminEmailNewAccountToUser
  ) {
    return TemplateGroup.Pdf;
  }
  return TemplateGroup.Email;
}

export function templateTypeIsAdmin(templateType: TemplateType): boolean {
  return TemplateType[templateType].startsWith("Admin");
}
