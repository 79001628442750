import { createContext, PropsWithChildren, useMemo, useState } from "react";
import { AppSize } from "../AppSize";
import Spinner from "../components/Spinner";

const GlobalSpinnerContextSet = createContext<{ setSpinnerState: (value: boolean) => void }>(
  {} as any
);

const GlobalSpinnerContextGet = createContext<{
  spinnerState: boolean;
}>({} as any);

const GlobalSpinnerProvider: React.FC<
  PropsWithChildren<{
    children: any;
  }>
> = ({ children }: any) => {
  const [spinnerState, setSpinnerState] = useState(false);

  const memoSpinnerState = useMemo(() => {
    return { spinnerState: spinnerState };
  }, [spinnerState]);

  const memoSetSpinnerState = useMemo(() => {
    return { setSpinnerState: setSpinnerState };
  }, [setSpinnerState]);

  return (
    <GlobalSpinnerContextGet.Provider value={memoSpinnerState}>
      <GlobalSpinnerContextSet.Provider value={memoSetSpinnerState}>
        {memoSpinnerState.spinnerState && (
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              paddingTop: "180px",
              width: "100%",
              height: "100vh",
              textAlign: "center",
              zIndex: 100000,
              userSelect: "none",
            }}>
            <Spinner size={AppSize.biggest} />
          </div>
        )}
        {children}
      </GlobalSpinnerContextSet.Provider>
    </GlobalSpinnerContextGet.Provider>
  );
};

export { GlobalSpinnerContextSet, GlobalSpinnerProvider };
