// File generated by Gesta.Build.PostEvents
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { LoggingLevel } from "../../shared/enums/LoggingLevel";
import { FilterRangeDateDto } from "../../shared/dtos/FilterRangeDateDto";
import { UserSimpleDto } from "./UserSimpleDto";
import { AdminAccountSimpleDto } from "../../shared/dtos/AdminAccountSimpleDto";

export class LogEventFiltersDto extends FiltersDto {
  levelList: LoggingLevel[] = [];
  date: FilterRangeDateDto;
  categoryFullName: string = "";
  message: string = "";
  requestPath: string = "";
  traceId: string = "";
  userId: string;
  user: UserSimpleDto;
  accountId: string;
  account: AdminAccountSimpleDto;
  impersonatedEnvironment: string = "";
}
