import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { AdminAccountDto } from "../../../api/admin/dtos/AdminAccountDto";
import { useApiAdminProviderEInvoiceDailyReportProcess } from "../../../api/shared/hooks/useApiAdminProviders";
import ButtonAccept from "../../../shared/components/Button/ButtonAccept";
import InputAutocompleteAdminAccount from "../../../shared/components/inputs/autocomplete/InputAutocompleteAdminAccount";
import InputDate from "../../../shared/components/inputs/InputDate";
import InputSwitch from "../../../shared/components/inputs/InputSwitch";
import Row from "../../../shared/components/Layout/GRow";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import { ModalSize } from "../../../shared/context/ModalProvider";
import { useModal } from "../../../shared/hooks/useModal";

const AdminEInvoiceDailyReportGenerateForAccountPopup = () => {
  const [modal] = useModal();
  const [apiAdminEInvoiceDailyReportProcess] = useApiAdminProviderEInvoiceDailyReportProcess();
  const [adminAccount, setAdminAccount] = useState<AdminAccountDto | null>(null);
  const [date, setDate] = useState<Date | null>(null);
  const [regenerate, setRegenerate] = useState(false);

  useEffect(() => {
    modal.setVisible("Generar reportes", ModalSize.small);
  }, []);

  const startProcess = async () => {
    if (!adminAccount) {
      return;
    }

    await apiAdminEInvoiceDailyReportProcess.startProcess(adminAccount?.id, date, regenerate);
    modal.closeAsSuccess(null, "Se inició el proceso de generación de reporte diario");
  };

  return (
    <Container>
      <Row>
        <InputAutocompleteAdminAccount
          label="Cuenta"
          value={adminAccount}
          onChange={setAdminAccount}
        />
      </Row>
      <Row>
        <InputDate label="Fecha" value={date} onChange={setDate} />
      </Row>
      <Row>
        <InputSwitch
          label="Regenerar si ya existe"
          value={regenerate}
          onChange={(value) => setRegenerate(value ?? false)}
        />
      </Row>
      <RowButtons>
        <ButtonAccept disabled={!adminAccount} text="Generar reporte" onClick={startProcess} />
      </RowButtons>
    </Container>
  );
};

export default AdminEInvoiceDailyReportGenerateForAccountPopup;
