// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminProviderDto } from "../dtos/AdminProviderDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { AdminProviderFiltersDto } from "../dtos/AdminProviderFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { AdminProviderWithSecureInfoDto } from "../dtos/AdminProviderWithSecureInfoDto";
import { SuggestResponseDto } from "../../shared/dtos/SuggestResponseDto";
import { SuggestSearchDto } from "../../shared/dtos/SuggestSearchDto";

export class ApiAdminProvider {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<AdminProviderFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AdminProviderDto>> {
    return this.apiService.apiPost("/admin/providers/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AdminProviderWithSecureInfoDto> {
    return this.apiService.apiGet("/admin/providers/" + id, apiOptions);
  }

  create(
    adminProviderDto: AdminProviderWithSecureInfoDto,
    apiOptions?: ApiOptions
  ): Promise<AdminProviderWithSecureInfoDto> {
    return this.apiService.apiPost("/admin/providers", adminProviderDto, apiOptions);
  }

  edit(
    adminProviderDto: AdminProviderWithSecureInfoDto,
    apiOptions?: ApiOptions
  ): Promise<AdminProviderWithSecureInfoDto> {
    return this.apiService.apiPut("/admin/providers", adminProviderDto, apiOptions);
  }

  suggest(
    suggestSearch: SuggestSearchDto<AdminProviderFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/admin/providers/suggest", suggestSearch, apiOptions);
  }

}
