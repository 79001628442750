// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminAccountAppAccessDto } from "../dtos/AdminAccountAppAccessDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminAccountAppAccess {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getCurrent(
    apiOptions?: ApiOptions
  ): Promise<AdminAccountAppAccessDto> {
    return this.apiService.apiGet("/admin-account/current", apiOptions);
  }

}
