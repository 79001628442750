// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { FilterRangeDateDto } from "../../shared/dtos/FilterRangeDateDto";

export class ApiDate {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getCurrentTime(
    apiOptions?: ApiOptions
  ): Promise<Date> {
    return this.apiService.apiGet("/date/local-now", apiOptions);
  }

  localTodayUtc(
    apiOptions?: ApiOptions
  ): Promise<Date> {
    return this.apiService.apiGet("/date/today-utc", apiOptions);
  }

  startMonthUtc(
    apiOptions?: ApiOptions
  ): Promise<Date> {
    return this.apiService.apiGet("/date/start-month-utc", apiOptions);
  }

  startQuarterUtc(
    apiOptions?: ApiOptions
  ): Promise<Date> {
    return this.apiService.apiGet("/date/start-quarter-utc", apiOptions);
  }

  startWeekUtc(
    apiOptions?: ApiOptions
  ): Promise<Date> {
    return this.apiService.apiGet("/date/start-week-utc", apiOptions);
  }

  startYearUtc(
    apiOptions?: ApiOptions
  ): Promise<Date> {
    return this.apiService.apiGet("/date/start-year-utc", apiOptions);
  }

  getRangeMinAndMax(
    type: number,
    apiOptions?: ApiOptions
  ): Promise<FilterRangeDateDto> {
    return this.apiService.apiGet("/date/range-min-max?" + 
      (type ? "&type=" + type : ""), apiOptions);
  }

}
