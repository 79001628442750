// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { State } from "../../shared/enums/State";
import { Country } from "../../shared/enums/Country";

export class AdminAddressDto extends BaseEntityDto {
  addressLine: string = "";
  addressLine2: string = "";
  city: string = "";
  state: State;
  country: Country;
  zipCode: string = "";
}
