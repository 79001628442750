import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { SentEmailDto } from "../../api/app/dtos/SentEmailDto";
import { enumName } from "../../api/shared/enums/_enumName";
import Grid from "../../shared/components/grid/Grid";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../shared/components/Layout/Page";
import { useApiSentEmail } from "../../api/shared/hooks/useApiApp";
import { useModal } from "../../shared/hooks/useModal";
import { enumColorSentEmailStatus } from "../../shared/utils/utilEnumColors";
import { getSentEmailListItemMenu } from "./SentEmailList.ItemMenu";

const SentEmailList = () => {
  const [apiSentEmail] = useApiSentEmail();
  const [reloadGrid] = useState(new Date());
  const [modal] = useModal();

  const itemTemplates: IGridItemTemplate<SentEmailDto>[] = [
    {
      header: "Fecha",
      field: nameof<SentEmailDto>((p) => p.createdDate),
      width: 10,
      cellType: GridCellType.dateTime,
    },
    {
      header: "Para",
      field: nameof<SentEmailDto>((p) => p.to),
      width: 15,
    },
    {
      header: "Asunto",
      field: nameof<SentEmailDto>((p) => p.subject),
      width: 30,
    },
    {
      header: "Desde",
      field: nameof<SentEmailDto>((p) => p.from),
      width: 15,
    },
    {
      header: "Actualización",
      field: nameof<SentEmailDto>((p) => p.updatedDate),
      width: 10,
      cellType: GridCellType.dateTime,
    },
    {
      header: "Proveedor",
      field: nameof<SentEmailDto>((p) => p.providerType),
      width: 10,
      cellTypeEnumName: enumName.EmailProviderType,
    },
    {
      header: "Estado",
      field: nameof<SentEmailDto>((p) => p.status),
      width: 10,
      cellType: GridCellType.statusWithColor,
      cellTypeEnumName: enumName.SentEmailStatus,
      cellTypeMapColor: enumColorSentEmailStatus,
    },
  ];

  return (
    <Page title="Correos enviados">
      <Grid
        itemTemplates={itemTemplates}
        onSearch={(search, options) => apiSentEmail.getPaged(search, options)}
        reloadGrid={reloadGrid}
        hideIncludeInactiveButton={true}
        onDisplayItemMenu={(SentEmailDto: SentEmailDto) =>
          getSentEmailListItemMenu(SentEmailDto, modal)
        }
      />
    </Page>
  );
};

export default SentEmailList;
