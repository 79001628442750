import { EInvoiceDailyReportStatus } from "../../api/shared/enums/EInvoiceDailyReportStatus";
import { EInvoiceEnvelopeStatus } from "../../api/shared/enums/EInvoiceEnvelopeStatus";
import { EInvoicePurchaseApprovalStatus } from "../../api/shared/enums/EInvoicePurchaseApprovalStatus";
import { LoggingLevel } from "../../api/shared/enums/LoggingLevel";
import { LongProcessStatus } from "../../api/shared/enums/LongProcessStatus";
import { SentEmailStatus } from "../../api/shared/enums/SentEmailStatus";
import { VaultCertificateStatus } from "../../api/shared/enums/VaultCertificateStatus";
import { AppThemeColor } from "../../styles/color";

export const enumColorEInvoiceDailyReportStatus = (enumKey: EInvoiceDailyReportStatus) => {
  switch (enumKey) {
    case EInvoiceDailyReportStatus.Pending:
    case EInvoiceDailyReportStatus.Sent:
    case EInvoiceDailyReportStatus.Processing:
    case EInvoiceDailyReportStatus.PendingVerification:
    case EInvoiceDailyReportStatus.PendingSent:
      return AppThemeColor.warning;
    case EInvoiceDailyReportStatus.Accepted:
      return AppThemeColor.success;
  }
  return AppThemeColor.alert;
};

export const enumColorEInvoicePurchaseApprovalStatus = (
  enumKey?: EInvoicePurchaseApprovalStatus
) => {
  switch (enumKey) {
    case EInvoicePurchaseApprovalStatus.Pending:
      return AppThemeColor.black;
    case EInvoicePurchaseApprovalStatus.Approved:
      return AppThemeColor.success;
    case EInvoicePurchaseApprovalStatus.Rejected:
      return AppThemeColor.alert;
  }
  return AppThemeColor.gray;
};

export const enumColorEInvoiceEnvelopeStatus = (enumKey?: EInvoiceEnvelopeStatus) => {
  switch (enumKey) {
    case EInvoiceEnvelopeStatus.Pending:
    case EInvoiceEnvelopeStatus.Sent:
    case EInvoiceEnvelopeStatus.SentAck:
      return AppThemeColor.warning;
    case EInvoiceEnvelopeStatus.Accepted:
    case EInvoiceEnvelopeStatus.NotApply:
      return AppThemeColor.success;
    case EInvoiceEnvelopeStatus.VerificationError:
    case EInvoiceEnvelopeStatus.ErrorSending:
    case EInvoiceEnvelopeStatus.Rejected:
      return AppThemeColor.alert;
  }
  return AppThemeColor.gray;
};

export const enumColorLongProcessStatus = (enumKey: LongProcessStatus) => {
  switch (enumKey) {
    case LongProcessStatus.Done:
      return AppThemeColor.success;
    case LongProcessStatus.Cancelled:
    case LongProcessStatus.CancellationRequested:
      return AppThemeColor.inactive;
    case LongProcessStatus.Running:
    case LongProcessStatus.Starting:
      return AppThemeColor.warning;
  }
  return AppThemeColor.alert;
};

export const enumColorSentEmailStatus = (enumKey: SentEmailStatus) => {
  switch (enumKey) {
    case SentEmailStatus.Sent:
      return AppThemeColor.black;
    case SentEmailStatus.Pending:
    case SentEmailStatus.Processed:
    case SentEmailStatus.Deferred:
      return AppThemeColor.warning;
    case SentEmailStatus.Delivered:
    case SentEmailStatus.Opened:
    case SentEmailStatus.Clicked:
      return AppThemeColor.success;
    case SentEmailStatus.Error:
    case SentEmailStatus.Bounce:
    case SentEmailStatus.Spam:
    case SentEmailStatus.Dropped:
      return AppThemeColor.alert;
  }
};

export const enumColorVaultCertificateStatus = (enumKey: VaultCertificateStatus) => {
  switch (enumKey) {
    case VaultCertificateStatus.Active:
      return AppThemeColor.success;
    case VaultCertificateStatus.Inactive:
      return AppThemeColor.inactive;
  }
};

export const enumColorLoggingLevel = (enumKey: LoggingLevel) => {
  switch (enumKey) {
    case LoggingLevel.Error:
    case LoggingLevel.Critical:
      return AppThemeColor.alert;
    case LoggingLevel.Warning:
      return AppThemeColor.warning;
    case LoggingLevel.Debug:
      return AppThemeColor.black;
    case LoggingLevel.Information:
      return AppThemeColor.info;
  }
  return AppThemeColor.gray;
};
