import { useEffect, useState } from "react";
import { SaleReferenceDto } from "../../api/app/dtos/SaleReferenceDto";
import { InvoiceType } from "../../api/shared/enums/InvoiceType";
import { useApiSale } from "../../api/shared/hooks/useApiApp";
import { ModelStateArrayItemHelper } from "../../shared/modelState/ModelStateArray";
import { ThemeVariant } from "../../shared/ThemeVariant";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import SaleFormLineRow from "./SaleFormLines.Row";
import SaleFormReferenceColumn from "./SaleFormReferenceColumn";
import { ISaleReferenceColumn } from "./SaleFormReferences";

const SaleFormReferencesItem = (props: {
  arrayItemHelper: ModelStateArrayItemHelper;
  referenceColumns: ISaleReferenceColumn[];
  showLabel: boolean;
  variant: ThemeVariant;
}) => {
  const [invoiceType, setInvoiceType] = useState<InvoiceType>();
  const [serie, setSerie] = useState<string>();
  const [number, setNumber] = useState<number>();
  const [apiSale] = useApiSale();
  const modelStateSale = useContextModelStateSale();
  const saleReference = props.arrayItemHelper.item as SaleReferenceDto;

  useEffect(() => {
    if (
      invoiceType === saleReference.invoiceType &&
      serie === saleReference.serie &&
      number === saleReference.number
    ) {
      return;
    }

    setInvoiceType(saleReference.invoiceType);
    setSerie(saleReference.serie);
    setNumber(saleReference.number ?? 0);
    modelStateSale.referenceSetIsGlobal(props.arrayItemHelper.index, true);

    const request = async () => {
      const sale = await apiSale.getSimpleByNumber(
        saleReference.invoiceType,
        saleReference.serie,
        saleReference.number ?? 0,
        { preventSpinner: true }
      );
      if (sale?.date) {
        modelStateSale.referenceSetIsGlobal(props.arrayItemHelper.index, false);
        modelStateSale.referenceSetReferenceDate(props.arrayItemHelper.index, sale.date);
      }
    };
    request();
  }, [saleReference.invoiceType, saleReference.serie, saleReference.number]);

  return (
    <SaleFormLineRow key={props.arrayItemHelper.index}>
      <div style={{ width: "100%", display: "flex", gap: 15, flexDirection: "column" }}>
        <div style={{ width: "100%", display: "flex", gap: 5 }}>
          {props.referenceColumns.map((saleReferenceColumn, index) => (
            <SaleFormReferenceColumn
              key={index}
              arrayItemHelper={props.arrayItemHelper}
              saleReferenceColumn={saleReferenceColumn}
              showLabel={props.showLabel}
              variant={props.variant}
            />
          ))}
        </div>
      </div>
    </SaleFormLineRow>
  );
};

export default SaleFormReferencesItem;
