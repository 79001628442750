import { useEffect, useState } from "react";
import { AdminAccountDto } from "../../../api/admin/dtos/AdminAccountDto";
import { AdminTemplateDto } from "../../../api/admin/dtos/AdminTemplateDto";
import { AdminTemplateEmailDto } from "../../../api/admin/dtos/AdminTemplateEmailDto";
import { AdminTemplateGenericDto } from "../../../api/admin/dtos/AdminTemplateGenericDto";
import { AdminTemplatePdfDto } from "../../../api/admin/dtos/AdminTemplatePdfDto";
import { Country } from "../../../api/shared/enums/Country";
import { TemplateGenericType } from "../../../api/shared/enums/TemplateGenericType";
import { TemplateGroup } from "../../../api/shared/enums/TemplateGroup";
import { TemplateType } from "../../../api/shared/enums/TemplateType";
import { enumName } from "../../../api/shared/enums/_enumName";
import Button from "../../../shared/components/Button/Button";
import InputSwitch from "../../../shared/components/inputs/InputSwitch";
import InputSelectEnum from "../../../shared/components/inputs/select/InputSelectEnum";
import Column from "../../../shared/components/Layout/GColumn";
import Row from "../../../shared/components/Layout/GRow";
import Section from "../../../shared/components/Layout/GSection";
import { useApiAdminAccountPublicTemplateDesign } from "../../../api/shared/hooks/useApiAdmin";
import { TextAlign } from "../../../shared/TextAlign";
import AdminTemplateSelectAccount from "./AdminTemplateSelectAccount";

const AdminTemplateCreateFirstStep = (props: {
  adminTemplate: AdminTemplateDto;
  onContinue: (adminTemplate: AdminTemplateDto) => void;
}) => {
  const [displayAdminTemplates, setDisplayAdminTemplates] = useState(false);
  const [apiTemplateDesign] = useApiAdminAccountPublicTemplateDesign();
  const [templateTypes, setTemplateTypes] = useState<TemplateType[]>();
  const [canContinue, setCanContinue] = useState(false);
  const [adminAccount, setAdminAccount] = useState<AdminAccountDto | null>(null);
  const [selectedType, setSelectedType] = useState<TemplateType | null>(null);
  const [selectedGenericType, setSelectedGenericType] = useState<TemplateGenericType | null>(null);
  const [isGlobal, setIsGlobal] = useState(true);
  const [country, setCountry] = useState<Country | null>(null);

  let templateGroup = TemplateGroup.Generic;
  if (props.adminTemplate instanceof AdminTemplatePdfDto) {
    templateGroup = TemplateGroup.Pdf;
  } else if (props.adminTemplate instanceof AdminTemplateEmailDto) {
    templateGroup = TemplateGroup.Email;
  }

  const load = async () => {
    const types = await apiTemplateDesign.getTypesByGroup(templateGroup, displayAdminTemplates);
    setTemplateTypes(types);
  };

  useEffect(() => {
    load();
  }, [displayAdminTemplates]);

  useEffect(() => {
    const verifyCanContinue = !!(
      selectedType &&
      (isGlobal || adminAccount) &&
      (templateGroup != TemplateGroup.Generic || selectedGenericType)
    );

    setCanContinue(verifyCanContinue);
  });

  return (
    <>
      <Column md={4}></Column>
      <Column md={4}>
        <Section>
          <Row minHeightIgnore={true}>
            <InputSwitch
              value={displayAdminTemplates}
              label="Mostrar plantillas de Admin"
              onChange={(value) => {
                setSelectedType(null);
                setCountry(null);
                setDisplayAdminTemplates(value ?? false);
              }}
            />
          </Row>
          <Row>
            <InputSelectEnum
              label="Tipo de plantilla"
              value={selectedType}
              enumTypeName={enumName.TemplateType}
              optionValues={templateTypes}
              onChange={setSelectedType}
            />
          </Row>
          {templateGroup == TemplateGroup.Generic && (
            <Row>
              <InputSelectEnum
                label="Tipo de contenido"
                value={selectedGenericType}
                enumTypeName={enumName.TemplateGenericType}
                onChange={setSelectedGenericType}
              />
            </Row>
          )}
          <AdminTemplateSelectAccount
            adminAccount={adminAccount}
            country={country}
            isAdmin={displayAdminTemplates}
            isGlobal={isGlobal}
            onChangeCountry={setCountry}
            onChangeIsGlobal={setIsGlobal}
            onChangeAdminAccount={setAdminAccount}
          />

          <Row align={TextAlign.center}>
            <Button
              text="Continuar"
              onClick={() => {
                const template = {
                  ...props.adminTemplate,
                  type: selectedType,
                  isGlobal: isGlobal,
                  country: country,
                  adminAccount: adminAccount,
                  adminAccountId: adminAccount?.id,
                } as AdminTemplateDto;

                if (selectedGenericType) {
                  (template as AdminTemplateGenericDto).genericType = selectedGenericType;
                }

                props.onContinue(template);
              }}
              disabled={!canContinue}
            />
          </Row>
        </Section>
      </Column>
    </>
  );
};

export default AdminTemplateCreateFirstStep;
