import { useEffect, useState } from "react";
import { AppNotificationDto } from "../../api/app/dtos/AppNotificationDto";
import { useApiAppNotifications } from "../../api/shared/hooks/useApiApp";
import { useAppTheme } from "../../AppThemeProvider";
import { AppSize } from "../../shared/AppSize";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import Icon, { TypeIcon } from "../../shared/components/Icon";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import ScrollableArea from "../../shared/components/Layout/ScrollableArea";
import Spinner from "../../shared/components/Spinner";
import { useClickOutside } from "../../shared/hooks/useClickOutside";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import { useModal } from "../../shared/hooks/useModal";
import { TextAlign } from "../../shared/TextAlign";
import { AppThemeColor } from "../../styles/color";
import AppNotificationCreatePopup from "./AppNotificationCreatePopup";
import AppNotificationHeaderListItem from "./AppNotificationHeaderList.Item";

const AppNotificationHeaderList = (props: {
  onClose: () => void;
  onItemSetAsRead: () => void;
  onNewItem: () => void;
}) => {
  const [notifications, setNotifications] = useState<AppNotificationDto[]>();
  const [apiAppNotifications] = useApiAppNotifications();
  const [isClosing, setIsClosing] = useState(false);
  const [flagRead, setFlagRead] = useState(false);
  const [modal] = useModal();
  const { loginInfo } = useContextLoginInfo();
  const appTheme = useAppTheme();

  const close = () => {
    setIsClosing(true);
    setTimeout(() => {
      props.onClose();
    }, 500);
  };

  const outerClickRef = useClickOutside(close) as any;

  const load = async () => {
    const result = await apiAppNotifications.getPending(20, {
      preventNotifications: true,
      preventSpinner: true,
    });
    setNotifications(result);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div
      ref={outerClickRef}
      className={"layout-app-notifications " + (!isClosing ? "expanded" : "")}
      style={{ paddingTop: 45, backgroundColor: appTheme.toHexColor(AppThemeColor.primaryLight) }}
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <Container>
        {!notifications && (
          <Row align={TextAlign.center}>
            <Spinner />
          </Row>
        )}
        {notifications?.length == 0 && (
          <>
            <Row align={TextAlign.center} fontColor={AppThemeColor.white} minHeightIgnore={true}>
              <Icon type={TypeIcon.checkBold} size={AppSize.bigger} />
            </Row>
            <Row align={TextAlign.center} fontColor={AppThemeColor.white} fontSize="2em">
              No hay nuevas notificaciones
            </Row>
          </>
        )}
        {notifications && notifications.length > 0 && (
          <>
            <Row minHeightIgnore={true}>
              {notifications && notifications.length > 0 && (
                <ScrollableArea maxHeight={420}>
                  {notifications?.map((notification: AppNotificationDto) => (
                    <AppNotificationHeaderListItem
                      key={notification.id}
                      appNotification={notification}
                      isSuperAdmin={loginInfo.authInfo.isSuperAdmin}
                      onSetAsRead={() => {
                        setFlagRead(true);
                        props.onItemSetAsRead();
                      }}
                    />
                  ))}
                </ScrollableArea>
              )}
            </Row>

            {flagRead && (
              <Row align={TextAlign.center} minHeightIgnore={true}>
                <ButtonSecondary
                  color={AppThemeColor.secondary}
                  icon={TypeIcon.refresh}
                  text="Recargar"
                  onClick={load}
                />
              </Row>
            )}

            {loginInfo.authInfo.isSuperAdmin && (
              <Row align={TextAlign.center} minHeightIgnore={true}>
                <ButtonSecondary
                  color={AppThemeColor.secondary}
                  icon={TypeIcon.bell}
                  text="Nueva notificación"
                  onClick={() => {
                    close();
                    modal.open(<AppNotificationCreatePopup />, () => {
                      props.onNewItem();
                    });
                  }}
                />
              </Row>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default AppNotificationHeaderList;
