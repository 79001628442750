// File generated by: Gesta.Build.PostEvents
import { useState } from "react";
import { useApi } from "../../../shared/hooks/useApi";
import { ApiAdminCertifiedProviderReport } from "../../admin/apis/ApiAdminCertifiedProviderReport";
import { ApiAdminCurrencyExchangeRate } from "../../admin/apis/ApiAdminCurrencyExchangeRate";
import { ApiAdminDevUtils } from "../../admin/apis/ApiAdminDevUtils";
import { ApiAdminEmailConfigAdminProvider } from "../../admin/apis/ApiAdminEmailConfigAdminProvider";
import { ApiAdminLoggingConfig } from "../../admin/apis/ApiAdminLoggingConfig";
import { ApiAdminLoggingViewer } from "../../admin/apis/ApiAdminLoggingViewer";
import { ApiAdminLongProcess } from "../../admin/apis/ApiAdminLongProcess";
import { ApiAdminLongProcessLog } from "../../admin/apis/ApiAdminLongProcessLog";
import { ApiAdminPointOfSaleClient } from "../../admin/apis/ApiAdminPointOfSaleClient";
import { ApiAdminProvider } from "../../admin/apis/ApiAdminProvider";
import { ApiAdminRetention } from "../../admin/apis/ApiAdminRetention";
import { ApiAdminServiceBus } from "../../admin/apis/ApiAdminServiceBus";
import { ApiAdminShard } from "../../admin/apis/ApiAdminShard";
import { ApiAdminSwitchPointOfSale } from "../../admin/apis/ApiAdminSwitchPointOfSale";
import { ApiAdminTax } from "../../admin/apis/ApiAdminTax";
import { ApiAdminTaxValue } from "../../admin/apis/ApiAdminTaxValue";
import { ApiAdminUserLinkedAdminProvider } from "../../admin/apis/ApiAdminUserLinkedAdminProvider";
import { ApiAdminPublicEmailContact } from "../../admin/apis/ApiAdminPublicEmailContact";
import { ApiAdminEInvoiceUruguay } from "../../admin/apis/ApiAdminEInvoiceUruguay";
import { ApiAdminAccountPublicAdminRetention } from "../../admin/apis/ApiAdminAccountPublicAdminRetention";
import { ApiAdminAccountPublicEmailContactSales } from "../../admin/apis/ApiAdminAccountPublicEmailContactSales";
import { ApiAdminAccountPublicEmailContactSupport } from "../../admin/apis/ApiAdminAccountPublicEmailContactSupport";
import { ApiAdminAccountPublicTemplateDesign } from "../../admin/apis/ApiAdminAccountPublicTemplateDesign";
import { ApiAdminAccountPublicTemplateEmail } from "../../admin/apis/ApiAdminAccountPublicTemplateEmail";
import { ApiAdminAccountPublicTemplateGeneric } from "../../admin/apis/ApiAdminAccountPublicTemplateGeneric";
import { ApiAdminAccountPublicTemplatePdf } from "../../admin/apis/ApiAdminAccountPublicTemplatePdf";

export function useApiAdminCertifiedProviderReport() {
  return useState(new ApiAdminCertifiedProviderReport(useApi()));
}

export function useApiAdminCurrencyExchangeRate() {
  return useState(new ApiAdminCurrencyExchangeRate(useApi()));
}

export function useApiAdminDevUtils() {
  return useState(new ApiAdminDevUtils(useApi()));
}

export function useApiAdminEmailConfigAdminProvider() {
  return useState(new ApiAdminEmailConfigAdminProvider(useApi()));
}

export function useApiAdminLoggingConfig() {
  return useState(new ApiAdminLoggingConfig(useApi()));
}

export function useApiAdminLoggingViewer() {
  return useState(new ApiAdminLoggingViewer(useApi()));
}

export function useApiAdminLongProcess() {
  return useState(new ApiAdminLongProcess(useApi()));
}

export function useApiAdminLongProcessLog() {
  return useState(new ApiAdminLongProcessLog(useApi()));
}

export function useApiAdminPointOfSaleClient() {
  return useState(new ApiAdminPointOfSaleClient(useApi()));
}

export function useApiAdminProvider() {
  return useState(new ApiAdminProvider(useApi()));
}

export function useApiAdminRetention() {
  return useState(new ApiAdminRetention(useApi()));
}

export function useApiAdminServiceBus() {
  return useState(new ApiAdminServiceBus(useApi()));
}

export function useApiAdminShard() {
  return useState(new ApiAdminShard(useApi()));
}

export function useApiAdminSwitchPointOfSale() {
  return useState(new ApiAdminSwitchPointOfSale(useApi()));
}

export function useApiAdminTax() {
  return useState(new ApiAdminTax(useApi()));
}

export function useApiAdminTaxValue() {
  return useState(new ApiAdminTaxValue(useApi()));
}

export function useApiAdminUserLinkedAdminProvider() {
  return useState(new ApiAdminUserLinkedAdminProvider(useApi()));
}

export function useApiAdminPublicEmailContact() {
  return useState(new ApiAdminPublicEmailContact(useApi()));
}

export function useApiAdminEInvoiceUruguay() {
  return useState(new ApiAdminEInvoiceUruguay(useApi()));
}

export function useApiAdminAccountPublicAdminRetention() {
  return useState(new ApiAdminAccountPublicAdminRetention(useApi()));
}

export function useApiAdminAccountPublicEmailContactSales() {
  return useState(new ApiAdminAccountPublicEmailContactSales(useApi()));
}

export function useApiAdminAccountPublicEmailContactSupport() {
  return useState(new ApiAdminAccountPublicEmailContactSupport(useApi()));
}

export function useApiAdminAccountPublicTemplateDesign() {
  return useState(new ApiAdminAccountPublicTemplateDesign(useApi()));
}

export function useApiAdminAccountPublicTemplateEmail() {
  return useState(new ApiAdminAccountPublicTemplateEmail(useApi()));
}

export function useApiAdminAccountPublicTemplateGeneric() {
  return useState(new ApiAdminAccountPublicTemplateGeneric(useApi()));
}

export function useApiAdminAccountPublicTemplatePdf() {
  return useState(new ApiAdminAccountPublicTemplatePdf(useApi()));
}

