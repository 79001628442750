// File generated by: Gesta.Build.PostEvents
import { useState } from "react";
import { useApi } from "../../../shared/hooks/useApi";
import { ApiAccountBranch } from "../../app/apis/ApiAccountBranch";
import { ApiAccountConfig } from "../../app/apis/ApiAccountConfig";
import { ApiAccountConfigPreferences } from "../../app/apis/ApiAccountConfigPreferences";
import { ApiAccountUser } from "../../app/apis/ApiAccountUser";
import { ApiAdminAccountAppAccess } from "../../app/apis/ApiAdminAccountAppAccess";
import { ApiAppNotifications } from "../../app/apis/ApiAppNotifications";
import { ApiAuth } from "../../app/apis/ApiAuth";
import { ApiClientLogger } from "../../app/apis/ApiClientLogger";
import { ApiContact } from "../../app/apis/ApiContact";
import { ApiCountry } from "../../app/apis/ApiCountry";
import { ApiCurrency } from "../../app/apis/ApiCurrency";
import { ApiCurrencyExchangeRate } from "../../app/apis/ApiCurrencyExchangeRate";
import { ApiDate } from "../../app/apis/ApiDate";
import { ApiEInvoiceAuthorization } from "../../app/apis/ApiEInvoiceAuthorization";
import { ApiEInvoiceDailyReport } from "../../app/apis/ApiEInvoiceDailyReport";
import { ApiEInvoiceEnvelopePurchase } from "../../app/apis/ApiEInvoiceEnvelopePurchase";
import { ApiEInvoiceEnvelopeSale } from "../../app/apis/ApiEInvoiceEnvelopeSale";
import { ApiEInvoiceEnvelopeSaleToCompany } from "../../app/apis/ApiEInvoiceEnvelopeSaleToCompany";
import { ApiEInvoiceEnvelopeStatusResponsePurchase } from "../../app/apis/ApiEInvoiceEnvelopeStatusResponsePurchase";
import { ApiEInvoiceEnvelopeStatusResponseSale } from "../../app/apis/ApiEInvoiceEnvelopeStatusResponseSale";
import { ApiEInvoiceEnvelopeStatusResponseSaleToCompany } from "../../app/apis/ApiEInvoiceEnvelopeStatusResponseSaleToCompany";
import { ApiEInvoiceProcessLog } from "../../app/apis/ApiEInvoiceProcessLog";
import { ApiEmailConfig } from "../../app/apis/ApiEmailConfig";
import { ApiFileUpload } from "../../app/apis/ApiFileUpload";
import { ApiLocale } from "../../app/apis/ApiLocale";
import { ApiLongProcess } from "../../app/apis/ApiLongProcess";
import { ApiPointOfSale } from "../../app/apis/ApiPointOfSale";
import { ApiProduct } from "../../app/apis/ApiProduct";
import { ApiPurchase } from "../../app/apis/ApiPurchase";
import { ApiRequestedExport } from "../../app/apis/ApiRequestedExport";
import { ApiSale } from "../../app/apis/ApiSale";
import { ApiSaleSenderBehalf } from "../../app/apis/ApiSaleSenderBehalf";
import { ApiSentEmail } from "../../app/apis/ApiSentEmail";
import { ApiServiceBus } from "../../app/apis/ApiServiceBus";
import { ApiState } from "../../app/apis/ApiState";
import { ApiSwitchPointOfSale } from "../../app/apis/ApiSwitchPointOfSale";
import { ApiTax } from "../../app/apis/ApiTax";
import { ApiTemplateConfig } from "../../app/apis/ApiTemplateConfig";
import { ApiTest } from "../../app/apis/ApiTest";
import { ApiUserTermsAndConditions } from "../../app/apis/ApiUserTermsAndConditions";
import { ApiVaultCertificate } from "../../app/apis/ApiVaultCertificate";
import { ApiGridSettings } from "../../app/apis/ApiGridSettings";
import { ApiServiceBusInvoiceDailyReportProcessor } from "../../app/apis/ApiServiceBusInvoiceDailyReportProcessor";
import { ApiServiceBusInvoiceEmailMonitorProcessor } from "../../app/apis/ApiServiceBusInvoiceEmailMonitorProcessor";
import { ApiServiceBusInvoiceProcessor } from "../../app/apis/ApiServiceBusInvoiceProcessor";
import { ApiReportPartialOperation } from "../../app/apis/ApiReportPartialOperation";
import { ApiReportTaxes } from "../../app/apis/ApiReportTaxes";
import { ApiPointOfSaleClient } from "../../app/apis/ApiPointOfSaleClient";
import { ApiContactOpenApi } from "../../app/apis/ApiContactOpenApi";
import { ApiSaleReceiptOpenApi } from "../../app/apis/ApiSaleReceiptOpenApi";
import { ApiUtilOpenApi } from "../../app/apis/ApiUtilOpenApi";
import { ApiAuthOpenApi } from "../../app/apis/ApiAuthOpenApi";
import { ApiJobDailyCleanUp } from "../../app/apis/ApiJobDailyCleanUp";
import { ApiJobCertificateExpirationReminder } from "../../app/apis/ApiJobCertificateExpirationReminder";
import { ApiJobEInvoiceDailyReportProcess } from "../../app/apis/ApiJobEInvoiceDailyReportProcess";
import { ApiJobEInvoiceEmailMonitor } from "../../app/apis/ApiJobEInvoiceEmailMonitor";
import { ApiSendGridWebHook } from "../../app/apis/ApiSendGridWebHook";
import { ApiEInvoiceCompanyInfo } from "../../app/apis/ApiEInvoiceCompanyInfo";
import { ApiEInvoiceUruguay } from "../../app/apis/ApiEInvoiceUruguay";
import { ApiExportContact } from "../../app/apis/ApiExportContact";
import { ApiExportPurchase } from "../../app/apis/ApiExportPurchase";
import { ApiExportSale } from "../../app/apis/ApiExportSale";

export function useApiAccountBranch() {
  return useState(new ApiAccountBranch(useApi()));
}

export function useApiAccountConfig() {
  return useState(new ApiAccountConfig(useApi()));
}

export function useApiAccountConfigPreferences() {
  return useState(new ApiAccountConfigPreferences(useApi()));
}

export function useApiAccountUser() {
  return useState(new ApiAccountUser(useApi()));
}

export function useApiAdminAccountAppAccess() {
  return useState(new ApiAdminAccountAppAccess(useApi()));
}

export function useApiAppNotifications() {
  return useState(new ApiAppNotifications(useApi()));
}

export function useApiAuth() {
  return useState(new ApiAuth(useApi()));
}

export function useApiClientLogger() {
  return useState(new ApiClientLogger(useApi()));
}

export function useApiContact() {
  return useState(new ApiContact(useApi()));
}

export function useApiCountry() {
  return useState(new ApiCountry(useApi()));
}

export function useApiCurrency() {
  return useState(new ApiCurrency(useApi()));
}

export function useApiCurrencyExchangeRate() {
  return useState(new ApiCurrencyExchangeRate(useApi()));
}

export function useApiDate() {
  return useState(new ApiDate(useApi()));
}

export function useApiEInvoiceAuthorization() {
  return useState(new ApiEInvoiceAuthorization(useApi()));
}

export function useApiEInvoiceDailyReport() {
  return useState(new ApiEInvoiceDailyReport(useApi()));
}

export function useApiEInvoiceEnvelopePurchase() {
  return useState(new ApiEInvoiceEnvelopePurchase(useApi()));
}

export function useApiEInvoiceEnvelopeSale() {
  return useState(new ApiEInvoiceEnvelopeSale(useApi()));
}

export function useApiEInvoiceEnvelopeSaleToCompany() {
  return useState(new ApiEInvoiceEnvelopeSaleToCompany(useApi()));
}

export function useApiEInvoiceEnvelopeStatusResponsePurchase() {
  return useState(new ApiEInvoiceEnvelopeStatusResponsePurchase(useApi()));
}

export function useApiEInvoiceEnvelopeStatusResponseSale() {
  return useState(new ApiEInvoiceEnvelopeStatusResponseSale(useApi()));
}

export function useApiEInvoiceEnvelopeStatusResponseSaleToCompany() {
  return useState(new ApiEInvoiceEnvelopeStatusResponseSaleToCompany(useApi()));
}

export function useApiEInvoiceProcessLog() {
  return useState(new ApiEInvoiceProcessLog(useApi()));
}

export function useApiEmailConfig() {
  return useState(new ApiEmailConfig(useApi()));
}

export function useApiFileUpload() {
  return useState(new ApiFileUpload(useApi()));
}

export function useApiLocale() {
  return useState(new ApiLocale(useApi()));
}

export function useApiLongProcess() {
  return useState(new ApiLongProcess(useApi()));
}

export function useApiPointOfSale() {
  return useState(new ApiPointOfSale(useApi()));
}

export function useApiProduct() {
  return useState(new ApiProduct(useApi()));
}

export function useApiPurchase() {
  return useState(new ApiPurchase(useApi()));
}

export function useApiRequestedExport() {
  return useState(new ApiRequestedExport(useApi()));
}

export function useApiSale() {
  return useState(new ApiSale(useApi()));
}

export function useApiSaleSenderBehalf() {
  return useState(new ApiSaleSenderBehalf(useApi()));
}

export function useApiSentEmail() {
  return useState(new ApiSentEmail(useApi()));
}

export function useApiServiceBus() {
  return useState(new ApiServiceBus(useApi()));
}

export function useApiState() {
  return useState(new ApiState(useApi()));
}

export function useApiSwitchPointOfSale() {
  return useState(new ApiSwitchPointOfSale(useApi()));
}

export function useApiTax() {
  return useState(new ApiTax(useApi()));
}

export function useApiTemplateConfig() {
  return useState(new ApiTemplateConfig(useApi()));
}

export function useApiTest() {
  return useState(new ApiTest(useApi()));
}

export function useApiUserTermsAndConditions() {
  return useState(new ApiUserTermsAndConditions(useApi()));
}

export function useApiVaultCertificate() {
  return useState(new ApiVaultCertificate(useApi()));
}

export function useApiGridSettings() {
  return useState(new ApiGridSettings(useApi()));
}

export function useApiServiceBusInvoiceDailyReportProcessor() {
  return useState(new ApiServiceBusInvoiceDailyReportProcessor(useApi()));
}

export function useApiServiceBusInvoiceEmailMonitorProcessor() {
  return useState(new ApiServiceBusInvoiceEmailMonitorProcessor(useApi()));
}

export function useApiServiceBusInvoiceProcessor() {
  return useState(new ApiServiceBusInvoiceProcessor(useApi()));
}

export function useApiReportPartialOperation() {
  return useState(new ApiReportPartialOperation(useApi()));
}

export function useApiReportTaxes() {
  return useState(new ApiReportTaxes(useApi()));
}

export function useApiPointOfSaleClient() {
  return useState(new ApiPointOfSaleClient(useApi()));
}

export function useApiContactOpenApi() {
  return useState(new ApiContactOpenApi(useApi()));
}

export function useApiSaleReceiptOpenApi() {
  return useState(new ApiSaleReceiptOpenApi(useApi()));
}

export function useApiUtilOpenApi() {
  return useState(new ApiUtilOpenApi(useApi()));
}

export function useApiAuthOpenApi() {
  return useState(new ApiAuthOpenApi(useApi()));
}

export function useApiJobDailyCleanUp() {
  return useState(new ApiJobDailyCleanUp(useApi()));
}

export function useApiJobCertificateExpirationReminder() {
  return useState(new ApiJobCertificateExpirationReminder(useApi()));
}

export function useApiJobEInvoiceDailyReportProcess() {
  return useState(new ApiJobEInvoiceDailyReportProcess(useApi()));
}

export function useApiJobEInvoiceEmailMonitor() {
  return useState(new ApiJobEInvoiceEmailMonitor(useApi()));
}

export function useApiSendGridWebHook() {
  return useState(new ApiSendGridWebHook(useApi()));
}

export function useApiEInvoiceCompanyInfo() {
  return useState(new ApiEInvoiceCompanyInfo(useApi()));
}

export function useApiEInvoiceUruguay() {
  return useState(new ApiEInvoiceUruguay(useApi()));
}

export function useApiExportContact() {
  return useState(new ApiExportContact(useApi()));
}

export function useApiExportPurchase() {
  return useState(new ApiExportPurchase(useApi()));
}

export function useApiExportSale() {
  return useState(new ApiExportSale(useApi()));
}

