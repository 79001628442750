import { ErrorResultDto } from "../dtos/ErrorResultDto";
import { IAuth } from "../interfaces/IAuth";
import { IEnvironment } from "../interfaces/IEnvironment";
import { ISpinner } from "../interfaces/ISpinner";
import { ApiOptions } from "./ApiOptions";

export class ApiError extends Error {}
export class ApiErrorConnection extends ApiError {}
export class ApiErrorNotFound extends ApiError {}
export class ApiErrorUserNotAuthenticated extends ApiError {}
export class ApiErrorUserNotAuthorized extends ApiError {}
export class ApiErrorServerInternal extends ApiError {}

export class ApiService {
  constructor(
    private _environment: IEnvironment,
    public auth: IAuth,
    private _notifyError: (errorResultDto: ErrorResultDto) => void,
    private _spinner: ISpinner
  ) {}

  private contentTypeIsJson(contentType: string): boolean {
    return contentType.startsWith("application/json");
  }

  private contentTypeIsProblemJson(contentType: string): boolean {
    return contentType.startsWith("application/problem+json");
  }

  private contentTypeIsTextPlain(contentType: string): boolean {
    return !contentType || contentType.startsWith("text/plain");
  }

  private handleConnectionError(error: any, spinnerId: number | null, options?: ApiOptions) {
    if (spinnerId) {
      this._spinner.hide(spinnerId);
    }

    const errorResultDto = new ErrorResultDto();
    errorResultDto.message =
      "Error al comunicarse con el servidor: " + this._environment.API_BASE_URL;

    if (!options?.preventNotifications) {
      this._notifyError(errorResultDto);
    }

    throw new ApiErrorConnection(errorResultDto.message);
  }

  private async apiFetch<T>(
    method: string,
    url: string,
    body?: any,
    options?: ApiOptions
  ): Promise<T> {
    const apiUrl = url.startsWith("http")
      ? new URL(url)
      : new URL(this._environment.API_BASE_URL + url);

    if (options?.params) {
      apiUrl.search = new URLSearchParams(options.params).toString();
    }

    if (body) {
      body = JSON.stringify(body);
    }

    let spinnerId: number | null = null;
    if (!options?.preventSpinner) {
      spinnerId = this._spinner.show(url);
    }

    const response = await fetch(apiUrl.toString(), {
      headers: new Headers({
        authorization: `Bearer ${this.auth.authToken}`,
        accept: "application/json",
        "content-type": "application/json",
      }),
      method: method,
      body: body,
    }).catch((error) => {
      this.handleConnectionError(error, spinnerId, options);
      return;
    });

    if (spinnerId) {
      this._spinner.hide(spinnerId);
    }

    const responseContentType = response?.headers?.get("content-type") ?? "";
    if (this.contentTypeIsJson(responseContentType)) {
      return await this.toJson<T>(response, spinnerId, responseContentType, options);
    }

    if (this.contentTypeIsProblemJson(responseContentType)) {
      return await this.toJson<T>(response, spinnerId, responseContentType, options);
    }

    if (this.contentTypeIsTextPlain(responseContentType)) {
      return await this.toJson<T>(response, spinnerId, responseContentType, options);
    }

    const blob = await response?.blob();
    const saveData = (function () {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      return function (data: any, fileName: string) {
        if (!blob) {
          return;
        }

        const blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(blobUrl);
      };
    })();

    const fileName = response?.headers
      ?.get("Content-Disposition")
      ?.split(";")?.[1]
      ?.split("=")?.[1];
    saveData(blob, fileName ?? "Gesta");
    return {} as T;
  }

  private async toJson<T>(
    response: any,
    spinnerId: number | null,
    contentType: string,
    options?: ApiOptions
  ): Promise<T> {
    response = await this.handleErrors(response, spinnerId, contentType, options);
    if (response.status === 204) {
      return null as any;
    }
    if (response.headers.get("content-length") === "0") {
      return {} as T;
    }
    return (await response.json()) as T;
  }

  private async handleErrors(
    response: any,
    spinnerId: number | null,
    contentType: string,
    options?: ApiOptions
  ): Promise<any> {
    let errorResultDto = new ErrorResultDto();
    let error = null;

    if (response.status === 400) {
      if (this.contentTypeIsProblemJson(contentType)) {
        errorResultDto.message = "Error de validación en el servidor";
        error = new ApiError("Error de validación en el servidor");
      } else {
        errorResultDto = JSON.parse(await response.text()) as ErrorResultDto;
        error = new ApiError(errorResultDto.message);
      }
    } else if (response.status === 401) {
      errorResultDto.message = "Usuario no autenticado";
      error = new ApiErrorUserNotAuthenticated(errorResultDto.message);
    } else if (response.status === 403) {
      errorResultDto.message = "Usuario no autorizado";
      error = new ApiErrorUserNotAuthorized(errorResultDto.message);
    } else if (response.status === 404) {
      errorResultDto.message = "No se encontró la acción en el servidor";
      error = new ApiErrorNotFound(errorResultDto.message);
    } else if (response.status >= 500 && response.status < 600) {
      errorResultDto = JSON.parse(await response.text()) as ErrorResultDto;
      error = new ApiErrorServerInternal(errorResultDto.message);
    } else if (!response.ok) {
      error = new Error("Error en la consulta: " + response.statusText);
    }

    if (error) {
      if (spinnerId) {
        this._spinner.hide(spinnerId);
      }

      if (!options?.preventNotifications && errorResultDto.message) {
        this._notifyError(errorResultDto);
      }

      throw error;
    }

    return response;
  }

  async apiDelete<T>(url: string, options?: ApiOptions): Promise<T> {
    return this.apiFetch("DELETE", url, null, options);
  }

  async apiGet<T>(url: string, options?: ApiOptions): Promise<T> {
    return this.apiFetch("GET", url, null, options);
  }

  async apiPost<T>(url: string, body: any, options?: ApiOptions): Promise<T> {
    return await this.apiFetch<T>("POST", url, body, options);
  }

  async apiPut<T>(url: string, body: any, options?: ApiOptions): Promise<T> {
    return await this.apiFetch<T>("PUT", url, body, options);
  }
}
