import { SearchOrderDto } from "../../../api/shared/dtos/SearchOrderDto";
import { IdGuidName } from "../../IdGuidName";
import { IdName } from "../../IdName";
import { ThemeVariant } from "../../ThemeVariant";
import InputSelectEnumBase from "../inputs/select/InputSelectEnumBase";
import InputSelectGuid from "../inputs/select/InputSelectGuid";
import Column from "../Layout/GColumn";
import { IGridItemTemplate } from "./ItemTemplate/IGridItemTemplate";
import { useContextGrid } from "./useContextGrid";

const GridMobileSortBy = () => {
  const grid = useContextGrid();

  const columnsToSortBy = grid.gridState.visibleColumns.filter((c: IGridItemTemplate<any>) => {
    return !c.orderByPrevent && !c.actionType;
  });

  const sortableColumns = columnsToSortBy.map(
    (c) => new IdGuidName(c.orderBy ?? c.field, c.header)
  );

  const orderBy =
    grid.gridState.searchDto.orderBy.length > 0
      ? grid.gridState.searchDto.orderBy[0]
      : new SearchOrderDto();

  return (
    <>
      <Column xs={8}>
        <InputSelectGuid
          label="Ordenar por"
          value={orderBy.columnName}
          options={sortableColumns}
          removeEmpty={true}
          variant={ThemeVariant.standard}
          onChange={(value) => grid.orderByChange(value, false)}
        />
      </Column>
      <Column xs={4}>
        <InputSelectEnumBase
          label="Orden"
          value={orderBy.asc ? 0 : 1}
          removeEmpty={true}
          options={[new IdName(0, "Ascendente"), new IdName(1, "Descendente")]}
          variant={ThemeVariant.standard}
          onChange={(value) => grid.orderByToggleAsc(orderBy.columnName, value == 0)}
        />
      </Column>
    </>
  );
};

export default GridMobileSortBy;
