import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import { ReactElement, useState } from "react";
import Icon from "../Icon";
import Row from "./GRow";

export class TabButton {
  title?: string;
  icon?: any;
  value: any;
  hide?: boolean;
}

export interface PropsTabs {
  tabs: TabButton[];
  children: ReactElement[];
  selectedTab: string;
  onTabChange?(value: string): void;
}

const TabGroup = (props: PropsTabs) => {
  const [selectedTab, setSelectedTab] = useState(props.selectedTab ? props.selectedTab : "");

  return (
    <Row>
      <TabContext value={selectedTab}>
        <TabList
          onChange={(_e: any, value: any) => {
            setSelectedTab(value.toString());
            if (props.onTabChange) {
              props.onTabChange(value);
            }
          }}>
          {props.tabs
            .filter((c) => !c.hide)
            .map((t, index) => (
              <Tab
                iconPosition="start"
                key={index}
                label={t.title}
                icon={
                  t.icon && (
                    <Icon
                      type={t.icon}
                      style={{ marginTop: 0, marginBottom: 0, marginRight: 10 }}
                    />
                  )
                }
                value={t.value.toString()}
              />
            ))}
        </TabList>
        {props.children}
      </TabContext>
    </Row>
  );
};

export default TabGroup;
