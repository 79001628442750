// File generated by Gesta.Build.PostEvents
import { AdminAccountSimpleDto } from "../../shared/dtos/AdminAccountSimpleDto";
import { AdminAccountCompanyDto } from "./AdminAccountCompanyDto";
import { AdminAccountContactDto } from "./AdminAccountContactDto";
import { Country } from "../../shared/enums/Country";
import { AppLocale } from "../../shared/enums/AppLocale";
import { AdminProviderSimpleDto } from "./AdminProviderSimpleDto";
import { AdminShardDto } from "./AdminShardDto";

export class AdminAccountDto extends AdminAccountSimpleDto {
  activeBranchesCount: number = 0;
  company: AdminAccountCompanyDto;
  contact: AdminAccountContactDto;
  country: Country;
  eInvoiceIsActive: boolean = false;
  eInvoiceStartDate: Date | null = null;
  eInvoiceEndDate: Date | null = null;
  logoUrl: string = "";
  locale: AppLocale;
  provider: AdminProviderSimpleDto;
  shardId: string;
  shard: AdminShardDto;
  shardName: string = "";
  timeZoneId: string = "";
}
