import { useState } from "react";
import { LoginRequestDto } from "../../api/app/dtos/LoginRequestDto";
import { AppRoute } from "../../AppRoutes";
import { useApiAuth } from "../../shared/hooks/useApiAuth";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import { useIsMobile } from "../../shared/hooks/useIsMobile";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import LoginGoogleButton from "./GoogleLoginButton";
import LoginCard from "./Layout/LoginCard";
import { useLoginLayout } from "./Layout/LoginLayoutContext";
import LoginLink from "./Layout/LoginLink";
import LoginRow from "./Layout/LoginRow";
import LoginRowError from "./Layout/LoginRowError";
import LoginTitle from "./Layout/LoginTitle";
import SignInForm from "./SignIn.Form";
import { useLoginSignInRequestValidation } from "./Validations/useLoginSigInValidation";

const SignIn = () => {
  const isMobile = useIsMobile();
  const contextLoginInfo = useContextLoginInfo();
  const loginLayout = useLoginLayout();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [apiAuth] = useApiAuth();
  const [loginRequest] = useState(new LoginRequestDto());

  const login = async (loginRequestDto: LoginRequestDto) => {
    setIsLoading(true);
    try {
      const loginResponse = await apiAuth.login(loginRequestDto, {
        preventSpinner: true,
        preventNotifications: true,
      });

      contextLoginInfo.login(loginResponse.authInfo, loginResponse.authToken);
    } catch (error: any) {
      const errorMessage = error.toString().replace("Error:", "");
      setError(errorMessage);
      setIsLoading(false);
    }
  };

  if (!loginRequest) {
    return <></>;
  }

  return (
    <LoginCard isLoading={isLoading}>
      {!isMobile && <LoginTitle>¡Bienvenido!</LoginTitle>}
      {isMobile && <LoginTitle>Iniciar sesión</LoginTitle>}
      {!isMobile && (
        <LoginRow style={{ marginBottom: 30 }}>Gestioná tu empresa desde donde estés.</LoginRow>
      )}
      <LoginRow>{}</LoginRow>
      <LoginRow>
        <LoginGoogleButton
          disabled={isLoading}
          onIsLoading={(value) => {
            setIsLoading(value);
          }}
          onError={(value) => {
            setError(value);
          }}
        />
      </LoginRow>

      <LoginRow>{}</LoginRow>

      <StateProvider
        model={loginRequest}
        onSubmit={(loginDto) => login(loginDto)}
        validationSchema={useLoginSignInRequestValidation()}>
        {(p) => <SignInForm isLoading={isLoading} onSubmit={p.handleSubmit} />}
      </StateProvider>

      <LoginRow>{}</LoginRow>

      <LoginRowError message={error} />

      <LoginRow fontSize={12} isBottom={true}>
        <div>
          ¿Aún no tenés cuenta?
          <LoginLink
            text="Registrarme"
            onClick={() => {
              loginLayout.navigateTo(AppRoute.login.register.base);
            }}
          />
        </div>
      </LoginRow>
    </LoginCard>
  );
};

export default SignIn;
