// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AccountBranchDto } from "../dtos/AccountBranchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { SuggestResponseDto } from "../../shared/dtos/SuggestResponseDto";
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { SuggestSearchDto } from "../../shared/dtos/SuggestSearchDto";

export class ApiAccountBranch {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AccountBranchDto> {
    return this.apiService.apiGet("/account-branch/" + id, apiOptions);
  }

  suggestAll(
    suggestSearch: SuggestSearchDto<FiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/account-branch/suggest", suggestSearch, apiOptions);
  }

  getAllComplete(
    includeInactive: boolean,
    apiOptions?: ApiOptions
  ): Promise<AccountBranchDto[]> {
    return this.apiService.apiGet("/account-branch/all-complete?" + 
      (includeInactive ? "&includeInactive=" + includeInactive : ""), apiOptions);
  }

}
