import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router";
import { AuthSwitchPointOfSaleDto } from "../../api/app/dtos/AuthSwitchPointOfSaleDto";
import { useApiAdminProviderAuth } from "../../api/shared/hooks/useApiAdminProviders";
import { useApiAuth } from "../../api/shared/hooks/useApiApp";
import LayoutHeader from "../../shared/components/Layout/LayoutHeader";
import LayoutMainContainer from "../../shared/components/Layout/LayoutMainContainer";
import LayoutSideMenu from "../../shared/components/Layout/LayoutSideMenu";
import UnauthorizedArea from "../../shared/components/LoginFlow/UnauthorizedArea";
import ModalDialog from "../../shared/components/Modal/ModalDialog";
import NotificationArea from "../../shared/components/Notifications/NotificationArea";
import { AppInfoSiteType } from "../../shared/context/AppInfoProvider";
import { LoginInfoStatus } from "../../shared/context/LoginInfoProvider";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import { useIsMobile } from "../../shared/hooks/useIsMobile";
import { useModal } from "../../shared/hooks/useModal";
import { useNavigation } from "../../shared/hooks/useNavigation";
import AdminProviderMenu from "./AdminProviderMenu";

const AdminProviderLayout = () => {
  const navigation = useNavigation();
  const [apiAuth] = useApiAuth();
  const [apiAdminProviderAuth] = useApiAdminProviderAuth();
  const [redirectUnauthorized, setRedirectUnauthorized] = useState(false);
  const { loginInfo, login } = useContextLoginInfo();
  const isMobile = useIsMobile();
  const { appInfo, setSiteType } = useContextAppInfo();
  const [menuIsExpanded, setMenuIsExpanded] = useState(!isMobile);
  const [modal] = useModal();

  useEffect(() => {
    const request = async () => {
      const providerAccountOwner = await apiAdminProviderAuth.getProviderAccountOwner();
      if (providerAccountOwner.id != loginInfo.authInfo.adminAccount.id) {
        modal.open(
          <ModalDialog
            title="Error"
            text={"¿Cambiar la cuenta actual a " + providerAccountOwner.name + " ?"}
          />,
          () => {
            const request = async () => {
              const authSwitchPointOfSaleDto = new AuthSwitchPointOfSaleDto();
              authSwitchPointOfSaleDto.adminAccountId = providerAccountOwner.id;
              const switchPointOfSaleResponse = await apiAuth.switchPointOfSale(
                authSwitchPointOfSaleDto
              );
              login(switchPointOfSaleResponse.authInfo, switchPointOfSaleResponse.authToken);
            };
            request();
          },
          () => {
            setRedirectUnauthorized(true);
          }
        );
      }
      setSiteType(AppInfoSiteType.AdminProvider);
    };
    request();
  }, []);

  if (loginInfo.status === LoginInfoStatus.LoggedOut) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  if (
    (loginInfo.isLoggedIn && !loginInfo.authInfo.hasAdminProviderAccess) ||
    redirectUnauthorized
  ) {
    return <UnauthorizedArea />;
  }

  if (!appInfo || appInfo.siteType != AppInfoSiteType.AdminProvider) {
    return <></>;
  }

  return (
    <div className="layout">
      <NotificationArea />
      <LayoutHeader
        onToggleMenu={() => setMenuIsExpanded(!menuIsExpanded)}
        sideMenuIsExpanded={menuIsExpanded}
      />
      <div className="layout-body">
        <LayoutSideMenu
          onCollapseRequested={() => setMenuIsExpanded(false)}
          onExpandRequested={() => setMenuIsExpanded(true)}
          expanded={menuIsExpanded}>
          <AdminProviderMenu
            isExpanded={menuIsExpanded}
            onNavigate={(url) => {
              setMenuIsExpanded(false);
              navigation.go(url);
            }}
          />
        </LayoutSideMenu>
        <LayoutMainContainer>
          <Outlet />
        </LayoutMainContainer>
      </div>
    </div>
  );
};

export default AdminProviderLayout;
