// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminProviderEmailConfigDto } from "../dtos/AdminProviderEmailConfigDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminEmailConfigAdminProvider {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getByAdminProviderId(
    adminProviderId: string,
    apiOptions?: ApiOptions
  ): Promise<AdminProviderEmailConfigDto> {
    return this.apiService.apiGet("/admin/email-config-admin-provider/by-admin-provider/" + adminProviderId, apiOptions);
  }

  create(
    adminProviderId: string,
    adminProviderEmailConfigDto: AdminProviderEmailConfigDto,
    apiOptions?: ApiOptions
  ): Promise<AdminProviderEmailConfigDto> {
    return this.apiService.apiPost("/admin/email-config-admin-provider/" + adminProviderId, adminProviderEmailConfigDto, apiOptions);
  }

  edit(
    adminProviderEmailConfigDto: AdminProviderEmailConfigDto,
    apiOptions?: ApiOptions
  ): Promise<AdminProviderEmailConfigDto> {
    return this.apiService.apiPut("/admin/email-config-admin-provider", adminProviderEmailConfigDto, apiOptions);
  }

}
