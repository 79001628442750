import { useEffect, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { LogEventDto } from "../../api/admin/dtos/LogEventDto";
import { LogEventFiltersDto } from "../../api/admin/dtos/LogEventFiltersDto";
import { FilterRangeDateDto } from "../../api/shared/dtos/FilterRangeDateDto";
import { FilterDateRangeType } from "../../api/shared/enums/FilterDateRangeType";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiAdminLoggingViewer } from "../../api/shared/hooks/useApiAdmin";
import {
  useApiAdminProviderAdminAccount,
  useApiAdminProviderUser,
} from "../../api/shared/hooks/useApiAdminProviders";
import { AppRoute } from "../../AppRoutes";
import { AppSize } from "../../shared/AppSize";
import Button from "../../shared/components/Button/Button";
import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import Grid, { GridColumnAlign } from "../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import { IGridContext } from "../../shared/components/grid/useContextGrid";
import { TypeIcon } from "../../shared/components/Icon";
import InputSelectBase from "../../shared/components/inputs/select/InputSelectBase";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Page from "../../shared/components/Layout/Page";
import Tooltip from "../../shared/components/Tooltip";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { usePathQueryString } from "../../shared/hooks/usePathQueryString";
import { IdGuidName } from "../../shared/IdGuidName";
import { enumColorLoggingLevel } from "../../shared/utils/utilEnumColors";

export type AdminLoggingListQueryParams = {
  date: string;
  traceId: string;
};

const AdminLogViewer = () => {
  const pathQueryString = usePathQueryString();
  const navigation = useNavigation();
  const [apiAdminLoggingViewer] = useApiAdminLoggingViewer();
  const [apiAdminUser] = useApiAdminProviderUser();
  const [apiAdminProviderAdminAccount] = useApiAdminProviderAdminAccount();
  const [tableName, setTableName] = useState<string>("...");
  const [impersonatedEnvironment, setImpersonatedEnvironment] = useState<string>("");

  const [logEventFilters, setLogEventFilters] = useState<LogEventFiltersDto>(() => {
    const filter = new LogEventFiltersDto();
    filter.traceId = pathQueryString.get("traceId") ?? "";
    filter.date = new FilterRangeDateDto();
    filter.date.type = FilterDateRangeType.Today;
    return filter;
  });

  const templates: IGridItemTemplate<LogEventDto>[] = [
    {
      header: "Fecha",
      field: nameof<LogEventDto>((p) => p.date),
      filter: {
        propertyName: nameof<LogEventFiltersDto>((p) => p.date),
        type: GridFilterType.dateTime,
      },
      orderByPrevent: true,
      cellType: GridCellType.dateTimeWithSeconds,
      width: 10,
    },
    {
      header: "Level",
      field: nameof<LogEventDto>((p) => p.level),
      filter: {
        propertyName: nameof<LogEventFiltersDto>((p) => p.levelList),
        type: GridFilterType.enumList,
        enumName: enumName.LoggingLevel,
      },
      orderByPrevent: true,
      cellType: GridCellType.statusWithColor,
      cellTypeEnumName: enumName.LoggingLevel,
      cellTypeMapColor: enumColorLoggingLevel,
      width: 5,
    },
    {
      header: "Categoría",
      field: nameof<LogEventDto>((p) => p.categoryFullName),
      filter: {
        propertyName: nameof<LogEventFiltersDto>((p) => p.categoryFullName),
        type: GridFilterType.text,
      },
      orderByPrevent: true,
      width: 10,
      render: (item: LogEventDto, gridContext: IGridContext<LogEventDto>) => {
        const parts = item.categoryFullName ? item.categoryFullName.split(".") : [""];
        return (
          <Tooltip text={item.categoryFullName}>
            <a
              style={{ cursor: "pointer" }}
              onClick={() =>
                gridContext.updateFiltersProperty(
                  nameof<LogEventFiltersDto>((p) => p.categoryFullName),
                  item.categoryFullName
                )
              }>
              {parts[parts.length - 1]}
            </a>
          </Tooltip>
        );
      },
    },
    {
      header: "Mensaje",
      field: nameof<LogEventDto>((p) => p.message),
      orderByPrevent: true,
      width: 50,
      maxHeightIgnore: true,
      cellStyle: { whiteSpace: "pre-wrap" },
      render: (item: LogEventDto) => {
        return <div dangerouslySetInnerHTML={{ __html: item.message }} />;
      },
    },
    {
      header: "Path",
      field: nameof<LogEventDto>((p) => p.requestPath),
      filter: {
        propertyName: nameof<LogEventFiltersDto>((p) => p.requestPath),
        type: GridFilterType.text,
      },
      width: 10,
      orderByPrevent: true,
    },
    {
      header: "Cuenta",
      field: nameof<LogEventDto>((p) => p.accountId),
      filter: {
        propertyName: nameof<LogEventFiltersDto>((p) => p.account),
        type: GridFilterType.account,
      },
      align: GridColumnAlign.center,
      width: 5,
      orderByPrevent: true,
      render: (item: LogEventDto, gridContext: IGridContext<LogEventDto>) => {
        return (
          <>
            {item.accountId && (
              <ButtonIcon
                icon={TypeIcon.adminAccount}
                size={AppSize.small}
                tooltip={item.accountId}
                onClick={() => {
                  const request = async () => {
                    const account = await apiAdminProviderAdminAccount.getById(item.accountId);
                    gridContext.updateFiltersProperties([
                      {
                        propertyName: nameof<LogEventFiltersDto>((p) => p.account),
                        value: account,
                      },
                      {
                        propertyName: nameof<LogEventFiltersDto>((p) => p.accountId),
                        value: item.accountId,
                      },
                    ]);
                  };
                  request();
                }}
              />
            )}
          </>
        );
      },
    },
    {
      header: "Usuario",
      field: nameof<LogEventDto>((p) => p.userId),
      filter: {
        propertyName: nameof<LogEventFiltersDto>((p) => p.user),
        type: GridFilterType.accountUser,
      },
      width: 5,
      align: GridColumnAlign.center,
      orderByPrevent: true,
      render: (item: LogEventDto, gridContext: IGridContext<LogEventDto>) => {
        return (
          <>
            {item.userId && (
              <ButtonIcon
                icon={TypeIcon.user}
                size={AppSize.small}
                tooltip={item.userId}
                onClick={() => {
                  const request = async () => {
                    const user = await apiAdminUser.getById(item.userId);
                    gridContext.updateFiltersProperties([
                      {
                        propertyName: nameof<LogEventFiltersDto>((p) => p.user),
                        value: user,
                      },
                      {
                        propertyName: nameof<LogEventFiltersDto>((p) => p.userId),
                        value: item.userId,
                      },
                    ]);
                  };
                  request();
                }}
              />
            )}
          </>
        );
      },
    },
    {
      header: "TraceID",
      field: nameof<LogEventDto>((p) => p.traceId),
      filter: {
        propertyName: nameof<LogEventFiltersDto>((p) => p.traceId),
        type: GridFilterType.text,
      },
      width: 5,
      align: GridColumnAlign.center,
      orderByPrevent: true,
      render: (item: LogEventDto, gridContext: IGridContext<LogEventDto>) => {
        return (
          <>
            {item.traceId && (
              <ButtonIcon
                icon={TypeIcon.server}
                size={AppSize.small}
                tooltip={item.traceId}
                onClick={() =>
                  gridContext.updateFiltersProperty(
                    nameof<LogEventFiltersDto>((p) => p.traceId),
                    item.traceId
                  )
                }
              />
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const load = async () => {
      const table = await apiAdminLoggingViewer.getTableName("");
      setTableName(table);
      setImpersonatedEnvironment(table);
    };
    load();
  }, []);

  if (!tableName) {
    return <></>;
  }

  const envOptions = [
    new IdGuidName("Production", "Production"),
    new IdGuidName("Staging", "Staging"),
  ];

  if (tableName !== "Production" && tableName !== "Staging") {
    envOptions.push(new IdGuidName(tableName, tableName));
  }

  return (
    <Page
      title={"Visor de log"}
      titleButton={
        <ComponentGroup>
          <InputSelectBase
            label="Entorno"
            removeEmpty={true}
            options={envOptions}
            value={impersonatedEnvironment}
            onChange={(e) => {
              setImpersonatedEnvironment(e.target.value);
              setLogEventFilters({ ...logEventFilters, impersonatedEnvironment: e.target.value });
            }}
          />
          <Button
            text="Configuración de logs"
            onClick={() => navigation.newTab(AppRoute.admin.logging)}
          />
        </ComponentGroup>
      }>
      <Grid
        initialFilters={logEventFilters}
        itemTemplates={templates}
        pageSize={40}
        hideIncludeInactiveButton={true}
        onSearch={(search, options) => apiAdminLoggingViewer.getPaged(search, options)}
        onExpandRow={async (item: LogEventDto) => {
          const event = await apiAdminLoggingViewer.getEventComplete(
            item.partitionKey,
            item.rowKey,
            impersonatedEnvironment != tableName ? impersonatedEnvironment : ""
          );
          if (!event) {
            <>No se encontró el evento en el servidor</>;
          }
          return (
            <>
              <div dangerouslySetInnerHTML={{ __html: event.exception }}></div>
            </>
          );
        }}
        onExpandRowShouldDisableButton={(item: LogEventDto) => !item.hasExceptionDetails}
      />
    </Page>
  );
};

export default AdminLogViewer;
