import { nameof } from "ts-simple-nameof";
import { AdminProviderDto } from "../../../api/admin/dtos/AdminProviderDto";
import { AdminProviderSecureSettingsDto } from "../../../api/admin/dtos/AdminProviderSecureSettingsDto";
import { AdminProviderWithSecureInfoDto } from "../../../api/admin/dtos/AdminProviderWithSecureInfoDto";
import { EmailProviderType } from "../../../api/shared/enums/EmailProviderType";
import { validate } from "../../../shared/validation/schema/ValidationField";
import { validateSchema } from "../../../shared/validation/schema/ValidationFieldSchema";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

function useProviderSecureSettingsValidator() {
  const schema = new ValidationSchema();

  schema[nameof<AdminProviderSecureSettingsDto>((p) => p.emailSendGridApiKey)] = validate(
    "SendGrid API Key"
  )
    .when((p: AdminProviderSecureSettingsDto) => p.emailProviderType == EmailProviderType.SendGrid)
    .required();

  schema[nameof<AdminProviderSecureSettingsDto>((p) => p.emailDefaultFromEmail)] = validate(
    "Remitente por defecto (Correo)"
  ).required();
  schema[nameof<AdminProviderSecureSettingsDto>((p) => p.emailDefaultFromUser)] = validate(
    "Remitente por defecto (Nombre)"
  ).required();

  return schema;
}

export function useAdminProviderValidator() {
  const schema = new ValidationSchema();

  schema[nameof<AdminProviderDto>((p) => p.name)] = validate("Nombre").required();
  schema[nameof<AdminProviderDto>((p) => p.country)] = validate("País").required();
  schema[nameof<AdminProviderDto>((p) => p.ownerAccount)] = validate("Cuenta asociada").required();
  schema[nameof<AdminProviderWithSecureInfoDto>((p) => p.providerSecureSettings)] = validateSchema(
    useProviderSecureSettingsValidator(),
    "Valores seguros"
  );

  return schema;
}
