import ValidationField from "../schema/ValidationField";
import ValidationContext from "./ValidationContext";
import ValidationFieldsTrackBase from "./ValidationFieldsTrackBase";
import ValidationFieldsTrackField from "./ValidationFieldsTrackField";
import ValidationResultField from "./ValidationResultField";
import ValidationResultMessage from "./ValidationResultMessage";

export default class ValidationContextField extends ValidationContext {
  constructor(
    parent: ValidationContext,
    propertyName: string,
    collectionItemIndex: number | undefined,
    public validationField: ValidationField,
    fieldsTrackGroup?: ValidationFieldsTrackBase,
    modelValue?: any
  ) {
    super(
      parent,
      propertyName,
      collectionItemIndex,
      validationField.propertyNameFriendly,
      fieldsTrackGroup,
      modelValue
    );
    this.validate();
  }

  validate() {
    this.validateExpressions(this.validationField);
    this.isValid = this.validations.length == 0;
  }

  getValidationResult(trySubmitCount: number) {
    const result = new ValidationResultField();
    const fieldsTrackField = this.fieldsTrack as ValidationFieldsTrackField;
    result.isTouched = fieldsTrackField?.isTouched ?? false;
    result.trySubmitCount = fieldsTrackField?.trySubmitCount ?? 0;
    result.isValid = this.isValid;
    this.validations.forEach((validation) => {
      result.validations.push(
        new ValidationResultMessage(
          validation,
          this.parent?.getPropertyFullFriendlyName() ?? "",
          (!fieldsTrackField && trySubmitCount > 0) || result.trySubmitCount > 0
        )
      );
    });
    return result;
  }
}
