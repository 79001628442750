import { Select } from "@mui/material";
import { IdGuidName } from "../../../IdGuidName";
import Spinner from "../../Spinner";
import Field from "../Field";
import PropsInputBase from "../PropsInputBase";

export interface PropsInputSelectBase<T> extends PropsInputBase<T> {
  emptyText?: string;
  options: IdGuidName[];
  removeEmpty?: boolean;
}

const InputSelectBase = (props: PropsInputSelectBase<any>) => {
  const spinner = props.showSpinner ? <Spinner /> : undefined;
  return (
    <Field
      childrenProps={props}
      labelIsInput={true}
      variant={props.variant}
      labelInputShrink={!!props.value || !!props.emptyText}>
      <Select
        native
        autoFocus={props.autoFocus}
        value={props.options && props.options.length ? props.value?.toString() ?? "" : ""}
        error={!!props.validation}
        disabled={props.readOnly}
        startAdornment={spinner}
        inputProps={{
          name: props.name,
          id: props.id,
        }}
        onBlur={props.onBlur}
        onChange={props.onChange}
        onFocus={props.onFocus}>
        {!props.removeEmpty && <option value="">{props.emptyText}</option>}
        {props.options &&
          props.options.map((option: IdGuidName) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
      </Select>
    </Field>
  );
};

export default InputSelectBase;
