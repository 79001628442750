import { ContactDto } from "../../api/app/dtos/ContactDto";
import { ProductDto } from "../../api/app/dtos/ProductDto";
import { SaleLineDto } from "../../api/app/dtos/SaleLineDto";
import { SaleLineRetentionDto } from "../../api/app/dtos/SaleLineRetentionDto";
import { SaleRetentionDto } from "../../api/app/dtos/SaleRetentionDto";
import { SaleTaxDto } from "../../api/app/dtos/SaleTaxDto";
import { InvoiceRetentionType } from "../../api/shared/enums/InvoiceRetentionType";
import { InvoiceTypeGroup } from "../../api/shared/enums/InvoiceTypeGroup";
import { Tax } from "../../api/shared/enums/Tax";
import {
  ModelStateContextAction,
  ModelStateContextActionType,
} from "../../shared/modelState/ModelStateContext";
import { decimalRound } from "../../shared/utils/utilNumbers";
import { SaleViewModel } from "./ViewModels/SaleViewModel";

export enum SaleModelStateActionType {
  onAutoRoundingChange,
  onContactChange,
  onLineDetailChange,
  onLineDiscountAmountChange,
  onLineDiscountPercentageChange,
  onLineProductChange,
  onLineQuantityChange,
  onLineRetentionAmountChange,
  onLineRetentionRateChange,
  onLineRetentionTotalAmountChange,
  onLineRetentionTypeChange,
  onLineSubtotalChange,
  onLineSurchargeAmountChange,
  onLineSurchargePercentageChange,
  onLineTaxChange,
  onLineTotalChange,
  onLineUnitPriceChange,
}

/**
 * Is not needed to be a pure function since modelStateReducer is in charge of that
 */
export const saleReducer = (
  sale: SaleViewModel,
  action: ModelStateContextAction
): SaleViewModel => {
  switch (action.type) {
    case ModelStateContextActionType.custom: {
      switch (action.typeCustom) {
        case SaleModelStateActionType.onContactChange: {
          const contact = sale.contact as ContactDto;
          sale.isFinalConsumer = !contact?.taxPayerId;
          return sale;
        }
        case SaleModelStateActionType.onLineDetailChange: {
          const saleLine = sale.lines[action.payload];
          if (!saleLine.lineDetail) {
            saleLine.productId = null as any;
            saleLine.product = null as any;
          }
          return sale;
        }
        case SaleModelStateActionType.onLineDiscountAmountChange: {
          const line = sale.lines[action.payload];
          line.discountPercentage = 0;
          calculateLineSubtotal(sale, line);
          return sale;
        }
        case SaleModelStateActionType.onLineDiscountPercentageChange: {
          calculateLineSubtotal(sale, sale.lines[action.payload]);
          return sale;
        }
        case SaleModelStateActionType.onLineProductChange: {
          const saleLine = sale.lines[action.payload];
          const product = saleLine.product as ProductDto;

          if (!product) {
            return sale;
          }

          saleLine.lineDetail = product.name;
          saleLine.viewModelReferencePriceCurrency = product.info?.defaultCurrency;
          saleLine.viewModelReferencePriceAmount = product.info?.referencePrice;

          if (!saleLine.unitPrice) {
            if (sale.currency == product.info?.defaultCurrency) {
              saleLine.unitPrice = product.info?.referencePrice;
            }
          }

          if (product.info) {
            if (saleLine.tax == null) {
              saleLine.tax = product.info.defaultTax;
            }

            if (product.info.saleLineAdditionalDescription) {
              saleLine.description = product.info.saleLineAdditionalDescription;
            }

            if (product.info.addendumAddLegend) {
              if (sale.saleInfo.addendum == null) {
                sale.saleInfo.addendum = "";
              }

              sale.saleInfo.addendum += product.info.addendumAddLegend + "\n";
            }
          }

          calculateLineTotal(sale, action.payload);
          return sale;
        }
        case SaleModelStateActionType.onLineQuantityChange: {
          calculateLineSubtotal(sale, sale.lines[action.payload]);
          return sale;
        }
        case SaleModelStateActionType.onLineRetentionAmountChange: {
          calculateLineRetentionRate(
            sale,
            sale.lines[action.payload.lineIndex].retentions[action.payload.retentionIndex]
          );
          return sale;
        }
        case SaleModelStateActionType.onLineRetentionRateChange:
        case SaleModelStateActionType.onLineRetentionTotalAmountChange:
        case SaleModelStateActionType.onLineRetentionTypeChange: {
          calculateLineRetentionAmount(
            sale,
            sale.lines[action.payload.lineIndex].retentions[action.payload.retentionIndex]
          );
          return sale;
        }
        case SaleModelStateActionType.onLineSubtotalChange: {
          const saleLine = sale.lines[action.payload];
          if (saleLine.subtotal != null && saleLine.quantity) {
            const subtotal =
              saleLine.subtotal - (saleLine.surchargeAmount ?? 0) + (saleLine.discountAmount ?? 0);
            saleLine.unitPrice = decimalRound(subtotal / saleLine.quantity);
          }
          // Recalculate subtotal to fix rounding issues
          calculateLineSubtotal(sale, saleLine);
          return sale;
        }
        case SaleModelStateActionType.onLineSurchargeAmountChange: {
          const line = sale.lines[action.payload];
          line.surchargePercentage = 0;
          calculateLineSubtotal(sale, line);
          return sale;
        }
        case SaleModelStateActionType.onLineSurchargePercentageChange: {
          calculateLineSubtotal(sale, sale.lines[action.payload]);
          return sale;
        }
        case SaleModelStateActionType.onLineTaxChange: {
          calculateLineTotal(sale, sale.lines[action.payload]);
          return sale;
        }
        case SaleModelStateActionType.onLineTotalChange: {
          const saleLine = sale.lines[action.payload];
          if (saleLine.tax && saleLine.quantity) {
            saleLine.subtotal = decimalRound(
              (saleLine.calculatedTotal ?? 0) / (1 + taxValue(saleLine.tax, sale) / 100)
            );
            saleLine.calculatedTaxAmount = decimalRound(
              (saleLine.calculatedTotal ?? 0) - saleLine.subtotal
            );
            saleLine.unitPrice = decimalRound(saleLine.subtotal / saleLine.quantity);
            // Recalculate subtotal to fix rounding issues
            calculateLineSubtotal(sale, saleLine);
          }
          calculateSaleTotal(sale);
          return sale;
        }
        case SaleModelStateActionType.onLineUnitPriceChange: {
          calculateLineSubtotal(sale, sale.lines[action.payload]);
          return sale;
        }
        case SaleModelStateActionType.onAutoRoundingChange: {
          calculateSaleTotal(sale);
          return sale;
        }
      }
      return sale;
    }
    case ModelStateContextActionType.arrayItemRemove:
    case ModelStateContextActionType.arrayItemReplace: {
      calculateSaleTotal(sale);
      return sale;
    }
  }
  return sale;
};

const taxValue = (tax: Tax, sale: SaleViewModel) => {
  const taxValue = sale.taxValuesDto.find((t) => t.tax === tax);
  if (!taxValue) {
    throw Error("Could not find tax value for tax: " + tax.toString());
  }

  return taxValue.value;
};

/* Calculates  quantity * unitPrice (without discounts/surcharges) */
const calculateLinePreSubtotal = (saleLine: SaleLineDto) => {
  return decimalRound((saleLine.quantity ?? 0) * (saleLine.unitPrice ?? 0));
};

const calculateLineSurchargeAmountByPercentage = (saleLine: SaleLineDto) => {
  if (saleLine.surchargePercentage) {
    saleLine.surchargeAmount = decimalRound(
      calculateLinePreSubtotal(saleLine) * (saleLine.surchargePercentage / 100)
    );
  }
};

const calculateLineDiscountAmountByPercentage = (saleLine: SaleLineDto) => {
  if (saleLine.discountPercentage) {
    saleLine.discountAmount = decimalRound(
      calculateLinePreSubtotal(saleLine) * (saleLine.discountPercentage / 100)
    );
  }
};

const calculateLineSubtotal = (sale: SaleViewModel, saleLine: SaleLineDto) => {
  if (saleLine.quantity != null && saleLine.unitPrice != null) {
    calculateLineSurchargeAmountByPercentage(saleLine);
    calculateLineDiscountAmountByPercentage(saleLine);
    saleLine.subtotal =
      calculateLinePreSubtotal(saleLine) +
      (saleLine.surchargeAmount ?? 0) -
      (saleLine.discountAmount ?? 0);
  }
  calculateLineTotal(sale, saleLine);
};

const updateLineRetentionTotalAmount = (sale: SaleViewModel, saleLine: SaleLineDto) => {
  if (saleLine.retentions) {
    saleLine.retentions.forEach((r) => {
      r.totalAmount = saleLine.subtotal;
      calculateLineRetentionAmount(sale, r, false);
    });
  }
};

const calculateLineTotal = (sale: SaleViewModel, saleLine: SaleLineDto) => {
  if (
    saleLine.quantity != null &&
    saleLine.unitPrice != null &&
    saleLine.subtotal != null &&
    saleLine.tax
  ) {
    saleLine.calculatedTaxAmount = decimalRound(
      saleLine.subtotal * (taxValue(saleLine.tax, sale) / 100)
    );
    saleLine.calculatedTotal = decimalRound(saleLine.subtotal + saleLine.calculatedTaxAmount);
  }
  updateLineRetentionTotalAmount(sale, saleLine);
  calculateSaleTotal(sale);
};

const calculateLineRetentionAmount = (
  sale: SaleViewModel,
  saleLineRetention: SaleLineRetentionDto,
  preventCalculateSaleTotal?: boolean
) => {
  if (saleLineRetention.rate && saleLineRetention.totalAmount) {
    saleLineRetention.amount = decimalRound(
      saleLineRetention.totalAmount * (saleLineRetention.rate / 100)
    );
  }

  if (!preventCalculateSaleTotal) {
    calculateSaleTotal(sale);
  }
};

const calculateLineRetentionRate = (
  sale: SaleViewModel,
  saleLineRetention: SaleLineRetentionDto
) => {
  if (saleLineRetention.amount && saleLineRetention.totalAmount) {
    saleLineRetention.rate = decimalRound(
      (saleLineRetention.amount / saleLineRetention.totalAmount) * 100
    );
  } else {
    saleLineRetention.rate = 0;
    saleLineRetention.totalAmount = 0;
  }
  calculateSaleTotal(sale);
};

const calculateSaleTotal = (sale: SaleViewModel) => {
  let subTotal = 0;
  let total = 0;

  sale.lines.forEach((l) => {
    if (l.subtotal && l.tax != Tax.NoBillable) {
      subTotal += l.subtotal ?? 0;
      total += l.calculatedTotal ?? 0;
    }
  });

  sale.subtotal = decimalRound(subTotal);
  sale.total = decimalRound(total);

  calculateTaxes(sale);
  calculateSaleRetentions(sale);

  if (sale.invoiceTypeGroup == InvoiceTypeGroup.EResguardo) {
    sale.totalToPay = (sale.totalRetentions ?? 0) + (sale.totalFiscalCredits ?? 0);
    calculateRounding(sale);
    return;
  }

  sale.totalToPay =
    sale.total +
    (sale.totalRetentions ?? 0) +
    (sale.totalFiscalCredits ?? 0) +
    (sale.totalNoBillable ?? 0);

  calculateRounding(sale);
};

const calculateRounding = (sale: SaleViewModel) => {
  if (!sale.autoRounding) {
    sale.rounding = null;
    return;
  }

  const rounding = decimalRound(sale.totalToPay % 1);
  if (rounding > 0 && rounding <= 0.5) {
    sale.rounding = rounding * -1;
  } else if (rounding != 0) {
    sale.rounding = 1 - rounding;
  }
  sale.totalNoBillable = (sale.totalNoBillable ?? 0) + (sale.rounding ?? 0);
  sale.totalToPay += sale.rounding ?? 0;
};

const calculateTaxes = (sale: SaleViewModel) => {
  sale.taxes = [];
  let calTotalNoBillable = 0;

  sale.lines.forEach((line) => {
    if (line.tax == Tax.NoBillable) {
      calTotalNoBillable += line.subtotal;
    } else {
      let saleTax = sale.taxes.find((c) => c.tax == line.tax);
      if (!saleTax) {
        saleTax = new SaleTaxDto();
        saleTax.tax = line.tax;
        saleTax.percentage = sale.taxValuesDto.find((c) => c.tax == line.tax)?.value ?? 0;
        sale.taxes.push(saleTax);
      }
      saleTax.amount += line.calculatedTaxAmount ?? 0;
      saleTax.linesTotalAmount += line.subtotal ?? 0;
    }
  });

  sale.totalNoBillable = calTotalNoBillable ? calTotalNoBillable : null;
};

const calculateSaleRetentions = (sale: SaleViewModel) => {
  const retentions = sale.lines.flatMap((l) => l.retentions);
  const saleRetentions: SaleRetentionDto[] = [];
  sale.totalRetentions = 0;
  sale.totalFiscalCredits = 0;
  retentions.forEach((r) => {
    let saleRetention = saleRetentions.find((c) => c.code == r.code);
    if (!saleRetention) {
      saleRetention = new SaleRetentionDto();
      saleRetention.code = r.code;
      saleRetention.type = r.type;
      saleRetention.amount = 0;
      saleRetentions.push(saleRetention);
    }
    saleRetention.amount += r.amount;

    if (
      saleRetention.type == InvoiceRetentionType.FiscalCredit &&
      sale.totalFiscalCredits != null
    ) {
      sale.totalFiscalCredits += r.amount;
    } else if (sale.totalRetentions != null) {
      sale.totalRetentions += r.amount;
    }
  });
};
