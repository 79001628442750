import { CSSProperties } from "react";
import SwitchPointOfSalePopUp from "../../../app/Login/SwitchPointOfSalePopUp";
import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import { useContextLoginInfo } from "../../hooks/useContextLoginInfo";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useModal } from "../../hooks/useModal";
import Icon, { TypeIcon } from "../Icon";
import Tooltip from "../Tooltip";

const LayoutSideMenuUserInfo = (props: { expanded: boolean }) => {
  const { loginInfo } = useContextLoginInfo();
  const [modal] = useModal();
  const isMobile = useIsMobile();
  const appTheme = useAppTheme();

  const styleContainer: CSSProperties = {
    position: "absolute",
    width: "calc(100% - 30px)",
    bottom: 15,
    margin: 15,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: 4,
    padding: 15,
    paddingBottom: 5,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    justifyContent: "space-between",
  };

  const styleLabel: CSSProperties = {
    fontSize: 9,
    marginBottom: 2,
    color: appTheme.toHexColor(AppThemeColor.gray),
  };

  const styleInfo: CSSProperties = {
    fontSize: 12,
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const styleRow: CSSProperties = {
    marginBottom: 5,
    display: "inline-block",
    whiteSpace: "nowrap",
    color: appTheme.toHexColor(AppThemeColor.grayLight),
  };

  const switchPointOfSale = () => {
    modal.open(<SwitchPointOfSalePopUp />);
  };

  if (isMobile) {
    return null;
  }

  if (!props.expanded) {
    return (
      <Tooltip
        text={
          <>
            Sucursal: {loginInfo.authInfo.accountBranch.displayName}
            <br />
            Punto de venta: {loginInfo.authInfo.pointOfSale.name}
          </>
        }>
        <div style={styleContainer} onClick={switchPointOfSale}>
          <div style={styleRow}>
            <Icon type={TypeIcon.branch} color={AppThemeColor.gray} />
          </div>
          <div style={styleRow}>
            <Icon type={TypeIcon.pointOfSale} color={AppThemeColor.gray} />
          </div>
        </div>
      </Tooltip>
    );
  }

  return (
    <div style={styleContainer} onClick={switchPointOfSale}>
      <div style={styleRow}>
        <div style={styleLabel}>Sucursal</div>
        <div style={styleInfo}>{loginInfo.authInfo.accountBranch.displayName}</div>
      </div>
      <div style={styleRow}>
        <div style={styleLabel}>Punto de venta</div>
        <div style={styleInfo}>{loginInfo.authInfo.pointOfSale.name}</div>
      </div>
    </div>
  );
};

export default LayoutSideMenuUserInfo;
