import { AppLocale } from "../../../api/shared/enums/AppLocale";
import { Currency } from "../../../api/shared/enums/Currency";
import { LocalizedUtilEnum } from "./localizedUtilEnum";
import { LocalizedUtilNumber } from "./localizedUtilNumbers";

export class LocalizedUtilCurrency {
  private _localizedUtilEnum: LocalizedUtilEnum;
  private _localizedUtilNumber: LocalizedUtilNumber;

  async load(
    _appLocal: AppLocale,
    localizedUtilEnum: LocalizedUtilEnum,
    localizedUtilNumber: LocalizedUtilNumber
  ) {
    this._localizedUtilEnum = localizedUtilEnum;
    this._localizedUtilNumber = localizedUtilNumber;
  }

  name(currency: Currency | null): string {
    return currency ? this._localizedUtilEnum.translateCurrencyName(currency) : "";
  }

  symbol(currency: Currency | null): string {
    return currency ? this._localizedUtilEnum.translateCurrencySymbol(currency) : "";
  }

  toString(currency: Currency | null, value: number | null): string {
    return (
      (currency ? this.symbol(currency) + " " : "") + this._localizedUtilNumber.toString(value)
    );
  }
}
