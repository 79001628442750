// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { AdminProviderSimpleDto } from "./AdminProviderSimpleDto";

export class AdminTermsAndConditionsDto extends BaseEntityDto {
  adminProvider: AdminProviderSimpleDto;
  notifySince: Date = new Date();
  mandatorySince: Date = new Date();
  notificationChanges: string = "";
  termsAndConditions: string = "";
  privacyPolice: string = "";
  version: number = 0;
}
