import { useSwipeable } from "react-swipeable";
import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import ButtonIcon from "../Button/ButtonIcon";
import { TypeIcon } from "../Icon";
import Props from "../Props";
import LayoutSideMenuUserInfo from "./LayoutSideMenu.UserInfo";

export interface PropsLayoutSideMenu extends Props {
  expanded: boolean;
  onCollapseRequested(): void;
  onExpandRequested(): void;
}

const LayoutSideMenu = (props: PropsLayoutSideMenu) => {
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      props.onCollapseRequested();
    },
  });

  return (
    <div className={"layout-side-menu " + (props.expanded ? "expanded" : "")} {...handlers}>
      <div style={{ overflow: "auto", height: "100%" }}>
        <div style={{ textAlign: "right" }}>
          <ButtonIcon
            icon={props.expanded ? TypeIcon.chevronLeft : TypeIcon.chevronRight}
            color={AppThemeColor.primaryContrast}
            size={AppSize.large}
            onClick={() => {
              if (props.expanded) {
                props.onCollapseRequested();
              } else {
                props.onExpandRequested();
              }
            }}
          />
        </div>
        {props.children}
        <LayoutSideMenuUserInfo expanded={props.expanded} />
      </div>
    </div>
  );
};

export default LayoutSideMenu;
