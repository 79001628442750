import { AppLocale } from "../../../api/shared/enums/AppLocale";
import { Country } from "../../../api/shared/enums/Country";
import { Currency } from "../../../api/shared/enums/Currency";
import { State } from "../../../api/shared/enums/State";
import { enumName } from "../../../api/shared/enums/_enumName";
import { IdName } from "../../IdName";

export class LocalizedUtilEnum {
  enumResources: any = null;

  async load(appLocale: AppLocale) {
    // const cachedEnumResources = localStorage.getItem("enumResources-" + AppLocale[appLocale]);
    // if (cachedEnumResources) {
    //   const resources = JSON.parse(cachedEnumResources);
    //   if (parseInt(resources._version) == versionCheck) {
    //     this.enumResources = resources;
    //     return;
    //   }
    // }

    const response = await fetch("res/enumTranslate." + AppLocale[appLocale] + ".json", {
      headers: {
        contentType: "application/json",
        accept: "application/json",
      },
    });
    this.enumResources = await response.json();
    // localStorage.setItem(
    //   "enumResources-" + AppLocale[appLocale],
    //   JSON.stringify(this.enumResources)
    // );
  }

  translate(enumType: string, enumKey: number | null): string {
    if (!this.enumResources) {
      return enumKey?.toString() ?? "";
    }

    if (enumKey == null) {
      return "";
    }

    const enumTranslations: any = this.enumResources[enumType];
    if (!enumTranslations) {
      throw Error("No se puede leer las traducciones para el enumerado: " + enumType);
    }
    return enumTranslations[enumKey];
  }

  toIdName(enumTypeName: string): IdName[] {
    const enumType = this.enumResources[enumTypeName];
    const values = Object.keys(enumType).map((k) => k);
    return this.valuesToIdName(enumTypeName, values);
  }

  valuesToIdName(enumTypeName: string, enumValues?: any[]): IdName[] {
    if (!enumValues) {
      return [];
    }

    return enumValues.map((c) => new IdName(Number(c), this.translate(enumTypeName, c)));
  }

  translateCountry(country: Country) {
    return this.translate(enumName.Country, country);
  }

  translateCurrencyName(currency: Currency) {
    return this.translate(enumName.CurrencyName, currency);
  }

  translateCurrencySymbol(currency: Currency) {
    return this.translate(enumName.Currency, currency);
  }

  translateState(state: State) {
    return this.translate(enumName.State, state);
  }

  translateWithAsDefaultLabel(enumType: string, value: number | null): string {
    return "Por defecto (" + (value ? this.translate(enumType, value) : "Ninguno") + ")";
  }
}
