import { CSSProperties, MutableRefObject, ReactNode, useEffect, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import { SaleLineDto } from "../../api/app/dtos/SaleLineDto";
import { SaleLineRetentionDto } from "../../api/app/dtos/SaleLineRetentionDto";
import { InvoiceTypeGroup } from "../../api/shared/enums/InvoiceTypeGroup";
import { Tax } from "../../api/shared/enums/Tax";
import { AppSize } from "../../shared/AppSize";
import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import Row from "../../shared/components/Layout/GRow";
import { useIsMobile } from "../../shared/hooks/useIsMobile";
import { useModal } from "../../shared/hooks/useModal";
import ModelStateArray, {
  ModelStateArrayItemHelper,
} from "../../shared/modelState/ModelStateArray";
import { TextAlign } from "../../shared/TextAlign";
import { ThemeVariant } from "../../shared/ThemeVariant";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import SaleFormLinesConfigPopup from "./SaleFormLines.ConfigPopup";
import SaleFormLinesEmptyList from "./SaleFormLines.EmptyList";
import SaleFormLinesHeader from "./SaleFormLines.Header";
import SaleFormLinesItem from "./SaleFormLines.Item";
import SaleFormLineRow from "./SaleFormLines.Row";
import SaleLineColumnButtons from "./SaleLineColumn.Buttons";
import SaleLineColumnDiscountAmount from "./SaleLineColumn.DiscountAmount";
import SaleLineColumnDiscountPercentage from "./SaleLineColumn.DiscountPercentage";
import SaleLineColumnProduct from "./SaleLineColumn.Product";
import SaleLineColumnQuantity from "./SaleLineColumn.Quantity";
import SaleLineColumnSubtotal from "./SaleLineColumn.Subtotal";
import SaleLineColumnSurchargeAmount from "./SaleLineColumn.SurchargeAmount";
import SaleLineColumnSurchargePercentage from "./SaleLineColumn.SurchargePercentage";
import SaleLineColumnTax from "./SaleLineColumn.Tax";
import SaleLineColumnTotal from "./SaleLineColumn.Total";
import SaleLineColumnUnitPrice from "./SaleLineColumn.UnitPrice";
import SaleLineRetentionColumnAmount from "./SaleLineRetentionColumn.Amount";
import SaleLineRetentionColumnButtons from "./SaleLineRetentionColumn.Buttons";
import SaleLineRetentionColumnCode from "./SaleLineRetentionColumn.Code";
import SaleLineRetentionColumnDetails from "./SaleLineRetentionColumn.Details";
import SaleLineRetentionColumnRate from "./SaleLineRetentionColumn.Rate";
import SaleLineRetentionColumnTotalAmount from "./SaleLineRetentionColumn.TotalAmount";
import { SaleViewModel } from "./ViewModels/SaleViewModel";

export interface ISaleLineRowConfig {
  columns: ISaleLineColumn[];
  retentionsColumns: ISaleLineRetentionColumn[];
}

export interface ISaleLineColumn {
  type: SaleLineColumnType;
  header: string;
  width: number;
  align?: TextAlign;
  render: (props: ISaleLineColumnRenderProps) => ReactNode;
}

export interface ISaleLineRetentionColumn {
  type: SaleLineRetentionColumnType;
  header: string;
  width: number;
  align?: TextAlign;
  render: (props: ISaleLineRetentionColumnRenderProps) => ReactNode;
}

export interface ISaleLineColumnRenderProps {
  arrayItemHelper: ModelStateArrayItemHelper;
  isEditing: boolean;
  quantityRef?: MutableRefObject<HTMLInputElement | undefined>;
  saleLine: SaleLineDto;
  saleLineColumn: ISaleLineColumn;
  showLabel: boolean;
  variant: ThemeVariant;
}

export interface ISaleLineRetentionColumnRenderProps {
  arrayItemHelper: ModelStateArrayItemHelper;
  lineIndex: number;
  saleLineRetentionColumn: ISaleLineRetentionColumn;
  showLabel: boolean;
  variant: ThemeVariant;
}

export enum SaleLineColumnType {
  buttons,
  discountAmount,
  discountPercentage,
  product,
  quantity,
  unitPrice,
  subtotal,
  surchargeAmount,
  surchargePercentage,
  tax,
  total,
}

export enum SaleLineRetentionColumnType {
  amount,
  buttons,
  code,
  details,
  rate,
  totalAmount,
}

const SaleFormLines = () => {
  const saleModelState = useContextModelStateSale();
  const sale = saleModelState.sale;
  const isMobile = useIsMobile();
  const [modal] = useModal();
  const [saleLineRowConfig, setSaleLineRowConfig] = useState<ISaleLineRowConfig>();

  useEffect(() => {
    setSaleLineRowConfig({
      columns: getColumns(sale),
      retentionsColumns: getRetentionColumns(),
    });
  }, [sale.invoiceTypeGroup, sale.enableLineDiscounts, sale.enableLineSurcharges]);

  if (!saleLineRowConfig) {
    return null;
  }

  const showConfigButton =
    sale.lines?.length > 0 &&
    sale.invoiceTypeGroup != InvoiceTypeGroup.EResguardo &&
    sale.invoiceTypeGroup != InvoiceTypeGroup.ERemito;

  const configButtonStyle: CSSProperties = isMobile
    ? { position: "absolute", right: 0, top: -30 }
    : { position: "absolute", right: 10 };

  return (
    <div style={{ minHeight: 420, position: "relative" }}>
      {showConfigButton && (
        <ButtonIcon
          icon={TypeIcon.config}
          size={AppSize.small}
          style={configButtonStyle}
          onClick={() =>
            modal.open(
              <SaleFormLinesConfigPopup saleViewModel={sale} />,
              (newSale: SaleViewModel) => {
                saleModelState.replaceModel(newSale);
              }
            )
          }
        />
      )}

      <Row>
        {sale.invoiceTypeGroup == InvoiceTypeGroup.EResguardo && <h2>Retenciones</h2>}
        {(!sale.lines || sale.lines?.length == 0) && <SaleFormLinesEmptyList />}
        {!isMobile && sale.lines?.length > 0 && saleLineRowConfig.columns.length > 0 && (
          <SaleFormLineRow>
            {saleLineRowConfig.columns.map((saleLineColumn, index) => (
              <SaleFormLinesHeader key={index} saleLineColumn={saleLineColumn} />
            ))}
          </SaleFormLineRow>
        )}
        <ModelStateArray propertyName={nameof<SaleDto>((p) => p.lines)}>
          {(arrayItemHelper) => (
            <SaleFormLinesItem
              key={arrayItemHelper.index}
              arrayItemHelper={arrayItemHelper}
              isEditing={sale.editingLineIndex == arrayItemHelper.index}
              sale={sale}
              saleLine={sale.lines[arrayItemHelper.index]}
              saleLineRowConfig={saleLineRowConfig}
            />
          )}
        </ModelStateArray>
      </Row>
      {sale.invoiceTypeGroup != InvoiceTypeGroup.EResguardo && (
        <Row paddingTop={3} align={TextAlign.right}>
          <ButtonSecondary
            text="agregar producto"
            icon={TypeIcon.plus}
            onClick={() => saleModelState.lineAdd(getNewLine(sale))}
          />
        </Row>
      )}
    </div>
  );
};

export default SaleFormLines;

const getColumns = (sale: SaleViewModel): ISaleLineColumn[] => {
  const columnTypes = getColumnTypes(sale);
  return saleLineColumns.filter((column) => columnTypes.includes(column.type));
};

const getRetentionColumns = (): ISaleLineRetentionColumn[] => {
  const columnTypes = getRetentionColumnTypes();
  return saleLineRetentionColumns.filter((column) => columnTypes.includes(column.type));
};

const getColumnTypes = (sale: SaleViewModel): SaleLineColumnType[] => {
  switch (sale.invoiceTypeGroup) {
    case InvoiceTypeGroup.ERemito:
      return [SaleLineColumnType.product, SaleLineColumnType.quantity, SaleLineColumnType.buttons];
    case InvoiceTypeGroup.EResguardo:
      return [];
    default: {
      const columns = [
        SaleLineColumnType.product,
        SaleLineColumnType.quantity,
        SaleLineColumnType.unitPrice,
        SaleLineColumnType.subtotal,
        SaleLineColumnType.tax,
        SaleLineColumnType.total,
        SaleLineColumnType.buttons,
      ];
      if (sale.enableLineDiscounts) {
        columns.push(SaleLineColumnType.discountPercentage);
        columns.push(SaleLineColumnType.discountAmount);
      }
      if (sale.enableLineSurcharges) {
        columns.push(SaleLineColumnType.surchargePercentage);
        columns.push(SaleLineColumnType.surchargeAmount);
      }
      return columns;
    }
  }
};

const getRetentionColumnTypes = (): SaleLineRetentionColumnType[] => {
  return [
    SaleLineRetentionColumnType.code,
    SaleLineRetentionColumnType.details,
    SaleLineRetentionColumnType.totalAmount,
    SaleLineRetentionColumnType.rate,
    SaleLineRetentionColumnType.amount,
    SaleLineRetentionColumnType.buttons,
  ];
};

export const getNewLine = (sale: SaleViewModel) => {
  const line = new SaleLineDto();

  if (sale.invoiceTypeGroup == InvoiceTypeGroup.ERemito) {
    line.tax = Tax.NoBillable;
  }

  if (sale.invoiceTypeGroup == InvoiceTypeGroup.EResguardo) {
    line.tax = Tax.NoBillable;
    line.retentions = [new SaleLineRetentionDto()];
  }

  return line;
};

const saleLineColumns: ISaleLineColumn[] = [
  {
    type: SaleLineColumnType.product,
    header: "Producto",
    width: 30,
    render: (props: ISaleLineColumnRenderProps) => <SaleLineColumnProduct {...props} />,
  },
  {
    type: SaleLineColumnType.quantity,
    header: "Cantidad",
    align: TextAlign.right,
    width: 10,
    render: (props: ISaleLineColumnRenderProps) => <SaleLineColumnQuantity {...props} />,
  },
  {
    type: SaleLineColumnType.unitPrice,
    header: "Precio unitario",
    align: TextAlign.right,
    width: 10,
    render: (props: ISaleLineColumnRenderProps) => <SaleLineColumnUnitPrice {...props} />,
  },
  {
    type: SaleLineColumnType.discountPercentage,
    header: "% Descuento",
    align: TextAlign.right,
    width: 10,
    render: (props: ISaleLineColumnRenderProps) => <SaleLineColumnDiscountPercentage {...props} />,
  },
  {
    type: SaleLineColumnType.discountAmount,
    header: "Descuento",
    align: TextAlign.right,
    width: 10,
    render: (props: ISaleLineColumnRenderProps) => <SaleLineColumnDiscountAmount {...props} />,
  },
  {
    type: SaleLineColumnType.surchargePercentage,
    header: "% Recargo",
    align: TextAlign.right,
    width: 10,
    render: (props: ISaleLineColumnRenderProps) => <SaleLineColumnSurchargePercentage {...props} />,
  },
  {
    type: SaleLineColumnType.surchargeAmount,
    header: "Recargo",
    align: TextAlign.right,
    width: 10,
    render: (props: ISaleLineColumnRenderProps) => <SaleLineColumnSurchargeAmount {...props} />,
  },
  {
    type: SaleLineColumnType.subtotal,
    header: "Subtotal",
    align: TextAlign.right,
    width: 10,
    render: (props: ISaleLineColumnRenderProps) => <SaleLineColumnSubtotal {...props} />,
  },
  {
    type: SaleLineColumnType.tax,
    header: "IVA",
    width: 15,
    render: (props: ISaleLineColumnRenderProps) => <SaleLineColumnTax {...props} />,
  },

  {
    type: SaleLineColumnType.total,
    header: "Total",
    align: TextAlign.right,
    width: 10,
    render: (props: ISaleLineColumnRenderProps) => <SaleLineColumnTotal {...props} />,
  },
  {
    type: SaleLineColumnType.buttons,
    header: "",
    width: 5,
    align: TextAlign.right,
    render: (props: ISaleLineColumnRenderProps) => <SaleLineColumnButtons {...props} />,
  },
];

const saleLineRetentionColumns: ISaleLineRetentionColumn[] = [
  {
    header: "Código de retención",
    type: SaleLineRetentionColumnType.code,
    width: 30,
    render: (props: ISaleLineRetentionColumnRenderProps) => (
      <SaleLineRetentionColumnCode {...props} />
    ),
  },
  {
    header: "Detalles",
    type: SaleLineRetentionColumnType.details,
    width: 35,
    render: (props: ISaleLineRetentionColumnRenderProps) => (
      <SaleLineRetentionColumnDetails {...props} />
    ),
  },
  {
    header: "Monto imponible",
    type: SaleLineRetentionColumnType.totalAmount,
    width: 10,
    render: (props: ISaleLineRetentionColumnRenderProps) => (
      <SaleLineRetentionColumnTotalAmount {...props} />
    ),
  },
  {
    header: "Tasa",
    type: SaleLineRetentionColumnType.rate,
    width: 10,
    render: (props: ISaleLineRetentionColumnRenderProps) => (
      <SaleLineRetentionColumnRate {...props} />
    ),
  },
  {
    header: "Monto",
    type: SaleLineRetentionColumnType.amount,
    width: 10,
    render: (props: ISaleLineRetentionColumnRenderProps) => (
      <SaleLineRetentionColumnAmount {...props} />
    ),
  },
  {
    header: "",
    type: SaleLineRetentionColumnType.buttons,
    width: 5,
    render: (props: ISaleLineRetentionColumnRenderProps) => (
      <SaleLineRetentionColumnButtons {...props} />
    ),
  },
];
