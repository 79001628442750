// File generated by: Gesta.Build.PostEvents
export enum PaperSize {
	Custom = 1,
	A3 = 2,
	A4 = 3,
	A5 = 5,
	B4 = 6,
	B5 = 7,
	Letter = 8,
	Legal = 9,
	Folio = 10,
	Roll80 = 11,
	Roll120 = 12,
}
