import { useEffect, useState } from "react";
import { Country } from "../../../../api/shared/enums/Country";
import { State } from "../../../../api/shared/enums/State";
import { enumName } from "../../../../api/shared/enums/_enumName";
import { useApiState } from "../../../../api/shared/hooks/useApiApp";
import useUtils from "../../../hooks/useUtils";
import { IdName } from "../../../IdName";
import InputSelectEnumBase, { PropsInputSelectEnumBase } from "./InputSelectEnumBase";

export interface PropsInputSelectState extends Omit<PropsInputSelectEnumBase<State>, "options"> {
  country?: Country;
}

const InputSelectState = (props: PropsInputSelectState) => {
  const { utils } = useUtils();
  const [apiState] = useApiState();
  const [states, setStates] = useState<IdName[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setStates([]);
    setLoading(true);

    const load = async () => {
      if (!props.country) {
        setLoading(false);
        return;
      }

      const countryStates = await apiState.getForCountry(props.country, {
        preventSpinner: true,
      });
      setStates(utils.enum.valuesToIdName(enumName.State, countryStates));
      setLoading(false);
    };
    load();
  }, [props.country]);

  return <InputSelectEnumBase {...props} options={states ?? []} showSpinner={loading} />;
};

export default InputSelectState;
