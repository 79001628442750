import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import { TypeIcon } from "../../shared/components/Icon";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import TabGroup from "../../shared/components/Layout/TabGroup";
import TabView from "../../shared/components/Layout/TabView";
import AdminProviderFormGeneral from "./AdminProviderForm.General";
import AdminProviderFormLogos from "./AdminProviderForm.Logos";
import AdminProviderFormSecureValues from "./AdminProviderForm.SecureValues";
import AdminProviderFormStyles from "./AdminProviderForm.Styles";

const TABS = {
  GENERAL: "GENERAL",
  LOGOS: "LOGOS",
  STYLES: "STYLES",
  SECRETS: "SECRETS",
};

const AdminProviderForm = (props: { onSave(): void; isEdit: boolean }) => {
  const tabs = [
    { title: "General", value: TABS.GENERAL },
    { title: "Logos", value: TABS.LOGOS },
    { title: "Estilos", value: TABS.STYLES },
    { title: "Valores seguros", value: TABS.SECRETS, icon: TypeIcon.lock },
  ];

  return (
    <>
      <TabGroup tabs={tabs} selectedTab={TABS.GENERAL}>
        <TabView value={TABS.GENERAL}>
          <AdminProviderFormGeneral />
        </TabView>
        <TabView value={TABS.LOGOS}>
          <AdminProviderFormLogos />
        </TabView>
        <TabView value={TABS.STYLES}>
          <AdminProviderFormStyles />
        </TabView>
        <TabView value={TABS.SECRETS}>
          <AdminProviderFormSecureValues />
        </TabView>
      </TabGroup>
      <RowButtons>
        <ButtonAccept onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default AdminProviderForm;
