import { useEffect, useState } from "react";
import { AdminTaxValueDto } from "../../api/admin/dtos/AdminTaxValueDto";
import { enumName } from "../../api/shared/enums/_enumName";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminTaxValue } from "../../api/shared/hooks/useApiAdmin";
import { useModal } from "../../shared/hooks/useModal";
import useUtils from "../../shared/hooks/useUtils";

interface PropsAdminTaxValueDetailsPopup {
  adminTaxValueId: string;
}

const AdminTaxValueDetailsPopup = (props: PropsAdminTaxValueDetailsPopup) => {
  const [modal] = useModal();
  const [adminTaxValue, setAdminTaxValue] = useState<AdminTaxValueDto>();
  const [apiAdminTaxValue] = useApiAdminTaxValue();
  const { utils } = useUtils();

  useEffect(() => {
    const loadAdminTaxValue = async () => {
      setAdminTaxValue(await apiAdminTaxValue.getById(props.adminTaxValueId));
      modal.setVisible("Detalles del impuesto", ModalSize.small);
    };
    loadAdminTaxValue();
  }, []);

  if (!adminTaxValue) {
    return <></>;
  }

  return (
    <Container>
      <ColumnDisplay label="País">
        {utils.enum.translate(enumName.Country, adminTaxValue.country)}
      </ColumnDisplay>
      <ColumnDisplay label="Impuesto">
        {utils.enum.translate(enumName.Tax, adminTaxValue.tax)}
      </ColumnDisplay>
      <ColumnDisplay label="Valor">{utils.number.toString(adminTaxValue.value)}</ColumnDisplay>
      <ColumnDisplay label="Válido desde">
        {utils.date.dateOnlyToString(adminTaxValue.validSince)}
      </ColumnDisplay>
      <ColumnDisplay label="Válido hasta">
        {utils.date.dateOnlyToString(adminTaxValue.validUntil)}
      </ColumnDisplay>
      <ColumnDisplay label="Definir por operación">
        {adminTaxValue.operationMustSpecify ? "Sí" : "-"}
      </ColumnDisplay>
    </Container>
  );
};

export default AdminTaxValueDetailsPopup;
