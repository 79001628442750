import { useState } from "react";
import { AppRoute } from "../../AppRoutes";
import { useLoginLayout } from "./Layout/LoginLayoutContext";
import LoginCard from "./Layout/LoginCard";
import LoginLink from "./Layout/LoginLink";
import LoginRow from "./Layout/LoginRow";
import LoginRowError from "./Layout/LoginRowError";
import LoginTitle from "./Layout/LoginTitle";
import RegisterForm from "./Register.Form";
import { useLeadValidation } from "./Validations/useLeadValidation";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import { useApiAdminPublicEmailContact } from "../../api/shared/hooks/useApiAdmin";
import { AdminEmailContactPublicSalesDto } from "../../api/admin/dtos/AdminEmailContactPublicSalesDto";

const Register = () => {
  const loginLayout = useLoginLayout();
  const [apiAdminPublicEmailContact] = useApiAdminPublicEmailContact();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [model] = useState(() => {
    const model = new AdminEmailContactPublicSalesDto();
    model.contactByWhatsApp = true;
    return model;
  });

  const sendContactRequest = (contactDto: AdminEmailContactPublicSalesDto) => {
    setIsLoading(true);
    const send = async () => {
      try {
        await apiAdminPublicEmailContact.sendFromRegisterToSales(contactDto, {
          preventNotifications: true,
          preventSpinner: true,
        });
        if (contactDto.contactByWhatsApp) {
          loginLayout.navigateTo(AppRoute.login.register.successMobile);
        } else {
          loginLayout.navigateTo(AppRoute.login.register.successEmail);
        }
      } catch (error: any) {
        setError(error.toString());
        setIsLoading(false);
      }
    };
    send();
  };

  if (!model) {
    return <></>;
  }

  return (
    <LoginCard isLoading={isLoading}>
      <LoginTitle>Registrate</LoginTitle>
      <LoginRow>Ingresá tus datos y nos ponemos en contacto en un minuto!</LoginRow>
      <StateProvider
        model={model}
        onSubmit={(model) => sendContactRequest(model)}
        validationSchema={useLeadValidation()}>
        {() => <RegisterForm isLoading={isLoading} />}
      </StateProvider>
      <LoginRowError message={error} />
      <LoginRow isBottom={true} fontSize={11}>
        <div>
          Ingresé por error,
          <LoginLink text="quiero regresar" onClick={() => loginLayout.navigateBack()} />
        </div>
      </LoginRow>
    </LoginCard>
  );
};

export default Register;
