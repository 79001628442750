import { Collapse } from "@mui/material";
import { CSSProperties, useState } from "react";
import { useAppTheme } from "../../AppThemeProvider";
import { AppSize } from "../../shared/AppSize";
import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import { TypeIcon } from "../../shared/components/Icon";
import { TextAlign } from "../../shared/TextAlign";
import { AppThemeColor } from "../../styles/color";

const ReportLine = (props: {
  children?: any;
  isSubtotalSummary?: boolean;
  isTotalSummary?: boolean;
  label: string;
  values: string[];
  expandButtonShow?: boolean;
  expandButtonDisabled?: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);
  const appTheme = useAppTheme();
  let styleLine: CSSProperties = {
    display: "flex",
    gap: 5,
    flexDirection: "row",
    overflow: "hidden",
    marginBottom: 5,
    color: appTheme.toHexColor(AppThemeColor.gray),
    borderBottom: "1px dotted " + AppThemeColor.gray,
    cursor: props.expandButtonShow && !props.expandButtonDisabled ? "pointer" : "default",
  };

  let style: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    paddingLeft: 5,
    paddingBottom: 0,
  };

  const styleCell: CSSProperties = {
    flex: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const styleLabel: CSSProperties = {
    ...styleCell,
    maxWidth: props.values.length > 0 ? "30%" : "60%",
  };

  const styleValue: CSSProperties = {
    ...styleCell,
    textAlign: TextAlign.right,
  };

  if (props?.isSubtotalSummary || props?.isTotalSummary) {
    styleLine = {
      ...styleLine,
      fontSize: props?.isTotalSummary ? "1.4em" : "1.1em",
      fontWeight: props?.isTotalSummary ? "bold" : "normal",
      padding: 5,
      paddingBottom: 0,
      color: appTheme.toHexColor(props?.isTotalSummary ? AppThemeColor.black : AppThemeColor.gray),
      borderBottom: "",
    };

    style = {
      ...style,
      borderRadius: 5,
      background: appTheme.toHexColor(
        props?.isTotalSummary ? AppThemeColor.gray : AppThemeColor.grayLight
      ),
    };
  }

  return (
    <div style={style}>
      <div
        style={styleLine}
        onClick={() => {
          if (props.expandButtonShow && !props.expandButtonDisabled) {
            setExpanded(!expanded);
          }
        }}>
        <div style={styleLabel}>
          {props.expandButtonShow && (
            <ButtonIcon
              disabled={props.expandButtonDisabled}
              icon={expanded ? TypeIcon.minus : TypeIcon.plus}
              noPadding={true}
              size={AppSize.small}
              onClick={() => {
                // Handled at container div
              }}
            />
          )}
          {props.label}
        </div>
        {props.values.map((value, index) => (
          <div key={index} style={styleValue}>
            {value}
          </div>
        ))}
      </div>
      <Collapse in={expanded}>{props.children}</Collapse>
    </div>
  );
};

export default ReportLine;
