import { ApiSale } from "../../api/app/apis/ApiSale";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import { EInvoiceProcessStatus } from "../../api/shared/enums/EInvoiceProcessStatus";
import { InvoiceTypeGroup } from "../../api/shared/enums/InvoiceTypeGroup";
import { SaleDraftStatus } from "../../api/shared/enums/SaleDraftStatus";
import { enumName } from "../../api/shared/enums/_enumName";
import { AppRoute } from "../../AppRoutes";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import ModalDialog from "../../shared/components/Modal/ModalDialog";
import { ModalService } from "../../shared/hooks/useModal";
import { Navigation } from "../../shared/hooks/useNavigation";
import { Notifier } from "../../shared/hooks/useNotifier";
import { LocalizedUtils } from "../../shared/utils/localizedUtil/localizedUtils";
import SaleDetailsPopup from "./SaleDetailsPopup";

export function getSaleListItemMenu(
  saleDto: SaleDto,
  modal: ModalService,
  navigation: Navigation,
  apiSale: ApiSale,
  utils: LocalizedUtils,
  notifier: Notifier,
  onReloadGrid: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Editar borrador",
      hide: saleDto.draftStatus != SaleDraftStatus.Draft,
      icon: TypeIcon.edit,
      onClick: () => {
        navigation.go(AppRoute.sales.draftEditById(saleDto.id));
      },
    },
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        modal.open(<SaleDetailsPopup saleId={saleDto.id} />, onReloadGrid);
      },
    },
    {
      text: "Descargar PDF",
      hide: !saleDto.pdfUrl,
      icon: TypeIcon.pdf,
      onClick: () => {
        navigation.newTab(saleDto.pdfUrl);
      },
    },
    {
      text: "Descargar XML",
      hide: !saleDto.xmlUrl,
      icon: TypeIcon.xml,
      onClick: () => {
        navigation.newTab(saleDto.xmlUrl);
      },
    },
    {
      text: "Descartar borrador",
      hide: saleDto.draftStatus != SaleDraftStatus.Draft,
      icon: TypeIcon.delete,
      onClick: () => {
        modal.open(<ModalDialog text="¿Seguro desea descartar el borrador de factura?" />, () => {
          const request = async () => {
            await apiSale.draftDiscard(saleDto.id);
            onReloadGrid();
          };
          request();
        });
      },
    },
    {
      text: "Recuperar borrador",
      hide: saleDto.draftStatus != SaleDraftStatus.Discarded,
      icon: TypeIcon.undo,
      onClick: () => {
        modal.open(<ModalDialog text={"¿Seguro desea recuperar el borrador?"} />, () => {
          const request = async () => {
            await apiSale.draftDiscardUndo(saleDto.id);
            onReloadGrid();
          };
          request();
        });
      },
    },
    // {
    //   text: "Crear copia",
    //   hide: saleDto.draftStatus != SaleDraftStatus.Issued,
    //   icon: TypeIcon.copy,
    //   onClick: () => {
    //     navigation.go(AppRoute.sales.cloneById(saleDto.id));
    //   },
    // },
    {
      text: "Anular",
      hide:
        saleDto.draftStatus != SaleDraftStatus.Issued ||
        saleDto.isCancellation ||
        saleDto.isCanceled,
      icon: TypeIcon.delete,
      onClick: () => {
        modal.open(
          <ModalDialog
            text={"¿Seguro desea anular el comprobante?"}
            helpText={
              utils.enum.translate(enumName.InvoiceType, saleDto.invoiceType) +
              ": " +
              saleDto.serieAndNumber +
              (saleDto.invoiceTypeGroup == InvoiceTypeGroup.EResguardo
                ? ". Nota: Se creará un nuevo eResguardo con fecha de hoy "
                : ". Nota: Se creará un nuevo comprobante con fecha igual al comprobante a anular")
            }
          />,
          () => {
            const request = async () => {
              const saleCancelation = await apiSale.cancel(saleDto.id);

              const invoiceNumber =
                utils.enum.translate(enumName.InvoiceType, saleCancelation.invoiceType) +
                " " +
                saleCancelation.serieAndNumber;

              notifier.showSuccess("El comprobante se emitió con éxito: " + invoiceNumber);
              onReloadGrid();
            };
            request();
          }
        );
      },
    },
    {
      text: "eFactura - Reintentar envío de sobre",
      icon: TypeIcon.refresh,
      hide: saleDto.eInvoiceProcessStatus != EInvoiceProcessStatus.ErrorEnvelopeSending,
      onClick: () => {
        modal.open(<ModalDialog text="¿Seguro desea reenviar el sobre?" />, () => {
          const request = async () => {
            await apiSale.retrySend(saleDto.id);
            notifier.showSuccess("El envío se volvió a enviar con éxito");
            onReloadGrid();
          };
          request();
        });
      },
    },
    {
      text: "eFactura - Cancelar envío de sobre",
      icon: TypeIcon.delete,
      hide: saleDto.eInvoiceProcessStatus != EInvoiceProcessStatus.ErrorEnvelopeSending,
      onClick: () => {
        modal.open(
          <ModalDialog
            text="¿Seguro desea cancelar el envío?"
            helpText="La factura se marcará como cancelada antes de enviar a DGI. No es necesario emitir un nuevo comprobante de corrección.
            Las notificaciones al proveedor todavía no se han enviado por lo cual no es necesario notificarlo de esta anulación."
          />,
          () => {
            const request = async () => {
              await apiSale.cancelBeforeSend(saleDto.id);
              notifier.showSuccess("El envío se canceló con éxito");
              onReloadGrid();
            };
            request();
          }
        );
      },
    },
    {
      text: "eFactura forzar próximo paso",
      hide:
        saleDto.draftStatus != SaleDraftStatus.Issued ||
        saleDto.eInvoiceProcessStatus == EInvoiceProcessStatus.ErrorEnvelopeSending ||
        saleDto.eInvoiceProcessStatus == EInvoiceProcessStatus.Accepted ||
        saleDto.eInvoiceProcessStatus == EInvoiceProcessStatus.AcceptedNotApply,
      icon: TypeIcon.refresh,
      onClick: () => {
        const requestVerify = async () => {
          const message = await apiSale.eInvoiceForceNextStepVerify(saleDto.id);

          modal.open(
            <ModalDialog text="¿Seguro desea forzar el próximo paso?" helpText={message} />,
            () => {
              const request = async () => {
                const message = await apiSale.eInvoiceForceNextStep(saleDto.id);
                notifier.showSuccess(message);
                onReloadGrid();
              };
              request();
            }
          );
        };
        requestVerify();
      },
    },
  ];
}
