import { EInvoiceEnvelopeSaleToCompanyDto } from "../../../api/app/dtos/EInvoiceEnvelopeSaleToCompanyDto";
import EInvoiceEnvelopeDetails from "./EInvoiceEnvelopeDetails";

const EInvoiceEnvelopeDetailsSaleToCompany = (props: {
  eInvoiceEnvelope: EInvoiceEnvelopeSaleToCompanyDto;
}) => {
  return (
    <EInvoiceEnvelopeDetails
      title="Sobre entre empresas"
      eInvoiceEnvelope={props.eInvoiceEnvelope}
    />
  );
};

export default EInvoiceEnvelopeDetailsSaleToCompany;
