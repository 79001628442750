import { AppEnvironment } from "../../../AppEnvironment";
import Button from "../Button/Button";
import Row from "../Layout/GRow";
import ErrorPageLayout from "./ErrorPageLayout";

const ServerCommunicationError = () => {
  return (
    <ErrorPageLayout>
      <Row>No podemos conectarnos con el servidor :(</Row>
      <Row>
        <span style={{ fontSize: ".5em" }}>{AppEnvironment.API_BASE_URL}</span>
      </Row>
      <Row>
        <Button text="Reintentar" onClick={() => window.location.reload()} />
      </Row>
    </ErrorPageLayout>
  );
};

export default ServerCommunicationError;
