// File generated by: Gesta.Build.PostEvents
import { useState } from "react";
import { useApi } from "../../../shared/hooks/useApi";
import { ApiAdminProviderAccountUserLinkedAccount } from "../../admin/apis/ApiAdminProviderAccountUserLinkedAccount";
import { ApiAdminProviderAdminAccount } from "../../admin/apis/ApiAdminProviderAdminAccount";
import { ApiAdminProviderAuth } from "../../admin/apis/ApiAdminProviderAuth";
import { ApiAdminProviderConfig } from "../../admin/apis/ApiAdminProviderConfig";
import { ApiAdminProviderEInvoiceDailyReportProcess } from "../../admin/apis/ApiAdminProviderEInvoiceDailyReportProcess";
import { ApiAdminProviderEmailConfig } from "../../admin/apis/ApiAdminProviderEmailConfig";
import { ApiAdminProviderSentEmail } from "../../admin/apis/ApiAdminProviderSentEmail";
import { ApiAdminProviderShard } from "../../admin/apis/ApiAdminProviderShard";
import { ApiAdminProviderTermsAndConditions } from "../../admin/apis/ApiAdminProviderTermsAndConditions";
import { ApiAdminProviderTimeZone } from "../../admin/apis/ApiAdminProviderTimeZone";
import { ApiAdminProviderUser } from "../../admin/apis/ApiAdminProviderUser";
import { ApiAdminProviderUserLinkedAccount } from "../../admin/apis/ApiAdminProviderUserLinkedAccount";
import { ApiAdminProviderUserTermsAccepted } from "../../admin/apis/ApiAdminProviderUserTermsAccepted";

export function useApiAdminProviderAccountUserLinkedAccount() {
  return useState(new ApiAdminProviderAccountUserLinkedAccount(useApi()));
}

export function useApiAdminProviderAdminAccount() {
  return useState(new ApiAdminProviderAdminAccount(useApi()));
}

export function useApiAdminProviderAuth() {
  return useState(new ApiAdminProviderAuth(useApi()));
}

export function useApiAdminProviderConfig() {
  return useState(new ApiAdminProviderConfig(useApi()));
}

export function useApiAdminProviderEInvoiceDailyReportProcess() {
  return useState(new ApiAdminProviderEInvoiceDailyReportProcess(useApi()));
}

export function useApiAdminProviderEmailConfig() {
  return useState(new ApiAdminProviderEmailConfig(useApi()));
}

export function useApiAdminProviderSentEmail() {
  return useState(new ApiAdminProviderSentEmail(useApi()));
}

export function useApiAdminProviderShard() {
  return useState(new ApiAdminProviderShard(useApi()));
}

export function useApiAdminProviderTermsAndConditions() {
  return useState(new ApiAdminProviderTermsAndConditions(useApi()));
}

export function useApiAdminProviderTimeZone() {
  return useState(new ApiAdminProviderTimeZone(useApi()));
}

export function useApiAdminProviderUser() {
  return useState(new ApiAdminProviderUser(useApi()));
}

export function useApiAdminProviderUserLinkedAccount() {
  return useState(new ApiAdminProviderUserLinkedAccount(useApi()));
}

export function useApiAdminProviderUserTermsAccepted() {
  return useState(new ApiAdminProviderUserTermsAccepted(useApi()));
}

