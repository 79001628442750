// File generated by Gesta.Build.PostEvents
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { AdminProviderSimpleDto } from "./AdminProviderSimpleDto";
import { FilterRangeDateDto } from "../../shared/dtos/FilterRangeDateDto";
import { EmailProviderType } from "../../shared/enums/EmailProviderType";
import { SentEmailStatus } from "../../shared/enums/SentEmailStatus";

export class AdminSentEmailFiltersDto extends FiltersDto {
  adminProviderId: string;
  adminProvider: AdminProviderSimpleDto;
  createdDate: FilterRangeDateDto;
  from: string = "";
  providerType: EmailProviderType;
  statusList: SentEmailStatus[] = [];
  subject: string = "";
  to: string = "";
}
