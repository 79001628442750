// File generated by Gesta.Build.PostEvents
import { SearchOrderDto } from "../../shared/dtos/SearchOrderDto";

export class SearchBaseDto<T>  {
  crossShardId: string;
  echoId: number = 0;
  filters: T;
  includeInactive: boolean = false;
  includedAllFields: boolean = false;
  includedFields: string[] = [];
  orderBy: SearchOrderDto[] = [];
}
