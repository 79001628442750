import { nameof } from "ts-simple-nameof";
import { SaleSenderBehalfDto } from "../../../../api/app/dtos/SaleSenderBehalfDto";
import { FiltersDto } from "../../../../api/shared/dtos/FiltersDto";
import { SuggestSearchDto } from "../../../../api/shared/dtos/SuggestSearchDto";
import { useApiSaleSenderBehalf } from "../../../../api/shared/hooks/useApiApp";
import SaleSenderBehalfCreatePopup from "../../../../app/SaleSenderBehalf/SaleSenderBehalfCreatePopup";
import SaleSenderBehalfEditPopup from "../../../../app/SaleSenderBehalf/SaleSenderBehalfEditPopup";
import { useModal } from "../../../hooks/useModal";
import InputAutocompleteBase, { PropsInputAutocompleteBase } from "./InputAutocompleteBase";

export type PropsInputAutocompleteSaleSenderBehalf = Omit<
  PropsInputAutocompleteBase<SaleSenderBehalfDto>,
  "onGetEntityById"
>;

const InputAutocompleteSaleSenderBehalf = (props: PropsInputAutocompleteSaleSenderBehalf) => {
  const [apiSaleSenderBehalf] = useApiSaleSenderBehalf();
  const [modal] = useModal();

  const apiRequest = (echoId: number, term: string, isInitialLoad: boolean) => {
    const searchDto = new SuggestSearchDto<FiltersDto>();
    searchDto.echoId = echoId;
    searchDto.filters = new FiltersDto();
    searchDto.filters.defaultTextSearch = term;
    searchDto.isInitialLoad = isInitialLoad;
    searchDto.orderBy = [{ columnName: nameof<SaleSenderBehalfDto>((p) => p.name), asc: true }];
    const apiOptions = {
      preventSpinner: true,
    };

    return apiSaleSenderBehalf.suggest(searchDto, apiOptions);
  };

  const openCreateSenderBehalf = (searchTerm: string, createCallback: (id: string) => void) => {
    modal.open(<SaleSenderBehalfCreatePopup />, (saleSenderBehalf: SaleSenderBehalfDto) => {
      createCallback(saleSenderBehalf.id);
    });
  };

  const openEditSenderBehalf = (id: string, editCallback: (id: string) => void) => {
    modal.open(<SaleSenderBehalfEditPopup id={id} />, (saleSenderBehalf: SaleSenderBehalfDto) => {
      editCallback(saleSenderBehalf.id);
    });
  };

  return (
    <InputAutocompleteBase
      {...props}
      value={props.value}
      onCreateNewClick={openCreateSenderBehalf}
      onEditClick={openEditSenderBehalf}
      onGetEntityById={(id: string) => apiSaleSenderBehalf.getById(id, { preventSpinner: true })}
      onServerSideSuggest={apiRequest}
    />
  );
};

export default InputAutocompleteSaleSenderBehalf;
