import { useContext } from "react";
import { GlobalSpinnerContextSet } from "../context/GlobalSpinnerProvider";

const spinnerIds: number[] = [];

export default function useSpinner() {
  const { setSpinnerState } = useContext(GlobalSpinnerContextSet);
  const show = () => {
    setSpinnerState(true);
    const id = spinnerIds.length > 0 ? spinnerIds[spinnerIds.length - 1] + 1 : 1;
    spinnerIds.push(id);
    return id;
  };
  const hide = (id: number) => {
    const indexOf = spinnerIds.indexOf(id);
    if (indexOf != -1) {
      spinnerIds.splice(indexOf, 1);
    }

    if (spinnerIds.length == 0) {
      setSpinnerState(false);
    }
  };
  return { show, hide };
}
