import { useEffect, useState } from "react";
import { AdminEmailContactSupportDto } from "../../api/admin/dtos/AdminEmailContactSupportDto";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminAccountPublicEmailContactSupport } from "../../api/shared/hooks/useApiAdmin";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import EmailContactSupportPopupForm from "./EmailContactSupportPopup.Form";
import { useEmailContactSupportValidation } from "./Validations/useEmailContactSupportValidation";

const EmailContactSupportPopup = (props: { subject: string; message: string }) => {
  const [modal] = useModal();
  const [apiAdminAccountPublicEmailContactSupport] = useApiAdminAccountPublicEmailContactSupport();
  const [emailContactSupportDto] = useState<AdminEmailContactSupportDto>(() => {
    const emailContactSupportDto = new AdminEmailContactSupportDto();
    emailContactSupportDto.subject = props.subject;
    emailContactSupportDto.message = props.message;
    return emailContactSupportDto;
  });

  const save = async (emailContactSupportDto: AdminEmailContactSupportDto) => {
    await apiAdminAccountPublicEmailContactSupport.send(emailContactSupportDto);
    modal.closeAsSuccess(null, "Nos pondremos en contacto a la brevedad");
  };

  useEffect(() => {
    modal.setVisible("Contactar con soporte técnico", ModalSize.small);
  }, [modal]);

  return (
    <Container>
      <StateProvider
        model={emailContactSupportDto}
        validationSchema={useEmailContactSupportValidation()}
        onSubmit={save}>
        {(p) => <EmailContactSupportPopupForm onSubmit={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default EmailContactSupportPopup;
