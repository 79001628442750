import { Badge } from "@mui/material";
import { useEffect, useState } from "react";
import { AppNotificationSummaryDto } from "../../api/app/dtos/AppNotificationSummaryDto";
import { AppSize } from "../../shared/AppSize";
import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import { TypeIcon } from "../../shared/components/Icon";
import { useApiAppNotifications } from "../../api/shared/hooks/useApiApp";
import { AppThemeColor } from "../../styles/color";
import AppNotificationHeaderList from "./AppNotificationHeaderList";

const AppNotificationHeaderIcon = () => {
  const [expanded, setExpanded] = useState(false);
  const [appNotificationSummary, setAppNotificationSummary] = useState<AppNotificationSummaryDto>();
  const [apiAppNotifications] = useApiAppNotifications();

  const getCount = (summary?: AppNotificationSummaryDto): number => {
    if (summary?.alertCount) {
      return summary.alertCount;
    }

    if (summary?.warningCount) {
      return summary.warningCount;
    }

    if (summary?.reminderCount) {
      return summary.reminderCount;
    }

    if (summary?.infoCount) {
      return summary.infoCount;
    }

    return 0;
  };

  const load = async (closeIfEmpty?: boolean) => {
    const summary = await apiAppNotifications.getSummary({
      preventNotifications: true,
      preventSpinner: true,
    });
    setAppNotificationSummary(summary);
    if (closeIfEmpty && !getCount(summary)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    load();
    const timer = setTimeout(() => {
      load();
    }, 60000);
    return () => clearTimeout(timer);
  }, []);

  const count = getCount(appNotificationSummary);

  let color: "info" | "error" | "warning" = "info";
  if (appNotificationSummary?.alertCount) {
    color = "error";
  } else if (appNotificationSummary?.warningCount) {
    color = "warning";
  }

  return (
    <>
      <div style={{ paddingTop: 10, paddingRight: 10 }}>
        <Badge badgeContent={count} style={{ marginLeft: 15 }} color={color}>
          <ButtonIcon
            icon={TypeIcon.bell}
            size={AppSize.large}
            color={AppThemeColor.gray}
            noPadding={true}
            onClick={(e) => {
              e.stopPropagation();
              load();
              setExpanded(!expanded);
            }}
          />
        </Badge>
      </div>
      {expanded && (
        <AppNotificationHeaderList
          onItemSetAsRead={() => load(true)}
          onNewItem={load}
          onClose={() => {
            load();
            setExpanded(false);
          }}
        />
      )}
    </>
  );
};

export default AppNotificationHeaderIcon;
