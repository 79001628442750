import { nameof } from "ts-simple-nameof";
import { validate } from "../../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../../shared/validation/schema/ValidationSchema";
import { EmailConfigViewModel, EmailVerificationStatus } from "../EmailConfigViewModel";

export function useEmailConfigValidations() {
  const schema = new ValidationSchema();

  schema[nameof<EmailConfigViewModel>((p) => p.user)] = validate("Usuario").required();
  schema[nameof<EmailConfigViewModel>((p) => p.password)] = validate("Contraseña").required();
  schema[nameof<EmailConfigViewModel>((p) => p.smtpServer)] = validate("Servidor smtp").required();
  schema[nameof<EmailConfigViewModel>((p) => p.smtpPort)] = validate("Puerto smtp").required();
  schema[nameof<EmailConfigViewModel>((p) => p.imapServer)] = validate("Servidor imap").required();
  schema[nameof<EmailConfigViewModel>((p) => p.imapPort)] = validate("Puerto imap").required();

  schema[nameof<EmailConfigViewModel>((p) => p.smtpVerificationStatus)] = validate(
    "Verificación smtp"
  ).verify((value: EmailVerificationStatus) => value == EmailVerificationStatus.Success);

  schema[nameof<EmailConfigViewModel>((p) => p.imapVerificationStatus)] = validate(
    "Verificación imap"
  ).verify((value: EmailVerificationStatus) => value == EmailVerificationStatus.Success);

  return schema;
}
