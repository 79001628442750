// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { EInvoiceEnvelopeSaleToCompanyDto } from "../dtos/EInvoiceEnvelopeSaleToCompanyDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiEInvoiceEnvelopeSaleToCompany {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceEnvelopeSaleToCompanyDto> {
    return this.apiService.apiGet("/e-invoice-envelopes-sale-to-company/" + id, apiOptions);
  }

  retrySend(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/e-invoice-envelopes-sale-to-company/retry-send/" + id, null, apiOptions);
  }

}
