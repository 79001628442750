import { nameof } from "ts-simple-nameof";
import { AuthForgotPasswordDto } from "../../api/app/dtos/AuthForgotPasswordDto";
import Button from "../../shared/components/Button/Button";
import FormText from "../../shared/components/forms/FormText";
import LoginRow from "./Layout/LoginRow";

const ForgotPasswordForm = (props: {
  isLoading: boolean;
  onSubmit(): void;
}) => {
  return (
    <>
      <LoginRow>
        <FormText
          label="Correo"
          readOnly={props.isLoading}
          propertyName={nameof<AuthForgotPasswordDto>((p) => p.userEmail)}
        />
      </LoginRow>
      <LoginRow>
        <Button
          text="Enviar"
          disabled={props.isLoading}
          fullWidth={true}
          onClick={props.onSubmit}
        />
      </LoginRow>
    </>
  );
};

export default ForgotPasswordForm;
