import { AppRoute } from "../../AppRoutes";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Row from "../../shared/components/Layout/GRow";
import Section from "../../shared/components/Layout/GSection";
import { useNavigation } from "../../shared/hooks/useNavigation";

const ConfigurationGeneral = () => {
  const navigation = useNavigation();

  return (
    <Row>
      <Section title="Generales">
        <Row>
          <ComponentGroup wrap={true}>
            <ButtonSecondary
              text="Preferencias"
              icon={TypeIcon.config}
              onClick={() => navigation.go(AppRoute.configuration.preferences)}
            />
            <ButtonSecondary
              text="Monedas"
              icon={TypeIcon.dollar}
              onClick={() => navigation.go(AppRoute.configuration.currencies)}
            />
            <ButtonSecondary
              text="Impuestos"
              icon={TypeIcon.file}
              onClick={() => navigation.go(AppRoute.configuration.taxes)}
            />
            <ButtonSecondary
              text="Región"
              icon={TypeIcon.locale}
              onClick={() => navigation.go(AppRoute.configuration.locale)}
            />
          </ComponentGroup>
        </Row>
      </Section>
    </Row>
  );
};

export default ConfigurationGeneral;
