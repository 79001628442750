import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { FilterRangeDateDto } from "../../../../api/shared/dtos/FilterRangeDateDto";
import { FilterDateRangeType } from "../../../../api/shared/enums/FilterDateRangeType";
import { enumName } from "../../../../api/shared/enums/_enumName";
import { useApiDate } from "../../../../api/shared/hooks/useApiApp";
import useUtils from "../../../hooks/useUtils";
import { useContextModelState } from "../../../modelState/useContextModelState";
import { ThemeVariant } from "../../../ThemeVariant";
import InputDate from "../../inputs/InputDate";
import PropsInputBase from "../../inputs/PropsInputBase";
import InputSelectEnum from "../../inputs/select/InputSelectEnum";
import Column from "../../Layout/GColumn";
import Container from "../../Layout/GContainer";
import Row from "../../Layout/GRow";

interface PropsFilterDateRange
  extends Omit<PropsInputBase<FilterRangeDateDto | null>, "value" | "onChange"> {
  includeTime?: boolean;
  propertyName: string;
}

const FilterDateRange = (props: PropsFilterDateRange) => {
  const modelState = useContextModelState();
  const filterValue = modelState.getValue(props.propertyName) as FilterRangeDateDto;
  const [hint, setHint] = useState<string>("");
  const [apiDate] = useApiDate();
  const { utils } = useUtils();

  return (
    <Container spacing={1}>
      <Row>
        <InputSelectEnum
          label={props.label}
          enumTypeName={enumName.FilterDateRangeType}
          hint={hint}
          value={modelState.getValue(props.propertyName)?.type ?? null}
          variant={props.variant ?? ThemeVariant.standard}
          onChange={async (value: number | null) => {
            if (!value) {
              modelState.updateProperty(props.propertyName, null);
              return;
            }

            if (!filterValue) {
              modelState.updateProperty(props.propertyName, new FilterRangeDateDto());
            }

            modelState.updateProperty(
              props.propertyName + "." + nameof<FilterRangeDateDto>((p) => p.type),
              value
            );

            if (
              value != FilterDateRangeType.Today &&
              value != FilterDateRangeType.Yesterday &&
              value != FilterDateRangeType.Date &&
              value != FilterDateRangeType.Range
            ) {
              const rangeValues = await apiDate.getRangeMinAndMax(value);
              setHint(
                utils.date.toString(rangeValues.rangeMin) +
                  " - " +
                  utils.date.toString(rangeValues.rangeMax)
              );
            } else {
              setHint("");
            }
          }}
        />
      </Row>
      {filterValue?.type == FilterDateRangeType.Range && (
        <>
          <Column md={props.includeTime ? 12 : 6} minHeightIgnore={true}>
            <InputDate
              includeTime={props.includeTime}
              label="Desde"
              variant={props.variant ?? ThemeVariant.standard}
              value={filterValue?.rangeMin}
              onChange={(value) => {
                modelState.updateProperty(
                  props.propertyName + "." + nameof<FilterRangeDateDto>((p) => p.rangeMin),
                  value
                );
              }}
            />
          </Column>
          <Column md={props.includeTime ? 12 : 6}>
            <InputDate
              includeTime={props.includeTime}
              label="Hasta"
              variant={props.variant ?? ThemeVariant.standard}
              value={filterValue?.rangeMax}
              onChange={(value) => {
                modelState.updateProperty(
                  props.propertyName + "." + nameof<FilterRangeDateDto>((p) => p.rangeMax),
                  value
                );
              }}
            />
          </Column>
        </>
      )}
      {filterValue?.type == FilterDateRangeType.Date && (
        <>
          <Row>
            <InputDate
              label="Fecha"
              variant={props.variant ?? ThemeVariant.standard}
              value={filterValue?.date}
              onChange={(value) => {
                modelState.updateProperty(
                  props.propertyName + "." + nameof<FilterRangeDateDto>((p) => p.date),
                  value
                );
              }}
            />
          </Row>
        </>
      )}
    </Container>
  );
};

export default FilterDateRange;
