// File generated by Gesta.Build.PostEvents
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { Country } from "../../shared/enums/Country";
import { State } from "../../shared/enums/State";
import { ContactType } from "../../shared/enums/ContactType";
import { ContactFilterGroup } from "../../shared/enums/ContactFilterGroup";
import { IdCardType } from "../../shared/enums/IdCardType";

export class ContactFiltersDto extends FiltersDto {
  addressLine: string = "";
  addressCity: string = "";
  addressCountry: Country;
  addressState: State;
  companyName: string = "";
  contactType: ContactType;
  email: string = "";
  eInvoiceEmail: string = "";
  filterGroup: ContactFilterGroup;
  idCardType: IdCardType;
  idCardNumber: string = "";
  isCustomer: boolean | null = null;
  isProvider: boolean | null = null;
  name: string = "";
  notes: string = "";
  openApiExternalId: string = "";
  phone: string = "";
  taxPayerId: string = "";
}
