import { isValid } from "date-fns";
import { DateOnlyDto } from "../dtos/DateOnlyDto";

export function dateToIsoString(date: any): string {
  if (!date) {
    return "";
  }

  if (typeof date === "string") {
    date = new Date(date);
  }

  if (isValid(date)) {
    return date.toISOString();
  }

  throw Error("Date is not valid: " + date);
}

export const toDateOnly = (date: Date): DateOnlyDto => {
  const dateOnly = new DateOnlyDto();
  dateOnly.day = date.getDate();
  dateOnly.year = date.getFullYear();
  dateOnly.month = date.getMonth() + 1;
  return dateOnly;
};
