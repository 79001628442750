import { SaleDto } from "../../api/app/dtos/SaleDto";
import ButtonFileDownload from "../../shared/components/Button/ButtonFileDownload";
import InfoText from "../../shared/components/InfoText";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import TabGroup from "../../shared/components/Layout/TabGroup";
import TabView from "../../shared/components/Layout/TabView";
import EInvoiceEnvelopeDetailsSale from "../EInvoice/Envelopes/EInvoiceEnvelopeDetails.Sale";
import SaleDetailsPopupEInvoiceEInvoiceEnvelopeStatusResponseSale from "./SaleDetailsPopup.EInvoiceEInvoiceEnvelopeStatusResponseSale";
import SaleDetailsPopupEInvoiceProcessLog from "./SaleDetailsPopup.EInvoiceProcessLog";

const TABS = {
  EVENTS: "EVENTS",
  ACK: "ACK",
};

const SaleDetailsPopupEInvoiceEnvelopeSale = (props: { sale: SaleDto }) => {
  if (!props.sale.eInvoiceEnvelopeSale) {
    return <InfoText>No aplica el envío del sobre para este documento</InfoText>;
  }

  const tabs = [
    { title: "Eventos", value: TABS.EVENTS },
    { title: "Acuses de recibo", value: TABS.ACK },
  ];

  return (
    <>
      <EInvoiceEnvelopeDetailsSale eInvoiceEnvelope={props.sale.eInvoiceEnvelopeSale} />

      <TabGroup tabs={tabs} selectedTab={TABS.EVENTS}>
        <TabView value={TABS.EVENTS}>
          <SaleDetailsPopupEInvoiceProcessLog sale={props.sale} />
        </TabView>
        <TabView value={TABS.ACK}>
          <SaleDetailsPopupEInvoiceEInvoiceEnvelopeStatusResponseSale sale={props.sale} />
        </TabView>
      </TabGroup>

      <RowButtons>
        <ButtonFileDownload text="Descargar XML comprobante" fileUrl={props.sale.xmlUrl} />

        <ButtonFileDownload
          fileUrl={props.sale.eInvoiceEnvelopeSale.xmlUrl}
          text="Descargar XML sobre"
        />
      </RowButtons>
    </>
  );
};

export default SaleDetailsPopupEInvoiceEnvelopeSale;
