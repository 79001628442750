import ValidationResultBase from "./ValidationResultBase";
import ValidationResultSchema from "./ValidationResultSchema";

export default class ValidationResultCollection extends ValidationResultBase {
  items: ValidationResultSchema[] = [];

  getTouchedValidation(propertyFullName: string): string {
    const itemIndex = parseInt(
      propertyFullName.substring(
        propertyFullName.indexOf("[") + 1,
        propertyFullName.indexOf("]") + 1
      )
    );
    return (
      this.items[itemIndex]?.getTouchedValidation(
        propertyFullName.substring(propertyFullName.indexOf(".") + 1)
      ) ?? ""
    );
  }
}
