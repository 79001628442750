import ValidationFieldCollection from "../schema/ValidationFieldCollection";
import { ValidationMessages } from "../schema/ValidationMessages";
import ValidationContext from "./ValidationContext";
import ValidationContextSchema from "./ValidationContextSchema";
import ValidationFieldsTrack from "./ValidationFieldsTrack";
import ValidationFieldsTrackCollection from "./ValidationFieldsTrackCollection";
import ValidationResultCollection from "./ValidationResultCollection";
import ValidationResultMessage from "./ValidationResultMessage";

export default class ValidationContextCollection extends ValidationContext {
  validationFieldCollection: ValidationFieldCollection;
  items: ValidationContextSchema[] = [];

  constructor(
    parent: ValidationContext,
    propertyName: string,
    collectionItemIndex: number | undefined,
    validationFieldCollection: ValidationFieldCollection,
    fieldsTrackGroup?: ValidationFieldsTrackCollection,
    modelValue?: any
  ) {
    super(
      parent,
      propertyName,
      collectionItemIndex,
      validationFieldCollection.propertyNameFriendly,
      fieldsTrackGroup,
      modelValue
    );
    this.validationFieldCollection = validationFieldCollection;
    this.validate();
  }

  validate() {
    this.validateExpressions(this.validationFieldCollection);

    let existsInvalidItem = false;
    if (this.modelValue) {
      if (!(this.modelValue instanceof Array)) {
        throw new Error(
          ValidationMessages.DevelopErrorCollectionMustBeArray(this.getPropertyFullName())
        );
      }

      (this.modelValue as []).forEach((item, index) => {
        const fieldsTrackCollection = this.fieldsTrack as ValidationFieldsTrackCollection;
        const validationCollectionItem = new ValidationContextSchema(
          this,
          "",
          index,
          this.validationFieldCollection.validationFieldCollectionItem,
          fieldsTrackCollection?.items[index] as ValidationFieldsTrack,
          item
        );
        this.items.push(validationCollectionItem);

        if (!validationCollectionItem.isValid) {
          existsInvalidItem = true;
        }
      });
    }
    this.isValid = this.validations.length == 0 && !existsInvalidItem;
  }

  getValidationResult(trySubmitCount: number): ValidationResultCollection {
    const result = new ValidationResultCollection();
    result.isValid = this.isValid;

    //Collection level only validations
    result.validations = this.validations.map(
      (v) =>
        new ValidationResultMessage(
          v,
          this.parent?.getPropertyFullFriendlyName() ?? "",
          trySubmitCount > 0
        )
    );

    //Collection item level only validations
    this.items.forEach((i, itemIndex) =>
      i.validations.forEach(
        (v) =>
          new ValidationResultMessage(
            v,
            this.parent?.getPropertyFullFriendlyName() + " [" + itemIndex + "]",
            trySubmitCount > 0
          )
      )
    );

    result.items = this.items.map((item) => item.getValidationResult(trySubmitCount));

    result.items.forEach((resultItem) => {
      if (resultItem.validations.length > 0) {
        result.validations = result.validations.concat(resultItem.validations);
      }
    });

    return result;
  }
}
