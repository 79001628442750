import { nameof } from "ts-simple-nameof";
import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import { PurchaseReferenceDto } from "../../api/app/dtos/PurchaseReferenceDto";
import { enumName } from "../../api/shared/enums/_enumName";
import GridClientSide from "../../shared/components/grid/GridClientSide";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Row from "../../shared/components/Layout/GRow";

const PurchaseDetailsReferences = (props: { purchase: PurchaseDto }) => {
  const templates: IGridItemTemplate<PurchaseReferenceDto>[] = [
    {
      header: "Número",
      field: nameof<PurchaseReferenceDto>((p) => p.number),
      width: 10,
    },
    {
      header: "Tipo",
      field: nameof<PurchaseReferenceDto>((p) => p.invoiceType),
      width: 10,
      cellTypeEnumName: enumName.InvoiceType,
    },
    {
      header: "Serie",
      field: nameof<PurchaseReferenceDto>((p) => p.serie),
      width: 10,
    },
    {
      header: "Número",
      field: nameof<PurchaseReferenceDto>((p) => p.number),
      width: 10,
    },
    {
      header: "Detalle",
      field: nameof<PurchaseReferenceDto>((p) => p.reason),
      width: 20,
    },
    {
      header: "Fecha referencia",
      field: nameof<PurchaseReferenceDto>((p) => p.referenceDate),
      width: 10,
      cellType: GridCellType.dateTime,
    },
  ];

  return (
    <Row>
      <GridClientSide items={props.purchase.references} templates={templates} />
    </Row>
  );
};

export default PurchaseDetailsReferences;
