import { createContext, useEffect, useState } from "react";
import { TaxValueDto } from "../../../api/app/dtos/TaxValueDto";
import { useApiTax } from "../../../api/shared/hooks/useApiApp";

const TaxValueContext = createContext({});

const TaxValueProvider = (props: { children: any; currentDate: Date | null }) => {
  const [currentDate, setCurrentDate] = useState<Date | null>(null);
  const [lastDate, setLastDate] = useState<Date | null | undefined>(undefined);
  const [taxValues, setTaxValues] = useState<TaxValueDto[]>();
  const [apiTax] = useApiTax();

  useEffect(() => {
    if (lastDate != currentDate || lastDate == undefined) {
      setLastDate(currentDate);
      const request = async () => {
        setTaxValues(
          await apiTax.getTaxValuesForCurrentAccount(currentDate ? currentDate : new Date(), {
            preventSpinner: true,
          })
        );
      };
      request();
    }
  }, [currentDate]);

  useEffect(() => {
    setCurrentDate(props.currentDate);
  }, [props.currentDate]);

  return (
    <TaxValueContext.Provider value={{ taxValues, setTaxValues, currentDate, setCurrentDate }}>
      {props.children}
    </TaxValueContext.Provider>
  );
};

export { TaxValueContext, TaxValueProvider };
