import InputSelectBase, { PropsInputSelectBase } from "./InputSelectBase";

export type PropsInputSelectGuid = PropsInputSelectBase<string>;

const InputSelectGuid = (props: PropsInputSelectGuid) => {
  return (
    <InputSelectBase
      {...props}
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
    />
  );
};

export default InputSelectGuid;
