import { useEffect, useState } from "react";
import { AdminAccountDto } from "../../api/admin/dtos/AdminAccountDto";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiAdminProviderAdminAccount } from "../../api/shared/hooks/useApiAdminProviders";
import Column from "../../shared/components/Layout/GColumn";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Container from "../../shared/components/Layout/GContainer";
import LogoAccount from "../../shared/components/LogoAccount";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useModal } from "../../shared/hooks/useModal";
import useUtils from "../../shared/hooks/useUtils";

const AdminAccountDetailsPopup = (props: { id: string }) => {
  const [modal] = useModal();
  const [adminAccount, setAdminAccount] = useState<AdminAccountDto>();
  const [apiAdminProviderAdminAccount] = useApiAdminProviderAdminAccount();
  const { utils } = useUtils();

  useEffect(() => {
    const loadAdminAccount = async () => {
      setAdminAccount(await apiAdminProviderAdminAccount.getById(props.id));
      modal.setVisible("Detalles de cuenta", ModalSize.big);
    };
    loadAdminAccount();
  }, []);

  if (!adminAccount) {
    return <></>;
  }

  return (
    <Container>
      <Column>
        <LogoAccount adminAccount={adminAccount} />
      </Column>
      <ColumnDisplay label="Nombre">{adminAccount.name}</ColumnDisplay>
      <ColumnDisplay label="Razón social">{adminAccount.company.name}</ColumnDisplay>
      <ColumnDisplay label="Nombre comercial">{adminAccount.company.comercialName}</ColumnDisplay>
      <ColumnDisplay label="RUT">{adminAccount.company.taxPayerId}</ColumnDisplay>
      <ColumnDisplay label="País">
        {utils.enum.translateCountry(adminAccount.country)}
      </ColumnDisplay>
      <ColumnDisplay label="Locale">
        {utils.enum.translate(enumName.AppLocale, adminAccount.locale)}
      </ColumnDisplay>
    </Container>
  );
};

export default AdminAccountDetailsPopup;
