import { useEffect, useState } from "react";
import { AccountUserCrudDto } from "../../api/admin/dtos/AccountUserCrudDto";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminProviderAccountUserLinkedAccount } from "../../api/shared/hooks/useApiAdminProviders";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AccountUserCreateForm from "./AccountUserCreatePopup.Form";
import { useAccountUserCrudValidation } from "./Validations/useAccountUserCrudValidation";

const AccountUserCreatePopup = () => {
  const [accountUser] = useState<AccountUserCrudDto>(new AccountUserCrudDto());
  const [modal] = useModal();
  const [apiAdminAccountUserLinkedAccount] = useApiAdminProviderAccountUserLinkedAccount();

  const save = async (accountUser: AccountUserCrudDto) => {
    const created = await apiAdminAccountUserLinkedAccount.createUser(accountUser);
    modal.closeAsSuccess(created, "El usuario se creó con éxito");
  };

  useEffect(() => {
    modal.setVisible("Crear usuario", ModalSize.small);
  }, [modal]);

  return (
    <Container>
      <StateProvider
        model={accountUser}
        onSubmit={save}
        validationSchema={useAccountUserCrudValidation()}>
        {(p) => <AccountUserCreateForm onSave={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default AccountUserCreatePopup;
