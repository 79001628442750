import { FileUploadExtensionsAllowed } from "../../api/shared/enums/FileUploadExtensionsAllowed";

const fileExtensionImages = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".bpg", ".tiff"];

const fileExtensionDocuments = [".pdf", ".doc", ".xml", ".csv", ".txt"];

const fileExtensionCertificates = [".pfx"];
const fileExtensionXml = [".xml"];

export const fileExtension = (fileName: string): string => {
  return fileName?.substring(fileName.lastIndexOf(".")) ?? "";
};

export const fileIsImage = (fileName: string): boolean => {
  const extension = fileExtension(fileName);
  return fileExtensionImages.find((c) => c === extension) != null;
};

export const fileInputAccept = (
  fileUploadExtensionsAllowed?: FileUploadExtensionsAllowed
): string => {
  switch (fileUploadExtensionsAllowed) {
    case FileUploadExtensionsAllowed.Images:
      return fileExtensionImages.join(",");
    case FileUploadExtensionsAllowed.Documents:
      return fileExtensionDocuments.join(",");
    case FileUploadExtensionsAllowed.Certificates:
      return fileExtensionCertificates.join(",");
    case FileUploadExtensionsAllowed.Xml:
      return fileExtensionXml.join(",");
  }

  return "";
};

export const downloadFileFromUrl = async (url: string, fileName: string) => {
  try {
    // Fetch the file
    const response = await fetch(url);

    // Check if the request was successful
    if (response.status !== 200) {
      throw new Error(`Unable to download file. HTTP status: ${response.status}`);
    }

    // Get the Blob data
    const blob = await response.blob();

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;

    // Trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up
    setTimeout(() => {
      URL.revokeObjectURL(downloadLink.href);
      document.body.removeChild(downloadLink);
    }, 100);
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
};
