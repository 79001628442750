import { forwardRef } from "react";
import useUtils from "../../hooks/useUtils";
import InputRadio, { PropsInputRadio } from "../inputs/InputRadio";
import { PropsFormInput, useFormInputInit } from "./_formInputProps";

export interface PropsFormRadioEnum
  extends PropsFormInput<number | null>,
    Omit<PropsInputRadio, "options" | "value" | "onChange" | "name"> {
  enumName: string;
}

const FormRadioEnum = forwardRef((props: PropsFormRadioEnum, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  const { utils } = useUtils();
  const options = utils.enum.toIdName(props.enumName);

  return <InputRadio {...props} {...inputProps} name={props.propertyName} options={options} />;
});

export default FormRadioEnum;
