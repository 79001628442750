import { nameof } from "ts-simple-nameof";
import { TemplateConfigEmailTestDto } from "../../../api/app/dtos/TemplateConfigEmailTestDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

export function useTemplateConfigEmailTestValidation() {
  const schema = new ValidationSchema();

  schema[nameof<TemplateConfigEmailTestDto>((p) => p.testEmailTo)] = validate("Correo")
    .required()
    .email();

  schema[nameof<TemplateConfigEmailTestDto>((p) => p.testEmailToName)] =
    validate("Nombre").required();

  return schema;
}
