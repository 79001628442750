import { useEffect, useState } from "react";
import { AdminPointOfSaleClientDto } from "../../api/admin/dtos/AdminPointOfSaleClientDto";
import { useApiAdminPointOfSaleClient } from "../../api/shared/hooks/useApiAdmin";
import Container from "../../shared/components/Layout/GContainer";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AdminPointOfSaleClientForm from "./AdminPointOfSaleClientForm";
import { useAdminPointOfSaleClientCreateValidation } from "./Validations/useAdminPointOfSaleClientCreateValidation";

const AdminPointOfSaleClientCreatePopup = () => {
  const [adminPointOfSaleClient] = useState(new AdminPointOfSaleClientDto());
  const [modal] = useModal();
  const [apiAdminPointOfSaleClient] = useApiAdminPointOfSaleClient();

  const save = async (adminPointOfSaleClient: AdminPointOfSaleClientDto) => {
    const created = await apiAdminPointOfSaleClient.create(adminPointOfSaleClient);
    modal.closeAsSuccess(created, "El client Pos se creó con éxito");
  };

  useEffect(() => modal.setVisible("Crear client Pos"), []);

  if (!adminPointOfSaleClient) {
    return <></>;
  }

  return (
    <Container>
      <StateProvider
        model={adminPointOfSaleClient}
        onSubmit={save}
        validationSchema={useAdminPointOfSaleClientCreateValidation()}>
        {(p) => <AdminPointOfSaleClientForm onSave={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default AdminPointOfSaleClientCreatePopup;
