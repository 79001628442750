import TabStepper, { TabStep } from "../../shared/components/Layout/TabStepper";
import TabView from "../../shared/components/Layout/TabView";
import ContactFormAddress from "./Contact.Form.Address";
import ContactFormGeneral from "./Contact.Form.General";
import ContactFormNotes from "./Contact.Form.Notes";

const TABS = {
  GENERAL: "GENERAL",
  ADDRESS: "ADDRESS",
  NOTES: "NOTES",
};

const ContactForm = (props: { isEdit?: boolean; onSubmit: () => void }) => {
  const tabs: TabStep[] = [
    {
      title: "General",
      value: TABS.GENERAL,
    },
    {
      title: "Dirección",
      value: TABS.ADDRESS,
    },
    { title: "Notas", value: TABS.NOTES },
  ];

  return (
    <TabStepper tabs={tabs} onLastTabAccept={props.onSubmit} isEditMode={props.isEdit}>
      <TabView value={TABS.GENERAL}>
        <ContactFormGeneral isEdit={props.isEdit} />
      </TabView>
      <TabView value={TABS.ADDRESS}>
        <ContactFormAddress />
      </TabView>
      <TabView value={TABS.NOTES}>
        <ContactFormNotes />
      </TabView>
    </TabStepper>
  );
};

export default ContactForm;
