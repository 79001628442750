import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import { TextAlign } from "../../TextAlign";
import ButtonIcon from "../Button/ButtonIcon";
import ButtonLink from "../Button/ButtonLink";
import ButtonSecondary from "../Button/ButtonSecondary";
import { TypeIcon } from "../Icon";
import Column from "../Layout/GColumn";
import Container from "../Layout/GContainer";
import Row from "../Layout/GRow";
import GridFiltersItem from "./GridFilters.Item";
import { IGridItemTemplate } from "./ItemTemplate/IGridItemTemplate";

const GridHeaderFilterPopup = (props: {
  hasValue: boolean;
  column: IGridItemTemplate<any>;
  onApply: () => void;
  onClear: () => void;
  onClose: () => void;
}) => {
  if (props.column.filter == null) {
    return <></>;
  }

  const appTheme = useAppTheme();

  return (
    <div style={{ width: 300 }} onClick={(e) => e.stopPropagation()}>
      <div
        style={{
          padding: 5,
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          paddingRight: 10,
          backgroundColor: appTheme.toHexColor(AppThemeColor.primary),
          color: appTheme.toHexColor(AppThemeColor.white),
          fontSize: 14,
          textAlign: TextAlign.left,
          fontWeight: "normal",
        }}>
        Aplicar filtro
      </div>
      <div style={{ padding: 15 }}>
        <ButtonIcon
          icon={TypeIcon.close}
          onClick={props.onClose}
          color={AppThemeColor.white}
          style={{ position: "absolute", right: -5, top: -5 }}
        />
        <Container>
          <Row>
            <GridFiltersItem
              filter={props.column.filter}
              label={props.column.headerAlt ?? props.column.header}
            />
          </Row>
          <Column md={6} minHeightIgnore={true}>
            {props.hasValue && (
              <ButtonLink icon={TypeIcon.eraser} text="borrar" onClick={props.onClear} />
            )}
          </Column>
          <Column md={6} align={TextAlign.right} minHeightIgnore={true}>
            <ButtonSecondary icon={TypeIcon.filter} text="Aplicar" onClick={props.onApply} />
          </Column>
        </Container>
      </div>
    </div>
  );
};

export default GridHeaderFilterPopup;
