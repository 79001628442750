import { useEffect, useState } from "react";
import { VaultCertificateDto } from "../../../api/app/dtos/VaultCertificateDto";
import Container from "../../../shared/components/Layout/GContainer";
import { ModalSize } from "../../../shared/context/ModalProvider";
import { useApiVaultCertificate } from "../../../api/shared/hooks/useApiApp";
import { useModal } from "../../../shared/hooks/useModal";
import VaultCertificateInfo from "./VaultCertificateInfo";

const VaultCertificateDetailsPopup = (props: { id: string }) => {
  const [modal] = useModal();
  const [vaultCertificate, setVaultCertificate] = useState<VaultCertificateDto>();
  const [apiVaultCertificate] = useApiVaultCertificate();

  useEffect(() => {
    const load = async () => {
      setVaultCertificate(await apiVaultCertificate.getById(props.id));
      modal.setVisible("Certificado digital", ModalSize.small);
    };
    load();
  }, [props.id, modal, apiVaultCertificate]);

  if (!vaultCertificate) {
    return null;
  }

  return (
    <Container>
      <VaultCertificateInfo vaultCertificate={vaultCertificate} />
    </Container>
  );
};

export default VaultCertificateDetailsPopup;
