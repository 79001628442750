// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminAccountSimpleDto } from "../../shared/dtos/AdminAccountSimpleDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminProviderAuth {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getProviderAccountOwner(
    apiOptions?: ApiOptions
  ): Promise<AdminAccountSimpleDto> {
    return this.apiService.apiGet("/admin-provider/auth/account-owner", apiOptions);
  }

}
