import { useEffect, useState } from "react";
import { AppLocale } from "../../../../api/shared/enums/AppLocale";
import { Country } from "../../../../api/shared/enums/Country";
import { enumName } from "../../../../api/shared/enums/_enumName";
import { useApiCountry } from "../../../../api/shared/hooks/useApiApp";
import useUtils from "../../../hooks/useUtils";
import InputSelectEnumBase, { PropsInputSelectEnumBase } from "./InputSelectEnumBase";

export interface PropsInputSelectLocale
  extends Omit<PropsInputSelectEnumBase<AppLocale>, "options"> {
  isEdit: boolean;
  country?: Country;
}

const InputSelectLocale = (props: PropsInputSelectLocale) => {
  const [apiCountry] = useApiCountry();
  const { utils } = useUtils();
  const [appLocales] = useState(utils.enum.toIdName(enumName.AppLocale));

  useEffect(() => {
    if (props.isEdit) {
      return;
    }
    const load = async () => {
      if (!props.country) {
        return;
      }
      const defaultLocale = await apiCountry.getDefaultLocale(props.country, {
        preventSpinner: true,
      });
      props.onChange(defaultLocale);
    };
    load();
  }, [props.country]);

  return <InputSelectEnumBase {...props} options={appLocales} />;
};

export default InputSelectLocale;
