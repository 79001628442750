import { useState } from "react";
import GoogleLogin from "react-google-login";
import { GoogleAuthTokenDto } from "../../api/app/dtos/GoogleAuthTokenDto";
import { AppEnvironment } from "../../AppEnvironment";
import Button from "../../shared/components/Button/Button";
import { TypeIcon } from "../../shared/components/Icon";
import { useApiAuth } from "../../shared/hooks/useApiAuth";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";

export interface IPropsLoginGoogleButton {
  loginValidateEmail?: string;
  disabled?: boolean;
  onIsLoading(value: boolean): void;
  onError(message: string): void;
}

const GoogleLoginButton = (props: IPropsLoginGoogleButton) => {
  const [failure, setFailure] = useState("");
  const contextLoginInfo = useContextLoginInfo();
  const [apiAuth] = useApiAuth();

  const onSuccess = async (authToken: GoogleAuthTokenDto) => {
    props.onIsLoading(true);
    authToken.loginValidateEmail = props.loginValidateEmail ?? "";
    try {
      const authResponse = await apiAuth.loginGoogle(authToken, {
        preventSpinner: true,
        preventNotifications: true,
      });
      contextLoginInfo.login(authResponse.authInfo, authResponse.authToken);
    } catch (error: any) {
      props.onIsLoading(false);
      const errorMessage = error.toString().replace("Error:", "");
      props.onError(errorMessage);
    }
  };

  return (
    <GoogleLogin
      clientId={AppEnvironment.GOOGLE_CLIENT_ID}
      onSuccess={(event: any) => onSuccess(event)}
      onFailure={(err) => setFailure(err.details)}
      cookiePolicy={"single_host_origin"}
      disabled={props.disabled || !!failure}
      isSignedIn={false}
      render={(renderProps) => (
        <Button
          className="login-google-button"
          fullWidth={true}
          icon={failure ? TypeIcon.warning : TypeIcon.google}
          onClick={renderProps.onClick}
          text={"Ingresar con Google"}
          tooltip={
            failure ? "Error en la integración con el servicio de google: " + failure : undefined
          }
          disabled={renderProps.disabled || !!failure}
        />
      )}
    />
  );
};

export default GoogleLoginButton;
