import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import useUtils from "../../hooks/useUtils";
import { IdName } from "../../IdName";
import Field from "./Field";
import PropsInputBase from "./PropsInputBase";

export interface PropsInputRadioEnum extends PropsInputBase<number | null> {
  name: string;
  enumTypeName: string;
  optionValues?: any[];
  alignRow?: boolean;
}

const InputRadioEnum = (props: PropsInputRadioEnum) => {
  const { utils } = useUtils();
  const [options, setOptions] = useState<IdName[]>();

  useEffect(() => {
    const mappedOptions = props.optionValues
      ? utils.enum.valuesToIdName(props.enumTypeName, props.optionValues)
      : utils.enum.toIdName(props.enumTypeName);

    setOptions(mappedOptions);
  }, [props.optionValues]);

  if (!options) {
    return <></>;
  }

  return (
    <Field childrenProps={props}>
      <RadioGroup
        value={props.value ?? ""}
        name={props.name}
        row={props.alignRow}
        onChange={(e: any) => {
          const parsedNumber = parseFloat(e.target.value);
          if (isNaN(parsedNumber)) {
            props.onChange(null);
            return;
          }
          props.onChange(parsedNumber);
        }}>
        {options.map((option: IdName) => (
          <FormControlLabel
            checked={option.id === props.value}
            control={<Radio />}
            disabled={props.readOnly}
            label={option.name}
            key={option.id}
            value={option.id}
            style={{ marginBottom: 4 }}
          />
        ))}
      </RadioGroup>
    </Field>
  );
};

export default InputRadioEnum;
