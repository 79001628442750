import { AppThemeColor } from "../../styles/color";
import useUtils from "../hooks/useUtils";
import Icon from "./Icon";

const StatusIconColor = (props: {
  enumType: string;
  enumKey: number;
  mapper: (enumKey: number) => { icon: any; color: AppThemeColor };
}) => {
  const { utils } = useUtils();
  const iconColor = props.mapper(props.enumKey);

  return (
    <Icon
      type={iconColor.icon}
      color={iconColor.color}
      tooltip={utils.enum.translate(props.enumType, props.enumKey)}
    />
  );
};

export default StatusIconColor;
