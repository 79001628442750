// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";

export class AdminProviderSettingsDto extends BaseEntityDto {
  colorPrimary: string = "";
  colorPrimaryLight: string = "";
  colorPrimaryContrast: string = "";
  emailVerificationHandShake: string = "";
  emailSales: string = "";
  emailSupport: string = "";
  emptyImageUrl: string = "";
  logoIsoTypeUrl: string = "";
  logoIsoTypeWhiteUrl: string = "";
  logoProductNameUrl: string = "";
  logoProductNameWhiteUrl: string = "";
  logoUrl: string = "";
  logoWhiteUrl: string = "";
  storageUrl: string = "";
  whatsAppPhoneNumber: string = "";
  webUrl: string = "";
}
