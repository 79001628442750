import { forwardRef } from "react";
import { nameof } from "ts-simple-nameof";
import { ContactDto } from "../../../../api/app/dtos/ContactDto";
import InputAutocompleteContact, {
  PropsInputAutocompleteContact,
} from "../../inputs/autocomplete/InputAutocompleteContact";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormAutocompleteContact
  extends PropsFormInput<ContactDto>,
    Omit<
      PropsInputAutocompleteContact,
      "value" | "onChange" | "onCreateNewClick" | "onGetEntityById" | "onServerSideSuggest"
    > {}

const FormAutocompleteContact = forwardRef((props: PropsFormAutocompleteContact, ref?: any) => {
  const inputProps = useFormInputInit(props, ref, {
    updateIdLinkedProperty: true,
    inputTextValuePropertyName: nameof<ContactDto>((p) => p.name),
  });
  return <InputAutocompleteContact {...props} {...inputProps} />;
});

export default FormAutocompleteContact;
