import { useEffect, useState } from "react";
import { IdGuidName } from "../../../IdGuidName";
import { IdName } from "../../../IdName";
import InputSelectBase, { PropsInputSelectBase } from "./InputSelectBase";

export interface PropsInputSelectEnumBase<T> extends Omit<PropsInputSelectBase<T>, "options"> {
  options: IdName[];
}

const InputSelectEnumBase = (props: PropsInputSelectEnumBase<any>) => {
  const [options, setOptions] = useState<IdGuidName[]>([]);

  useEffect(() => {
    setOptions(props.options.map((c) => new IdGuidName(c.id.toString(), c.name)));
  }, [props.options]);

  return (
    <InputSelectBase
      {...props}
      options={options}
      onChange={(e) => {
        const parsedNumber = parseInt(e.target.value as any);
        if (isNaN(parsedNumber)) {
          props.onChange(null);
        } else {
          props.onChange(parsedNumber);
        }
      }}
    />
  );
};

export default InputSelectEnumBase;
