import { Stack } from "@mui/material";
import { FlexDirection, TextAlign } from "../../TextAlign";
import { LAYOUT_DEFAULT_SPACING } from "./LayoutConstants";

const ComponentGroup = (props: {
  align?: TextAlign;
  children: any;
  direction?: FlexDirection;
  gap?: number;
  wrap?: boolean;
}) => {
  return (
    <Stack
      justifyContent={props.align}
      direction={props.direction ?? FlexDirection.row}
      flexWrap={props.wrap ? "wrap" : undefined}
      gap={props.direction != FlexDirection.row ? props.gap ?? LAYOUT_DEFAULT_SPACING : undefined}>
      {props.children}
    </Stack>
  );
};

export default ComponentGroup;
