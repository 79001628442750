import { useEffect, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { TemplateConfigDto } from "../../api/app/dtos/TemplateConfigDto";
import { TemplateConfigEmailTestDto } from "../../api/app/dtos/TemplateConfigEmailTestDto";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormText from "../../shared/components/forms/FormText";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiTemplateConfig } from "../../api/shared/hooks/useApiApp";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import { useTemplateConfigEmailTestValidation } from "./Validations/useTemplateConfigEmailTestValidation";

const TemplateConfigEmailTestPopup = (props: { templateConfig: TemplateConfigDto }) => {
  const [templateEmailConfigTestDto] = useState<TemplateConfigEmailTestDto>(() => {
    const testDto = new TemplateConfigEmailTestDto();
    testDto.isGlobal = props.templateConfig.isGlobal;
    testDto.templateId = props.templateConfig.templateId;
    testDto.templateType = props.templateConfig.templateType;
    return testDto;
  });

  const [modal] = useModal();
  const [apiTemplateConfig] = useApiTemplateConfig();

  useEffect(() => {
    modal.setVisible("Probar envío de plantilla", ModalSize.small);
  }, [modal]);

  const send = (testDto: TemplateConfigEmailTestDto) => {
    const request = async () => {
      await apiTemplateConfig.emailConfigSendTest(testDto);
      modal.closeAsSuccess(null, "Email de prueba de plantilla enviado");
    };
    request();
  };

  return (
    <Container>
      <StateProvider
        model={templateEmailConfigTestDto}
        validationSchema={useTemplateConfigEmailTestValidation()}
        onSubmit={send}>
        {(stateProps) => (
          <>
            <Row>
              <FormText
                label="Correo"
                propertyName={nameof<TemplateConfigEmailTestDto>((p) => p.testEmailTo)}
              />
            </Row>
            <Row>
              <FormText
                label="Nombre"
                propertyName={nameof<TemplateConfigEmailTestDto>((p) => p.testEmailToName)}
              />
            </Row>
            <RowButtons>
              <ButtonAccept text="Enviar" onClick={stateProps.handleSubmit} />
            </RowButtons>
          </>
        )}
      </StateProvider>
    </Container>
  );
};

export default TemplateConfigEmailTestPopup;
