import { useEffect } from "react";
import { ModalSize } from "../../context/ModalProvider";
import { useModal } from "../../hooks/useModal";
import { TextAlign } from "../../TextAlign";
import Button from "../Button/Button";
import ButtonLink from "../Button/ButtonLink";
import Container from "../Layout/GContainer";
import Row from "../Layout/GRow";
import "./Modal.scss";

const ModalDialog = (props: {
  title?: string;
  text: string;
  helpText?: string;
  buttonNoText?: string;
  buttonNoHide?: boolean;
  buttonYesText?: string;
}) => {
  const [modal] = useModal();

  useEffect(() => {
    modal.setVisible(" ", ModalSize.dialog);
  }, [modal, props.title]);

  return (
    <Container>
      <Row align={TextAlign.center} fontSize="2em" minHeightIgnore={true}>
        {props.text}
      </Row>
      {props.helpText && (
        <Row
          align={TextAlign.center}
          fontSize="1.25em"
          minHeightIgnore={true}
          style={{ marginBottom: 10 }}>
          {props.helpText}
        </Row>
      )}
      <Row align={TextAlign.center}>
        {!props.buttonNoHide && (
          <ButtonLink
            text={props.buttonNoText ?? "No"}
            onClick={() => {
              modal.closeAsCancel();
            }}
          />
        )}
        <Button
          text={props.buttonYesText ?? "Sí"}
          onClick={() => {
            modal.closeAsSuccess(null, "");
          }}
        />
      </Row>
    </Container>
  );
};

export default ModalDialog;
