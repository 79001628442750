import { forwardRef } from "react";
import { AdminShardDto } from "../../../../api/admin/dtos/AdminShardDto";
import InputSelectAdminShard, {
  PropsInputSelectAdminShard,
} from "../../inputs/select/InputSelectAdminShard";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

interface PropsFormSelectAdminShard
  extends PropsFormInput<AdminShardDto | null>,
    Omit<PropsInputSelectAdminShard, "value" | "onChange"> {}

const FormSelectAdminShard = forwardRef((props: PropsFormSelectAdminShard, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputSelectAdminShard {...props} {...inputProps} />;
});

export default FormSelectAdminShard;
