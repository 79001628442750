// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ContactDto } from "../dtos/ContactDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { ContactFiltersDto } from "../dtos/ContactFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { ContactSimpleDto } from "../dtos/ContactSimpleDto";
import { SuggestResponseDto } from "../../shared/dtos/SuggestResponseDto";
import { SuggestSearchDto } from "../../shared/dtos/SuggestSearchDto";

export class ApiContact {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<ContactFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<ContactDto>> {
    return this.apiService.apiPost("/contacts/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<ContactDto> {
    return this.apiService.apiGet("/contacts/" + id, apiOptions);
  }

  getSimpleById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<ContactSimpleDto> {
    return this.apiService.apiGet("/contacts/simple/" + id, apiOptions);
  }

  create(
    contactDto: ContactDto,
    apiOptions?: ApiOptions
  ): Promise<ContactDto> {
    return this.apiService.apiPost("/contacts", contactDto, apiOptions);
  }

  edit(
    contactDto: ContactDto,
    apiOptions?: ApiOptions
  ): Promise<ContactDto> {
    return this.apiService.apiPut("/contacts", contactDto, apiOptions);
  }

  suggestAll(
    suggestSearch: SuggestSearchDto<ContactFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/contacts/suggest-all", suggestSearch, apiOptions);
  }

  suggestCustomers(
    suggestSearch: SuggestSearchDto<ContactFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/contacts/suggest-customers", suggestSearch, apiOptions);
  }

  suggestProviders(
    suggestSearch: SuggestSearchDto<ContactFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/contacts/suggest-providers", suggestSearch, apiOptions);
  }

}
