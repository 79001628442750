import { useEffect, useState } from "react";
import { AdminTemplateDesignEmailTestDto } from "../../../api/admin/dtos/AdminTemplateDesignEmailTestDto";
import { AdminTemplateDesignPdfTestDto } from "../../../api/admin/dtos/AdminTemplateDesignPdfTestDto";
import { AdminTemplateDesignTestDto } from "../../../api/admin/dtos/AdminTemplateDesignTestDto";
import { AdminTemplateDto } from "../../../api/admin/dtos/AdminTemplateDto";
import { AdminTemplateEmailDto } from "../../../api/admin/dtos/AdminTemplateEmailDto";
import { AdminTemplatePdfDto } from "../../../api/admin/dtos/AdminTemplatePdfDto";
import { ApiOptions } from "../../../api/shared/apis/ApiOptions";
import { TemplateGroup } from "../../../api/shared/enums/TemplateGroup";
import { useApiAdminAccountPublicTemplateDesign } from "../../../api/shared/hooks/useApiAdmin";
import { useModal } from "../../hooks/useModal";
import { useContextModelState } from "../../modelState/useContextModelState";
import { templateGetGroup, templateTypeIsGeneric } from "../../utils/utilTemplate";
import ButtonAccept from "../Button/ButtonAccept";
import ButtonIcon from "../Button/ButtonIcon";
import ButtonSecondary from "../Button/ButtonSecondary";
import { TypeIcon } from "../Icon";
import InfoText, { InfoTextType } from "../InfoText";
import ComponentGroup from "../Layout/ComponentsGroup";
import Row from "../Layout/GRow";
import {
  LAYOUT_DEFAULT_SPACING,
  LAYOUT_SCROLLABLE_AREA_LARGE_MIN_HEIGHT,
  LAYOUT_SPACING_PIXELS,
} from "../Layout/LayoutConstants";
import TemplateEditorPreviewFrame from "./TemplateEditorPreview.Frame";
import TemplateEditorPreviewPdfFrame from "./TemplateEditorPreview.PdfViewer";
import TemplateEditorSendEmailTestPopup from "./TemplateEditorSendEmailTestPopup";

const TemplateEditorPreview = (props: { previewJsonData?: string; onCollapse: () => void }) => {
  const modelState = useContextModelState();
  const template = modelState.model as AdminTemplateDto;
  const templateGroup = templateGetGroup(template.type);
  const [currentPreviewTemplate, setCurrentPreviewTemplate] = useState(template);
  const [currentPreviewJsonData, setCurrentPreviewJsonData] = useState(props.previewJsonData);
  const [modal] = useModal();
  const [htmlPreview, setHtmlPreview] = useState("");
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState("");
  const [showPdfPreviewFrame, setShowPdfPreviewFrame] = useState(
    templateGroup == TemplateGroup.Pdf && !templateTypeIsGeneric(template.type)
  );
  const [apiAdminTemplateDesign] = useApiAdminAccountPublicTemplateDesign();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState(false);

  const getDesignTestDto = () => {
    let designTestDto: AdminTemplateDesignTestDto;
    if (templateGroup == TemplateGroup.Pdf) {
      designTestDto = new AdminTemplateDesignPdfTestDto();
      (designTestDto as AdminTemplateDesignPdfTestDto).templatePdf =
        template as AdminTemplatePdfDto;
    } else {
      designTestDto = new AdminTemplateDesignEmailTestDto();
      (designTestDto as AdminTemplateDesignEmailTestDto).templateEmail =
        template as AdminTemplateEmailDto;
    }
    designTestDto.template = template;
    designTestDto.previewJsonData = props.previewJsonData ?? "";
    designTestDto.templateGroup = templateGroup;

    return designTestDto;
  };

  const preview = async () => {
    setIsLoading(true);

    const designTestDto = getDesignTestDto();
    setCurrentPreviewTemplate(template);
    setCurrentPreviewJsonData(props.previewJsonData);

    const apiOptions: ApiOptions = {
      preventSpinner: true,
      preventNotifications: true,
    };

    if (showPdfPreviewFrame) {
      try {
        const response = await apiAdminTemplateDesign.previewPdfFile(
          designTestDto as AdminTemplateDesignPdfTestDto,
          apiOptions
        );
        setPdfPreviewUrl(response.url);
        setShouldRefresh(false);
        setErrorMessage("");
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        setErrorMessage(error?.message ?? "Error");
      }
      return;
    }

    try {
      let html = "";
      if (templateGroup == TemplateGroup.Pdf) {
        const response = await apiAdminTemplateDesign.previewPdfContent(
          designTestDto as AdminTemplateDesignPdfTestDto,
          apiOptions
        );
        html = response.bodyHtml;
      } else {
        const responseEmail = await apiAdminTemplateDesign.previewEmail(
          designTestDto as AdminTemplateDesignEmailTestDto,
          apiOptions
        );
        html = responseEmail.bodyHtml;
      }

      setHtmlPreview(html);
      setShouldRefresh(false);
      setErrorMessage("");
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error?.message ?? "Error");
    }
  };

  const downloadPdf = () => {
    apiAdminTemplateDesign.pdfTestDownload(getDesignTestDto() as AdminTemplateDesignPdfTestDto);
  };

  const sendTestEmail = () => {
    modal.open(
      <TemplateEditorSendEmailTestPopup
        designTestDto={getDesignTestDto() as AdminTemplateDesignEmailTestDto}
      />
    );
  };

  useEffect(() => {
    preview();
  }, [showPdfPreviewFrame]);

  useEffect(() => {
    if (
      props.previewJsonData !== currentPreviewJsonData ||
      template.body !== currentPreviewTemplate.body ||
      template.styles !== currentPreviewTemplate.styles
    ) {
      setShouldRefresh(true);
    }
  }, [props.previewJsonData, template]);

  useEffect(() => {
    if (autoRefresh) {
      preview();
      setAutoRefresh(false);
    }
  }, [autoRefresh]);

  return (
    <>
      <Row>
        {templateTypeIsGeneric(template.type) && (
          <InfoText>
            Aquí se muestra vista previa de como se verá la plantilla genérica. Te recomendamos
            hacer pruebas utilizándola en otra plantilla
          </InfoText>
        )}
        {!templateTypeIsGeneric(template.type) && (
          <>
            {templateGroup == TemplateGroup.Pdf && (
              <InfoText>
                Aquí puedes ver una vista previa de como se generará el Pdf. Probá haciendo click en
                el botón <strong>descargar pdf</strong> para obtener una vista más exacta al
                resultado final
              </InfoText>
            )}
            {templateGroup == TemplateGroup.Email && (
              <InfoText>
                Aquí puedes ver una vista previa del correo. Probá haciendo click en{" "}
                <strong>enviar correo de prueba</strong> para ver el resultado final
              </InfoText>
            )}
          </>
        )}
      </Row>
      <Row
        style={{
          position: "relative",
          height:
            LAYOUT_SPACING_PIXELS * LAYOUT_DEFAULT_SPACING +
            LAYOUT_SCROLLABLE_AREA_LARGE_MIN_HEIGHT,
        }}>
        {errorMessage && <InfoText type={InfoTextType.error}>{errorMessage}</InfoText>}
        {!errorMessage && (
          <>
            {!showPdfPreviewFrame && (
              <TemplateEditorPreviewFrame html={htmlPreview} isLoading={isLoading} />
            )}
            {showPdfPreviewFrame && (
              <TemplateEditorPreviewPdfFrame fileUrl={pdfPreviewUrl} isLoading={isLoading} />
            )}
          </>
        )}
      </Row>
      <Row>
        <ComponentGroup>
          <ButtonIcon
            icon={TypeIcon.chevronRight}
            onClick={props.onCollapse}
            tooltip="Ocultar panel de vista previa"
          />
          {templateGroup == TemplateGroup.Pdf && (
            <ButtonSecondary
              icon={TypeIcon.download}
              text="Descargar Pdf"
              onClick={() => downloadPdf()}
            />
          )}
          {templateGroup == TemplateGroup.Email && !templateTypeIsGeneric(template.type) && (
            <ButtonSecondary
              icon={TypeIcon.email}
              text="Enviar correo de prueba"
              onClick={() => sendTestEmail()}
            />
          )}
          {showPdfPreviewFrame && (
            <ButtonSecondary
              text="Vista Html"
              icon={TypeIcon.html}
              onClick={() => setShowPdfPreviewFrame(false)}
            />
          )}
          {templateGroup == TemplateGroup.Pdf && !templateTypeIsGeneric(template.type) && (
            <>
              {!showPdfPreviewFrame && (
                <ButtonSecondary
                  text="Vista Pdf"
                  icon={TypeIcon.pdf}
                  onClick={() => setShowPdfPreviewFrame(true)}
                />
              )}
            </>
          )}
          {shouldRefresh && (
            <ButtonAccept
              icon={TypeIcon.refresh}
              text="Actualizar vista previa"
              onClick={() => preview()}
            />
          )}
          {!shouldRefresh && (
            <ButtonSecondary
              icon={TypeIcon.refresh}
              text="Actualizar vista previa"
              onClick={() => preview()}
            />
          )}
        </ComponentGroup>
      </Row>
    </>
  );
};

export default TemplateEditorPreview;
