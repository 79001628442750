import { nameof } from "ts-simple-nameof";
import { SaleReferenceDto } from "../../api/app/dtos/SaleReferenceDto";
import FormText from "../../shared/components/forms/FormText";
import { ISaleReferenceColumnRenderProps } from "./SaleFormReferences";

const SaleFormReferenceColumnSerie = (props: ISaleReferenceColumnRenderProps) => {
  return (
    <FormText
      arrayItemHelper={props.arrayItemHelper}
      label={props.showLabel ? props.saleReferenceColumn.header : ""}
      propertyName={nameof<SaleReferenceDto>((p) => p.serie)}
      variant={props.variant}
    />
  );
};

export default SaleFormReferenceColumnSerie;
