import { useEffect, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminTemplateDesignDto } from "../../../api/admin/dtos/AdminTemplateDesignDto";
import { AdminTemplateEmailDto } from "../../../api/admin/dtos/AdminTemplateEmailDto";
import { ButtonAccept } from "../../../shared/components/Button/ButtonAccept";
import Row from "../../../shared/components/Layout/GRow";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import TemplateEditorEmailInfo from "../../../shared/components/Templates/TemplateEditorEmailInfo";
import TemplateEditorInfoSection from "../../../shared/components/Templates/TemplateEditorInfoSection";
import TemplateEditorMain from "../../../shared/components/Templates/TemplateEditorMain";
import { useApiAdminAccountPublicTemplateDesign } from "../../../api/shared/hooks/useApiAdmin";
import { useContextModelState } from "../../../shared/modelState/useContextModelState";

const AdminTemplateEmailForm = (props: { onSave(): void }) => {
  const [apiTemplateDesign] = useApiAdminAccountPublicTemplateDesign();
  const [designDto, setDesignDto] = useState<AdminTemplateDesignDto>();
  const modelState = useContextModelState();
  const template = modelState.model as AdminTemplateEmailDto;

  useEffect(() => {
    const loadType = async () => {
      const design = await apiTemplateDesign.getTemplateDesign(template.type);
      setDesignDto(design);
    };
    loadType();
  }, []);

  if (!designDto) {
    return <></>;
  }

  return (
    <>
      <Row>
        <TemplateEditorInfoSection />
      </Row>
      <Row>
        <TemplateEditorEmailInfo
          attachments={template.attachments}
          designDto={designDto}
          onAttachmentChange={(value: string) => {
            modelState.updateProperty(
              nameof<AdminTemplateEmailDto>((p) => p.attachments),
              value
            );
          }}
        />
      </Row>
      <TemplateEditorMain />
      <RowButtons>
        <ButtonAccept onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default AdminTemplateEmailForm;
