import { nameof } from "ts-simple-nameof";
import { AdminProviderDto } from "../../api/admin/dtos/AdminProviderDto";
import FormText from "../../shared/components/forms/FormText";
import Column from "../../shared/components/Layout/GColumn";

const AdminProviderFormStyles = () => {
  return (
    <>
      <Column>
        <FormText
          label="Color primario"
          propertyName={nameof<AdminProviderDto>((p) => p.providerSettings.colorPrimary)}
        />
      </Column>
      <Column>
        <FormText
          label="Color primario claro"
          propertyName={nameof<AdminProviderDto>((p) => p.providerSettings.colorPrimaryLight)}
        />
      </Column>
      <Column>
        <FormText
          label="Color contraste"
          propertyName={nameof<AdminProviderDto>((p) => p.providerSettings.colorPrimaryContrast)}
        />
      </Column>
    </>
  );
};

export default AdminProviderFormStyles;
