import { AdminProviderDto } from "../../api/admin/dtos/AdminProviderDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import { ModalService } from "../../shared/hooks/useModal";
import { Navigation } from "../../shared/hooks/useNavigation";
import AdminProviderDetailsPopup from "./AdminProviderDetailsPopup";
import AdminProviderEditPopup from "./AdminProviderEditPopup";
import AdminProviderEmailConfigPopup from "./AdminProviderEmailConfig/AdminProviderEmailConfigPopup";
import AdminProviderLinkUserPopup from "./AdminProviderLinkUserPopup";

export default function getAdminProviderListItemMenu(
  adminProviderDto: AdminProviderDto,
  modal: ModalService,
  navigation: Navigation,
  onGridReload: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Configurar SMTP",
      icon: TypeIcon.email,
      onClick: () =>
        modal.open(
          <AdminProviderEmailConfigPopup adminProviderId={adminProviderDto.id} />,
          onGridReload
        ),
    },
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () =>
        modal.open(<AdminProviderDetailsPopup id={adminProviderDto.id} />, onGridReload),
    },
    {
      text: "Editar",
      icon: TypeIcon.edit,
      onClick: () => modal.open(<AdminProviderEditPopup id={adminProviderDto.id} />, onGridReload),
    },
    {
      text: "Vincular usuario",
      icon: TypeIcon.user,
      onClick: () =>
        modal.open(
          <AdminProviderLinkUserPopup adminProviderId={adminProviderDto.id} />,
          onGridReload
        ),
    },
  ];
}
