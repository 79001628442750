import { useEffect, useState } from "react";
import { EmailConfigDto } from "../../../api/app/dtos/EmailConfigDto";
import Container from "../../../shared/components/Layout/GContainer";
import { ModalSize } from "../../../shared/context/ModalProvider";
import { useApiEmailConfig } from "../../../api/shared/hooks/useApiApp";
import { useModal } from "../../../shared/hooks/useModal";
import { StateProvider } from "../../../shared/modelState/ModelStateContext";
import ConfigEmailForm from "./ConfigEmailForm";
import { EmailConfigViewModel } from "./EmailConfigViewModel";
import { useEmailConfigValidations } from "./Validations/useEmailConfigValidations";

const ConfigEmailEditPopup = (props: { id: string }) => {
  const [emailConfig, setEmailConfig] = useState<EmailConfigViewModel>();
  const [modal] = useModal();
  const [apiEmailConfig] = useApiEmailConfig();

  const save = async (emailConfig: EmailConfigDto) => {
    const created = await apiEmailConfig.edit(emailConfig);
    modal.closeAsSuccess(created, "La configuración de correo se creó con éxito");
  };

  useEffect(() => {
    const request = async () => {
      setEmailConfig((await apiEmailConfig.getById(props.id)) as EmailConfigViewModel);
      modal.setVisible("Editar configuración de correo", ModalSize.normal);
    };
    request();
  }, []);

  return (
    <Container>
      {emailConfig && (
        <StateProvider
          model={emailConfig}
          onSubmit={save}
          validationSchema={useEmailConfigValidations()}>
          {(p) => <ConfigEmailForm onSubmit={p.handleSubmit} />}
        </StateProvider>
      )}
    </Container>
  );
};

export default ConfigEmailEditPopup;
