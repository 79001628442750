// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AccountBranchDto } from "../../app/dtos/AccountBranchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminProviderAccessAccountBranch {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getById(
    adminAccountId: string,
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AccountBranchDto> {
    return this.apiService.apiGet("/admin-provider/access/account-branches/" + adminAccountId + "/" + id, apiOptions);
  }

  create(
    adminAccountId: string,
    accountBranchDto: AccountBranchDto,
    apiOptions?: ApiOptions
  ): Promise<AccountBranchDto> {
    return this.apiService.apiPost("/admin-provider/access/account-branches/" + adminAccountId, accountBranchDto, apiOptions);
  }

  edit(
    adminAccountId: string,
    accountBranchDto: AccountBranchDto,
    apiOptions?: ApiOptions
  ): Promise<AccountBranchDto> {
    return this.apiService.apiPut("/admin-provider/access/account-branches/" + adminAccountId, accountBranchDto, apiOptions);
  }

  inactivate(
    adminAccountId: string,
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/access/account-branches/inactivate/" + adminAccountId + "/" + id, null, apiOptions);
  }

  reactivate(
    adminAccountId: string,
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/access/account-branches/reactivate/" + adminAccountId + "/" + id, null, apiOptions);
  }

}
