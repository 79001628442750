import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import { ModalService } from "../../shared/hooks/useModal";
import PurchaseDetailsPopup from "./PurchaseDetailsPopup";

export function getPurchaseListItemMenu(
  purchaseDto: PurchaseDto,
  modal: ModalService,
  onReloadGrid: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        modal.open(<PurchaseDetailsPopup purchaseId={purchaseDto.id} />, onReloadGrid);
      },
    },
  ];
}
