import { forwardRef } from "react";
import InputSelectEnumMultiple, {
  PropsInputSelectEnumMultiple,
} from "../../inputs/select/InputSelectEnumMultiple";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormSelectEnumMultiple
  extends PropsFormInput<number[] | null>,
    Omit<PropsInputSelectEnumMultiple, "value" | "onChange"> {}

const FormSelectEnumMultiple = forwardRef((props: PropsFormSelectEnumMultiple, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);

  return <InputSelectEnumMultiple {...props} {...inputProps} />;
});

export default FormSelectEnumMultiple;
