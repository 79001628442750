// cSpell:ignore hubccv, sgnp, cjanal, googleusercontent
import { IEnvironment } from "./api/shared/interfaces/IEnvironment";

export const AppEnvironment = {
  ENVIRONMENT: process.env.REACT_APP_ENV,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  GOOGLE_CLIENT_ID:
    "673119107348-3c2ab6ild56hubccv6sgnp74c7cjanal.apps.googleusercontent.com",
  GOOGLE_SECRET_ID: "981y-rWk6Iw5F5ZiCAaYTwS5",
  REACT_APP_GRID_PAGE_SIZE: +(process.env.REACT_APP_GRID_PAGE_SIZE ?? 10),
} as IEnvironment;
