import { useState } from "react";
import { Collapse } from "@mui/material";
import { AppThemeColor } from "../../../styles/color";
import { useContextModelState } from "../../modelState/useContextModelState";
import Icon, { TypeIcon } from "../Icon";
import ComponentGroup from "../Layout/ComponentsGroup";
import Row from "../Layout/GRow";
import Section from "../Layout/GSection";

const FormValidationSummary = () => {
  const [showErrors, setShowErrors] = useState(false);
  const modelState = useContextModelState();

  if (!modelState.validation?.submitSummary || modelState.validation.submitSummary.length == 0) {
    return null;
  }

  return (
    <>
      <Row fontColor={AppThemeColor.alert} fontSize="1.25em" paddingTop={2} minHeightIgnore={true}>
        <ComponentGroup>
          <Icon type={TypeIcon.info} color={AppThemeColor.alert} style={{ flex: 0 }} />
          <div
            style={{ marginTop: 4, cursor: "pointer", flex: 1 }}
            onClick={() => setShowErrors(!showErrors)}>
            <span style={{ textDecoration: "underline" }}>Click</span> para{" "}
            {showErrors ? "ocultar" : "ver"} la lista de errores
          </div>
        </ComponentGroup>
      </Row>
      <Row paddingTop={showErrors ? 2 : 0} minHeightIgnore={true}>
        <Collapse in={showErrors}>
          <Section spacing={1} background={AppThemeColor.grayLight}>
            {modelState.validation.submitSummary.map((message: string, index: number) => (
              <Row key={index} fontColor={AppThemeColor.alert} minHeightIgnore={true}>
                * {message}
              </Row>
            ))}
          </Section>
        </Collapse>
      </Row>
    </>
  );
};

export default FormValidationSummary;
