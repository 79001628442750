// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { LocaleConfigSamplesDto } from "../dtos/LocaleConfigSamplesDto";

export class ApiLocale {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getCurrent(
    apiOptions?: ApiOptions
  ): Promise<string> {
    return this.apiService.apiGet("/locale/current", apiOptions);
  }

  getSamples(
    locale: number,
    apiOptions?: ApiOptions
  ): Promise<LocaleConfigSamplesDto> {
    return this.apiService.apiGet("/locale/samples?" + 
      (locale ? "&locale=" + locale : ""), apiOptions);
  }

}
