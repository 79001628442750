import { CSSProperties, MutableRefObject } from "react";
import { useIsMobile } from "../../shared/hooks/useIsMobile";
import { ModelStateArrayItemHelper } from "../../shared/modelState/ModelStateArray";
import { TextAlign } from "../../shared/TextAlign";
import { ThemeVariant } from "../../shared/ThemeVariant";
import { ISaleLineColumn, ISaleLineColumnRenderProps } from "./SaleFormLines";

const SaleLineColumn = (props: {
  arrayItemHelper: ModelStateArrayItemHelper;
  isEditing: boolean;
  quantityRef: MutableRefObject<HTMLInputElement | undefined>;
  saleLineColumn: ISaleLineColumn;
  showLabel: boolean;
  variant: ThemeVariant;
}) => {
  const isMobile = useIsMobile();
  const style: CSSProperties = {
    flex: props.saleLineColumn.width,
    textAlign: isMobile ? TextAlign.left : props.saleLineColumn.align,
  };

  const renderProps: ISaleLineColumnRenderProps = {
    arrayItemHelper: props.arrayItemHelper,
    isEditing: props.isEditing,
    quantityRef: props.quantityRef,
    saleLine: props.arrayItemHelper.item,
    saleLineColumn: props.saleLineColumn,
    variant: props.variant,
    showLabel: props.showLabel,
  };

  if (!props.isEditing && props.showLabel) {
    return (
      <div style={style}>
        <div>{props.saleLineColumn.header} </div>
        <div>
          <strong>{props.saleLineColumn.render(renderProps)}</strong>
        </div>
      </div>
    );
  }

  return <div style={style}>{props.saleLineColumn.render(renderProps)}</div>;
};

export default SaleLineColumn;
