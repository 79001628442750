import { nameof } from "ts-simple-nameof";
import { AdminTemplatePdfDto } from "../../../../api/admin/dtos/AdminTemplatePdfDto";
import { validate } from "../../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../../shared/validation/schema/ValidationSchema";

export function useAdminTemplatePdfValidation() {
  const schema = new ValidationSchema();

  schema[nameof<AdminTemplatePdfDto>((p) => p.type)] = validate("Tipo").required();
  schema[nameof<AdminTemplatePdfDto>((p) => p.name)] = validate("Nombre").required();

  return schema;
}
