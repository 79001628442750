import { useContext } from "react";
import { LocaleContext } from "../context/LocaleProvider";

export function useContextLocale() {
  const { appLocaleState, dispatchAppLocale } = useContext(LocaleContext);

  return {
    appLocaleState,
    dispatchAppLocale,
  };
}
