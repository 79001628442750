// File generated by: Gesta.Build.PostEvents
import { useState } from "react";
import { useApi } from "../../../shared/hooks/useApi";
import { ApiAdminAccessAccountBranch } from "../../admin/apis/ApiAdminAccessAccountBranch";
import { ApiAdminAccessPointOfSale } from "../../admin/apis/ApiAdminAccessPointOfSale";

export function useApiAdminAccessAccountBranch() {
  return useState(new ApiAdminAccessAccountBranch(useApi()));
}

export function useApiAdminAccessPointOfSale() {
  return useState(new ApiAdminAccessPointOfSale(useApi()));
}

