// File generated by: Gesta.Build.PostEvents
export enum StorageContainerType {
	Images = 1,
	SalesPdf = 2,
	SalesXml = 3,
	EInvoiceEnvelopes = 4,
	EInvoiceAuthorizations = 5,
	EInvoiceDailyReports = 6,
	EInvoiceSalesCompanySent = 7,
	EInvoicePurchases = 8,
	EInvoiceEnvelopesAck = 9,
	EInvoiceRejectionNotifications = 10,
	CertificatesBackups = 11,
	ExportExcel = 100,
	EInvoiceWsLogs = 10000,
}
