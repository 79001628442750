import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import TabGroup from "../../shared/components/Layout/TabGroup";
import TabView from "../../shared/components/Layout/TabView";
import PurchaseDetailsAuthorization from "./PurchaseDetails.Authorization";
import PurchaseDetailsDiscounts from "./PurchaseDetails.Discounts";
import PurchaseDetailsHeader from "./PurchaseDetails.Header";
import PurchaseDetailsMoreInfo from "./PurchaseDetails.MoreInfo";
import PurchaseDetailsPaymentInfo from "./PurchaseDetails.PaymentInfo";
import PurchaseDetailsReceiver from "./PurchaseDetails.Receiver";
import PurchaseDetailsReferences from "./PurchaseDetails.References";
import PurchaseDetailsRetentions from "./PurchaseDetails.Retentions";
import PurchaseDetailsSender from "./PurchaseDetails.Sender";
import PurchaseDetailsSubtotals from "./PurchaseDetails.Subtotals";
import PurchaseDetailsSurcharges from "./PurchaseDetails.Surcharges";

const TABS = {
  GENERAL: "GENERAL",
  AUTHORIZATION: "AUTHORIZATION",
  DISCOUNTS: "DISCOUNTS",
  SURCHARGES: "SURCHARGES",
  SENDER: "SENDER",
  PAYMENT: "PAYMENT",
  RECEIVER: "RECEIVER",
  RETENTIONS: "RETENTIONS",
  REFERENCES: "REFERENCES",
  SUBTOTALS: "SUBTOTALS",
  MORE_INFO: "MORE_INFO",
};

const PurchaseDetails = (props: { purchase: PurchaseDto }) => {
  const purchase = props.purchase;

  const tabs = [
    { title: "General", value: TABS.GENERAL },
    { title: "Pago", value: TABS.PAYMENT },
    { title: "Referencias", value: TABS.REFERENCES },
    { title: "Descuentos", value: TABS.DISCOUNTS },
    { title: "Autorización", value: TABS.AUTHORIZATION },
    { title: "Emisor", value: TABS.SENDER },
    { title: "Receptor", value: TABS.RECEIVER },
    { title: "Retenciones", value: TABS.RETENTIONS },
    { title: "Subtotales", value: TABS.SUBTOTALS },
    { title: "Más info", value: TABS.MORE_INFO },
  ];

  return (
    <TabGroup tabs={tabs} selectedTab={TABS.GENERAL}>
      <TabView value={TABS.GENERAL}>
        <PurchaseDetailsHeader purchase={purchase} />
      </TabView>
      <TabView value={TABS.PAYMENT}>
        <PurchaseDetailsPaymentInfo purchase={purchase} />
      </TabView>
      <TabView value={TABS.REFERENCES}>
        <PurchaseDetailsReferences purchase={purchase} />
      </TabView>
      <TabView value={TABS.DISCOUNTS}>
        <PurchaseDetailsDiscounts purchase={purchase} />
      </TabView>
      <TabView value={TABS.SURCHARGES}>
        <PurchaseDetailsSurcharges purchase={purchase} />
      </TabView>
      <TabView value={TABS.AUTHORIZATION}>
        <PurchaseDetailsAuthorization purchase={purchase} />
      </TabView>
      <TabView value={TABS.SENDER}>
        <PurchaseDetailsSender purchase={purchase} />
      </TabView>
      <TabView value={TABS.RECEIVER}>
        <PurchaseDetailsReceiver purchase={purchase} />
      </TabView>
      <TabView value={TABS.RETENTIONS}>
        <PurchaseDetailsRetentions purchase={purchase} />
      </TabView>
      <TabView value={TABS.SUBTOTALS}>
        <PurchaseDetailsSubtotals purchase={purchase} />
      </TabView>
      <TabView value={TABS.MORE_INFO}>
        <PurchaseDetailsMoreInfo purchase={purchase} />
      </TabView>
    </TabGroup>
  );
};

export default PurchaseDetails;
