import { AdminRetentionDto } from "../../api/admin/dtos/AdminRetentionDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import { ModalService } from "../../shared/hooks/useModal";
import AdminRetentionEditPopup from "./AdminRetentionEditPopup";

export default function getAdminRetentionListItemMenu(
  adminRetentionDto: AdminRetentionDto,
  modal: ModalService,
  onReloadGrid: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Editar",
      icon: TypeIcon.edit,
      onClick: () => {
        modal.open(
          <AdminRetentionEditPopup adminRetentionId={adminRetentionDto.id} />,
          onReloadGrid
        );
      },
    },
  ];
}
