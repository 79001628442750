import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import enUsLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import { PropsWithChildren } from "react";
import { AppLocale } from "../../api/shared/enums/AppLocale";
import { useContextLoginInfo } from "../hooks/useContextLoginInfo";

const localeMap: any = {
  EsCo: esLocale,
  EsUy: esLocale,
  EnUs: enUsLocale,
};

function getLocale(locale: AppLocale) {
  if (!locale) {
    return localeMap.EsUy;
  }

  const localeName = AppLocale[locale];
  return localeMap[localeName] ? localeMap[localeName] : localeMap.EsUy;
}

const LocaleDateProvider: React.FC<
  PropsWithChildren<{
    children: any;
  }>
> = ({ children }: any) => {
  const { loginInfo } = useContextLoginInfo();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={getLocale(loginInfo?.authInfo?.adminAccount?.locale)}>
      {children}
    </LocalizationProvider>
  );
};

export default LocaleDateProvider;
