import { useIsMobile } from "../../hooks/useIsMobile";
import GridFiltersDesktop from "./GridFilters.Desktop";
import GridFiltersMobile from "./GridFilters.Mobile";

const GridFilters = (props: {
  hideIncludeInactiveButton: boolean;
  preventShowDefaultSearch: boolean;
  onApplyFilters: () => void;
}) => {
  const isMobile = useIsMobile();

  if (props.preventShowDefaultSearch) {
    return <></>;
  }

  return (
    <>
      {isMobile ? (
        <GridFiltersMobile
          hideIncludeInactiveButton={props.hideIncludeInactiveButton}
          preventShowDefaultSearch={props.preventShowDefaultSearch}
          onApplyFilters={props.onApplyFilters}
        />
      ) : (
        <GridFiltersDesktop
          preventShowDefaultSearch={props.preventShowDefaultSearch}
          onApplyFilters={props.onApplyFilters}
        />
      )}
    </>
  );
};

export default GridFilters;
