import { useEffect, useState } from "react";
import { AdminPointOfSaleClientDto } from "../../api/admin/dtos/AdminPointOfSaleClientDto";
import { useApiAdminPointOfSaleClient } from "../../api/shared/hooks/useApiAdmin";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useModal } from "../../shared/hooks/useModal";

const AdminPointOfSaleClientDetailsPopup = (props: { id: string }) => {
  const [modal] = useModal();
  const [apiAdminPointOfSaleClient] = useApiAdminPointOfSaleClient();
  const [pointOfSaleClient, setPointOfSaleClient] = useState<AdminPointOfSaleClientDto>();

  useEffect(() => {
    const load = async () => {
      setPointOfSaleClient(await apiAdminPointOfSaleClient.getById(props.id));
      modal.setVisible("Detalles de cliente Pos", ModalSize.normal);
    };
    load();
  }, []);

  if (!pointOfSaleClient) {
    return <></>;
  }

  return (
    <Container>
      <ColumnDisplay md={3} label="Cuenta">
        {pointOfSaleClient.account.name}
      </ColumnDisplay>
      <ColumnDisplay md={3} label="Token">
        {pointOfSaleClient.token}
      </ColumnDisplay>
      <ColumnDisplay md={3} label="Id de conexión actual ">
        {pointOfSaleClient.currentConnectionId}
      </ColumnDisplay>
    </Container>
  );
};

export default AdminPointOfSaleClientDetailsPopup;
