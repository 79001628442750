import "./Login.scss";
import LoginRow from "./LoginRow";

const LoginTitle = (props: { mobileTop?: boolean; children: any }) => {
  return (
    <LoginRow
      className={
        "login-layout-row-title " +
        (props.mobileTop ? "login-layout-row-title-mobile-top" : "")
      }>
      {props.children}
    </LoginRow>
  );
};

export default LoginTitle;
