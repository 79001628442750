import { Collapse } from "@mui/material";
import { useState } from "react";
import { AdminLoggingListQueryParams } from "../../admin/AdminLogging/AdminLogViewer";
import { AppNotificationDto } from "../../api/app/dtos/AppNotificationDto";
import { AppNotificationType } from "../../api/shared/enums/AppNotificationType";
import { useApiAppNotifications } from "../../api/shared/hooks/useApiApp";
import { AppRoute } from "../../AppRoutes";
import { useAppTheme } from "../../AppThemeProvider";
import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import Icon, { TypeIcon } from "../../shared/components/Icon";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { useNotifier } from "../../shared/hooks/useNotifier";
import useUtils from "../../shared/hooks/useUtils";
import { DateFormatType } from "../../shared/utils/localizedUtil/localizedUtilDates";
import { AppThemeColor } from "../../styles/color";

const AppNotificationHeaderListItem = (props: {
  appNotification: AppNotificationDto;
  isSuperAdmin: boolean;
  onSetAsRead: () => void;
}) => {
  const appTheme = useAppTheme();
  let iconColor = AppThemeColor.success;
  let backgroundColor = appTheme.toHexColor(AppThemeColor.successLight);
  let icon = TypeIcon.bell;
  const { utils } = useUtils();
  const navigation = useNavigation();
  const [expanded, setExpanded] = useState(true);
  const [apiAppNotifications] = useApiAppNotifications();
  const notifier = useNotifier();

  switch (props.appNotification.type) {
    case AppNotificationType.Alert:
      iconColor = AppThemeColor.alert;
      backgroundColor = appTheme.toHexColor(AppThemeColor.alertLight);
      icon = TypeIcon.error;
      break;
    case AppNotificationType.Warning:
      iconColor = AppThemeColor.warning;
      backgroundColor = appTheme.toHexColor(AppThemeColor.warningLight);
      icon = TypeIcon.warning;
      break;
    case AppNotificationType.Info:
      iconColor = AppThemeColor.info;
      backgroundColor = appTheme.toHexColor(AppThemeColor.infoLight);
      icon = TypeIcon.info;
      break;
  }

  const setAsRead = () => {
    setExpanded(false);
    const request = async () => {
      await apiAppNotifications.setAsRead(props.appNotification.id, {
        preventNotifications: true,
        preventSpinner: true,
      });
      props.onSetAsRead();
    };
    request();
  };

  const showTraceId =
    props.appNotification.traceId && props.appNotification.type == AppNotificationType.Alert;

  return (
    <Collapse in={expanded}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          background: backgroundColor,
          width: "100%",
          borderRadius: 6,
          marginBottom: 15,
          padding: 15,
        }}>
        <div style={{ flex: 0 }}>
          <Icon type={icon} style={{ marginRight: 15 }} color={iconColor} />
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ fontSize: "1.3em" }}>
            <strong>{props.appNotification.title}</strong>
          </div>
          <div style={{ fontSize: "1.1em" }}>
            {utils.date.toString(props.appNotification.createDate, DateFormatType.DateHourMinutes)}
          </div>
          {showTraceId && (
            <div
              style={{
                color: appTheme.toHexColor(AppThemeColor.gray),
                cursor: props.isSuperAdmin ? "pointer" : "",
              }}
              onClick={(e) => {
                if (!props.isSuperAdmin) {
                  navigator.clipboard.writeText(props.appNotification.traceId);
                  notifier.showSuccessFast("Trace ID copiado al portapapeles");
                  return;
                }
                e.preventDefault();
                navigation.newTab(AppRoute.admin.logViewer, {
                  traceId: props.appNotification.traceId,
                } as AdminLoggingListQueryParams);
              }}>
              <strong>{props.appNotification.traceId}</strong>
            </div>
          )}
          <div
            style={{ overflow: "hidden", wordBreak: "break-word", marginTop: 15 }}
            dangerouslySetInnerHTML={{ __html: props.appNotification.body }}></div>
        </div>
        <div>
          <ButtonIcon noPadding={true} icon={TypeIcon.delete} onClick={setAsRead} />
        </div>
      </div>
    </Collapse>
  );
};

export default AppNotificationHeaderListItem;
