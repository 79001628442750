import { InputAdornment, TextField } from "@mui/material";
import React, { CSSProperties, useContext } from "react";
import { IconType } from "react-icons";
import { Constants } from "../../../Constants";
import { AppThemeColor } from "../../../styles/color";
import { AppInfoContext } from "../../context/AppInfoProvider";
import { ThemeSize } from "../../ThemeSize";
import { ThemeVariant } from "../../ThemeVariant";
import ButtonIcon from "../Button/ButtonIcon";
import Icon, { TypeIcon } from "../Icon";
import {
  LAYOUT_HINT_MARGIN_BOTTOM_FIX,
  LAYOUT_HINT_PADDING_BOTTOM,
} from "../Layout/LayoutConstants";
import Spinner from "../Spinner";
import PropsInputBase from "./PropsInputBase";

export interface PropsInputText extends PropsInputBase<string> {
  type?: "text" | "password";
  helpTooltip?: string;
  startIcon?: IconType;
  endIcon?: IconType;
  onStartIconClick?(): void;
  onEndIconClick?(): void;
  onKeyDown?(event: React.KeyboardEvent<HTMLDivElement>): void;
}

const InputText = React.forwardRef((props: PropsInputText, ref: any) => {
  const { appInfo } = useContext(AppInfoContext);
  const variant = props.variant ?? appInfo?.muiVariant;
  const style: CSSProperties = {
    ...props.style,
    marginBottom:
      props.style?.marginBottom ??
      (!props.hintAutoHeight ? -LAYOUT_HINT_MARGIN_BOTTOM_FIX : LAYOUT_HINT_PADDING_BOTTOM),
  };

  const startIcon = props.startIcon ? (
    <InputAdornment position="start">
      <ButtonIcon
        icon={props.startIcon}
        style={{
          marginLeft: variant === ThemeVariant.filled ? -5 : 0,
        }}
        color={AppThemeColor.gray}
        onClick={() => {
          if (props.onStartIconClick) {
            props.onStartIconClick();
          }
        }}
      />
    </InputAdornment>
  ) : null;

  const endIcon = props.showSpinner ? (
    <InputAdornment position="end">
      <Spinner />
    </InputAdornment>
  ) : props.endIcon ? (
    <InputAdornment position="end">
      <ButtonIcon
        icon={props.endIcon}
        color={variant == ThemeVariant.filled ? AppThemeColor.gray : AppThemeColor.success}
        noPaddingX={true}
        onClick={() => {
          if (props.onEndIconClick) {
            props.onEndIconClick();
          }
        }}
      />
    </InputAdornment>
  ) : props.helpTooltip ? (
    <InputAdornment position="end">
      <Icon
        type={TypeIcon.help}
        color={AppThemeColor.gray}
        tooltip={props.helpTooltip}
        style={{ cursor: "default" }}
      />
    </InputAdornment>
  ) : null;

  const icon =
    startIcon || endIcon ? { startAdornment: startIcon, endAdornment: endIcon } : undefined;

  let helperText = Constants.EMPTY_HINT;

  if (props.validation) {
    helperText = props.validation;
  } else if (props.hint) {
    helperText = props.hint;
  } else if (props.hintAutoHeight) {
    helperText = "";
  }

  return (
    <TextField
      id={props.id}
      autoFocus={props.autoFocus}
      inputRef={ref}
      type={props.type ?? "text"}
      size={ThemeSize.small}
      autoComplete={"off"}
      variant={variant}
      label={props.label}
      disabled={props.readOnly}
      error={!!props.validation}
      helperText={helperText}
      multiline={props.multiline || (props.multilineRows ?? 0) > 1}
      rows={props.multilineRows ?? 4}
      value={props.value ?? ""}
      fullWidth={true}
      style={style}
      InputProps={icon}
      onFocus={() => {
        if (props.onFocus) {
          props.onFocus();
        }

        setTimeout(() => {
          ref?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 500);
      }}
      onBlur={props.onBlur}
      onChange={(e) => props.onChange(e.target.value)}
      onKeyDown={(e) => {
        if (props.onKeyDown) {
          props.onKeyDown(e);
        }
      }}
    />
  );
});

export default InputText;
