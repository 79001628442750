import { useEffect, useState } from "react";
import { InvoiceType } from "../../api/shared/enums/InvoiceType";
import { InvoiceTypeGroup } from "../../api/shared/enums/InvoiceTypeGroup";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiEInvoiceAuthorization } from "../../api/shared/hooks/useApiApp";
import { AppRoute } from "../../AppRoutes";
import ButtonLink from "../../shared/components/Button/ButtonLink";
import { TypeIcon } from "../../shared/components/Icon";
import InfoText, { InfoTextType } from "../../shared/components/InfoText";
import Row from "../../shared/components/Layout/GRow";
import RowDisplay from "../../shared/components/Layout/GRowDisplay";
import { useNavigation } from "../../shared/hooks/useNavigation";
import useUtils from "../../shared/hooks/useUtils";

const SaleFormInfoInvoiceType = (props: {
  invoiceType: InvoiceType;
  invoiceTypeGroup: InvoiceTypeGroup;
}) => {
  const { utils } = useUtils();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [apiEInvoiceAuthorization] = useApiEInvoiceAuthorization();
  const navigation = useNavigation();

  useEffect(() => {
    setErrorMessage(undefined);
    setIsWarning(false);
    if (!props.invoiceType) {
      return;
    }
    const load = async () => {
      const info = await apiEInvoiceAuthorization.verifyUsageByType(props.invoiceType, {
        preventSpinner: true,
      });

      setErrorMessage(info.message);
      setIsWarning(info.isWarning);
    };
    load();
  }, [props.invoiceType]);

  return (
    <>
      <RowDisplay label="Tipo de comprobante" minHeightIgnore={true}>
        {utils.enum.translate(enumName.InvoiceType, props.invoiceType)} (
        {utils.enum.translate(enumName.InvoiceTypeGroup, props.invoiceTypeGroup)})
      </RowDisplay>
      {errorMessage && (
        <>
          <Row minHeightIgnore={true}>
            <InfoText type={isWarning ? InfoTextType.warning : InfoTextType.error}>
              {errorMessage}
            </InfoText>
          </Row>
          <Row minHeightIgnore={true}>
            <ButtonLink
              icon={TypeIcon.config}
              text="Administrar CAEs"
              onClick={() => navigation.newTab(AppRoute.configuration.eInvoice.authorizations)}
            />
          </Row>
        </>
      )}
    </>
  );
};

export default SaleFormInfoInvoiceType;
