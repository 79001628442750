// File generated by Gesta.Build.PostEvents
import { AdminTemplateSimpleDto } from "./AdminTemplateSimpleDto";
import { AdminAccountSimpleDto } from "../../shared/dtos/AdminAccountSimpleDto";
import { Country } from "../../shared/enums/Country";
import { TemplateType } from "../../shared/enums/TemplateType";

export class AdminTemplateDto extends AdminTemplateSimpleDto {
  adminAccountId: string;
  adminAccount: AdminAccountSimpleDto;
  body: string = "";
  country: Country;
  isDefault: boolean = false;
  isAdmin: boolean = false;
  isGlobal: boolean = false;
  styles: string = "";
  type: TemplateType;
}
