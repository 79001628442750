import { nameof } from "ts-simple-nameof";
import { AdminEmailContactSupportDto } from "../../../api/admin/dtos/AdminEmailContactSupportDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

export function useEmailContactSupportValidation() {
  const schema = new ValidationSchema();

  schema[nameof<AdminEmailContactSupportDto>((p) => p.subject)] = validate("Asunto").required();
  schema[nameof<AdminEmailContactSupportDto>((p) => p.message)] = validate("Mensaje").required();

  return schema;
}
