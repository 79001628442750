// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AccountConfigCurrenciesCrudDto } from "../dtos/AccountConfigCurrenciesCrudDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { AccountConfigEInvoiceCrudDto } from "../dtos/AccountConfigEInvoiceCrudDto";
import { AccountConfigTaxesCrudDto } from "../dtos/AccountConfigTaxesCrudDto";

export class ApiAccountConfig {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  configCurrencies(
    accountConfigCurrenciesCrudDto: AccountConfigCurrenciesCrudDto,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/account-config/currencies", accountConfigCurrenciesCrudDto, apiOptions);
  }

  getForCurrencies(
    apiOptions?: ApiOptions
  ): Promise<AccountConfigCurrenciesCrudDto> {
    return this.apiService.apiGet("/account-config/currencies", apiOptions);
  }

  configEInvoice(
    accountConfigEInvoiceCrudDto: AccountConfigEInvoiceCrudDto,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/account-config/e-invoice", accountConfigEInvoiceCrudDto, apiOptions);
  }

  getForEInvoice(
    apiOptions?: ApiOptions
  ): Promise<AccountConfigEInvoiceCrudDto> {
    return this.apiService.apiGet("/account-config/e-invoice", apiOptions);
  }

  configTaxes(
    accountConfigTaxesCrudDto: AccountConfigTaxesCrudDto,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/account-config/taxes", accountConfigTaxesCrudDto, apiOptions);
  }

  getForTaxes(
    apiOptions?: ApiOptions
  ): Promise<AccountConfigTaxesCrudDto> {
    return this.apiService.apiGet("/account-config/taxes", apiOptions);
  }

}
