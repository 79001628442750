import { useEffect, useState } from "react";
import { AppRoute } from "../../AppRoutes";
import { ButtonLink } from "../../shared/components/Button/ButtonLink";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Row from "../../shared/components/Layout/GRow";
import Section from "../../shared/components/Layout/GSection";
import Page from "../../shared/components/Layout/Page";
import { useApiTest } from "../../api/shared/hooks/useApiApp";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { useNotifier } from "../../shared/hooks/useNotifier";

const AdminHome = () => {
  const navigation = useNavigation();
  const notifier = useNotifier();
  const [apiTest] = useApiTest();
  const [showComponentWithError, setShowComponentWithError] = useState(false);

  return (
    <Page title="Configuraciones">
      <Row>
        <Section title="Generales">
          <Row>
            <ComponentGroup>
              <ButtonSecondary
                text="Shards"
                icon={TypeIcon.shard}
                onClick={() => navigation.go(AppRoute.admin.shards)}
              />
              <ButtonSecondary
                text="Impuestos"
                icon={TypeIcon.details}
                onClick={() => navigation.go(AppRoute.admin.taxValues)}
              />
              <ButtonSecondary
                text="Cotizaciones"
                icon={TypeIcon.dollar}
                onClick={() => navigation.go(AppRoute.admin.currencyExchangeRates)}
              />
              <ButtonSecondary
                text="Términos y condiciones"
                icon={TypeIcon.legal}
                onClick={() => navigation.go(AppRoute.admin.termsAndConditions.base)}
              />
              <ButtonSecondary
                text="Aceptación de términos y condiciones"
                tooltip="Información acerca de cada usuario y su aceptación de los términos y condiciones "
                icon={TypeIcon.legal}
                onClick={() => navigation.go(AppRoute.admin.userTermsAccepted)}
              />
            </ComponentGroup>
          </Row>
        </Section>
      </Row>
      <Row>
        <Section title="Desarrollo">
          <Row>
            <ButtonLink text="Íconos" onClick={() => navigation.go(AppRoute.admin.icons)} />
            <ButtonLink
              text="Éxito"
              onClick={() => {
                notifier.showSuccess("Mostrando mensaje de éxito");
              }}
            />
            <ButtonLink
              text="Éxito rápido"
              onClick={() => {
                notifier.showSuccessFast("Mostrando mensaje de éxito rápido");
              }}
            />
            <ButtonLink
              text="Info"
              onClick={() => {
                notifier.showInfo("Mostrando mensaje de información");
              }}
            />
            <ButtonLink
              text="Advertencia"
              onClick={() => {
                notifier.showWarning("Mostrando mensaje de advertencia");
              }}
            />
            <ButtonLink
              text="Error"
              onClick={() => {
                notifier.showErrorMessage("Mostrando mensaje de error");
              }}
            />
          </Row>
        </Section>
      </Row>
      <Row>
        <Section title="Pruebas desarrollo - Endpoints ">
          <Row>
            <ComponentGroup wrap={true}>
              <ButtonSecondary
                text="Obtener numero"
                onClick={async () => notifier.showSuccess("" + (await apiTest.getNumber()))}
              />
              <ButtonSecondary
                text="Forzar excepción de negocio (400)"
                onClick={async () => {
                  await apiTest.getBusinessException();
                }}
              />
              <ButtonSecondary
                text="Forzar excepción de negocio (400) (logging level Error)"
                onClick={async () => {
                  await apiTest.getBusinessExceptionLoggingError();
                }}
              />
              <ButtonSecondary
                text="Forzar exception (500)"
                onClick={async () => await apiTest.getException()}
              />
              <ButtonSecondary
                text="Forzar excepción cliente (No React y ServerLog)"
                onClick={async () => {
                  throw Error("Probando lanzar excepción!!");
                }}
              />
              <ButtonSecondary
                text="Forzar excepción cliente (ComponenteReact y ServerLog)"
                onClick={() => setShowComponentWithError(true)}
              />
              {showComponentWithError && <ComponentWithError />}
            </ComponentGroup>
          </Row>
        </Section>
      </Row>
    </Page>
  );
};

export default AdminHome;

function ComponentWithError() {
  useEffect(() => {
    throw Error("Testing error thrown at ComponentWithError");
  }, []);
  return <></>;
}
