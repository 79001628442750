// File generated by: Gesta.Build.PostEvents
export enum TemplateType {
	Generic = 1,
	GenericPdf = 2,
	GenericEmail = 3,
	AdminGeneric = 10,
	AdminGenericPdf = 11,
	AdminGenericEmail = 12,
	PdfSaleInvoice = 101,
	AdminEmailContactPublicSales = 900,
	AdminEmailContactSales = 901,
	AdminEmailContactSupport = 902,
	AdminEmailNewAccountToUser = 1001,
	AdminEmailAccountInvitationToUser = 1002,
	AdminEmailResetPasswordToUser = 1003,
	AdminEmailWelcomeToUser = 1004,
	AdminEmailCertificateExpirationReminderToAccountContact = 1005,
	EmailSaleInvoiceToContact = 1100,
	EmailEInvoiceEnvelopeToCompany = 1101,
	EmailEInvoiceEnvelopePurchaseAckToCompany = 1102,
	EmailEInvoiceEnvelopePurchaseStatusResponseToCompany = 1103,
	EmailEInvoiceEnvelopeRejectionNotificationToCompany = 1104,
}
