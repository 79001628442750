// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminTermsAndConditionsDto } from "../dtos/AdminTermsAndConditionsDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { AdminTermAndConditionsFiltersDto } from "../dtos/AdminTermAndConditionsFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminProviderTermsAndConditions {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<AdminTermAndConditionsFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AdminTermsAndConditionsDto>> {
    return this.apiService.apiPost("/admin-provider/terms-and-conditions/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AdminTermsAndConditionsDto> {
    return this.apiService.apiGet("/admin-provider/terms-and-conditions/" + id, apiOptions);
  }

  create(
    adminTermsAndConditionsDto: AdminTermsAndConditionsDto,
    apiOptions?: ApiOptions
  ): Promise<AdminTermsAndConditionsDto> {
    return this.apiService.apiPost("/admin-provider/terms-and-conditions", adminTermsAndConditionsDto, apiOptions);
  }

  edit(
    adminTermsAndConditionsDto: AdminTermsAndConditionsDto,
    apiOptions?: ApiOptions
  ): Promise<AdminTermsAndConditionsDto> {
    return this.apiService.apiPut("/admin-provider/terms-and-conditions", adminTermsAndConditionsDto, apiOptions);
  }

}
