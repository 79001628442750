import { useEffect, useState } from "react";
import { AdminAccountAppAccessDto } from "../../api/app/dtos/AdminAccountAppAccessDto";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import Row from "../../shared/components/Layout/GRow";
import Page from "../../shared/components/Layout/Page";
import TabGroup from "../../shared/components/Layout/TabGroup";
import TabView from "../../shared/components/Layout/TabView";
import { useApiAdminAccountAppAccess } from "../../api/shared/hooks/useApiApp";
import { useModal } from "../../shared/hooks/useModal";
import EmailContactSupportPopup from "../EmailContact/EmailContactSupportPopup";
import MyAdminAccountBranches from "./MyAdminAccount.Branches";
import MyAdminAccountGeneral from "./MyAdminAccount.General";

const TABS = {
  GENERAL: "GENERAL",
  BRANCHES: "BRANCHES",
};

const MyAdminAccount = () => {
  const [apiAdminAccountAppAccess] = useApiAdminAccountAppAccess();
  const [adminAccount, setAdminAccount] = useState<AdminAccountAppAccessDto>();
  const [modal] = useModal();

  useEffect(() => {
    const load = async () => {
      setAdminAccount(await apiAdminAccountAppAccess.getCurrent());
    };
    load();
  }, []);

  if (!adminAccount) {
    return <></>;
  }

  return (
    <Page title="Mi empresa">
      <TabGroup
        tabs={[
          { title: "General", value: TABS.GENERAL },
          { title: "Sucursales", value: TABS.BRANCHES },
        ]}
        selectedTab={TABS.GENERAL}>
        <TabView value={TABS.GENERAL}>
          <MyAdminAccountGeneral adminAccount={adminAccount} />
        </TabView>
        <TabView value={TABS.BRANCHES}>
          <MyAdminAccountBranches />
        </TabView>
      </TabGroup>
      <Row>
        <ButtonSecondary
          text="Necesito cambiar información"
          icon={TypeIcon.help}
          onClick={() => {
            modal.open(
              <EmailContactSupportPopup
                subject="Actualizar información de mi empresa"
                message="Necesito actualizar la siguiente información de mi empresa: "
              />
            );
          }}
        />
      </Row>
    </Page>
  );
};

export default MyAdminAccount;
