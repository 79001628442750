import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AccountUserCrudDto } from "../../api/admin/dtos/AccountUserCrudDto";
import { UserCrudEmailVerificationStatus } from "../../api/shared/enums/UserCrudEmailVerificationStatus";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormSwitch from "../../shared/components/forms/FormSwitch";
import FormText from "../../shared/components/forms/FormText";
import { TypeIcon } from "../../shared/components/Icon";
import IconText from "../../shared/components/IconText";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import { useApiAdminProviderAccountUserLinkedAccount } from "../../api/shared/hooks/useApiAdminProviders";
import { useContextModelState } from "../../shared/modelState/useContextModelState";
import { AppThemeColor } from "../../styles/color";

const AccountUserCreateForm = (props: { onSave(): void }) => {
  const [apiAdminAccountUserLinkedAccount] = useApiAdminProviderAccountUserLinkedAccount();
  const [isPendingEmailVerification, setIsPendingEmailVerification] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [emailVerificationStatus, setEmailVerificationStatus] =
    useState<UserCrudEmailVerificationStatus | null>(null);
  const [canSave, setCanSave] = useState(false);

  const modelState = useContextModelState();
  const accountUser = modelState.model as AccountUserCrudDto;

  const verifyEmail = (email: string) => {
    const verify = async () => {
      setIsPendingEmailVerification(true);
      setIsVerifying(true);
      const statusDto =
        await apiAdminAccountUserLinkedAccount.getUserAccountCrudEmailVerificationStatus(email);
      const status = statusDto.emailVerificationStatus;
      setEmailVerificationStatus(status);
      setIsVerifying(false);
      setIsPendingEmailVerification(false);
      setCanEdit(status == UserCrudEmailVerificationStatus.NotExists);
      setCanSave(
        status == UserCrudEmailVerificationStatus.NotExists ||
          status == UserCrudEmailVerificationStatus.AvailableToLinkAccount
      );
      modelState.updateProperty(
        nameof<AccountUserCrudDto>((p) => p.userFirstName),
        statusDto.existingUserFirstName
      );
      modelState.updateProperty(
        nameof<AccountUserCrudDto>((p) => p.userLastName),
        statusDto.existingUserLastName
      );
    };
    verify();
  };

  return (
    <>
      <Row>
        <FormText
          label="Correo"
          propertyName={nameof<AccountUserCrudDto>((p) => p.userEmail)}
          showSpinner={isVerifying}
          onBlur={() => verifyEmail(accountUser.userEmail)}
        />
      </Row>

      <Row>
        {isPendingEmailVerification && (
          <IconText icon={TypeIcon.pending} text="Pendiente verificar correo" />
        )}
        {!isPendingEmailVerification && (
          <>
            {emailVerificationStatus == UserCrudEmailVerificationStatus.NotExists && (
              <IconText
                icon={TypeIcon.check}
                text="Usuario disponible"
                color={AppThemeColor.success}
              />
            )}
            {emailVerificationStatus == UserCrudEmailVerificationStatus.AvailableToLinkAccount && (
              <IconText
                icon={TypeIcon.check}
                text="Usuario ya existe, se asociará a la cuenta"
                color={AppThemeColor.success}
              />
            )}
            {emailVerificationStatus == UserCrudEmailVerificationStatus.UserInactive && (
              <IconText
                icon={TypeIcon.warning}
                text="El usuario está inhabilitado"
                color={AppThemeColor.alert}
              />
            )}
            {emailVerificationStatus == UserCrudEmailVerificationStatus.UserLinkedAccountExists && (
              <IconText
                icon={TypeIcon.warning}
                text="El usuario ya existe"
                color={AppThemeColor.alert}
              />
            )}
            {emailVerificationStatus ==
              UserCrudEmailVerificationStatus.UserLinkedAccountExistsInactive && (
              <IconText
                icon={TypeIcon.warning}
                text="El usuario ya existe pero se encuentra inactivo, intenta reactivarlo"
                color={AppThemeColor.alert}
              />
            )}
          </>
        )}
      </Row>

      <Row>
        <FormText
          label="Nombre"
          readOnly={!canEdit}
          propertyName={nameof<AccountUserCrudDto>((p) => p.userFirstName)}
        />
      </Row>

      <Row>
        <FormText
          label="Apellido"
          readOnly={!canEdit}
          propertyName={nameof<AccountUserCrudDto>((p) => p.userLastName)}
        />
      </Row>

      <Row>
        <FormSwitch
          label="Administrador"
          readOnly={!canEdit}
          propertyName={nameof<AccountUserCrudDto>((p) => p.isAccountAdmin)}
        />
      </Row>
      <RowButtons>
        <ButtonAccept disabled={!canSave} onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default AccountUserCreateForm;
