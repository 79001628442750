import { AddressDto } from "../../../api/app/dtos/AddressDto";
import useUtils from "../../hooks/useUtils";
import ColumnDisplay from "../Layout/GColumnDisplay";

const AddressDisplay = (props: {
  address: AddressDto;
  columnMd?: number;
  displayAsRows?: boolean;
}) => {
  const { utils } = useUtils();
  const columnMd = props.displayAsRows ? 12 : props.columnMd ?? 3;
  return (
    <>
      <ColumnDisplay label="País" md={columnMd}>
        {utils.enum.translateCountry(props.address?.country)}
      </ColumnDisplay>

      <ColumnDisplay label="Departamento" md={columnMd}>
        {utils.enum.translateState(props.address?.state)}
      </ColumnDisplay>

      <ColumnDisplay label="Ciudad" md={columnMd}>
        {props.address?.city}
      </ColumnDisplay>

      <ColumnDisplay label="Dirección" md={columnMd}>
        {props.address?.addressLine}
      </ColumnDisplay>
    </>
  );
};

export default AddressDisplay;
