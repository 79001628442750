import { EInvoiceProcessStatus } from "../../api/shared/enums/EInvoiceProcessStatus";
import { SaleDraftStatus } from "../../api/shared/enums/SaleDraftStatus";
import { AppThemeColor } from "../../styles/color";
import { TypeIcon } from "../components/Icon";

export const enumIconEInvoiceProcessStatus = (
  enumKey: EInvoiceProcessStatus
): { icon: any; color: AppThemeColor } => {
  switch (enumKey) {
    case EInvoiceProcessStatus.None:
      return { icon: TypeIcon.hyphen, color: AppThemeColor.inactive };
    case EInvoiceProcessStatus.Accepted:
    case EInvoiceProcessStatus.AcceptedNotApply:
      return { icon: TypeIcon.check, color: AppThemeColor.success };
    case EInvoiceProcessStatus.EnvelopeSent:
      return { icon: TypeIcon.email, color: AppThemeColor.warning };
    case EInvoiceProcessStatus.EnvelopeSentAck:
    case EInvoiceProcessStatus.PendingVerification:
      return { icon: TypeIcon.check, color: AppThemeColor.warning };
    case EInvoiceProcessStatus.PdfGenerated:
    case EInvoiceProcessStatus.Pending:
    case EInvoiceProcessStatus.XmlCreated:
    case EInvoiceProcessStatus.XmlEnvelopeCreated:
      return { icon: TypeIcon.pending, color: AppThemeColor.warning };
    case EInvoiceProcessStatus.ErrorUserCancelledBeforeSend:
      return { icon: TypeIcon.delete, color: AppThemeColor.alert };
  }
  return { icon: TypeIcon.error, color: AppThemeColor.alert };
};

export const enumIconSaleDraftStatus = (
  enumKey: SaleDraftStatus
): { icon: any; color: AppThemeColor } => {
  switch (enumKey) {
    case SaleDraftStatus.Draft:
      return { icon: TypeIcon.draft, color: AppThemeColor.black };
    case SaleDraftStatus.Discarded:
      return { icon: TypeIcon.delete, color: AppThemeColor.inactive };
    case SaleDraftStatus.Issued:
      return { icon: TypeIcon.check, color: AppThemeColor.success };
  }
};
