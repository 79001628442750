// File generated by: Gesta.Build.PostEvents
export enum EInvoiceProcessStatus {
	None = 1,
	Pending = 2,
	XmlCreated = 3,
	PdfGenerated = 4,
	XmlEnvelopeCreated = 5,
	EnvelopeSent = 6,
	EnvelopeSentAck = 7,
	PendingVerification = 8,
	Accepted = 9,
	AcceptedNotApply = 10,
	RejectedNotificationSent = 11,
	ErrorXmlProcessing = 1001,
	ErrorPdfGenerating = 1002,
	ErrorEnvelopeProcessing = 1003,
	ErrorEnvelopeSending = 1004,
	ErrorEnvelopeVerification = 1005,
	ErrorVerificationTimeout = 1006,
	ErrorRejected = 1007,
	ErrorRejectedNotificationSending = 1008,
	ErrorUserCancelledBeforeSend = 1009,
}
