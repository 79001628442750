// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminRetentionDto } from "../dtos/AdminRetentionDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { SuggestResponseDto } from "../../shared/dtos/SuggestResponseDto";
import { AdminRetentionFiltersDto } from "../dtos/AdminRetentionFiltersDto";
import { SuggestSearchDto } from "../../shared/dtos/SuggestSearchDto";

export class ApiAdminAccountPublicAdminRetention {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AdminRetentionDto> {
    return this.apiService.apiGet("/admin/account-public/retention?" + 
      (id ? "&id=" + id : ""), apiOptions);
  }

  suggestByCountry(
    suggestSearch: SuggestSearchDto<AdminRetentionFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/admin/account-public/retention/suggest-by-country", suggestSearch, apiOptions);
  }

}
