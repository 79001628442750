import { CSSProperties } from "react";
import { ISaleLineColumn } from "./SaleFormLines";

const SaleFormLineHeader = (props: { saleLineColumn: ISaleLineColumn }) => {
  const style: CSSProperties = {
    flex: props.saleLineColumn.width,
    gap: 5,
    textAlign: props.saleLineColumn.align,
    whiteSpace: "nowrap",
  };

  return <div style={style}>{props.saleLineColumn.header}</div>;
};

export default SaleFormLineHeader;
