import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Field from "./Field";
import PropsInputBase from "./PropsInputBase";

export interface PropsInputRadio extends PropsInputBase<boolean | null> {
  alignRow?: boolean;
  allowNull?: boolean;
  labelTrue: string;
  labelFalse: string;
  labelAsDefault?: string;
  showAsDefaultOption?: boolean;
}

const toBool = (value: any) => {
  if (value && typeof value === "string") {
    if (value.toLowerCase() === "true") return true;
    if (value.toLowerCase() === "false") return false;
  }
  return value;
};

const InputRadioBoolean = (props: PropsInputRadio) => {
  const handleClick = (event: any) => {
    const value = toBool(event.target.value);

    if (value === "") {
      props.onChange(null);
      return;
    }

    if (value === props.value) {
      props.onChange(null);
    } else {
      props.onChange(value);
    }
  };

  return (
    <Field childrenProps={props}>
      <RadioGroup value={props.value ?? ""} name={props.name} row={props.alignRow}>
        <FormControlLabel
          value={true}
          control={<Radio onClick={handleClick} />}
          label={props.labelTrue}
          checked={props.value == true}
        />
        <FormControlLabel
          value={false}
          control={<Radio onClick={handleClick} />}
          label={props.labelFalse}
          checked={props.value == false}
        />
        {props.allowNull && props.showAsDefaultOption && (
          <FormControlLabel
            value={""}
            control={<Radio onClick={handleClick} />}
            label={props.labelAsDefault ?? "Por defecto"}
            checked={props.value == null}
          />
        )}
      </RadioGroup>
    </Field>
  );
};

export default InputRadioBoolean;
