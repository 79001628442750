// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { AddressDto } from "./AddressDto";
import { IdCardType } from "../../shared/enums/IdCardType";
import { AccountUserDto } from "./AccountUserDto";

export class ContactInfoDto extends BaseEntityDto {
  activity: string = "";
  addressId: string;
  address: AddressDto;
  eInvoiceExtraInfo: string = "";
  idCardNumber: string = "";
  idCardType: IdCardType;
  notes: string = "";
  mainBranchName: string = "";
  mainBranchCode: string = "";
  phone: string = "";
  salesOwnerId: string;
  salesOwner: AccountUserDto;
}
