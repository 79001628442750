// File generated by Gesta.Build.PostEvents
import { AdminTemplateSimpleDto } from "./AdminTemplateSimpleDto";
import { TemplateGenericType } from "../../shared/enums/TemplateGenericType";
import { TemplateGroup } from "../../shared/enums/TemplateGroup";
import { TemplateType } from "../../shared/enums/TemplateType";

export class AdminTemplateGenericSimpleDto extends AdminTemplateSimpleDto {
  code: string = "";
  genericType: TemplateGenericType;
  helpText: string = "";
  isGlobal: boolean = false;
  isAdmin: boolean = false;
  templateGroup: TemplateGroup;
  type: TemplateType;
}
