import { CSSProperties, ReactNode } from "react";
import { AppThemeColor } from "../../../../styles/color";
import { TextAlign } from "../../../TextAlign";
import { GridColumnAlign } from "../Grid";
import { IGridContext } from "../useContextGrid";

export interface IGridItemTemplate<T> {
  align?: GridColumnAlign;
  actionType?: GridColumnActionType;
  cellStyle?: CSSProperties;
  cellType?: GridCellType;
  cellTypeCurrencyField?: string;
  cellTypeEnumName?: string;
  cellTypeMapColor?: (value: any) => AppThemeColor;
  cellTypeMapIcon?: (value: any) => { icon: any; color: AppThemeColor };
  cellTypeDecimals?: number;
  className?: string;
  header: string;
  headerAlt?: string;
  hideByDefault?: boolean;
  isMandatory?: boolean;
  field: string;
  filter?: IGridItemTemplateFilter;
  relatedFields?: string[]; // Indicates that the columns needs more info included besides the field value
  maxHeightIgnore?: boolean;
  mobileSpecialType?: MobileSpecialType;
  noPaddingY?: boolean;
  orderBy?: string; // Allows to override the orderBy behavior that is the field property by default
  orderByPrevent?: boolean; // Indicates that this column does not support order by
  tooltip?(item: T): string;
  width: number;
  render?(item: T, gridContext: IGridContext<T>): ReactNode;
}

export interface IGridItemTemplateFilter {
  propertyName: string;
  type?: GridFilterType;
  element?: ReactNode;
  enumName?: string;
}

export enum GridFilterType {
  account,
  accountBranch,
  accountUser,
  adminProviders,
  boolean,
  custom,
  currency,
  customers,
  date,
  dateTime,
  enum,
  enumList,
  numberRange,
  numberRangeInteger,
  providers,
  text,
  country,
  countryAvailable,
}

export enum GridCellType {
  date,
  dateTime,
  dateTimeWithSeconds,
  dateOnlyLocal,
  decimal,
  integer,
  enum,
  iconCheck,
  currencyValue,
  statusWithColor,
  statusWithIcon,
  download,
  timeHourSecondsMs,
}

export enum GridColumnActionType {
  Selection = 1,
  MenuActionButton = 2,
  CustomActionButton = 3,
  ExpandRowButton = 4,
}

export enum MobileSpecialType {
  Main = 1,
  Secondary = 2,
}

export const getGridColumnAlign = (itemTemplate: IGridItemTemplate<any>): TextAlign => {
  let align = itemTemplate.align;

  if (!align) {
    align =
      itemTemplate.cellType == GridCellType.decimal || itemTemplate.cellType == GridCellType.integer
        ? GridColumnAlign.right
        : GridColumnAlign.left;
  }

  switch (align) {
    case GridColumnAlign.center:
      return TextAlign.center;
    case GridColumnAlign.right:
      return TextAlign.right;
    default:
      return TextAlign.left;
  }
};
