import { nameof } from "ts-simple-nameof";
import { AdminAccountDto } from "../../api/admin/dtos/AdminAccountDto";
import FormText from "../../shared/components/forms/FormText";
import Row from "../../shared/components/Layout/GRow";

const AdminAccountFormContact = () => {
  return (
    <>
      <Row>
        <FormText label="Teléfono" propertyName={nameof<AdminAccountDto>((p) => p.contact.phone)} />
      </Row>

      <Row>
        <FormText label="Correo" propertyName={nameof<AdminAccountDto>((p) => p.contact.email)} />
      </Row>

      <Row>
        <FormText
          label="Nombre"
          propertyName={nameof<AdminAccountDto>((p) => p.contact.firstName)}
        />
      </Row>

      <Row>
        <FormText
          label="Apellido"
          propertyName={nameof<AdminAccountDto>((p) => p.contact.lastName)}
        />
      </Row>
    </>
  );
};

export default AdminAccountFormContact;
