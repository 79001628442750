import { forwardRef } from "react";
import InputDate, { PropsInputDate } from "../inputs/InputDate";
import { PropsFormInput, useFormInputInit } from "./_formInputProps";

interface PropsFormDate
  extends PropsFormInput<Date | null>,
    Omit<PropsInputDate, "value" | "onChange"> {}

const FormDate = forwardRef((props: PropsFormDate, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputDate {...props} {...inputProps} />;
});

export default FormDate;
