import cloneDeep from "lodash/cloneDeep";

export const getObjectValue = <T>(value: any, propertyName: string): T => {
  if (!propertyName) {
    return value;
  }

  if (!value) {
    return value;
  }

  return propertyName.split(".").reduce(function (o, key) {
    const startIndexOf = key.indexOf("[");
    if (startIndexOf !== -1) {
      const arrayIndex = parseInt(key.substring(startIndexOf + 1, key.length - 1));
      const collection = o[key.substring(0, startIndexOf)];
      if (!collection) {
        return null;
      }
      return collection[arrayIndex];
    }
    return o ? o[key] : null;
  }, value);
};

export const cloneObject = <T>(value: any): T => {
  return cloneDeep(value);
};

export function propertyNameJoin(propertyBaseName?: string, propertyName?: string) {
  return (propertyBaseName ? propertyBaseName + "." : "") + propertyName;
}

export function propertyFriendlyNameJoin(
  propertyBaseFriendlyName?: string,
  propertyFriendlyName?: string
) {
  return (propertyBaseFriendlyName ? propertyBaseFriendlyName + " - " : "") + propertyFriendlyName;
}

export function deepEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) {
    return true;
  }

  if (obj1 !== Object(obj1) && obj2 !== Object(obj2)) {
    if ((obj1 === null || obj1 === undefined) && (obj2 === null || obj2 === undefined)) {
      return true;
    }
    return obj1 === obj2;
  }

  if ((obj1 && !obj2) || (!obj1 && obj2)) {
    return false;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  for (const key in obj1) {
    if (!(key in obj2)) {
      return false;
    }

    if (!deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
}
