import { useContext, useEffect } from "react";
import ButtonLink from "../../shared/components/Button/ButtonLink";
import FormValidationSummary from "../../shared/components/forms/FormValidationSummary";
import { TypeIcon } from "../../shared/components/Icon";
import Column from "../../shared/components/Layout/GColumn";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import { TaxValueContext } from "../../shared/components/TaxValue/TaxValueProvider";
import { useModal } from "../../shared/hooks/useModal";
import SaleFormInfo from "./SaleForm.Info";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import SaleFormReferences from "./SaleFormReferences";
import SaleFormLines from "./SaleFormLines";
import SaleMoreInfoPopup from "./SaleMoreInfoPopup";
import SaleTotals from "./SaleTotals";
import { SaleViewModel } from "./ViewModels/SaleViewModel";
import { SalePageType } from "./SalePage";

const SaleForm = (props: { pageType: SalePageType; onSubmit: () => void }) => {
  const { taxValues } = useContext(TaxValueContext) as any;
  const saleModelState = useContextModelStateSale();
  const [modal] = useModal();

  useEffect(() => {
    if (taxValues) {
      saleModelState.updateTaxValues(taxValues);
    }
  }, [taxValues]);

  return (
    <>
      <Column md={2}>
        <Container>
          <SaleFormInfo pageType={props.pageType} />
          <Row>
            <ButtonLink
              text="Más info"
              icon={TypeIcon.info}
              onClick={() => {
                modal.open(
                  <SaleMoreInfoPopup saleViewModel={saleModelState.sale} />,
                  (newSale: SaleViewModel) => {
                    saleModelState.replaceModel(newSale);
                  }
                );
              }}
            />
          </Row>
        </Container>
      </Column>
      <Column md={10}>
        <SaleFormReferences />
        <SaleFormLines />
      </Column>
      <Row minHeightIgnore={true}>
        <FormValidationSummary />
      </Row>

      <Row>
        <SaleTotals
          onSave={props.onSubmit}
          onSaveDraft={() => {
            saleModelState.setSaveAsDraft(true);
            props.onSubmit();
          }}
        />
      </Row>
    </>
  );
};

export default SaleForm;
