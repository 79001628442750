// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { UserDto } from "../dtos/UserDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { UserFiltersDto } from "../dtos/UserFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { UserCrudDto } from "../dtos/UserCrudDto";
import { SuggestResponseDto } from "../../shared/dtos/SuggestResponseDto";
import { SuggestSearchDto } from "../../shared/dtos/SuggestSearchDto";

export class ApiAdminProviderUser {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchFilters: PagedSearchDto<UserFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<UserDto>> {
    return this.apiService.apiPost("/admin-provider/users/paged", searchFilters, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<UserDto> {
    return this.apiService.apiGet("/admin-provider/users/" + id, apiOptions);
  }

  getToEditById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<UserCrudDto> {
    return this.apiService.apiGet("/admin-provider/users/to-edit/" + id, apiOptions);
  }

  create(
    userCrudDto: UserCrudDto,
    apiOptions?: ApiOptions
  ): Promise<UserDto> {
    return this.apiService.apiPost("/admin-provider/users", userCrudDto, apiOptions);
  }

  edit(
    userCrudDto: UserCrudDto,
    apiOptions?: ApiOptions
  ): Promise<UserDto> {
    return this.apiService.apiPut("/admin-provider/users", userCrudDto, apiOptions);
  }

  getRoles(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<string[]> {
    return this.apiService.apiGet("/admin-provider/users/roles/" + id, apiOptions);
  }

  inactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/users/inactivate/" + id, null, apiOptions);
  }

  reactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/users/reactivate/" + id, null, apiOptions);
  }

  resendWelcomeEmail(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/users/resend-welcome-email/" + id, null, apiOptions);
  }

  superAdminAdd(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<UserDto> {
    return this.apiService.apiPut("/admin-provider/users/super-admin-add/" + id, null, apiOptions);
  }

  superAdminRemove(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<UserDto> {
    return this.apiService.apiPut("/admin-provider/users/super-admin-remove/" + id, null, apiOptions);
  }

  sendForgotPasswordEmail(
    userEmail: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/users/resend-forgot-password-email?" + 
      (userEmail ? "&userEmail=" + userEmail : ""), null, apiOptions);
  }

  suggest(
    suggestSearch: SuggestSearchDto<UserFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/admin-provider/users/suggest", suggestSearch, apiOptions);
  }

}
