// File generated by Gesta.Build.PostEvents
import { AuthInfoAdminAccountDto } from "./AuthInfoAdminAccountDto";
import { AuthInfoAccountBranchDto } from "./AuthInfoAccountBranchDto";
import { AuthInfoPointOfSaleDto } from "./AuthInfoPointOfSaleDto";

export class AuthSwitchPointOfSaleDto  {
  adminAccountId: string;
  adminAccount: AuthInfoAdminAccountDto;
  accountBranchId: string;
  accountBranch: AuthInfoAccountBranchDto;
  pointOfSaleId: string;
  pointOfSale: AuthInfoPointOfSaleDto;
}
