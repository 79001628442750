import { CSSProperties } from "react";
import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import { useContextAppInfo } from "../../hooks/useContextAppInfo";
import Container from "./GContainer";
import Row from "./GRow";
import RowTitle from "./GRowTitle";
import {
  LayoutSize,
  LAYOUT_DEFAULT_BORDER_RADIUS,
  LAYOUT_DEFAULT_SPACING,
  LAYOUT_SPACING_PIXELS,
} from "./LayoutConstants";

const Section = (props: {
  background?: AppThemeColor;
  border?: boolean;
  borderColor?: AppThemeColor;
  borderRadius?: number;
  children: any;
  paddingTop?: LayoutSize;
  spacing?: LayoutSize;
  title?: string;
  titleIcon?: any;
}) => {
  const { appInfo } = useContextAppInfo();
  const spacing = props.spacing ?? LAYOUT_DEFAULT_SPACING;
  const appTheme = useAppTheme();

  let border: string | undefined = undefined;
  if (appInfo?.developShowLinesBoxes) {
    border = "1px solid blue";
  } else if (props.border || props.borderColor) {
    border = `1px solid ${props.borderColor ?? AppThemeColor.black}`;
  }

  const style: CSSProperties = {
    background: appTheme.toHexColor(props.background ?? AppThemeColor.white),
    borderRadius: props.borderRadius ?? LAYOUT_DEFAULT_BORDER_RADIUS,
    paddingTop: (props.paddingTop ?? spacing) * LAYOUT_SPACING_PIXELS,
    paddingBottom: spacing * LAYOUT_SPACING_PIXELS,
    paddingLeft: (spacing / 2) * LAYOUT_SPACING_PIXELS,
    paddingRight: spacing * LAYOUT_SPACING_PIXELS + (spacing / 2) * LAYOUT_SPACING_PIXELS,
    border: border,
  };

  return (
    <Row
      className="layout-container-padding-fix"
      minHeightIgnore={true}
      style={{
        paddingLeft: spacing * LAYOUT_SPACING_PIXELS,
        paddingTop: spacing * LAYOUT_SPACING_PIXELS,
        position: "relative",
      }}>
      <Container style={style} spacing={spacing}>
        {props.title && (
          <RowTitle icon={props.titleIcon} paddingTop={0}>
            {props.title}
          </RowTitle>
        )}
        {props.children}
      </Container>
    </Row>
  );
};

export default Section;
