import { CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AdminLoggingListQueryParams } from "../../../admin/AdminLogging/AdminLogViewer";
import { AppRoute } from "../../../AppRoutes";
import { useAppTheme } from "../../../AppThemeProvider";
import { Constants } from "../../../Constants";
import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import { useContextLoginInfo } from "../../hooks/useContextLoginInfo";
import { useNavigation } from "../../hooks/useNavigation";
import { TextAlign } from "../../TextAlign";
import ButtonIcon from "../Button/ButtonIcon";
import ButtonLink from "../Button/ButtonLink";
import Icon, { TypeIcon } from "../Icon";
import {
  Notification,
  NotificationActionType,
  NotificationsContextSet,
  NotificationType,
} from "./NotificationsProvider";

const NotificationPop = (props: { notification: Notification }) => {
  const { loginInfo } = useContextLoginInfo();
  const navigation = useNavigation();
  const { notificationDispatcher } = useContext(NotificationsContextSet);
  const [seconds, setSeconds] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isStopped, setStopped] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isCloseForced, setIsCloseForced] = useState(false);
  const [showTraceId, setShowTraceId] = useState(false);
  const appTheme = useAppTheme();

  const closeNotificationItem = () => {
    setIsClosing(true);
    const timer = setTimeout(() => {
      notificationDispatcher({
        type: NotificationActionType.removeNotification,
        index: props.notification.id ?? 0,
      });
    }, 1000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (isStopped && seconds <= 0) {
      setProgress(0);
      return;
    }

    if (props.notification.forcingClose && !isCloseForced) {
      setIsCloseForced(true);
      setSeconds(0);
      setProgress(0);
      return;
    }

    const timer = setTimeout(() => {
      const secondsToShow =
        props.notification.closeSeconds ??
        (props.notification.type == NotificationType.error
          ? Constants.NOTIFICATION_ERROR_DEFAULT_SECONDS
          : Constants.NOTIFICATION_DEFAULT_SECONDS);

      if (seconds >= secondsToShow) {
        closeNotificationItem();
        return;
      }
      setSeconds(seconds + (isStopped ? -0.5 : 0.1));
      setProgress((seconds / secondsToShow) * 100);
    }, 100);

    return () => clearTimeout(timer);
  }, [seconds, props.notification.forcingClose]);

  const className =
    "notification-item notification-item-bounce type-" +
    NotificationType[props.notification.type] +
    (isClosing ? " notification-item-closing" : "");

  let icon = TypeIcon.checkCircle;
  let title = props.notification.title;
  let iconColor = AppThemeColor.black;
  let backgroundColor = AppThemeColor.successLight;

  if (!title) {
    switch (props.notification.type) {
      case NotificationType.error:
        title = "Error :(";
        break;
      case NotificationType.info:
        title = "Información";
        break;
      case NotificationType.warning:
        title = "Atención";
        break;
      case NotificationType.success:
        title = "¡Listo!";
        break;
    }
  }

  switch (props.notification.type) {
    case NotificationType.error:
      icon = TypeIcon.error;
      iconColor = AppThemeColor.alert;
      backgroundColor = AppThemeColor.alertLight;
      break;
    case NotificationType.warning:
      icon = TypeIcon.warning;
      iconColor = AppThemeColor.warning;
      backgroundColor = AppThemeColor.warningLight;
      break;
    case NotificationType.info:
      icon = TypeIcon.info;
      iconColor = AppThemeColor.info;
      backgroundColor = AppThemeColor.infoLight;
      break;
  }

  return (
    <div
      className={className}
      style={{ backgroundColor: appTheme.toHexColor(backgroundColor) }}
      onClick={() => setStopped(true)}>
      <Icon type={icon} className="notification-icon" color={iconColor} size={AppSize.biggest} />
      <div className="notification-text-container">
        <div className="notification-title">{title}</div>
        <div
          className="notification-text"
          dangerouslySetInnerHTML={{ __html: props.notification.message }}></div>
        {props.notification.errorResult?.traceId && (
          <div style={{ width: "100%", textAlign: TextAlign.right, paddingRight: 15 }}>
            {showTraceId && <>{props.notification.errorResult.traceId}</>}
            {!showTraceId && props.notification.errorResult?.traceId && (
              <>
                {loginInfo.authInfo.isSuperAdmin && (
                  <ButtonLink
                    text="Ver logs"
                    onClick={(e) => {
                      e.preventDefault();
                      navigation.newTab(AppRoute.admin.logViewer, {
                        traceId: props.notification.errorResult?.traceId,
                      } as AdminLoggingListQueryParams);
                    }}
                  />
                )}
                {!loginInfo.authInfo.isSuperAdmin && (
                  <ButtonLink
                    text="Ver TraceID"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowTraceId(true);
                    }}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
      <CircularProgress
        variant="determinate"
        value={progress}
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          height: 32,
          width: 32,
        }}
      />
      <ButtonIcon
        icon={TypeIcon.close}
        color={iconColor}
        size={AppSize.large}
        style={{ position: "absolute", right: 10, top: 10 }}
        onClick={() => {
          closeNotificationItem();
        }}
      />
    </div>
  );
};

export default NotificationPop;
