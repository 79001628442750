import { IdGuidName } from "../../../IdGuidName";
import InputSelectBase from "./InputSelectBase";
import { PropsInputSelectEnumBase } from "./InputSelectEnumBase";

export interface PropsInputSelectNumber
  extends Omit<PropsInputSelectEnumBase<number | null>, "options"> {
  optionValues: number[];
}

const InputSelectNumber = (props: PropsInputSelectNumber) => {
  const options = props.optionValues.map((c) => new IdGuidName(c.toString(), c.toString()));

  return (
    <InputSelectBase
      {...props}
      options={options}
      onChange={(e) => {
        const parsedNumber = parseInt(e.target.value as any);
        if (isNaN(parsedNumber)) {
          props.onChange(null);
        } else {
          props.onChange(parsedNumber);
        }
      }}
    />
  );
};

export default InputSelectNumber;
