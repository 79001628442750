// File generated by Gesta.Build.PostEvents
import { InvoiceFiltersDto } from "./InvoiceFiltersDto";
import { EInvoicePurchaseApprovalStatus } from "../../shared/enums/EInvoicePurchaseApprovalStatus";

export class PurchaseFiltersDto extends InvoiceFiltersDto {
  onlyPendingApprove: boolean = false;
  approvalStatusList: EInvoicePurchaseApprovalStatus[] = [];
  envelopeSenderTaxPayerId: string = "";
  hasRejectDetails: boolean | null = null;
  hasSignatureVerificationMessage: boolean | null = null;
  taxFixApplied: boolean | null = null;
  taxPayerId: string = "";
}
