// File generated by Gesta.Build.PostEvents
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { AdminAccountSimpleDto } from "../../shared/dtos/AdminAccountSimpleDto";
import { FilterRangeDateDto } from "../../shared/dtos/FilterRangeDateDto";
import { UserSimpleDto } from "./UserSimpleDto";

export class UserLinkedAccountFiltersDto extends FiltersDto {
  adminAccountId: string;
  adminAccount: AdminAccountSimpleDto;
  isAccountAdmin: boolean | null = null;
  lastLogin: FilterRangeDateDto;
  userEmail: string = "";
  userId: string = "";
  user: UserSimpleDto;
}
