import { forwardRef } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminProviderSimpleDto } from "../../../../api/admin/dtos/AdminProviderSimpleDto";
import InputAutocompleteAdminProvider, {
  PropsInputAutocompleteAdminProvider,
} from "../../inputs/autocomplete/InputAutocompleteAdminProvider";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormAutocompleteAdminProvider
  extends PropsFormInput<AdminProviderSimpleDto>,
    Omit<
      PropsInputAutocompleteAdminProvider,
      "value" | "onChange" | "onCreateNewClick" | "onGetEntity" | "onServerSideSuggest"
    > {}

const FormAutocompleteAdminProvider = forwardRef(
  (props: PropsFormAutocompleteAdminProvider, ref?: any) => {
    const inputProps = useFormInputInit(props, ref, {
      updateIdLinkedProperty: true,
      inputTextValuePropertyName: nameof<AdminProviderSimpleDto>((p) => p.name),
    });
    return <InputAutocompleteAdminProvider {...props} {...inputProps} />;
  }
);

export default FormAutocompleteAdminProvider;
