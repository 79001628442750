import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminSentEmailDto } from "../../api/admin/dtos/AdminSentEmailDto";
import { enumName } from "../../api/shared/enums/_enumName";
import Grid from "../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../shared/components/Layout/Page";
import { useApiAdminProviderSentEmail } from "../../api/shared/hooks/useApiAdminProviders";
import { useModal } from "../../shared/hooks/useModal";
import { getAdminSentEmailListItemMenu } from "./AdminSentEmailList.ItemMenu";
import { AdminSentEmailFiltersDto } from "../../api/admin/dtos/AdminSentEmailFiltersDto";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { AppInfoSiteType } from "../../shared/context/AppInfoProvider";
import useUtils from "../../shared/hooks/useUtils";
import { DateFormatType } from "../../shared/utils/localizedUtil/localizedUtilDates";
import { enumColorSentEmailStatus } from "../../shared/utils/utilEnumColors";

const AdminSentEmailList = () => {
  const [apiAdminSentEmail] = useApiAdminProviderSentEmail();
  const [reloadGrid] = useState(new Date());
  const [modal] = useModal();
  const { utils } = useUtils();
  const { appInfo } = useContextAppInfo();

  const [initialFilters] = useState<AdminSentEmailFiltersDto>(() => {
    const filters = new AdminSentEmailFiltersDto();
    filters.crossShardIncludeAllProviders = appInfo?.siteType == AppInfoSiteType.SuperAdmin;
    return filters;
  });

  const itemTemplates: IGridItemTemplate<AdminSentEmailDto>[] = [
    {
      header: "Proveedor",
      field: nameof<AdminSentEmailDto>((p) => p.adminProvider.name),
      hideByDefault: appInfo?.siteType !== AppInfoSiteType.SuperAdmin,
      filter: {
        propertyName: nameof<AdminSentEmailFiltersDto>((p) => p.adminProvider),
        type: GridFilterType.adminProviders,
      },
      width: 10,
    },
    {
      header: "Fecha",
      field: nameof<AdminSentEmailDto>((p) => p.createdDate),
      filter: {
        propertyName: nameof<AdminSentEmailFiltersDto>((p) => p.createdDate),
        type: GridFilterType.dateTime,
      },
      width: 10,
      cellType: GridCellType.dateTime,
    },
    {
      header: "Para",
      field: nameof<AdminSentEmailDto>((p) => p.to),
      filter: {
        propertyName: nameof<AdminSentEmailFiltersDto>((p) => p.to),
        type: GridFilterType.text,
      },
      width: 15,
    },
    {
      header: "Plantilla",
      hideByDefault: true,
      field: nameof<AdminSentEmailDto>((p) => p.adminTemplateEmail.name),
      width: 10,
    },
    {
      header: "Desde",
      field: nameof<AdminSentEmailDto>((p) => p.from),
      filter: {
        propertyName: nameof<AdminSentEmailFiltersDto>((p) => p.from),
        type: GridFilterType.text,
      },
      width: 15,
    },
    {
      header: "Asunto",
      field: nameof<AdminSentEmailDto>((p) => p.subject),
      filter: {
        propertyName: nameof<AdminSentEmailFiltersDto>((p) => p.subject),
        type: GridFilterType.text,
      },
      width: 30,
    },
    {
      header: "Proveedor",
      field: nameof<AdminSentEmailDto>((p) => p.providerType),
      filter: {
        propertyName: nameof<AdminSentEmailFiltersDto>((p) => p.providerType),
        type: GridFilterType.enum,
        enumName: enumName.EmailProviderType,
      },
      width: 10,
      cellTypeEnumName: enumName.EmailProviderType,
    },
    {
      header: "Estado",
      field: nameof<AdminSentEmailDto>((p) => p.status),
      relatedFields: [nameof<AdminSentEmailDto>((p) => p.updatedDate)],
      filter: {
        propertyName: nameof<AdminSentEmailFiltersDto>((p) => p.statusList),
        type: GridFilterType.enumList,
        enumName: enumName.SentEmailStatus,
      },
      tooltip: (item: AdminSentEmailDto) =>
        utils.date.toString(item.updatedDate, DateFormatType.DateHourMinutes),
      width: 10,
      cellType: GridCellType.statusWithColor,
      cellTypeEnumName: enumName.SentEmailStatus,
      cellTypeMapColor: enumColorSentEmailStatus,
    },
  ];

  return (
    <Page title="Correos enviados">
      <Grid
        itemTemplates={itemTemplates}
        initialFilters={initialFilters}
        onSearch={(search, options) => apiAdminSentEmail.getPaged(search, options)}
        reloadGrid={reloadGrid}
        onDisplayItemMenu={(AdminSentEmailDto: AdminSentEmailDto) =>
          getAdminSentEmailListItemMenu(AdminSentEmailDto, modal)
        }
      />
    </Page>
  );
};

export default AdminSentEmailList;
