import Row, { PropsRow } from "./GRow";

const RowDisplay = (props: PropsRow) => {
  return (
    <Row {...props} isDisplay={true}>
      {props.children}
    </Row>
  );
};

export default RowDisplay;
