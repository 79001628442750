import Page from "../../shared/components/Layout/Page";
import ConfigurationEInvoice from "./Configuration.EInvoice";
import ConfigurationEmail from "./Configuration.Email";
import ConfigurationGeneral from "./Configuration.General";
import ConfigurationTemplates from "./Configuration.Templates";

const Configuration = () => {
  return (
    <Page title="Configuraciones">
      <ConfigurationEmail />
      <ConfigurationEInvoice />
      <ConfigurationTemplates />
      <ConfigurationGeneral />
    </Page>
  );
};

export default Configuration;
