import { AppSize } from "../../AppSize";
import { useApiAdminAccountPublicTemplateDesign } from "../../../api/shared/hooks/useApiAdmin";
import { TextAlign } from "../../TextAlign";
import Row from "../Layout/GRow";
import Spinner from "../Spinner";

const TemplateEditorPreviewPdfFrame = (props: { fileUrl: string; isLoading: boolean }) => {
  const [apiTemplateDesign] = useApiAdminAccountPublicTemplateDesign();
  if (props.isLoading) {
    return (
      <Row align={TextAlign.center} paddingTop={20}>
        <Spinner size={AppSize.bigger} />
      </Row>
    );
  }

  if (!props.fileUrl) {
    return <></>;
  }

  return (
    <embed
      className="editor-code-preview"
      src={props.fileUrl}
      type="application/pdf"
      onLoad={() => {
        const request = async () => {
          const urlParts = props.fileUrl.split("/");
          await apiTemplateDesign.previewPdfFileAck(urlParts[urlParts.length - 1], {
            preventSpinner: true,
          });
        };
        request();
      }}
    />
  );
};

export default TemplateEditorPreviewPdfFrame;
