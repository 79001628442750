import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { LongProcessStatusInfoDto } from "../../../api/shared/dtos/LongProcessStatusInfoDto";
import { LongProcessStatus } from "../../../api/shared/enums/LongProcessStatus";
import { useApiLongProcess } from "../../../api/shared/hooks/useApiApp";
import { AppSize } from "../../../shared/AppSize";
import ButtonIcon from "../../../shared/components/Button/ButtonIcon";
import { TypeIcon } from "../../../shared/components/Icon";
import InfoText from "../../../shared/components/InfoText";
import Row from "../../../shared/components/Layout/GRow";
import RowDisplay from "../../../shared/components/Layout/GRowDisplay";
import useUtils from "../../../shared/hooks/useUtils";

const LongProcessBar = (props: {
  processId?: string;
  onCancel?: () => void;
  onError?: (errorMessage: string) => void;
  onSuccess?: (successMessage?: string) => void;
}) => {
  const [longProcessStatus, setLongProcessStatus] = useState<LongProcessStatusInfoDto>();
  const [lastTimeChecked, setLastTimeChecked] = useState<Date>();
  const [apiLongProcess] = useApiLongProcess();
  const [isCancellationRequested, setIsCancellationRequested] = useState(false);
  const { utils } = useUtils();

  const cancel = async () => {
    await apiLongProcess.tryCancel(props.processId ?? "", {
      preventSpinner: true,
    });
    setIsCancellationRequested(true);
  };

  useEffect(() => {
    if (!props.processId) {
      setIsCancellationRequested(false);
      return;
    }

    const timer = setTimeout(() => {
      const request = async () => {
        const status = await apiLongProcess.getByProcessId(props.processId ?? "", {
          preventSpinner: true,
        });

        setLongProcessStatus(status);
        setLastTimeChecked(new Date());
        if (!status || status.status == LongProcessStatus.Done) {
          if (props.onSuccess) {
            props.onSuccess(status?.successMessage);
          }
          return;
        }

        if (status.status == LongProcessStatus.Error) {
          if (props.onError) {
            props.onError(status.errorMessage);
          }
          return;
        }

        if (status.status == LongProcessStatus.Cancelled) {
          if (props.onCancel) {
            props.onCancel();
          }
          return;
        }
      };
      request();
    }, 3000);
    return () => clearTimeout(timer);
  }, [props.processId, longProcessStatus, lastTimeChecked]);

  if (!props.processId) {
    return null;
  }

  if (!longProcessStatus) {
    return <LinearProgress variant="indeterminate" />;
  }

  if (longProcessStatus.status == LongProcessStatus.Cancelled) {
    return <InfoText>Cancelado</InfoText>;
  }

  if (longProcessStatus.status == LongProcessStatus.Done) {
    return <InfoText>Finalizado: {longProcessStatus.successMessage}</InfoText>;
  }

  return (
    <>
      <Row minHeightIgnore={true}>
        <LinearProgress variant="determinate" value={longProcessStatus.progress} />
      </Row>
      <RowDisplay>
        {(longProcessStatus.status == LongProcessStatus.Running ||
          longProcessStatus.status == LongProcessStatus.CancellationRequested) && (
          <ButtonIcon
            icon={TypeIcon.delete}
            size={AppSize.small}
            onClick={cancel}
            disabled={isCancellationRequested}
          />
        )}
        {longProcessStatus.progressMessage && (
          <>
            {utils.number.toIntString(longProcessStatus.progress)}%{" "}
            {longProcessStatus.progressMessage}
          </>
        )}
      </RowDisplay>
    </>
  );
};

export default LongProcessBar;
