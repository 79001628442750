import Page from "../../../shared/components/Layout/Page";
import LocaleProvider from "../../../shared/context/LocaleProvider";
import ConfigLocaleForm from "./ConfigLocale.Form";

const ConfigLocale = () => {
  return (
    <Page title="Configurar región">
      <LocaleProvider>
        <ConfigLocaleForm />
      </LocaleProvider>
    </Page>
  );
};

export default ConfigLocale;
