import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";

const LayoutMainContainer = (props: any) => {
  const appTheme = useAppTheme();
  return (
    <div
      className="layout-main-container"
      style={{ background: appTheme.toHexColor(AppThemeColor.layoutBackground) }}>
      {props.children}
    </div>
  );
};

export default LayoutMainContainer;
