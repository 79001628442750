// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { UserDto } from "./UserDto";
import { AdminProviderSimpleDto } from "./AdminProviderSimpleDto";

export class UserLinkedAdminProviderDto extends BaseEntityDto {
  userId: string = "";
  user: UserDto;
  adminProviderId: string;
  adminProvider: AdminProviderSimpleDto;
}
