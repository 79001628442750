import { CSSProperties, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import { SaleFiltersDto } from "../../api/app/dtos/SaleFiltersDto";
import { EInvoiceProcessStatus } from "../../api/shared/enums/EInvoiceProcessStatus";
import { GridName } from "../../api/shared/enums/GridName";
import { SaleDraftStatus } from "../../api/shared/enums/SaleDraftStatus";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiExportSale, useApiSale } from "../../api/shared/hooks/useApiApp";
import { AppRoute } from "../../AppRoutes";
import { useAppTheme } from "../../AppThemeProvider";
import { Constants } from "../../Constants";
import ButtonGrouped from "../../shared/components/Button/ButtonGrouped";
import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import Grid, { GridColumnAlign } from "../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
  MobileSpecialType,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import { TypeIcon } from "../../shared/components/Icon";
import Page from "../../shared/components/Layout/Page";
import { useModal } from "../../shared/hooks/useModal";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { useNotifier } from "../../shared/hooks/useNotifier";
import useUtils from "../../shared/hooks/useUtils";
import {
  enumIconEInvoiceProcessStatus,
  enumIconSaleDraftStatus,
} from "../../shared/utils/utilEnumIcons";
import { AppThemeColor } from "../../styles/color";
import GridCellContact from "../Contact/GridCellContact";
import SaleChoseTypeAdjustmentPopup from "./SaleChoseTypeAdjustmentPopup";
import SaleChoseTypeCancellationPopup from "./SaleChoseTypeCancellationPopup";
import { getSaleListItemMenu } from "./SaleList.ItemMenu";

const SaleList = () => {
  const [initialFilters] = useState(() => {
    const filters = new SaleFiltersDto();
    return filters;
  });

  const appTheme = useAppTheme();
  const navigation = useNavigation();
  const notifier = useNotifier();
  const { utils } = useUtils();
  const [apiSale] = useApiSale();
  const [apiExportSale] = useApiExportSale();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [modal] = useModal();

  //Keep in sync with SaleExcelExporter
  const itemTemplates: IGridItemTemplate<SaleDto>[] = [
    {
      field: nameof<SaleDto>((p) => p.pointOfSale.accountBranch.displayName),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.accountBranch),
        type: GridFilterType.accountBranch,
      },
      header: "Sucursal",
      width: 10,
      hideByDefault: true,
    },
    {
      field: nameof<SaleDto>((p) => p.pointOfSale.name),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.pointOfSale),
        type: GridFilterType.accountBranch,
      },
      header: "Punto de venta",
      width: 10,
      hideByDefault: true,
    },
    {
      field: nameof<SaleDto>((p) => p.date),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.operationDate),
        type: GridFilterType.date,
      },
      header: "Fecha",
      width: 10,
      cellType: GridCellType.date,
    },
    {
      field: nameof<SaleDto>((p) => p.issuedDate),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.issuedDate),
        type: GridFilterType.date,
      },
      header: "Fecha emisión",
      width: 10,
      hideByDefault: true,
      cellType: GridCellType.dateTime,
    },
    {
      field: nameof<SaleDto>((p) => p.createdDate),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.createdDate),
        type: GridFilterType.date,
      },
      header: "Fecha creación",
      width: 10,
      hideByDefault: true,
      cellType: GridCellType.dateTime,
    },
    {
      field: nameof<SaleDto>((p) => p.invoiceType),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.invoiceTypeList),
        type: GridFilterType.enumList,
        enumName: enumName.InvoiceType,
      },
      header: "Tipo",
      width: 10,
      cellTypeEnumName: enumName.InvoiceType,
    },
    {
      field: nameof<SaleDto>((p) => p.serieAndNumber),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.number),
        type: GridFilterType.numberRangeInteger,
      },
      header: "Número",
      mobileSpecialType: MobileSpecialType.Main,
      orderBy: nameof<SaleDto>((p) => p.serie) + " " + nameof<SaleDto>((p) => p.number),
      width: 10,
      render: (sale: SaleDto) => {
        if (sale.draftStatus != SaleDraftStatus.Issued) {
          return (
            <ButtonIcon
              noPadding={true}
              icon={TypeIcon.draft}
              tooltip="Editar borrador"
              onClick={() => {
                navigation.go(AppRoute.sales.draftEditById(sale.id));
              }}
            />
          );
        }
        return sale.serieAndNumber;
      },
    },
    {
      field: nameof<SaleDto>((p) => p.contact.name),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.contact),
        type: GridFilterType.customers,
      },
      header: "Cliente",
      relatedFields: [nameof<SaleDto>((p) => p.contactId)],
      mobileSpecialType: MobileSpecialType.Secondary,
      width: 30,
      render: (sale: SaleDto) => (
        <GridCellContact contactId={sale.contactId} contactName={sale.contact?.name} />
      ),
    },
    {
      field: nameof<SaleDto>((p) => p.receiver.companyName),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.receiverName),
        type: GridFilterType.text,
      },
      header: "Razón social",
      hideByDefault: true,
      width: 20,
    },
    {
      field: nameof<SaleDto>((p) => p.receiver.idCardNumber),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.receiverIdCardNumber),
        type: GridFilterType.text,
      },
      header: "RUT",
      hideByDefault: true,
      width: 10,
    },
    {
      field: nameof<SaleDto>((p) => p.accountUser.email),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.accountUser),
        type: GridFilterType.accountUser,
      },
      header: "Usuario",
      hideByDefault: true,
      width: 10,
    },
    {
      field: nameof<SaleDto>((p) => p.currency),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.currency),
        type: GridFilterType.currency,
      },
      header: "Moneda",
      width: 5,
      cellTypeEnumName: enumName.Currency,
    },
    {
      field: nameof<SaleDto>((p) => p.currencyExchangeRate),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.currency),
        type: GridFilterType.numberRange,
      },
      header: "Cotización",
      hideByDefault: true,
      width: 10,
      align: GridColumnAlign.right,
      cellType: GridCellType.decimal,
      cellTypeDecimals: Constants.CURRENCY_EXCHANGE_DECIMALS,
    },
    {
      field: nameof<SaleDto>((p) => p.subtotal),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.subtotal),
        type: GridFilterType.numberRange,
      },
      header: "Subtotal",
      hideByDefault: true,
      width: 10,
      align: GridColumnAlign.right,
      cellType: GridCellType.decimal,
    },
    {
      field: nameof<SaleDto>((p) => p.total),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.total),
        type: GridFilterType.numberRange,
      },
      header: "Total",
      hideByDefault: true,
      width: 10,
      align: GridColumnAlign.right,
      cellStyle: { fontWeight: 600 },
      cellType: GridCellType.decimal,
    },
    {
      field: nameof<SaleDto>((p) => p.calculatedNationalCurrencyTotal),
      // filter: {
      //   propertyName: nameof<SaleFiltersDto>((p) => p.total),
      //   type: GridFilterType.numberRange,
      // },
      header: "Total (Moneda nacional)",
      orderByPrevent: true,
      hideByDefault: true,
      width: 10,
      align: GridColumnAlign.right,
      cellStyle: { fontWeight: 600 },
      cellType: GridCellType.decimal,
    },
    {
      field: nameof<SaleDto>((p) => p.totalToPay),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.totalToPay),
        type: GridFilterType.numberRange,
      },
      header: "A Pagar",
      width: 10,
      align: GridColumnAlign.right,
      cellStyle: { fontWeight: 600 },
      cellType: GridCellType.decimal,
    },
    {
      field: nameof<SaleDto>((p) => p.draftStatus),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.saleDraftStatus),
        type: GridFilterType.enum,
        enumName: enumName.SaleDraftStatus,
      },
      header: "Estado borrador",
      width: 5,
      hideByDefault: true,
      align: GridColumnAlign.center,
      cellType: GridCellType.statusWithIcon,
      cellTypeEnumName: enumName.SaleDraftStatus,
      cellTypeMapIcon: enumIconSaleDraftStatus,
    },
    {
      field: nameof<SaleDto>((p) => p.eInvoiceProcessStatus),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.eInvoiceProcessStatusList),
        type: GridFilterType.enumList,
        enumName: enumName.EInvoiceProcessStatus,
      },
      header: "eFactura",
      width: 5,
      align: GridColumnAlign.center,
      cellType: GridCellType.statusWithIcon,
      cellTypeEnumName: enumName.EInvoiceProcessStatus,
      cellTypeMapIcon: enumIconEInvoiceProcessStatus,
    },
    {
      field: nameof<SaleDto>((p) => p.eInvoiceCompanySentStatus),
      filter: {
        propertyName: nameof<SaleFiltersDto>((p) => p.eInvoiceCompanySentStatusList),
        type: GridFilterType.enumList,
        enumName: enumName.EInvoiceProcessStatus,
      },
      header: "Receptor",
      width: 5,
      align: GridColumnAlign.center,
      cellType: GridCellType.statusWithIcon,
      cellTypeEnumName: enumName.EInvoiceProcessStatus,
      cellTypeMapIcon: enumIconEInvoiceProcessStatus,
    },
    {
      field: nameof<SaleDto>((p) => p.pdfUrl),
      header: "PDF",
      hideByDefault: true,
      orderByPrevent: true,
      width: 5,
      align: GridColumnAlign.center,
      cellType: GridCellType.download,
    },
    {
      field: nameof<SaleDto>((p) => p.xmlUrl),
      header: "XML",
      hideByDefault: true,
      orderByPrevent: true,
      width: 5,
      align: GridColumnAlign.center,
      cellType: GridCellType.download,
    },
  ];

  return (
    <Page
      fixedHeight={true}
      title="Ventas"
      titleButton={
        <ButtonGrouped
          text="Nueva factura"
          icon={TypeIcon.create}
          onClick={() => navigation.go(AppRoute.sales.create)}
          actions={[
            {
              text: "Nueva devolución",
              icon: TypeIcon.salesCancellation,
              onClick: () => modal.open(<SaleChoseTypeCancellationPopup />),
            },
            {
              text: "Nuevo ajuste",
              icon: TypeIcon.salesAdjust,
              onClick: () => modal.open(<SaleChoseTypeAdjustmentPopup />),
            },
            {
              text: "Nueva boleta de entrada",
              icon: TypeIcon.entranceTicket,
              onClick: () => navigation.go(AppRoute.sales.createEntranceTicket),
            },
            {
              text: "Nuevo remito",
              icon: TypeIcon.salesDeliveryNote,
              onClick: () => navigation.go(AppRoute.sales.createDeliveryNote),
            },
            {
              text: "Nuevo resguardo",
              icon: TypeIcon.salesReceipt,
              onClick: () => navigation.go(AppRoute.sales.createReceipt),
            },
            {
              text: "Nueva exportación",
              icon: TypeIcon.salesExport,
              onClick: () => navigation.go(AppRoute.sales.createExport),
            },
            {
              text: "Nueva venta por cuenta ajena",
              icon: TypeIcon.salesInBehalf,
              onClick: () => navigation.go(AppRoute.sales.createForSenderInBehalf),
            },
          ]}
        />
      }>
      <Grid
        itemTemplates={itemTemplates}
        initialFilters={initialFilters}
        mustIncludeFields={[
          nameof<SaleDto>((p) => p.draftStatus),
          nameof<SaleDto>((p) => p.pdfUrl),
          nameof<SaleDto>((p) => p.xmlUrl),
          nameof<SaleDto>((p) => p.isCanceled),
        ]}
        gridName={GridName.Sales}
        reloadGrid={reloadGrid}
        onExport={(exportRequest, apiOptions) =>
          apiExportSale.default(exportRequest as any, apiOptions)
        }
        onGetRowStyle={(sale: SaleDto) => {
          const style = {} as CSSProperties;
          if (sale.draftStatus == SaleDraftStatus.Draft) {
            style.background = appTheme.toHexColor(AppThemeColor.info);
          }

          if (sale.draftStatus == SaleDraftStatus.Discarded || sale.isCanceled) {
            style.color = appTheme.toHexColor(AppThemeColor.inactive);
          }

          if (sale.eInvoiceProcessStatus >= EInvoiceProcessStatus.ErrorXmlProcessing) {
            style.background = appTheme.toHexColor(AppThemeColor.alertLight);
          }
          return style;
        }}
        onDisplayItemMenu={(saleDto: SaleDto) =>
          getSaleListItemMenu(saleDto, modal, navigation, apiSale, utils, notifier, () => {
            setReloadGrid(new Date());
          })
        }
        onSearch={(search, options) => apiSale.getPaged(search, options)}
      />
    </Page>
  );
};

export default SaleList;
