// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AccountUserDto } from "../dtos/AccountUserDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { AccountUserFiltersDto } from "../dtos/AccountUserFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { SuggestResponseDto } from "../../shared/dtos/SuggestResponseDto";
import { SuggestSearchDto } from "../../shared/dtos/SuggestSearchDto";

export class ApiAccountUser {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<AccountUserFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AccountUserDto>> {
    return this.apiService.apiPost("/account-users/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AccountUserDto> {
    return this.apiService.apiGet("/account-users/" + id, apiOptions);
  }

  inactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/account-users/inactivate/" + id, null, apiOptions);
  }

  suggestAll(
    suggestSearch: SuggestSearchDto<AccountUserFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/account-users/suggest-all", suggestSearch, apiOptions);
  }

  reactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/account-users/reactivate/" + id, null, apiOptions);
  }

}
