// File generated by Gesta.Build.PostEvents
import { AdminTemplateGenericSimpleDto } from "./AdminTemplateGenericSimpleDto";
import { AdminAccountSimpleDto } from "../../shared/dtos/AdminAccountSimpleDto";
import { Country } from "../../shared/enums/Country";

export class AdminTemplateGenericDto extends AdminTemplateGenericSimpleDto {
  adminAccountId: string;
  adminAccount: AdminAccountSimpleDto;
  body: string = "";
  country: Country;
  isDefault: boolean = false;
  styles: string = "";
}
