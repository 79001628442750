import { Box, FormControlLabel, Switch } from "@mui/material";
import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import PropsInputBase from "./PropsInputBase";

export interface PropsInputSwitch extends PropsInputBase<boolean | null> {
  labelPlacement?: "start" | "end";
  size?: AppSize;
  fontColor?: AppThemeColor;
  fontSize?: number | string;
  handleFalseAsNull?: boolean;
}

const InputSwitch = (props: PropsInputSwitch) => {
  const appTheme = useAppTheme();

  if (props.hintAutoHeight === undefined) {
    props = { ...props, hintAutoHeight: true };
  }

  let fontSize = props.fontSize;
  if (!props.fontSize) {
    if (props.size == AppSize.small) {
      fontSize = ".5em";
    } else if (props.size == AppSize.medium) {
      fontSize = ".8em";
    } else {
      fontSize = undefined;
    }
  }

  return (
    <FormControlLabel
      labelPlacement={props.labelPlacement}
      control={
        <Switch
          checked={props.value ?? false}
          size="small"
          disabled={props.readOnly}
          onChange={(event) => {
            let value: boolean | null = event.target.checked;
            if (props.handleFalseAsNull && !value) {
              value = null;
            }
            props.onChange(value);
          }}
        />
      }
      label={
        <Box
          component="div"
          style={{ color: appTheme.toHexColor(props.fontColor), fontSize: fontSize }}>
          {props.label ?? ""}
        </Box>
      }
    />
  );
};

export default InputSwitch;
