import { ApiAdminAccountPublicTemplateDesign } from "../../../api/admin/apis/ApiAdminAccountPublicTemplateDesign";
import { ApiAdminAccountPublicTemplatePdf } from "../../../api/admin/apis/ApiAdminAccountPublicTemplatePdf";
import { AdminTemplateDesignPdfTestDto } from "../../../api/admin/dtos/AdminTemplateDesignPdfTestDto";
import { AdminTemplatePdfDto } from "../../../api/admin/dtos/AdminTemplatePdfDto";
import { AppRoute } from "../../../AppRoutes";
import { ContextMenuItem } from "../../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../../shared/components/Icon";
import { AppInfoSiteType } from "../../../shared/context/AppInfoProvider";
import { Navigation } from "../../../shared/hooks/useNavigation";

export function getAdminTemplatePdfListItemMenu(
  adminTemplatePdfDto: AdminTemplatePdfDto,
  apiAdminTemplatePdf: ApiAdminAccountPublicTemplatePdf,
  apiAdminTemplateDesign: ApiAdminAccountPublicTemplateDesign,
  appInfoSiteType: AppInfoSiteType,
  navigation: Navigation
): ContextMenuItem[] {
  return [
    {
      text: "Descargar documento de prueba",
      icon: TypeIcon.pdf,
      onClick: async () => {
        const getDesignTestDto = async () => {
          const template = await apiAdminTemplatePdf.getById(adminTemplatePdfDto.id);
          const design = await apiAdminTemplateDesign.getTemplateDesign(template.type);
          const designTestDto = new AdminTemplateDesignPdfTestDto();
          designTestDto.templatePdf = template as AdminTemplatePdfDto;
          designTestDto.template = template;
          designTestDto.previewJsonData = JSON.stringify(design.previewData ?? "");
          return designTestDto;
        };
        apiAdminTemplateDesign.pdfTestDownload(await getDesignTestDto());
      },
    },
    {
      text: "Editar",
      hide: appInfoSiteType != AppInfoSiteType.SuperAdmin,
      icon: TypeIcon.edit,
      onClick: () => {
        navigation.go(AppRoute.admin.templates.pdf.edit, adminTemplatePdfDto.id);
      },
    },
  ];
}
