import TabGroup from "../Layout/TabGroup";
import TabView from "../Layout/TabView";
import TemplateEditorPreview from "./TemplateEditorPreview";

const TABS = {
  PREVIEW: "PREVIEW",
};

const TemplateEditorPanelRight = (props: { previewJsonData?: string; onCollapse: () => void }) => {
  return (
    <TabGroup tabs={[{ title: "Preview", value: TABS.PREVIEW }]} selectedTab={TABS.PREVIEW}>
      <TabView value={TABS.PREVIEW}>
        <TemplateEditorPreview
          previewJsonData={props.previewJsonData}
          onCollapse={props.onCollapse}
        />
      </TabView>
      <></>
    </TabGroup>
  );
};

export default TemplateEditorPanelRight;
