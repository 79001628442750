// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { LoggingConfigCategoryDto } from "../dtos/LoggingConfigCategoryDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminLoggingConfig {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getDefaultLogLevel(
    apiOptions?: ApiOptions
  ): Promise<LoggingConfigCategoryDto> {
    return this.apiService.apiGet("/admin/logging-config/default-level", apiOptions);
  }

  getGestaAll(
    apiOptions?: ApiOptions
  ): Promise<LoggingConfigCategoryDto[]> {
    return this.apiService.apiGet("/admin/logging-config/gesta-all", apiOptions);
  }

  logTest(
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/admin/logging-config/log-test", null, apiOptions);
  }

  getSqlQueryIsEnabled(
    apiOptions?: ApiOptions
  ): Promise<boolean> {
    return this.apiService.apiGet("/admin/logging-config/sql-query-is-enabled", apiOptions);
  }

  setSqlQueryIsEnabled(
    value: boolean,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin/logging-config/set-sql-query-is-enabled?" + 
      (value ? "&value=" + value : ""), null, apiOptions);
  }

  throwTestException(
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiGet("/admin/logging-config/throw-test-exception", apiOptions);
  }

  setDefaultLevel(
    loggingLevel: number,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin/logging-config/set-default-level?" + 
      (loggingLevel ? "&loggingLevel=" + loggingLevel : ""), null, apiOptions);
  }

  resetAll(
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin/logging-config/reset-all", null, apiOptions);
  }

  setCategoryLevel(
    categoryName: string,
    loggingLevel: number | null,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin/logging-config/set-category-level?" + 
      (categoryName ? "&categoryName=" + categoryName : "") + 
      (loggingLevel ? "&loggingLevel=" + loggingLevel : ""), null, apiOptions);
  }

}
