import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AdminTemplateGenericDto } from "../../../api/admin/dtos/AdminTemplateGenericDto";
import Page from "../../../shared/components/Layout/Page";
import { useApiAdminAccountPublicTemplateGeneric } from "../../../api/shared/hooks/useApiAdmin";
import { useNotifier } from "../../../shared/hooks/useNotifier";
import { StateProvider } from "../../../shared/modelState/ModelStateContext";
import AdminTemplateGenericForm from "./AdminTemplateGenericForm";
import { useAdminTemplateGenericValidation } from "./Validations/useAdminTemplateGenericValidation";

type TParams = { id: string };

const AdminTemplateGenericEdit = () => {
  const { id } = useParams<TParams>();

  const [adminTemplateGeneric, setAdminTemplateGeneric] = useState<AdminTemplateGenericDto>();
  const [apiTemplateGeneric] = useApiAdminAccountPublicTemplateGeneric();
  const notifier = useNotifier();

  useEffect(() => {
    const loadAdminTemplateGeneric = async () => {
      setAdminTemplateGeneric(await apiTemplateGeneric.getById(id ?? ""));
    };
    loadAdminTemplateGeneric();
  }, [id]);

  const save = async (adminTemplateGeneric: AdminTemplateGenericDto) => {
    await apiTemplateGeneric.edit(adminTemplateGeneric);
    notifier.showSuccess("La plantilla se editó con éxito");
  };

  if (!adminTemplateGeneric) {
    return <></>;
  }

  return (
    <Page title="Editar plantilla genérica">
      <StateProvider
        model={adminTemplateGeneric}
        onSubmit={save}
        validationSchema={useAdminTemplateGenericValidation()}>
        {(p) => <AdminTemplateGenericForm onSave={p.handleSubmit} />}
      </StateProvider>
    </Page>
  );
};

export default AdminTemplateGenericEdit;
