export const TemplateConstants = {
  TAG_GENERIC_TEMPLATE_CONTENT: "##TEMPLATE_CONTENT##",
  TAG_VALUE: "%%",
  TAG_OPERATOR: "##",
  TAG_OPERATOR_SPLIT: ":",
  TAG_OPERATOR_REPEAT: "REPEAT",
  TAG_OPERATOR_IF: "IF",
  TAG_OPERATOR_IF_NOT: "IF_NOT",
  TAG_OPERATOR_END: "END_",
  TAG_OPERATOR_TEMPLATE_GENERIC: "TEMPLATE",
  TAG_OPERATOR_TEMPLATE_CONTAINER: "TEMPLATE_CONTAINER",
};

export const TemplateGetTagOpen = (operator: string, fieldName: string) =>
  TemplateConstants.TAG_OPERATOR +
  operator +
  TemplateConstants.TAG_OPERATOR_SPLIT +
  fieldName +
  TemplateConstants.TAG_OPERATOR;

export const TemplateGetTagClose = (operator: string, fieldName: string) =>
  TemplateConstants.TAG_OPERATOR +
  TemplateConstants.TAG_OPERATOR_END +
  operator +
  TemplateConstants.TAG_OPERATOR_SPLIT +
  fieldName +
  TemplateConstants.TAG_OPERATOR;
