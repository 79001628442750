import { forwardRef } from "react";
import { nameof } from "ts-simple-nameof";
import { ProductDto } from "../../../../api/app/dtos/ProductDto";
import InputAutocompleteProduct, {
  PropsInputAutocompleteProduct,
} from "../../inputs/autocomplete/InputAutocompleteProduct";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormAutocompleteProduct
  extends PropsFormInput<ProductDto>,
    Omit<
      PropsInputAutocompleteProduct,
      "value" | "onChange" | "onCreateNewClick" | "onGetEntity" | "onServerSideSuggest"
    > {}

const FormAutocompleteProduct = forwardRef((props: PropsFormAutocompleteProduct, ref?: any) => {
  const inputProps = useFormInputInit(props, ref, {
    updateIdLinkedProperty: true,
    inputTextValuePropertyName: nameof<ProductDto>((p) => p.name),
    delayTouchedOnBlur: 500, //Delay to avoid show validation errors when user selects a product
  });
  return <InputAutocompleteProduct {...props} {...inputProps} />;
});

export default FormAutocompleteProduct;
