import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminTemplateEmailDto } from "../../../api/admin/dtos/AdminTemplateEmailDto";
import { enumName } from "../../../api/shared/enums/_enumName";
import { AppRoute } from "../../../AppRoutes";
import { ButtonCreate } from "../../../shared/components/Button/ButtonCreate";
import Grid from "../../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
} from "../../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../../shared/components/Layout/Page";
import { useApiAdminAccountPublicTemplateEmail } from "../../../api/shared/hooks/useApiAdmin";
import { useModal } from "../../../shared/hooks/useModal";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import { useNotifier } from "../../../shared/hooks/useNotifier";
import { getAdminTemplateEmailListItemMenu } from "./AdminTemplateEmailList.ItemMenu";
import { AdminTemplateEmailFiltersDto } from "../../../api/admin/dtos/AdminTemplateEmailFiltersDto";
import { useContextAppInfo } from "../../../shared/hooks/useContextAppInfo";
import { AppInfoSiteType } from "../../../shared/context/AppInfoProvider";

const AdminTemplateEmailList = () => {
  const navigation = useNavigation();
  const notifier = useNotifier();
  const [modal] = useModal();
  const { appInfo } = useContextAppInfo();
  const [apiTemplateEmail] = useApiAdminAccountPublicTemplateEmail();
  const [reloadGrid, setReloadGrid] = useState(new Date());

  const itemTemplates: IGridItemTemplate<AdminTemplateEmailDto>[] = [
    {
      header: "Tipo",
      field: nameof<AdminTemplateEmailDto>((p) => p.type),
      filter: {
        propertyName: nameof<AdminTemplateEmailFiltersDto>((p) => p.templateType),
        type: GridFilterType.enum,
        enumName: enumName.TemplateType,
      },
      width: 15,
      cellTypeEnumName: enumName.TemplateType,
    },
    {
      header: "Cuenta",
      field: nameof<AdminTemplateEmailDto>((p) => p.adminAccount.name),
      filter: {
        propertyName: nameof<AdminTemplateEmailFiltersDto>((p) => p.adminAccount),
        type: GridFilterType.account,
      },
      width: 15,
    },
    {
      header: "Nombre",
      field: nameof<AdminTemplateEmailDto>((p) => p.name),
      filter: {
        propertyName: nameof<AdminTemplateEmailFiltersDto>((p) => p.name),
      },
      width: 15,
    },
    {
      header: "Asunto",
      field: nameof<AdminTemplateEmailDto>((p) => p.subject),
      filter: {
        propertyName: nameof<AdminTemplateEmailFiltersDto>((p) => p.subject),
      },
      width: 50,
    },
    {
      header: "Por defecto",
      field: nameof<AdminTemplateEmailDto>((p) => p.isDefault),
      filter: {
        propertyName: nameof<AdminTemplateEmailFiltersDto>((p) => p.isDefault),
        type: GridFilterType.boolean,
      },
      width: 10,
      cellType: GridCellType.iconCheck,
    },
    {
      header: "Admin",
      field: nameof<AdminTemplateEmailDto>((p) => p.isAdmin),
      filter: {
        propertyName: nameof<AdminTemplateEmailFiltersDto>((p) => p.isAdmin),
        type: GridFilterType.boolean,
      },
      width: 10,
      cellType: GridCellType.iconCheck,
    },
  ];

  if (!appInfo) {
    return <></>;
  }

  return (
    <Page
      title="Plantillas de correo"
      titleButton={
        <ButtonCreate
          text="Nueva plantilla"
          disabled={appInfo.siteType !== AppInfoSiteType.SuperAdmin}
          onClick={() => navigation.go(AppRoute.admin.templates.email.create)}
        />
      }>
      <Grid
        itemTemplates={itemTemplates}
        onSearch={(search, options) => apiTemplateEmail.getPaged(search, options)}
        reloadGrid={reloadGrid}
        onDisplayItemMenu={(adminTemplateEmailDto: AdminTemplateEmailDto) =>
          getAdminTemplateEmailListItemMenu(
            adminTemplateEmailDto,
            apiTemplateEmail,
            appInfo.siteType,
            navigation,
            modal,
            notifier,
            setReloadGrid
          )
        }
      />
    </Page>
  );
};

export default AdminTemplateEmailList;
