import { MutableRefObject, ReactElement } from "react";
import { TemplateGroup } from "../../../api/shared/enums/TemplateGroup";
import { TextAlign } from "../../TextAlign";
import ButtonLink from "../Button/ButtonLink";
import CodeEditor, { CodeEditorLang } from "../CodeEditor";
import InfoText from "../InfoText";
import { TypeIcon } from "../Icon";
import Column from "../Layout/GColumn";
import Row from "../Layout/GRow";
import { TemplateGenericType } from "../../../api/shared/enums/TemplateGenericType";
import { TemplateConstants } from "./TemplateConstants";
import { AdminTemplateDto } from "../../../api/admin/dtos/AdminTemplateDto";
import { templateGetGroup } from "../../utils/utilTemplate";
import { AdminTemplateGenericDto } from "../../../api/admin/dtos/AdminTemplateGenericDto";

const TemplateEditorHtml = (props: {
  bodyRef?: MutableRefObject<any>;
  expandPreviewPanelButton?: ReactElement;
  height?: number;
  scrollPosX?: number;
  scrollPosY?: number;
  template: AdminTemplateDto;
  value: string;
  onChange: (value: string) => void;
  onScroll?: (positionX: number, positionY: number) => void;
  onInsertPageBreak?: () => void;
}) => {
  const helpText =
    props.template instanceof AdminTemplateGenericDto
      ? (props.template as AdminTemplateGenericDto).genericType == TemplateGenericType.Container
        ? "Deberás agregar el tag " +
          TemplateConstants.TAG_GENERIC_TEMPLATE_CONTENT +
          " para indicar donde se incluye el contenido."
        : ""
      : "En el panel de las izquierda podés seleccionar los campos disponibles que se reemplazarán al generar el " +
        (templateGetGroup(props.template.type) == TemplateGroup.Pdf ? "pdf" : "correo") +
        ".";

  return (
    <>
      <Row>
        <InfoText>
          Aquí podés ingresar el código <strong>HTML</strong> de la plantilla. {helpText}
        </InfoText>
      </Row>
      <Row>
        <CodeEditor
          height={props.height}
          lang={CodeEditorLang.html}
          ref={props.bodyRef}
          value={props.value ?? ""}
          scrollPosX={props.scrollPosX}
          scrollPosY={props.scrollPosY}
          onChange={props.onChange}
          onScroll={props.onScroll}
        />
      </Row>
      <Column md={props.expandPreviewPanelButton ? 10 : 12}>
        {props.onInsertPageBreak && (
          <ButtonLink
            text="Salto de página"
            icon={TypeIcon.pageBreak}
            onClick={props.onInsertPageBreak}
          />
        )}
      </Column>
      {props.expandPreviewPanelButton && (
        <Column md={2} align={TextAlign.right}>
          {props.expandPreviewPanelButton}
        </Column>
      )}
    </>
  );
};

export default TemplateEditorHtml;
