import Column from "../../shared/components/Layout/GColumn";
import Section from "../../shared/components/Layout/GSection";
import Page from "../../shared/components/Layout/Page";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";

const MyAccountUser = () => {
  const { loginInfo } = useContextLoginInfo();
  return (
    <Page title="Mi usuario">
      <Section title="Información general">
        <Column label="Correo">{loginInfo.authInfo.user.email}</Column>
        <Column label="Nombre">
          {loginInfo.authInfo.user.firstName} {loginInfo.authInfo.user.lastName}
        </Column>
      </Section>
    </Page>
  );
};

export default MyAccountUser;
