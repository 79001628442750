// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { InvoiceRetentionType } from "../../shared/enums/InvoiceRetentionType";

export class InvoiceLineRetentionDto extends BaseEntityDto {
  amount: number = 0;
  code: string = "";
  detail: string = "";
  rate: number = 0;
  totalAmount: number = 0;
  type: InvoiceRetentionType;
}
