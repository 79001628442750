import { forwardRef } from "react";
import { nameof } from "ts-simple-nameof";
import { AccountBranchDto } from "../../../../api/app/dtos/AccountBranchDto";
import InputAutocompleteAccountBranch, {
  PropsInputAutocompleteAccountBranch,
} from "../../inputs/autocomplete/InputAutocompleteAccountBranch";
import { OmitFormAutocompleteProps } from "../../inputs/autocomplete/InputAutocompleteBase";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormAutocompleteAccountBranch
  extends PropsFormInput<AccountBranchDto>,
    Omit<PropsInputAutocompleteAccountBranch, OmitFormAutocompleteProps> {}

const FormAutocompleteAccountBranch = forwardRef(
  (props: PropsFormAutocompleteAccountBranch, ref?: any) => {
    const inputProps = useFormInputInit(props, ref, {
      updateIdLinkedProperty: true,
      inputTextValuePropertyName: nameof<AccountBranchDto>((p) => p.branchName),
    });
    return <InputAutocompleteAccountBranch {...props} {...inputProps} />;
  }
);

export default FormAutocompleteAccountBranch;
