import { EmailConfigDto } from "../../../api/app/dtos/EmailConfigDto";

export enum EmailVerificationStatus {
  Pending = 0,
  Verifying = 1,
  Success = 2,
  Error = 3,
}

export class EmailConfigViewModel extends EmailConfigDto {
  smtpVerificationStatus: EmailVerificationStatus;
  imapVerificationStatus: EmailVerificationStatus;
}
