import { forwardRef } from "react";
import InputTaxPayerId, { PropsInputTaxPayerId } from "../inputs/InputTaxPayerId";
import { PropsFormInput, useFormInputInit } from "./_formInputProps";

export interface PropsFormTaxPayerId
  extends PropsFormInput<string | null>,
    Omit<PropsInputTaxPayerId, "value" | "onChange"> {}

const FormTaxPayerId = forwardRef((props: PropsFormTaxPayerId, ref: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputTaxPayerId {...props} {...inputProps} />;
});

export default FormTaxPayerId;
