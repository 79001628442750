import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { EInvoiceEnvelopePurchaseDto } from "../../../../api/app/dtos/EInvoiceEnvelopePurchaseDto";
import { EInvoiceEnvelopePurchaseFiltersDto } from "../../../../api/app/dtos/EInvoiceEnvelopePurchaseFiltersDto";
import { enumName } from "../../../../api/shared/enums/_enumName";
import { useApiEInvoiceEnvelopePurchase } from "../../../../api/shared/hooks/useApiApp";
import ButtonSecondary from "../../../../shared/components/Button/ButtonSecondary";
import Grid, { GridColumnAlign } from "../../../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
} from "../../../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import { TypeIcon } from "../../../../shared/components/Icon";
import RowButtonsToolbar from "../../../../shared/components/Layout/GRowButtonsToolbar";
import Page from "../../../../shared/components/Layout/Page";
import { useModal } from "../../../../shared/hooks/useModal";
import { enumColorEInvoiceEnvelopeStatus } from "../../../../shared/utils/utilEnumColors";
import GridCellContact from "../../../Contact/GridCellContact";
import EInvoiceEnvelopePurchaseUploadPopup from "../EInvoiceEnvelopePurchaseUploadPopup";
import { getEInvoiceEnvelopePurchaseListItemMenu } from "./EInvoiceEnvelopePurchaseList.ItemMenu";

const EInvoiceEnvelopePurchaseList = () => {
  const [initialFilters] = useState(() => {
    return new EInvoiceEnvelopePurchaseFiltersDto();
  });

  const [apiEInvoiceEnvelopePurchase] = useApiEInvoiceEnvelopePurchase();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [modal] = useModal();

  const itemTemplates: IGridItemTemplate<EInvoiceEnvelopePurchaseDto>[] = [
    {
      field: nameof<EInvoiceEnvelopePurchaseDto>((p) => p.createdDate),
      filter: {
        propertyName: nameof<EInvoiceEnvelopePurchaseFiltersDto>((p) => p.createdDate),
        type: GridFilterType.dateTime,
      },
      header: "Creación",
      width: 10,
      cellType: GridCellType.dateTime,
    },
    {
      field: nameof<EInvoiceEnvelopePurchaseDto>((p) => p.senderTaxPayerId),
      filter: {
        propertyName: nameof<EInvoiceEnvelopePurchaseFiltersDto>((p) => p.taxPayerId),
      },
      header: "RUT",
      width: 10,
    },
    {
      field: nameof<EInvoiceEnvelopePurchaseDto>((p) => p.contact),
      header: "Proveedor",
      filter: {
        propertyName: nameof<EInvoiceEnvelopePurchaseFiltersDto>((p) => p.contact),
        type: GridFilterType.providers,
      },
      width: 10,
      render: (item: EInvoiceEnvelopePurchaseDto) => {
        return (
          <GridCellContact
            contactId={item.contactId}
            contactName={item.contact?.name}
            taxPayerId={item.contact?.taxPayerId}
            onContactCreated={() => {
              setReloadGrid(new Date());
            }}
          />
        );
      },
    },
    {
      field: nameof<EInvoiceEnvelopePurchaseDto>((p) => p.eInvoicesCount),
      header: "Comprobantes",
      width: 5,
      cellType: GridCellType.integer,
    },
    {
      field: nameof<EInvoiceEnvelopePurchaseDto>((p) => p.internalSenderId),
      header: "Id interno",
      filter: {
        propertyName: nameof<EInvoiceEnvelopePurchaseFiltersDto>((p) => p.internalSenderId),
      },
      width: 5,
      align: GridColumnAlign.right,
    },
    {
      field: nameof<EInvoiceEnvelopePurchaseDto>((p) => p.receiverId),
      header: "Id receptor",
      cellType: GridCellType.integer,
      width: 5,
    },
    {
      field: nameof<EInvoiceEnvelopePurchaseDto>((p) => p.responseEmailTo),
      filter: {
        propertyName: nameof<EInvoiceEnvelopePurchaseFiltersDto>((p) => p.responseEmailTo),
      },
      header: "Correo respuesta",
      width: 10,
    },
    {
      field: nameof<EInvoiceEnvelopePurchaseDto>((p) => p.status),
      filter: {
        propertyName: nameof<EInvoiceEnvelopePurchaseFiltersDto>((p) => p.statusList),
        type: GridFilterType.enumList,
        enumName: enumName.EInvoiceEnvelopeStatus,
      },
      relatedFields: [
        nameof<EInvoiceEnvelopePurchaseDto>((p) => p.errorMessage),
        nameof<EInvoiceEnvelopePurchaseDto>((p) => p.rejectReasonDetails),
      ],
      header: "Estado",
      width: 10,
      cellType: GridCellType.statusWithColor,
      cellTypeEnumName: enumName.EInvoiceEnvelopeStatus,
      cellTypeMapColor: enumColorEInvoiceEnvelopeStatus,
      tooltip: (item: EInvoiceEnvelopePurchaseDto) =>
        item.rejectReasonDetails ? item.rejectReasonDetails : item.errorMessage,
    },
  ];

  return (
    <Page title="Sobres de compras recibidos">
      <RowButtonsToolbar>
        <ButtonSecondary
          text="Importar CFE"
          icon={TypeIcon.uploadFile}
          onClick={() => {
            modal.open(<EInvoiceEnvelopePurchaseUploadPopup />, () => setReloadGrid(new Date()));
          }}
        />
      </RowButtonsToolbar>
      <Grid
        hideIncludeInactiveButton={true}
        itemTemplates={itemTemplates}
        initialFilters={initialFilters}
        reloadGrid={reloadGrid}
        onSearch={(search, options) => apiEInvoiceEnvelopePurchase.getPaged(search, options)}
        onDisplayItemMenu={(EInvoiceEnvelopePurchaseDto: EInvoiceEnvelopePurchaseDto) =>
          getEInvoiceEnvelopePurchaseListItemMenu(EInvoiceEnvelopePurchaseDto, modal, () => {
            setReloadGrid(new Date());
          })
        }
      />
    </Page>
  );
};

export default EInvoiceEnvelopePurchaseList;
