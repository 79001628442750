import { nameof } from "ts-simple-nameof";
import { AdminAccountCompanyDto } from "../../../api/admin/dtos/AdminAccountCompanyDto";
import { AdminAccountContactDto } from "../../../api/admin/dtos/AdminAccountContactDto";
import { AdminAccountDto } from "../../../api/admin/dtos/AdminAccountDto";
import { AdminAddressDto } from "../../../api/admin/dtos/AdminAddressDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import { validateSchema } from "../../../shared/validation/schema/ValidationFieldSchema";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

function useAddressValidation() {
  const addressSchema = new ValidationSchema();
  addressSchema[nameof<AdminAddressDto>((p) => p.addressLine)] = validate("dirección").required();
  addressSchema[nameof<AdminAddressDto>((p) => p.country)] = validate("país").required();
  addressSchema[nameof<AdminAddressDto>((p) => p.state)] = validate("departamento").required();
  addressSchema[nameof<AdminAddressDto>((p) => p.city)] = validate("ciudad").required();

  return addressSchema;
}

function useCompanyValidation() {
  const schema = new ValidationSchema();
  schema[nameof<AdminAccountCompanyDto>((p) => p.name)] = validate("razón social").required();
  schema[nameof<AdminAccountCompanyDto>((p) => p.comercialName)] =
    validate("nombre comercial").required();
  schema[nameof<AdminAccountCompanyDto>((p) => p.taxPayerId)] = validate("RUT").required();
  schema[nameof<AdminAccountCompanyDto>((p) => p.address)] = validateSchema(
    useAddressValidation(),
    "dirección"
  ).required();

  return schema;
}

function useContactValidation() {
  const schema = new ValidationSchema();
  schema[nameof<AdminAccountContactDto>((p) => p.firstName)] = validate("nombre").required();
  schema[nameof<AdminAccountContactDto>((p) => p.lastName)] = validate("apellido").required();
  schema[nameof<AdminAccountContactDto>((p) => p.email)] = validate("correo").email().required();
  return schema;
}

export function useAdminAccountValidation() {
  const schema = new ValidationSchema();

  schema[nameof<AdminAccountDto>((p) => p.name)] = validate("Nombre").required();
  schema[nameof<AdminAccountDto>((p) => p.locale)] = validate("Locale").required();
  schema[nameof<AdminAccountDto>((p) => p.timeZoneId)] = validate("Zona horaria").required();
  schema[nameof<AdminAccountDto>((p) => p.shardId)] = validate("Shard").required();
  schema[nameof<AdminAccountDto>((p) => p.provider)] = validate("Proveedor").required();
  schema[nameof<AdminAccountDto>((p) => p.contact)] = validateSchema(
    useContactValidation(),
    "contacto"
  ).required();
  schema[nameof<AdminAccountDto>((p) => p.company)] = validateSchema(
    useCompanyValidation(),
    "empresa"
  ).required();
  return schema;
}
