import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminRetentionDto } from "../../api/admin/dtos/AdminRetentionDto";
import { AdminRetentionFiltersDto } from "../../api/admin/dtos/AdminRetentionFiltersDto";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiAdminRetention } from "../../api/shared/hooks/useApiAdmin";
import ButtonCreate from "../../shared/components/Button/ButtonCreate";
import Grid from "../../shared/components/grid/Grid";
import {
  GridFilterType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../shared/components/Layout/Page";
import { useModal } from "../../shared/hooks/useModal";
import AdminRetentionCreatePopup from "./AdminRetentionCreatePopup";
import getAdminRetentionListItemMenu from "./AdminRetentionList.ItemMenu";

const AdminRetentionList = () => {
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [apiAdminRetention] = useApiAdminRetention();
  const [initialFilters] = useState(() => {
    return new AdminRetentionFiltersDto();
  });
  const [modal] = useModal();

  const itemTemplates: IGridItemTemplate<AdminRetentionDto>[] = [
    {
      header: "País",
      filter: {
        propertyName: nameof<AdminRetentionFiltersDto>((p) => p.country),
        type: GridFilterType.enum,
        enumName: enumName.Country,
      },
      orderByPrevent: true,
      field: nameof<AdminRetentionDto>((p) => p.country),
      width: 10,
      cellTypeEnumName: enumName.Country,
    },
    {
      header: "Tipo",
      field: nameof<AdminRetentionDto>((p) => p.type),
      filter: {
        propertyName: nameof<AdminRetentionFiltersDto>((p) => p.type),
        type: GridFilterType.enum,
        enumName: enumName.InvoiceRetentionType,
      },
      width: 10,
      cellTypeEnumName: enumName.InvoiceRetentionType,
    },
    {
      header: "Código",
      field: nameof<AdminRetentionDto>((p) => p.code),
      filter: {
        propertyName: nameof<AdminRetentionFiltersDto>((p) => p.code),
      },
      width: 10,
    },

    {
      header: "Detalle",
      field: nameof<AdminRetentionDto>((p) => p.details),
      filter: {
        propertyName: nameof<AdminRetentionFiltersDto>((p) => p.details),
      },
      width: 70,
    },
  ];

  return (
    <Page
      title="Retenciones/Percepciones"
      titleButton={
        <ButtonCreate
          text="Nueva retención/percepción"
          onClick={() => modal.open(<AdminRetentionCreatePopup />, () => setReloadGrid(new Date()))}
        />
      }>
      <Grid
        itemTemplates={itemTemplates}
        defaultSortBy={[{ columnName: nameof<AdminRetentionDto>((p) => p.code), asc: true }]}
        reloadGrid={reloadGrid}
        hideIncludeInactiveButton={true}
        preventShowDefaultSearch={true}
        initialFilters={initialFilters}
        onSearch={(search, options) => apiAdminRetention.getPaged(search, options)}
        onDisplayItemMenu={(adminRetention: AdminRetentionDto) =>
          getAdminRetentionListItemMenu(adminRetention, modal, () => {
            setReloadGrid(new Date());
          })
        }
      />
    </Page>
  );
};

export default AdminRetentionList;
