import { useEffect, useState } from "react";
import { AdminSentEmailDto } from "../../api/admin/dtos/AdminSentEmailDto";
import { enumName } from "../../api/shared/enums/_enumName";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminProviderSentEmail } from "../../api/shared/hooks/useApiAdminProviders";
import { useModal } from "../../shared/hooks/useModal";
import useUtils from "../../shared/hooks/useUtils";
import { DateFormatType } from "../../shared/utils/localizedUtil/localizedUtilDates";
import { AppThemeColor } from "../../styles/color";

const AdminSentEmailDetailsPopup = (props: { id: string }) => {
  const [modal] = useModal();
  const [apiAdminSentEmail] = useApiAdminProviderSentEmail();
  const [sentEmail, setSentEmail] = useState<AdminSentEmailDto>();
  const { utils } = useUtils();

  useEffect(() => {
    const load = async () => {
      setSentEmail(await apiAdminSentEmail.getById(props.id));
      modal.setVisible("Detalles de correo enviado", ModalSize.normal);
    };
    load();
  }, []);

  if (!sentEmail) {
    return <></>;
  }

  return (
    <Container>
      <ColumnDisplay md={3} label="Plantilla">
        {utils.enum.translate(enumName.TemplateType, sentEmail.templateType)}
      </ColumnDisplay>
      <ColumnDisplay md={3} label="Fecha">
        {utils.date.toString(sentEmail.createdDate, DateFormatType.DateHourMinutes)}
      </ColumnDisplay>
      <ColumnDisplay md={3} label="Estado">
        {utils.enum.translate(enumName.SentEmailStatus, sentEmail.status)}
      </ColumnDisplay>
      <ColumnDisplay md={3} label="Actualización">
        {utils.date.toString(sentEmail.createdDate, DateFormatType.DateHourMinutes)}
      </ColumnDisplay>
      <ColumnDisplay md={3} label="Proveedor">
        {utils.enum.translate(enumName.EmailProviderType, sentEmail.providerType)}
      </ColumnDisplay>
      <ColumnDisplay md={3} label="Para">
        {sentEmail.to} {sentEmail.toName}
      </ColumnDisplay>
      <ColumnDisplay md={3} label="Desde">
        {sentEmail.from} {sentEmail.fromName}
      </ColumnDisplay>
      <ColumnDisplay md={9} label="Asunto">
        {sentEmail.subject}
      </ColumnDisplay>
      <ColumnDisplay md={3} label="Adjuntos">
        {sentEmail.attachmentFileNames ?? "-"}
      </ColumnDisplay>
      <Row>
        <iframe
          style={{
            width: "100%",
            height: 820,
            border: "1px solid " + AppThemeColor.gray,
            borderRadius: 6,
          }}
          src={"data:text/html;charset=utf-8," + encodeURIComponent(sentEmail.htmlBody)}
        />
      </Row>
    </Container>
  );
};

export default AdminSentEmailDetailsPopup;
