import { nameof } from "ts-simple-nameof";
import { AccountConfigPreferencesDto } from "../../../api/app/dtos/AccountConfigPreferencesDto";
import { enumName } from "../../../api/shared/enums/_enumName";
import FormRadioEnum from "../../../shared/components/forms/FormRadioEnum";
import Column from "../../../shared/components/Layout/GColumn";

const ConfigPreferencesProducts = () => {
  return (
    <Column md={3}>
      <FormRadioEnum
        label="Tipo de producto por defecto"
        propertyName={nameof<AccountConfigPreferencesDto>((p) => p.defaultProductType)}
        enumName={enumName.ProductType}
      />
    </Column>
  );
};

export default ConfigPreferencesProducts;
