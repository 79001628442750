import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminTaxValueDto } from "../../api/admin/dtos/AdminTaxValueDto";
import { AdminTaxValueFiltersDto } from "../../api/admin/dtos/AdminTaxValueFiltersDto";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiAdminTaxValue } from "../../api/shared/hooks/useApiAdmin";
import ButtonCreate from "../../shared/components/Button/ButtonCreate";
import Grid from "../../shared/components/grid/Grid";
import {
  GridCellType,
  IGridItemTemplate,
  MobileSpecialType,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import InfoText, { InfoTextType } from "../../shared/components/InfoText";
import Row from "../../shared/components/Layout/GRow";
import Page from "../../shared/components/Layout/Page";
import { useModal } from "../../shared/hooks/useModal";
import AdminTaxValueCreatePopup from "./AdminTaxValueCreatePopup";
import getAdminTaxValueListItemMenu from "./AdminTaxValueList.ItemMenu";

const AdminTaxValueList = () => {
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [apiAdminTaxValue] = useApiAdminTaxValue();
  const [initialFilters] = useState(() => {
    return new AdminTaxValueFiltersDto();
  });
  const [modal] = useModal();

  const itemTemplates: IGridItemTemplate<AdminTaxValueDto>[] = [
    {
      header: "País",
      orderByPrevent: true,
      field: nameof<AdminTaxValueDto>((p) => p.country),
      width: 10,
      cellTypeEnumName: enumName.Country,
    },
    {
      header: "Nombre",
      field: nameof<AdminTaxValueDto>((p) => p.tax),
      mobileSpecialType: MobileSpecialType.Main,
      width: 20,
      cellTypeEnumName: enumName.Tax,
    },

    {
      header: "Válido desde",
      field: nameof<AdminTaxValueDto>((p) => p.validSince),
      width: 20,
      cellType: GridCellType.date,
    },
    {
      header: "Válido hasta",
      field: nameof<AdminTaxValueDto>((p) => p.validUntil),
      width: 20,
      cellType: GridCellType.date,
    },
    {
      header: "Valor",
      field: nameof<AdminTaxValueDto>((p) => p.value),
      width: 20,
      cellStyle: { fontWeight: 600 },
    },
  ];

  return (
    <Page
      title="Impuestos"
      titleButton={
        <ButtonCreate
          text="Nuevo impuesto"
          onClick={() => modal.open(<AdminTaxValueCreatePopup />, () => setReloadGrid(new Date()))}
        />
      }>
      <Row>
        <InfoText type={InfoTextType.error}>
          <strong>Importante</strong> Se debe ajustar manualmente la fecha de la validez de cada
          impuesto
        </InfoText>
      </Row>
      <Grid
        itemTemplates={itemTemplates}
        defaultSortBy={[{ columnName: nameof<AdminTaxValueDto>((p) => p.tax), asc: true }]}
        reloadGrid={reloadGrid}
        hideIncludeInactiveButton={true}
        preventShowDefaultSearch={true}
        initialFilters={initialFilters}
        onSearch={(search, options) => apiAdminTaxValue.getPaged(search, options)}
        onDisplayItemMenu={(adminTaxValue: AdminTaxValueDto) =>
          getAdminTaxValueListItemMenu(adminTaxValue, modal, () => {
            setReloadGrid(new Date());
          })
        }
      />
    </Page>
  );
};

export default AdminTaxValueList;
