// File generated by Gesta.Build.PostEvents
import { ContactSimpleDto } from "./ContactSimpleDto";
import { ContactType } from "../../shared/enums/ContactType";
import { ContactInfoDto } from "./ContactInfoDto";

export class ContactDto extends ContactSimpleDto {
  contactType: ContactType;
  eInvoiceEmail: string = "";
  eInvoiceWebService: string = "";
  eInvoiceEmailPreventAutoUpdate: boolean = false;
  email: string = "";
  firstName: string = "";
  info: ContactInfoDto;
  isCustomer: boolean = false;
  isProvider: boolean = false;
  lastName: string = "";
}
