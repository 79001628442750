import { nameof } from "ts-simple-nameof";
import { SaleLineDto } from "../../api/app/dtos/SaleLineDto";
import { enumName } from "../../api/shared/enums/_enumName";
import FormSelectTaxValue from "../../shared/components/forms/Select/FormSelectTaxValue";
import useUtils from "../../shared/hooks/useUtils";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import { ISaleLineColumnRenderProps } from "./SaleFormLines";

const SaleLineColumnTax = (props: ISaleLineColumnRenderProps) => {
  const { utils } = useUtils();
  const saleModelState = useContextModelStateSale();

  if (!props.isEditing) {
    return <>{utils.enum.translate(enumName.Tax, props.saleLine.tax)}</>;
  }

  return (
    <FormSelectTaxValue
      arrayItemHelper={props.arrayItemHelper}
      label={props.showLabel ? props.saleLineColumn.header : ""}
      propertyName={nameof<SaleLineDto>((p) => p.tax)}
      onValueChange={() => saleModelState.onLineTaxChange(props.arrayItemHelper.index)}
      variant={props.variant}
    />
  );
};

export default SaleLineColumnTax;
