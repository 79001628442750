// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { AdminAccountSimpleDto } from "../../shared/dtos/AdminAccountSimpleDto";

export class AdminPointOfSaleClientDto extends BaseEntityDto {
  account: AdminAccountSimpleDto;
  token: string = "";
  currentConnectionId: string = "";
  lastConnection: Date | null = null;
  lastDisconnection: Date | null = null;
}
