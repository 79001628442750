import { ReactNode, useEffect, useRef, useState } from "react";
import { GridSettingsDto } from "../../../api/app/dtos/GridSettingsDto";
import { FiltersDto } from "../../../api/shared/dtos/FiltersDto";
import { PagedResultDto } from "../../../api/shared/dtos/PagedResultDto";
import { SearchResultItemDto } from "../../../api/shared/dtos/SearchResultItemDto";
import { PagedSearchDto } from "../../../api/shared/dtos/PagedSearchDto";
import { GridSettingType } from "../../../api/shared/enums/GridSettingType";
import { useApiGridSettings } from "../../../api/shared/hooks/useApiApp";
import { AppEnvironment } from "../../../AppEnvironment";
import { useIsMobile } from "../../hooks/useIsMobile";
import { StateProvider } from "../../modelState/ModelStateContext";
import InputSelectAdminShard from "../inputs/select/InputSelectAdminShard";
import Column from "../Layout/GColumn";
import Row from "../Layout/GRow";
import "./Grid.scss";
import GridBody from "./GridBody";
import { GridProvider, IGridState } from "./GridContext";
import GridFilters from "./GridFilters";
import GridFooter from "./GridFooter";
import GridHeader from "./GridHeader";
import { gridSettingsStorageGet, gridSettingsStorageSet } from "./GridSettingsStorage";
import { GridColumnActionType, IGridItemTemplate } from "./ItemTemplate/IGridItemTemplate";
import { GridMode, PropsGrid } from "./PropsGrid";
import { useContextGrid } from "./useContextGrid";

const Grid = (props: PropsGrid<any>) => {
  return (
    <GridProvider>
      <GridContent {...props} />
    </GridProvider>
  );
};

export default Grid;

export interface IGridSelection<T> {
  selectedCount: number;
  allSelected: boolean;
  selectedList: T[];
}

export interface IGridAction {
  render(selection: IGridSelection<any>): ReactNode;
}

export enum GridColumnAlign {
  left = 0,
  center = 1,
  right = 2,
}

const GridContent = (props: PropsGrid<any>) => {
  const grid = useContextGrid();
  const searchServerSideEndpoint = useRef(props.onSearch);
  const clientSideApplyFiltersCallback = useRef(props.onClientSideApplyFilters);
  const columnTemplates = useRef(props.itemTemplates);
  const mustIncludeFields = useRef(props.mustIncludeFields);
  const [initialFilters, setInitialFilters] = useState(props.initialFilters);
  const [apiGridSettings] = useApiGridSettings();
  const isMobile = useIsMobile();
  const flagLoadingState = useRef(false);
  // State provider render key force to re-render grid filters with new gridState.filterEditing values when filters are applied or cleared
  const [stateProviderRenderKey, setStateProviderRenderKey] = useState(1);

  const loadSettings = async () => {
    let settings = null;
    const settingsType = isMobile ? GridSettingType.Mobile : GridSettingType.Desktop;

    if (props.gridName) {
      const storedSettings = gridSettingsStorageGet(props.gridName, settingsType);

      if (storedSettings) {
        settings = storedSettings;
      } else {
        settings = await apiGridSettings.getForCurrentUser(props.gridName, settingsType);
      }
    }

    if (!settings) {
      settings = {
        name: props.gridName,
        pageSize: props.pageSize ?? AppEnvironment.REACT_APP_GRID_PAGE_SIZE,
        type: settingsType,
        visibleColumns: columnTemplates.current
          .filter((c) => !c.hideByDefault && !c.actionType)
          .map((c) => c.field)
          .join(),
      } as GridSettingsDto;
    }
    gridSettingsStorageSet(settings);

    grid.init(
      props.onSearch ? GridMode.ServerSide : GridMode.ClientSide,
      props.defaultSortBy ?? [],
      settings,
      props.initialFilters ?? new FiltersDto(),
      columnTemplates.current,
      mustIncludeFields.current,
      props.selectedItems,
      props.isCrossShard
    );
  };

  const loadResults = async () => {
    if (grid.gridState.isCrossShard && !grid.gridState.searchDto.crossShardId) {
      grid.setLoadingError("Se debe seleccionar una shard");
      return;
    }

    try {
      let searchPagedResult = null;
      const validationTokenId = grid.gridState.validationTokenId + 1;
      grid.updateValidationToken(validationTokenId);

      if (searchServerSideEndpoint?.current) {
        searchPagedResult = await searchServerSideEndpoint.current(grid.gridState.searchDto, {
          preventSpinner: true,
          preventNotifications: true,
        });
      } else if (props.clientSideItems || clientSideApplyFiltersCallback?.current) {
        if (!props.clientSideItems) {
          throw Error("Property clientSideItems must be passed when GridMode is ClientSide");
        }

        searchPagedResult = getClientSidePagedResult(
          props.clientSideItems,
          grid.gridState,
          clientSideApplyFiltersCallback.current
        );
      }

      if (searchPagedResult) {
        grid.updatePage(searchPagedResult, validationTokenId);
      }
    } catch (error) {
      grid.setLoadingError(error as any);
    }
  };

  const init = () => {
    if (flagLoadingState.current) {
      return;
    }
    flagLoadingState.current = true;

    if ((props.clientSideItems || props.onClientSideApplyFilters) && props.onSearch) {
      throw Error(
        "Cannot recognize grid mode, must define only one of onSearch for ServerSide or clientSideAllItems for ClientSide"
      );
    }

    if (props.showSelectionColumn) {
      columnTemplates.current.unshift({
        width: 5,
        align: GridColumnAlign.center,
        actionType: GridColumnActionType.Selection,
      } as IGridItemTemplate<any>);
    }

    if (props.onExpandRow) {
      columnTemplates.current.push({
        width: 3,
        align: GridColumnAlign.right,
        actionType: GridColumnActionType.ExpandRowButton,
        noPaddingY: true,
      } as IGridItemTemplate<any>);
    }

    if (props.onDisplayItemMenu) {
      columnTemplates.current.push({
        width: 3,
        align: GridColumnAlign.right,
        actionType: GridColumnActionType.MenuActionButton,
        noPaddingY: true,
      } as IGridItemTemplate<any>);
    }

    loadSettings();
  };

  const gridStateChange = () => {
    if (!grid.gridState.init) {
      return;
    }

    if (
      !grid.gridState.settings ||
      (isMobile && grid.gridState.settings.type !== GridSettingType.Mobile) ||
      (!isMobile && grid.gridState.settings.type !== GridSettingType.Desktop)
    ) {
      loadSettings();
      return;
    }

    if (initialFilters !== props.initialFilters) {
      setInitialFilters(props.initialFilters);
      grid.updateFilters(props.initialFilters);
      return;
    }

    const load = () => {
      const delayLoadTimeout = setTimeout(
        loadResults,
        grid.gridState.filtersDelay ? 500 : 0
      ) as any;

      return () => {
        clearTimeout(delayLoadTimeout);
      };
    };
    return load();
  };

  const clientSideItemsChange = () => {
    if (grid.gridState.init && props.clientSideItems) {
      const clientSidePagedResult = getClientSidePagedResult(
        props.clientSideItems,
        grid.gridState,
        clientSideApplyFiltersCallback.current
      );
      grid.clientSideRefresh(clientSidePagedResult);
    }
  };

  const selectionChange = () => {
    if (grid.gridState.selectionNotificationPending) {
      if (!props.onSelectionChange) {
        throw Error("Grid onSelectionChange must be defined");
      }
      props.onSelectionChange(grid.gridState.selection);
      grid.selectionNotificationDone();
      return;
    }
  };

  useEffect(init, []);

  useEffect(gridStateChange, [
    props.initialFilters,
    props.defaultSortBy,
    props.reloadGrid,
    props.gridName,
    props.clientSideItems,
    grid.gridState.init,
    grid.gridState.searchDto,
    grid.gridState.settings,
    isMobile,
  ]);

  useEffect(selectionChange, [grid.gridState.selectionNotificationPending]);

  useEffect(clientSideItemsChange, [props.clientSideItems]);

  useEffect(() => {
    setStateProviderRenderKey(stateProviderRenderKey + 1);
  }, [grid.gridState.filtersEditing]);

  if (!grid.gridState.settings || !grid.gridState.searchDto) {
    return null;
  }

  return (
    <>
      <StateProvider
        model={grid.gridState.filtersEditing ?? new FiltersDto()}
        onSubmit={(filters) => grid.updateFilters(filters)}>
        {(s) => (
          <>
            {grid.gridState.isCrossShard && (
              <Column>
                <InputSelectAdminShard
                  label="Shard"
                  value={grid.gridState.searchDto.crossShardId}
                  onChange={grid.updateCrossShardId}
                />
              </Column>
            )}
            <Row minHeightIgnore={true}>
              <GridFilters
                hideIncludeInactiveButton={props.hideIncludeInactiveButton ?? false}
                preventShowDefaultSearch={props.preventShowDefaultSearch ?? false}
                onApplyFilters={s.handleSubmit}
              />
            </Row>
            <Row minHeightIgnore={true} style={{ position: "relative", paddingTop: 0 }}>
              {!isMobile && (
                <GridHeader
                  hideIncludeInactiveButton={props.hideIncludeInactiveButton}
                  onClientSideReload={props.onClientSideReload}
                  onApplyFilters={s.handleSubmit}
                />
              )}
            </Row>
          </>
        )}
      </StateProvider>
      <Row style={{ position: "relative", paddingTop: 0 }}>
        <GridBody
          onDisplayItemMenu={props.onDisplayItemMenu}
          onExpandRow={props.onExpandRow}
          onExpandRowShouldDisableButton={props.onExpandRowShouldDisableButton}
          onGetRowStyle={props.onGetRowStyle}
          onSelectRow={(item: SearchResultItemDto<any>) => {
            if (item.selected) {
              grid.unselectItem(item);
              return;
            }
            grid.selectItem(item);
          }}
        />
      </Row>
      {!props.hideFooter && (
        <Row minHeightIgnore={true}>
          <GridFooter
            onExport={props.onExport}
            hideIncludeInactiveButton={props.hideIncludeInactiveButton}
          />
        </Row>
      )}
    </>
  );
};

function getClientSidePagedResult(
  clientSideAllItems: any[],
  gridState: IGridState<any>,
  clientSideApplyFiltersCallback?: (item: any[], pagedSearchDto: PagedSearchDto<any>) => any[]
): PagedResultDto<any> {
  const localItems: any[] = clientSideApplyFiltersCallback
    ? clientSideApplyFiltersCallback(clientSideAllItems, gridState.searchDto)
    : clientSideAllItems;

  const pageItemCount = gridState.searchDto.pageSize ?? 15;

  const startIndex = ((gridState.searchDto.page ?? 1) - 1) * pageItemCount;
  const currenPageItems = localItems.slice(
    startIndex,
    startIndex + (gridState.searchDto.pageSize ?? 15)
  );

  const searchPagedResult = new PagedResultDto<any>();

  searchPagedResult.items = currenPageItems.map((c) => {
    const item = new SearchResultItemDto();
    item.item = c;
    return item;
  });

  searchPagedResult.currentPageIndex = gridState.searchDto.page ?? 0;
  searchPagedResult.currentPageItemCount = searchPagedResult.items.length;
  searchPagedResult.totalItemCount = localItems.length;
  searchPagedResult.totalPageCount = Math.ceil(localItems.length / pageItemCount);

  return searchPagedResult;
}
