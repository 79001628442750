import { ReportTaxesPeriodTaxCurrencyInvoiceTypeDto } from "../../../api/app/dtos/ReportTaxesPeriodTaxCurrencyInvoiceTypeDto";
import { Currency } from "../../../api/shared/enums/Currency";
import { enumName } from "../../../api/shared/enums/_enumName";
import useUtils from "../../../shared/hooks/useUtils";
import ReportLine from "../ReportLine";

const ReportTaxesPeriodTaxCurrencyInvoiceType = (props: {
  periodTaxCurrencyInvoiceType: ReportTaxesPeriodTaxCurrencyInvoiceTypeDto;
  taxCurrency: Currency;
  defaultCurrency: Currency;
}) => {
  const { utils } = useUtils();

  let originalTotal = "";
  if (props.periodTaxCurrencyInvoiceType.originalTotal) {
    originalTotal = utils.currency.toString(
      props.taxCurrency,
      props.periodTaxCurrencyInvoiceType.originalTotal
    );
  }

  return (
    <ReportLine
      label={utils.enum.translate(
        enumName.InvoiceType,
        props.periodTaxCurrencyInvoiceType.invoiceType
      )}
      values={[
        utils.number.toIntString(props.periodTaxCurrencyInvoiceType.invoicesCount),
        originalTotal,
        utils.currency.toString(props.defaultCurrency, props.periodTaxCurrencyInvoiceType.total),
      ]}
    />
  );
};

export default ReportTaxesPeriodTaxCurrencyInvoiceType;
