import { useEffect, useRef, useState } from "react";
import { OAuthTokenResponseDto } from "../../../api/app/dtos/OAuthTokenResponseDto";
import { useApiEmailConfig } from "../../../api/shared/hooks/useApiApp";
import { AppSize } from "../../../shared/AppSize";
import Row from "../../../shared/components/Layout/GRow";
import RowDisplay from "../../../shared/components/Layout/GRowDisplay";
import Page from "../../../shared/components/Layout/Page";
import Spinner from "../../../shared/components/Spinner";
import { usePathQueryString } from "../../../shared/hooks/usePathQueryString";
import { TextAlign } from "../../../shared/TextAlign";

const ConfigEmailOAuthSuccessGmail = () => {
  const [apiEmailConfig] = useApiEmailConfig();
  const pathQueryString = usePathQueryString();
  const [token, setToken] = useState<OAuthTokenResponseDto>();
  const hasRun = useRef(false);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;
    const load = async () => {
      const response = await apiEmailConfig.oAuthAccessGrantedGmail(
        pathQueryString.get("code") ?? "",
        {
          preventSpinner: true,
        }
      );
      setToken(response);

      console.log("Guardando el response en el storage", response);
      localStorage.setItem("gesta_oauth_success", JSON.stringify(response));
      //window.close();
    };
    load();
  }, []);

  return (
    <Page title="Conectando con tu cuenta de GMAIL">
      <Row align={TextAlign.center}>{!token && <Spinner size={AppSize.biggest} />}</Row>
      <RowDisplay>{JSON.stringify(token)}</RowDisplay>
    </Page>
  );
};

export default ConfigEmailOAuthSuccessGmail;
