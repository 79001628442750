import MuiModal from "@mui/material/Modal";
import { useContext, useEffect } from "react";
import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import { ModalInfoContext, ModalInfoStatus, ModalSize } from "../../context/ModalProvider";
import { useModal } from "../../hooks/useModal";
import { arrayStringConcat } from "../../utils/utilArrays";
import "./Modal.scss";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";

const ModalContainer = () => {
  const { modalCollection } = useContext(ModalInfoContext);
  const [modal] = useModal();
  const appTheme = useAppTheme();

  useEffect(() => {
    if (modalCollection.modalList.length > 0) {
      const currentModal = modalCollection.modalList[modalCollection.modalList.length - 1];

      switch (currentModal.status) {
        case ModalInfoStatus.visible:
          break;

        case ModalInfoStatus.replaced:
          if (modalCollection.isClosingAsSuccess) {
            modal.closeAsSuccess(modalCollection.closingInfo, "");

            if (currentModal.onCloseAsSuccess) {
              currentModal.onCloseAsSuccess();
            }
          } else {
            modal.closeAsCancel(modalCollection.closingInfo);
            if (currentModal.onCloseAsCancel) {
              currentModal.onCloseAsCancel();
            }
          }
          break;

        case ModalInfoStatus.closing:
          modal.confirmClose();

          if (modalCollection.isClosingAsSuccess && currentModal.onCloseAsSuccess) {
            currentModal.onCloseAsSuccess(modalCollection.closingInfo);
          } else if (!modalCollection.isClosingAsSuccess && currentModal.onCloseAsCancel) {
            currentModal.onCloseAsCancel(modalCollection.closingInfo);
          }
          break;

        default:
          break;
      }
    }
  }, [modalCollection, modal]);

  if (!modalCollection || !modalCollection.modalList || modalCollection.modalList.length === 0) {
    return <></>;
  }

  return (
    <>
      {modalCollection.modalList.map(
        (modalInfo, index) =>
          modalInfo.status !== ModalInfoStatus.replaced && (
            <MuiModal
              key={index}
              open={true}
              onClose={() => {
                if (!modalInfo.preventCloseOnOutsideClick) {
                  modal.closeAsCancel();
                }
              }}>
              <div
                className={arrayStringConcat(
                  "layout-modal",
                  "layout-modal-size-" + ModalSize[modalInfo.size ?? ModalSize.normal],
                  modalInfo.status === ModalInfoStatus.loading ? "layout-modal-loading" : "",
                  modalInfo.size === ModalSize.dialog ? "layout-modal-dialog" : ""
                )}
                style={{
                  display: modalInfo.status === ModalInfoStatus.loading ? "none" : "",
                  backgroundColor: appTheme.toHexColor(AppThemeColor.white),
                }}>
                <ModalHeader
                  title={modalInfo.title}
                  isDialog={modalInfo.size == ModalSize.dialog}
                  onClickClose={() => modal.closeAsCancel()}
                />

                <div className="layout-modal-content">{modalInfo.content}</div>

                <ModalFooter></ModalFooter>
              </div>
            </MuiModal>
          )
      )}
    </>
  );
};

export default ModalContainer;
