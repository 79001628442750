import { AppSize } from "../AppSize";
import { ThemeSize } from "../ThemeSize";

export function sizeFromTheme(themeSize?: ThemeSize): AppSize {
  switch (themeSize) {
    case ThemeSize.small:
      return AppSize.small;
    case ThemeSize.large:
      return AppSize.large;
  }
  return AppSize.medium;
}

export function toThemeSize(appSize?: AppSize): ThemeSize {
  switch (appSize) {
    case AppSize.small:
      return ThemeSize.small;
    case AppSize.large:
      return ThemeSize.large;
  }
  return ThemeSize.medium;
}
