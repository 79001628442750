import { nameof } from "ts-simple-nameof";
import { AdminTermsAndConditionsDto } from "../../api/admin/dtos/AdminTermsAndConditionsDto";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import CodeEditor, { CodeEditorLang } from "../../shared/components/CodeEditor";
import FormAutocompleteAdminProvider from "../../shared/components/forms/Autocomplete/FormAutocompleteAdminProvider";
import FormDate from "../../shared/components/forms/FormDate";
import Column from "../../shared/components/Layout/GColumn";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import TabGroup from "../../shared/components/Layout/TabGroup";
import TabView from "../../shared/components/Layout/TabView";
import { AppInfoSiteType } from "../../shared/context/AppInfoProvider";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { useContextModelState } from "../../shared/modelState/useContextModelState";
import AdminTermsAndConditionsFormPreview from "./AdminTermsAndConditionsForm.Preview";

const TABS = {
  CHANGES: "CHANGES",
  TERMS: "TERMS",
  PRIVACY: "PRIVACY",
};

const AdminTermsAndConditionsForm = (props: { editMode?: boolean; onSave(): void }) => {
  const modelState = useContextModelState();
  const { appInfo } = useContextAppInfo();
  const tabs = [
    { title: "Notificación de cambios", value: TABS.CHANGES },
    { title: "Términos y condiciones", value: TABS.TERMS },
    { title: "Política de privacidad", value: TABS.PRIVACY },
  ];

  return (
    <>
      {appInfo?.siteType == AppInfoSiteType.SuperAdmin && (
        <Column md={3}>
          <FormAutocompleteAdminProvider
            label="Proveedor"
            propertyName={nameof<AdminTermsAndConditionsDto>((p) => p.adminProvider)}
          />
        </Column>
      )}
      {appInfo?.siteType != AppInfoSiteType.SuperAdmin && (
        <ColumnDisplay label="Proveedor" md={3}>
          {modelState.model.adminProvider?.name}
        </ColumnDisplay>
      )}
      <Column md={3}>
        <FormDate
          label="Notificar desde"
          propertyName={nameof<AdminTermsAndConditionsDto>((p) => p.notifySince)}
        />
      </Column>
      <Column md={3}>
        <FormDate
          label="Obligatoria desde"
          propertyName={nameof<AdminTermsAndConditionsDto>((p) => p.mandatorySince)}
        />
      </Column>

      <Row>
        <TabGroup tabs={tabs} selectedTab={TABS.CHANGES}>
          <TabView value={TABS.CHANGES}>
            <Column md={6}>
              <CodeEditor
                height={580}
                lang={CodeEditorLang.html}
                value={modelState.model.notificationChanges}
                onChange={(value: any) =>
                  modelState.updateProperty(
                    nameof<AdminTermsAndConditionsDto>((p) => p.notificationChanges),
                    value
                  )
                }
              />
            </Column>
            <Column md={6}>
              <AdminTermsAndConditionsFormPreview value={modelState.model.notificationChanges} />
            </Column>
          </TabView>
          <TabView value={TABS.TERMS}>
            <Container>
              <Column md={6}>
                <CodeEditor
                  height={580}
                  lang={CodeEditorLang.html}
                  value={modelState.model.termsAndConditions}
                  onChange={(value: any) =>
                    modelState.updateProperty(
                      nameof<AdminTermsAndConditionsDto>((p) => p.termsAndConditions),
                      value
                    )
                  }
                />
              </Column>
              <Column md={6}>
                <AdminTermsAndConditionsFormPreview value={modelState.model.termsAndConditions} />
              </Column>
            </Container>
          </TabView>
          <TabView value={TABS.PRIVACY}>
            <Container>
              <Column md={6}>
                <CodeEditor
                  height={580}
                  lang={CodeEditorLang.html}
                  value={modelState.model.privacyPolice}
                  onChange={(value: any) =>
                    modelState.updateProperty(
                      nameof<AdminTermsAndConditionsDto>((p) => p.privacyPolice),
                      value
                    )
                  }
                />
              </Column>
              <Column md={6}>
                <AdminTermsAndConditionsFormPreview value={modelState.model.privacyPolice} />
              </Column>
            </Container>
          </TabView>
        </TabGroup>
      </Row>
      <RowButtons>
        <ButtonAccept onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default AdminTermsAndConditionsForm;
