import { useEffect, useState } from "react";
import { ProductDto } from "../../api/app/dtos/ProductDto";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiProduct } from "../../api/shared/hooks/useApiApp";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import ProductForm from "./Product.Form";
import { useProductValidation } from "./Validations/useProductValidation";
import { ProductViewModel } from "./ViewModels/SaleViewModel";

interface PropsProductEditPopup {
  productId: string;
}

const ProductEditPopup = (props: PropsProductEditPopup) => {
  const [product, setProduct] = useState<ProductDto>();
  const [modal] = useModal();
  const [apiProduct] = useApiProduct();

  useEffect(() => {
    const loadProduct = async () => {
      const product = (await apiProduct.getById(props.productId)) as ProductViewModel;
      product.enableAddendumLegend = !!product.info.addendumAddLegend;
      setProduct(product);
      modal.setVisible("Editar producto", ModalSize.small);
    };
    loadProduct();
  }, []);

  const save = async (product: ProductDto) => {
    const edited = await apiProduct.edit(product);
    modal.closeAsSuccess(edited, "El producto se editó con éxito");
  };

  if (!product) {
    return <></>;
  }

  return (
    <StateProvider model={product} validationSchema={useProductValidation()} onSubmit={save}>
      {(p) => <ProductForm onSubmit={p.handleSubmit} />}
    </StateProvider>
  );
};

export default ProductEditPopup;
