import { useAppTheme } from "../../AppThemeProvider";
import { AppThemeColor } from "../../styles/color";

const AdminTermsAndConditionsFormPreview = (props: { value: string }) => {
  const appTheme = useAppTheme();
  return (
    <iframe
      srcDoc={props.value}
      style={{
        width: "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: appTheme.toHexColor(AppThemeColor.gray),
        borderRadius: 5,
        height: 580,
      }}
    />
  );
};

export default AdminTermsAndConditionsFormPreview;
