import { Box } from "@mui/material";
import { CSSProperties, useEffect, useRef } from "react";
import { useIsMobile } from "../../hooks/useIsMobile";
import { LAYOUT_SCROLLABLE_AREA_DEFAULT_MIN_HEIGHT } from "./LayoutConstants";

const ScrollableArea = (props: {
  children: any;
  maxHeight?: number;
  mobileMaxHeight?: number;
  initialScrollY?: number;
  posX?: number;
  posY?: number;
  scrollX?: boolean;
  style?: CSSProperties;
  onScroll?: (positionX: number, positionY: number) => void;
}) => {
  const isMobile = useIsMobile();
  const ref = useRef();

  useEffect(() => {
    if (!props.onScroll) {
      return;
    }

    const nativeRef = ref.current as any;
    const onScroll = (event: any) => {
      const { scrollTop, scrollX } = event.target;
      if (props.onScroll) {
        props.onScroll(scrollX, scrollTop);
      }
    };
    nativeRef.addEventListener("scroll", onScroll);
    return () => {
      nativeRef.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    if (props.posX != undefined || props.posY != undefined) {
      const nativeRef = ref.current as any;
      nativeRef.scrollTo(props.posX ?? 0, props.posY ?? 0);
    }
  }, [props.posX, props.posY]);

  return (
    <Box
      ref={ref}
      maxHeight={
        isMobile && props.mobileMaxHeight
          ? props.mobileMaxHeight
          : props.maxHeight ?? LAYOUT_SCROLLABLE_AREA_DEFAULT_MIN_HEIGHT
      }
      style={{
        width: "100%",
        overflowX: props.scrollX ? "auto" : "hidden",
        overflowY: "auto",
        ...props.style,
      }}>
      {props.children}
    </Box>
  );
};

export default ScrollableArea;
