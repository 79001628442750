import { VaultCertificateDto } from "../../../api/app/dtos/VaultCertificateDto";
import RowDisplay from "../../../shared/components/Layout/GRowDisplay";
import useUtils from "../../../shared/hooks/useUtils";
import { DateFormatType } from "../../../shared/utils/localizedUtil/localizedUtilDates";

const VaultCertificateInfo = (props: { vaultCertificate?: VaultCertificateDto }) => {
  const { utils } = useUtils();
  return (
    <>
      <RowDisplay label="Issuer">{props.vaultCertificate?.issuer ?? "-"}</RowDisplay>
      <RowDisplay label="Subject" multilineAllow={true}>
        {props.vaultCertificate?.subject ?? "-"}
      </RowDisplay>
      <RowDisplay label="Válido desde">
        {utils.date.toString(
          props.vaultCertificate?.activationDate,
          DateFormatType.DateHourMinutes
        )}
      </RowDisplay>
      <RowDisplay md={6} label="Valido hasta">
        {utils.date.toString(
          props.vaultCertificate?.expirationDate,
          DateFormatType.DateHourMinutes
        )}
      </RowDisplay>
      <RowDisplay label="Serial">{props.vaultCertificate?.serialNumber ?? "-"}</RowDisplay>
      <RowDisplay label="Thumbprint">{props.vaultCertificate?.thumbprint ?? "-"}</RowDisplay>
    </>
  );
};

export default VaultCertificateInfo;
