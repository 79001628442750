import { useEffect, useState } from "react";
import { EInvoiceDailyReportDto } from "../../../../api/app/dtos/EInvoiceDailyReportDto";
import { enumName } from "../../../../api/shared/enums/_enumName";
import { useApiEInvoiceDailyReport } from "../../../../api/shared/hooks/useApiApp";
import ButtonIconFileDownload from "../../../../shared/components/Button/ButtonIconFileDownload";
import ColumnDisplay from "../../../../shared/components/Layout/GColumnDisplay";
import Container from "../../../../shared/components/Layout/GContainer";
import RowDisplay from "../../../../shared/components/Layout/GRowDisplay";
import { ModalSize } from "../../../../shared/context/ModalProvider";
import { useModal } from "../../../../shared/hooks/useModal";
import useUtils from "../../../../shared/hooks/useUtils";
import { DateFormatType } from "../../../../shared/utils/localizedUtil/localizedUtilDates";

const EInvoiceDailyReportDetailsPopup = (props: { id: string }) => {
  const [modal] = useModal();
  const [dailyReport, setDailyReport] = useState<EInvoiceDailyReportDto>();
  const [apiEInvoiceDailyReport] = useApiEInvoiceDailyReport();
  const { utils } = useUtils();

  useEffect(() => {
    const loadAdminAccount = async () => {
      setDailyReport(await apiEInvoiceDailyReport.getById(props.id));
      modal.setVisible("Detalles de reporte diario", ModalSize.normal);
    };
    loadAdminAccount();
  }, []);

  if (!dailyReport) {
    return <></>;
  }

  return (
    <Container>
      <ColumnDisplay label="Fecha">{utils.date.toString(dailyReport.date)}</ColumnDisplay>
      <ColumnDisplay label="Fecha de envío">
        {utils.date.toString(dailyReport.sentDate, DateFormatType.DateHourMinutesSeconds)}
      </ColumnDisplay>
      <ColumnDisplay label="Id interno">
        {utils.number.toIntString(dailyReport.internalSenderId)}
      </ColumnDisplay>
      <ColumnDisplay label="No secuencia">
        {utils.number.toIntString(dailyReport.secuencialNumber)}
      </ColumnDisplay>
      <ColumnDisplay label="Estado">
        {utils.enum.translate(enumName.EInvoiceDailyReportStatus, dailyReport.status)}
      </ColumnDisplay>
      <RowDisplay label="Error" multilineAllow={true}>
        {dailyReport.errorMessage}
      </RowDisplay>
      <ColumnDisplay label="Xml">
        <ButtonIconFileDownload fileUrl={dailyReport.xmlUrl} />
      </ColumnDisplay>
      <ColumnDisplay label="Request">
        <ButtonIconFileDownload fileUrl={dailyReport.wsLogRequestUrl} />
      </ColumnDisplay>
      <ColumnDisplay label="Response">
        <ButtonIconFileDownload fileUrl={dailyReport.wsLogResponseUrl} />
      </ColumnDisplay>
    </Container>
  );
};

export default EInvoiceDailyReportDetailsPopup;
