import { useEffect, useState } from "react";
import { AdminTemplateDto } from "../../api/admin/dtos/AdminTemplateDto";
import { TemplateConfigDto } from "../../api/app/dtos/TemplateConfigDto";
import { TemplateGroup } from "../../api/shared/enums/TemplateGroup";
import { TemplateType } from "../../api/shared/enums/TemplateType";
import ButtonLink from "../../shared/components/Button/ButtonLink";
import { TypeIcon } from "../../shared/components/Icon";
import InputSelectGuid from "../../shared/components/inputs/select/InputSelectGuid";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import Spinner from "../../shared/components/Spinner";
import {
  useApiAdminAccountPublicTemplateEmail,
  useApiAdminAccountPublicTemplateGeneric,
  useApiAdminAccountPublicTemplatePdf,
} from "../../api/shared/hooks/useApiAdmin";
import { useApiTemplateConfig } from "../../api/shared/hooks/useApiApp";
import { useModal } from "../../shared/hooks/useModal";
import { IdGuidName } from "../../shared/IdGuidName";
import TemplateConfigEmailTestPopup from "./TemplateConfig.EmailTestPopup";

const TemplateConfigType = (props: {
  label: string;
  templateConfig: TemplateConfigDto;
  templateGroup: TemplateGroup;
  templateType: TemplateType;
  onChange(template?: AdminTemplateDto): void;
}) => {
  const [templates, setTemplates] = useState<AdminTemplateDto[]>();
  const [apiTemplateConfig] = useApiTemplateConfig();
  const [apiTemplatePdf] = useApiAdminAccountPublicTemplatePdf();
  const [apiTemplateEmail] = useApiAdminAccountPublicTemplateEmail();
  const [apiTemplateGeneric] = useApiAdminAccountPublicTemplateGeneric();
  const [modal] = useModal();

  useEffect(() => {
    const load = async () => {
      if (props.templateGroup == TemplateGroup.Email) {
        setTemplates(await apiTemplateEmail.getByType(props.templateType));
        return;
      }
      if (props.templateGroup == TemplateGroup.Pdf) {
        setTemplates(await apiTemplatePdf.getByType(props.templateType));
        return;
      }
      setTemplates(await apiTemplateGeneric.getByType(props.templateType));
    };
    load();
  }, []);

  if (!templates) {
    return <Spinner />;
  }

  return (
    <Container>
      <Row>
        <InputSelectGuid
          label={props.label}
          emptyText={"Por defecto"}
          options={templates.map(
            (t) => new IdGuidName(t.id, (t.isGlobal ? "Global - " : "") + t.name)
          )}
          value={props.templateConfig?.templateId}
          onChange={(value: string) => {
            if (!value) {
              props.onChange(undefined);
              return;
            }
            props.onChange(templates.find((c) => c.id === value));
          }}
        />
      </Row>
      <Row paddingTop={0}>
        {props.templateGroup == TemplateGroup.Pdf && (
          <ButtonLink
            icon={TypeIcon.download}
            text="Descargar PDF de prueba"
            onClick={() => {
              apiTemplateConfig.pdfConfigTestDownload(
                props.templateConfig.templateType,
                props.templateConfig.templateId
              );
            }}
          />
        )}
        {props.templateGroup == TemplateGroup.Email && (
          <ButtonLink
            icon={TypeIcon.email}
            text="Enviar correo de prueba"
            onClick={() => {
              modal.open(
                <TemplateConfigEmailTestPopup templateConfig={props.templateConfig} />,
                () => {
                  // Do nothing
                }
              );
            }}
          />
        )}
      </Row>
    </Container>
  );
};

export default TemplateConfigType;
