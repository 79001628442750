// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { EInvoiceAuthorizationDto } from "../dtos/EInvoiceAuthorizationDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { EInvoiceAuthorizationFiltersDto } from "../dtos/EInvoiceAuthorizationFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { EInvoiceAuthorizationVerifyUsageInfoDto } from "../dtos/EInvoiceAuthorizationVerifyUsageInfoDto";

export class ApiEInvoiceAuthorization {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<EInvoiceAuthorizationFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<EInvoiceAuthorizationDto>> {
    return this.apiService.apiPost("/e-invoice-authorization/paged", searchDto, apiOptions);
  }

  verifyUsageByType(
    invoiceType: number,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceAuthorizationVerifyUsageInfoDto> {
    return this.apiService.apiGet("/e-invoice-authorization/verify-usage-by-type?" + 
      (invoiceType ? "&invoiceType=" + invoiceType : ""), apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceAuthorizationDto> {
    return this.apiService.apiPost("/e-invoice-authorization/" + id, null, apiOptions);
  }

  getInfo(
    apiOptions?: ApiOptions
  ): Promise<EInvoiceAuthorizationDto> {
    return this.apiService.apiPost("/e-invoice-authorization/get-info", null, apiOptions);
  }

  createFromValidated(
    eInvoiceAuthorizationDto: EInvoiceAuthorizationDto,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceAuthorizationDto> {
    return this.apiService.apiPost("/e-invoice-authorization/create-from-validated", eInvoiceAuthorizationDto, apiOptions);
  }

  setAsCurrent(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceAuthorizationDto> {
    return this.apiService.apiPut("/e-invoice-authorization/set-as-current/" + id, null, apiOptions);
  }

  setAsNoCurrent(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceAuthorizationDto> {
    return this.apiService.apiPut("/e-invoice-authorization/set-as-no-current/" + id, null, apiOptions);
  }

}
