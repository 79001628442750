// File generated by Gesta.Build.PostEvents
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { EInvoicePurchaseApprovalStatus } from "../../shared/enums/EInvoicePurchaseApprovalStatus";
import { FilterRangeDateDto } from "../../shared/dtos/FilterRangeDateDto";
import { ContactSimpleDto } from "./ContactSimpleDto";

export class EInvoiceEnvelopePurchaseFiltersDto extends FiltersDto {
  statusList: EInvoicePurchaseApprovalStatus[] = [];
  createdDate: FilterRangeDateDto;
  contactId: string;
  contact: ContactSimpleDto;
  taxPayerId: string = "";
  internalSenderId: number | null = null;
  responseEmailTo: string = "";
}
