// File generated by Gesta.Build.PostEvents
import { AdminProviderSimpleDto } from "./AdminProviderSimpleDto";
import { Country } from "../../shared/enums/Country";
import { AdminProviderSettingsDto } from "./AdminProviderSettingsDto";
import { AdminAccountSimpleDto } from "../../shared/dtos/AdminAccountSimpleDto";

export class AdminProviderDto extends AdminProviderSimpleDto {
  country: Country;
  providerSettings: AdminProviderSettingsDto;
  ownerAccountId: string;
  ownerAccount: AdminAccountSimpleDto;
}
