import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect } from "react";
import { ModalSize } from "../../context/ModalProvider";
import { useContextAppInfo } from "../../hooks/useContextAppInfo";
import { useModal } from "../../hooks/useModal";
import { ThemeVariant } from "../../ThemeVariant";
import InputCheck from "../inputs/InputCheck";
import Container from "./GContainer";
import Row from "./GRow";

const StylePopup = () => {
  const [modal] = useModal();
  const { appInfo, appInfoChangeThemeVariant, appInfoDevelopBoxLines } = useContextAppInfo();

  useEffect(() => {
    modal.setVisible("Estilos", ModalSize.small);
  }, [modal]);

  if (!appInfo) {
    return null;
  }

  return (
    <Container>
      <Row>
        <RadioGroup
          value={appInfo.muiVariant}
          name="variant"
          onChange={(e: any) => {
            appInfoChangeThemeVariant(e.target.value);
          }}>
          <FormControlLabel
            value={ThemeVariant.filled}
            control={<Radio />}
            label="Filled"
            checked={appInfo.muiVariant === ThemeVariant.filled}
          />
          <FormControlLabel
            value={ThemeVariant.standard}
            control={<Radio />}
            label="Standard"
            checked={appInfo.muiVariant === ThemeVariant.standard}
          />
          <FormControlLabel
            value={ThemeVariant.outlined}
            control={<Radio />}
            label="Outlined"
            checked={appInfo.muiVariant === ThemeVariant.outlined}
          />
        </RadioGroup>
      </Row>
      <Row label="Desarrollo">
        <InputCheck
          label="Mostrar recuadros en Rows/Columns"
          value={appInfo.developShowLinesBoxes}
          onChange={(value) => appInfoDevelopBoxLines(value)}
        />
      </Row>
    </Container>
  );
};

export default StylePopup;
