import { useEffect, useState } from "react";
import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import { useApiPurchase } from "../../api/shared/hooks/useApiApp";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useModal } from "../../shared/hooks/useModal";
import PurchaseDetails from "./PurchaseDetails";

const PurchaseDetailsPopup = (props: { purchaseId: string }) => {
  const [modal] = useModal();
  const [apiPurchase] = useApiPurchase();
  const [eInvoicePurchase, setPurchase] = useState<PurchaseDto>();

  useEffect(() => {
    const load = async () => {
      const eInvoicePurchase = await apiPurchase.getById(props.purchaseId);
      setPurchase(eInvoicePurchase as PurchaseDto);
      modal.setVisible("Factura de compra", ModalSize.big);
    };
    load();
  }, []);

  if (!eInvoicePurchase) {
    return <></>;
  }

  return (
    <Container>
      <PurchaseDetails purchase={eInvoicePurchase} />
    </Container>
  );
};

export default PurchaseDetailsPopup;
