import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { ContactDto } from "../../api/app/dtos/ContactDto";
import { ContactFiltersDto } from "../../api/app/dtos/ContactFiltersDto";
import { GridName } from "../../api/shared/enums/GridName";
import { enumName } from "../../api/shared/enums/_enumName";
import { ButtonCreate } from "../../shared/components/Button/ButtonCreate";
import Grid from "../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
  MobileSpecialType,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../shared/components/Layout/Page";
import { useApiContact, useApiExportContact } from "../../api/shared/hooks/useApiApp";
import { useModal } from "../../shared/hooks/useModal";
import ContactCreate from "./ContactCreatePopup";
import { getContactListItemMenu } from "./ContactList.ItemMenu";

const ContactList = () => {
  const [modal] = useModal();
  const [initialFilters] = useState(new ContactFiltersDto());
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [apiContact] = useApiContact();
  const [apiExportContact] = useApiExportContact();

  const itemTemplates: Array<IGridItemTemplate<ContactDto>> = [
    {
      header: "Nombre",
      field: nameof<ContactDto>((p) => p.name),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.name),
        type: GridFilterType.text,
      },
      mobileSpecialType: MobileSpecialType.Main,
      width: 30,
    },
    {
      header: "Correo",
      field: nameof<ContactDto>((p) => p.email),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.email),
        type: GridFilterType.text,
      },
      width: 20,
    },
    {
      header: "Correo eFactura",
      field: nameof<ContactDto>((p) => p.eInvoiceEmail),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.eInvoiceEmail),
        type: GridFilterType.text,
      },
      hideByDefault: true,
      width: 20,
    },
    {
      header: "RUT",
      field: nameof<ContactDto>((p) => p.taxPayerId),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.taxPayerId),
        type: GridFilterType.text,
      },
      mobileSpecialType: MobileSpecialType.Secondary,
      width: 20,
    },
    {
      header: "Razón social",
      field: nameof<ContactDto>((p) => p.companyName),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.companyName),
        type: GridFilterType.text,
      },
      mobileSpecialType: MobileSpecialType.Secondary,
      width: 20,
    },
    {
      header: "Tipo documento",
      field: nameof<ContactDto>((p) => p.info.idCardType),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.idCardType),
        type: GridFilterType.enum,
        enumName: enumName.IdCardType,
      },
      cellType: GridCellType.enum,
      cellTypeEnumName: enumName.IdCardType,
      hideByDefault: true,
      width: 20,
    },
    {
      header: "Documento",
      field: nameof<ContactDto>((p) => p.info.idCardNumber),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.idCardNumber),
        type: GridFilterType.text,
      },
      hideByDefault: true,
      width: 20,
    },
    {
      header: "Teléfono",
      field: nameof<ContactDto>((p) => p.info.phone),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.phone),
        type: GridFilterType.text,
      },
      width: 20,
    },
    {
      header: "Es cliente",
      field: nameof<ContactDto>((p) => p.isCustomer),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.isCustomer),
        type: GridFilterType.boolean,
      },
      width: 10,
      hideByDefault: true,
      cellType: GridCellType.iconCheck,
    },
    {
      header: "Es proveedor",
      field: nameof<ContactDto>((p) => p.isProvider),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.isProvider),
        type: GridFilterType.boolean,
      },
      width: 10,
      hideByDefault: true,
      cellType: GridCellType.iconCheck,
    },
    {
      header: "Tipo",
      field: nameof<ContactDto>((p) => p.contactType),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.contactType),
        type: GridFilterType.enum,
        enumName: enumName.ContactType,
      },
      width: 10,
      hideByDefault: true,
      cellTypeEnumName: enumName.ContactType,
    },
    {
      header: "Dirección",
      field: nameof<ContactDto>((p) => p.info.address.addressLine),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.addressLine),
        type: GridFilterType.text,
      },
      hideByDefault: true,
      width: 10,
    },
    {
      header: "Ciudad",
      field: nameof<ContactDto>((p) => p.info.address.city),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.addressCity),
        type: GridFilterType.text,
      },
      hideByDefault: true,
      width: 10,
    },
    {
      header: "Departamento",
      field: nameof<ContactDto>((p) => p.info.address.state),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.addressState),
        type: GridFilterType.enumList,
        enumName: enumName.State,
      },
      width: 10,
      hideByDefault: true,
      cellTypeEnumName: enumName.State,
    },
    {
      header: "País",
      field: nameof<ContactDto>((p) => p.info.address.country),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.addressCountry),
        type: GridFilterType.enumList,
        enumName: enumName.Country,
      },
      width: 10,
      hideByDefault: true,
      cellTypeEnumName: enumName.Country,
    },
    {
      header: "Notas",
      field: nameof<ContactDto>((p) => p.info.notes),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.notes),
        type: GridFilterType.text,
      },
      hideByDefault: true,
      width: 20,
    },
    {
      header: "Id externo",
      field: nameof<ContactDto>((p) => p.openApiExternalId),
      filter: {
        propertyName: nameof<ContactFiltersDto>((p) => p.openApiExternalId),
        type: GridFilterType.text,
      },
      hideByDefault: true,
      width: 10,
    },
  ];

  return (
    <Page
      title="Contactos"
      titleButton={
        <ButtonCreate
          text="Nuevo contacto"
          onClick={() =>
            modal.open(<ContactCreate />, () => {
              setReloadGrid(new Date());
            })
          }
        />
      }>
      <Grid
        itemTemplates={itemTemplates}
        reloadGrid={reloadGrid}
        gridName={GridName.Contacts}
        initialFilters={initialFilters}
        onSearch={(search, options) => apiContact.getPaged(search, options)}
        onExport={(exportRequest, apiOptions) =>
          apiExportContact.default(exportRequest as any, apiOptions)
        }
        onDisplayItemMenu={(contact: ContactDto) =>
          getContactListItemMenu(contact, modal, () => {
            setReloadGrid(new Date());
          })
        }
      />
    </Page>
  );
};

export default ContactList;
