import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AccountBranchDto } from "../../../api/app/dtos/AccountBranchDto";
import { PointOfSaleDto } from "../../../api/app/dtos/PointOfSaleDto";
import { PointOfSaleFiltersDto } from "../../../api/app/dtos/PointOfSaleFiltersDto";
import { FiltersDto } from "../../../api/shared/dtos/FiltersDto";
import { useApiAdminProviderAccessPointOfSale } from "../../../api/shared/hooks/useApiAdminProviderAccess";
import { useApiCrossShardPointOfSale } from "../../../api/shared/hooks/useApiCrossShard";
import Grid, { GridColumnAlign } from "../../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
} from "../../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../../shared/components/Layout/Page";
import { AppInfoSiteType } from "../../../shared/context/AppInfoProvider";
import { useContextAppInfo } from "../../../shared/hooks/useContextAppInfo";
import { useModal } from "../../../shared/hooks/useModal";
import { useNotifier } from "../../../shared/hooks/useNotifier";
import getAdminPointOfSaleListItemMenu from "./AdminPointOfSaleList.ItemMenu";

const AdminPointOfSaleList = () => {
  const [reloadGrid, setReloadGrid] = useState<Date>();
  const [modal] = useModal();
  const notifier = useNotifier();
  const [apiCrossShardPointOfSale] = useApiCrossShardPointOfSale();
  const [apiAdminAccessPointOfSale] = useApiAdminProviderAccessPointOfSale();
  const { appInfo } = useContextAppInfo();
  const [initialFilters] = useState(() => {
    const filters = new FiltersDto();
    filters.crossShardIncludeAllProviders = appInfo?.siteType == AppInfoSiteType.SuperAdmin;
    return filters;
  });

  const itemTemplates: IGridItemTemplate<PointOfSaleDto>[] = [
    {
      header: "Cuenta",
      field: nameof<PointOfSaleDto>((p) => p.account.name),
      filter: {
        propertyName: nameof<PointOfSaleFiltersDto>((p) => p.crossShardAdminAccount),
        type: GridFilterType.account,
      },
      width: 30,
    },
    {
      header: "Sucursal",
      field: nameof<PointOfSaleDto>((p) => p.accountBranch.displayName),
      orderBy:
        nameof<PointOfSaleDto>((p) => p.accountBranch) +
        "." +
        nameof<AccountBranchDto>((p) => p.branchCode),
      width: 20,
    },
    {
      header: "Nombre",
      field: nameof<PointOfSaleDto>((p) => p.name),
      width: 20,
    },
    {
      header: "Por defecto",
      field: nameof<PointOfSaleDto>((p) => p.isDefault),
      width: 10,
      align: GridColumnAlign.center,
      cellType: GridCellType.iconCheck,
    },
  ];

  return (
    <Page title="Puntos de venta">
      <Grid
        itemTemplates={itemTemplates}
        initialFilters={initialFilters}
        isCrossShard={true}
        reloadGrid={reloadGrid}
        defaultSortBy={[{ columnName: nameof<PointOfSaleDto>((p) => p.name), asc: true }]}
        onDisplayItemMenu={(item) =>
          getAdminPointOfSaleListItemMenu(item, apiAdminAccessPointOfSale, modal, notifier, () => {
            setReloadGrid(new Date());
          })
        }
        onSearch={(search, options) => apiCrossShardPointOfSale.getPaged(search, options)}
      />
    </Page>
  );
};

export default AdminPointOfSaleList;
