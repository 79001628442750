// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { AppNotificationType } from "../../shared/enums/AppNotificationType";
import { AccountUserDto } from "./AccountUserDto";
import { AppNotificationGroup } from "../../shared/enums/AppNotificationGroup";

export class AppNotificationDto extends BaseEntityDto {
  createDate: Date = new Date();
  type: AppNotificationType;
  accountUserId: string;
  accountUser: AccountUserDto;
  onlyAdmins: boolean = false;
  group: AppNotificationGroup;
  isRead: boolean = false;
  title: string = "";
  body: string = "";
  traceId: string = "";
}
