import { AdminAccountDto } from "../../../api/admin/dtos/AdminAccountDto";
import { AdminAccountSimpleDto } from "../../../api/shared/dtos/AdminAccountSimpleDto";
import { Country } from "../../../api/shared/enums/Country";
import InputAutocompleteAdminAccount from "../../../shared/components/inputs/autocomplete/InputAutocompleteAdminAccount";
import InputRadioBoolean from "../../../shared/components/inputs/InputRadioBoolean";
import InputSelectCountry from "../../../shared/components/inputs/select/InputSelectCountry";
import Row from "../../../shared/components/Layout/GRow";

const AdminTemplateSelectGlobalOrAccount = (props: {
  adminAccount: AdminAccountSimpleDto | null;
  country: Country | null;
  isAdmin: boolean;
  isGlobal: boolean;
  onChangeAdminAccount: (adminAccount: AdminAccountDto | null) => void;
  onChangeCountry: (country: Country | null) => void;
  onChangeIsGlobal: (value: boolean) => void;
}) => {
  return (
    <>
      {!props.isAdmin && (
        <Row>
          <InputRadioBoolean
            labelTrue={"Global"}
            labelFalse={"Específico para una cuenta"}
            value={props.isGlobal}
            onChange={props.onChangeIsGlobal}
          />
        </Row>
      )}
      <Row>
        {props.isGlobal && (
          <InputSelectCountry
            label="Mostrar sólo para país"
            value={props.country}
            onlyAvailable={true}
            onChange={props.onChangeCountry}
          />
        )}
        {!props.isGlobal && (
          <InputAutocompleteAdminAccount
            label="Cuenta"
            value={props.adminAccount as AdminAccountDto}
            onChange={(adminAccount) => props.onChangeAdminAccount(adminAccount)}
          />
        )}
      </Row>
    </>
  );
};

export default AdminTemplateSelectGlobalOrAccount;
