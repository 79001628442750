import React, { createContext, useEffect, useReducer } from "react";
import ValidationFieldsTrack from "../validation/context/ValidationFieldsTrack";
import ValidationResultSchema from "../validation/context/ValidationResultSchema";
import ValidationSchema from "../validation/schema/ValidationSchema";
import modelStateContextReducer from "./ModelStateContext.Reducer";

export class ModelState {
  initKey: string;
  model: any;
  validation?: ValidationResultSchema;
  validationSchema?: ValidationSchema;
  validationFieldsTrack: ValidationFieldsTrack;
  tryFormSubmitCount = 0;
}

export enum ModelStateContextActionType {
  arrayItemAdd,
  arrayItemRemove,
  arrayItemReplace,
  custom,
  updateProperties,
  fieldTrackInit,
  fieldTrackSetChildrenTouched,
  fieldTrackSetTouched,
  fieldTrackSetNotTouched,
  init,
  replaceModel,
  submit,
}

export type ModelStateContextAction =
  | {
      type: ModelStateContextActionType.arrayItemAdd | ModelStateContextActionType.arrayItemReplace;
      propertyFullName: string;
      insertAt?: number;
      customReducer?: (model: any, action: ModelStateContextAction) => any;
      item: any;
    }
  | {
      type: ModelStateContextActionType.arrayItemRemove;
      propertyFullName: string;
      deleteAt: number;
      deleteCount?: number;
      customReducer?: (model: any, action: ModelStateContextAction) => any;
    }
  | {
      type: ModelStateContextActionType.custom;
      typeCustom: number;
      payload?: any;
      customReducer: (model: any, action: ModelStateContextAction) => any;
    }
  | {
      type: ModelStateContextActionType.updateProperties;
      properties: {
        propertyFullName: any;
        value: any;
        setTouched?: boolean;
        updateIdLinkedProperty?: boolean;
      }[];
      customReducer?: (model: any, action: ModelStateContextAction) => any;
    }
  | {
      type:
        | ModelStateContextActionType.fieldTrackInit
        | ModelStateContextActionType.fieldTrackSetChildrenTouched
        | ModelStateContextActionType.fieldTrackSetTouched
        | ModelStateContextActionType.fieldTrackSetNotTouched;
      propertyFullName: string;
    }
  | {
      type: ModelStateContextActionType.init;
      model: any;
      validationSchema?: ValidationSchema;
    }
  | {
      type: ModelStateContextActionType.submit;
    }
  | {
      type: ModelStateContextActionType.replaceModel;
      model: any;
    };

export interface ModelStateActionPropertyUpdate {
  propertyFullName: string;
  value?: any;
  setTouched: boolean;
  updateIdLinkedProperty?: boolean;
}

const ModelStateContext = createContext<{
  modelState: ModelState;
  modelStateDispatcher: React.Dispatch<ModelStateContextAction>;
}>({
  modelState: new ModelState(),
  modelStateDispatcher: () => null,
});

const StateProvider = (props: {
  model: any;
  validationSchema?: ValidationSchema;
  onSubmit?: (model: any) => void;
  children: (props: { handleSubmit: () => void }) => JSX.Element;
}) => {
  const [modelState, modelStateDispatcher] = useReducer(modelStateContextReducer, new ModelState());

  useEffect(() => {
    modelStateDispatcher({
      type: ModelStateContextActionType.init,
      model: props.model,
      validationSchema: props.validationSchema,
    });
  }, [props.model]);

  useEffect(() => {
    if (
      props.onSubmit &&
      modelState.tryFormSubmitCount > 0 &&
      (!modelState.validation || modelState.validation?.isValid)
    ) {
      props.onSubmit(modelState.model);
    }
  }, [modelState.tryFormSubmitCount]);

  if (!modelState.initKey) {
    return null;
  }

  return (
    <ModelStateContext.Provider
      key={modelState.initKey}
      value={{ modelState, modelStateDispatcher }}>
      {modelState.model &&
        props.children({
          handleSubmit: () => {
            modelStateDispatcher({
              type: ModelStateContextActionType.submit,
            });
          },
        })}
    </ModelStateContext.Provider>
  );
};

export { ModelStateContext, StateProvider };
