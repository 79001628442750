import { useEffect, useState } from "react";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import { Country } from "../../api/shared/enums/Country";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiPublicQrQuery } from "../../api/shared/hooks/useApiPublic";
import { AppSize } from "../../shared/AppSize";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import InfoText, { InfoTextType } from "../../shared/components/InfoText";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Row from "../../shared/components/Layout/GRow";
import RowDisplay from "../../shared/components/Layout/GRowDisplay";
import RowTitle from "../../shared/components/Layout/GRowTitle";
import Spinner from "../../shared/components/Spinner";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { usePathQueryString } from "../../shared/hooks/usePathQueryString";
import useUtils from "../../shared/hooks/useUtils";
import { TextAlign } from "../../shared/TextAlign";

const PublicQrQuery = (props: { country: Country }) => {
  const pathQueryString = usePathQueryString();
  const [apiPublicQrQuery] = useApiPublicQrQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [saleDto, setSaleDto] = useState<SaleDto>();
  const [errorMessage, setErrorMessage] = useState("");
  const values = pathQueryString.toString().split("%2C");
  const { utils } = useUtils();
  const navigation = useNavigation();

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      setErrorMessage("");
      try {
        setSaleDto(
          await apiPublicQrQuery.querySale(
            props.country,
            values[0],
            values[1] as any,
            values[2],
            parseInt(values[3]),
            parseFloat(values[4]),
            values[5],
            values[6],
            {
              preventSpinner: true,
              preventNotifications: true,
            }
          )
        );
      } catch (error: any) {
        setErrorMessage(error?.message ?? error);
      }
      setIsLoading(false);
    };
    load();
  }, []);

  return (
    <>
      <Row>
        <RowTitle>Consulta comprobante</RowTitle>
      </Row>
      {isLoading && (
        <Row align={TextAlign.center}>
          <Spinner size={AppSize.biggest} />
        </Row>
      )}
      {errorMessage && (
        <Row>
          <InfoText type={InfoTextType.error}>{errorMessage}</InfoText>
        </Row>
      )}
      {saleDto && (
        <>
          <RowDisplay label="Tipo" minHeightIgnore={true}>
            {saleDto ? utils.enum.translate(enumName.InvoiceType, saleDto.invoiceType) : "-"}
          </RowDisplay>
          <RowDisplay label="Serie" minHeightIgnore={true}>
            {saleDto ? saleDto.serieAndNumber : "-"}
          </RowDisplay>
          <RowDisplay label="Fecha" minHeightIgnore={true}>
            {saleDto ? utils.date.toString(saleDto.date) : "-"}
          </RowDisplay>
          <RowDisplay label="Total">
            {saleDto ? utils.currency.toString(saleDto.currency, saleDto.total) : "-"}
          </RowDisplay>

          <Row align={TextAlign.center} minHeightIgnore={true}>
            <ComponentGroup>
              <ButtonSecondary
                text="Descargar PDF"
                icon={TypeIcon.pdf}
                disabled={!saleDto}
                onClick={() => navigation.newTab(saleDto?.pdfUrl ?? "")}
              />
              <ButtonSecondary
                text="Descargar XML"
                icon={TypeIcon.xml}
                disabled={!saleDto}
                onClick={() => navigation.newTab(saleDto?.xmlUrl ?? "")}
              />
            </ComponentGroup>
          </Row>
        </>
      )}
    </>
  );
};

export default PublicQrQuery;
