import { CSSProperties } from "react";
import { PurchaseExportRequestDto } from "../../../api/app/dtos/PurchaseExportRequestDto";
import { PurchaseFiltersDto } from "../../../api/app/dtos/PurchaseFiltersDto";
import { ReportTaxesPeriodDto } from "../../../api/app/dtos/ReportTaxesPeriodDto";
import { SaleExportRequestDto } from "../../../api/app/dtos/SaleExportRequestDto";
import { SaleFiltersDto } from "../../../api/app/dtos/SaleFiltersDto";
import { FilterRangeDateDto } from "../../../api/shared/dtos/FilterRangeDateDto";
import { Currency } from "../../../api/shared/enums/Currency";
import { FilterDateRangeType } from "../../../api/shared/enums/FilterDateRangeType";
import { enumName } from "../../../api/shared/enums/_enumName";
import { useApiExportPurchase, useApiExportSale } from "../../../api/shared/hooks/useApiApp";
import { useAppTheme } from "../../../AppThemeProvider";
import ButtonContextMenu from "../../../shared/components/ContextMenu/ButtonContextMenu";
import { TypeIcon } from "../../../shared/components/Icon";
import InfoText, { InfoTextType } from "../../../shared/components/InfoText";
import { useContextAppInfo } from "../../../shared/hooks/useContextAppInfo";
import useUtils from "../../../shared/hooks/useUtils";
import { AppThemeColor } from "../../../styles/color";
import ReportLine from "../ReportLine";
import ReportTaxesPeriodTax from "./ReportTaxes.PeriodTax";

const ReportTaxesPeriod = (props: {
  period: ReportTaxesPeriodDto;
  defaultCurrency: Currency;
  style: CSSProperties;
}) => {
  const { utils } = useUtils();
  const { requestedExportListOpen } = useContextAppInfo();
  const [apiExportSale] = useApiExportSale();
  const [apiExportPurchase] = useApiExportPurchase();
  const appTheme = useAppTheme();

  const style: CSSProperties = {
    border: "1px solid " + appTheme.toHexColor(AppThemeColor.primary),
    borderRadius: 5,
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
    ...props.style,
  };

  const styleHeader: CSSProperties = {
    display: "flex",
    backgroundColor: appTheme.toHexColor(AppThemeColor.white),
    borderBottom: "1px solid " + appTheme.toHexColor(AppThemeColor.primary),
    color: appTheme.toHexColor(AppThemeColor.black),
    fontWeight: "bold",
    fontSize: "1.5em",
    padding: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  };

  const styleContainer: CSSProperties = {
    padding: 10,
    display: "flex",
    flex: 1,
    flexDirection: "column",
  };

  const periodStart = utils.date.getDateParts(props.period.startDate);
  const periodName =
    utils.enum.translate(enumName.Month, periodStart?.month ?? 0) + " " + periodStart?.year;

  const exportSales = async () => {
    const saleExportRequest = new SaleExportRequestDto();
    saleExportRequest.fileName = "Ventas " + periodName + ".xlsx";
    saleExportRequest.includedAllFields = true;
    saleExportRequest.includeTaxes = true;
    saleExportRequest.isCancellationAsNegativeValues = true;
    saleExportRequest.filters = new SaleFiltersDto();
    saleExportRequest.filters.onlyValidStatus = true;
    saleExportRequest.filters.operationDate = new FilterRangeDateDto();
    saleExportRequest.filters.operationDate.type = FilterDateRangeType.Range;
    saleExportRequest.filters.operationDate.rangeMin = props.period.startDate;
    saleExportRequest.filters.operationDate.rangeMax = props.period.endDate;
    await apiExportSale.default(saleExportRequest);
    requestedExportListOpen(true);
  };

  const exportPurchase = async () => {
    const purchaseExportRequest = new PurchaseExportRequestDto();
    purchaseExportRequest.fileName = "Compras " + periodName + ".xlsx";
    purchaseExportRequest.includedAllFields = true;
    purchaseExportRequest.includeTaxes = true;
    purchaseExportRequest.isCancellationAsNegativeValues = true;
    purchaseExportRequest.filters = new PurchaseFiltersDto();
    purchaseExportRequest.filters.onlyValidStatus = true;
    purchaseExportRequest.filters.operationDate = new FilterRangeDateDto();
    purchaseExportRequest.filters.operationDate.type = FilterDateRangeType.Range;
    purchaseExportRequest.filters.operationDate.rangeMin = props.period.startDate;
    purchaseExportRequest.filters.operationDate.rangeMax = props.period.endDate;
    await apiExportPurchase.default(purchaseExportRequest);
    requestedExportListOpen(true);
  };

  const noInvoices =
    !props.period.salesCount &&
    !props.period.purchasesCount &&
    !props.period.salesPendingProcessCount;

  return (
    <div style={style}>
      <div style={styleHeader}>
        <div style={{ flex: 1 }}>{periodName}</div>
        <div>
          <ButtonContextMenu
            noPadding={true}
            items={[
              {
                text: "Descargar ventas",
                icon: TypeIcon.download,
                onClick: exportSales,
              },
              {
                text: "Descargar compras",
                icon: TypeIcon.download,
                onClick: exportPurchase,
              },
            ]}
          />
        </div>
      </div>
      <div style={styleContainer}>
        {noInvoices && (
          <div style={{ marginBottom: 20 }}>
            <InfoText type={InfoTextType.info}>No hay comprobantes para el período</InfoText>
          </div>
        )}

        {!!props.period.salesPendingProcessCount && (
          <div style={{ marginBottom: 20 }}>
            <InfoText type={InfoTextType.info}>
              Ventas pendientes de procesar: {props.period.salesPendingProcessCount}
            </InfoText>
          </div>
        )}

        {props.period.totalByTaxes.map((totalByTax, index) => (
          <ReportTaxesPeriodTax
            key={index}
            periodTax={totalByTax}
            defaultCurrency={props.defaultCurrency}
          />
        ))}

        <div style={{ flex: 1 }}></div>

        <ReportLine
          label="Venta"
          values={[utils.currency.toString(props.defaultCurrency, props.period.salesTotal)]}
        />

        <ReportLine
          label="Compra"
          values={[utils.currency.toString(props.defaultCurrency, props.period.purchasesTotal)]}
        />

        <ReportLine
          isTotalSummary={true}
          label="Total"
          values={[utils.currency.toString(props.defaultCurrency, props.period.total)]}
        />
      </div>
    </div>
  );
};

export default ReportTaxesPeriod;
