import { nameof } from "ts-simple-nameof";
import { AdminTermsAndConditionsDto } from "../../../api/admin/dtos/AdminTermsAndConditionsDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

export function useAdminTermsAndConditionsValidation() {
  const schema = new ValidationSchema();

  schema[nameof<AdminTermsAndConditionsDto>((p) => p.adminProvider)] =
    validate("Proveedor").required();
  schema[nameof<AdminTermsAndConditionsDto>((p) => p.mandatorySince)] =
    validate("Obligatoria desde").required();
  schema[nameof<AdminTermsAndConditionsDto>((p) => p.notificationChanges)] =
    validate("Cambios").required();
  schema[nameof<AdminTermsAndConditionsDto>((p) => p.termsAndConditions)] =
    validate("Términos y condiciones").required();
  schema[nameof<AdminTermsAndConditionsDto>((p) => p.privacyPolice)] =
    validate("Política de privacidad").required();

  return schema;
}
