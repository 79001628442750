import { useEffect, useState } from "react";
import { LocaleConfigSamplesDto } from "../../../api/app/dtos/LocaleConfigSamplesDto";
import { AppLocale } from "../../../api/shared/enums/AppLocale";
import { SentEmailStatus } from "../../../api/shared/enums/SentEmailStatus";
import { enumName } from "../../../api/shared/enums/_enumName";
import InputSelectEnum from "../../../shared/components/inputs/select/InputSelectEnum";
import Column from "../../../shared/components/Layout/GColumn";
import Container from "../../../shared/components/Layout/GContainer";
import Row from "../../../shared/components/Layout/GRow";
import Section from "../../../shared/components/Layout/GSection";
import { AppLocalActionType } from "../../../shared/context/LocaleProvider";
import { useApiLocale } from "../../../api/shared/hooks/useApiApp";
import { useContextLocale } from "../../../shared/hooks/useContextLocale";
import useUtils from "../../../shared/hooks/useUtils";
import { DateFormatType } from "../../../shared/utils/localizedUtil/localizedUtilDates";

const ConfigLocaleForm = () => {
  const { appLocaleState, dispatchAppLocale } = useContextLocale();
  const { utils } = useUtils();
  const [apiLocale] = useApiLocale();
  const [localeSamples, setLocaleSamples] = useState<LocaleConfigSamplesDto>();

  const enumSentEmailStatusValues = Object.keys(SentEmailStatus)
    .filter((v) => !isNaN(parseInt(v)))
    .map((v) => utils.enum.translate(enumName.SentEmailStatus, parseInt(v)));

  const loadSamples = async () => {
    setLocaleSamples(await apiLocale.getSamples(appLocaleState.appLocale));
  };

  useEffect(() => {
    loadSamples();
  }, [appLocaleState.appLocale]);

  return (
    <>
      <Row>
        <Container>
          <Column>
            <InputSelectEnum
              label="Región"
              enumTypeName={enumName.AppLocale}
              value={appLocaleState.appLocale}
              onChange={(value: AppLocale) =>
                dispatchAppLocale({ type: AppLocalActionType.setLocale, appLocale: value })
              }
            />
          </Column>
        </Container>
      </Row>
      <Column md={6}>
        <Section title="ClienteWeb" border={true}>
          <Column label="Fecha larga" md={6}>
            {utils.date.toString(new Date(), DateFormatType.DateHourMinutes)}
          </Column>
          <Column label="Fecha corta" md={6}>
            {utils.date.toString(new Date(), DateFormatType.OnlyDate)}
          </Column>
          <Column label="Entero" md={6}>
            {utils.number.toString(123456789, 0, 0)}
          </Column>
          <Column label="Decimal" md={6}>
            {utils.number.toString(123456789.123)}
          </Column>
          <Row label="Ejemplo estados (envío de correo)">
            {enumSentEmailStatusValues.join(", ")}
          </Row>
        </Section>
      </Column>
      <Column md={6}>
        <Section title="Servidor" border={true}>
          <Column label="Fecha larga" md={6}>
            {localeSamples?.dateLong}
          </Column>
          <Column label="Fecha corta" md={6}>
            {localeSamples?.dateShort}
          </Column>
          <Column label="Entero" md={6}>
            {localeSamples?.numberInt}
          </Column>
          <Column label="Decimal" md={6}>
            {localeSamples?.numberDecimal}
          </Column>
          <Row label="Ejemplo estados (envío de correo)">
            {localeSamples?.enumTranslateSample.join(", ")}
          </Row>
        </Section>
      </Column>
    </>
  );
};

export default ConfigLocaleForm;
