import { useContext } from "react";
import { AppRoute } from "../../AppRoutes";
import { TypeIcon } from "../../shared/components/Icon";
import LayoutSideMenuArea from "../../shared/components/Layout/LayoutSideMenu.Area";
import Props from "../../shared/components/Props";
import { AppInfoContext } from "../../shared/context/AppInfoProvider";

export interface PropsAppMenu extends Props {
  isExpanded: boolean;
  onNavigate(url: string): void;
}

const AppMenu = (props: PropsAppMenu) => {
  const { appInfo } = useContext(AppInfoContext);

  const area = [
    {
      name: "Ventas",
      icon: TypeIcon.sales,
      navigateUrl: AppRoute.sales.base,
    },
    {
      name: "Contactos",
      icon: TypeIcon.contact,
      navigateUrl: AppRoute.contacts,
    },
    {
      name: "Productos",
      icon: TypeIcon.products,
      navigateUrl: AppRoute.products,
    },
    {
      name: "Compras",
      icon: TypeIcon.purchase,
      navigateUrl: AppRoute.purchases.base,
    },
    {
      name: "Reportes",
      icon: TypeIcon.lineChart,
      navigateUrl: AppRoute.reports.base,
    },
  ];

  return (
    <>
      {area.map((area) => (
        <LayoutSideMenuArea
          key={area.name}
          title={area.name}
          icon={area.icon}
          isExpanded={props.isExpanded}
          isSelected={appInfo?.currentArea === area.navigateUrl}
          onClick={() => props.onNavigate(area.navigateUrl)}
        />
      ))}
    </>
  );
};

export default AppMenu;
