import { ButtonVariant } from "../../ButtonVariant";
import { ThemeSize } from "../../ThemeSize";
import Button, { PropsButton } from "./Button";

export const ButtonLink = (props: PropsButton) => {
  return (
    <Button variant={ButtonVariant.text} size={ThemeSize.small} {...props} />
  );
};

export default ButtonLink;
