import { EInvoiceDailyReportDto } from "../../../../api/app/dtos/EInvoiceDailyReportDto";
import { ContextMenuItem } from "../../../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../../../shared/components/Icon";
import { ModalService } from "../../../../shared/hooks/useModal";
import { Navigation } from "../../../../shared/hooks/useNavigation";
import EInvoiceDailyReportDetailsPopup from "./EInvoiceDailyReportDetailsPopup";

export default function getEInvoiceDailyReportListItemMenu(
  eInvoiceDailyReportDto: EInvoiceDailyReportDto,
  modal: ModalService,
  navigation: Navigation
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        modal.open(<EInvoiceDailyReportDetailsPopup id={eInvoiceDailyReportDto.id} />);
      },
    },
    {
      text: "Descargar Xml",
      hide: !eInvoiceDailyReportDto.xmlUrl,
      icon: TypeIcon.xml,
      onClick: () => {
        navigation.newTab(eInvoiceDailyReportDto.xmlUrl);
      },
    },
  ];
}
