import { Grid } from "@mui/material";
import { CSSProperties } from "react";
import { useContextAppInfo } from "../../hooks/useContextAppInfo";
import { LayoutSize, LAYOUT_DEFAULT_SPACING } from "./LayoutConstants";

const Container = (props: {
  children?: any;
  className?: string;
  spacing?: LayoutSize;
  style?: CSSProperties;
}) => {
  const { appInfo } = useContextAppInfo();

  const style: CSSProperties = {
    ...props.style,
    boxShadow: appInfo?.developShowLinesBoxes ? "inset 0px 0px 2px 2px green" : undefined,
  };

  return (
    <Grid
      className={"layout-container " + (props.className ?? "")}
      container
      spacing={props.spacing ?? LAYOUT_DEFAULT_SPACING}
      style={style}>
      {props.children}
    </Grid>
  );
};

export default Container;
