import { nameof } from "ts-simple-nameof";
import { EInvoiceEnvelopeStatusResponseDto } from "../../../../api/app/dtos/EInvoiceEnvelopeStatusResponseDto";
import { enumName } from "../../../../api/shared/enums/_enumName";
import Grid from "../../../../shared/components/grid/Grid";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Section from "../../../../shared/components/Layout/GSection";

const EInvoiceEnvelopeStatusResponseDetailsSection = (props: {
  statusResponseList: EInvoiceEnvelopeStatusResponseDto[];
}) => {
  const columns: IGridItemTemplate<EInvoiceEnvelopeStatusResponseDto>[] = [
    {
      header: "Fecha",
      field: nameof<EInvoiceEnvelopeStatusResponseDto>((p) => p.date),
      width: 10,
      cellType: GridCellType.dateTimeWithSeconds,
    },
    {
      header: "Tipo",
      field: nameof<EInvoiceEnvelopeStatusResponseDto>((p) => p.type),
      width: 10,
      cellType: GridCellType.enum,
      cellTypeEnumName: enumName.EInvoiceEnvelopeStatusResponseType,
    },
    {
      header: "Id",
      field: nameof<EInvoiceEnvelopeStatusResponseDto>((p) => p.responseId),
      width: 10,
    },
    {
      header: "Notas",
      field: nameof<EInvoiceEnvelopeStatusResponseDto>((p) => p.notes),
      width: 40,
    },
    {
      header: "Xml",
      field: nameof<EInvoiceEnvelopeStatusResponseDto>((p) => p.xmlUrl),
      width: 10,
      cellType: GridCellType.download,
    },
  ];

  return (
    <Section>
      <Grid
        itemTemplates={columns}
        clientSideItems={props.statusResponseList}
        preventShowDefaultSearch={true}
        hideIncludeInactiveButton={true}
      />
    </Section>
  );
};

export default EInvoiceEnvelopeStatusResponseDetailsSection;
