import { nameof } from "ts-simple-nameof";
import { AccountBranchDto } from "../../api/app/dtos/AccountBranchDto";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormAutocompleteAdminAccount from "../../shared/components/forms/Autocomplete/FormAutocompleteAdminAccount";
import FormAddress from "../../shared/components/forms/FormAddress";
import FormSwitch from "../../shared/components/forms/FormSwitch";
import FormText from "../../shared/components/forms/FormText";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";

const AdminAccountBranchForm = (props: { onSave(): void; isEdit: boolean }) => {
  return (
    <>
      <Row>
        {!props.isEdit && (
          <FormAutocompleteAdminAccount
            label="Cuenta"
            propertyName={nameof<AccountBranchDto>((p) => p.account)}
          />
        )}
      </Row>
      <Row>
        <FormText label="Nombre" propertyName={nameof<AccountBranchDto>((p) => p.branchName)} />
      </Row>
      <Row>
        <FormText label="Código" propertyName={nameof<AccountBranchDto>((p) => p.branchCode)} />
      </Row>
      <Row>
        <FormSwitch
          label="Por defecto"
          propertyName={nameof<AccountBranchDto>((p) => p.isDefault)}
        />
      </Row>
      <Row>
        <FormAddress propertyName={nameof<AccountBranchDto>((p) => p.address)} />
      </Row>
      <RowButtons>
        <ButtonAccept onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default AdminAccountBranchForm;
