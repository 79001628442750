// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { AdminAddressDto } from "./AdminAddressDto";
import { CompanyType } from "../../shared/enums/CompanyType";

export class AdminAccountCompanyDto extends BaseEntityDto {
  activity: string = "";
  address: AdminAddressDto;
  comercialName: string = "";
  email: string = "";
  eInvoiceIsExemptTaxPayer: boolean = false;
  eInvoiceIsTaxUpToDate: boolean = false;
  eInvoicePrintLegendExemptTaxPayer: string = "";
  eInvoiceRegimenStartDate: Date | null = null;
  name: string = "";
  phone: string = "";
  taxPayerId: string = "";
  type: CompanyType;
}
