import { useAppTheme } from "../../AppThemeProvider";
import { AppThemeColor } from "../../styles/color";
import { AppSize } from "../AppSize";
import Spinner from "./Spinner";

const SpinnerBlock = (props: { label?: string }) => {
  const appTheme = useAppTheme();
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        paddingTop: "180px",
        width: "100%",
        height: "100vh",
        textAlign: "center",
        zIndex: 100000,
        userSelect: "none",
      }}>
      <Spinner size={AppSize.biggest} />
      {props.label && (
        <div
          style={{
            marginTop: 30,
            fontSize: AppSize.small,
            color: appTheme.toHexColor(AppThemeColor.gray),
          }}>
          {props.label}
        </div>
      )}
    </div>
  );
};

export default SpinnerBlock;
