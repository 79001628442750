import { CSSProperties, ReactElement, useEffect, useRef, useState } from "react";
import { SearchResultItemDto } from "../../../api/shared/dtos/SearchResultItemDto";
import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import { useIsMobile } from "../../hooks/useIsMobile";
import { ContextMenuItem } from "../ContextMenu/ContextMenu";
import Icon, { TypeIcon } from "../Icon";
import Row from "../Layout/GRow";
import Spinner from "../Spinner";
import { IGridState } from "./GridContext";
import GridRow from "./GridRow";
import { useContextGrid } from "./useContextGrid";

const GridBody = (props: {
  onDisplayItemMenu?(item: any): ContextMenuItem[];
  onExpandRowShouldDisableButton?(item: any): boolean;
  onExpandRow?(item: any): Promise<ReactElement>;
  onGetRowStyle?(item: any): CSSProperties;
  onSelectRow(item: any): void;
}) => {
  const grid = useContextGrid();
  const isMobile = useIsMobile();
  const [bodyMaxHeight, setBodyMaxHeight] = useState<number | undefined>(
    isMobile ? undefined : 100
  );
  const bodyRef = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    const position = bodyRef.current?.getBoundingClientRect();
    if (!position || !position.top) {
      return;
    }

    setBodyMaxHeight(window.innerHeight - position.top - 75);
  };

  useEffect(() => {
    if (isMobile) {
      return;
    }

    setTimeout(() => handleResize(), 10);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [grid.gridState.pagedResult]);

  useEffect(() => {
    if (isMobile) {
      return;
    }
    setTimeout(() => handleResize(), 500);
  }, [grid.gridState.filtersAllExpanded]);

  if (grid.gridState.error) {
    return renderError(grid.gridState);
  }

  if (
    grid.gridState.pagedResult?.totalItemCount === 0 &&
    !grid.gridState.pagedResult?.pageContinuationToken
  ) {
    return renderNoData(grid.gridState);
  }

  return (
    <>
      {grid.gridState.isLoading && renderSpinner}
      <div ref={bodyRef} className="grid-body" style={{ maxHeight: bodyMaxHeight }}>
        {grid.gridState.pagedResult?.items.map((item: SearchResultItemDto<any>, index: number) => (
          <GridRow
            key={index}
            item={item}
            index={index}
            onDisplayItemMenu={props.onDisplayItemMenu}
            onExpandRow={props.onExpandRow}
            onExpandRowShouldDisableButton={props.onExpandRowShouldDisableButton}
            onGetRowStyle={props.onGetRowStyle}
            onSelect={props.onSelectRow}
          />
        ))}
        {renderFilterPendingApply(grid.gridState)}
      </div>
    </>
  );
};

export default GridBody;

const renderUniqueRow = (content: ReactElement) => (
  <Row className="grid-body">
    <div className="grid-row">{content}</div>
  </Row>
);

const renderSpinner = (
  <div className="grid-body-spinner">
    <Spinner size={AppSize.medium} />
  </div>
);

const renderFilterPendingApply = (gridState: IGridState<any>) => {
  return gridState.filtersPendingApply ? (
    <div className="grid-row-filters-pending-apply"></div>
  ) : (
    <></>
  );
};

const renderError = (gridState: IGridState<any>) => {
  return renderUniqueRow(
    <div className="grid-cell grid-cell-no-data">
      {gridState.isLoading && renderSpinner}
      <Icon type={TypeIcon.noData} style={{ display: "block" }} />
      {gridState?.error?.toString()}
    </div>
  );
};

const renderNoData = (gridState: IGridState<any>) =>
  renderUniqueRow(
    <div className="grid-cell grid-cell-no-data">
      {gridState.isLoading && renderSpinner}
      <Icon type={TypeIcon.noData} style={{ display: "block" }} color={AppThemeColor.gray} />
      Sin datos para mostrar
    </div>
  );
