import { forwardRef } from "react";
import { State } from "../../../../api/shared/enums/State";
import InputSelectState, { PropsInputSelectState } from "../..//inputs/select/InputSelectState";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

interface PropsFormSelectState
  extends PropsFormInput<State | null>,
    Omit<PropsInputSelectState, "value" | "onChange"> {}

const FormSelectState = forwardRef((props: PropsFormSelectState, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputSelectState {...props} {...inputProps} />;
});

export default FormSelectState;
