import { nameof } from "ts-simple-nameof";
import { SaleLineDto } from "../../api/app/dtos/SaleLineDto";
import FormNumber from "../../shared/components/forms/FormNumber";
import useUtils from "../../shared/hooks/useUtils";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import { ISaleLineColumnRenderProps } from "./SaleFormLines";

const SaleLineColumnSurchargePercentage = (props: ISaleLineColumnRenderProps) => {
  const { utils } = useUtils();
  const saleModelState = useContextModelStateSale();

  if (!props.isEditing) {
    return props.saleLine.surchargePercentage ? (
      <>{utils.number.toString(props.saleLine.surchargePercentage)}%</>
    ) : (
      <>-</>
    );
  }

  return (
    <FormNumber
      arrayItemHelper={props.arrayItemHelper}
      label={props.showLabel ? props.saleLineColumn.header : ""}
      propertyName={nameof<SaleLineDto>((p) => p.surchargePercentage)}
      showZeroAsEmpty={true}
      variant={props.variant}
      onValueChange={() =>
        saleModelState.onLineSurchargePercentageChange(props.arrayItemHelper.index)
      }
    />
  );
};

export default SaleLineColumnSurchargePercentage;
