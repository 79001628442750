import { cloneDeep } from "lodash";
import { createContext, PropsWithChildren, useReducer } from "react";
import { GridSettingsDto } from "../../../api/app/dtos/GridSettingsDto";
import { FiltersDto } from "../../../api/shared/dtos/FiltersDto";
import { PagedResultDto } from "../../../api/shared/dtos/PagedResultDto";
import { SearchResultItemDto } from "../../../api/shared/dtos/SearchResultItemDto";
import { PagedSearchDto } from "../../../api/shared/dtos/PagedSearchDto";
import { SearchOrderDto } from "../../../api/shared/dtos/SearchOrderDto";
import { IGridSelection } from "./Grid";
import { IGridItemTemplate } from "./ItemTemplate/IGridItemTemplate";
import { GridMode } from "./PropsGrid";

export interface IGridState<T> {
  actionListShowFor: T;
  columnTemplates: IGridItemTemplate<T>[];
  error?: string;
  filtersAllExpanded: boolean;
  filtersDelay: boolean;
  filtersPendingApply: boolean;
  filtersDefault: FiltersDto;
  filtersEditing: FiltersDto;
  init: boolean;
  isCrossShard?: boolean;
  mode: GridMode;
  isLoading: boolean;
  pagedResult?: PagedResultDto<T>;
  pagingContinuationTokens?: string[];
  searchDto: PagedSearchDto<any>;
  selection: IGridSelection<T>;
  selectionNotificationPending: boolean;
  validationTokenId: number;
  visibleColumns: IGridItemTemplate<T>[];
  settings: GridSettingsDto | null;
  onSearch(searchDto: PagedSearchDto<any>): Promise<PagedResultDto<T>>;
}

export function getGridInitialState(): IGridState<any> {
  return {
    selection: {
      selectedCount: 0,
      allSelected: false,
      selectedList: [],
    } as IGridSelection<any>,
    filtersDelay: false,
  } as IGridState<any>;
}

const initialSearchDto = (
  pageSize: number,
  filters: FiltersDto,
  orderBy: SearchOrderDto[],
  includedFields: string[]
) => {
  const searchDto = new PagedSearchDto<any>();
  searchDto.page = 1;
  searchDto.pageSize = pageSize;
  searchDto.includeInactive = false;
  searchDto.orderBy = orderBy;
  searchDto.filters = filters ?? new FiltersDto();
  searchDto.includedFields = includedFields;
  return searchDto;
};

export enum GridActionType {
  actionListHide,
  actionListShow,
  changePage,
  clientSideRefresh,
  filtersAllCollapse,
  filtersAllExpand,
  includeInactiveUpdate,
  init,
  filtersPendingApplySet,
  orderByAdd,
  orderByChange,
  orderByRemove,
  orderByToggleAsc,
  refresh,
  selectItem,
  selectAll,
  setLoadingError,
  updateCrossShardId,
  updatePage,
  updateValidationTokenId,
  updateFilters,
  unselectAll,
  unselectItem,
  selectionNotificationDone,
  settingsUpdate,
}

export type GridAction =
  | { type: GridActionType.actionListHide }
  | { type: GridActionType.actionListShow; item: SearchResultItemDto<any> }
  | { type: GridActionType.orderByAdd; columnName: string; ascendent?: boolean }
  | { type: GridActionType.orderByChange; columnName: string; ascendent?: boolean }
  | {
      type: GridActionType.orderByToggleAsc;
      columnName: string;
      ascendent?: boolean;
    }
  | { type: GridActionType.orderByRemove; columnName: string }
  | { type: GridActionType.changePage; page: number }
  | { type: GridActionType.clientSideRefresh; clientSidePagedResult: PagedResultDto<any> }
  | { type: GridActionType.settingsUpdate }
  | { type: GridActionType.filtersAllCollapse }
  | { type: GridActionType.filtersAllExpand }
  | {
      type: GridActionType.init;
      mode: GridMode;
      settings: GridSettingsDto;
      orderBy: SearchOrderDto[];
      filters: FiltersDto;
      columnTemplates: IGridItemTemplate<any>[];
      mustIncludeFields?: string[];
      selectedItems: any[];
      isCrossShard?: boolean;
    }
  | { type: GridActionType.refresh }
  | { type: GridActionType.includeInactiveUpdate; value: boolean }
  | { type: GridActionType.filtersPendingApplySet; value: boolean }
  | { type: GridActionType.selectItem; resultItem: SearchResultItemDto<any> }
  | { type: GridActionType.selectAll }
  | { type: GridActionType.setLoadingError; errorMessage: string }
  | { type: GridActionType.selectionNotificationDone }
  | { type: GridActionType.updateCrossShardId; crossShardId: string }
  | { type: GridActionType.updatePage; pagedResult: PagedResultDto<any>; validationTokenId: number }
  | { type: GridActionType.updateValidationTokenId; validationTokenId: number }
  | { type: GridActionType.updateFilters; filters: FiltersDto }
  | { type: GridActionType.unselectAll }
  | { type: GridActionType.unselectItem; resultItem: SearchResultItemDto<any> };

export function gridReducer(state: IGridState<any>, action: GridAction): IGridState<any> {
  switch (action.type) {
    case GridActionType.actionListHide:
      return { ...state, actionListShowFor: null };
    case GridActionType.actionListShow:
      return { ...state, actionListShowFor: action.item };
    case GridActionType.init: {
      const visibleColumnNames = action.settings.visibleColumns?.split(",") ?? [];
      const visibleColumns = action.columnTemplates.filter(
        (c) =>
          c.actionType ||
          visibleColumnNames.length === 0 ||
          visibleColumnNames.find((d) => d === c.field) != null
      );

      // Adding missing used related fields
      const includedFields = visibleColumnNames;
      if (action.mustIncludeFields) {
        action.mustIncludeFields.forEach((mustIncludeField) => {
          if (
            !visibleColumnNames.find((visibleColumnName) => visibleColumnName === mustIncludeField)
          ) {
            includedFields.push(mustIncludeField);
          }
        });
      }

      visibleColumns.forEach((c) => {
        c.relatedFields?.forEach((f) => {
          if (includedFields.find((includedField) => includedField === f) == null) {
            includedFields.push(f);
          }
        });
      });

      const selection = {} as IGridSelection<any>;
      selection.selectedList = action.selectedItems;

      0.0;
      return {
        ...state,
        columnTemplates: action.columnTemplates,
        init: true,
        isLoading: true,
        isCrossShard: action.isCrossShard,
        mode: action.mode,
        settings: action.settings,
        filtersDefault: cloneDeep(action.filters),
        filtersEditing: cloneDeep(action.filters),
        searchDto: initialSearchDto(
          action.settings.pageSize,
          action.filters,
          action.orderBy,
          includedFields
        ),
        validationTokenId: 0,
        visibleColumns: visibleColumns,
        selection: selection,
      };
    }
    case GridActionType.settingsUpdate:
      return {
        ...state,
        settings: null,
      };
    case GridActionType.clientSideRefresh:
      return {
        ...state,
        pagedResult: action.clientSidePagedResult,
      };
    case GridActionType.filtersAllCollapse:
      return {
        ...state,
        filtersAllExpanded: false,
      };
    case GridActionType.filtersAllExpand:
      return {
        ...state,
        filtersAllExpanded: true,
      };
    case GridActionType.includeInactiveUpdate:
      return {
        ...state,
        searchDto: { ...state.searchDto, includeInactive: action.value },
      };
    case GridActionType.updatePage: {
      if (action.validationTokenId != state.validationTokenId) {
        return { ...state };
      }

      if (state.selection.allSelected) {
        action.pagedResult.items.forEach((c) => (c.selected = true));
      } else if (state.selection.selectedList?.length > 0) {
        action.pagedResult.items.forEach((c) => {
          c.selected = state.selection.selectedList.find((c2) => c.item == c2) != null;
        });
      }

      const pagingContinuationTokens = action.pagedResult.usingContinuationToken
        ? state.pagingContinuationTokens ?? []
        : undefined;

      if (pagingContinuationTokens && action.pagedResult.pageContinuationToken) {
        const indexOf = pagingContinuationTokens.indexOf(action.pagedResult.pageContinuationToken);
        if (indexOf == -1) {
          pagingContinuationTokens.push(action.pagedResult.pageContinuationToken);
        } else {
          pagingContinuationTokens.splice(indexOf + 1, pagingContinuationTokens.length - indexOf);
        }
      }

      return {
        ...state,
        error: undefined,
        isLoading: false,
        pagedResult: action.pagedResult,
        pagingContinuationTokens: pagingContinuationTokens,
        selection: {
          ...state.selection,
          allSelected:
            state.selection.selectedList &&
            state.selection.selectedList.length == state.pagedResult?.totalItemCount,
        },
      };
    }
    case GridActionType.updateCrossShardId:
      return {
        ...state,
        isLoading: true,
        filtersDelay: true,
        searchDto: { ...state.searchDto, crossShardId: action.crossShardId },
      };
    case GridActionType.updateFilters:
      return {
        ...state,
        pagingContinuationTokens: undefined,
        isLoading: true,
        filtersDelay: true,
        filtersEditing: cloneDeep(action.filters),
        searchDto: {
          ...state.searchDto,
          filters: action.filters,
          page: 1,
          pageContinuationToken: "",
        },
      };
    case GridActionType.updateValidationTokenId:
      return {
        ...state,
        validationTokenId: action.validationTokenId,
      };
    case GridActionType.orderByAdd: {
      const searchOrderDto = new SearchOrderDto();
      searchOrderDto.columnName = action.columnName;
      searchOrderDto.asc = action.ascendent ?? false;
      const orderBy = cloneDeep(state.searchDto.orderBy);
      orderBy.push(searchOrderDto);
      return {
        ...state,
        isLoading: true,
        searchDto: { ...state.searchDto, orderBy: [...orderBy] },
      };
    }
    case GridActionType.orderByChange: {
      const searchOrderDto = new SearchOrderDto();
      searchOrderDto.columnName = action.columnName;
      searchOrderDto.asc = action.ascendent ?? false;
      return {
        ...state,
        isLoading: true,
        searchDto: { ...state.searchDto, orderBy: [searchOrderDto] },
      };
    }
    case GridActionType.orderByRemove: {
      const orderBy = cloneDeep(state.searchDto.orderBy);
      const index = orderBy.findIndex((c) => c.columnName == action.columnName);
      orderBy.splice(index, 1);
      return {
        ...state,
        isLoading: true,
        searchDto: { ...state.searchDto, orderBy: orderBy },
      };
    }
    case GridActionType.orderByToggleAsc: {
      const orderBy = cloneDeep(state.searchDto.orderBy);
      const index = orderBy.findIndex((c) => c.columnName == action.columnName);
      orderBy[index].asc = action.ascendent ?? !orderBy[index].asc;
      return {
        ...state,
        isLoading: true,
        searchDto: { ...state.searchDto, orderBy: [...orderBy] },
      };
    }
    case GridActionType.changePage: {
      let pageContinuationToken = "";
      if (action.page > 1 && state.pagingContinuationTokens) {
        pageContinuationToken = state.pagingContinuationTokens[action.page - 2];
      }

      return {
        ...state,
        isLoading: true,
        searchDto: {
          ...state.searchDto,
          page: action.page,
          pageContinuationToken: pageContinuationToken,
        },
      };
    }
    case GridActionType.filtersPendingApplySet:
      return {
        ...state,
        filtersPendingApply: action.value,
      };
    case GridActionType.refresh:
      return {
        ...state,
        isLoading: true,
        searchDto: { ...state.searchDto },
      };
    case GridActionType.selectItem: {
      return setItemSelected(state, action.resultItem, true);
    }
    case GridActionType.unselectItem:
      return setItemSelected(state, action.resultItem, false);
    case GridActionType.selectAll: {
      return setItemSelectedAll(state, true);
    }
    case GridActionType.unselectAll: {
      return setItemSelectedAll(state, false);
    }
    case GridActionType.selectionNotificationDone:
      return {
        ...state,
        selectionNotificationPending: false,
      };
    case GridActionType.setLoadingError:
      return {
        ...state,
        error: action.errorMessage,
        isLoading: false,
      };
  }
}

const GridContext = createContext<{
  gridState: IGridState<any>;
  dispatchGrid: React.Dispatch<GridAction>;
}>({
  gridState: getGridInitialState(),
  dispatchGrid: () => null,
});

const GridProvider: React.FC<PropsWithChildren<{ children: any }>> = ({ children }) => {
  const [gridState, dispatchGrid] = useReducer(gridReducer, getGridInitialState());

  return (
    <GridContext.Provider value={{ gridState, dispatchGrid }}>{children}</GridContext.Provider>
  );
};

export { GridContext, GridProvider };

function setItemSelected(
  state: IGridState<any>,
  pagedResultTem: SearchResultItemDto<any>,
  value: boolean
): IGridState<any> {
  const indexOf = state.pagedResult?.items.indexOf(pagedResultTem) ?? -1;
  const clonedState = cloneDeep(state);
  const selection = {} as IGridSelection<any>;
  selection.selectedList = [];
  if (clonedState.pagedResult && indexOf >= 0) {
    clonedState.pagedResult.items[indexOf].selected = value;
    selection.selectedList = clonedState.pagedResult.items
      .filter((c) => c.selected)
      .map((c) => c.item);
  }
  selection.selectedCount = selection.selectedList.length;
  selection.allSelected = selection.selectedCount === clonedState.pagedResult?.totalItemCount;
  return {
    ...state,
    pagedResult: clonedState.pagedResult,
    selection: selection,
    selectionNotificationPending: true,
  };
}

function setItemSelectedAll(state: IGridState<any>, value: boolean): IGridState<any> {
  const clonedState = cloneDeep(state);
  const selection = {} as IGridSelection<any>;
  selection.selectedList = [];
  selection.allSelected = value;
  if (clonedState.pagedResult) {
    clonedState.pagedResult.items.forEach((c) => (c.selected = value));
    selection.selectedList = clonedState.pagedResult.items
      .filter((c) => c.selected)
      .map((c) => c.item);
  }
  selection.selectedCount = value ? clonedState.pagedResult?.totalItemCount ?? 0 : 0;
  selection.allSelected = value;
  return {
    ...state,
    pagedResult: clonedState.pagedResult,
    selection: selection,
    selectionNotificationPending: true,
  };
}
