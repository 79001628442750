import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AccountBranchDto } from "../../api/app/dtos/AccountBranchDto";
import { FiltersDto } from "../../api/shared/dtos/FiltersDto";
import { useApiAdminProviderAccessAccountBranch } from "../../api/shared/hooks/useApiAdminProviderAccess";
import { useApiCrossShardAccountBranch } from "../../api/shared/hooks/useApiCrossShard";
import { AppRoute } from "../../AppRoutes";
import ButtonCreate from "../../shared/components/Button/ButtonCreate";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import Grid, { GridColumnAlign } from "../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import { TypeIcon } from "../../shared/components/Icon";
import RowButtonsToolbar from "../../shared/components/Layout/GRowButtonsToolbar";
import Page from "../../shared/components/Layout/Page";
import { AppInfoSiteType } from "../../shared/context/AppInfoProvider";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { useModal } from "../../shared/hooks/useModal";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { useNotifier } from "../../shared/hooks/useNotifier";
import AdminAccountBranchCreatePopup from "./AdminAccountBranchCreatePopup";
import getAdminAccountBranchListItemMenu from "./AdminAccountBranchList.ItemMenu";

const AdminAccountBranchList = () => {
  const [reloadGrid, setReloadGrid] = useState<Date>();
  const [modal] = useModal();
  const navigation = useNavigation();
  const notifier = useNotifier();
  const { appInfo } = useContextAppInfo();
  const [initialFilters] = useState(() => {
    const filters = new FiltersDto();
    filters.crossShardIncludeAllProviders = appInfo?.siteType == AppInfoSiteType.SuperAdmin;
    return filters;
  });
  const [apiCrossShardAccountBranch] = useApiCrossShardAccountBranch();
  const [apiAdminProviderAccessAccountBranch] = useApiAdminProviderAccessAccountBranch();

  const itemTemplates: IGridItemTemplate<AccountBranchDto>[] = [
    {
      header: "Cuenta",
      field: nameof<AccountBranchDto>((p) => p.account.name),
      width: 30,
      filter: {
        propertyName: nameof<FiltersDto>((p) => p.crossShardAdminAccount),
        type: GridFilterType.account,
      },
    },
    {
      header: "Código",
      field: nameof<AccountBranchDto>((p) => p.branchCode),
      width: 20,
    },
    {
      header: "Nombre",
      field: nameof<AccountBranchDto>((p) => p.branchName),
      width: 20,
    },
    {
      header: "Por defecto",
      field: nameof<AccountBranchDto>((p) => p.isDefault),
      width: 10,
      align: GridColumnAlign.center,
      cellType: GridCellType.iconCheck,
    },
  ];

  return (
    <Page
      title="Sucursales"
      titleButton={
        <ButtonCreate
          text="Nueva sucursal"
          onClick={() =>
            modal.open(<AdminAccountBranchCreatePopup />, () => setReloadGrid(new Date()))
          }
        />
      }>
      <RowButtonsToolbar>
        <ButtonSecondary
          icon={TypeIcon.details}
          text="Ver puntos de venta"
          onClick={() =>
            navigation.go(
              appInfo?.siteType == AppInfoSiteType.SuperAdmin
                ? AppRoute.admin.accountBranches.pointOfSales
                : AppRoute.adminProvider.accountBranches.pointOfSales
            )
          }
        />
      </RowButtonsToolbar>
      <Grid
        itemTemplates={itemTemplates}
        initialFilters={initialFilters}
        isCrossShard={true}
        reloadGrid={reloadGrid}
        defaultSortBy={[{ columnName: nameof<AccountBranchDto>((p) => p.branchCode), asc: true }]}
        onDisplayItemMenu={(item) =>
          getAdminAccountBranchListItemMenu(
            item,
            apiAdminProviderAccessAccountBranch,
            modal,
            notifier,
            () => {
              setReloadGrid(new Date());
            }
          )
        }
        onSearch={(search, options) => apiCrossShardAccountBranch.getPaged(search, options)}
      />
    </Page>
  );
};

export default AdminAccountBranchList;
