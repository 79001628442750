import { nameof } from "ts-simple-nameof";
import { UserLinkedAccountDto } from "../../../api/admin/dtos/UserLinkedAccountDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

export function useAdminUserLinkedAccountValidation() {
  const schema = new ValidationSchema();

  schema[nameof<UserLinkedAccountDto>((p) => p.accountId)] = validate("Cuenta").required();
  schema[nameof<UserLinkedAccountDto>((p) => p.user)] = validate("Usuario").required();

  return schema;
}
