import { Collapse } from "@mui/material";
import { CSSProperties } from "react";
import { useAppTheme } from "../../AppThemeProvider";
import { AppSize } from "../../shared/AppSize";
import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import InfoText, { InfoTextType } from "../../shared/components/InfoText";
import Spinner from "../../shared/components/Spinner";
import { TextAlign } from "../../shared/TextAlign";
import { AppThemeColor } from "../../styles/color";

const ReportCard = (props: {
  title: string;
  children?: any;
  errorMessage?: string;
  isLoading?: boolean;
  filtersComponent?: any;
  hasInvalidFilters?: boolean;
  hasPendingFilters?: boolean;
  onRefresh?: () => void;
}) => {
  const appTheme = useAppTheme();
  const style: CSSProperties = {
    border: "1px solid " + appTheme.toHexColor(AppThemeColor.primary),
    borderRadius: 5,
  };

  const styleTitle: CSSProperties = {
    position: "relative",
    backgroundColor: appTheme.toHexColor(AppThemeColor.primary),
    color: appTheme.toHexColor(AppThemeColor.white),
    fontSize: "1.5em",
    padding: 10,
  };

  const styleContainer: CSSProperties = {
    padding: 20,
  };

  return (
    <div style={style}>
      <div style={styleTitle}>
        {props.title}
        {props.onRefresh && !props.isLoading && (
          <ButtonIcon
            tooltip="Actualizar"
            disabled={props.isLoading}
            icon={TypeIcon.refresh}
            color={AppThemeColor.white}
            noPadding={true}
            style={{ position: "absolute", right: 10 }}
            onClick={props.onRefresh}
          />
        )}
      </div>
      <div style={styleContainer}>
        {props.filtersComponent && (
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            {props.filtersComponent}
          </div>
        )}

        {props.hasPendingFilters && props.onRefresh && (
          <div style={{ flex: 1, textAlign: TextAlign.center }}>
            <ButtonSecondary
              icon={TypeIcon.refresh}
              text="Generar reporte"
              disabled={props.hasInvalidFilters}
              onClick={props.onRefresh}
            />
          </div>
        )}

        {props.isLoading && (
          <div style={{ flex: 1, textAlign: TextAlign.center }}>
            <Spinner size={AppSize.bigger} />
          </div>
        )}

        <Collapse in={!props.hasPendingFilters && !props.isLoading}>{props.children}</Collapse>

        {props.errorMessage && (
          <InfoText type={InfoTextType.error}>
            Error al generar el reporte: {props.errorMessage}
          </InfoText>
        )}
      </div>
    </div>
  );
};

export default ReportCard;
