import { nameof } from "ts-simple-nameof";
import { AdminPointOfSaleClientDto } from "../../api/admin/dtos/AdminPointOfSaleClientDto";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormAutocompleteAdminAccount from "../../shared/components/forms/Autocomplete/FormAutocompleteAdminAccount";
import InfoText from "../../shared/components/InfoText";
import Column from "../../shared/components/Layout/GColumn";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import RowButtons from "../../shared/components/Layout/GRowButtons";

const AdminPointOfSaleClientForm = (props: { onSave(): void }) => {
  return (
    <>
      <Column md={3} label="Cuenta">
        <FormAutocompleteAdminAccount
          propertyName={nameof<AdminPointOfSaleClientDto>((p) => p.account)}
        />
      </Column>
      <ColumnDisplay md={3} label="Token">
        <InfoText>Se generará automáticamente</InfoText>
      </ColumnDisplay>
      <RowButtons>
        <ButtonAccept onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default AdminPointOfSaleClientForm;
