import { FormControl, FormHelperText, FormLabel, InputLabel } from "@mui/material";
import { CSSProperties, useContext } from "react";
import { Constants } from "../../../Constants";
import { AppInfoContext } from "../../context/AppInfoProvider";
import { ThemeSize } from "../../ThemeSize";
import { ThemeVariant } from "../../ThemeVariant";
import {
  LAYOUT_HINT_MARGIN_BOTTOM_FIX,
  LAYOUT_HINT_PADDING_BOTTOM,
} from "../Layout/LayoutConstants";
import PropsInputBase from "./PropsInputBase";

export interface PropsField {
  autoWidth?: boolean;
  children: any;
  childrenProps: PropsInputBase<any>;
  labelHide?: boolean;
  labelIsInput?: boolean;
  labelInputShrink?: boolean;
  variant?: ThemeVariant;
}

export function Field(props: PropsField) {
  const { appInfo } = useContext(AppInfoContext);
  const style: CSSProperties = {
    marginBottom: !props.childrenProps.hintAutoHeight
      ? -LAYOUT_HINT_MARGIN_BOTTOM_FIX
      : LAYOUT_HINT_PADDING_BOTTOM,
  };

  let helperText = Constants.EMPTY_HINT;
  if (props.childrenProps.hint) {
    helperText = props.childrenProps.hint;
  } else if (props.childrenProps.hintAutoHeight) {
    helperText = "";
  }

  return (
    <FormControl
      component="fieldset"
      error={!!props.childrenProps.validation}
      fullWidth={!props.childrenProps.autoWidth}
      size={ThemeSize.small}
      style={style}
      variant={props.variant ?? appInfo?.muiVariant}>
      {!props.labelHide && (
        <>
          {props.labelIsInput && (
            <InputLabel
              htmlFor={props.childrenProps.id}
              shrink={props.labelInputShrink}
              style={{ marginBottom: 5 }}>
              {props.childrenProps.label}
            </InputLabel>
          )}
          {!props.labelIsInput && props.childrenProps.label && (
            <FormLabel htmlFor={props.childrenProps.id} style={{ marginBottom: 5 }}>
              {props.childrenProps.label}
            </FormLabel>
          )}
        </>
      )}
      {props.children}
      {!!props.childrenProps.validation && (
        <FormHelperText>{props.childrenProps.validation}</FormHelperText>
      )}
      {!props.childrenProps.validation && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default Field;
