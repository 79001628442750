// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AuthInfoDto } from "../dtos/AuthInfoDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { AuthPasswordResetDto } from "../dtos/AuthPasswordResetDto";
import { AuthLoginResponseDto } from "../dtos/AuthLoginResponseDto";
import { LoginRequestDto } from "../dtos/LoginRequestDto";
import { GoogleAuthTokenDto } from "../dtos/GoogleAuthTokenDto";
import { AuthSwitchPointOfSaleDto } from "../dtos/AuthSwitchPointOfSaleDto";
import { AuthForgotPasswordDto } from "../dtos/AuthForgotPasswordDto";
import { AuthWelcomeResponseDto } from "../dtos/AuthWelcomeResponseDto";
import { AuthWelcomeDto } from "../dtos/AuthWelcomeDto";

export class ApiAuth {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getAuthInfo(
    apiOptions?: ApiOptions
  ): Promise<AuthInfoDto> {
    return this.apiService.apiGet("/auth/auth-info", apiOptions);
  }

  getUserForResetPassword(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AuthPasswordResetDto> {
    return this.apiService.apiGet("/auth/user-for-reset-password/" + id, apiOptions);
  }

  login(
    loginRequestDto: LoginRequestDto,
    apiOptions?: ApiOptions
  ): Promise<AuthLoginResponseDto> {
    return this.apiService.apiPost("/auth/login", loginRequestDto, apiOptions);
  }

  loginGoogle(
    loginGoogleRequestDto: GoogleAuthTokenDto,
    apiOptions?: ApiOptions
  ): Promise<AuthLoginResponseDto> {
    return this.apiService.apiPost("/auth/login-google", loginGoogleRequestDto, apiOptions);
  }

  switchPointOfSale(
    authSwitchPointOfSaleDto: AuthSwitchPointOfSaleDto,
    apiOptions?: ApiOptions
  ): Promise<AuthLoginResponseDto> {
    return this.apiService.apiPost("/auth/switch-point-of-sale", authSwitchPointOfSaleDto, apiOptions);
  }

  resetPassword(
    authPasswordReset: AuthPasswordResetDto,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/auth/reset-password", authPasswordReset, apiOptions);
  }

  sendForgotPasswordEmail(
    forgotPasswordDto: AuthForgotPasswordDto,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/auth/send-forgot-password-email", forgotPasswordDto, apiOptions);
  }

  welcome(
    authWelcomeDto: AuthWelcomeDto,
    apiOptions?: ApiOptions
  ): Promise<AuthWelcomeResponseDto> {
    return this.apiService.apiPost("/auth/welcome", authWelcomeDto, apiOptions);
  }

}
