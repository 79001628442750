import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminTemplatePdfDto } from "../../../api/admin/dtos/AdminTemplatePdfDto";
import { enumName } from "../../../api/shared/enums/_enumName";
import { AppRoute } from "../../../AppRoutes";
import { ButtonCreate } from "../../../shared/components/Button/ButtonCreate";
import Grid from "../../../shared/components/grid/Grid";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../../shared/components/Layout/Page";
import {
  useApiAdminAccountPublicTemplateDesign,
  useApiAdminAccountPublicTemplatePdf,
} from "../../../api/shared/hooks/useApiAdmin";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import { getAdminTemplatePdfListItemMenu } from "./AdminTemplatePdfList.ItemMenu";
import { useContextAppInfo } from "../../../shared/hooks/useContextAppInfo";
import { AppInfoSiteType } from "../../../shared/context/AppInfoProvider";

const AdminTemplatePdfList = () => {
  const navigation = useNavigation();
  const [apiTemplatePdf] = useApiAdminAccountPublicTemplatePdf();
  const [reloadGrid] = useState(new Date());
  const { appInfo } = useContextAppInfo();
  const [apiAdminTemplateDesign] = useApiAdminAccountPublicTemplateDesign();
  const [apiAdminTemplatePdf] = useApiAdminAccountPublicTemplatePdf();

  const itemTemplates: IGridItemTemplate<AdminTemplatePdfDto>[] = [
    {
      header: "Tipo",
      field: nameof<AdminTemplatePdfDto>((p) => p.type),
      width: 20,
      cellTypeEnumName: enumName.TemplateType,
    },
    {
      header: "Cuenta",
      field: nameof<AdminTemplatePdfDto>((p) => p.adminAccount.name),
      width: 20,
    },
    {
      header: "País",
      field: nameof<AdminTemplatePdfDto>((p) => p.country),
      width: 20,
      cellTypeEnumName: enumName.Country,
    },
    {
      header: "Nombre",
      field: nameof<AdminTemplatePdfDto>((p) => p.name),
      width: 30,
    },
    {
      header: "Por defecto",
      field: nameof<AdminTemplatePdfDto>((p) => p.isDefault),
      width: 10,
      cellType: GridCellType.iconCheck,
    },
  ];

  if (!appInfo) {
    return <></>;
  }

  return (
    <Page
      title="Plantillas pdf"
      titleButton={
        <ButtonCreate
          text="Nueva plantilla"
          disabled={appInfo.siteType !== AppInfoSiteType.SuperAdmin}
          onClick={() => navigation.go(AppRoute.admin.templates.pdf.create)}
        />
      }>
      <Grid
        itemTemplates={itemTemplates}
        onSearch={(search, options) => apiTemplatePdf.getPaged(search, options)}
        reloadGrid={reloadGrid}
        onDisplayItemMenu={(adminTemplatePdfDto: AdminTemplatePdfDto) =>
          getAdminTemplatePdfListItemMenu(
            adminTemplatePdfDto,
            apiAdminTemplatePdf,
            apiAdminTemplateDesign,
            appInfo.siteType,
            navigation
          )
        }
      />
    </Page>
  );
};

export default AdminTemplatePdfList;
