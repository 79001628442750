export const ValidationMessages = {
  MessageFieldInvalidDefault: "Campo inválido",
  MessageFieldRequiredDefault: "Campo requerido",

  MessageFieldRequired: (propertyFriendlyName: string) => propertyFriendlyName + " es requerido",

  MessageFieldInvalid: (propertyFriendlyName: string) => propertyFriendlyName + " no es válido",

  DevelopErrorCollectionMustBeArray: (propertyFullName: string) =>
    "Schema field defined as a collection expects an array. Property full name: " +
    propertyFullName,

  DevelopErrorFriendlyNameRequired: "PropertyFriendlyName must be defined to show required message",

  DevelopErrorExpressionRequired: (propertyFullName: string) =>
    "Validation field must define at least one expression to validate. Property full name: " +
    propertyFullName,

  DevelopErrorRequiredNestedSchemaIsNull: (propertyFullName: string) =>
    "Validation field must define at least one expression to validate. Property full name: " +
    propertyFullName,
};
