import { useEffect, useState } from "react";
import { EInvoiceAuthorizationDto } from "../../../../api/app/dtos/EInvoiceAuthorizationDto";
import Container from "../../../../shared/components/Layout/GContainer";
import { ModalSize } from "../../../../shared/context/ModalProvider";
import { useApiEInvoiceAuthorization } from "../../../../api/shared/hooks/useApiApp";
import { useModal } from "../../../../shared/hooks/useModal";
import EInvoiceAuthorizationDetails from "./EInvoiceAuthorizationDetails";

const EInvoiceAuthorizationDetailsPopup = (props: { id: string }) => {
  const [apiUseApiEInvoiceAuthorization] = useApiEInvoiceAuthorization();
  const [eInvoiceAuthorization, setEInvoiceAuthorization] = useState<EInvoiceAuthorizationDto>();
  const [modal] = useModal();

  useEffect(() => {
    const load = async () => {
      setEInvoiceAuthorization(await apiUseApiEInvoiceAuthorization.getById(props.id));
      modal.setVisible("Detalles de CAE", ModalSize.small);
    };
    load();
  }, [modal, apiUseApiEInvoiceAuthorization, props.id]);

  if (!eInvoiceAuthorization) {
    return null;
  }

  return (
    <Container>
      <EInvoiceAuthorizationDetails eInvoiceAuthorization={eInvoiceAuthorization} />
    </Container>
  );
};

export default EInvoiceAuthorizationDetailsPopup;
