import { useState } from "react";
import { RequestedExportDto } from "../../api/app/dtos/RequestedExportDto";
import { useAppTheme } from "../../AppThemeProvider";
import { AppSize } from "../../shared/AppSize";
import Icon, { TypeIcon } from "../../shared/components/Icon";
import InputSwitch from "../../shared/components/inputs/InputSwitch";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import ScrollableArea from "../../shared/components/Layout/ScrollableArea";
import { useClickOutside } from "../../shared/hooks/useClickOutside";
import { TextAlign } from "../../shared/TextAlign";
import { AppThemeColor } from "../../styles/color";
import RequestedExportHeaderListItem from "./RequestedExportHeaderList.Item";

const RequestedExportHeaderList = (props: {
  items: RequestedExportDto[];
  includeDownloaded: boolean;
  onCancel: (item: RequestedExportDto) => void;
  onClose: () => void;
  onClick: (item: RequestedExportDto) => void;
  onIncludedDownloaded: (value: boolean) => void;
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const appTheme = useAppTheme();

  const close = () => {
    setIsClosing(true);
    setTimeout(() => {
      props.onClose();
      setIsClosing(false);
    }, 500);
  };

  const outerClickRef = useClickOutside(close) as any;

  return (
    <div
      ref={outerClickRef}
      className={"layout-app-notifications " + (!isClosing ? "expanded" : "")}
      style={{ padding: 20, backgroundColor: appTheme.toHexColor(AppThemeColor.primaryLight) }}
      onClick={(e) => e.stopPropagation()}>
      <Container>
        {props.items.length == 0 && (
          <>
            <Row minHeightIgnore={true}></Row>
            <Row align={TextAlign.center} fontColor={AppThemeColor.white} minHeightIgnore={true}>
              <Icon type={TypeIcon.checkBold} size={AppSize.bigger} />
            </Row>
            <Row align={TextAlign.center} fontColor={AppThemeColor.white} fontSize="2em">
              No hay nuevas descargas
            </Row>
          </>
        )}
        {props.items.length > 0 && (
          <>
            <Row minHeightIgnore={true}>
              <ScrollableArea maxHeight={420}>
                {props.items.map((item: RequestedExportDto) => (
                  <RequestedExportHeaderListItem
                    key={item.id}
                    item={item}
                    onCancel={() => props.onCancel(item)}
                    onClick={() => props.onClick(item)}
                  />
                ))}
              </ScrollableArea>
            </Row>
          </>
        )}
        <Row minHeightIgnore={true}>
          <InputSwitch
            fontColor={AppThemeColor.white}
            label="Mostrar ya descargados"
            value={props.includeDownloaded}
            onChange={props.onIncludedDownloaded}
          />
        </Row>
      </Container>
    </div>
  );
};

export default RequestedExportHeaderList;
