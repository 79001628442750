// File generated by: Gesta.Build.PostEvents
import { useState } from "react";
import { useApi } from "../../../shared/hooks/useApi";
import { ApiAdminProviderAccessPointOfSale } from "../../admin/apis/ApiAdminProviderAccessPointOfSale";
import { ApiAdminProviderAccessAccountBranch } from "../../admin/apis/ApiAdminProviderAccessAccountBranch";

export function useApiAdminProviderAccessPointOfSale() {
  return useState(new ApiAdminProviderAccessPointOfSale(useApi()));
}

export function useApiAdminProviderAccessAccountBranch() {
  return useState(new ApiAdminProviderAccessAccountBranch(useApi()));
}

