import { nameof } from "ts-simple-nameof";
import { AdminProviderDto } from "../../api/admin/dtos/AdminProviderDto";
import FormAutocompleteAdminAccount from "../../shared/components/forms/Autocomplete/FormAutocompleteAdminAccount";
import FormText from "../../shared/components/forms/FormText";
import Column from "../../shared/components/Layout/GColumn";
import Row from "../../shared/components/Layout/GRow";

const AdminProviderFormStyles = () => {
  return (
    <>
      <Row>
        <FormText label="Nombre" propertyName={nameof<AdminProviderDto>((p) => p.name)} />
      </Row>
      <Row>
        <FormAutocompleteAdminAccount
          label="Cuenta asociada"
          propertyName={nameof<AdminProviderDto>((p) => p.ownerAccount)}
        />
      </Row>
      <Column>
        <FormText
          label="Web url"
          propertyName={nameof<AdminProviderDto>((p) => p.providerSettings.webUrl)}
        />
      </Column>
      <Column>
        <FormText
          label="Correo - Ventas"
          propertyName={nameof<AdminProviderDto>((p) => p.providerSettings.emailSales)}
        />
      </Column>
      <Column>
        <FormText
          label="Correo - Ventas"
          propertyName={nameof<AdminProviderDto>((p) => p.providerSettings.emailSupport)}
        />
      </Column>
      <Column>
        <FormText
          label="Correo - Verificación configuración"
          hint="Se utiliza para verificar el correo emisor del correo de verificación"
          propertyName={nameof<AdminProviderDto>(
            (p) => p.providerSettings.emailVerificationHandShake
          )}
        />
      </Column>
      <Column>
        <FormText
          label="Correo - Ventas"
          propertyName={nameof<AdminProviderDto>(
            (p) => p.providerSettings.emailVerificationHandShake
          )}
        />
      </Column>
    </>
  );
};

export default AdminProviderFormStyles;
