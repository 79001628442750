// File generated by: Gesta.Build.PostEvents
export enum Currency {
	DirhamEmiratosArabes = 100,
	Afgani = 101,
	Lek = 102,
	DramArmenio = 103,
	FlorinAntillanoNeerlandes = 104,
	Kwanza = 105,
	PesoArgentino = 106,
	DolarAustraliano = 107,
	FlorinArubeno = 108,
	ManataZerbaiyano = 109,
	MarcoConvertible = 110,
	DolarBarbadense = 111,
	Taka = 112,
	LevBulgaro = 113,
	DinarBareini = 114,
	FrancoDeBurundi = 115,
	DolarBermudeno = 116,
	DolarDeBrunei = 117,
	Boliviano = 118,
	MVDOL = 119,
	RealBrasileno = 120,
	DolarBahameno = 121,
	Ngultrum = 122,
	Pula = 123,
	RubloBielorruso = 124,
	DolarBeliceno = 125,
	DolarCanadiense = 126,
	FrancoCongoleno = 127,
	EuroWIR = 128,
	FrancoSuizo = 129,
	FrancoWIR = 130,
	UnidadDeFomento = 131,
	PesoChileno = 132,
	YuanChino = 133,
	PesoColombiano = 134,
	UnidadDeValorReal = 135,
	ColonCostarricense = 136,
	PesoConvertible = 137,
	PesoCubano = 138,
	EscudoCaboverdiano = 139,
	CoronaCheca = 140,
	FrancoYibutiano = 141,
	CoronaDanesa = 142,
	PesoDominicano = 143,
	DinarArgelino = 144,
	LibraEgipcia = 145,
	Nakfa = 146,
	BirrEtiope = 147,
	Euro = 148,
	DolarFiyiano = 149,
	LibraMalvinense = 150,
	LibraEsterlina = 151,
	Lari = 152,
	CediGhanés = 153,
	LibraDeGibraltar = 154,
	Dalasi = 155,
	FrancoGuineano = 156,
	Quetzal = 157,
	DolarGuyanes = 158,
	DolarDeHongKong = 159,
	Lempira = 160,
	Kuna = 161,
	Gourde = 162,
	Forinto = 163,
	RupiaIndonesia = 164,
	NuevoSequelIsraeli = 165,
	RupiaIndia = 166,
	DinarIraqui = 167,
	RialIrani = 168,
	CoronaIslandesa = 169,
	DolarJamaiquino = 170,
	DinarJordano = 171,
	Yen = 172,
	ChelinKeniano = 173,
	Som = 174,
	Riel = 175,
	FrancoComorense = 176,
	WonNorcoreano = 177,
	Won = 178,
	DinarKuwaiti = 179,
	DolarDeLasIslasCaiman = 180,
	Tenge = 181,
	Kip = 182,
	LibraLibanesa = 183,
	RupiaDeSriLanka = 184,
	DolarLiberiano = 185,
	Loti = 186,
	DinarLibio = 187,
	DirhamMarroqui = 188,
	LeuMoldavo = 189,
	AriaryMalgache = 190,
	Denar = 191,
	Kyat = 192,
	Tugrik = 193,
	Pataca = 194,
	Uguiya = 195,
	RupiaDeMauricio = 196,
	Rufiyaa = 197,
	Kwacha = 198,
	PesoMexicano = 199,
	UnidadDeInversionMexicana = 200,
	RinggitMalayo = 201,
	MeticalMozambiqueno = 202,
	DolarNamibio = 203,
	Naira = 204,
	Cordoba = 205,
	CoronaNoruega = 206,
	RupiaNepali = 207,
	DolarNeozelandés = 208,
	RialOmani = 209,
	Balboa = 210,
	Sol = 211,
	Kina = 212,
	PesoFilipino = 213,
	RupiaPakistani = 214,
	Złoty = 215,
	Guarani = 216,
	RialCatari = 217,
	LeuRumano = 218,
	DinarSerbio = 219,
	RubloRuso = 220,
	FrancoRuandés = 221,
	RialSaudi = 222,
	DolarDeLasIslasSalomon = 223,
	RupiaSeychelense = 224,
	LibraSudanesa = 225,
	CoronaSueca = 226,
	DolarDeSingapur = 227,
	LibraDeSantaElena = 228,
	Leone = 229,
	ChelinSomali = 230,
	DolarSurinamés = 231,
	LibraSursudanesa = 232,
	Dobra = 233,
	LibraSiria = 235,
	Lilangeni = 236,
	Baht = 237,
	SomoniTayiko = 238,
	ManatTurcomano = 239,
	DinarTunecino = 240,
	Paanga = 241,
	LiraTurca = 242,
	DolarDeTrinidadYTobago = 243,
	NuevoDolarTaiwanes = 244,
	ChelinTanzano = 245,
	Grivna = 246,
	ChelinUgandés = 247,
	Dolar = 248,
	UnidadesIndexadasUruguay = 250,
	PesoUruguayo = 251,
	SomUzbeko = 253,
	BolivarSoberano = 254,
	DongVietnamita = 255,
	Vatu = 256,
	Tala = 257,
	FrancoCFAdeAfricaCentral = 258,
	PlataOnzaTroy = 259,
	OroOnzaTroy = 260,
	DolarDelCaribeOriental = 261,
	DerechosEspecialesDeGiro = 262,
	FrancoCFAdeAfricaOccidental = 263,
	PaladioOnzaTroy = 264,
	FrancoCFP = 265,
	PlatinoOnzaTroy = 266,
	SUCRE = 267,
	ReservadoParaPruebas = 268,
	UnidadDeCuentaBAD = 269,
	RialYemeni = 271,
	Rand = 272,
	KwachaZambiano = 273,
	DolarZimbabuense = 274,
	Other = 999,
}
