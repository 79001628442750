import { AppRoute } from "../../AppRoutes";
import { ButtonSecondary } from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Row from "../../shared/components/Layout/GRow";
import Page from "../../shared/components/Layout/Page";
import { useNavigation } from "../../shared/hooks/useNavigation";

const AdminEInvoice = () => {
  const navigation = useNavigation();
  return (
    <Page title="eFactura">
      <Row>
        <ComponentGroup wrap={true}>
          <ButtonSecondary
            icon={TypeIcon.eInvoice}
            text="Homologación"
            onClick={() => {
              navigation.go(AppRoute.admin.eInvoices.homologation);
            }}
          />
          <ButtonSecondary
            icon={TypeIcon.eInvoiceProcessXmlCreated}
            text="Reportes diarios"
            onClick={() => {
              navigation.go(AppRoute.admin.eInvoices.dailyReports);
            }}
          />
          <ButtonSecondary
            icon={TypeIcon.emptyProductList}
            text="Retenciones/Percepciones"
            onClick={() => {
              navigation.go(AppRoute.admin.eInvoices.retentions);
            }}
          />
          <ButtonSecondary
            icon={TypeIcon.emptyProductList}
            text="Reporte de proveedor habilitado"
            onClick={() => {
              navigation.go(AppRoute.admin.eInvoices.certifiedProviderReport);
            }}
          />
        </ComponentGroup>
      </Row>
    </Page>
  );
};

export default AdminEInvoice;
