import { forwardRef } from "react";
import { Country } from "../../../../api/shared/enums/Country";
import InputSelectCountry, {
  PropsInputSelectCountry,
} from "../..//inputs/select/InputSelectCountry";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormSelectCountry
  extends PropsFormInput<Country | null>,
    Omit<PropsInputSelectCountry, "value" | "onChange"> {}

const FormSelectCountry = forwardRef((props: PropsFormSelectCountry, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputSelectCountry {...props} {...inputProps} />;
});

export default FormSelectCountry;
