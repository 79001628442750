import { AdminProviderSimpleDto } from "./api/admin/dtos/AdminProviderSimpleDto";
import { appThemePaletteDefault } from "./AppThemePalette.Default";
import { appThemePaletteExeSoluciones } from "./AppThemePalette.exeSoluciones";

export interface AppThemePalette {
  alert: string;
  alertLight: string;
  warning: string;
  warningLight: string;
  inactive: string;
  info: string;
  infoLight: string;
  header: string;
  layoutBackground: string;
  primary: string;
  primaryLight: string;
  primaryContrast: string;
  secondary: string;
  secondaryLight: string;
  secondaryContrast: string;
  success: string;
  successLight: string;
  white: string;
  black: string;
  gray: string;
  grayLight: string;
}

export function getThemePaletteFromAdminProvider(
  adminProvider: AdminProviderSimpleDto
): AppThemePalette {
  if (adminProvider.name === "eXe") {
    return appThemePaletteExeSoluciones;
  }
  return appThemePaletteDefault;
}
