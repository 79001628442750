// File generated by Gesta.Build.PostEvents
import { InvoiceFiltersDto } from "./InvoiceFiltersDto";
import { AccountBranchDto } from "./AccountBranchDto";
import { PointOfSaleDto } from "./PointOfSaleDto";
import { AccountUserDto } from "./AccountUserDto";
import { Country } from "../../shared/enums/Country";
import { SaleDraftStatus } from "../../shared/enums/SaleDraftStatus";
import { EInvoiceProcessStatus } from "../../shared/enums/EInvoiceProcessStatus";

export class SaleFiltersDto extends InvoiceFiltersDto {
  accountBranchId: string;
  accountBranch: AccountBranchDto;
  pointOfSaleId: string;
  pointOfSale: PointOfSaleDto;
  accountUserId: string;
  accountUser: AccountUserDto;
  receiverName: string = "";
  receiverIdCardNumber: string = "";
  receiverCountry: Country;
  saleDraftStatus: SaleDraftStatus;
  eInvoiceProcessStatusList: EInvoiceProcessStatus[] = [];
  eInvoiceCompanySentStatusList: EInvoiceProcessStatus[] = [];
}
