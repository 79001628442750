// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { SuggestResponseDto } from "../../shared/dtos/SuggestResponseDto";
import { UserLinkedAccountFiltersDto } from "../dtos/UserLinkedAccountFiltersDto";
import { SuggestSearchDto } from "../../shared/dtos/SuggestSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { AdminAccountSimpleDto } from "../../shared/dtos/AdminAccountSimpleDto";

export class ApiAdminSwitchPointOfSale {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  suggestLinkedAccount(
    suggestSearch: SuggestSearchDto<UserLinkedAccountFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/admin/switch-point-of-sale/suggest-linked-account", suggestSearch, apiOptions);
  }

  getAdminAccount(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AdminAccountSimpleDto> {
    return this.apiService.apiGet("/admin/switch-point-of-sale/admin-account/" + id, apiOptions);
  }

}
