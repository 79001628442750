// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";

export class ContactSimpleDto extends BaseEntityDto {
  companyName: string = "";
  name: string = "";
  taxPayerId: string = "";
  displayBothNames: string = "";
  openApiExternalId: string = "";
}
