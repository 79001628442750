import { useEffect, useState } from "react";
import { AccountConfigPreferencesDto } from "../../../api/app/dtos/AccountConfigPreferencesDto";
import { AccountConfigPreferencesType } from "../../../api/shared/enums/AccountConfigPreferencesType";
import { enumName } from "../../../api/shared/enums/_enumName";
import { useApiAccountConfigPreferences } from "../../../api/shared/hooks/useApiApp";
import { EMPTY_GUID } from "../../../Constants";
import ButtonAccept from "../../../shared/components/Button/ButtonAccept";
import ButtonLink from "../../../shared/components/Button/ButtonLink";
import { TypeIcon } from "../../../shared/components/Icon";
import InputSelectEnum from "../../../shared/components/inputs/select/InputSelectEnum";
import Column from "../../../shared/components/Layout/GColumn";
import Container from "../../../shared/components/Layout/GContainer";
import Row from "../../../shared/components/Layout/GRow";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import Page from "../../../shared/components/Layout/Page";
import TabGroup from "../../../shared/components/Layout/TabGroup";
import { TabStep } from "../../../shared/components/Layout/TabStepper";
import TabView from "../../../shared/components/Layout/TabView";
import ModalDialog from "../../../shared/components/Modal/ModalDialog";
import ModalMessage from "../../../shared/components/Modal/ModalMessage";
import { loginBroadCastChannel } from "../../../shared/context/LoginInfoProvider";
import { useModal } from "../../../shared/hooks/useModal";
import { useNotifier } from "../../../shared/hooks/useNotifier";
import { StateProvider } from "../../../shared/modelState/ModelStateContext";
import { TextAlign } from "../../../shared/TextAlign";
import ConfigPreferencesProducts from "./ConfigPreferences.Products";
import ConfigPreferencesSales from "./ConfigPreferences.Sales";

const TABS = {
  SALES: "SALES",
  PRODUCTS: "PRODUCTS",
};

const tabs: TabStep[] = [
  {
    title: "Ventas",
    value: TABS.SALES,
  },
  {
    title: "Productos",
    value: TABS.PRODUCTS,
  },
];

const ConfigPreferences = () => {
  const [defaultPreferences, setDefaultPreferences] = useState<AccountConfigPreferencesDto>();
  const [preferences, setPreferences] = useState<AccountConfigPreferencesDto>();
  const [preferencesType, setPreferencesType] = useState<AccountConfigPreferencesType | null>(
    AccountConfigPreferencesType.Account
  );
  const [apiAccountConfigPreferences] = useApiAccountConfigPreferences();
  const notifier = useNotifier();
  const [modal] = useModal();

  const loadPreferences = () => {
    const load = async () => {
      if (!preferencesType) {
        return;
      }

      const defaultType =
        preferencesType != AccountConfigPreferencesType.Account ? preferencesType - 1 : null;

      setPreferences(await apiAccountConfigPreferences.getForType(preferencesType));
      setDefaultPreferences(await apiAccountConfigPreferences.getMerged(defaultType));
    };
    load();
  };

  const pageReload = () => {
    loginBroadCastChannel.postMessage("ReloadPage");
    window.location.reload();
  };

  const save = async (preferences: AccountConfigPreferencesDto) => {
    if (preferences.id === EMPTY_GUID) {
      await apiAccountConfigPreferences.create(preferences);
    } else {
      await apiAccountConfigPreferences.edit(preferences);
    }
    notifier.showSuccessFast("La configuración se actualizó con éxito");
    modal.open(<ModalMessage text="Este cambio requiere recargar la página" />, pageReload);
  };

  const deleteForType = async () => {
    if (!preferencesType) {
      return;
    }
    modal.open(<ModalDialog text="¿Está seguro de tomar la configuración por defecto?" />, () => {
      const request = async () => {
        await apiAccountConfigPreferences.deleteForType(preferencesType);
        pageReload();
      };
      request();
    });
  };

  useEffect(() => {
    loadPreferences();
  }, [preferencesType]);

  return (
    <Page title="Preferencias">
      <Row align={TextAlign.right}>
        <Container>
          <Column md={3}>
            <InputSelectEnum
              label="Tipo"
              enumTypeName={enumName.AccountConfigPreferencesType}
              removeEmpty={true}
              value={preferencesType}
              onChange={setPreferencesType}
            />
          </Column>
          {preferences && preferences.id !== EMPTY_GUID && (
            <Column md={9} align={TextAlign.right}>
              <ButtonLink
                icon={TypeIcon.delete}
                text="Usar configuración por defecto"
                onClick={deleteForType}
              />
            </Column>
          )}
        </Container>
      </Row>
      {preferences && defaultPreferences && (
        <StateProvider model={preferences} onSubmit={save}>
          {(p) => (
            <>
              <TabGroup tabs={tabs} selectedTab={TABS.SALES}>
                <TabView value={TABS.SALES}>
                  <ConfigPreferencesSales defaultPreferences={defaultPreferences} />
                </TabView>
                <TabView value={TABS.PRODUCTS}>
                  <ConfigPreferencesProducts />
                </TabView>
              </TabGroup>
              <RowButtons>
                <ButtonAccept onClick={p.handleSubmit} />
              </RowButtons>
            </>
          )}
        </StateProvider>
      )}
    </Page>
  );
};

export default ConfigPreferences;
