import { nameof } from "ts-simple-nameof";
import { AdminTaxValueDto } from "../../../api/admin/dtos/AdminTaxValueDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

export function useAdminTaxValueValidation() {
  const schema = new ValidationSchema();

  schema[nameof<AdminTaxValueDto>((p) => p.value)] = validate("Valor").required();
  schema[nameof<AdminTaxValueDto>((p) => p.tax)] = validate("Impuesto").required();

  return schema;
}
