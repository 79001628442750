import { useEffect, useState } from "react";
import { UserLinkedAccountDto } from "../../api/admin/dtos/UserLinkedAccountDto";
import { UserDto } from "../../api/admin/dtos/UserDto";
import Column from "../../shared/components/Layout/GColumn";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useModal } from "../../shared/hooks/useModal";
import UserLinkedAccountItem from "../UserLinkedAccount/UserLinkedAccountItem";
import useUtils from "../../shared/hooks/useUtils";
import { DateFormatType } from "../../shared/utils/localizedUtil/localizedUtilDates";
import { useApiAdminProviderUser } from "../../api/shared/hooks/useApiAdminProviders";

const UserDetailsPopup = (props: { userId: string }) => {
  const [modal] = useModal();
  const [user, setUser] = useState<UserDto>();
  const [apiUser] = useApiAdminProviderUser();
  const { utils } = useUtils();

  useEffect(() => {
    const load = async () => {
      setUser(await apiUser.getById(props.userId));
      modal.setVisible("Detalle de usuario", ModalSize.big);
    };
    load();
  }, [modal, apiUser, props.userId]);

  if (!user) {
    return null;
  }

  return (
    <Container>
      <ColumnDisplay label="Correo">{user.email}</ColumnDisplay>

      <ColumnDisplay label="Nombre">{user.firstName}</ColumnDisplay>

      <ColumnDisplay label="Apellido">{user.lastName}</ColumnDisplay>

      <ColumnDisplay label="Último login">
        {utils.date.toString(user.lastLogin, DateFormatType.DateHourMinutesSeconds)}
      </ColumnDisplay>

      <Row label="Cuentas asociadas">
        <Container>
          {user.accounts.map((userLinkedAccount: UserLinkedAccountDto, index) => (
            <Column key={index}>
              <UserLinkedAccountItem userLinkedAccount={userLinkedAccount} readOnly={true} />
            </Column>
          ))}
        </Container>
      </Row>
    </Container>
  );
};

export default UserDetailsPopup;
