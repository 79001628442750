import { Grammar, highlight, languages } from "prismjs";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-tomorrow.css";
import { forwardRef } from "react";
import Editor from "react-simple-code-editor";
import { LAYOUT_SCROLLABLE_AREA_LARGE_MIN_HEIGHT } from "./Layout/LayoutConstants";
import ScrollableArea from "./Layout/ScrollableArea";

export enum CodeEditorLang {
  html = 1,
  css = 2,
  json = 3,
}

const CodeEditor = forwardRef(
  (
    props: {
      lang: CodeEditorLang;
      value: string;
      height?: number;
      scrollPosX?: number;
      scrollPosY?: number;
      onChange: (value: string) => void;
      onScroll?: (positionX: number, positionY: number) => void;
    },
    ref: any
  ) => {
    let grammar: Grammar = languages.html;
    let lang = "html";

    if (props.lang === CodeEditorLang.css) {
      grammar = languages.css;
      lang = "css";
    } else if (props.lang === CodeEditorLang.json) {
      grammar = languages.json;
      lang = "json";
    }

    return (
      <ScrollableArea
        maxHeight={props.height ?? LAYOUT_SCROLLABLE_AREA_LARGE_MIN_HEIGHT}
        posX={props.scrollPosX}
        posY={props.scrollPosY}
        onScroll={props.onScroll}>
        <Editor
          ref={ref}
          value={props.value}
          highlight={(code) => {
            return highlight(code, grammar, lang);
          }}
          padding={1}
          style={{
            fontFamily: "Fira code, Fira Mono, monospace",
            fontSize: 12,
            border: "none",
            color: "white",
            background: "#1c1c1c",
            borderRadius: 6,
            minHeight: props.height ?? LAYOUT_SCROLLABLE_AREA_LARGE_MIN_HEIGHT,
          }}
          onValueChange={props.onChange}
        />
      </ScrollableArea>
    );
  }
);

export default CodeEditor;
