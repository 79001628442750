import { useEffect, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AccountBranchDto } from "../../api/app/dtos/AccountBranchDto";
import { EInvoiceAccountBranchInfoDto } from "../../api/app/dtos/EInvoiceAccountBranchInfoDto";
import Grid from "../../shared/components/grid/Grid";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import IconCheck from "../../shared/components/IconCheck";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminAccessAccountBranch } from "../../api/shared/hooks/useApiAdminAccess";
import { useModal } from "../../shared/hooks/useModal";

const AdminAccountBranchDetailsPopup = (props: { accountId: string; accountBranchId: string }) => {
  const [modal] = useModal();
  const [accountBranch, setAccountBranch] = useState<AccountBranchDto>();
  const [apiAdminAccessAccountBranch] = useApiAdminAccessAccountBranch();

  const eInvoiceInfoColumns: IGridItemTemplate<EInvoiceAccountBranchInfoDto>[] = [
    { field: nameof<EInvoiceAccountBranchInfoDto>((p) => p.name), header: "Nombre", width: 10 },
    { field: nameof<EInvoiceAccountBranchInfoDto>((p) => p.code), header: "Código", width: 10 },
    {
      field: nameof<EInvoiceAccountBranchInfoDto>((p) => p.startDate),
      header: "Desde",
      width: 10,
      cellType: GridCellType.dateTime,
    },
    {
      field: nameof<EInvoiceAccountBranchInfoDto>((p) => p.endDate),
      header: "Hasta",
      width: 10,
      cellType: GridCellType.dateTime,
    },
  ];

  useEffect(() => {
    const loadAdminAccount = async () => {
      setAccountBranch(
        await apiAdminAccessAccountBranch.getById(props.accountId, props.accountBranchId)
      );
      modal.setVisible("Detalles de la sucursal", ModalSize.small);
    };
    loadAdminAccount();
  }, []);

  if (!accountBranch) {
    return <></>;
  }

  return (
    <Container>
      <ColumnDisplay label="Código">{accountBranch.branchCode}</ColumnDisplay>
      <ColumnDisplay label="Nombre">{accountBranch.branchName}</ColumnDisplay>
      <ColumnDisplay label="Por defecto">
        <IconCheck value={accountBranch.isDefault} showHyphen={true} />
      </ColumnDisplay>
      <Grid
        clientSideItems={accountBranch.eInvoiceInfoHistory}
        itemTemplates={eInvoiceInfoColumns}></Grid>
    </Container>
  );
};

export default AdminAccountBranchDetailsPopup;
