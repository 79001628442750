import { AdminAccountAppAccessDto } from "../../api/app/dtos/AdminAccountAppAccessDto";
import { enumName } from "../../api/shared/enums/_enumName";
import { AppSize } from "../../shared/AppSize";
import AddressDisplay from "../../shared/components/Display/AddressDisplay";
import Img from "../../shared/components/Img";
import Column from "../../shared/components/Layout/GColumn";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Row from "../../shared/components/Layout/GRow";
import LogoAccount from "../../shared/components/LogoAccount";
import useUtils from "../../shared/hooks/useUtils";

const MyAdminAccountGeneral = (props: { adminAccount: AdminAccountAppAccessDto }) => {
  const { utils } = useUtils();
  const adminAccount = props.adminAccount;
  return (
    <>
      <ColumnDisplay md={3} label="Nombre">
        {adminAccount.name}
      </ColumnDisplay>
      <ColumnDisplay md={3} label="Logo">
        <LogoAccount adminAccount={adminAccount} />
      </ColumnDisplay>

      <Column label="Logo grande" md={6}>
        <Img size={AppSize.biggest2} src={adminAccount.logoUrl} />
      </Column>

      <ColumnDisplay md={3} label="RUT">
        {adminAccount.company.taxPayerId}
      </ColumnDisplay>

      <ColumnDisplay md={3} label="Razón social">
        {adminAccount.company.name}
      </ColumnDisplay>

      <ColumnDisplay md={3} label="Nombre comercial">
        {adminAccount.company.comercialName}
      </ColumnDisplay>

      <ColumnDisplay md={3} label="Tipo">
        {utils.enum.translate(enumName.CompanyType, adminAccount.company.type)}
      </ColumnDisplay>

      <ColumnDisplay md={3} label="Giro">
        {adminAccount.company.activity}
      </ColumnDisplay>

      <ColumnDisplay md={3} label="Teléfono">
        {adminAccount.company.phone}
      </ColumnDisplay>

      <Row minHeightIgnore={true}></Row>

      <AddressDisplay address={props.adminAccount.company.address} columnMd={3} />
    </>
  );
};

export default MyAdminAccountGeneral;
