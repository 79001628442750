import { AppSize } from "../../AppSize";
import PropsAdminProviderLogoIsoType from "../AdminProviderLogoIsoType";
import Container from "../Layout/GContainer";
import Row from "../Layout/GRow";

const ErrorPageLayout = (props: { children: any }) => {
  return (
    <Container
      style={{
        width: "100%",
        fontSize: "3em",
        marginTop: "6rem",
        color: "gray",
        textAlign: "center",
      }}>
      <Row style={{ marginBottom: 60 }}>
        <PropsAdminProviderLogoIsoType size={AppSize.bigger} />
      </Row>
      {props.children}
    </Container>
  );
};

export default ErrorPageLayout;
