import { LinearProgress } from "@mui/material";
import { RequestedExportDto } from "../../api/app/dtos/RequestedExportDto";
import { RequestedExportStatus } from "../../api/shared/enums/RequestedExportStatus";
import { useAppTheme } from "../../AppThemeProvider";
import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import { TypeIcon } from "../../shared/components/Icon";
import IconError from "../../shared/components/IconError";
import ModalDialog from "../../shared/components/Modal/ModalDialog";
import { useModal } from "../../shared/hooks/useModal";
import useUtils from "../../shared/hooks/useUtils";
import { AppThemeColor } from "../../styles/color";

const AppRequestedExportHeaderListItem = (props: {
  item: RequestedExportDto;
  onClick: () => void;
  onCancel: () => void;
}) => {
  const { utils } = useUtils();
  const [modal] = useModal();
  const appTheme = useAppTheme();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: AppThemeColor.white,
        width: "100%",
        borderRadius: 6,
        marginBottom: 15,
      }}
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 15,
          cursor: props.item.fileUrl ? "pointer" : "default",
        }}>
        <div style={{ flex: 1 }}>
          <div
            style={{
              fontSize: "1.25em",
              color: appTheme.toHexColor(
                !props.item.isDownloaded ? AppThemeColor.black : AppThemeColor.gray
              ),
              fontWeight: !props.item.isDownloaded ? "bold" : "normal",
            }}>
            {props.item.requestedFileName}
          </div>
          <div style={{ color: appTheme.toHexColor(AppThemeColor.gray), display: "flex", gap: 10 }}>
            {utils.date.toAgoString(props.item.requestedDate, "Generando archivo")}
            {props.item.status == RequestedExportStatus.Canceled && <> (cancelada)</>}
          </div>
          {props.item.status == RequestedExportStatus.Error && (
            <div
              style={{ display: "flex", gap: 10, color: appTheme.toHexColor(AppThemeColor.alert) }}>
              <div>
                <IconError />
              </div>
              <div>{props.item.errorMessage}</div>
            </div>
          )}
        </div>

        <div>
          {props.item.fileUrl && (
            <ButtonIcon
              icon={TypeIcon.download}
              color={props.item.isDownloaded ? AppThemeColor.gray : AppThemeColor.primary}
              noPadding={true}
              disabled={!!props.item.fileUrl}
              onClick={() => {
                // Handled by the parent component
              }}
            />
          )}
        </div>
        {props.item.status == RequestedExportStatus.Pending && (
          <ButtonIcon
            icon={TypeIcon.delete}
            noPadding={true}
            onClick={(e) => {
              e.stopPropagation();
              modal.open(
                <ModalDialog text="¿Está seguro que desea cancelar la exportación?" />,
                props.onCancel
              );
            }}
          />
        )}
      </div>
      {props.item.status == RequestedExportStatus.Pending && (
        <LinearProgress variant="indeterminate" />
      )}
    </div>
  );
};

export default AppRequestedExportHeaderListItem;
