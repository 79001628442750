import { forwardRef } from "react";
import InputSelectLocale, { PropsInputSelectLocale } from "../..//inputs/select/InputSelectLocale";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormSelectLocale
  extends PropsFormInput<Locale | null>,
    Omit<PropsInputSelectLocale, "value" | "onChange"> {}

const FormSelectLocale = forwardRef((props: PropsFormSelectLocale, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputSelectLocale {...props} {...inputProps} />;
});

export default FormSelectLocale;
