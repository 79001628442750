import { EInvoiceEnvelopeDto } from "../../../api/app/dtos/EInvoiceEnvelopeDto";
import { enumName } from "../../../api/shared/enums/_enumName";
import ColumnDisplay from "../../../shared/components/Layout/GColumnDisplay";
import useUtils from "../../../shared/hooks/useUtils";
import { DateFormatType } from "../../../shared/utils/localizedUtil/localizedUtilDates";

const EInvoiceEnvelopeDetails = (props: {
  eInvoiceEnvelope: EInvoiceEnvelopeDto;
  title: string;
}) => {
  const { utils } = useUtils();
  return (
    <>
      <ColumnDisplay label="Id interno">{props.eInvoiceEnvelope.internalSenderId}</ColumnDisplay>
      <ColumnDisplay label="Estado">
        {utils.enum.translate(enumName.EInvoiceEnvelopeStatus, props.eInvoiceEnvelope.status)}
      </ColumnDisplay>
      <ColumnDisplay label="Token">{props.eInvoiceEnvelope.verificationToken}</ColumnDisplay>
      <ColumnDisplay label="Fecha de verificación desde">
        {utils.date.toString(
          props.eInvoiceEnvelope.verificationDateSince,
          DateFormatType.DateHourMinutesSeconds
        )}
      </ColumnDisplay>
      <ColumnDisplay label="Fecha creación">
        {utils.date.toString(
          props.eInvoiceEnvelope.createdDate,
          DateFormatType.DateHourMinutesSeconds
        )}
      </ColumnDisplay>
      <ColumnDisplay label="Fecha de envío">
        {utils.date.toString(
          props.eInvoiceEnvelope.sentDate,
          DateFormatType.DateHourMinutesSeconds
        )}
      </ColumnDisplay>
      {props.eInvoiceEnvelope.errorMessage && (
        <ColumnDisplay label="Errores">{props.eInvoiceEnvelope.errorMessage}</ColumnDisplay>
      )}
    </>
  );
};

export default EInvoiceEnvelopeDetails;
