import { useEffect, useState } from "react";
import { AdminRetentionDto } from "../../api/admin/dtos/AdminRetentionDto";
import { Country } from "../../api/shared/enums/Country";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminRetention } from "../../api/shared/hooks/useApiAdmin";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AdminRetentionForm from "./AdminRetentionForm";
import { useAdminRetentionValidation } from "./Validations/useAdminRetentionValidation";

const AdminRetentionCreatePopup = () => {
  const [adminRetention] = useState(() => {
    const adminRetention = new AdminRetentionDto();
    adminRetention.country = Country.Uruguay;
    return adminRetention;
  });

  const [modal] = useModal();
  const [apiAdminRetention] = useApiAdminRetention();

  const save = async (adminRetention: AdminRetentionDto) => {
    const created = await apiAdminRetention.create(adminRetention);
    modal.closeAsSuccess(created, "El impuesto se creó con éxito");
  };

  useEffect(() => modal.setVisible("Crear impuesto", ModalSize.small), []);

  if (!adminRetention) {
    return <></>;
  }

  return (
    <Container>
      <StateProvider
        model={adminRetention}
        onSubmit={save}
        validationSchema={useAdminRetentionValidation()}>
        {(p) => <AdminRetentionForm onSave={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default AdminRetentionCreatePopup;
