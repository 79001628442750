// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { CompanyInfoDto } from "../dtos/CompanyInfoDto";

export class ApiEInvoiceCompanyInfo {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  isCountryAvailable(
    apiOptions?: ApiOptions
  ): Promise<boolean> {
    return this.apiService.apiGet("/integrations/e-invoice/companies/is-current-country-available", apiOptions);
  }

  getCompanyInfo(
    taxPayerId: string,
    apiOptions?: ApiOptions
  ): Promise<CompanyInfoDto> {
    return this.apiService.apiGet("/integrations/e-invoice/companies/company-info?" + 
      (taxPayerId ? "&taxPayerId=" + taxPayerId : ""), apiOptions);
  }

}
