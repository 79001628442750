import ValidationFieldsTrackBase, { ParamSetItemValues } from "./ValidationFieldsTrackBase";
import ValidationFieldsTrackCollection from "./ValidationFieldsTrackCollection";
import ValidationFieldsTrackField from "./ValidationFieldsTrackField";

export default class ValidationFieldsTrack extends ValidationFieldsTrackBase {
  id: string | undefined;
  fields: { [key: string]: ValidationFieldsTrackBase } = {};

  protected createChild(propertyFullName: string) {
    const propertyPath = propertyFullName.split(".");

    if (!propertyPath[0]) {
      throw Error("Invalid empty property name: " + propertyFullName);
    }

    if (propertyPath[0].indexOf("[") !== -1) {
      return new ValidationFieldsTrackCollection();
    }
    if (propertyPath.length > 1) {
      return new ValidationFieldsTrack();
    }
    return new ValidationFieldsTrackField();
  }

  getValue(propertyFullName: string): ValidationFieldsTrackBase | undefined {
    if (!propertyFullName) {
      return this;
    }

    const propertiesPath = propertyFullName.split(".");

    const isCollection = propertiesPath[0].indexOf("[") !== -1;
    if (!isCollection && propertiesPath.length == 1) {
      return this.fields[propertiesPath[0]];
    }

    const propertyName = isCollection
      ? propertiesPath[0].substring(0, propertiesPath[0].indexOf("["))
      : propertiesPath[0];

    return this.fields[propertyName]?.getValue(
      isCollection ? propertyFullName : this.getRemainingPropertyPath(propertiesPath)
    );
  }

  incrementTrySubmitAll() {
    Object.keys(this.fields).forEach((fieldName) => this.fields[fieldName].incrementTrySubmitAll());
  }

  setChildrenValue(params: ParamSetItemValues): void {
    Object.keys(this.fields).forEach((key) => {
      this.fields[key].setChildrenValue(params);
    });
  }

  setValue(propertyFullName: string, params: ParamSetItemValues): void {
    const propertiesPath = propertyFullName.split(".");
    const isCollection = propertiesPath[0].indexOf("[") !== -1;
    const propertyName = isCollection
      ? propertiesPath[0].substring(0, propertiesPath[0].indexOf("["))
      : propertiesPath[0];

    if (!this.fields[propertyName]) {
      this.fields[propertyName] = this.createChild(propertyFullName);
    }

    const nestedValue = this.fields[propertyName];

    if (isCollection && !(nestedValue instanceof ValidationFieldsTrackCollection)) {
      throw Error(
        "ValidationFieldsTrack was expecting an instance of ValidationFieldsTrackCollection for " +
          propertyFullName
      );
    }

    nestedValue.setValue(
      isCollection ? propertyFullName : propertyFullName.substring(propertiesPath[0].length + 1),
      params
    );
  }

  removeArrayItem(propertyFullName: string, startArrayIndex: number, deleteCount?: number): void {
    const nestedFieldTrack = this.getValue(propertyFullName);

    if (!nestedFieldTrack) {
      throw Error(
        "Getting undefined value trying to get array to remove items: " + propertyFullName
      );
    }

    if (!(nestedFieldTrack instanceof ValidationFieldsTrackCollection)) {
      throw Error(
        "An instance of ValidationFieldsTrackCollection was expected in order to remove array items: " +
          propertyFullName
      );
    }

    nestedFieldTrack.removeArrayItem("", startArrayIndex, deleteCount);
  }
}
