import { useEffect, useState } from "react";
import { AdminShardDto } from "../../api/admin/dtos/AdminShardDto";
import Container from "../../shared/components/Layout/GContainer";
import { useApiAdminShard } from "../../api/shared/hooks/useApiAdmin";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AdminShardForm from "./AdminShardForm";
import { useAdminShardValidation } from "./Validations/useAdminShardValidation";

const AdminShardEditPopup = (props: { adminShardId: string }) => {
  const [adminShard, setAdminShard] = useState<AdminShardDto>();
  const [modal] = useModal();
  const [apiAdminShard] = useApiAdminShard();

  useEffect(() => {
    const loadAdminShard = async () => {
      setAdminShard(await apiAdminShard.getById(props.adminShardId));
      modal.setVisible("Editar shard");
    };
    loadAdminShard();
  }, [props.adminShardId, modal, apiAdminShard]);

  const save = async (adminShard: AdminShardDto) => {
    const created = await apiAdminShard.edit(adminShard);
    modal.closeAsSuccess(created, "El shard se editó con éxito");
  };

  if (!adminShard) {
    return <></>;
  }

  return (
    <Container>
      <StateProvider
        model={adminShard}
        onSubmit={save}
        validationSchema={useAdminShardValidation()}>
        {(p) => <AdminShardForm onSave={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default AdminShardEditPopup;
