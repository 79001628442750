// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { Tax } from "../../shared/enums/Tax";
import { Currency } from "../../shared/enums/Currency";

export class ProductInfoDto extends BaseEntityDto {
  defaultTax: Tax;
  defaultCurrency: Currency;
  referencePrice: number = 0;
  saleLineAdditionalDescription: string = "";
  addendumAddLegend: string = "";
}
