import { useEffect, useState } from "react";
import AdminProviderForm from "../../admin/AdminProvider/AdminProviderForm";
import { useAdminProviderValidator } from "../../admin/AdminProvider/Validations/useAdminProviderValidations";
import { AdminProviderWithSecureInfoDto } from "../../api/admin/dtos/AdminProviderWithSecureInfoDto";
import { useApiAdminProviderConfig } from "../../api/shared/hooks/useApiAdminProviders";
import Page from "../../shared/components/Layout/Page";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { useNotifier } from "../../shared/hooks/useNotifier";
import { StateProvider } from "../../shared/modelState/ModelStateContext";

const AdminProviderConfiguration = () => {
  const [apiAdminProviderConfig] = useApiAdminProviderConfig();
  const [providerDto, setProviderDto] = useState<AdminProviderWithSecureInfoDto>();
  const navigation = useNavigation();
  const notifier = useNotifier();

  const save = async (adminProviderDto: AdminProviderWithSecureInfoDto) => {
    await apiAdminProviderConfig.edit(adminProviderDto);
    notifier.showSuccessFast("La configuración se editó con éxito");
    navigation.back();
  };

  useEffect(() => {
    const loadAdminAccount = async () => {
      setProviderDto(await apiAdminProviderConfig.get());
    };
    loadAdminAccount();
  }, []);

  if (!providerDto) {
    return <></>;
  }

  return (
    <Page title="Configuración de proveedor">
      <StateProvider
        model={providerDto}
        onSubmit={save}
        validationSchema={useAdminProviderValidator()}>
        {(p) => <AdminProviderForm onSave={p.handleSubmit} isEdit={true} />}
      </StateProvider>
    </Page>
  );
};

export default AdminProviderConfiguration;
