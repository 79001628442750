import {
  Autocomplete,
  Box,
  createFilterOptions,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import { CSSProperties, MutableRefObject, ReactNode, useEffect, useRef, useState } from "react";
import { SuggestItemDto } from "../../../../api/shared/dtos/SuggestItemDto";
import { SuggestResponseDto } from "../../../../api/shared/dtos/SuggestResponseDto";
import { useAppTheme } from "../../../../AppThemeProvider";
import { Constants } from "../../../../Constants";
import { AppThemeColor } from "../../../../styles/color";
import { AppSize } from "../../../AppSize";
import { useContextAppInfo } from "../../../hooks/useContextAppInfo";
import { TextAlign } from "../../../TextAlign";
import { ThemeSize } from "../../../ThemeSize";
import { ThemeVariant } from "../../../ThemeVariant";
import ButtonIcon from "../../Button/ButtonIcon";
import ButtonSecondary from "../../Button/ButtonSecondary";
import Icon, { TypeIcon } from "../../Icon";
import {
  LAYOUT_HINT_MARGIN_BOTTOM_FIX,
  LAYOUT_HINT_PADDING_BOTTOM,
} from "../../Layout/LayoutConstants";
import Spinner from "../../Spinner";
import PropsInputBase from "../PropsInputBase";

export type OmitFormAutocompleteProps =
  | "value"
  | "onChange"
  | "onCreateNewClick"
  | "onGetEntityById"
  | "onServerSideSuggest";

export interface PropsInputAutocompleteBase<T> extends PropsInputBase<T> {
  inputTextValue?: string;
  isFilter?: boolean;
  isSuggestOnly?: boolean;
  preventClearButton?: boolean;
  value: T;
  onChange(value: T): void;
  onInputTextValueChange?(value: string): void;
  onCreateNewClick?(searchTerm: string, createCallback: (id: string) => void): void;
  onGetEntityById(id: string): Promise<T>;
  onMapFromItemSuggest?(item: SuggestItemDto): T;
  onEditClick?(id: string, editCallback: (id: string) => void): void;
  onServerSideSuggest?(
    echoId: number,
    term: string,
    isInitLoad: boolean
  ): Promise<SuggestResponseDto>;
}

const MAX_DISPLAY_ITEMS = 20;
const MIN_WIDTH = 280;

const InputAutocompleteBase = (props: PropsInputAutocompleteBase<any>) => {
  const appTheme = useAppTheme();
  const { appInfo } = useContextAppInfo();
  const [forceReload, setForceReload] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitLoadDone, setIsInitLoadDone] = useState(false);
  const [inputTextValue, setInputTextValue] = useState(props.inputTextValue ?? "");
  const [options, setOptions] = useState<SuggestItemDto[]>([]);
  const [isServerSideSearch, setIsServerSideSearch] = useState(false);
  const [displayItemCount, setDisplayItemCount] = useState(10);
  const [initLoadTotalCount, setInitLoadTotalCount] = useState(0);
  const [timerDelaySearch, setTimerDelaySearch] = useState<number | null>(null);
  const echoId = useRef(1);
  const inputRef = useRef();
  const style: CSSProperties = {
    marginBottom: !props.hintAutoHeight
      ? -LAYOUT_HINT_MARGIN_BOTTOM_FIX
      : LAYOUT_HINT_PADDING_BOTTOM,
  };

  if (props.isFilter && !props.onMapFromItemSuggest) {
    throw Error("Filter autocomplete must define onMapFromItemSuggest");
  }

  const getEntityAndPropagate = (id: string | undefined): void => {
    if (!id) {
      props.onChange(null);
      return;
    }

    setIsLoading(true);
    const request = async () => {
      const entity = await props.onGetEntityById(id);
      props.onChange(entity);
      setIsLoading(false);
    };
    request();
  };

  useEffect(() => {
    if (!isOpen || isInitLoadDone) {
      return;
    }

    if (forceReload) {
      setForceReload(false);
    }

    setIsLoading(true);
    const initLoad = async () => {
      if (!props.onServerSideSuggest) {
        return;
      }

      const suggestResponse = await props.onServerSideSuggest(echoId.current, "", true);
      setIsServerSideSearch(!suggestResponse.initLoadAll);
      setInitLoadTotalCount(suggestResponse.initLoadTotalCount ?? 0);
      setIsLoading(false);
      setIsInitLoadDone(true);

      if (!suggestResponse.initLoadAll && inputTextValue) {
        serverSideSuggest(props, echoId, inputTextValue, setOptions, setIsLoading);
      } else {
        setOptions(suggestResponse.items);
      }
    };
    initLoad();
  }, [isOpen, forceReload]);

  useEffect(() => {
    setInputTextValue(props.inputTextValue ?? "");
  }, [props.inputTextValue]);

  return (
    <Autocomplete
      PaperComponent={({ children }) => {
        return (
          <Paper style={{ minWidth: MIN_WIDTH }}>
            {children}
            {props.onCreateNewClick && (
              <div style={{ padding: 10, textAlign: TextAlign.center }}>
                <ButtonSecondary
                  icon={TypeIcon.create}
                  text="Agregar nuevo"
                  onMouseDown={() => {
                    if (props.onCreateNewClick) {
                      props.onCreateNewClick(props.inputTextValue ?? "", getEntityAndPropagate);
                    }
                  }}
                  onClick={() => {
                    // Using on mouse down because the autocomplete closes the dropdown on click
                  }}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "center",
                padding: 10,
                color: appTheme.toHexColor(AppThemeColor.gray),
              }}>
              {initLoadTotalCount > 0 && (
                <>
                  Mostrando {displayItemCount} de {initLoadTotalCount}
                </>
              )}
              {!isServerSideSearch && (
                <ButtonIcon
                  icon={TypeIcon.refresh}
                  size={AppSize.small}
                  style={{ position: "absolute", right: 10, top: 5 }}
                  onClick={() => {
                    setIsInitLoadDone(false);
                    setForceReload(true);
                  }}
                />
              )}
            </div>
          </Paper>
        );
      }}
      filterOptions={(options, params) => {
        if (!isInitLoadDone) {
          return options;
        }

        return applyFilter(
          options,
          params,
          props,
          isServerSideSearch,
          MAX_DISPLAY_ITEMS,
          setDisplayItemCount
        );
      }}
      clearIcon={!props.preventClearButton ? <Icon type={TypeIcon.close} /> : null}
      getOptionDisabled={(option: SuggestItemDto) => option.disabled}
      getOptionLabel={(option) =>
        option instanceof String ? (option as string) : (option as SuggestItemDto)?.label ?? ""
      }
      freeSolo={props.isSuggestOnly}
      inputValue={inputTextValue ?? ""}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={isLoading}
      loadingText="Cargando..."
      noOptionsText="No hay registros para mostrar"
      open={isOpen}
      options={options}
      disabled={props.readOnly}
      style={style}
      value={props.value ?? null}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      onInputChange={(event: any) =>
        onInputChange(
          event,
          props,
          echoId,
          initLoadTotalCount,
          isServerSideSearch,
          props.value?.id,
          timerDelaySearch,
          setInputTextValue,
          setIsLoading,
          setOptions,
          setTimerDelaySearch
        )
      }
      onBlur={() => {
        setInputTextValue(props.inputTextValue ?? "");
        if (props.onBlur) {
          props.onBlur();
        }
      }}
      onChange={async (_event, value: SuggestItemDto | string | null) => {
        if (value instanceof String) {
          return;
        }
        setInputTextValue((value as SuggestItemDto)?.label ?? "");
        if (value && props.isFilter && props.onMapFromItemSuggest) {
          props.onChange(props.onMapFromItemSuggest(value as SuggestItemDto));
          return;
        }
        getEntityAndPropagate((value as SuggestItemDto)?.id);
      }}
      renderOption={(renderProps: any, option: SuggestItemDto) =>
        renderOption(renderProps, option, appTheme)
      }
      renderInput={(params: any) => {
        if (props.value && !props.isFilter && props.onEditClick) {
          params.InputProps.startAdornment = (
            <InputAdornment position="start" style={{ margin: 0 }}>
              <ButtonIcon
                icon={TypeIcon.edit}
                size={AppSize.small}
                noPadding={true}
                onClick={() => {
                  if (props.onEditClick) {
                    props.onEditClick(props.value.id, getEntityAndPropagate);
                  }
                }}
              />
            </InputAdornment>
          );
        }

        return renderInput(
          params,
          props,
          isLoading,
          inputRef,
          props.variant ??
            (props.isFilter ? ThemeVariant.standard : appInfo?.muiVariant) ??
            ThemeVariant.filled
        );
      }}
    />
  );
};

export default InputAutocompleteBase;

const applyFilter = (
  options: SuggestItemDto[],
  params: any,
  props: PropsInputAutocompleteBase<any>,
  isServerSideSearch: boolean,
  suggestMaxItemCount: number,
  setDisplayItemCount: (value: number) => void
): SuggestItemDto[] => {
  if (isServerSideSearch) {
    return options;
  }

  const filterOptions = createFilterOptions<SuggestItemDto>({
    limit: suggestMaxItemCount,
  });

  if (params.inputValue == props.inputTextValue) {
    params.inputValue = "";
  }

  const filtered = filterOptions(options, params);
  setTimeout(() => {
    setDisplayItemCount(filtered.length);
  }, 0);

  return filtered;
};

const onInputChange = (
  event: any,
  props: PropsInputAutocompleteBase<any>,
  echoId: MutableRefObject<number>,
  initLoadTotalCount: number,
  isServerSideSearch: boolean,
  currentValueId: string,
  timerDelaySearch: number | null,
  setInputTextValue: (value: string) => void,
  setIsLoading: (value: boolean) => void,
  setOptions: (options: SuggestItemDto[]) => void,
  setTimerDelaySearch: (timer: number | null) => void
) => {
  if (!event || !event.target) {
    return;
  }

  setInputTextValue(event.target.value);
  if (event.type != "change") {
    return;
  }

  if (props.onInputTextValueChange) {
    props.onInputTextValueChange(event.target.value);
  }

  if (isServerSideSearch) {
    if (timerDelaySearch) {
      clearTimeout(timerDelaySearch);
    }
    const timer: any = setTimeout(() => {
      serverSideSuggest(props, echoId, event.target.value, setOptions, setIsLoading);
      setTimerDelaySearch(null);
    }, 600);
    setTimerDelaySearch(timer);
  }
};

const renderOption = (renderProps: any, option: SuggestItemDto, appTheme: any): ReactNode => {
  const optionStyle: CSSProperties = {
    ...renderProps.style,
    flexDirection: "column",
  };

  return (
    <Box {...renderProps} key={option.id} component="li" style={optionStyle}>
      <div
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          fontSize: "1.4em",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}>
        {option.label}
      </div>
      {!!option.label2 && (
        <div
          style={{
            width: "100%",
            color: appTheme.toHexColor(AppThemeColor.gray),
            fontSize: ".8em",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
          {option.label2}
        </div>
      )}
      {!!option.label3 && (
        <div
          style={{
            width: "100%",
            color: appTheme.toHexColor(AppThemeColor.gray),
            fontSize: ".7em",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
          {option.label3}
        </div>
      )}
    </Box>
  );
};

const renderInput = (
  params: any,
  props: PropsInputAutocompleteBase<any>,
  isLoading: boolean,
  inputRef: any,
  variant: ThemeVariant
) => {
  let helperText = Constants.EMPTY_HINT;
  if (props.validation) {
    helperText = props.validation;
  } else if (props.hint) {
    helperText = props.hint;
  } else if (props.hintAutoHeight) {
    helperText = "";
  }

  return (
    <TextField
      {...params}
      inputRef={inputRef}
      size={ThemeSize.small}
      variant={variant}
      label={props.label}
      error={!!props.validation}
      helperText={helperText}
      InputProps={{
        ...params.InputProps,
        startAdornment: params.InputProps.startAdornment,
        endAdornment: (
          <>
            {isLoading ? <Spinner /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );
};

const serverSideSuggest = async (
  props: PropsInputAutocompleteBase<any>,
  echoId: MutableRefObject<number>,
  searchTerm: string,
  setOptions: (options: SuggestItemDto[]) => void,
  setIsLoading: (value: boolean) => void
) => {
  setIsLoading(true);
  echoId.current++;
  try {
    if (!props.onServerSideSuggest) {
      return;
    }
    const suggestResponse = await props.onServerSideSuggest(echoId.current, searchTerm, false);
    if (echoId.current == suggestResponse.echoId) {
      setOptions(suggestResponse.items);
      setIsLoading(false);
    }
  } catch {
    setIsLoading(false);
  }
};
