import { useEffect, useState } from "react";
import { AccountBranchDto } from "../../api/app/dtos/AccountBranchDto";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminAccessAccountBranch } from "../../api/shared/hooks/useApiAdminAccess";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AdminAccountBranchForm from "./AdminAccountBranchForm";
import { useAdminAccountBranchValidator } from "./Validations/useAdminAccountBranchValidations";

const AdminAccountBranchDetailsPopup = (props: { accountId: string; accountBranchId: string }) => {
  const [modal] = useModal();
  const [accountBranchDto, setAccountBranchDto] = useState<AccountBranchDto>();
  const [apiAdminAccessAccountBranch] = useApiAdminAccessAccountBranch();

  const save = async (accountBranchDto: AccountBranchDto) => {
    await apiAdminAccessAccountBranch.edit(accountBranchDto.accountId, accountBranchDto);
    modal.closeAsSuccess(accountBranchDto, "La sucursal se editó con éxito");
  };

  useEffect(() => {
    const loadAdminAccount = async () => {
      setAccountBranchDto(
        await apiAdminAccessAccountBranch.getById(props.accountId, props.accountBranchId)
      );
      modal.setVisible("Editar sucursal", ModalSize.small);
    };
    loadAdminAccount();
  }, []);

  if (!accountBranchDto) {
    return <></>;
  }

  return (
    <Container>
      <StateProvider
        model={accountBranchDto}
        onSubmit={save}
        validationSchema={useAdminAccountBranchValidator()}>
        {(p) => <AdminAccountBranchForm onSave={p.handleSubmit} isEdit={true} />}
      </StateProvider>
    </Container>
  );
};

export default AdminAccountBranchDetailsPopup;
