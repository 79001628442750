// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminTax {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getByCountry(
    country: number,
    apiOptions?: ApiOptions
  ): Promise<number[]> {
    return this.apiService.apiGet("/admin/taxes/by-country?" + 
      (country ? "&country=" + country : ""), apiOptions);
  }

}
