// File generated by Gesta.Build.PostEvents
import { SearchResultBaseDto } from "./SearchResultBaseDto";

export class PagedResultDto<T> extends SearchResultBaseDto<T> {
  currentPageIndex: number = 0;
  currentPageItemCount: number = 0;
  totalPageCount: number = 0;
  totalItemCount: number = 0;
  usingContinuationToken: boolean = false;
  pageContinuationToken: string = "";
}
