// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { Tax } from "../../shared/enums/Tax";

export class InvoiceTaxDto extends BaseEntityDto {
  amount: number = 0;
  linesTotalAmount: number = 0;
  percentage: number = 0;
  tax: Tax;
  totalGlobalDiscounts: number | null = null;
  totalGlobalSurcharges: number | null = null;
}
