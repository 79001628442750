// File generated by: Gesta.Build.PostEvents
export enum GridName {
	Sales = 1,
	SaleCancellations = 2,
	Contacts = 3,
	Products = 4,
	EInvoicePurchases = 5,
	EInvoicePurchasesPendingApprove = 6,
	EInvoiceDailyReport = 7,
}
