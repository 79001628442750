import { useEffect, useState } from "react";
import Container from "../../../shared/components/Layout/GContainer";
import { ModalSize } from "../../../shared/context/ModalProvider";
import { useModal } from "../../../shared/hooks/useModal";
import { StateProvider } from "../../../shared/modelState/ModelStateContext";
import { useAdminPointOfSaleValidator } from "./Validations/useAdminPointOfSaleValidations";
import { PointOfSaleDto } from "../../../api/app/dtos/PointOfSaleDto";
import AdminPointOfSaleForm from "./AdminPointOfSaleForm";
import { useApiAdminProviderAccessPointOfSale } from "../../../api/shared/hooks/useApiAdminProviderAccess";

const AdminPointOfSAleDetailsPopup = (props: { accountId: string; pointOfSaleId: string }) => {
  const [modal] = useModal();
  const [pointOfSAleDto, setPointOfSAleDto] = useState<PointOfSaleDto>();
  const [apiAdminAccessPointOfSale] = useApiAdminProviderAccessPointOfSale();

  const save = async (pointOfSAleDto: PointOfSaleDto) => {
    await apiAdminAccessPointOfSale.edit(pointOfSAleDto.accountId, pointOfSAleDto);
    modal.closeAsSuccess(pointOfSAleDto, "El punto de venta se editó con éxito");
  };

  useEffect(() => {
    const loadAdminAccount = async () => {
      setPointOfSAleDto(
        await apiAdminAccessPointOfSale.getById(props.accountId, props.pointOfSaleId)
      );
      modal.setVisible("Editar punto de venta", ModalSize.small);
    };
    loadAdminAccount();
  }, []);

  if (!pointOfSAleDto) {
    return <></>;
  }

  return (
    <Container>
      <StateProvider
        model={pointOfSAleDto}
        onSubmit={save}
        validationSchema={useAdminPointOfSaleValidator()}>
        {(p) => <AdminPointOfSaleForm onSave={p.handleSubmit} isEdit={true} />}
      </StateProvider>
    </Container>
  );
};

export default AdminPointOfSAleDetailsPopup;
