import Row from "../Layout/GRow";
import ErrorPageLayout from "./ErrorPageLayout";

const NotFound = () => {
  return (
    <ErrorPageLayout>
      <Row>No encontramos la página :(</Row>
    </ErrorPageLayout>
  );
};

export default NotFound;
