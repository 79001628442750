import { useEffect, useRef } from "react";
import { nameof } from "ts-simple-nameof";
import { FiltersDto } from "../../../api/shared/dtos/FiltersDto";
import { useIsMobile } from "../../hooks/useIsMobile";
import { ThemeVariant } from "../../ThemeVariant";
import FormText from "../forms/FormText";

const GridDefaultSearch = (props: { setFocus?: boolean; onDefaultSearchChange: () => void }) => {
  const isMobile = useIsMobile();
  const ref = useRef();

  useEffect(() => {
    if (props.setFocus) {
      setTimeout(() => {
        (ref.current as any).focus();
      }, 100);
    }
  }, [props.setFocus]);

  return (
    <FormText
      ref={ref}
      label={isMobile ? "Buscar por" : ""}
      hintAutoHeight={true}
      propertyName={nameof<FiltersDto>((p) => p.defaultTextSearch)}
      variant={ThemeVariant.standard}
      onValueChange={() => {
        props.onDefaultSearchChange();
      }}
    />
  );
};

export default GridDefaultSearch;
