import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import AdminAccountList from "./admin-provider/AdminAccount/AdminAccountList";
import AdminProviderHome from "./admin-provider/Home/AdminProviderHome";
import AdminProviderLayout from "./admin-provider/Layout/AdminProviderLayout";
import AdminTemplateEmailCreate from "./admin/AccountAccess/AdminTemplateEmail/AdminTemplateEmailCreate";
import AdminTemplateEmailEdit from "./admin/AccountAccess/AdminTemplateEmail/AdminTemplateEmailEdit";
import AdminTemplateEmailList from "./admin/AccountAccess/AdminTemplateEmail/AdminTemplateEmailList";
import AdminTemplateGenericCreate from "./admin/AccountAccess/AdminTemplateGeneric/AdminTemplateGenericCreate";
import AdminTemplateGenericEdit from "./admin/AccountAccess/AdminTemplateGeneric/AdminTemplateGenericEdit";
import AdminTemplateGenericList from "./admin/AccountAccess/AdminTemplateGeneric/AdminTemplateGenericList";
import AdminTemplatePdfCreate from "./admin/AccountAccess/AdminTemplatePdf/AdminTemplatePdfCreate";
import AdminTemplatePdfEdit from "./admin/AccountAccess/AdminTemplatePdf/AdminTemplatePdfEdit";
import AdminTemplatePdfList from "./admin/AccountAccess/AdminTemplatePdf/AdminTemplatePdfList";
import AdminAccountBranchList from "./admin-provider/AdminAccountBranch/AdminAccountBranchList";
import AdminPointOfSaleList from "./admin-provider/AdminAccountBranch/AdminPointOfSale/AdminPointOfSaleList";
import AdminConfig from "./admin/AdminConfig/AdminConfig";
import AdminCurrencyExchangeRateList from "./admin/AdminCurrencyExchangeRate/AdminCurrencyExchangeRateList";
import AdminEInvoice from "./admin/AdminEInvoice/AdminEInvoice";
import AdminEInvoiceHomologation from "./admin/AdminEInvoice/EInvoiceHomologation/AdminEInvoiceHomologation";
import AdminIcons from "./admin/AdminIcons/AdminIcons";
import AdminLogging from "./admin/AdminLogging/AdminLogging";
import AdminLoggingList from "./admin/AdminLogging/AdminLogViewer";
import AdminLongProcessLogList from "./admin/AdminLongProcessLog/AdminLongProcessLogList";
import AdminPointOfSaleClientList from "./admin/AdminPointOfSaleClients/AdminPointOfSaleClientList";
import AdminProviderList from "./admin/AdminProvider/AdminProviderList";
import AdminProviderLinkedUserList from "./admin/AdminProviderLinkedUser/AdminProviderLinkedUserList";
import AdminRetentionList from "./admin/AdminRetention/AdminRetentionList";
import AdminServiceBusDashboard from "./admin/AdminServiceBus/AdminServiceBusDashboard";
import AdminShardList from "./admin/AdminShard/AdminShardList";
import AdminTaxValueList from "./admin/AdminTaxValue/AdminTaxValueList";
import AdminTemplates from "./admin/AdminTemplates/AdminTemplates";
import AdminTermsAndConditionsCreate from "./admin-provider/AdminTermsAndConditions/AdminTermsAndConditionsCreate";
import AdminTermsAndConditionsDetails from "./admin-provider/AdminTermsAndConditions/AdminTermsAndConditionsDetails";
import AdminTermsAndConditionsEdit from "./admin-provider/AdminTermsAndConditions/AdminTermsAndConditionsEdit";
import AdminTermsAndConditionsList from "./admin-provider/AdminTermsAndConditions/AdminTermsAndConditionsList";
import AdminHome from "./admin/Home/AdminHome";
import AdminLayout from "./admin/Layout/AdminLayout";
import UserList from "./admin-provider/User/UserList";
import UserLinkedAccountList from "./admin-provider/UserLinkedAccount/UserLinkedAccountList";
import UserTermsAcceptedList from "./admin-provider/UserTermsAccepted/UserTermsAcceptedList";
import { Country } from "./api/shared/enums/Country";
import AccountUserList from "./app/AccountUser/AccountUserList";
import ConfigEmailList from "./app/Configuration/ConfigEmail/ConfigEmailList";
import ConfigEmailOAuthSuccessGmail from "./app/Configuration/ConfigEmail/ConfigEmailOAuthSuccessGmail";
import ConfigLocale from "./app/Configuration/ConfigLocale/ConfigLocale";
import Configuration from "./app/Configuration/Configuration";
import ConfigCurrencies from "./app/Configuration/Currencies/ConfigCurrencies";
import EInvoiceConfigGeneral from "./app/Configuration/EInvoice/Config/EInvoiceConfigGeneral";
import EInvoiceAuthorizationList from "./app/Configuration/EInvoice/EInvoiceAuthorization/EInvoiceAuthorizationList";
import EInvoiceDailyReportList from "./app/Configuration/EInvoice/EInvoiceDailyReport/EInvoiceDailyReportList";
import ConfigPreferences from "./app/Configuration/Preferences/ConfigPreferences";
import ConfigTaxes from "./app/Configuration/Taxes/ConfigTaxes";
import VaultCertificateList from "./app/Configuration/VaultCertificates/VaultCertificateList";
import ContactList from "./app/Contact/ContactList";
import Home from "./app/Home/Home";
import ForgotPassword from "./app/Login/ForgotPassword";
import ForgotPasswordEmailSent from "./app/Login/ForgotPassword.EmailSent";
import LoginLayout from "./app/Login/Layout/LoginLayout";
import LoginRegister from "./app/Login/Register";
import LoginRegisterSuccessEmail from "./app/Login/Register.Success.Email";
import LoginRegisterSuccessMobile from "./app/Login/Register.Success.Mobile";
import LoginResetPassword from "./app/Login/ResetPassword";
import LoginSignIn from "./app/Login/SignIn";
import LoginWelcome from "./app/Login/Welcome";
import MyAccountUser from "./app/MyAccountUser/MyAccountUser";
import MyAdminAccount from "./app/MyAdminAccount/MyAdminAccount";
import ProductList from "./app/Products/ProductList";
import PublicLayout from "./app/Public/Layout/PublicLayout";
import PublicQrQuery from "./app/Public/PublicQrQuery";
import EInvoicePurchasePendingList from "./app/Purchases/EInvoices/EInvoicePurchasePendingList";
import EInvoiceEnvelopePurchaseList from "./app/Purchases/EInvoices/Envelopes/EInvoiceEnvelopePurchaseList";
import PurchaseList from "./app/Purchases/PurchaseList";
import ReportDashboard from "./app/Reports/ReportDashboard";
import RequestedExportList from "./app/Reports/RequestedExport/RequestedExportList";
import SaleList from "./app/Sales/SaleList";
import SalePageCancel from "./app/Sales/SalePage.Cancel";
import SalePageCancelExport from "./app/Sales/SalePage.CancelExport";
import SalePageCancelSenderInBehalf from "./app/Sales/SalePage.CancelSenderInBehalf";
import SalePageClone from "./app/Sales/SalePage.Clone";
import SalePageCreate from "./app/Sales/SalePage.Create";
import SalePageCreateAdjustment from "./app/Sales/SalePage.CreateAdjustment";
import SalePageCreateAdjustmentExport from "./app/Sales/SalePage.CreateAdjustmentExport";
import SalePageCreateAdjustmentSenderInBehalf from "./app/Sales/SalePage.CreateAdjustmentSenderInBehalf";
import SalePageCreateDeliveryNote from "./app/Sales/SalePage.CreateDeliveryNote";
import SalePageCreateEntranceTicket from "./app/Sales/SalePage.CreateEntranceTicket";
import SalePageCreateExport from "./app/Sales/SalePage.CreateExport";
import SalePageCreateReceipt from "./app/Sales/SalePage.CreateReceipt";
import SalePageCreateSenderInBehalf from "./app/Sales/SalePage.CreateSenderInBehalf";
import SalePageDraftEdit from "./app/Sales/SalePage.DraftEdit";
import SentEmailList from "./app/SentEmail/SentEmailList";
import StockDashboard from "./app/Stock/StockDashboard";
import TemplateConfig from "./app/Templates/TemplateConfig";
import Layout from "./shared/components/Layout/Layout";
import NotFound from "./shared/components/LoginFlow/NotFound";
import Unauthorized from "./shared/components/LoginFlow/Unauthorized";
import { useContextLoginInfo } from "./shared/hooks/useContextLoginInfo";
import AdminSentEmailList from "./admin-provider/AdminSentEmail/AdminSentEmailList";
import AdminProviderConfiguration from "./admin-provider/AdminProviderConfiguration/AdminProviderConfiguration";
import AdminProviderTemplates from "./admin-provider/AdminProviderTemplates/AdminProviderTemplates";
import AdminEInvoiceDailyReportList from "./admin-provider/AdminEInvoice/AdminEInvoiceDailyReports/AdminEInvoiceDailyReportList";
import AdminProviderEInvoice from "./admin-provider/AdminEInvoice/AdminProviderEInvoice";
import AdminCertifiedProviderReportList from "./admin-provider/AdminEInvoice/AdminCertifiedProviderReport/AdminCertifiedProviderReportList";

export const AppRoute = {
  base: "/",
  admin: {
    base: "/admin",
    accounts: "/admin/accounts",
    accountBranches: { 
      base: "/admin/account-branches",
      pointOfSales: "/admin/account-branches/point-of-sales"
    },
    config: "/admin/config",
    currencyExchangeRates: "/admin/currency-exchange-rates",
    eInvoices: {
      base: "/admin/e-invoices",
      dailyReports: "/admin/e-invoices/daily-reports",
      homologation: "/admin/e-invoices/homologation",
      retentions: "/admin/e-invoices/retentions",
      certifiedProviderReport: "/admin/e-invoices/certified-provider-report",
    },
    icons: "/admin/icons",
    logging: "/admin/logging",
    longProcessLog: "/admin/long-process-log",
    logViewer: "/admin/log-viewer",
    pointOfSaleClients: "/admin/pos-clients",
    providers: {
      base: "/admin/providers",
      linkedUsers: "/admin/providers/linked-users",
    },
    sentEmails: "/admin/sent-emails",
    serviceBus: "/admin/service-bus",
    shards: "/admin/shards",
    taxValues: "/admin/taxValues",
    templates: {
      base: "/admin/templates",
      email: {
        base: "/admin/templates/email",
        create: "/admin/templates/email/create",
        edit: "/admin/templates/email/edit",
      },
      generic: {
        base: "/admin/templates/generic",
        create: "/admin/templates/generic/create",
        edit: "/admin/templates/generic/edit",
      },
      pdf: {
        base: "/admin/templates/pdf",
        create: "/admin/templates/pdf/create",
        edit: "/admin/templates/pdf/edit",
      },
    },
    termsAndConditions: { 
      base: "/admin/terms-and-conditions", 
      create: "/admin/terms-and-conditions/create", 
      edit: "/admin/terms-and-conditions/edit", 
      details: "/admin/terms-and-conditions/details", 
    },
    users: "/admin/users",
    userLinkedAccounts: (
      params?: {
        userId?: string, 
        adminAccountId?: string
      }) => "/admin/user-linked-accounts" + getQueryString(params),
    userTermsAccepted: "/admin/user-terms-accepted",
  },
  adminProvider: {
    base: "/admin-provider",
    accounts: "/admin-provider/accounts",
    accountBranches: { 
      base: "/admin-provider/account-branches",
      pointOfSales: "/admin-provider/account-branches/point-of-sales"
    },
    config: "/admin-provider/config",
    eInvoices: {
      base: "/admin-provider/e-invoices",
      dailyReports: "/admin-provider/e-invoices/daily-reports",
      certifiedProviderReport: "/admin/e-invoices/certified-provider-report",
    },
    sentEmails: "/admin-provider/sent-emails",
    termsAndConditions: { 
      base: "/admin-provider/terms-and-conditions", 
      create: "/admin-provider/terms-and-conditions/create", 
      edit: "/admin-provider/terms-and-conditions/edit", 
      details: "/admin-provider/terms-and-conditions/details", 
    },
    templates: {
      base: "/admin-provider/templates",
      email: {
        base: "/admin-provider/templates/email",
      },
      pdf: {
        base: "/admin-provider/templates/pdf",
      }
    },
    users: "/admin-provider/users",
    userLinkedAccounts: (
      params?: {
        userId?: string, 
        adminAccountId?: string
      }) => "/admin-provider/user-linked-accounts" + getQueryString(params),
    userTermsAccepted: "/admin-provider/user-terms-accepted",
  },
  login: {
    base: "/login",
    forgotPassword: "/login/forgot-password",
    forgotPasswordEmailSent: "/login/forgot-password-email-sent",
    register: {
      base: "/login/register",
      successMobile: "/login/register/success-mobile",
      successEmail: "/login/register/success-email",
    },
    resetPassword: "/login/reset-password",
    resetPasswordParams: (params: {userId: string, token: string}) =>
      "/login/reset-password" + getQueryString(params),
    welcome: "/login/welcome",
  },
  contacts: "/contacts",
  configuration: {
    base: "/configuration",
    currencies: "/configuration/currencies",
    eInvoice: {
      base: "/configuration/e-invoice",
      authorizations: "/configuration/e-invoice/authorizations",
      dailyReports: "/configuration/e-invoice/daily-reports",
      general: "/configuration/e-invoice/general",
      vaultCertificates: "/configuration/e-invoice/vault-certificates",
    },
    preferences: "/configuration/preferences",
    email: {
      base:  "/configuration/email",
      oAuthSuccessGmail: "/configuration/email/oauth-success-gmail"
    },
    taxes: "/configuration/taxes",
    locale: "/configuration/locale",
  },
  myAdminAccount: "/my-company",
  myAccountUser: "/my-account",
  purchases: {
    base: "/purchases",
    create: "/purchases/create",
    eInvoicePendingApprove: "/purchases/e-invoice-pending-approve",
    envelopes: {
      base: "/purchases/envelopes",
    },
  },
  products: "/products",
  reports: {
    base: "/reports",
    requestedExport: "/reports/requested-export",
  },
  sales: {
    base: "/sales",
    create: "/sales/create",
    createAdjustment: "/sales/crate-adjustment",
    createAdjustmentExport: "/sales/crate-adjustment-export",
    createAdjustmentSenderInBehalf: "/sales/crate-adjustment-sender-behalf",
    createExport: "/sales/create-export",
    createDeliveryNote: "/sales/create-delivery-note",
    createEntranceTicket: "/sales/create-entrance-ticket",
    createReceipt: "/sales/create-receipt",
    createForSenderInBehalf: "/sales/create-sender-behalf",
    cancel: "/sales/cancel",
    cancelExport: "/sales/cancel-export",
    cancelSenderBehalf: "/sales/cancel-sender-behalf",
    clone: "/sales/clone",
    draftEdit: "/sales/draft-edit",
    cancelSaleById: (id: string) => "/sales/cancel/" + id,
    cloneById: (id: string) => "/sales/clone/" + id,
    draftEditById: (id: string) => "/sales/draft-edit/" + id,
  },
  sentEmails: "/sent-emails",
  stock: "/stock",
  templates: {
    base: "/templates",
    config: "/templates/config",
    generic: {
      base: "/templates/generic",
      create: "/templates/generic/create",
      edit: "/templates/generic/edit",
    },
    email: {
      base: "/templates/email",
      create: "/templates/email/create",
      edit: "/templates/email/edit",
    },
    pdf: {
      base: "/templates/pdf",
      create: "/templates/pdf/create",
      edit: "/templates/pdf/edit",
    },
  },
  unauthorized: "/unauthorized",
  users: "/users",
  public: {
    base: "/public",
    uruguay: {
      base: "/public/uruguay",
      qrQuery: "/public/uruguay/qr-query"
    }
  },
};

function path(path: string) {
  const parts = path.split("/");
  return parts[parts.length - 1];
}

const GuardAccountAdmin = () => {
  const { loginInfo } = useContextLoginInfo();
  if (!loginInfo.authInfo.isSuperAdmin && !loginInfo.authInfo.isAccountAdmin) {
    return <Navigate replace to={AppRoute.unauthorized} />;
  }
  return <Outlet />;
};

const AppRoutes = () => (
  <Routes>
    <Route path={AppRoute.login.base} element={<LoginLayout />}>
      <Route path={path(AppRoute.login.forgotPassword)} element={<ForgotPassword />} />
      <Route path={path(AppRoute.login.forgotPasswordEmailSent)} element={<ForgotPasswordEmailSent />} />
      <Route path={path(AppRoute.login.resetPassword)} element={<LoginResetPassword />} />
      <Route path={path(AppRoute.login.welcome)} element={<LoginWelcome />} />
      <Route path={path(AppRoute.login.register.base)}>
        <Route path={path(AppRoute.login.register.successMobile)} element={<LoginRegisterSuccessMobile />} />
        <Route path={path(AppRoute.login.register.successEmail)} element={<LoginRegisterSuccessEmail />} />
        <Route path={path(AppRoute.login.register.base)} element={<LoginRegister />} />
        <Route path="" element={<LoginRegister />} />
      </Route>
      <Route path="" element={<LoginSignIn />} />
    </Route>
    <Route path={AppRoute.public.base} element={<PublicLayout />}>
      <Route path={path(AppRoute.public.uruguay.base)}>
        <Route path={path(AppRoute.public.uruguay.qrQuery)} element={<PublicQrQuery country={Country.Uruguay} />} />
      </Route>
    </Route>
    <Route path={AppRoute.admin.base} element={<AdminLayout />}>
      <Route path={path(AppRoute.admin.accounts)} element={<AdminAccountList />} />
      <Route path={path(AppRoute.admin.accountBranches.base)}>
        <Route path={path(AppRoute.admin.accountBranches.pointOfSales)} element={<AdminPointOfSaleList />} />
        <Route path="" element={<AdminAccountBranchList />} />
      </Route>
      <Route path={path(AppRoute.admin.config)} element={<AdminConfig />} />
      <Route path={path(AppRoute.admin.currencyExchangeRates)} element={<AdminCurrencyExchangeRateList />} />
      <Route path={path(AppRoute.admin.eInvoices.base)}>
        <Route path={path(AppRoute.admin.eInvoices.homologation)} element={<AdminEInvoiceHomologation />} />
        <Route path={path(AppRoute.admin.eInvoices.dailyReports)} element={<AdminEInvoiceDailyReportList />} />
        <Route path={path(AppRoute.admin.eInvoices.retentions)} element={<AdminRetentionList />} />
        <Route path={path(AppRoute.admin.eInvoices.certifiedProviderReport)} element={<AdminCertifiedProviderReportList />} />
        <Route path="" element={<AdminEInvoice />} />
      </Route>
      <Route path={path(AppRoute.admin.providers.base)}>
        <Route path={path(AppRoute.admin.providers.linkedUsers)} element={<AdminProviderLinkedUserList />} />
        <Route path="" element={<AdminProviderList />} />
      </Route>
      <Route path={AppRoute.admin.templates.base}>
        <Route path={path(AppRoute.admin.templates.email.base)}>
          <Route path={path(AppRoute.admin.templates.email.create)} element={<AdminTemplateEmailCreate />} />
          <Route path={path(AppRoute.admin.templates.email.edit) + "/:id"} element={<AdminTemplateEmailEdit />} />
          <Route path="" element={<AdminTemplateEmailList />} />
        </Route>
        <Route path={path(AppRoute.admin.templates.generic.base)}>
          <Route path={path(AppRoute.admin.templates.generic.create)} element={<AdminTemplateGenericCreate />} />
          <Route path={path(AppRoute.admin.templates.generic.edit) + "/:id"} element={<AdminTemplateGenericEdit />} />
          <Route path="" element={<AdminTemplateGenericList />} />
        </Route>
        <Route path={path(AppRoute.admin.templates.pdf.base)}>
          <Route path={path(AppRoute.admin.templates.pdf.create)} element={<AdminTemplatePdfCreate />} />
          <Route path={path(AppRoute.admin.templates.pdf.edit) + "/:id"} element={<AdminTemplatePdfEdit />} />
          <Route path="" element={<AdminTemplatePdfList />} />
        </Route>
        <Route path="" element={<AdminTemplates />} />
      </Route>
      <Route path={path(AppRoute.admin.termsAndConditions.base)}>
        <Route path={path(AppRoute.admin.termsAndConditions.create)} element={<AdminTermsAndConditionsCreate />} />
        <Route path={path(AppRoute.admin.termsAndConditions.edit) + "/:id"} element={<AdminTermsAndConditionsEdit />} />
        <Route path={path(AppRoute.admin.termsAndConditions.details) + "/:id"} element={<AdminTermsAndConditionsDetails />} />
        <Route path="" element={<AdminTermsAndConditionsList />} />
      </Route>
      <Route path={path(AppRoute.admin.icons)} element={<AdminIcons />} />
      <Route path={path(AppRoute.admin.logging)} element={<AdminLogging />} />
      <Route path={path(AppRoute.admin.longProcessLog)} element={<AdminLongProcessLogList />} />
      <Route path={path(AppRoute.admin.logViewer)} element={<AdminLoggingList />} />
      <Route path={path(AppRoute.admin.pointOfSaleClients)} element={<AdminPointOfSaleClientList />} />
      <Route path={path(AppRoute.admin.sentEmails)} element={<AdminSentEmailList />} />
      <Route path={path(AppRoute.admin.serviceBus)} element={<AdminServiceBusDashboard />} />
      <Route path={path(AppRoute.admin.shards)} element={<AdminShardList />} />
      <Route path={path(AppRoute.admin.taxValues)} element={<AdminTaxValueList />} />
      <Route path={path(AppRoute.admin.users)} element={<UserList />} />
      <Route path={path(AppRoute.admin.userLinkedAccounts())} element={<UserLinkedAccountList />} />
      <Route path={path(AppRoute.admin.userTermsAccepted)} element={<UserTermsAcceptedList />} />
      <Route path="" element={<AdminHome />} />
      <Route path="*" element={<NotFound />} />
    </Route>
    <Route path={AppRoute.adminProvider.base} element={<AdminProviderLayout />}>
      <Route path={path(AppRoute.adminProvider.accounts)} element={<AdminAccountList />} />
      <Route path={path(AppRoute.adminProvider.accountBranches.base)}>
        <Route path={path(AppRoute.adminProvider.accountBranches.pointOfSales)} element={<AdminPointOfSaleList />} />
        <Route path="" element={<AdminAccountBranchList />} />
      </Route>
      <Route path={path(AppRoute.adminProvider.config)} element={<AdminProviderConfiguration />} />
      <Route path={path(AppRoute.adminProvider.eInvoices.base)}>
        <Route path={path(AppRoute.adminProvider.eInvoices.dailyReports)} element={<AdminEInvoiceDailyReportList />} />
        <Route path={path(AppRoute.adminProvider.eInvoices.certifiedProviderReport)} element={<AdminCertifiedProviderReportList />} />
        <Route path="" element={<AdminProviderEInvoice />} />
      </Route>
      <Route path={path(AppRoute.adminProvider.sentEmails)} element={<AdminSentEmailList />} />

      <Route path={AppRoute.adminProvider.templates.base}>
        <Route path={path(AppRoute.adminProvider.templates.email.base)}>
          {/* <Route path={path(AppRoute.adminProvider.templates.email.create)} element={<AdminTemplateEmailCreate />} />
          <Route path={path(AppRoute.adminProvider.templates.email.edit) + "/:id"} element={<AdminTemplateEmailEdit />} /> */}
          <Route path="" element={<AdminTemplateEmailList />} />
        </Route>
        {/* <Route path={path(AppRoute.admin.templates.generic.base)}>
          <Route path={path(AppRoute.admin.templates.generic.create)} element={<AdminTemplateGenericCreate />} />
          <Route path={path(AppRoute.admin.templates.generic.edit) + "/:id"} element={<AdminTemplateGenericEdit />} />
          <Route path="" element={<AdminTemplateGenericList />} />
        </Route>*/}
        <Route path={path(AppRoute.admin.templates.pdf.base)}>
          {/* <Route path={path(AppRoute.admin.templates.pdf.create)} element={<AdminTemplatePdfCreate />} />
          <Route path={path(AppRoute.admin.templates.pdf.edit) + "/:id"} element={<AdminTemplatePdfEdit />} /> */}
          <Route path="" element={<AdminTemplatePdfList />} />
        </Route>
        <Route path="" element={<AdminProviderTemplates />} />
      </Route>

      <Route path={path(AppRoute.adminProvider.termsAndConditions.base)}>
        <Route path={path(AppRoute.adminProvider.termsAndConditions.create)} element={<AdminTermsAndConditionsCreate />} />
        <Route path={path(AppRoute.adminProvider.termsAndConditions.edit) + "/:id"} element={<AdminTermsAndConditionsEdit />} />
        <Route path={path(AppRoute.adminProvider.termsAndConditions.details) + "/:id"} element={<AdminTermsAndConditionsDetails />} />
        <Route path="" element={<AdminTermsAndConditionsList />} />
      </Route>
      <Route path={path(AppRoute.adminProvider.users)} element={<UserList />} />
      <Route path={path(AppRoute.adminProvider.userLinkedAccounts())} element={<UserLinkedAccountList />} />
      <Route path={path(AppRoute.adminProvider.userTermsAccepted)} element={<UserTermsAcceptedList />} />
      <Route path={""} element={<AdminProviderHome />} />
    </Route>
    <Route path={AppRoute.base} element={<Layout />}>
      <Route path={path(AppRoute.configuration.base)} element={<GuardAccountAdmin />}>
        <Route path={path(AppRoute.configuration.currencies)} element={<ConfigCurrencies />} />
        <Route path={path(AppRoute.configuration.eInvoice.base)}>
          <Route path={path(AppRoute.configuration.eInvoice.authorizations)} element={<EInvoiceAuthorizationList />} />
          <Route path={path(AppRoute.configuration.eInvoice.dailyReports)} element={<EInvoiceDailyReportList />} />
          <Route path={path(AppRoute.configuration.eInvoice.general)} element={<EInvoiceConfigGeneral />} />
          <Route path={path(AppRoute.configuration.eInvoice.vaultCertificates)} element={<VaultCertificateList />} />
        </Route>
        <Route path={path(AppRoute.configuration.email.base)}>
          <Route path={path(AppRoute.configuration.email.oAuthSuccessGmail)} element={<ConfigEmailOAuthSuccessGmail />} />
          <Route path="" element={<ConfigEmailList />} />
        </Route>
        <Route path={path(AppRoute.configuration.locale)} element={<ConfigLocale />} />
        <Route path={path(AppRoute.configuration.preferences)} element={<ConfigPreferences />} />
        <Route path={path(AppRoute.configuration.taxes)} element={<ConfigTaxes />} />
        <Route path="" element={<Configuration />} />
      </Route>
      <Route path={path(AppRoute.contacts)} element={<ContactList />} />
      <Route path={path(AppRoute.purchases.base)}>
        <Route path="" element={<PurchaseList />} />
        <Route path={path(AppRoute.purchases.eInvoicePendingApprove)} element={<EInvoicePurchasePendingList />} />
        <Route path={path(AppRoute.purchases.envelopes.base)} >
          <Route path="" element={<EInvoiceEnvelopePurchaseList />} />
        </Route>
      </Route>
      <Route path={path(AppRoute.myAccountUser)} element={<MyAccountUser />} />
      <Route path={path(AppRoute.myAdminAccount)} element={<MyAdminAccount />} />
      <Route path={path(AppRoute.products)} element={<ProductList />} />
      <Route path={path(AppRoute.reports.base)} >
        <Route path={path(AppRoute.reports.requestedExport)} element={<RequestedExportList />} />
        <Route path="" element={<ReportDashboard />} />
      </Route>
      <Route path={path(AppRoute.sales.base)}>
        <Route path={path(AppRoute.sales.create)} element={<SalePageCreate />} />
        <Route path={path(AppRoute.sales.createAdjustment)} element={<SalePageCreateAdjustment />} />
        <Route path={path(AppRoute.sales.createAdjustmentExport)} element={<SalePageCreateAdjustmentExport />} />
        <Route path={path(AppRoute.sales.createAdjustmentSenderInBehalf)} element={<SalePageCreateAdjustmentSenderInBehalf />} />
        <Route path={path(AppRoute.sales.createExport)} element={<SalePageCreateExport />} />
        <Route path={path(AppRoute.sales.createDeliveryNote)} element={<SalePageCreateDeliveryNote />} />
        <Route path={path(AppRoute.sales.createEntranceTicket)} element={<SalePageCreateEntranceTicket />} />
        <Route path={path(AppRoute.sales.createReceipt)} element={<SalePageCreateReceipt />} />
        <Route path={path(AppRoute.sales.createForSenderInBehalf)} element={<SalePageCreateSenderInBehalf />} />
        <Route path={path(AppRoute.sales.cancel)} element={<SalePageCancel />} />
        <Route path={path(AppRoute.sales.cancelExport)} element={<SalePageCancelExport />} />
        <Route path={path(AppRoute.sales.cancelSenderBehalf)} element={<SalePageCancelSenderInBehalf />} />
        <Route path={path(AppRoute.sales.clone) + "/:id"} element={<SalePageClone />} />
        <Route path={path(AppRoute.sales.draftEdit) + "/:id"} element={<SalePageDraftEdit />} />
        <Route path="" element={<SaleList />} />
      </Route>
      <Route path={path(AppRoute.templates.base)}>
        <Route path={path(AppRoute.templates.config)} element={<TemplateConfig />} />
      </Route>
      <Route path={path(AppRoute.sentEmails)} element={<SentEmailList />} />
      <Route path={path(AppRoute.stock)} element={<StockDashboard />} />
      <Route path={path(AppRoute.unauthorized)} element={<Unauthorized />} />
      <Route path={path(AppRoute.users)} element={<AccountUserList />} />
      <Route path="" element={<Home />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

export default AppRoutes;

function getQueryString(params: any): string {
  if (!params) {
    return "";
  }
  const queryString = Object.keys(params).map((key) => {
    return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
  }).join("&");
  return "?" + queryString;
}