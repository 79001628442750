import { nameof } from "ts-simple-nameof";
import { InvoiceChargeDto } from "../../api/app/dtos/InvoiceChargeDto";
import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import { InvoiceChargeType } from "../../api/shared/enums/InvoiceChargeType";
import { enumName } from "../../api/shared/enums/_enumName";
import GridClientSide from "../../shared/components/grid/GridClientSide";
import { IGridItemTemplate } from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Row from "../../shared/components/Layout/GRow";

const PurchaseDetailsSurcharges = (props: { purchase: PurchaseDto }) => {
  const templates: IGridItemTemplate<InvoiceChargeDto>[] = [
    {
      header: "Número",
      field: nameof<InvoiceChargeDto>((p) => p.order),
      width: 10,
    },
    {
      header: "Código",
      field: nameof<InvoiceChargeDto>((p) => p.code),
      width: 10,
    },
    {
      header: "Detalle",
      field: nameof<InvoiceChargeDto>((p) => p.detail),
      width: 20,
    },
    {
      header: "Monto",
      field: nameof<InvoiceChargeDto>((p) => p.amount),
      width: 20,
      render: (item: InvoiceChargeDto) => {
        return (item.isPercentage ? "% " : "") + item.amount;
      },
    },
    {
      header: "Indicador",
      field: nameof<InvoiceChargeDto>((p) => p.eInvoiceBillingIndicatorType),
      cellTypeEnumName: enumName.EInvoiceBillingIndicatorType,
      width: 10,
    },
  ];

  const charges = props.purchase.charges.filter((c) => c.type == InvoiceChargeType.Surcharge);

  return (
    <Row>
      <GridClientSide items={charges} templates={templates} />
    </Row>
  );
};

export default PurchaseDetailsSurcharges;
