import { nameof } from "ts-simple-nameof";
import { AddressDto } from "../../../api/app/dtos/AddressDto";
import { useContextModelState } from "../../modelState/useContextModelState";
import Column from "../Layout/GColumn";
import FormText from "./FormText";
import FormSelectCountry from "./Select/FormSelectCountry";
import FormSelectState from "./Select/FormSelectState";

const FormAddress = (props: { propertyName: string; columnMd?: number }) => {
  const form = useContextModelState();
  return (
    <>
      <Column md={props.columnMd ?? 12}>
        <FormSelectCountry
          label="País"
          propertyName={props.propertyName + "." + nameof<AddressDto>((p) => p.country)}
        />
      </Column>

      <Column md={props.columnMd ?? 12}>
        <FormSelectState
          country={form.getValue(props.propertyName + "." + nameof<AddressDto>((p) => p.country))}
          label="Departamento"
          propertyName={props.propertyName + "." + nameof<AddressDto>((p) => p.state)}
        />
      </Column>

      <Column md={props.columnMd ?? 12}>
        <FormText
          label="Ciudad"
          propertyName={props.propertyName + "." + nameof<AddressDto>((p) => p.city)}
        />
      </Column>

      <Column md={props.columnMd ?? 12}>
        <FormText
          label="Dirección"
          propertyName={props.propertyName + "." + nameof<AddressDto>((p) => p.addressLine)}
        />
      </Column>
    </>
  );
};

export default FormAddress;
