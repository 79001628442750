// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiState {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getForCountry(
    country: number,
    apiOptions?: ApiOptions
  ): Promise<number[]> {
    return this.apiService.apiGet("/states/by-country/" + country, apiOptions);
  }

}
