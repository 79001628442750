// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AppNotificationDto } from "../dtos/AppNotificationDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { AppNotificationSummaryDto } from "../dtos/AppNotificationSummaryDto";

export class ApiAppNotifications {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPending(
    maxCount: number,
    apiOptions?: ApiOptions
  ): Promise<AppNotificationDto[]> {
    return this.apiService.apiGet("/app-notifications/pending?" + 
      (maxCount ? "&maxCount=" + maxCount : ""), apiOptions);
  }

  getSummary(
    apiOptions?: ApiOptions
  ): Promise<AppNotificationSummaryDto> {
    return this.apiService.apiGet("/app-notifications/summary", apiOptions);
  }

  setAsRead(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AppNotificationSummaryDto> {
    return this.apiService.apiPut("/app-notifications/set-as-read/" + id, null, apiOptions);
  }

  create(
    appNotificationDto: AppNotificationDto,
    apiOptions?: ApiOptions
  ): Promise<AppNotificationDto> {
    return this.apiService.apiPost("/app-notifications/create", appNotificationDto, apiOptions);
  }

}
