import { useContext } from "react";
import { useNavigate } from "react-router";
import {
  NotificationActionType,
  NotificationsContextSet,
} from "../components/Notifications/NotificationsProvider";

export interface Navigation {
  go: (to: string, id?: string, queryParams?: any) => void;
  back: (count?: number) => void;
  newTab: (url: string, queryParams?: any) => void;
}

export const useNavigation = (): Navigation => {
  const navigate = useNavigate();
  const { notificationDispatcher } = useContext(NotificationsContextSet);

  const go = (to: string, id?: string, queryParams?: any) => {
    if (id) {
      to = to + "/" + id;
    }

    if (queryParams) {
      to = to + "?" + new URLSearchParams(queryParams).toString();
    }

    navigate(to);
    notificationDispatcher({ type: NotificationActionType.closeAll });
  };

  const back = (count?: number) => {
    navigate(count ?? -1);
  };

  const newTab = (url: string, queryParams?: any) => {
    if (queryParams) {
      url = url + "?" + new URLSearchParams(queryParams).toString();
    }

    window.open(url);
  };

  return { go, back, newTab };
};
