import { nameof } from "ts-simple-nameof";
import { UserCrudDto } from "../../api/admin/dtos/UserCrudDto";
import { ButtonAccept } from "../../shared/components/Button/ButtonAccept";
import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import FormAutocompleteAdminAccount from "../../shared/components/forms/Autocomplete/FormAutocompleteAdminAccount";
import FormSwitch from "../../shared/components/forms/FormSwitch";
import FormText from "../../shared/components/forms/FormText";
import { TypeIcon } from "../../shared/components/Icon";
import InfoText, { InfoTextType } from "../../shared/components/InfoText";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import RowDisplay from "../../shared/components/Layout/GRowDisplay";
import Section from "../../shared/components/Layout/GSection";
import { useContextModelState } from "../../shared/modelState/useContextModelState";
import { newGuid } from "../../shared/utils/utilGuid";

const UserForm = (props: { isEditing?: boolean; onSave(): void }) => {
  const modelState = useContextModelState();
  const userCrudDto = modelState.model as UserCrudDto;

  const isSuperAdminChange = (isSuperAdmin: boolean) => {
    if (!isSuperAdmin) {
      return;
    }

    modelState.updateProperty(
      nameof<UserCrudDto>((p) => p.isOpenApi),
      false
    );
    modelState.updateProperty(
      nameof<UserCrudDto>((p) => p.openAuthKey),
      null
    );
  };

  const generateAuthKey = () => {
    const authKey = newGuid();
    modelState.updateProperty(
      nameof<UserCrudDto>((p) => p.openAuthKey),
      authKey
    );
  };

  return (
    <>
      {props.isEditing && <RowDisplay label="Id">{userCrudDto.user.id}</RowDisplay>}

      <Row>
        <FormText label="Correo" propertyName={nameof<UserCrudDto>((p) => p.user.email)} />
      </Row>

      <Row>
        <FormText label="Nombre" propertyName={nameof<UserCrudDto>((p) => p.user.firstName)} />
      </Row>

      <Row>
        <FormText label="Apellido" propertyName={nameof<UserCrudDto>((p) => p.user.lastName)} />
      </Row>

      <Row minHeightIgnore={true}>
        <FormSwitch
          label="Administrador GESTA"
          propertyName={nameof<UserCrudDto>((p) => p.isSuperAdmin)}
          readOnly={props.isEditing && userCrudDto.isOpenApi}
          onValueChange={(value: boolean) => isSuperAdminChange(value)}
        />
      </Row>

      <Row minHeightIgnore={true}>
        <FormSwitch
          label="Integración OpenAPI"
          readOnly={userCrudDto.isSuperAdmin || props.isEditing}
          propertyName={nameof<UserCrudDto>((p) => p.isOpenApi)}
        />
      </Row>

      {userCrudDto.isOpenApi && (
        <>
          <Row>
            <ComponentGroup>
              <FormText
                label="OpenAPI - AuthKey"
                propertyName={nameof<UserCrudDto>((p) => p.openAuthKey)}
              />
              <ButtonIcon icon={TypeIcon.refresh} onClick={generateAuthKey} />
            </ComponentGroup>
          </Row>

          <Row>
            <InfoText type={InfoTextType.warning}>
              Al editar se debe generar un nuevo AuthKey
            </InfoText>
          </Row>
        </>
      )}

      {!props.isEditing && (
        <Row>
          <Section title="Cuenta asociada" border={true}>
            <Row>
              <FormAutocompleteAdminAccount
                label="Cuenta"
                propertyName={nameof<UserCrudDto>((p) => p.defaultAccount)}
              />
            </Row>
            <Row>
              <FormSwitch
                label="Administrador cuenta"
                propertyName={nameof<UserCrudDto>((p) => p.defaultAccountIsAdmin)}
              />
            </Row>
          </Section>
        </Row>
      )}

      <RowButtons>
        <ButtonAccept onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default UserForm;
