export const LAYOUT_DEFAULT_SPACING = 2;
export const LAYOUT_SPACING_PIXELS = 8;
export const LAYOUT_DEFAULT_BORDER_RADIUS = 8;
export const LAYOUT_HINT_HEIGHT = LAYOUT_SPACING_PIXELS * 2;
export const LAYOUT_HINT_PADDING_BOTTOM = LAYOUT_SPACING_PIXELS;
export const LAYOUT_HINT_MARGIN_BOTTOM_FIX = LAYOUT_HINT_HEIGHT - LAYOUT_HINT_PADDING_BOTTOM;
export const LAYOUT_SCROLLABLE_AREA_DEFAULT_MIN_HEIGHT = 320;
export const LAYOUT_SCROLLABLE_AREA_LARGE_MIN_HEIGHT = 580;

export type LayoutSize = 0 | 1 | 2 | 3 | 4 | 5 | 10;
