import { useEffect, useState } from "react";
import { AuthWelcomeDto } from "../../api/app/dtos/AuthWelcomeDto";
import { AuthWelcomeResponseDto } from "../../api/app/dtos/AuthWelcomeResponseDto";
import { AppRoute } from "../../AppRoutes";
import Icon, { TypeIcon } from "../../shared/components/Icon";
import { useApiAuth } from "../../shared/hooks/useApiAuth";
import { usePathQueryString } from "../../shared/hooks/usePathQueryString";
import LoginCard from "./Layout/LoginCard";
import { useLoginLayout } from "./Layout/LoginLayoutContext";
import LoginRow from "./Layout/LoginRow";
import LoginRowBottom from "./Layout/LoginRowBottom";
import LoginRowError from "./Layout/LoginRowError";
import LoginTitle from "./Layout/LoginTitle";
import LoginGoogleButton from "./GoogleLoginButton";
import LoginLink from "./Layout/LoginLink";
import { AppThemeColor } from "../../styles/color";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";

const LoginWelcome = () => {
  const pathQueryString = usePathQueryString();
  const [apiAuth] = useApiAuth();
  const loginLayout = useLoginLayout();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [authWelcomeResponseDto, setAuthWelcomeResponseDto] = useState<AuthWelcomeResponseDto>();
  const [authWelcomeDto] = useState<AuthWelcomeDto>(() => {
    const authWelcomeDto = new AuthWelcomeDto();
    authWelcomeDto.userId = pathQueryString.get("userId") ?? "";
    authWelcomeDto.emailConfirmationToken = pathQueryString.get("token") ?? "";
    return authWelcomeDto;
  });

  useEffect(() => {
    setIsLoading(true);
    const loadUser = async () => {
      try {
        const authWelcomeResponseDto = await apiAuth.welcome(authWelcomeDto, {
          preventNotifications: true,
          preventSpinner: true,
        });
        setAuthWelcomeResponseDto(authWelcomeResponseDto);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        setError(error.toString());
      }
    };

    const timer = setTimeout(() => {
      loadUser();
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const rowBottom = (
    <LoginRowBottom>
      <div>
        No sé porque estoy aquí,
        <LoginLink
          text="necesito ayuda"
          onClick={() => {
            loginLayout.navigateTo(AppRoute.login.register.base);
          }}
        />
      </div>
    </LoginRowBottom>
  );

  if (!authWelcomeResponseDto) {
    return (
      <LoginCard isLoading={isLoading}>
        <LoginTitle>Hola!</LoginTitle>
        <LoginRow>Un momento mientras cargamos la información de tu usuario...</LoginRow>
        <LoginRowError message={error} />
        {rowBottom}
      </LoginCard>
    );
  }

  return (
    <LoginCard isLoading={isLoading}>
      {authWelcomeResponseDto && (
        <>
          <LoginTitle>Hola {authWelcomeResponseDto.userFirstName}!</LoginTitle>
          <LoginRow>Este es el primer paso para activar tu cuenta.</LoginRow>
          <LoginRow marginBottom={2}>
            <ComponentGroup>
              <strong style={{ marginTop: 4 }}>{authWelcomeResponseDto.userEmail}</strong>
              <Icon type={TypeIcon.checkCircle} color={AppThemeColor.success} />
            </ComponentGroup>
          </LoginRow>

          <LoginRow>Usá tu cuenta de Google y no necesitarás recordar una contraseña!</LoginRow>

          <LoginRow marginBottom={2}>
            <LoginGoogleButton
              disabled={isLoading}
              loginValidateEmail={authWelcomeResponseDto.userEmail}
              onIsLoading={() => setIsLoading(true)}
              onError={(value) => {
                console.log("ON ERROR", value);
                setError(value);
                setIsLoading(false);
              }}
            />
          </LoginRow>

          <LoginRow>Si no, podés crear una contraseña.</LoginRow>

          <LoginRow marginBottom={1}>
            <ButtonSecondary
              fullWidth={true}
              disabled={isLoading}
              text="Ingresar contraseña"
              onClick={() => {
                loginLayout.navigateTo(
                  AppRoute.login.resetPasswordParams({
                    userId: authWelcomeResponseDto.userId,
                    token: authWelcomeResponseDto.resetPasswordToken,
                  })
                );
              }}
            />
          </LoginRow>

          <LoginRowError message={error} />
          {rowBottom}
        </>
      )}
    </LoginCard>
  );
};

export default LoginWelcome;
