import { AdminTermsAndConditionsDto } from "../../api/admin/dtos/AdminTermsAndConditionsDto";
import { AppRoute } from "../../AppRoutes";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import { AppInfoSiteType } from "../../shared/context/AppInfoProvider";
import { Navigation } from "../../shared/hooks/useNavigation";

export default function getAdminTermsAndConditionListItemMenu(
  adminTermAndConditionsDto: AdminTermsAndConditionsDto,
  appInfoSiteType: AppInfoSiteType,
  navigation: Navigation
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        navigation.go(
          appInfoSiteType == AppInfoSiteType.SuperAdmin
            ? AppRoute.admin.termsAndConditions.details
            : AppRoute.adminProvider.termsAndConditions.details,
          adminTermAndConditionsDto.id
        );
      },
    },
    {
      text: "Editar",
      icon: TypeIcon.edit,
      onClick: () => {
        navigation.go(
          appInfoSiteType == AppInfoSiteType.SuperAdmin
            ? AppRoute.admin.termsAndConditions.edit
            : AppRoute.adminProvider.termsAndConditions.edit,
          adminTermAndConditionsDto.id
        );
      },
    },
  ];
}
