import { nameof } from "ts-simple-nameof";
import { PointOfSaleDto } from "../../../../api/app/dtos/PointOfSaleDto";
import { PointOfSaleFiltersDto } from "../../../../api/app/dtos/PointOfSaleFiltersDto";
import { SuggestItemDto } from "../../../../api/shared/dtos/SuggestItemDto";
import { SuggestSearchDto } from "../../../../api/shared/dtos/SuggestSearchDto";
import { useApiSwitchPointOfSale } from "../../../../api/shared/hooks/useApiApp";
import InputAutocompleteBase, { PropsInputAutocompleteBase } from "./InputAutocompleteBase";

interface InternalPropsInputAutocompleteSwitchPointOfSale
  extends PropsInputAutocompleteBase<PointOfSaleDto> {
  adminAccountId: string;
  accountBranchId: string;
}

export type PropsInputAutocompleteSwitchPointOfSale = Omit<
  InternalPropsInputAutocompleteSwitchPointOfSale,
  "onGetEntityById"
>;

const InputAutocompleteSwitchPointOfSale = (props: PropsInputAutocompleteSwitchPointOfSale) => {
  const [apiSwitchPointOfSale] = useApiSwitchPointOfSale();

  const apiRequest = (echoId: number, term: string, isInitialLoad: boolean) => {
    const searchDto = new SuggestSearchDto<PointOfSaleFiltersDto>();
    searchDto.echoId = echoId;
    searchDto.filters = new PointOfSaleFiltersDto();
    searchDto.filters.defaultTextSearch = term;
    searchDto.filters.accountBranchId = props.accountBranchId;
    searchDto.isInitialLoad = isInitialLoad;
    searchDto.orderBy = [{ columnName: nameof<PointOfSaleDto>((p) => p.name), asc: true }];
    const apiOptions = {
      preventSpinner: true,
    };
    return apiSwitchPointOfSale.suggestPointOfSale(props.adminAccountId, searchDto, apiOptions);
  };

  return (
    <InputAutocompleteBase
      {...props}
      value={props.value}
      onGetEntityById={(id: string) =>
        apiSwitchPointOfSale.getPointOfSaleById(props.adminAccountId, id, {
          preventSpinner: true,
        })
      }
      onMapFromItemSuggest={(item: SuggestItemDto) => {
        const switchPointOfSaleDto = new PointOfSaleDto();
        switchPointOfSaleDto.id = item.id;
        switchPointOfSaleDto.name = item.label;
        return switchPointOfSaleDto;
      }}
      onServerSideSuggest={apiRequest}
    />
  );
};

export default InputAutocompleteSwitchPointOfSale;
