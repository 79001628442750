import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AdminTemplateEmailDto } from "../../../api/admin/dtos/AdminTemplateEmailDto";
import Page from "../../../shared/components/Layout/Page";
import { useApiAdminAccountPublicTemplateEmail } from "../../../api/shared/hooks/useApiAdmin";
import { useNotifier } from "../../../shared/hooks/useNotifier";
import { StateProvider } from "../../../shared/modelState/ModelStateContext";
import AdminTemplateEmailForm from "./AdminTemplateEmailForm";
import { useAdminTemplateEmailValidation } from "./Validations/useAdminEmailTemplateValidation";

type TParams = { id: string };

const AdminTemplateEmailEdit = () => {
  const { id } = useParams<TParams>();

  const [adminTemplateEmail, setAdminTemplateEmail] = useState<AdminTemplateEmailDto>();
  const [apiTemplateEmail] = useApiAdminAccountPublicTemplateEmail();
  const notifier = useNotifier();

  useEffect(() => {
    if (!id) {
      return;
    }

    const loadAdminTemplateEmail = async () => {
      setAdminTemplateEmail(await apiTemplateEmail.getById(id ?? ""));
    };
    loadAdminTemplateEmail();
  }, [id]);

  const save = async (adminTemplateEmail: AdminTemplateEmailDto) => {
    await apiTemplateEmail.edit(adminTemplateEmail);
    notifier.showSuccess("La plantilla se editó con éxito");
  };

  if (!adminTemplateEmail) {
    return <></>;
  }

  return (
    <Page title="Editar plantilla de correo">
      <StateProvider
        model={adminTemplateEmail}
        onSubmit={save}
        validationSchema={useAdminTemplateEmailValidation()}>
        {(p) => <AdminTemplateEmailForm onSave={p.handleSubmit} />}
      </StateProvider>
    </Page>
  );
};

export default AdminTemplateEmailEdit;
