import { useEffect, useState } from "react";
import { AdminAccountDto } from "../../../api/admin/dtos/AdminAccountDto";
import {
  useApiAdminDevUtils,
  useApiAdminEInvoiceUruguay,
  useApiAdminLongProcess,
} from "../../../api/shared/hooks/useApiAdmin";
import Button from "../../../shared/components/Button/Button";
import ButtonSecondary from "../../../shared/components/Button/ButtonSecondary";
import InfoText from "../../../shared/components/InfoText";
import InputAutocompleteAdminAccount from "../../../shared/components/inputs/autocomplete/InputAutocompleteAdminAccount";
import InputSwitch from "../../../shared/components/inputs/InputSwitch";
import ComponentGroup from "../../../shared/components/Layout/ComponentsGroup";
import Column from "../../../shared/components/Layout/GColumn";
import Row from "../../../shared/components/Layout/GRow";
import Section from "../../../shared/components/Layout/GSection";
import Page from "../../../shared/components/Layout/Page";
import LongProcessBar from "../../../shared/components/LongProcess/LongProcessBar";
import ModalDialog from "../../../shared/components/Modal/ModalDialog";
import Spinner from "../../../shared/components/Spinner";
import { useModal } from "../../../shared/hooks/useModal";
import { useNotifier } from "../../../shared/hooks/useNotifier";

const AdminEInvoiceHomologation = () => {
  const notifier = useNotifier();
  const [modal] = useModal();
  const [includeReceipts, setIncludeReceipts] = useState(false);
  const [apiAdminEInvoiceUruguay] = useApiAdminEInvoiceUruguay();
  const [apiAdminLongProcess] = useApiAdminLongProcess();
  const [apiAdminDevUtils] = useApiAdminDevUtils();
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [longProcessId, setLongProcessId] = useState<string>();
  const [adminAccount, setAdminAccount] = useState<AdminAccountDto | null>(null);

  const accountSalesClearAll = () => {
    const request = async () => {
      if (!adminAccount) {
        return;
      }

      await apiAdminDevUtils.salesClearAll(adminAccount.id);
      notifier.showSuccess("Ventas borradas con éxito");
    };
    request();
  };

  const accountPurchasesClearAll = () => {
    const request = async () => {
      if (!adminAccount) {
        return;
      }

      await apiAdminDevUtils.purchasesClearAll(adminAccount.id);
      notifier.showSuccess("Compras borradas con éxito");
    };
    request();
  };

  const createCertifiedProviderDataSet = () => {
    const request = async () => {
      if (!adminAccount) {
        return;
      }

      const longProcessId = await apiAdminEInvoiceUruguay.createCertifiedProviderDataSet(
        adminAccount.id
      );
      setLongProcessId(longProcessId);
    };
    request();
  };

  const createTestingDataSet = () => {
    const request = async () => {
      if (!adminAccount) {
        return;
      }

      const longProcessId = await apiAdminEInvoiceUruguay.createTestingDataSet(adminAccount.id);
      setLongProcessId(longProcessId);
    };
    request();
  };

  const createCertificationDataSet = () => {
    const request = async () => {
      if (!adminAccount) {
        return;
      }

      const longProcessId = await apiAdminEInvoiceUruguay.createCertificationDataSet(
        adminAccount.id,
        includeReceipts
      );
      setLongProcessId(longProcessId);
    };
    request();
  };

  const createSimulationDataSet = () => {
    const request = async () => {
      if (!adminAccount) {
        return;
      }

      const longProcessId = await apiAdminEInvoiceUruguay.createSimulationDataSet(
        adminAccount.id,
        includeReceipts
      );
      setLongProcessId(longProcessId);
    };
    request();
  };

  const downloadCertificationEnvelopes = () => {
    const request = async () => {
      if (!adminAccount) {
        return;
      }

      await apiAdminEInvoiceUruguay.downloadAllEnvelopes(adminAccount.id);
    };
    request();
  };

  useEffect(() => {
    if (!adminAccount) {
      return;
    }

    setLoadingStatus(true);
    const request = async () => {
      const longProcess = await apiAdminLongProcess.getByEInvoiceCreateTestingDataSetLongProcess(
        adminAccount.id
      );
      if (longProcess) {
        setLongProcessId(longProcess.processId);
      }
      setLoadingStatus(false);
    };
    request();
  }, [adminAccount]);

  return (
    <Page title="Homologación">
      <Column>
        <InputAutocompleteAdminAccount
          label="Cuenta"
          value={adminAccount}
          onChange={setAdminAccount}
        />
      </Column>
      {loadingStatus && (
        <Row>
          <Spinner />
        </Row>
      )}
      {adminAccount && !loadingStatus && (
        <>
          <Section title="Local">
            <Row>
              <ComponentGroup>
                <ButtonSecondary
                  text="Borrar todas las VENTAS de la cuenta"
                  onClick={() =>
                    modal.open(
                      <ModalDialog text="¿Seguro desea eliminar todas las ventas de la cuenta?" />,
                      accountSalesClearAll
                    )
                  }
                  disabled={!!longProcessId}
                />
                <ButtonSecondary
                  text="Borrar todas las COMPRAS de la cuenta"
                  onClick={() =>
                    modal.open(
                      <ModalDialog text="¿Seguro desea eliminar todas las compras de la cuenta?" />,
                      accountPurchasesClearAll
                    )
                  }
                  disabled={!!longProcessId}
                />
              </ComponentGroup>
            </Row>
          </Section>
          <Section title="Proveedor habilitado">
            <Row>
              <InfoText>
                Se corre una unica vez cuando se registra como proveedor habilitado ante DGI. Se
                debe cambiar la URL para enviar al endpoint de ws_certificacion.
              </InfoText>
            </Row>
            <Row>
              <Button
                text="Crear dataSet de proveedor habilitado"
                onClick={createCertifiedProviderDataSet}
              />
            </Row>
          </Section>
          <Section title="Testing">
            <Row>
              <InfoText>
                1. Se crean 50 comprobantes de los tipos mínimos. <br />
                2. Luego se tiene que generar el reporte diario. <br />
                3. Luego se tiene que procesar el reporte diario desde el portal de la DGI. <br />
                4. Se habilita el siguiente ambiente de homologación.
              </InfoText>
            </Row>
            <Row>
              <Button
                text="Crear dataSet Testing"
                onClick={createTestingDataSet}
                disabled={!!longProcessId}
              />
            </Row>
          </Section>
          <Section title="Homologación">
            <Row>
              <InfoText>
                <strong>Testing data set</strong>
                <br />
                1. Crear el data set con el sender de la cuenta para que los comprobantes sean
                emitidos y validados en DGI <br />
                2. Con estos comprobantes <strong>validados</strong> por DGI se puede generar el
                reporte diario. <br />
                3. Descargar los comprobantes y cargarlos en el sitio de Homologación de DGI. <br />
                <strong>Simulación</strong> <br />
                1. Crear data set de simulación <br />
                2. Generar el reporte luego que los comprobantes estén validados por DGI. <br />
                <strong>Intercambio</strong> <br />
                1. Desde el XML, borrar indicador de facturación del eRemito A 1. <br />
                2. Al cargar el sobre, rechazar el eResguardo A 2. <br />
              </InfoText>
            </Row>
            <Row>
              <InputSwitch
                label="Incluir eResguardos"
                value={includeReceipts}
                onChange={(value) => setIncludeReceipts(value ?? false)}
              />
            </Row>
            <Row>
              <ComponentGroup>
                <Button
                  text="Crear data set Homologación"
                  onClick={createCertificationDataSet}
                  disabled={!!longProcessId}
                />

                <Button
                  text="Crear data set Simulación"
                  onClick={createSimulationDataSet}
                  disabled={!!longProcessId}
                />

                <Button
                  text="Descargar sobres"
                  onClick={() => downloadCertificationEnvelopes()}
                  disabled={!!longProcessId}
                />
              </ComponentGroup>
            </Row>
            <Row>
              <LongProcessBar
                processId={longProcessId}
                onCancel={() => {
                  setLongProcessId(undefined);
                  notifier.showWarning("Proceso cancelado");
                }}
                onError={(errorMessage: string) => {
                  setLongProcessId(undefined);
                  notifier.showErrorMessage(errorMessage);
                }}
                onSuccess={() => {
                  setLongProcessId(undefined);
                  notifier.showSuccess("Comprobantes creados con éxito. TODO: Reporte diario");
                }}
              />
            </Row>
          </Section>
        </>
      )}
    </Page>
  );
};

export default AdminEInvoiceHomologation;
