import { forwardRef } from "react";
import { Tax } from "../../../../api/shared/enums/Tax";
import InputSelectTax, { PropsInputSelectTax } from "../..//inputs/select/InputSelectTax";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

interface PropsFormSelectTax
  extends PropsFormInput<Tax | null>,
    Omit<PropsInputSelectTax, "value" | "onChange"> {}

const FormSelectTax = forwardRef((props: PropsFormSelectTax, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputSelectTax {...props} {...inputProps} />;
});

export default FormSelectTax;
