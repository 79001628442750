// File generated by Gesta.Build.PostEvents
import { ProductSimpleDto } from "./ProductSimpleDto";
import { ProductInfoDto } from "./ProductInfoDto";
import { ProductType } from "../../shared/enums/ProductType";

export class ProductDto extends ProductSimpleDto {
  info: ProductInfoDto;
  isForPurchase: boolean = false;
  isForSale: boolean = false;
  type: ProductType;
}
