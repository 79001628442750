import { useState } from "react";
import { AdminTemplateDto } from "../../../api/admin/dtos/AdminTemplateDto";
import { AdminTemplateGenericDto } from "../../../api/admin/dtos/AdminTemplateGenericDto";
import { AppRoute } from "../../../AppRoutes";
import Page from "../../../shared/components/Layout/Page";
import { useApiAdminAccountPublicTemplateGeneric } from "../../../api/shared/hooks/useApiAdmin";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import { useNotifier } from "../../../shared/hooks/useNotifier";
import { StateProvider } from "../../../shared/modelState/ModelStateContext";
import AdminTemplateCreateFirstStep from "../AdminTemplate/AdminTemplateCreateFirstStep";
import AdminTemplateGenericForm from "./AdminTemplateGenericForm";
import { useAdminTemplateGenericValidation } from "./Validations/useAdminTemplateGenericValidation";

const AdminTemplateGenericCreate = () => {
  const navigation = useNavigation();
  const notifier = useNotifier();
  const [adminTemplateGeneric, setAdminTemplateGeneric] = useState(new AdminTemplateGenericDto());
  const [apiTemplateGeneric] = useApiAdminAccountPublicTemplateGeneric();
  const [displayFirstPage, setDisplayFirstPage] = useState(true);

  const save = async (adminTemplateGeneric: AdminTemplateGenericDto) => {
    await apiTemplateGeneric.create(adminTemplateGeneric);
    notifier.showSuccess("La plantilla genérica se creó con éxito");
    navigation.go(AppRoute.admin.templates.generic.base);
  };

  return (
    <Page title="Crear plantilla genérica">
      {displayFirstPage && (
        <AdminTemplateCreateFirstStep
          adminTemplate={adminTemplateGeneric}
          onContinue={(adminTemplate: AdminTemplateDto) => {
            setAdminTemplateGeneric(adminTemplate as AdminTemplateGenericDto);
            setDisplayFirstPage(false);
          }}
        />
      )}
      {!displayFirstPage && (
        <StateProvider
          model={adminTemplateGeneric}
          onSubmit={save}
          validationSchema={useAdminTemplateGenericValidation()}>
          {(p) => <AdminTemplateGenericForm onSave={p.handleSubmit} />}
        </StateProvider>
      )}
    </Page>
  );
};

export default AdminTemplateGenericCreate;
