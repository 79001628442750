import ValidationFieldSchema from "../schema/ValidationFieldSchema";
import ValidationSchema from "../schema/ValidationSchema";
import ValidationContextSchema from "./ValidationContextSchema";
import ValidationFieldsTrack from "./ValidationFieldsTrack";
import ValidationResultSchema from "./ValidationResultSchema";

export default class ValidationContextRoot extends ValidationContextSchema {
  constructor(rootSchema: ValidationSchema, model: any, validationFieldsTrack: ValidationFieldsTrack) {
    super(undefined, "", undefined, new ValidationFieldSchema(rootSchema, ""), validationFieldsTrack, model);
  }

  getValidationResult(trySubmitCount: number): ValidationResultSchema {
    return super.getValidationResult(trySubmitCount) as ValidationResultSchema;
  }
}
