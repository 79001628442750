import { nameof } from "ts-simple-nameof";
import { EInvoiceEnvelopeStatusResponseDto } from "../../api/app/dtos/EInvoiceEnvelopeStatusResponseDto";
import { enumName } from "../../api/shared/enums/_enumName";
import { GridColumnAlign } from "../../shared/components/grid/Grid";
import GridClientSide from "../../shared/components/grid/GridClientSide";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";

const SaleDetailsPopupEInvoiceEInvoiceEnvelopeStatusResponseBase = (props: {
  items: EInvoiceEnvelopeStatusResponseDto[];
}) => {
  const itemTemplates: IGridItemTemplate<EInvoiceEnvelopeStatusResponseDto>[] = [
    {
      header: "Fecha",
      field: nameof<EInvoiceEnvelopeStatusResponseDto>((p) => p.date),
      cellType: GridCellType.dateTime,
      width: 10,
    },
    {
      header: "Tipo",
      field: nameof<EInvoiceEnvelopeStatusResponseDto>((p) => p.type),
      cellType: GridCellType.enum,
      cellTypeEnumName: enumName.EInvoiceEnvelopeStatusResponseType,
      width: 10,
    },
    {
      header: "Notas",
      field: nameof<EInvoiceEnvelopeStatusResponseDto>((p) => p.notes),
      width: 30,
    },
    {
      header: "Errores",
      field: nameof<EInvoiceEnvelopeStatusResponseDto>((p) => p.errorMessage),
      width: 30,
    },
    {
      header: "Xml",
      field: nameof<EInvoiceEnvelopeStatusResponseDto>((p) => p.xmlUrl),
      align: GridColumnAlign.center,
      cellType: GridCellType.download,
      width: 5,
    },
    {
      header: "",
      field: nameof<EInvoiceEnvelopeStatusResponseDto>((p) => p.wsRequestLogUrl),
      align: GridColumnAlign.center,
      cellType: GridCellType.download,
      width: 5,
    },
    {
      header: "",
      field: nameof<EInvoiceEnvelopeStatusResponseDto>((p) => p.wsResponseLogUrl),
      align: GridColumnAlign.center,
      cellType: GridCellType.download,
      width: 5,
    },
  ];

  return <GridClientSide templates={itemTemplates} items={props.items} />;
};

export default SaleDetailsPopupEInvoiceEInvoiceEnvelopeStatusResponseBase;
