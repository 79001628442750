import { CSSProperties } from "react";
import { useAppTheme } from "../../AppThemeProvider";
import { AppThemeColor } from "../../styles/color";
import { AppSize } from "../AppSize";

const Spinner = (props: { size?: AppSize; style?: CSSProperties; color?: AppThemeColor }) => {
  const appTheme = useAppTheme();

  const combinedStyle: React.CSSProperties = {
    width: props.size ?? AppSize.medium,
    height: props.size ?? AppSize.medium,
    color: appTheme.toHexColor(props.color ?? AppThemeColor.primary),
    ...props.style,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        margin: "auto",
        background: "none",
        display: "block",
        shapeRendering: "auto",
        ...combinedStyle,
      }}
      width={props.size ?? AppSize.medium}
      height={props.size ?? AppSize.medium}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
      <circle
        cx="50"
        cy="50"
        r="32"
        strokeWidth="11"
        stroke="currentColor"
        strokeDasharray="50.26548245743669 50.26548245743669"
        fill="none"
        strokeLinecap="round">
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1.2048192771084336s"
          keyTimes="0;1"
          values="0 50 50;360 50 50"
        />
      </circle>
    </svg>
  );
};

export default Spinner;
