import { useState } from "react";
import { AdminTemplateDto } from "../../../api/admin/dtos/AdminTemplateDto";
import { AdminTemplateEmailDto } from "../../../api/admin/dtos/AdminTemplateEmailDto";
import { AppRoute } from "../../../AppRoutes";
import Page from "../../../shared/components/Layout/Page";
import { useApiAdminAccountPublicTemplateEmail } from "../../../api/shared/hooks/useApiAdmin";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import { useNotifier } from "../../../shared/hooks/useNotifier";
import { StateProvider } from "../../../shared/modelState/ModelStateContext";
import AdminTemplateCreateFirstStep from "../AdminTemplate/AdminTemplateCreateFirstStep";
import AdminTemplateEmailForm from "./AdminTemplateEmailForm";
import { useAdminTemplateEmailValidation } from "./Validations/useAdminEmailTemplateValidation";

const AdminTemplateEmailCreate = () => {
  const navigation = useNavigation();
  const notifier = useNotifier();

  const [adminTemplateEmail, setAdminTemplateEmail] = useState(new AdminTemplateEmailDto());
  const [apiTemplateEmail] = useApiAdminAccountPublicTemplateEmail();
  const [displayFirstPage, setDisplayFirstPage] = useState(true);

  const save = async (adminTemplateEmail: AdminTemplateEmailDto) => {
    await apiTemplateEmail.create(adminTemplateEmail);
    notifier.showSuccess("La plantilla de correo se creó con éxito");
    navigation.go(AppRoute.admin.templates.email.base);
  };

  return (
    <Page title="Crear plantilla de correo">
      {displayFirstPage && (
        <AdminTemplateCreateFirstStep
          adminTemplate={adminTemplateEmail}
          onContinue={(adminTemplate: AdminTemplateDto) => {
            setAdminTemplateEmail(adminTemplate as AdminTemplateEmailDto);
            setDisplayFirstPage(false);
          }}
        />
      )}
      {!displayFirstPage && (
        <StateProvider
          model={adminTemplateEmail}
          onSubmit={save}
          validationSchema={useAdminTemplateEmailValidation()}>
          {(p) => <AdminTemplateEmailForm onSave={p.handleSubmit} />}
        </StateProvider>
      )}
    </Page>
  );
};

export default AdminTemplateEmailCreate;
