import { useContext } from "react";
import { GridSettingsDto } from "../../../api/app/dtos/GridSettingsDto";
import { FiltersDto } from "../../../api/shared/dtos/FiltersDto";
import { PagedResultDto } from "../../../api/shared/dtos/PagedResultDto";
import { SearchResultItemDto } from "../../../api/shared/dtos/SearchResultItemDto";
import { SearchOrderDto } from "../../../api/shared/dtos/SearchOrderDto";
import { GridActionType, GridContext, IGridState } from "./GridContext";
import { IGridItemTemplate } from "./ItemTemplate/IGridItemTemplate";
import { GridMode } from "./PropsGrid";

export interface IGridContext<T> {
  gridState: IGridState<T>;
  clientSideRefresh: (pagedResult: PagedResultDto<any>) => void;
  changePage: (page: number) => void;
  filtersAllCollapse: () => void;
  filtersAllExpand: () => void;
  filtersPendingApply: (value: boolean) => void;
  includeInactiveUpdate: (value: boolean) => void;
  init: (
    mode: GridMode,
    defaultSortBy: SearchOrderDto[],
    settings: GridSettingsDto,
    initialFilters: FiltersDto,
    columnTemplates: IGridItemTemplate<any>[],
    mustIncludeFields: string[] | undefined,
    selectedItems: any[],
    isCrossShard: boolean | undefined
  ) => void;
  orderByAdd: (columnName: string) => void;
  orderByChange: (columnName: string, ascendent?: boolean) => void;
  orderByRemove: (columnName: string) => void;
  orderByToggleAsc: (columnName: string, ascendent?: boolean) => void;
  refresh: () => void;
  selectionNotificationDone: () => void;
  selectAll: () => void;
  selectItem: (item: SearchResultItemDto<any>) => void;
  setLoadingError: (message: string) => void;
  settingsUpdate: () => void;
  unselectAll: () => void;
  unselectItem: (item: SearchResultItemDto<any>) => void;
  updateCrossShardId: (crossShardId: string) => void;
  updateFilters: (filters?: FiltersDto) => void;
  updateFiltersProperties: (properties: { propertyName: string; value: any }[]) => void;
  updateFiltersProperty: (propertyName: string, value: any) => void;
  updatePage: (pagedResult: PagedResultDto<any>, validationTokenId: number) => void;
  updateValidationToken: (validationTokenId: number) => void;
}

export function useContextGrid(): IGridContext<any> {
  const { gridState, dispatchGrid } = useContext(GridContext);

  const init = (
    mode: GridMode,
    defaultSortBy: SearchOrderDto[],
    settings: GridSettingsDto,
    initialFilters: FiltersDto,
    columnTemplates: IGridItemTemplate<any>[],
    mustIncludeFields: string[] | undefined,
    selectedItems: any[],
    isCrossShard: boolean | undefined
  ) => {
    dispatchGrid({
      type: GridActionType.init,
      mode: mode,
      orderBy: defaultSortBy,
      settings: settings,
      filters: initialFilters,
      columnTemplates: columnTemplates,
      mustIncludeFields: mustIncludeFields,
      selectedItems: selectedItems,
      isCrossShard: isCrossShard,
    });
  };

  const setLoadingError = (message: string) => {
    dispatchGrid({
      type: GridActionType.setLoadingError,
      errorMessage: message,
    });
  };

  const updateValidationToken = (validationTokenId: number) => {
    dispatchGrid({
      type: GridActionType.updateValidationTokenId,
      validationTokenId: validationTokenId,
    });
  };

  const updatePage = (pagedResult: PagedResultDto<any>, validationTokenId: number) => {
    dispatchGrid({
      type: GridActionType.updatePage,
      pagedResult: pagedResult,
      validationTokenId: validationTokenId,
    });
  };

  const updateFilters = (filters?: FiltersDto) => {
    dispatchGrid({
      type: GridActionType.updateFilters,
      filters: filters ?? new FiltersDto(),
    });
  };

  const updateFiltersProperties = (properties: { propertyName: string; value: any }[]) => {
    const newFilters = { ...gridState.searchDto.filters };
    for (const property of properties) {
      newFilters[property.propertyName] = property.value;
    }

    dispatchGrid({
      type: GridActionType.updateFilters,
      filters: newFilters,
    });
  };

  const updateFiltersProperty = (propertyName: string, value: any) => {
    updateFiltersProperties([{ propertyName: propertyName, value: value }]);
  };

  const clientSideRefresh = (pagedResult: PagedResultDto<any>) => {
    dispatchGrid({
      type: GridActionType.clientSideRefresh,
      clientSidePagedResult: pagedResult,
    });
  };

  const selectionNotificationDone = () => {
    dispatchGrid({ type: GridActionType.selectionNotificationDone });
  };

  const updateCrossShardId = (crossShardId: string) => {
    dispatchGrid({ type: GridActionType.updateCrossShardId, crossShardId: crossShardId });
  };

  const unselectItem = (item: SearchResultItemDto<any>) => {
    dispatchGrid({
      type: GridActionType.unselectItem,
      resultItem: item,
    });
  };

  const selectItem = (item: SearchResultItemDto<any>) => {
    dispatchGrid({
      type: GridActionType.selectItem,
      resultItem: item,
    });
  };

  const filtersAllCollapse = () => {
    dispatchGrid({ type: GridActionType.filtersAllCollapse });
  };

  const filtersAllExpand = () => {
    dispatchGrid({ type: GridActionType.filtersAllExpand });
  };

  const orderByChange = (columnName: string, ascendent?: boolean) => {
    dispatchGrid({
      type: GridActionType.orderByChange,
      columnName: columnName,
      ascendent: ascendent,
    });
  };

  const orderByToggleAsc = (columnName: string, ascendent?: boolean) => {
    dispatchGrid({
      type: GridActionType.orderByToggleAsc,
      columnName: columnName,
      ascendent: ascendent,
    });
  };

  const filtersPendingApply = (value: boolean) => {
    dispatchGrid({ type: GridActionType.filtersPendingApplySet, value: value });
  };

  const refresh = () => {
    dispatchGrid({
      type: GridActionType.refresh,
    });
  };

  const orderByAdd = (columnName: string) => {
    dispatchGrid({
      type: GridActionType.orderByAdd,
      columnName: columnName,
    });
  };

  const orderByRemove = (columnName: string) => {
    dispatchGrid({
      type: GridActionType.orderByRemove,
      columnName: columnName,
    });
  };

  const unselectAll = () => {
    dispatchGrid({ type: GridActionType.unselectAll });
  };

  const selectAll = () => {
    dispatchGrid({ type: GridActionType.selectAll });
  };

  const includeInactiveUpdate = (value: boolean) => {
    dispatchGrid({
      type: GridActionType.includeInactiveUpdate,
      value: value,
    });
  };

  const changePage = (page: number) => {
    dispatchGrid({
      type: GridActionType.changePage,
      page: page,
    });
  };

  const settingsUpdate = () => {
    dispatchGrid({
      type: GridActionType.settingsUpdate,
    });
  };

  return {
    gridState,
    clientSideRefresh,
    changePage,
    filtersAllCollapse,
    filtersAllExpand,
    filtersPendingApply,
    includeInactiveUpdate,
    init,
    orderByAdd,
    orderByChange,
    orderByRemove,
    orderByToggleAsc,
    refresh,
    selectionNotificationDone,
    selectAll,
    selectItem,
    setLoadingError,
    settingsUpdate,
    unselectAll,
    unselectItem,
    updateCrossShardId,
    updateFilters,
    updateFiltersProperties,
    updateFiltersProperty,
    updatePage,
    updateValidationToken,
  };
}
