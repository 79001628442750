// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { IdCardType } from "../../shared/enums/IdCardType";
import { Country } from "../../shared/enums/Country";

export class SaleSenderBehalfDto extends BaseEntityDto {
  idCardType: IdCardType;
  country: Country;
  idCardNumber: string = "";
  name: string = "";
}
