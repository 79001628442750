import { useEffect } from "react";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import SaleMoreInfoPopupForm from "./SaleMoreInfoPopup.Form";
import { SaleViewModel } from "./ViewModels/SaleViewModel";

const SaleMoreInfoPopup = (props: { saleViewModel: SaleViewModel }) => {
  const [modal] = useModal();
  useEffect(() => {
    modal.setVisible("Más info", ModalSize.normal, { preventCloseOnOutsideClick: true });
  }, []);

  const accept = (saleViewModel: SaleViewModel) => {
    modal.closeAsSuccess(saleViewModel, "");
  };

  return (
    <Container>
      <StateProvider model={props.saleViewModel} onSubmit={accept}>
        {(p) => <SaleMoreInfoPopupForm onSubmit={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default SaleMoreInfoPopup;
