import { useEffect, useState } from "react";
import { ContactDto } from "../../api/app/dtos/ContactDto";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiContact } from "../../api/shared/hooks/useApiApp";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import ContactForm from "./Contact.Form";
import { useContactValidation } from "./Validations/useContactValidation";

const ContactEditPopup = (props: { id: string }) => {
  const [contact, setContact] = useState<ContactDto>();
  const [modal] = useModal();
  const [apiContact] = useApiContact();
  const { loginInfo } = useContextLoginInfo();

  useEffect(() => {
    const loadContact = async () => {
      setContact(await apiContact.getById(props.id));
      modal.setVisible("Editar contacto", ModalSize.small);
    };
    loadContact();
  }, []);

  const save = async (contact: ContactDto) => {
    const created = await apiContact.edit(contact);
    modal.closeAsSuccess(created, "El contacto se editó con éxito");
  };

  if (!contact) {
    return null;
  }

  return (
    <Container>
      <StateProvider
        model={contact}
        validationSchema={useContactValidation(loginInfo)}
        onSubmit={save}>
        {(p) => <ContactForm onSubmit={p.handleSubmit} isEdit={true} />}
      </StateProvider>
    </Container>
  );
};

export default ContactEditPopup;
