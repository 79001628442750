import { useEffect, useState } from "react";
import { VaultCertificateDto } from "../../../api/app/dtos/VaultCertificateDto";
import { VaultCertificateInstallDto } from "../../../api/app/dtos/VaultCertificateInstallDto";
import Container from "../../../shared/components/Layout/GContainer";
import { ModalSize } from "../../../shared/context/ModalProvider";
import { useModal } from "../../../shared/hooks/useModal";
import VaultCertificateInstallPopupConfirm from "./VaultCertificateInstallPopup.Confirm";
import VaultCertificateInstallPopupSelectFile from "./VaultCertificateInstallPopup.SelectFile";

const VaultCertificateInstallPopup = () => {
  const [modal] = useModal();
  const [vaultCertificateDto, setVaultCertificateDto] = useState<VaultCertificateDto>();
  const [vaultCertificateInstallDto, setVaultCertificateInstallDto] =
    useState<VaultCertificateInstallDto>();

  useEffect(() => {
    modal.setVisible("Instalar certificado digital", ModalSize.small);
  }, [modal]);

  return (
    <Container>
      {!vaultCertificateDto && (
        <VaultCertificateInstallPopupSelectFile
          onInfoLoaded={(vaultCertificate, vaultCertificateInstall) => {
            setVaultCertificateDto(vaultCertificate);
            setVaultCertificateInstallDto(vaultCertificateInstall);
          }}
        />
      )}
      {vaultCertificateDto && vaultCertificateInstallDto && (
        <VaultCertificateInstallPopupConfirm
          vaultCertificateDto={vaultCertificateDto}
          vaultCertificateInstallDto={vaultCertificateInstallDto}
          onInstallSuccess={() => {
            modal.closeAsSuccess(null, "El certificado se instaló con éxito");
          }}
        />
      )}
    </Container>
  );
};

export default VaultCertificateInstallPopup;
