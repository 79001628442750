import { forwardRef } from "react";
import InputText, { PropsInputText } from "../inputs/InputText";
import { PropsFormInput, useFormInputInit } from "./_formInputProps";

export interface PropsFormText
  extends PropsFormInput<string | null>,
    Omit<PropsInputText, "value" | "onChange"> {}

const FormText = forwardRef((props: PropsFormText, ref: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputText {...props} {...inputProps} />;
});

export default FormText;
