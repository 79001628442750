import { ThemeProvider } from "@mui/material/styles";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { AppTermsAndConditionsDto } from "../../api/app/dtos/AppTermsAndConditionsDto";
import { useApiUserTermsAndConditions } from "../../api/shared/hooks/useApiApp";
import { loginMobileCreateThemeForAdminProvider } from "../../AppThemeLogin";
import { useAppTheme } from "../../AppThemeProvider";
import AdminProviderLogoLanding from "../components/AdminProviderLogoLanding";
import Button from "../components/Button/Button";
import ButtonLink from "../components/Button/ButtonLink";
import InputSwitch from "../components/inputs/InputSwitch";
import NotificationArea from "../components/Notifications/NotificationArea";
import { useContextAppInfo } from "../hooks/useContextAppInfo";
import { useContextLoginInfo } from "../hooks/useContextLoginInfo";
import { useIsMobile } from "../hooks/useIsMobile";
import { TextAlign } from "../TextAlign";
import { ThemeVariant } from "../ThemeVariant";

const NotifyChanges = (props: {
  userTermsAndConditions: AppTermsAndConditionsDto;
  onSkip: () => void;
  onReadTerms: () => void;
}) => {
  const isMobile = useIsMobile();
  const mandatoryDate = parseISO(props.userTermsAndConditions.mandatorySince as any);

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column", gap: 15 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          //backgroundColor: isMobile ? AppThemeColor.white : undefined,
          borderRadius: isMobile ? 5 : undefined,
        }}>
        <iframe
          srcDoc={props.userTermsAndConditions.notificationChanges}
          className="terms-and-conditions"
          style={{ flex: 1, border: "none" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          //color: AppThemeColor.black,
          gap: 15,
        }}>
        <div style={{ textAlign: TextAlign.left }}>
          {mandatoryDate > new Date() && (
            <>
              Tenés tiempo hasta el <strong>{format(mandatoryDate, "dd/MM/yyyy")}</strong>,
              <ButtonLink text="ver luego" onClick={props.onSkip} />
            </>
          )}
        </div>
        <div style={{ flex: 1, textAlign: isMobile ? TextAlign.center : TextAlign.right }}>
          <Button
            text={isMobile ? "Ver términos" : "Ver los términos y condiciones"}
            onClick={props.onReadTerms}
          />
        </div>
      </div>
    </div>
  );
};

const TermsPanel = (props: {
  userTermsAndConditions: AppTermsAndConditionsDto;
  onAccept: () => void;
  onSkip: () => void;
}) => {
  const isMobile = useIsMobile();
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(true);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const mandatoryDate = parseISO(props.userTermsAndConditions.mandatorySince as any);

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column", height: "100%", gap: 15 }}>
      <div style={{ display: "flex", flex: 1, flexDirection: "column", gap: 15 }}>
        <iframe
          srcDoc={
            showPrivacyPolicy
              ? props.userTermsAndConditions.privacyBody
              : props.userTermsAndConditions.termsAndConditionsBody
          }
          className="terms-and-conditions"
          style={{
            flex: 1,
            border: "none",
            //backgroundColor: isMobile ? AppThemeColor.white : undefined,
            borderRadius: isMobile ? 5 : undefined,
          }}
        />
        <div style={{ textAlign: "left" }}>
          {showPrivacyPolicy && (
            <ButtonLink
              text="Ver términos y condiciones de uso"
              onClick={() => setShowPrivacyPolicy(false)}
            />
          )}
          {!showPrivacyPolicy && (
            <ButtonLink
              text="Ver política de privacidad"
              onClick={() => setShowPrivacyPolicy(true)}
            />
          )}
        </div>
      </div>

      <div style={{ display: "flex", gap: 30, flexDirection: isMobile ? "column" : "row" }}>
        <div style={{ flex: 1, textAlign: "left" }}>
          <InputSwitch
            value={privacyPolicyAccepted}
            onChange={(value) => setPrivacyPolicyAccepted(value ?? false)}
            label={
              isMobile
                ? "Acepto la política de privacidad"
                : "He leído y acepto la política de privacidad"
            }
          />
        </div>
        <div style={{ textAlign: TextAlign.right }}>
          <Button
            text={isMobile ? "Acepto" : "He leído y acepto los términos y condiciones de uso"}
            onClick={props.onAccept}
            disabled={!privacyPolicyAccepted}
          />
        </div>
      </div>
      {props.userTermsAndConditions.isPreviousVersionAccepted && mandatoryDate > new Date() && (
        <div style={{ textAlign: "right" }}>
          <ButtonLink text="Omitir por ahora" onClick={props.onSkip} />
        </div>
      )}
    </div>
  );
};

const TermsLayout = () => {
  const isMobile = useIsMobile();
  const [userTermsAndConditions, setUserTermsAndConditions] = useState<AppTermsAndConditionsDto>();
  const [apiUserTermsAndConditions] = useApiUserTermsAndConditions();
  const [showNotifyChanges, setShowNotifyChanges] = useState(false);
  const contextLoginInfo = useContextLoginInfo();

  const accept = async () => {
    await apiUserTermsAndConditions.accept();
    contextLoginInfo.acceptTermsAndConditions();
  };

  const read = () => {
    setShowNotifyChanges(false);
  };

  const skip = () => {
    contextLoginInfo.acceptTermsAndConditions();
  };

  useEffect(() => {
    const load = async () => {
      const version = await apiUserTermsAndConditions.notifyVersion();
      setUserTermsAndConditions(version);
      if (version?.isPreviousVersionAccepted) {
        setShowNotifyChanges(true);
      }
    };
    load();
  }, []);

  if (!userTermsAndConditions) {
    return <></>;
  }

  const loginCardStyle = !isMobile
    ? { width: "90%", maxWidth: "none", height: "80%" }
    : { width: "100%" };

  return (
    <div className="login-layout">
      {!isMobile && (
        <div className="login-layout-left" style={{ width: !isMobile ? "34%" : "100%" }}>
          <AdminProviderLogoLanding />
        </div>
      )}
      <div className="login-layout-right" style={{ width: !isMobile ? "66%" : "100%" }}>
        <div className="login-layout-card" style={loginCardStyle}>
          {showNotifyChanges && (
            <NotifyChanges
              userTermsAndConditions={userTermsAndConditions}
              onReadTerms={read}
              onSkip={skip}
            />
          )}
          {!showNotifyChanges && (
            <TermsPanel
              userTermsAndConditions={userTermsAndConditions}
              onAccept={accept}
              onSkip={skip}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const UserTermsAndConditions = () => {
  const isMobile = useIsMobile();
  const { appInfo, appInfoChangeThemeVariant } = useContextAppInfo();
  const appTheme = useAppTheme();

  useEffect(() => {
    if (isMobile && appInfo?.muiVariant != ThemeVariant.outlined) {
      appInfoChangeThemeVariant(ThemeVariant.outlined);
    }
  }, [isMobile]);

  if (!appInfo) {
    return <></>;
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <>
        <NotificationArea />
        {!isMobile && <TermsLayout />}
        {isMobile && (
          <ThemeProvider theme={loginMobileCreateThemeForAdminProvider(appTheme.appThemePalette)}>
            <TermsLayout />
          </ThemeProvider>
        )}
      </>
    </div>
  );
};

export default UserTermsAndConditions;
