import { UserLinkedAccountDto } from "../../api/admin/dtos/UserLinkedAccountDto";
import { useAppTheme } from "../../AppThemeProvider";
import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import { TypeIcon } from "../../shared/components/Icon";
import Image from "../../shared/components/Img";
import { AppThemeColor } from "../../styles/color";

interface PropsUserLinkedAccountItem {
  userLinkedAccount: UserLinkedAccountDto;
  readOnly?: boolean;
  onDelete?(): void;
  onDeleteUndo?(): void;
}

const UserLinkedAccountItem = (props: PropsUserLinkedAccountItem) => {
  const appTheme = useAppTheme();
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        borderRadius: 6,
        padding: 5,
        border: "1px solid " + AppThemeColor.gray,
        backgroundColor: appTheme.toHexColor(
          props.userLinkedAccount.inactive ? AppThemeColor.gray : undefined
        ),
      }}>
      <Image src={props.userLinkedAccount.account.logoSmallUrl} />
      <div style={{ flex: 1, paddingTop: 5, fontSize: "1.4em" }}>
        {props.userLinkedAccount.account.name}
      </div>
      {!props.readOnly && (
        <>
          {!props.userLinkedAccount.inactive && (
            <ButtonIcon
              icon={TypeIcon.delete}
              onClick={() => {
                if (props.onDelete) {
                  props.onDelete();
                }
              }}
            />
          )}
          {props.userLinkedAccount.inactive && (
            <ButtonIcon
              icon={TypeIcon.undo}
              onClick={() => {
                if (props.onDeleteUndo) {
                  props.onDeleteUndo();
                }
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UserLinkedAccountItem;
