import { nameof } from "ts-simple-nameof";
import { AdminAccountDto } from "../../../../api/admin/dtos/AdminAccountDto";
import { UserLinkedAccountDto } from "../../../../api/admin/dtos/UserLinkedAccountDto";
import { UserLinkedAccountFiltersDto } from "../../../../api/admin/dtos/UserLinkedAccountFiltersDto";
import { AdminAccountSimpleDto } from "../../../../api/shared/dtos/AdminAccountSimpleDto";
import { SuggestItemDto } from "../../../../api/shared/dtos/SuggestItemDto";
import { SuggestSearchDto } from "../../../../api/shared/dtos/SuggestSearchDto";
import { useApiAdminSwitchPointOfSale } from "../../../../api/shared/hooks/useApiAdmin";
import InputAutocompleteBase, { PropsInputAutocompleteBase } from "./InputAutocompleteBase";

export type PropsInputAutocompleteSwitchAdminAccount = Omit<
  PropsInputAutocompleteBase<AdminAccountDto | null>,
  "onGetEntityById"
>;

const InputAutocompleteSwitchAdminAccount = (props: PropsInputAutocompleteSwitchAdminAccount) => {
  const [apiAdminSwitchAccount] = useApiAdminSwitchPointOfSale();

  const apiRequest = (echoId: number, term: string, isInitialLoad: boolean) => {
    const searchDto = new SuggestSearchDto<UserLinkedAccountFiltersDto>();
    searchDto.echoId = echoId;
    searchDto.filters = new UserLinkedAccountFiltersDto();
    searchDto.filters.defaultTextSearch = term;
    searchDto.isInitialLoad = isInitialLoad;
    searchDto.orderBy = [
      { columnName: nameof<UserLinkedAccountDto>((p) => p.account.name), asc: true },
    ];
    const apiOptions = {
      preventSpinner: true,
    };

    return apiAdminSwitchAccount.suggestLinkedAccount(searchDto, apiOptions);
  };

  return (
    <InputAutocompleteBase
      {...props}
      value={props.value}
      inputTextValue={props.inputTextValue ?? props.value?.name ?? ""}
      onGetEntityById={(id: string) =>
        apiAdminSwitchAccount.getAdminAccount(id, { preventSpinner: true })
      }
      onServerSideSuggest={apiRequest}
      onMapFromItemSuggest={(item: SuggestItemDto) => {
        const adminAccountSimpleDto = new AdminAccountSimpleDto();
        adminAccountSimpleDto.id = item.id;
        adminAccountSimpleDto.name = item.label;
        return adminAccountSimpleDto;
      }}
    />
  );
};

export default InputAutocompleteSwitchAdminAccount;
