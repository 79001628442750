// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminPointOfSaleClientDto } from "../dtos/AdminPointOfSaleClientDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminPointOfSaleClient {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<FiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AdminPointOfSaleClientDto>> {
    return this.apiService.apiPost("/admin/point-of-sale-client/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AdminPointOfSaleClientDto> {
    return this.apiService.apiGet("/admin/point-of-sale-client/" + id, apiOptions);
  }

  create(
    adminPointOfSaleClientDto: AdminPointOfSaleClientDto,
    apiOptions?: ApiOptions
  ): Promise<AdminPointOfSaleClientDto> {
    return this.apiService.apiPost("/admin/point-of-sale-client", adminPointOfSaleClientDto, apiOptions);
  }

  edit(
    adminPointOfSaleClientDto: AdminPointOfSaleClientDto,
    apiOptions?: ApiOptions
  ): Promise<AdminPointOfSaleClientDto> {
    return this.apiService.apiPut("/admin/point-of-sale-client", adminPointOfSaleClientDto, apiOptions);
  }

  pingAll(
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiGet("/admin/point-of-sale-client/ping-all", apiOptions);
  }

  sendTestMessage(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiGet("/admin/point-of-sale-client/send-test-message/" + id, apiOptions);
  }

}
