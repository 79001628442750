import { nameof } from "ts-simple-nameof";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormText from "../../shared/components/forms/FormText";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import { SaleViewModel } from "./ViewModels/SaleViewModel";

const SaleMoreInfoPopupForm = (props: { onSubmit: () => void }) => {
  return (
    <>
      <Row>
        <FormText
          label="Adenda"
          propertyName={nameof<SaleViewModel>((p) => p.saleInfo.addendum)}
          multilineRows={8}
        />
      </Row>
      <RowButtons>
        <ButtonAccept onClick={props.onSubmit} />
      </RowButtons>
    </>
  );
};

export default SaleMoreInfoPopupForm;
