import { nameof } from "ts-simple-nameof";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import { enumName } from "../../api/shared/enums/_enumName";
import FormText from "../../shared/components/forms/FormText";
import FormSelectEnum from "../../shared/components/forms/Select/FormSelectEnum";
import Row from "../../shared/components/Layout/GRow";

const SaleFormInfoInvoiceExport = () => {
  return (
    <>
      <Row>
        <FormText
          label="Cláusula de venta"
          propertyName={nameof<SaleDto>((p) => p.saleInfo.saleClause)}
        />
      </Row>
      <Row>
        <FormSelectEnum
          label="Modo de venta"
          enumTypeName={enumName.EInvoiceSaleMode}
          propertyName={nameof<SaleDto>((p) => p.saleInfo.saleMode)}
        />
      </Row>
      <Row>
        <FormSelectEnum
          label="Tipo de transporte"
          enumTypeName={enumName.EInvoiceTransportationType}
          propertyName={nameof<SaleDto>((p) => p.saleInfo.transportationType)}
        />
      </Row>
    </>
  );
};

export default SaleFormInfoInvoiceExport;
