import { ServiceBusOrchestratorItemStatusDto } from "../../api/admin/dtos/ServiceBusOrchestratorItemStatusDto";
import { ServiceBusProcessorStatus } from "../../api/shared/enums/ServiceBusProcessorStatus";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiAdminServiceBus } from "../../api/shared/hooks/useApiAdmin";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import Column from "../../shared/components/Layout/GColumn";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import RowDisplay from "../../shared/components/Layout/GRowDisplay";
import Section from "../../shared/components/Layout/GSection";
import useUtils from "../../shared/hooks/useUtils";
import { AppThemeColor } from "../../styles/color";

const AdminServiceBusStatusCard = (props: {
  serviceBusStatus: ServiceBusOrchestratorItemStatusDto;
  onStatusChange(serviceBus: ServiceBusOrchestratorItemStatusDto): void;
}) => {
  const [apiAdminServiceBus] = useApiAdminServiceBus();
  const { utils } = useUtils();

  const resume = async () => {
    await apiAdminServiceBus.resume(props.serviceBusStatus.name, {
      preventSpinner: true,
    });
    props.onStatusChange(props.serviceBusStatus);
  };

  return (
    <Section title={props.serviceBusStatus.name} background={AppThemeColor.grayLight}>
      {props.serviceBusStatus.status != ServiceBusProcessorStatus.Inactive && (
        <ColumnDisplay label="Estado">
          {utils.enum.translate(enumName.ServiceBusProcessorStatus, props.serviceBusStatus.status)}
        </ColumnDisplay>
      )}
      {props.serviceBusStatus.status == ServiceBusProcessorStatus.Inactive && (
        <Column label="Estado">
          <ButtonSecondary text="Iniciar" icon={TypeIcon.play} onClick={resume} />
        </Column>
      )}
      <ColumnDisplay label="Períodos inactivos">
        {props.serviceBusStatus.inactivePeriods}
      </ColumnDisplay>
      <ColumnDisplay label="Lecturas período">
        {props.serviceBusStatus.status == ServiceBusProcessorStatus.Processing && (
          <>{utils.number.toIntString(props.serviceBusStatus.currentPeriodReadCount)}</>
        )}
        {props.serviceBusStatus.status != ServiceBusProcessorStatus.Processing && <>-</>}
      </ColumnDisplay>
      <RowDisplay label="Lecturas últimos períodos">
        {props.serviceBusStatus.periodReadCounts.join(", ")}
      </RowDisplay>
    </Section>
  );
};

export default AdminServiceBusStatusCard;
