// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AccountBranchSimpleDto } from "../dtos/AccountBranchSimpleDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { PointOfSaleDto } from "../dtos/PointOfSaleDto";
import { SuggestResponseDto } from "../../shared/dtos/SuggestResponseDto";
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { SuggestSearchDto } from "../../shared/dtos/SuggestSearchDto";
import { PointOfSaleFiltersDto } from "../dtos/PointOfSaleFiltersDto";

export class ApiSwitchPointOfSale {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getAccountBranchById(
    adminAccountId: string,
    accountBranchId: string,
    apiOptions?: ApiOptions
  ): Promise<AccountBranchSimpleDto> {
    return this.apiService.apiPost("/switch-point-of-sale/account-branch/" + adminAccountId + "/" + accountBranchId, null, apiOptions);
  }

  getPointOfSaleById(
    adminAccountId: string,
    pointOfSaleId: string,
    apiOptions?: ApiOptions
  ): Promise<PointOfSaleDto> {
    return this.apiService.apiPost("/switch-point-of-sale/pont-of-sale/" + adminAccountId + "/" + pointOfSaleId, null, apiOptions);
  }

  getDefaultAccountBranch(
    adminAccountId: string,
    apiOptions?: ApiOptions
  ): Promise<AccountBranchSimpleDto> {
    return this.apiService.apiPost("/switch-point-of-sale/default-account-branch/" + adminAccountId, null, apiOptions);
  }

  getDefaultPointOfSale(
    adminAccountId: string,
    accountBranchId: string,
    apiOptions?: ApiOptions
  ): Promise<PointOfSaleDto> {
    return this.apiService.apiPost("/switch-point-of-sale/default-account-point-of-sale/" + adminAccountId + "/" + accountBranchId, null, apiOptions);
  }

  suggestAccountBranch(
    adminAccountId: string,
    suggestSearch: SuggestSearchDto<FiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/switch-point-of-sale/suggest-account-branch/" + adminAccountId, suggestSearch, apiOptions);
  }

  suggestPointOfSale(
    adminAccountId: string,
    suggestSearch: SuggestSearchDto<PointOfSaleFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/switch-point-of-sale/suggest-point-of-sale/" + adminAccountId, suggestSearch, apiOptions);
  }

}
