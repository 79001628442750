import { nameof } from "ts-simple-nameof";
import { AccountBranchDto } from "../../../api/app/dtos/AccountBranchDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

export function useAdminAccountBranchValidator() {
  const schema = new ValidationSchema();

  schema[nameof<AccountBranchDto>((p) => p.accountId)] = validate("Cuenta").required();
  schema[nameof<AccountBranchDto>((p) => p.branchName)] = validate("Nombre").required();
  schema[nameof<AccountBranchDto>((p) => p.branchCode)] = validate("Código").required();

  return schema;
}
