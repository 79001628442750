import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import InputSwitch from "../inputs/InputSwitch";
import { useContextGrid } from "./useContextGrid";

const GridIncludeInactiveCheck = () => {
  const grid = useContextGrid();
  return (
    <InputSwitch
      autoWidth
      label="Incluir inactivos"
      labelPlacement="end"
      size={AppSize.medium}
      fontColor={AppThemeColor.gray}
      value={grid.gridState.searchDto.includeInactive}
      onChange={(value) => {
        grid.includeInactiveUpdate(value ?? false);
      }}
    />
  );
};

export default GridIncludeInactiveCheck;
