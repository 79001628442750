import { nameof } from "ts-simple-nameof";
import { AdminLongProcessLogDto } from "../../api/admin/dtos/AdminLongProcessLogDto";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiAdminLongProcessLog } from "../../api/shared/hooks/useApiAdmin";
import Grid, { GridColumnAlign } from "../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../shared/components/Layout/Page";
import { enumColorLongProcessStatus } from "../../shared/utils/utilEnumColors";

const AdminLongProcessLogList = () => {
  const [apiAdminLongProcessLog] = useApiAdminLongProcessLog();

  const itemTemplates: IGridItemTemplate<AdminLongProcessLogDto>[] = [
    {
      header: "Cuenta",
      field: nameof<AdminLongProcessLogDto>((p) => p.account.name),
      filter: {
        propertyName: nameof<AdminLongProcessLogDto>((p) => p.account),
        type: GridFilterType.account,
      },
      width: 10,
    },
    {
      header: "Tipo",
      field: nameof<AdminLongProcessLogDto>((p) => p.type),
      filter: {
        propertyName: nameof<AdminLongProcessLogDto>((p) => p.type),
        type: GridFilterType.enumList,
        enumName: enumName.LongProcessType,
      },
      width: 10,
      cellType: GridCellType.enum,
      cellTypeEnumName: enumName.LongProcessType,
    },
    {
      header: "Inicio",
      field: nameof<AdminLongProcessLogDto>((p) => p.startDate),
      filter: {
        propertyName: nameof<AdminLongProcessLogDto>((p) => p.startDate),
        type: GridFilterType.dateTime,
      },
      width: 8,
      cellType: GridCellType.dateTimeWithSeconds,
    },
    {
      header: "Fin",
      field: nameof<AdminLongProcessLogDto>((p) => p.endDate),
      filter: {
        propertyName: nameof<AdminLongProcessLogDto>((p) => p.endDate),
        type: GridFilterType.dateTime,
      },
      width: 8,
      cellType: GridCellType.dateTimeWithSeconds,
    },
    {
      header: "Mensaje",
      field: nameof<AdminLongProcessLogDto>((p) => p.message),
      width: 20,
    },
    {
      header: "Progreso",
      field: nameof<AdminLongProcessLogDto>((p) => p.progress),
      width: 5,
      cellType: GridCellType.integer,
    },
    {
      header: "Estado",
      field: nameof<AdminLongProcessLogDto>((p) => p.status),
      filter: {
        propertyName: nameof<AdminLongProcessLogDto>((p) => p.status),
        type: GridFilterType.enumList,
        enumName: enumName.LongProcessStatus,
      },
      width: 10,
      cellType: GridCellType.statusWithColor,
      cellTypeEnumName: enumName.LongProcessStatus,
      cellTypeMapColor: enumColorLongProcessStatus,
      align: GridColumnAlign.right,
    },
  ];

  return (
    <Page title="Log de procesos">
      <Grid
        itemTemplates={itemTemplates}
        hideIncludeInactiveButton={true}
        defaultSortBy={[
          { columnName: nameof<AdminLongProcessLogDto>((p) => p.startDate), asc: false },
        ]}
        onSearch={(search, options) => apiAdminLongProcessLog.getPaged(search, options)}
      />
    </Page>
  );
};

export default AdminLongProcessLogList;
