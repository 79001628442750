import ValidationExpressionTree from "./ValidationExpressionTree";

export default class ValidationField {
  public expressionsTree: ValidationExpressionTree;

  private _buildingExpressionTree: ValidationExpressionTree;

  constructor(public propertyNameFriendly?: string) {
    this.expressionsTree = new ValidationExpressionTree(this);
    this._buildingExpressionTree = this.expressionsTree;
  }

  atRoot(): ValidationField {
    this._buildingExpressionTree = this.expressionsTree;
    return this;
  }

  continueBuilding(validationExpressionTree: ValidationExpressionTree): ValidationField {
    this._buildingExpressionTree = validationExpressionTree;
    return this;
  }

  email(message?: string): ValidationField {
    this._buildingExpressionTree.email(message);
    return this;
  }

  required(message?: string): ValidationField {
    this._buildingExpressionTree.required(message);
    return this;
  }

  verify(
    expression: (value: any, baseModel: any, baseModelList: any[]) => boolean,
    message?: string
  ): ValidationField {
    this._buildingExpressionTree.verify(expression, message);
    return this;
  }

  when(condition: (baseModel: any, baseModelList: any[]) => boolean): ValidationField {
    this._buildingExpressionTree.when(condition);
    return this;
  }
}

export function validate(propertyFriendlyName?: string): ValidationField {
  return new ValidationField(propertyFriendlyName);
}
