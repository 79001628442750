import Row, { PropsRow } from "./GRow";

const Column = (props: PropsRow) => {
  return (
    <Row {...props} xs={props.xs ?? 12} md={props.md ?? 4}>
      {props.children}
    </Row>
  );
};

export default Column;
