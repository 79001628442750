import ValidationResultBase from "./ValidationResultBase";

export default class ValidationResultSchema extends ValidationResultBase {
  [fields: string]: any;

  getTouchedValidation(propertyFullName: string): string {
    const propertyNamePath = propertyFullName.split(".");
    const itemIndexStart = propertyNamePath[0].indexOf("[");
    if (itemIndexStart !== -1) {
      return (
        this[propertyNamePath[0].substring(0, itemIndexStart)]?.getTouchedValidation(
          propertyFullName
        ) ?? ""
      );
    }

    if (propertyNamePath.length == 1) {
      return this[propertyNamePath[0]]?.getTouchedValidation();
    }

    return (
      this[propertyNamePath[0]]?.getTouchedValidation(propertyNamePath.slice(1).join(".")) ?? ""
    );
  }
}
