// File generated by Gesta.Build.PostEvents

export class AccountUserCrudDto  {
  userId: string = "";
  userEmail: string = "";
  userFirstName: string = "";
  userLastName: string = "";
  canEdit: boolean = false;
  isAccountAdmin: boolean = false;
}
