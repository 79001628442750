import { useEffect, useState } from "react";
import { EInvoiceProcessLogDto } from "../../api/app/dtos/EInvoiceProcessLogDto";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import Row from "../../shared/components/Layout/GRow";
import Spinner from "../../shared/components/Spinner";
import { useApiEInvoiceProcessLog } from "../../api/shared/hooks/useApiApp";
import { TextAlign } from "../../shared/TextAlign";
import SaleDetailsPopupEInvoiceProcessLogBase from "./SaleDetailsPopup.EInvoiceProcessLogBase";

const SaleDetailsPopupEInvoiceProcessLog = (props: { sale: SaleDto }) => {
  const [apiEInvoiceProcessLog] = useApiEInvoiceProcessLog();
  const [eInvoiceProcessDtoList, setEInvoiceProcessDtoList] = useState<EInvoiceProcessLogDto[]>();

  useEffect(() => {
    const request = async () => {
      setEInvoiceProcessDtoList(
        await apiEInvoiceProcessLog.getProcessBySaleId(props.sale.id, { preventSpinner: true })
      );
    };
    request();
  }, []);

  if (!eInvoiceProcessDtoList) {
    return (
      <Row align={TextAlign.center}>
        <Spinner />
      </Row>
    );
  }

  return <SaleDetailsPopupEInvoiceProcessLogBase logs={eInvoiceProcessDtoList} />;
};

export default SaleDetailsPopupEInvoiceProcessLog;
