import { nameof } from "ts-simple-nameof";
import { AccountUserCrudDto } from "../../api/admin/dtos/AccountUserCrudDto";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormText from "../../shared/components/forms/FormText";
import InfoText from "../../shared/components/InfoText";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import { useContextModelState } from "../../shared/modelState/useContextModelState";

const AccountUserEditForm = (props: { onSave(): void }) => {
  const modelState = useContextModelState();
  const accountUserCrudDto = modelState.model as AccountUserCrudDto;

  return (
    <>
      {!accountUserCrudDto.canEdit && (
        <Row>
          <InfoText>El usuario no se puede editar porque tiene acceso a múltiples cuentas</InfoText>
        </Row>
      )}

      <Row>
        <FormText
          label="Correo"
          readOnly={!accountUserCrudDto.canEdit}
          propertyName={nameof<AccountUserCrudDto>((p) => p.userEmail)}
        />
      </Row>

      <Row>
        <FormText
          label="Nombre"
          readOnly={!accountUserCrudDto.canEdit}
          propertyName={nameof<AccountUserCrudDto>((p) => p.userFirstName)}
        />
      </Row>

      <Row>
        <FormText
          label="Apellido"
          readOnly={!accountUserCrudDto.canEdit}
          propertyName={nameof<AccountUserCrudDto>((p) => p.userLastName)}
        />
      </Row>
      <RowButtons>
        <ButtonAccept disabled={!accountUserCrudDto.canEdit} onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default AccountUserEditForm;
