import { Grid, GridSize } from "@mui/material";
import { CSSProperties, ReactElement } from "react";
import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import { useContextAppInfo } from "../../hooks/useContextAppInfo";
import { TextAlign } from "../../TextAlign";
import Tooltip from "../Tooltip";
import { LAYOUT_SPACING_PIXELS } from "./LayoutConstants";

export interface PropsRow {
  align?: TextAlign;
  buttonIcon?: ReactElement;
  children?: any;
  className?: string;
  fontColor?: AppThemeColor;
  fontSize?: number | string;
  isDisplay?: boolean;
  label?: string;
  md?: GridSize;
  minHeightIgnore?: boolean;
  multilineAllow?: boolean;
  paddingTop?: number;
  style?: CSSProperties;
  xs?: GridSize;
}

const Row = (props: PropsRow) => {
  const { appInfo } = useContextAppInfo();
  const appTheme = useAppTheme();

  let style: CSSProperties = {
    ...props.style,
    color: appTheme.toHexColor(props.fontColor),
    fontSize: props.fontSize,
    textAlign: props.align,
    overflow: props.isDisplay ? "hidden" : undefined,
    boxShadow: appInfo?.developShowLinesBoxes ? "inset 0px 0px 1px 1px blue" : undefined,
    minHeight: !props.minHeightIgnore ? 72 : undefined,
  };

  if (props.paddingTop !== undefined) {
    style = { ...style, paddingTop: props.paddingTop * LAYOUT_SPACING_PIXELS };
  }

  const tooltip = typeof props.children === "string" ? props.children : undefined;

  return (
    <Grid
      item
      xs={props.xs ?? 12}
      md={props.md ?? 12}
      className={"layout-row " + (props.className ?? "")}
      style={style}>
      {props.label && (
        <div
          style={{
            fontSize: ".75rem",
            color: appTheme.toHexColor(AppThemeColor.gray),
            marginBottom: 3,
            whiteSpace: "nowrap",
          }}>
          {props.label}
        </div>
      )}
      {props.isDisplay && (
        <Tooltip text={tooltip}>
          <div
            style={{
              color: appTheme.toHexColor(props.fontColor ?? AppThemeColor.black),
              overflow: "hidden",
              overflowY: props.multilineAllow ? "auto" : undefined,
              textOverflow: "ellipsis",
              whiteSpace: props.multilineAllow ? "break-spaces" : "nowrap",
              overflowWrap: props.multilineAllow ? "anywhere" : undefined,
              fontSize: props.fontSize ?? "1.2em",
            }}>
            {props.children}
            {props.buttonIcon}
          </div>
        </Tooltip>
      )}
      {!props.isDisplay && (
        <>
          {props.children}
          {props.buttonIcon}
        </>
      )}
    </Grid>
  );
};

export default Row;
