import { useEffect, useState } from "react";
import { SaleSenderBehalfDto } from "../../api/app/dtos/SaleSenderBehalfDto";
import { useApiSaleSenderBehalf } from "../../api/shared/hooks/useApiApp";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import SaleSenderBehalfForm from "./SaleSenderBehalf.Form";
import { useSaleSenderBehalfValidation } from "./Validations/useSaleSenderBehalfValidation";

const SaleSenderBehalfCreatePopup = () => {
  const [saleSenderBehalf] = useState<SaleSenderBehalfDto>(new SaleSenderBehalfDto());
  const [modal] = useModal();
  const [apiSaleSenderBehalf] = useApiSaleSenderBehalf();

  const save = async (saleSenderBehalf: SaleSenderBehalfDto) => {
    const created = await apiSaleSenderBehalf.create(saleSenderBehalf);
    modal.closeAsSuccess(created, "El emisor por cuenta ajena se creó con éxito");
  };

  useEffect(() => {
    modal.setVisible("Crear emisor por cuenta ajena", ModalSize.small);
  }, [modal]);

  return (
    <StateProvider
      model={saleSenderBehalf}
      validationSchema={useSaleSenderBehalfValidation()}
      onSubmit={save}>
      {(p) => <SaleSenderBehalfForm onSubmit={p.handleSubmit} />}
    </StateProvider>
  );
};

export default SaleSenderBehalfCreatePopup;
