import { useEffect, useState } from "react";
import { AccountConfigEInvoiceCrudDto } from "../../../../api/app/dtos/AccountConfigEInvoiceCrudDto";
import { useApiAccountConfig } from "../../../../api/shared/hooks/useApiApp";
import ButtonAccept from "../../../../shared/components/Button/ButtonAccept";
import InputSwitch from "../../../../shared/components/inputs/InputSwitch";
import Row from "../../../../shared/components/Layout/GRow";
import RowButtons from "../../../../shared/components/Layout/GRowButtons";
import Page from "../../../../shared/components/Layout/Page";
import ModalMessage from "../../../../shared/components/Modal/ModalMessage";
import { loginBroadCastChannel } from "../../../../shared/context/LoginInfoProvider";
import { useModal } from "../../../../shared/hooks/useModal";
import { useNotifier } from "../../../../shared/hooks/useNotifier";

const EInvoiceConfigGeneral = () => {
  const notifier = useNotifier();
  const [modal] = useModal();
  const [apiAccountConfig] = useApiAccountConfig();
  const [configDto, setConfigDto] = useState<AccountConfigEInvoiceCrudDto>();

  const save = async () => {
    if (!configDto) {
      return null;
    }
    await apiAccountConfig.configEInvoice(configDto);
    notifier.showSuccessFast(
      "La configuración de la facturación electrónica se actualizó con éxito"
    );
    modal.open(<ModalMessage text="Este cambio requiere recargar la página" />, () => {
      loginBroadCastChannel.postMessage("ReloadPage");
      window.location.reload();
    });
  };

  useEffect(() => {
    const load = async () => {
      const config = await apiAccountConfig.getForEInvoice();
      setConfigDto(config);
    };
    load();
  }, []);

  if (!configDto) {
    return <></>;
  }

  return (
    <Page title="Configuración de eFactura">
      <Row>
        <InputSwitch
          label="Activar modo contingencia"
          value={configDto.eInvoiceIsContingencyMode}
          onChange={(value: boolean) =>
            setConfigDto({ ...configDto, eInvoiceIsContingencyMode: value })
          }
        />
      </Row>
      <RowButtons preventValidation={true}>
        <ButtonAccept onClick={save} />
      </RowButtons>
    </Page>
  );
};

export default EInvoiceConfigGeneral;
