export enum TextAlign {
  right = "right",
  center = "center",
  left = "left",
}

export enum FlexDirection {
  row = "row",
  column = "column",
}
