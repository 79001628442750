import { useEffect, useState } from "react";
import { AdminTemplateDesignEmailTestDto } from "../../../api/admin/dtos/AdminTemplateDesignEmailTestDto";
import { AdminTemplateEmailDto } from "../../../api/admin/dtos/AdminTemplateEmailDto";
import {
  useApiAdminAccountPublicTemplateDesign,
  useApiAdminAccountPublicTemplateEmail,
} from "../../../api/shared/hooks/useApiAdmin";
import { useApiAdminProviderAdminAccount } from "../../../api/shared/hooks/useApiAdminProviders";
import { ModalSize } from "../../context/ModalProvider";
import { useContextLoginInfo } from "../../hooks/useContextLoginInfo";
import { useModal } from "../../hooks/useModal";
import ButtonAccept from "../Button/ButtonAccept";
import InputText from "../inputs/InputText";
import Container from "../Layout/GContainer";
import Row from "../Layout/GRow";
import RowButtons from "../Layout/GRowButtons";
import RowDisplay from "../Layout/GRowDisplay";

const TemplateSendEmailTestPopup = (props: { templateId: string }) => {
  const [modal] = useModal();
  const [designTestDto, setDesignTestDto] = useState<AdminTemplateDesignEmailTestDto>();
  const [apiTemplateEmail] = useApiAdminAccountPublicTemplateEmail();
  const [apiTemplateDesign] = useApiAdminAccountPublicTemplateDesign();
  const [apiAdminAccount] = useApiAdminProviderAdminAccount();
  const { loginInfo } = useContextLoginInfo();

  useEffect(() => {
    const request = async () => {
      const template = await apiTemplateEmail.getById(props.templateId);
      const design = await apiTemplateDesign.getTemplateDesign(template.type);
      const currentAdminAccount = await apiAdminAccount.getById(loginInfo.authInfo.adminAccount.id);

      const designTestDto = new AdminTemplateDesignEmailTestDto();
      designTestDto.templateEmail = template as AdminTemplateEmailDto;
      designTestDto.template = template;
      designTestDto.previewJsonData = design.previewData ?? "";
      designTestDto.testEmailTo = currentAdminAccount.contact.email;
      designTestDto.testEmailToName =
        currentAdminAccount.contact.firstName + " " + currentAdminAccount.contact.lastName;
      setDesignTestDto(designTestDto);
    };
    request();
    modal.setVisible("Probar envío de correo", ModalSize.small);
  }, [modal]);

  const send = () => {
    if (!designTestDto) {
      return;
    }
    const request = async () => {
      designTestDto.previewJsonData = JSON.stringify(designTestDto.previewJsonData);
      await apiTemplateDesign.emailSendTest(designTestDto);
      modal.closeAsSuccess(
        null,
        "Email de prueba de correo enviado a " + designTestDto.testEmailTo
      );
    };
    request();
    return;
  };

  if (!designTestDto) {
    return <></>;
  }

  return (
    <Container>
      <RowDisplay label="Asunto de correo de prueba">
        {(designTestDto.template as AdminTemplateEmailDto).subject}
      </RowDisplay>
      <Row>
        <InputText
          label="Correo"
          value={designTestDto.testEmailTo}
          onChange={(value) => {
            setDesignTestDto({ ...designTestDto, testEmailTo: value });
          }}
        />
      </Row>
      <Row>
        <InputText
          label="Nombre"
          value={designTestDto.testEmailToName}
          onChange={(value) => {
            setDesignTestDto({ ...designTestDto, testEmailToName: value });
          }}
        />
      </Row>
      <RowButtons preventValidation={true}>
        <ButtonAccept text="Enviar" disabled={!designTestDto.testEmailTo} onClick={send} />
      </RowButtons>
    </Container>
  );
};

export default TemplateSendEmailTestPopup;
