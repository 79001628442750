import { useEffect, useState } from "react";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import { useApiEInvoiceProcessLog, useApiSale } from "../../api/shared/hooks/useApiApp";
import Container from "../../shared/components/Layout/GContainer";
import TabGroup from "../../shared/components/Layout/TabGroup";
import TabView from "../../shared/components/Layout/TabView";
import { useModal } from "../../shared/hooks/useModal";
import SaleDetailsPopupEInvoiceEnvelopeSale from "./SaleDetailsPopup.EInvoiceEnvelopeSale";
import SaleDetailsPopupEInvoiceEnvelopeSaleToCompany from "./SaleDetailsPopup.EInvoiceEnvelopeSaleToCompany";
import SaleDetailsPopupGeneral from "./SaleDetailsPopup.General";

const TABS = {
  GENERAL: "E_INVOICE",
  ENVELOPE_SALE: "ENVELOPE",
  ENVELOPE_SALE_TO_COMPANY: "ENVELOPE_SALE_TO_COMPANY",
};

const SaleDetailsPopup = (props: { saleId: string }) => {
  const [modal] = useModal();
  const [apiSale] = useApiSale();
  const [sale, setSale] = useState<SaleDto>();
  const [apiEInvoiceProcessLog] = useApiEInvoiceProcessLog();

  useEffect(() => {
    const load = async () => {
      const sale = await apiSale.getById(props.saleId);
      setSale(sale);
      modal.setVisible("Factura de venta");
    };
    load();
  }, [modal, apiSale, apiEInvoiceProcessLog, props.saleId]);

  if (!sale) {
    return <></>;
  }

  const tabs = [{ title: "General", value: TABS.GENERAL }];
  if (sale.eInvoiceEnvelopeSale) {
    tabs.push({ title: "Sobre", value: TABS.ENVELOPE_SALE });
  }
  if (sale.eInvoiceEnvelopeSaleToCompany) {
    tabs.push({ title: "Sobre a receptor", value: TABS.ENVELOPE_SALE_TO_COMPANY });
  }

  return (
    <Container>
      <TabGroup tabs={tabs} selectedTab={TABS.GENERAL}>
        <TabView value={TABS.GENERAL}>
          <SaleDetailsPopupGeneral sale={sale} />
        </TabView>
        <TabView value={TABS.ENVELOPE_SALE}>
          <SaleDetailsPopupEInvoiceEnvelopeSale sale={sale} />
        </TabView>
        <TabView value={TABS.ENVELOPE_SALE_TO_COMPANY}>
          <SaleDetailsPopupEInvoiceEnvelopeSaleToCompany sale={sale} />
        </TabView>
      </TabGroup>
    </Container>
  );
};

export default SaleDetailsPopup;
