import { nameof } from "ts-simple-nameof";
import { ContactDto } from "../../api/app/dtos/ContactDto";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import { ContactFilterGroup } from "../../api/shared/enums/ContactFilterGroup";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiSale } from "../../api/shared/hooks/useApiApp";
import FormAutocompleteContact from "../../shared/components/forms/Autocomplete/FormAutocompleteContact";
import FormCheck from "../../shared/components/forms/FormCheck";
import Row from "../../shared/components/Layout/GRow";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import useUtils from "../../shared/hooks/useUtils";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import { getSaleCreateOptionsDto, SalePageType } from "./SalePage";
import { SaleViewModel } from "./ViewModels/SaleViewModel";

const SaleFormInfoCustomer = (props: { pageType: SalePageType }) => {
  const modelState = useContextModelStateSale();
  const { loginInfo } = useContextLoginInfo();
  const sale = modelState.sale as SaleViewModel;
  const { utils } = useUtils();
  const [apiSale] = useApiSale();

  const onContactChange = (contact: ContactDto) => {
    const request = async () => {
      const saleForCreateOptionsDto = getSaleCreateOptionsDto(
        props.pageType,
        loginInfo.authInfo.accountConfig,
        contact
      );
      const response = await apiSale.getInvoiceType(saleForCreateOptionsDto, {
        preventSpinner: true,
      });
      modelState.updateInvoiceType(response.invoiceType);
      modelState.updateInvoiceTypeGroup(response.invoiceTypeGroup);
      modelState.onContactChange();
    };
    request();
  };

  return (
    <>
      <Row>
        {!sale.saleInfo.isExport && (
          <FormCheck
            label="Es consumidor final"
            propertyName={nameof<SaleDto>((p) => p.isFinalConsumer)}
            readOnly={!!sale.contact}
          />
        )}
        <FormAutocompleteContact
          contactFilterGroup={ContactFilterGroup.Customers}
          label="Cliente"
          propertyName={nameof<SaleDto>((p) => p.contact)}
          onValueChange={onContactChange}
        />
      </Row>
      {sale.contact && (
        <>
          <Row label="Nombre" minHeightIgnore={true}>
            {sale.contact.displayBothNames}
          </Row>

          {!!sale.contact.taxPayerId && (
            <Row label="RUT" minHeightIgnore={true}>
              {sale.contact.taxPayerId}
            </Row>
          )}
          {!sale.contact.taxPayerId && !!sale.contact.info?.idCardNumber && (
            <Row
              label={utils.enum.translate(enumName.IdCardType, sale.contact.info.idCardType)}
              minHeightIgnore={true}>
              {sale.contact.info.idCardNumber ? sale.contact.info.idCardNumber : "-"}
            </Row>
          )}
          <Row label="Correo" minHeightIgnore={true}>
            {sale.contact?.email ? sale.contact?.email : "-"}
          </Row>
        </>
      )}
    </>
  );
};

export default SaleFormInfoCustomer;
