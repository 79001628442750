// File generated by Gesta.Build.PostEvents
import { ContactSimpleDto } from "./ContactSimpleDto";
import { InvoiceTypeGroup } from "../../shared/enums/InvoiceTypeGroup";

export class SaleForCreateOptionsDto  {
  contact: ContactSimpleDto;
  invoiceTypeGroup: InvoiceTypeGroup;
  isContingency: boolean = false;
  isExport: boolean = false;
  isSaleInBehalf: boolean = false;
  isCancellation: boolean = false;
  isAdjustment: boolean = false;
}
