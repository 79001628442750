import { MutableRefObject, useEffect, useRef, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminTemplateDesignDto } from "../../../api/admin/dtos/AdminTemplateDesignDto";
import { AdminTemplateDto } from "../../../api/admin/dtos/AdminTemplateDto";
import { AdminTemplatePdfDto } from "../../../api/admin/dtos/AdminTemplatePdfDto";
import { useApiAdminAccountPublicTemplateDesign } from "../../../api/shared/hooks/useApiAdmin";
import { useContextModelState } from "../../modelState/useContextModelState";
import Column from "../Layout/GColumn";
import Spinner from "../Spinner";
import TemplateEditorPanelCenter, { TEMPLATE_EDITOR_TAB } from "./TemplateEditorPanelCenter";
import TemplateEditorPanelLeft from "./TemplateEditorPanelLeft";
import TemplateEditorPanelRight from "./TemplateEditorPanelRight";

const TemplateEditor = () => {
  const bodyRef = useRef(null);
  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const cssRef = useRef(null);
  const [apiTemplateDesign] = useApiAdminAccountPublicTemplateDesign();
  const [designDto, setDesignDto] = useState<AdminTemplateDesignDto>();
  const [previewData, setPreviewData] = useState();
  const [tabFocus, setTabFocus] = useState(TEMPLATE_EDITOR_TAB.BODY);
  const [isPreviewPanelExpanded, setIsPreviewPanelExpanded] = useState(true);
  const modelState = useContextModelState();
  const template = modelState.model as AdminTemplateDto;

  const addFieldTag = (tag: string) => {
    let ref = bodyRef;
    let propertyName = nameof<AdminTemplateDto>((p) => p.body);

    if (tabFocus == TEMPLATE_EDITOR_TAB.HEADER) {
      ref = headerRef;
      propertyName = nameof<AdminTemplatePdfDto>((p) => p.header);
    } else if (tabFocus == TEMPLATE_EDITOR_TAB.FOOTER) {
      ref = footerRef;
      propertyName = nameof<AdminTemplatePdfDto>((p) => p.footer);
    }

    addTag(tag, propertyName, ref, template, modelState.updateProperty);
  };

  const insertPageBreak = () => {
    addFieldTag("\n##PAGE_BREAK##\n");
  };

  useEffect(() => {
    const loadType = async () => {
      const design = await apiTemplateDesign.getTemplateDesign(template.type);
      setDesignDto(design);
      setPreviewData(design.previewData);
    };
    loadType();
  }, []);

  if (!designDto) {
    return <Spinner />;
  }

  return (
    <>
      <Column md={isPreviewPanelExpanded ? 2 : 4}>
        <TemplateEditorPanelLeft
          canAddField={
            tabFocus == TEMPLATE_EDITOR_TAB.BODY ||
            tabFocus == TEMPLATE_EDITOR_TAB.HEADER ||
            tabFocus == TEMPLATE_EDITOR_TAB.FOOTER
          }
          templateDesignDto={designDto}
          onAddField={(tag: string) => addFieldTag(tag)}
        />
      </Column>

      <Column md={isPreviewPanelExpanded ? 5 : 8}>
        <TemplateEditorPanelCenter
          bodyRef={bodyRef}
          cssRef={cssRef}
          footerRef={footerRef}
          headerRef={headerRef}
          previewData={previewData}
          showExpandPreviewPanelButton={!isPreviewPanelExpanded}
          onBodyChange={(value: string) =>
            modelState.updateProperty(
              nameof<AdminTemplateDto>((p) => p.body),
              value
            )
          }
          onCssChange={(value: string) =>
            modelState.updateProperty(
              nameof<AdminTemplateDto>((p) => p.styles),
              value
            )
          }
          onHeaderChange={(value: string) =>
            modelState.updateProperty(
              nameof<AdminTemplatePdfDto>((p) => p.header),
              value
            )
          }
          onFooterChange={(value: string) =>
            modelState.updateProperty(
              nameof<AdminTemplatePdfDto>((p) => p.footer),
              value
            )
          }
          onExpandPreviewPanel={() => setIsPreviewPanelExpanded(true)}
          onInsertPageBreak={insertPageBreak}
          onTabChange={setTabFocus}
          onPreviewDataChange={setPreviewData}
        />
      </Column>

      {isPreviewPanelExpanded && (
        <Column md={5}>
          <TemplateEditorPanelRight
            previewJsonData={previewData ? JSON.stringify(previewData) : undefined}
            onCollapse={() => setIsPreviewPanelExpanded(false)}
          />
        </Column>
      )}
    </>
  );
};

export default TemplateEditor;

const addTag = (
  tag: string,
  propertyName: string,
  inputReference: MutableRefObject<any>,
  model: any,
  updateProperty: (propertyName: string, newValue: string) => void
) => {
  const input = (inputReference?.current as any)._input as any;
  const currentValue = model[propertyName] ?? "";
  const newValue =
    currentValue.slice(0, input.selectionStart ?? 0) +
    tag +
    currentValue.slice(input.selectionStart ?? 0);

  updateProperty(propertyName, newValue);
  input.focus();
};
