import { useEffect, useState } from "react";
import { AppNotificationDto } from "../../api/app/dtos/AppNotificationDto";
import { AppNotificationGroup } from "../../api/shared/enums/AppNotificationGroup";
import { AppNotificationType } from "../../api/shared/enums/AppNotificationType";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAppNotifications } from "../../api/shared/hooks/useApiApp";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AppNotificationCreatePopupForm from "./AppNotificationCreatePopup.Form";

const AppNotificationCreatePopup = () => {
  const [modal] = useModal();
  const [apiAppNotifications] = useApiAppNotifications();
  const [appNotification] = useState<AppNotificationDto>(() => {
    const appNotification = new AppNotificationDto();
    appNotification.group = AppNotificationGroup.General;
    appNotification.title = "Texto de prueba";
    appNotification.body = "Notificación de prueba";
    appNotification.type = AppNotificationType.Reminder;
    return appNotification;
  });

  const save = async (appNotification: AppNotificationDto) => {
    await apiAppNotifications.create(appNotification);
    modal.closeAsSuccess(null, "La notificación se creó con éxito");
  };

  useEffect(() => {
    modal.setVisible("Nueva notificación", ModalSize.normal);
  }, [modal]);

  return (
    <Container>
      <StateProvider model={appNotification} onSubmit={save}>
        {(p) => <AppNotificationCreatePopupForm onSubmit={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default AppNotificationCreatePopup;
