// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { SentEmailDto } from "../dtos/SentEmailDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiSentEmail {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<FiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<SentEmailDto>> {
    return this.apiService.apiPost("/sent-emails/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<SentEmailDto> {
    return this.apiService.apiGet("/sent-emails/" + id, apiOptions);
  }

}
