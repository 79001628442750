// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AccountConfigPreferencesDto } from "../dtos/AccountConfigPreferencesDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAccountConfigPreferences {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getCurrent(
    apiOptions?: ApiOptions
  ): Promise<AccountConfigPreferencesDto> {
    return this.apiService.apiGet("/account-config-preferences", apiOptions);
  }

  getMerged(
    type: number | null,
    apiOptions?: ApiOptions
  ): Promise<AccountConfigPreferencesDto> {
    return this.apiService.apiGet("/account-config-preferences/merged?" + 
      (type ? "&type=" + type : ""), apiOptions);
  }

  getForType(
    type: number,
    apiOptions?: ApiOptions
  ): Promise<AccountConfigPreferencesDto> {
    return this.apiService.apiGet("/account-config-preferences/for-type?" + 
      (type ? "&type=" + type : ""), apiOptions);
  }

  create(
    accountConfigPreferencesDto: AccountConfigPreferencesDto,
    apiOptions?: ApiOptions
  ): Promise<AccountConfigPreferencesDto> {
    return this.apiService.apiPost("/account-config-preferences", accountConfigPreferencesDto, apiOptions);
  }

  edit(
    accountConfigPreferencesDto: AccountConfigPreferencesDto,
    apiOptions?: ApiOptions
  ): Promise<AccountConfigPreferencesDto> {
    return this.apiService.apiPut("/account-config-preferences", accountConfigPreferencesDto, apiOptions);
  }

  deleteForType(
    type: number,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiDelete("/account-config-preferences/delete-for-type?" + 
      (type ? "&type=" + type : ""), apiOptions);
  }

}
