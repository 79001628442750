import { Collapse } from "@mui/material";
import { useEffect, useState } from "react";
import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import { ThemeSize } from "../../ThemeSize";
import ButtonIcon from "../Button/ButtonIcon";
import ButtonLink from "../Button/ButtonLink";
import { TypeIcon } from "../Icon";
import ComponentGroup from "../Layout/ComponentsGroup";
import Column from "../Layout/GColumn";
import Container from "../Layout/GContainer";
import Row from "../Layout/GRow";
import Section from "../Layout/GSection";
import GridDefaultSearch from "./GridDefaultSearch";
import GridFiltersForm from "./GridFilters.Form";
import { useContextGrid } from "./useContextGrid";

const GridFiltersDesktop = (props: {
  preventShowDefaultSearch: boolean;
  onApplyFilters: () => void;
}) => {
  const grid = useContextGrid();
  const [expandedDefaultSearch, setExpandedDefaultSearch] = useState(false);
  const [animatedCustomFilters, setAnimatedCustomFilters] = useState(false);

  useEffect(() => {
    if (grid.gridState.filtersAllExpanded) {
      setAnimatedCustomFilters(true);
    }
  }, [grid.gridState.filtersAllExpanded]);

  useEffect(() => {
    if (!animatedCustomFilters) {
      const timer = setTimeout(() => {
        grid.filtersAllCollapse();
      }, 250);
      return () => clearTimeout(timer);
    }
  }, [animatedCustomFilters]);

  useEffect(() => {
    if (props.preventShowDefaultSearch) {
      grid.filtersAllExpand();
    }
  }, []);

  if (grid.gridState.filtersAllExpanded) {
    return (
      <Container style={{ minHeight: 48 }}>
        <Row>
          <Collapse in={animatedCustomFilters}>
            <Section border={true}>
              <GridFiltersForm onApplyFilters={props.onApplyFilters} />
            </Section>
          </Collapse>
        </Row>
        {animatedCustomFilters && !props.preventShowDefaultSearch && (
          <Row paddingTop={0} minHeightIgnore={true}>
            <ButtonLink
              text="ocultar filtros"
              icon={TypeIcon.chevronUp}
              onClick={() => {
                setAnimatedCustomFilters(false);
              }}
            />
          </Row>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <Column md={6} minHeightIgnore={true}>
        <ComponentGroup>
          {!expandedDefaultSearch && (
            <ButtonLink
              size={ThemeSize.medium}
              icon={TypeIcon.search}
              text={!expandedDefaultSearch ? "Buscar" : ""}
              styles={{ marginBottom: 3 }}
              onClick={() => {
                setExpandedDefaultSearch(!expandedDefaultSearch);
              }}
            />
          )}
          {expandedDefaultSearch && (
            <>
              <ButtonIcon
                size={AppSize.medium}
                icon={TypeIcon.search}
                color={AppThemeColor.primary}
                onClick={() => {
                  setExpandedDefaultSearch(false);
                }}
              />
              <GridDefaultSearch setFocus={true} onDefaultSearchChange={props.onApplyFilters} />
              <ButtonLink
                icon={TypeIcon.plus}
                styles={{ marginLeft: 45 }}
                text="filtros de búsqueda"
                onClick={() => {
                  grid.filtersAllExpand();
                }}
              />
            </>
          )}
        </ComponentGroup>
      </Column>
    </Container>
  );
};

export default GridFiltersDesktop;
