import { AppThemeColor } from "../../styles/color";
import { AppSize } from "../AppSize";
import Icon, { TypeIcon } from "./Icon";
import IconHyphen from "./IconHyphen";

const IconCheck = (props: { value: boolean; size?: AppSize; showHyphen?: boolean }) => {
  return props.value ? (
    <Icon type={TypeIcon.check} color={AppThemeColor.success} size={props.size} />
  ) : props.showHyphen ? (
    <IconHyphen size={props.size} />
  ) : (
    <></>
  );
};

export default IconCheck;
