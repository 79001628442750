import { SearchResultItemDto } from "../../../api/shared/dtos/SearchResultItemDto";
import { ContextMenuItem } from "../ContextMenu/ContextMenu";
import GridCellValue from "./GridRow.CellValue";
import { IGridItemTemplate, MobileSpecialType } from "./ItemTemplate/IGridItemTemplate";

const GridRowCellMobile = (props: {
  isRowExpanded: boolean;
  template: IGridItemTemplate<any>;
  pagedResultItem: SearchResultItemDto<any>;
  onDisplayItemMenu?(item: any): ContextMenuItem[];
  onExpandRowShouldDisableButton?(item: any): boolean;
  onRowToggleExpand?(item: any): void;
  onSelect(item: any): void;
}) => {
  return (
    <div
      className={[
        "grid-cell",
        props.template.className ?? "",
        props.template.mobileSpecialType
          ? props.template.mobileSpecialType == MobileSpecialType.Main
            ? " grid-cell-special main"
            : " grid-cell-special secondary"
          : "",
      ].join(" ")}>
      {!props.template.mobileSpecialType && <div className="label">{props.template.header}</div>}
      <GridCellValue
        isRowExpanded={true}
        template={props.template}
        pagedResultItem={props.pagedResultItem}
        onDisplayItemMenu={props.onDisplayItemMenu}
        onExpandRowShouldDisableButton={props.onExpandRowShouldDisableButton}
        onRowToggleExpand={props.onRowToggleExpand}
        onSelect={props.onSelect}
      />
    </div>
  );
};

export default GridRowCellMobile;
