import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { CompanyInfoDto } from "../../api/app/dtos/CompanyInfoDto";
import { ContactDto } from "../../api/app/dtos/ContactDto";
import { ContactInfoDto } from "../../api/app/dtos/ContactInfoDto";
import { ContactType } from "../../api/shared/enums/ContactType";
import { enumName } from "../../api/shared/enums/_enumName";
import FormRadioEnum from "../../shared/components/forms/FormRadioEnum";
import FormSwitch from "../../shared/components/forms/FormSwitch";
import FormTaxPayerId from "../../shared/components/forms/FormTaxPayerId";
import FormText from "../../shared/components/forms/FormText";
import FormSelectCountry from "../../shared/components/forms/Select/FormSelectCountry";
import FormSelectEnum from "../../shared/components/forms/Select/FormSelectEnum";
import InfoText, { InfoTextType } from "../../shared/components/InfoText";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Column from "../../shared/components/Layout/GColumn";
import Row from "../../shared/components/Layout/GRow";
import { useContextModelState } from "../../shared/modelState/useContextModelState";
import { FlexDirection } from "../../shared/TextAlign";

const ContactFormGeneral = (props: { isEdit?: boolean }) => {
  const modelState = useContextModelState();
  const contact = modelState.model as ContactDto;
  const [companyInfoDto, setCompanyInfoDto] = useState<CompanyInfoDto>();

  const companyInfoLoaded = (companyInfo: CompanyInfoDto) => {
    if (!companyInfo || !companyInfo.found) {
      return;
    }

    setCompanyInfoDto(companyInfo);

    companyInfo.taxPayerInfo.address.id = contact.info.address.id;

    modelState.updateProperties([
      {
        propertyFullName: nameof<ContactDto>((p) => p.companyName),
        value: companyInfo.taxPayerInfo.companyName,
      },
      {
        propertyFullName: nameof<ContactDto>((p) => p.name),
        value: companyInfo.taxPayerInfo.name
          ? companyInfo.taxPayerInfo.name
          : companyInfo.taxPayerInfo.companyName,
      },
      {
        propertyFullName: nameof<ContactDto>((p) => p.info.address),
        value: companyInfo.taxPayerInfo.address,
      },
      {
        propertyFullName: nameof<ContactDto>((p) => p.email),
        value: companyInfo.taxPayerInfo.contactEmail,
      },
      {
        propertyFullName: nameof<ContactDto>((p) => p.info.phone),
        value: companyInfo.taxPayerInfo.contactPhone,
      },
      {
        propertyFullName: nameof<ContactDto>((p) => p.eInvoiceEmail),
        value: companyInfo.eInvoiceInfo.eInvoiceEmail,
      },
      {
        propertyFullName: nameof<ContactDto>((p) => p.eInvoiceWebService),
        value: companyInfo.eInvoiceInfo.eInvoiceWebService,
      },
    ]);
  };

  return (
    <>
      <Row>
        <FormRadioEnum
          propertyName={nameof<ContactDto>((p) => p.contactType)}
          enumName={enumName.ContactType}
          alignRow={true}
          readOnly={props.isEdit}
          onValueChange={(value: ContactType) => {
            if (value == ContactType.ForeignCompany) {
              modelState.updateProperty(
                nameof<ContactDto>((p) => p.info.address.country),
                null
              );
            }
          }}
        />
      </Row>

      {contact.contactType === ContactType.Company && (
        <Row>
          <FormTaxPayerId
            label="RUT"
            propertyName={nameof<ContactDto>((p) => p.taxPayerId)}
            onCompanyInfoFetched={companyInfoLoaded}
          />
        </Row>
      )}

      {contact.contactType === ContactType.ForeignCompany && (
        <>
          {contact.isCustomer && (
            <Row>
              <InfoText type={InfoTextType.info}>
                Al facturar a un contacto del tipo empresa extranjera se emiten facturas de
                exportación
              </InfoText>
            </Row>
          )}
          <Row>
            <FormSelectCountry
              label="País"
              propertyName={nameof<ContactDto>((p) => p.info.address.country)}
            />
          </Row>
        </>
      )}

      {contact.contactType !== ContactType.Company && (
        <>
          <Column md={4}>
            <FormSelectEnum
              label="Tipo documento"
              enumTypeName={enumName.IdCardType}
              propertyName={nameof<ContactDto>((p) => p.info.idCardType)}
            />
          </Column>
          <Column md={8}>
            <FormText
              label="Número de documento"
              propertyName={nameof<ContactDto>((p) => p.info.idCardNumber)}
            />
          </Column>
        </>
      )}

      {contact.contactType !== ContactType.Person && (
        <>
          <Row>
            <FormText
              label="Nombre"
              propertyName={nameof<ContactDto>((p) => p.name)}
              hint={
                companyInfoDto?.taxPayerInfo?.name &&
                companyInfoDto?.taxPayerInfo?.name !== contact.name
                  ? companyInfoDto?.taxPayerInfo.name
                  : ""
              }
            />
          </Row>
          <Row>
            <FormText
              label="Razón social"
              propertyName={nameof<ContactDto>((p) => p.companyName)}
              hint={
                companyInfoDto?.taxPayerInfo?.companyName &&
                companyInfoDto?.taxPayerInfo?.companyName !== contact.companyName
                  ? companyInfoDto?.taxPayerInfo.companyName
                  : ""
              }
            />
          </Row>
        </>
      )}

      {contact.contactType === ContactType.Person && (
        <>
          <Row>
            <FormText
              label="Nombre"
              propertyName={nameof<ContactDto>((p) => p.firstName)}
              onValueChange={(value) => {
                modelState.updateProperty(
                  nameof<ContactDto>((p) => p.name),
                  value + " " + contact.lastName
                );
              }}
            />
          </Row>
          <Row>
            <FormText
              label="Apellido"
              propertyName={nameof<ContactDto>((p) => p.lastName)}
              onValueChange={(value) => {
                modelState.updateProperty(
                  nameof<ContactDto>((p) => p.name),
                  contact.firstName + " " + value
                );
              }}
            />
          </Row>
        </>
      )}

      <Row>
        <FormText label="Correo" propertyName={nameof<ContactDto>((p) => p.email)} />
      </Row>
      {contact.contactType == ContactType.Company && (
        <>
          <Row minHeightIgnore={true}>
            <FormSwitch
              label="eFactura definir correo diferente a DGI"
              propertyName={nameof<ContactDto>((p) => p.eInvoiceEmailPreventAutoUpdate)}
            />
          </Row>
          <Row>
            <FormText
              label="eFactura correo"
              propertyName={nameof<ContactDto>((p) => p.eInvoiceEmail)}
              readOnly={!contact.eInvoiceEmailPreventAutoUpdate}
            />
          </Row>
          <Row>
            <FormText
              label="eFactura web service"
              propertyName={nameof<ContactDto>((p) => p.eInvoiceWebService)}
              readOnly={!contact.eInvoiceEmailPreventAutoUpdate}
            />
          </Row>
        </>
      )}
      <Row>
        <FormText
          label="Teléfono"
          propertyName={
            nameof<ContactDto>((p) => p.info) + "." + nameof<ContactInfoDto>((p) => p.phone)
          }
        />
      </Row>

      <Column>
        <FormText
          label="Id externo"
          propertyName={nameof<ContactDto>((p) => p.openApiExternalId)}
        />
      </Column>

      <Row>
        <ComponentGroup direction={FlexDirection.column}>
          <FormSwitch label="Es cliente" propertyName={nameof<ContactDto>((p) => p.isCustomer)} />
          <FormSwitch label="Es proveedor" propertyName={nameof<ContactDto>((p) => p.isProvider)} />
        </ComponentGroup>
      </Row>
    </>
  );
};

export default ContactFormGeneral;
