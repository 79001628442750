import { useEffect, useState } from "react";
import { AdminProviderWithSecureInfoDto } from "../../api/admin/dtos/AdminProviderWithSecureInfoDto";
import { useApiAdminProvider } from "../../api/shared/hooks/useApiAdmin";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AdminProviderForm from "./AdminProviderForm";
import { useAdminProviderValidator } from "./Validations/useAdminProviderValidations";

const AdminProviderDetailsPopup = (props: { id: string }) => {
  const [modal] = useModal();
  const [apiAdminProvider] = useApiAdminProvider();
  const [providerDto, setProviderDto] = useState<AdminProviderWithSecureInfoDto>();

  const save = async (adminProviderDto: AdminProviderWithSecureInfoDto) => {
    await apiAdminProvider.edit(adminProviderDto);
    modal.closeAsSuccess(providerDto, "El proveedor se editó con éxito");
  };

  useEffect(() => {
    const loadAdminAccount = async () => {
      setProviderDto(await apiAdminProvider.getById(props.id));
      modal.setVisible("Editar proveedor", ModalSize.big);
    };
    loadAdminAccount();
  }, []);

  if (!providerDto) {
    return <></>;
  }

  return (
    <Container>
      <StateProvider
        model={providerDto}
        onSubmit={save}
        validationSchema={useAdminProviderValidator()}>
        {(p) => <AdminProviderForm onSave={p.handleSubmit} isEdit={true} />}
      </StateProvider>
    </Container>
  );
};

export default AdminProviderDetailsPopup;
