import { useEffect, useState } from "react";
import { EInvoiceEnvelopeStatusResponseSaleToCompanyDto } from "../../api/app/dtos/EInvoiceEnvelopeStatusResponseSaleToCompanyDto";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import { useApiEInvoiceEnvelopeStatusResponseSaleToCompany } from "../../api/shared/hooks/useApiApp";
import Row from "../../shared/components/Layout/GRow";
import Spinner from "../../shared/components/Spinner";
import { TextAlign } from "../../shared/TextAlign";
import SaleDetailsPopupEInvoiceEInvoiceEnvelopeStatusResponseBase from "./SaleDetailsPopup.EInvoiceEInvoiceEnvelopeStatusResponseBase";

const SaleDetailsPopupEInvoiceEInvoiceEnvelopeStatusResponseSaleToCompany = (props: {
  sale: SaleDto;
}) => {
  const [apiEInvoiceEnvelopeStatusResponseSaleToCompany] =
    useApiEInvoiceEnvelopeStatusResponseSaleToCompany();
  const [items, setItems] = useState<EInvoiceEnvelopeStatusResponseSaleToCompanyDto[]>();

  useEffect(() => {
    const request = async () => {
      setItems(
        await apiEInvoiceEnvelopeStatusResponseSaleToCompany.getAllByEnvelopeId(
          props.sale.eInvoiceEnvelopeSaleToCompanyId,
          { preventSpinner: true }
        )
      );
    };
    request();
  }, []);

  if (!items) {
    return (
      <Row align={TextAlign.center}>
        <Spinner />
      </Row>
    );
  }

  return <SaleDetailsPopupEInvoiceEInvoiceEnvelopeStatusResponseBase items={items} />;
};

export default SaleDetailsPopupEInvoiceEInvoiceEnvelopeStatusResponseSaleToCompany;
