// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminTemplateGenericSimpleDto } from "../dtos/AdminTemplateGenericSimpleDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { AdminTemplateGenericDto } from "../dtos/AdminTemplateGenericDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { AdminTemplateGenericFiltersDto } from "../dtos/AdminTemplateGenericFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { AdminTemplateDto } from "../dtos/AdminTemplateDto";

export class ApiAdminAccountPublicTemplateGeneric {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getGenericsForAdmin(
    templateGroup: number,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplateGenericSimpleDto[]> {
    return this.apiService.apiGet("/admin/account-public/templates/generic/by-group-admin?" + 
      (templateGroup ? "&templateGroup=" + templateGroup : ""), apiOptions);
  }

  getGenericsForGlobal(
    templateGroup: number,
    country: number | null,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplateGenericSimpleDto[]> {
    return this.apiService.apiGet("/admin/account-public/templates/generic/by-group-global?" + 
      (templateGroup ? "&templateGroup=" + templateGroup : "") + 
      (country ? "&country=" + country : ""), apiOptions);
  }

  getGenericsForAccount(
    templateGroup: number,
    accountId: string,
    onlyAccountDefined: boolean,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplateGenericSimpleDto[]> {
    return this.apiService.apiGet("/admin/account-public/templates/generic/by-group-for-account?" + 
      (templateGroup ? "&templateGroup=" + templateGroup : "") + 
      (accountId ? "&accountId=" + accountId : "") + 
      (onlyAccountDefined ? "&onlyAccountDefined=" + onlyAccountDefined : ""), apiOptions);
  }

  getPaged(
    searchDto: PagedSearchDto<AdminTemplateGenericFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AdminTemplateGenericDto>> {
    return this.apiService.apiPost("/admin/account-public/templates/generic/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplateGenericDto> {
    return this.apiService.apiGet("/admin/account-public/templates/generic/" + id, apiOptions);
  }

  create(
    adminTemplateGenericDto: AdminTemplateGenericDto,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplateGenericDto> {
    return this.apiService.apiPost("/admin/account-public/templates/generic", adminTemplateGenericDto, apiOptions);
  }

  edit(
    adminTemplateGenericDto: AdminTemplateGenericDto,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplateGenericDto> {
    return this.apiService.apiPut("/admin/account-public/templates/generic", adminTemplateGenericDto, apiOptions);
  }

  inactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin/account-public/templates/generic/inactivate/" + id, null, apiOptions);
  }

  reactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin/account-public/templates/generic/reactivate/" + id, null, apiOptions);
  }

  getByType(
    templateType: number,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplateDto[]> {
    return this.apiService.apiGet("/admin/account-public/templates/generic/by-type?" + 
      (templateType ? "&templateType=" + templateType : ""), apiOptions);
  }

}
