import { nameof } from "ts-simple-nameof";
import { ProductDto } from "../../api/app/dtos/ProductDto";
import { enumName } from "../../api/shared/enums/_enumName";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormNumber from "../../shared/components/forms/FormNumber";
import FormRadioEnum from "../../shared/components/forms/FormRadioEnum";
import FormSwitch from "../../shared/components/forms/FormSwitch";
import FormText from "../../shared/components/forms/FormText";
import FormSelectCurrency from "../../shared/components/forms/Select/FormSelectCurrency";
import FormSelectTax from "../../shared/components/forms/Select/FormSelectTax";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Column from "../../shared/components/Layout/GColumn";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import { useContextModelState } from "../../shared/modelState/useContextModelState";
import { FlexDirection } from "../../shared/TextAlign";
import { ProductViewModel } from "./ViewModels/SaleViewModel";

const ProductForm = (props: { onSubmit: () => void }) => {
  const modelState = useContextModelState();
  const product = modelState.model as ProductViewModel;

  return (
    <Container>
      <Column md={8}>
        <FormRadioEnum
          propertyName={nameof<ProductDto>((p) => p.type)}
          enumName={enumName.ProductType}
        />
      </Column>

      <Column xs={4}>
        <FormNumber
          label="Código"
          intOnly={true}
          preventFormat={true}
          propertyName={nameof<ProductDto>((p) => p.code)}
        />
      </Column>

      <Row>
        <FormText label="Nombre" propertyName={nameof<ProductDto>((p) => p.name)} />
      </Row>

      <Row>
        <ComponentGroup direction={FlexDirection.column}>
          <FormSwitch
            label="Mostrar en compras"
            propertyName={nameof<ProductDto>((p) => p.isForPurchase)}
          />
          <FormSwitch
            label="Mostrar en ventas"
            style={{ background: "red" }}
            propertyName={nameof<ProductDto>((p) => p.isForSale)}
          />
        </ComponentGroup>
      </Row>

      {product.isForSale && (
        <>
          <Column>
            <FormSelectTax
              label="Impuesto"
              propertyName={nameof<ProductDto>((p) => p.info.defaultTax)}
            />
          </Column>

          <Column>
            <FormSelectCurrency
              label="Moneda"
              propertyName={nameof<ProductDto>((p) => p.info.defaultCurrency)}
            />
          </Column>

          <Column>
            <FormNumber
              label="Precio"
              propertyName={nameof<ProductDto>((p) => p.info.referencePrice)}
              showIncrementButtons={true}
            />
          </Column>

          <Row minHeightIgnore={true}>
            <FormText
              label="Descripción adicional"
              helpTooltip="Se agregará en la impresión del documento junto al producto. Se puede utilizar para la impresión de leyendas obligatorias de la normativa vigente."
              propertyName={nameof<ProductViewModel>((p) => p.info.saleLineAdditionalDescription)}
            />
          </Row>

          <Row minHeightIgnore={true}>
            <FormSwitch
              label="Agregar texto en adenda"
              propertyName={nameof<ProductViewModel>((p) => p.enableAddendumLegend)}
            />
          </Row>
          {product.enableAddendumLegend && (
            <Row>
              <FormText
                label="Leyenda"
                multiline={true}
                helpTooltip="Texto que se agregará en la adenda de la factura"
                propertyName={nameof<ProductDto>((p) => p.info.addendumAddLegend)}
              />
            </Row>
          )}
        </>
      )}
      <RowButtons>
        <ButtonAccept onClick={props.onSubmit} />
      </RowButtons>
    </Container>
  );
};

export default ProductForm;
