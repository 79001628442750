// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminTemplatePdfDto } from "../dtos/AdminTemplatePdfDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { AdminTemplatePdfFiltersDto } from "../dtos/AdminTemplatePdfFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { AdminTemplateDto } from "../dtos/AdminTemplateDto";

export class ApiAdminAccountPublicTemplatePdf {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<AdminTemplatePdfFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AdminTemplatePdfDto>> {
    return this.apiService.apiPost("/admin/account-public/templates/pdf/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplatePdfDto> {
    return this.apiService.apiGet("/admin/account-public/templates/pdf/" + id, apiOptions);
  }

  create(
    adminTemplatePdfDto: AdminTemplatePdfDto,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplatePdfDto> {
    return this.apiService.apiPost("/admin/account-public/templates/pdf", adminTemplatePdfDto, apiOptions);
  }

  edit(
    adminTemplatePdfDto: AdminTemplatePdfDto,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplatePdfDto> {
    return this.apiService.apiPut("/admin/account-public/templates/pdf", adminTemplatePdfDto, apiOptions);
  }

  inactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin/account-public/templates/pdf/inactivate/" + id, null, apiOptions);
  }

  reactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin/account-public/templates/pdf/reactivate/" + id, null, apiOptions);
  }

  getByType(
    templateType: number,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplateDto[]> {
    return this.apiService.apiGet("/admin/account-public/templates/pdf/by-type/" + templateType, apiOptions);
  }

}
