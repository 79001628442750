import { useEffect, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { EInvoiceEnvelopeStatusResponsePurchaseDto } from "../../api/app/dtos/EInvoiceEnvelopeStatusResponsePurchaseDto";
import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiEInvoiceEnvelopeStatusResponsePurchase } from "../../api/shared/hooks/useApiApp";
import { GridColumnAlign } from "../../shared/components/grid/Grid";
import GridClientSide from "../../shared/components/grid/GridClientSide";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import RowTitle from "../../shared/components/Layout/GRowTitle";

const PurchaseDetailsStatusResponses = (props: { purchase: PurchaseDto }) => {
  const [apiEInvoiceEnvelopeStatusResponsePurchase] =
    useApiEInvoiceEnvelopeStatusResponsePurchase();
  const [responseList, setResponseList] = useState<EInvoiceEnvelopeStatusResponsePurchaseDto[]>();
  const purchase = props.purchase;

  const itemTemplates: IGridItemTemplate<EInvoiceEnvelopeStatusResponsePurchaseDto>[] = [
    {
      header: "Fecha",
      field: nameof<EInvoiceEnvelopeStatusResponsePurchaseDto>((p) => p.date),
      cellType: GridCellType.dateTime,
      width: 10,
    },
    {
      header: "Tipo",
      field: nameof<EInvoiceEnvelopeStatusResponsePurchaseDto>((p) => p.type),
      cellType: GridCellType.enum,
      cellTypeEnumName: enumName.EInvoiceEnvelopeStatusResponseType,
      width: 10,
    },
    {
      header: "Notas",
      field: nameof<EInvoiceEnvelopeStatusResponsePurchaseDto>((p) => p.notes),
      width: 30,
    },
    {
      header: "Errores",
      field: nameof<EInvoiceEnvelopeStatusResponsePurchaseDto>((p) => p.errorMessage),
      width: 30,
    },
    {
      header: "Xml",
      field: nameof<EInvoiceEnvelopeStatusResponsePurchaseDto>((p) => p.xmlUrl),
      align: GridColumnAlign.center,
      cellType: GridCellType.download,
      width: 5,
    },
    {
      header: "",
      field: nameof<EInvoiceEnvelopeStatusResponsePurchaseDto>((p) => p.wsRequestLogUrl),
      align: GridColumnAlign.center,
      cellType: GridCellType.download,
      width: 5,
    },
    {
      header: "",
      field: nameof<EInvoiceEnvelopeStatusResponsePurchaseDto>((p) => p.wsResponseLogUrl),
      align: GridColumnAlign.center,
      cellType: GridCellType.download,
      width: 5,
    },
  ];

  useEffect(() => {
    const load = async () => {
      setResponseList(
        await apiEInvoiceEnvelopeStatusResponsePurchase.getAllByEnvelopeId(
          purchase.eInvoiceEnvelopePurchaseId
        )
      );
    };
    load();
  }, []);

  return (
    <>
      <RowTitle>Acuses de recibo</RowTitle>

      <GridClientSide templates={itemTemplates} items={responseList} />
    </>
  );
};

export default PurchaseDetailsStatusResponses;
