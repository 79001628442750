import Column from "./GColumn";
import { PropsRow } from "./GRow";

const ColumnDisplay = (props: PropsRow) => {
  return (
    <Column {...props} isDisplay={true}>
      {props.children}
    </Column>
  );
};

export default ColumnDisplay;
