import { nameof } from "ts-simple-nameof";
import { SaleLineDto } from "../../api/app/dtos/SaleLineDto";
import { SaleLineRetentionDto } from "../../api/app/dtos/SaleLineRetentionDto";
import ButtonLink from "../../shared/components/Button/ButtonLink";
import { TypeIcon } from "../../shared/components/Icon";
import { useIsMobile } from "../../shared/hooks/useIsMobile";
import ModelStateArray from "../../shared/modelState/ModelStateArray";
import { TextAlign } from "../../shared/TextAlign";
import { ThemeVariant } from "../../shared/ThemeVariant";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import { ISaleLineRetentionColumn } from "./SaleFormLines";
import SaleFormLineRow from "./SaleFormLines.Row";
import SaleLineRetentionsHeader from "./SaleLineRetentions.Header";
import SaleLineRetentionsItem from "./SaleLineRetentions.Item";

const addNewRetention = (saleLine: SaleLineDto) => {
  const saleLineRetention = new SaleLineRetentionDto();
  saleLineRetention.totalAmount = saleLine.subtotal;
  return saleLineRetention;
};

const SaleLineRetentions = (props: {
  lineIndex: number;
  propertyBaseName: string;
  saleLine: SaleLineDto;
  retentionColumns: ISaleLineRetentionColumn[];
  showLabel: boolean;
  variant: ThemeVariant;
}) => {
  const saleModelState = useContextModelStateSale();
  const isMobile = useIsMobile();
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {props.saleLine.retentions?.length > 0 && !isMobile && (
        <SaleFormLineRow>
          {props.retentionColumns.map((saleLineRetentionColumn, index) => (
            <SaleLineRetentionsHeader
              key={index}
              saleLineRetentionColumn={saleLineRetentionColumn}
            />
          ))}
        </SaleFormLineRow>
      )}
      <ModelStateArray
        propertyName={props.propertyBaseName + "." + nameof<SaleLineDto>((p) => p.retentions)}>
        {(arrayItemHelper) => (
          <SaleLineRetentionsItem
            key={arrayItemHelper.index}
            lineIndex={props.lineIndex}
            arrayItemHelper={arrayItemHelper}
            retentionsColumns={props.retentionColumns}
            showLabel={props.showLabel}
            variant={props.variant}
          />
        )}
      </ModelStateArray>
      <div style={{ width: "100%", textAlign: TextAlign.right }}>
        <ButtonLink
          icon={TypeIcon.plus}
          text="agregar retención"
          onClick={() =>
            saleModelState.lineRetentionAdd(props.lineIndex, addNewRetention(props.saleLine))
          }
        />
      </div>
    </div>
  );
};

export default SaleLineRetentions;
