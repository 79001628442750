import { useEffect, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { EmailConfigDto } from "../../../api/app/dtos/EmailConfigDto";
import { OAuthTokenResponseDto } from "../../../api/app/dtos/OAuthTokenResponseDto";
import { useApiEmailConfig } from "../../../api/shared/hooks/useApiApp";
import { EMPTY_GUID } from "../../../Constants";
import { AppSize } from "../../../shared/AppSize";
import ButtonAccept from "../../../shared/components/Button/ButtonAccept";
import ButtonSecondary from "../../../shared/components/Button/ButtonSecondary";
import FormNumber from "../../../shared/components/forms/FormNumber";
import FormPassword from "../../../shared/components/forms/FormPassword";
import FormSwitch from "../../../shared/components/forms/FormSwitch";
import FormText from "../../../shared/components/forms/FormText";
import Icon, { TypeIcon } from "../../../shared/components/Icon";
import IconCheck from "../../../shared/components/IconCheck";
import InfoText, { InfoTextType } from "../../../shared/components/InfoText";
import InputSelectEnumBase from "../../../shared/components/inputs/select/InputSelectEnumBase";
import ComponentGroup from "../../../shared/components/Layout/ComponentsGroup";
import Column from "../../../shared/components/Layout/GColumn";
import Container from "../../../shared/components/Layout/GContainer";
import Row from "../../../shared/components/Layout/GRow";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import RowTitle from "../../../shared/components/Layout/GRowTitle";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import { IdName } from "../../../shared/IdName";
import { useContextModelState } from "../../../shared/modelState/useContextModelState";
import { TextAlign } from "../../../shared/TextAlign";
import { AppThemeColor } from "../../../styles/color";
import { EmailConfigViewModel, EmailVerificationStatus } from "./EmailConfigViewModel";

export interface IIndexable {
  [key: string]: any;
}

const enum Provider {
  Other = 1,
  Gmail = 2,
  Hotmail = 3,
  Outlook = 4,
}

const providers: IdName[] = [
  { id: Provider.Gmail, name: "GMail" },
  { id: Provider.Hotmail, name: "Hotmail" },
  { id: Provider.Outlook, name: "Outlook" },
  { id: Provider.Other, name: "Otro proveedor" },
];

const providersConfig = {
  1: {
    smtpServer: "",
    smtpPort: 587,
    smtpSsl: true,
    imapServer: "",
    imapPort: 993,
    imapSsl: true,
  } as EmailConfigDto,
  2: {
    smtpServer: "smtp.gmail.com",
    smtpPort: 587,
    smtpSsl: true,
    imapServer: "imap.gmail.com",
    imapPort: 993,
    imapSsl: true,
    useOAuth: true,
  } as EmailConfigDto,
  3: {
    smtpServer: "smtp.hotmail.com",
    smtpPort: 587,
    smtpSsl: true,
    imapServer: "imap.hotmail.com",
    imapPort: 993,
    imapSsl: true,
  } as EmailConfigDto,
  4: {
    smtpServer: "smtp-mail.outlook.com",
    smtpPort: 587,
    smtpSsl: true,
    imapServer: "outlook.office365.com",
    imapPort: 993,
    imapSsl: true,
  } as EmailConfigDto,
} as IIndexable;

const ConfigEmailForm = (props: { onSubmit: () => void }) => {
  const [apiEmailConfig] = useApiEmailConfig();
  const modelState = useContextModelState();
  const emailConfig = modelState.model as EmailConfigViewModel;
  const navigation = useNavigation();
  const [linkOAuthInProgress, setLinkOAuthInProgress] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

  const [provider, setProvider] = useState(() => {
    if (!emailConfig.imapServer) {
      return Provider.Gmail;
    }

    let foundProvider = Provider.Gmail;
    Object.keys(providersConfig).forEach((provider) => {
      if (providersConfig[provider].imapServer == emailConfig.imapServer) {
        foundProvider = provider as any;
        return;
      }
    });
    return foundProvider;
  });

  const smtpVerify = async () => {
    modelState.updateProperty(
      nameof<EmailConfigViewModel>((p) => p.smtpVerificationStatus),
      EmailVerificationStatus.Verifying
    );
    try {
      await apiEmailConfig.smtpVerify(emailConfig, {
        preventSpinner: true,
      });
      modelState.updateProperty(
        nameof<EmailConfigViewModel>((p) => p.smtpVerificationStatus),
        EmailVerificationStatus.Success
      );
    } catch (err) {
      modelState.updateProperty(
        nameof<EmailConfigViewModel>((p) => p.smtpVerificationStatus),
        EmailVerificationStatus.Error
      );
      throw err;
    }
  };

  const imapVerify = async () => {
    modelState.updateProperty(
      nameof<EmailConfigViewModel>((p) => p.imapVerificationStatus),
      EmailVerificationStatus.Verifying
    );
    try {
      await apiEmailConfig.imapVerify(emailConfig, {
        preventSpinner: true,
      });
      modelState.updateProperty(
        nameof<EmailConfigViewModel>((p) => p.imapVerificationStatus),
        EmailVerificationStatus.Success
      );
    } catch (err) {
      modelState.updateProperty(
        nameof<EmailConfigViewModel>((p) => p.imapVerificationStatus),
        EmailVerificationStatus.Error
      );
      throw err;
    }
  };

  const onAuthChange = () => {
    modelState.updateProperty(
      nameof<EmailConfigViewModel>((p) => p.password),
      null
    );

    onValueChange();
  };

  const providerChange = (provider: number, isManuallyChanged: boolean) => {
    setProvider(provider);

    if (modelState.model.id && modelState.model.id != EMPTY_GUID && !isManuallyChanged) {
      return;
    }

    const knownProvider = !provider ? providersConfig[1] : providersConfig[provider];

    modelState.updateProperties([
      {
        propertyFullName: nameof<EmailConfigDto>((p) => p.smtpServer),
        value: knownProvider.smtpServer,
      },
      {
        propertyFullName: nameof<EmailConfigDto>((p) => p.smtpPort),
        value: knownProvider.smtpPort,
      },
      {
        propertyFullName: nameof<EmailConfigDto>((p) => p.smtpSsl),
        value: knownProvider.smtpSsl,
      },
      {
        propertyFullName: nameof<EmailConfigDto>((p) => p.imapPort),
        value: knownProvider.imapPort,
      },
      {
        propertyFullName: nameof<EmailConfigDto>((p) => p.imapServer),
        value: knownProvider.imapServer,
      },
      {
        propertyFullName: nameof<EmailConfigDto>((p) => p.imapSsl),
        value: knownProvider.imapSsl,
      },
      {
        propertyFullName: nameof<EmailConfigViewModel>((p) => p.smtpVerificationStatus),
        value: EmailVerificationStatus.Pending,
      },
      {
        propertyFullName: nameof<EmailConfigViewModel>((p) => p.imapVerificationStatus),
        value: EmailVerificationStatus.Pending,
      },
      {
        propertyFullName: nameof<EmailConfigViewModel>((p) => p.useOAuth),
        value: knownProvider.useOAuth,
      },
    ]);
  };

  const providerManuallyChanged = (provider: number) => {
    setProvider(provider);
    providerChange(provider, true);
  };

  const onValueChange = () => {
    modelState.updateProperty(
      nameof<EmailConfigViewModel>((p) => p.smtpVerificationStatus),
      EmailVerificationStatus.Pending
    );
    modelState.updateProperty(
      nameof<EmailConfigViewModel>((p) => p.imapVerificationStatus),
      EmailVerificationStatus.Pending
    );
  };

  useEffect(() => {
    providerChange(provider, false);
  }, []);

  let imapIcon = TypeIcon.pending;

  if (emailConfig.imapVerificationStatus != EmailVerificationStatus.Pending) {
    imapIcon =
      emailConfig.imapVerificationStatus == EmailVerificationStatus.Success
        ? TypeIcon.check
        : TypeIcon.error;
  }

  let smtpIcon = TypeIcon.pending;

  if (emailConfig.smtpVerificationStatus != EmailVerificationStatus.Pending) {
    smtpIcon =
      emailConfig.smtpVerificationStatus == EmailVerificationStatus.Success
        ? TypeIcon.check
        : TypeIcon.error;
  }

  const checkOAuthResponse = () => {
    const tokenString = localStorage.getItem("gesta_oauth_success");
    if (tokenString) {
      if (intervalId) {
        clearInterval(intervalId);
      }

      localStorage.removeItem("gesta_oauth_success");
      const token = JSON.parse(tokenString) as OAuthTokenResponseDto;
      modelState.updateProperty(
        nameof<EmailConfigViewModel>((p) => p.user),
        token.email
      );
      modelState.updateProperty(
        nameof<EmailConfigViewModel>((p) => p.password),
        token.refreshToken
      );
    }
  };

  const onLinkOAuthClick = async () => {
    const request = async () => {
      setLinkOAuthInProgress(true);
      const aAuthUri = await apiEmailConfig.oAuthReceiveAccessTokenGmail({
        preventSpinner: true,
      });
      navigation.newTab(aAuthUri.uri);

      setIntervalId(setInterval(checkOAuthResponse, 2000));
    };
    request();
  };

  useEffect(() => {
    // Clean up the interval on component unmount
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  return (
    <>
      <Column md={4}>
        <Container>
          <RowTitle>Proveedor</RowTitle>
          <Row>
            <InputSelectEnumBase
              label="Proveedor"
              value={provider}
              onChange={providerManuallyChanged}
              options={providers}
            />
          </Row>
          {provider == Provider.Gmail && (
            <>
              {emailConfig.useOAuth && <GmailWarningOAuth />}
              {!emailConfig.useOAuth && <GmailWarning />}
            </>
          )}
        </Container>
      </Column>
      <Column md={8}>
        <Container>
          <RowTitle>Usuario</RowTitle>
          <Row minHeightIgnore={true}>
            <FormSwitch
              label="Utilizar autenticación por OAuth"
              propertyName={nameof<EmailConfigDto>((p) => p.useOAuth)}
              onValueChange={onAuthChange}
            />
          </Row>

          {emailConfig.useOAuth && (
            <>
              {provider != Provider.Gmail && (
                <Row>
                  <InfoText>El proveedor no soporta OAuth</InfoText>
                </Row>
              )}
              {provider == Provider.Gmail && (
                <Row>
                  {!emailConfig.password && (
                    <ButtonSecondary
                      icon={TypeIcon.email}
                      text={"Conectar con tu cuenta GMail"}
                      onClick={onLinkOAuthClick}
                      loading={linkOAuthInProgress}
                    />
                  )}
                  {emailConfig.password && (
                    <>
                      <Row minHeightIgnore={true}>
                        <IconCheck value={true} size={AppSize.bigger} />
                      </Row>
                      <Row minHeightIgnore={true}>
                        Cuenta vinculada: <strong>{emailConfig.user}</strong>
                      </Row>
                    </>
                  )}
                </Row>
              )}
            </>
          )}
          {!emailConfig.useOAuth && (
            <>
              <Row>
                <FormText
                  label="Usuario"
                  propertyName={nameof<EmailConfigDto>((p) => p.user)}
                  onValueChange={onValueChange}
                />
              </Row>
              <Row>
                <FormPassword
                  label="Contraseña"
                  propertyName={nameof<EmailConfigDto>((p) => p.password)}
                  onValueChange={onValueChange}
                />
              </Row>
            </>
          )}
          <RowTitle>IMAP</RowTitle>
          <Column md={6}>
            <FormText
              label="Servidor"
              propertyName={nameof<EmailConfigDto>((p) => p.imapServer)}
              onValueChange={onValueChange}
            />
          </Column>
          <Column md={2}>
            <FormNumber
              label="Puerto"
              intOnly={true}
              propertyName={nameof<EmailConfigDto>((p) => p.imapPort)}
              onValueChange={onValueChange}
            />
          </Column>
          <Column md={2} label="SSL">
            <FormSwitch
              label="Habilitar"
              propertyName={nameof<EmailConfigDto>((p) => p.imapSsl)}
              onValueChange={onValueChange}
            />
          </Column>
          <Column md={2} label="Verificación">
            <Icon
              color={AppThemeColor.success}
              isLoading={emailConfig.imapVerificationStatus == EmailVerificationStatus.Verifying}
              type={imapIcon}
            />
          </Column>
          <RowTitle>SMTP</RowTitle>
          <Column md={6}>
            <FormText
              label="Servidor"
              propertyName={nameof<EmailConfigDto>((p) => p.smtpServer)}
              onValueChange={onValueChange}
            />
          </Column>
          <Column md={2}>
            <FormNumber
              label="Puerto"
              intOnly={true}
              propertyName={nameof<EmailConfigDto>((p) => p.smtpPort)}
              onValueChange={onValueChange}
            />
          </Column>
          <Column md={2} label="SSL">
            <FormSwitch
              label="Habilitar"
              propertyName={nameof<EmailConfigDto>((p) => p.smtpSsl)}
              onValueChange={onValueChange}
            />
          </Column>
          <Column md={2} label="Verificación">
            <Icon
              color={AppThemeColor.success}
              isLoading={emailConfig.smtpVerificationStatus == EmailVerificationStatus.Verifying}
              type={smtpIcon}
            />
          </Column>
        </Container>
      </Column>
      <RowButtons>
        <ComponentGroup align={TextAlign.right}>
          <ButtonSecondary
            text="Verificar conexión"
            disabled={
              emailConfig.imapVerificationStatus == EmailVerificationStatus.Success &&
              emailConfig.smtpVerificationStatus == EmailVerificationStatus.Success
            }
            icon={TypeIcon.checkCircle}
            onClick={() => {
              smtpVerify();
              imapVerify();
            }}
          />
          <ButtonAccept
            disabled={
              !(
                emailConfig.imapVerificationStatus == EmailVerificationStatus.Success &&
                emailConfig.smtpVerificationStatus == EmailVerificationStatus.Success
              )
            }
            onClick={props.onSubmit}
          />
        </ComponentGroup>
      </RowButtons>
    </>
  );
};

export default ConfigEmailForm;

const GmailWarning = () => {
  return (
    <Row>
      <InfoText>
        <strong>GMAIL</strong>
        <br />
        <br />
        <strong>Paso 1</strong>
        <br />
        Habilitar la{" "}
        <a
          href="https://myaccount.google.com/signinoptions/two-step-verification"
          target="_blank"
          rel="noreferrer">
          autenticación en 2 pasos
        </a>
        <br />
        <br />
        <strong>Paso 2</strong>
        <br />
        Generar una{" "}
        <a href="https://myaccount.google.com/apppasswords" target="_blank" rel="noreferrer">
          contraseña de aplicación
        </a>
        <br />
        <br />
        <strong>Paso 3</strong>
        <br />
        Verificar en las configuraciones de gmail que se encuentre habilitado el acceso IMAP.
        <br />
        <br />
        <InfoText type={InfoTextType.warning}>
          La autenticación clásica ya no es recomendada. En su lugar, se recomienda utilizar OAuth.
        </InfoText>
      </InfoText>
    </Row>
  );
};

const GmailWarningOAuth = () => {
  return (
    <Row>
      <InfoText>
        <strong>GMAIL (OAuth)</strong>
        <br />
        <br />
        Únicamente se requiere hacer click en "Otorgar permisos de OAuth" para poder conectarnos al
        correo
      </InfoText>
    </Row>
  );
};
