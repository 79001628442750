import { AppRoute } from "../../AppRoutes";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Row from "../../shared/components/Layout/GRow";
import Page from "../../shared/components/Layout/Page";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { TextAlign } from "../../shared/TextAlign";
import { ThemeSize } from "../../shared/ThemeSize";

const AdminTemplates = () => {
  const navigation = useNavigation();
  return (
    <Page title="Plantillas">
      <Row>
        <ComponentGroup align={TextAlign.center}>
          {/* <ButtonSecondary
            text="Plantillas genéricas"
            icon={TypeIcon.file}
            size={ThemeSize.medium}
            onClick={() => navigation.go(AppRoute.admin.templates.generic.base)}
          /> */}
          <ButtonSecondary
            text="Plantillas de correo"
            icon={TypeIcon.email}
            size={ThemeSize.medium}
            onClick={() => navigation.go(AppRoute.adminProvider.templates.email.base)}
          />
          <ButtonSecondary
            text="Plantillas de pdf"
            icon={TypeIcon.pdf}
            size={ThemeSize.medium}
            onClick={() => navigation.go(AppRoute.adminProvider.templates.pdf.base)}
          />
        </ComponentGroup>
      </Row>
    </Page>
  );
};

export default AdminTemplates;
