import { useState } from "react";
import { RequestedExportDto } from "../../../api/app/dtos/RequestedExportDto";
import { ApiOptions } from "../../../api/shared/apis/ApiOptions";
import { ExportRequestDto } from "../../../api/shared/dtos/ExportRequestDto";
import { ExportGridColumnOption } from "../../../api/shared/enums/ExportGridColumnOption";
import { GridName } from "../../../api/shared/enums/GridName";
import { AppSize } from "../../AppSize";
import { useContextAppInfo } from "../../hooks/useContextAppInfo";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useModal } from "../../hooks/useModal";
import ButtonIcon from "../Button/ButtonIcon";
import ButtonIconSmall from "../Button/ButtonIconSmall";
import { TypeIcon } from "../Icon";
import ComponentGroup from "../Layout/ComponentsGroup";
import Column from "../Layout/GColumn";
import Container from "../Layout/GContainer";
import { IGridState } from "./GridContext";
import GridExportPopup from "./GridExportPopup";
import GridIncludeInactiveCheck from "./GridIncludeInactiveCheck";
import GridSettings from "./GridSettings";
import { useContextGrid } from "./useContextGrid";

const PagedGridFooter = (props: {
  hideIncludeInactiveButton?: boolean;
  onExport?(exportRequest: ExportRequestDto<any>, options: ApiOptions): Promise<RequestedExportDto>;
}) => {
  const grid = useContextGrid();
  const isMobile = useIsMobile();
  const [modal] = useModal();
  const [loadingExport, setLoadingExport] = useState(false);
  const { requestedExportListOpen } = useContextAppInfo();

  if (!grid.gridState || !grid.gridState.pagedResult) {
    return null;
  }

  const nextPage = (gridState: IGridState<any>) => {
    grid.changePage((gridState?.pagedResult?.currentPageIndex ?? 0) + 1);
  };

  const previousPage = (gridState: IGridState<any>) => {
    grid.changePage((gridState?.pagedResult?.currentPageIndex ?? 1) - 1);
  };

  let tooltip = "Exportar";
  if (grid.gridState.filtersPendingApply) {
    tooltip = "Aplicá los filtros para poder exportar";
  } else if (!grid.gridState.pagedResult?.totalItemCount) {
    tooltip = "No hay registros para exportar";
  }

  return (
    <Container>
      <Column md={6} xs={4} minHeightIgnore={true}>
        <ComponentGroup>
          {props.onExport && (
            <ButtonIcon
              disabled={
                grid.gridState.filtersPendingApply || !grid.gridState.pagedResult?.totalItemCount
              }
              noPadding={true}
              tooltip={tooltip}
              loading={loadingExport}
              icon={TypeIcon.exportToExcel}
              onClick={() => {
                modal.open(
                  <GridExportPopup
                    totalItemCount={grid.gridState.pagedResult?.totalItemCount ?? 0}
                    gridName={grid.gridState.settings?.name ?? GridName.Contacts}
                    onExport={(columnOption: ExportGridColumnOption, fileName: string) => {
                      setLoadingExport(true);
                      const request = async () => {
                        if (!props.onExport) {
                          return;
                        }
                        const exportRequestDto = new ExportRequestDto();
                        exportRequestDto.filters = grid.gridState.searchDto.filters;
                        exportRequestDto.includeInactive = grid.gridState.searchDto.includeInactive;
                        exportRequestDto.orderBy = grid.gridState.searchDto.orderBy;
                        exportRequestDto.fileName = fileName;
                        if (columnOption === ExportGridColumnOption.SameAsGrid) {
                          exportRequestDto.includedFields = grid.gridState.searchDto.includedFields;
                        } else if (columnOption === ExportGridColumnOption.AllColumns) {
                          exportRequestDto.includedAllFields = true;
                        }
                        try {
                          await props.onExport(exportRequestDto, { preventSpinner: true });
                          requestedExportListOpen(true);
                        } finally {
                          setLoadingExport(false);
                        }
                      };
                      request();
                    }}
                  />
                );
              }}
            />
          )}
          {grid.gridState.pagedResult?.currentPageItemCount > 0 && (
            <div className="grid-footer-left">
              {grid.gridState.selection.selectedCount > 0
                ? "Seleccionados " + grid.gridState.selection.selectedCount
                : (isMobile ? "" : "Mostrando ") + grid.gridState.pagedResult.currentPageItemCount}
              {" de "}
              <strong>
                {grid.gridState.pagedResult.pageContinuationToken
                  ? "?"
                  : grid.gridState.pagedResult.totalItemCount}
              </strong>
            </div>
          )}
        </ComponentGroup>
      </Column>
      <Column md={6} xs={8} minHeightIgnore={true}>
        <div className="grid-footer-right">
          {!isMobile && !props.hideIncludeInactiveButton && <GridIncludeInactiveCheck />}

          {!!grid.gridState.pagedResult && (
            <div className="pagination">
              {grid.gridState.pagedResult.currentPageIndex > 1 && (
                <div className="pagination-item previous">
                  <ButtonIconSmall
                    icon={TypeIcon.arrowLeft}
                    disabled={grid.gridState.pagedResult.currentPageIndex === 1}
                    onClick={() => previousPage(grid.gridState)}
                  />
                </div>
              )}
              {(grid.gridState.pagedResult.totalPageCount ||
                grid.gridState.pagedResult.usingContinuationToken) && (
                <>
                  <div className="pagination-item info">
                    Página <strong>{grid.gridState.pagedResult.currentPageIndex}</strong> de{" "}
                    <strong>
                      {grid.gridState.pagedResult.totalPageCount
                        ? grid.gridState.pagedResult.totalPageCount
                        : "?"}
                    </strong>
                  </div>
                  {grid.gridState.pagedResult.currentPageIndex !==
                    grid.gridState.pagedResult.totalPageCount && (
                    <div className="pagination-item next">
                      <ButtonIconSmall
                        icon={TypeIcon.arrowRight}
                        disabled={
                          grid.gridState.pagedResult.currentPageIndex ===
                          grid.gridState.pagedResult.totalPageCount
                        }
                        onClick={() => nextPage(grid.gridState)}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {grid.gridState.settings?.name && (
            <div className="grid-settings">
              <ButtonIconSmall
                icon={TypeIcon.config}
                style={{
                  marginLeft: 15,
                }}
                size={AppSize.small}
                onClick={() => {
                  if (!grid.gridState.settings) {
                    return;
                  }

                  modal.open(
                    <GridSettings
                      gridSettings={grid.gridState.settings}
                      visibleColumns={grid.gridState.visibleColumns}
                      columnTemplates={grid.gridState.columnTemplates}
                    />,
                    () => grid.settingsUpdate()
                  );
                }}
              />
            </div>
          )}
        </div>
      </Column>
    </Container>
  );
};

export default PagedGridFooter;
