// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminDevUtils {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  salesClearAll(
    accountId: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/admin/dev-utils/sale-clear-all?" + 
      (accountId ? "&accountId=" + accountId : ""), null, apiOptions);
  }

  purchasesClearAll(
    accountId: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/admin/dev-utils/purchases-clear-all?" + 
      (accountId ? "&accountId=" + accountId : ""), null, apiOptions);
  }

}
