// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { EInvoiceDailyReportDto } from "../dtos/EInvoiceDailyReportDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { EInvoiceDailyReportsFiltersDto } from "../dtos/EInvoiceDailyReportsFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiEInvoiceDailyReport {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<EInvoiceDailyReportsFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<EInvoiceDailyReportDto>> {
    return this.apiService.apiPost("/e-invoice-daily-report/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceDailyReportDto> {
    return this.apiService.apiGet("/e-invoice-daily-report/" + id, apiOptions);
  }

}
