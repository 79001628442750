import { ApiPurchase } from "../../../api/app/apis/ApiPurchase";
import { PurchaseDto } from "../../../api/app/dtos/PurchaseDto";
import { ContextMenuItem } from "../../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../../shared/components/Icon";
import ModalDialog from "../../../shared/components/Modal/ModalDialog";
import { ModalService } from "../../../shared/hooks/useModal";
import { Navigation } from "../../../shared/hooks/useNavigation";
import { Notifier } from "../../../shared/hooks/useNotifier";
import PurchaseDetailsPopup from "../PurchaseDetailsPopup";

export function getEInvoicePurchasePendingListItemMenu(
  purchaseDto: PurchaseDto,
  apiPurchase: ApiPurchase,
  modal: ModalService,
  notifier: Notifier,
  navigation: Navigation,
  onReloadGrid: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        modal.open(<PurchaseDetailsPopup purchaseId={purchaseDto.id} />, onReloadGrid);
      },
    },
    {
      text: "Rechazar",
      icon: TypeIcon.deleteCross,
      onClick: () => {
        modal.open(<ModalDialog text="¿Seguro desea rechazar el comprobante?" />, () => {
          const request = async () => {
            await apiPurchase.reject(purchaseDto.id);
            notifier.showSuccessFast("El comprobante se rechazó correctamente");
            onReloadGrid();
          };
          request();
        });
      },
    },
    {
      text: "Descargar XML sobre",
      hide: !purchaseDto.eInvoiceEnvelopePurchase?.xmlUrl,
      icon: TypeIcon.xml,
      onClick: () => {
        navigation.newTab(purchaseDto.eInvoiceEnvelopePurchase.xmlUrl);
      },
    },
  ];
}
