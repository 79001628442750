import { nameof } from "ts-simple-nameof";
import { AdminShardDto } from "../../api/admin/dtos/AdminShardDto";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormCheck from "../../shared/components/forms/FormCheck";
import FormText from "../../shared/components/forms/FormText";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import { useContextModelState } from "../../shared/modelState/useContextModelState";

const AdminShardForm = (props: { onSave(): void }) => {
  const modelState = useContextModelState();
  const adminShard = modelState.model as AdminShardDto;

  return (
    <>
      <Row>
        <FormText label="Nombre" propertyName={nameof<AdminShardDto>((p) => p.name)} />
      </Row>

      <Row>
        <FormCheck
          label="Obtener cadena de conexión desde el KeyVault"
          propertyName={nameof<AdminShardDto>((p) => p.getConnectionFromSecret)}
        />
      </Row>

      <Row>
        <FormText
          label={adminShard.getConnectionFromSecret ? "Nombre del KeyVault" : "Cadena de conexión"}
          propertyName={nameof<AdminShardDto>((p) => p.connectionString)}
        />
      </Row>

      <RowButtons>
        <ButtonAccept onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default AdminShardForm;
