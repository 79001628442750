import { forwardRef } from "react";
import { nameof } from "ts-simple-nameof";
import { AccountUserDto } from "../../../../api/app/dtos/AccountUserDto";
import InputAutocompleteAccountUser, {
  PropsInputAutocompleteAccountUser,
} from "../../inputs/autocomplete/InputAutocompleteAccountUser";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormAutocompleteAccountUser
  extends PropsFormInput<AccountUserDto>,
    Omit<
      PropsInputAutocompleteAccountUser,
      "value" | "onChange" | "onCreateNewClick" | "onGetEntity" | "onServerSideSuggest"
    > {}

const FormAutocompleteAccountUser = forwardRef(
  (props: PropsFormAutocompleteAccountUser, ref?: any) => {
    const inputProps = useFormInputInit(props, ref, {
      updateIdLinkedProperty: true,
      inputTextValuePropertyName: nameof<AccountUserDto>((p) => p.email),
    });
    return <InputAutocompleteAccountUser {...props} {...inputProps} />;
  }
);

export default FormAutocompleteAccountUser;
