import ValidationResultMessage from "./ValidationResultMessage";

export default abstract class ValidationResultBase {
  isValid: boolean;
  validations: ValidationResultMessage[] = [];

  get submitSummary(): string[] {
    return this.validations
      .filter((c) => c.displayAtSubmitSummary)
      .map(
        (c) =>
          (c.basePropertyFriendlyName
            ? c.basePropertyFriendlyName + ": "
            : "") + c.message
      );
  }

  abstract getTouchedValidation(propertyFullName: string): string;
}
