import { nameof } from "ts-simple-nameof";
import { SaleReferenceDto } from "../../api/app/dtos/SaleReferenceDto";
import FormNumber from "../../shared/components/forms/FormNumber";
import { ISaleReferenceColumnRenderProps } from "./SaleFormReferences";

const SaleFormReferenceColumnNumber = (props: ISaleReferenceColumnRenderProps) => {
  return (
    <FormNumber
      arrayItemHelper={props.arrayItemHelper}
      intOnly={true}
      showZeroAsEmpty={true}
      label={props.showLabel ? props.saleReferenceColumn.header : ""}
      propertyName={nameof<SaleReferenceDto>((p) => p.number)}
      variant={props.variant}
    />
  );
};

export default SaleFormReferenceColumnNumber;
