import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import { TypeIcon } from "../../shared/components/Icon";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import ModalDialog from "../../shared/components/Modal/ModalDialog";
import { useModal } from "../../shared/hooks/useModal";
import { TextAlign } from "../../shared/TextAlign";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import { ISaleLineRetentionColumnRenderProps } from "./SaleFormLines";

const SaleLineRetentionColumnTotalAmount = (props: ISaleLineRetentionColumnRenderProps) => {
  const saleModelState = useContextModelStateSale();
  const [modal] = useModal();
  return (
    <ComponentGroup align={TextAlign.right}>
      <ButtonIcon
        icon={TypeIcon.delete}
        onClick={() => {
          modal.open(<ModalDialog text="¿Está seguro de eliminar la retención?" />, () => {
            saleModelState.lineRetentionRemove(props.lineIndex, props.arrayItemHelper.index);
          });
        }}
      />
    </ComponentGroup>
  );
};

export default SaleLineRetentionColumnTotalAmount;
