import { nameof } from "ts-simple-nameof";
import { PointOfSaleDto } from "../../../../api/app/dtos/PointOfSaleDto";
import { validate } from "../../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../../shared/validation/schema/ValidationSchema";

export function useAdminPointOfSaleValidator() {
  const schema = new ValidationSchema();

  schema[nameof<PointOfSaleDto>((p) => p.accountBranch)] = validate("Sucursal").required();
  schema[nameof<PointOfSaleDto>((p) => p.name)] = validate("Nombre").required();

  return schema;
}
