import { nameof } from "ts-simple-nameof";
import { AdminTemplateDto } from "../../../api/admin/dtos/AdminTemplateDto";
import { AdminTemplateGenericDto } from "../../../api/admin/dtos/AdminTemplateGenericDto";
import { enumName } from "../../../api/shared/enums/_enumName";
import { useContextLoginInfo } from "../../hooks/useContextLoginInfo";
import useUtils from "../../hooks/useUtils";
import { useContextModelState } from "../../modelState/useContextModelState";
import { templateTypeIsAdmin, templateTypeIsGeneric } from "../../utils/utilTemplate";
import FormSwitch from "../forms/FormSwitch";
import FormText from "../forms/FormText";
import Column from "../Layout/GColumn";
import ColumnDisplay from "../Layout/GColumnDisplay";
import Row from "../Layout/GRow";
import Section from "../Layout/GSection";

const TemplateEditorInfoSection = () => {
  const { utils } = useUtils();
  const modelState = useContextModelState();
  const { loginInfo } = useContextLoginInfo();
  const template = modelState.model as AdminTemplateDto;
  const genericType = (template as AdminTemplateGenericDto).genericType;
  const templateIsAdmin = templateTypeIsAdmin(template.type);

  return (
    <Row>
      <Section border={true}>
        <ColumnDisplay md={2} label="Tipo de plantilla">
          {utils.enum.translate(enumName.TemplateType, template.type)}
          {genericType
            ? " (" + utils.enum.translate(enumName.TemplateGenericType, genericType) + ")"
            : ""}
        </ColumnDisplay>
        {loginInfo.authInfo.isSuperAdmin && (
          <>
            {!templateIsAdmin && (
              <ColumnDisplay label="Cuenta" md={2}>
                {template.adminAccount && template.adminAccount.name}
                {!template.adminAccount && <>Global</>}
              </ColumnDisplay>
            )}
            {template.country && (
              <ColumnDisplay label="País" md={2}>
                {utils.enum.translateCountry(template.country)}
              </ColumnDisplay>
            )}
          </>
        )}
        <Column md={3}>
          <FormText
            label="Nombre"
            propertyName={nameof<AdminTemplateDto>((p) => p.name)}
            onValueChange={(value) => {
              const code = (value ?? "").replace(/\s/g, "").replace("#", "").replace("%", "");
              modelState.updateProperty(
                nameof<AdminTemplateGenericDto>((p) => p.code),
                code
              );
            }}
          />
        </Column>

        {templateTypeIsGeneric(template.type) && (
          <>
            <Column md={2}>
              <FormText
                label="Código"
                propertyName={nameof<AdminTemplateGenericDto>((p) => p.code)}
                helpTooltip="El código es el texto con el que hará referencia a esta plantilla cuando se requiera utilizar desde otra plantilla"
              />
            </Column>
            <Column md={3}>
              <FormText
                label="Texto de ayuda"
                propertyName={nameof<AdminTemplateGenericDto>((p) => p.helpText)}
                helpTooltip="Este texto se mostrará como información al momento de mostrar el listado de plantillas genéricas disponibles"
              />
            </Column>
          </>
        )}

        {!templateTypeIsGeneric(template.type) && (
          <Column md={2}>
            <FormSwitch
              label="Por defecto"
              propertyName={nameof<AdminTemplateDto>((p) => p.isDefault)}
            />
          </Column>
        )}
      </Section>
    </Row>
  );
};

export default TemplateEditorInfoSection;
