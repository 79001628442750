import { useEffect, useState } from "react";
import useUtils from "../../../hooks/useUtils";
import { IdGuidName } from "../../../IdGuidName";
import InputSelectBase from "./InputSelectBase";
import { PropsInputSelectEnumBase } from "./InputSelectEnumBase";

export interface PropsInputSelectEnum
  extends Omit<PropsInputSelectEnumBase<number | null>, "options"> {
  enumTypeName: string;
  optionValues?: any[];
}

const InputSelectEnum = (props: PropsInputSelectEnum) => {
  const { utils } = useUtils();
  const [options, setOptions] = useState<IdGuidName[]>();

  useEffect(() => {
    const mappedOptions = (
      props.optionValues
        ? utils.enum.valuesToIdName(props.enumTypeName, props.optionValues)
        : utils.enum.toIdName(props.enumTypeName)
    ).map((c) => new IdGuidName(c.id.toString(), c.name));

    setOptions(mappedOptions);
  }, [props.optionValues]);

  if (!options) {
    return <></>;
  }

  return (
    <InputSelectBase
      {...props}
      options={options}
      onChange={(e) => {
        const parsedNumber = parseInt(e.target.value as any);
        if (isNaN(parsedNumber)) {
          props.onChange(null);
        } else {
          props.onChange(parsedNumber);
        }
      }}
    />
  );
};

export default InputSelectEnum;
