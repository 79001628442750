import { forwardRef } from "react";
import InputPassword, { PropsInputPassword } from "../inputs/InputPassword";
import { PropsFormInput, useFormInputInit } from "./_formInputProps";

interface PropsFormPassword
  extends PropsFormInput<string | null>,
    Omit<PropsInputPassword, "value" | "onChange"> {}

const FormPassword = forwardRef((props: PropsFormPassword, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputPassword {...props} {...inputProps} />;
});

export default FormPassword;
