import { CSSProperties, useState } from "react";
import { FileUploadExtensionsAllowed } from "../../api/shared/enums/FileUploadExtensionsAllowed";
import { AppEnvironment } from "../../AppEnvironment";
import { useAppTheme } from "../../AppThemeProvider";
import { AppThemeColor } from "../../styles/color";
import { fileInputAccept } from "../utils/utilFiles";
import { ButtonSecondary } from "./Button/ButtonSecondary";
import Icon, { TypeIcon } from "./Icon";
import IconCheck from "./IconCheck";

export interface PropsFileUpload {
  style?: CSSProperties;
  endpointUrl: string;
  extensionsAllowed: FileUploadExtensionsAllowed;
  onUploadStart?: () => void;
  onUploadError?: () => void;
  onUploadComplete: (response: any) => void;
}

const FileImport = (props: PropsFileUpload) => {
  let inputFileRef = null as any;
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const appTheme = useAppTheme();

  const postFile = (file: any) => {
    const apiUrl = new URL(AppEnvironment.API_BASE_URL + props.endpointUrl);
    const formData = new FormData();
    formData.append("file", file);

    const request = new XMLHttpRequest();
    request.open("POST", apiUrl.toString());
    request.upload.addEventListener("progress", function (e) {
      setIsUploadComplete(false);
      setIsLoading(true);
      setProgress((e.loaded / e.total) * 100);
    });

    request.addEventListener("load", () => {
      if (request.status !== 200) {
        const errorDetails = JSON.parse(request.responseText);
        const errorMessage = errorDetails.message ?? request.responseText;

        setError("Error subiendo el archivo: " + errorMessage);
        setIsLoading(false);
        setProgress(0);
        if (props.onUploadError) {
          props.onUploadError();
        }
        return;
      }
      setIsLoading(false);
      setProgress(0);
      setIsUploadComplete(true);
      props.onUploadComplete(JSON.parse(request.response));
    });

    request.addEventListener("error", (error) => {
      setIsLoading(false);
      setProgress(0);
      setError("Error subiendo el archivo: " + error.toString());
      if (props.onUploadError) {
        props.onUploadError();
      }
    });

    request.setRequestHeader("Authorization", `Bearer ${localStorage.getItem("auth-token")}`);
    request.send(formData);
    if (props.onUploadStart) {
      props.onUploadStart();
    }
  };

  const onChangeHandler = (e: any) => {
    postFile(e.target.files[0]);
  };

  const defaultStyles: CSSProperties = {
    display: "inline-block",
    width: "100%",
  };

  const styles = { ...defaultStyles, ...props.style };

  if (isLoading) {
    return (
      <div style={{ fontSize: "1.25em" }}>
        <strong style={{ color: appTheme.toHexColor(AppThemeColor.success) }}>
          {Math.floor(progress)}%
        </strong>
        {progress >= 100 && <IconCheck value={true} />}
      </div>
    );
  }

  return (
    <div style={styles}>
      {isUploadComplete && (
        <div
          style={{
            display: "inline-block",
            border: "2px solid gray",
            borderRadius: 4,
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 6,
            paddingLeft: 6,
            fontSize: "1.25em",
            color: "gray",
            cursor: "pointer",
          }}>
          <IconCheck value={true} />
        </div>
      )}
      {!isUploadComplete && (
        <ButtonSecondary
          text={"Subir archivo"}
          icon={TypeIcon.uploadFile}
          onClick={() => inputFileRef.click()}
        />
      )}
      {error && (
        <div
          style={{
            marginTop: 10,
            color: appTheme.toHexColor(AppThemeColor.alert),
            fontSize: "1.25em",
          }}>
          <Icon type={TypeIcon.error} color={AppThemeColor.alert} style={{ marginRight: 10 }} />
          {error}
        </div>
      )}
      <input
        type="file"
        name="file"
        accept={fileInputAccept(props.extensionsAllowed)}
        hidden
        onChange={(e) => onChangeHandler(e)}
        ref={(ref) => (inputFileRef = ref)}
      />
    </div>
  );
};

export default FileImport;
