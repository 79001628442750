import { useEffect, useState } from "react";
import { AdminProviderEmailConfigDto } from "../../../api/admin/dtos/AdminProviderEmailConfigDto";
import { useApiAdminProviderEmailConfig } from "../../../api/shared/hooks/useApiAdminProviders";
import { useApiAdminEmailConfigAdminProvider } from "../../../api/shared/hooks/useApiAdmin";
import ConfigEmailForm from "../../../app/Configuration/ConfigEmail/ConfigEmailForm";
import { EmailConfigViewModel } from "../../../app/Configuration/ConfigEmail/EmailConfigViewModel";
import { useEmailConfigValidations } from "../../../app/Configuration/ConfigEmail/Validations/useEmailConfigValidations";
import { EMPTY_GUID } from "../../../Constants";
import Container from "../../../shared/components/Layout/GContainer";
import { AppInfoSiteType } from "../../../shared/context/AppInfoProvider";
import { ModalSize } from "../../../shared/context/ModalProvider";
import { useContextAppInfo } from "../../../shared/hooks/useContextAppInfo";
import { useModal } from "../../../shared/hooks/useModal";
import { StateProvider } from "../../../shared/modelState/ModelStateContext";

const AdminProviderEmailConfigPopup = (props: { adminProviderId?: string }) => {
  const [modal] = useModal();
  const [apiAdminProviderEmailConfig] = useApiAdminProviderEmailConfig();
  const [apiAdminEmailConfigAdminProvider] = useApiAdminEmailConfigAdminProvider();
  const [emailConfig, setEmailConfig] = useState<EmailConfigViewModel>();
  const { appInfo } = useContextAppInfo();

  if (appInfo?.siteType != AppInfoSiteType.AdminProvider && !props.adminProviderId) {
    throw new Error("AdminProviderId is required");
  }

  const save = async (adminProviderEmailConfigDto: AdminProviderEmailConfigDto) => {
    if (adminProviderEmailConfigDto.id == EMPTY_GUID) {
      if (appInfo?.siteType == AppInfoSiteType.SuperAdmin) {
        await apiAdminEmailConfigAdminProvider.create(
          props.adminProviderId ?? "",
          adminProviderEmailConfigDto
        );
      } else {
        await apiAdminProviderEmailConfig.create(adminProviderEmailConfigDto);
      }
    } else {
      if (appInfo?.siteType == AppInfoSiteType.SuperAdmin) {
        await apiAdminEmailConfigAdminProvider.edit(adminProviderEmailConfigDto);
      } else {
        await apiAdminProviderEmailConfig.edit(adminProviderEmailConfigDto);
      }
    }
    modal.closeAsSuccess(
      adminProviderEmailConfigDto,
      "La configuración de SMTP se guardó con éxito"
    );
  };

  useEffect(() => {
    const request = async () => {
      let response = null;
      if (appInfo?.siteType == AppInfoSiteType.SuperAdmin) {
        response = await apiAdminEmailConfigAdminProvider.getByAdminProviderId(
          props.adminProviderId ?? ""
        );
      } else {
        response = await apiAdminProviderEmailConfig.get();
      }

      setEmailConfig(response as EmailConfigViewModel);
    };
    request();
    modal.setVisible("Configurar SMTP", ModalSize.normal);
  }, [modal]);

  if (!emailConfig) return <></>;

  return (
    <Container>
      <StateProvider
        model={emailConfig}
        onSubmit={save}
        validationSchema={useEmailConfigValidations()}>
        {(p) => <ConfigEmailForm onSubmit={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default AdminProviderEmailConfigPopup;
