import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdminTermsAndConditionsDto } from "../../api/admin/dtos/AdminTermsAndConditionsDto";
import { useApiAdminProviderTermsAndConditions } from "../../api/shared/hooks/useApiAdminProviders";
import Page from "../../shared/components/Layout/Page";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { useNotifier } from "../../shared/hooks/useNotifier";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AdminTermsAndConditionsForm from "./AdminTermsAndConditionsForm";
import { useAdminTermsAndConditionsValidation } from "./Validations/useAdminTermsAndConditionsValidation";

type TParams = { id: string };

const AdminTermsAndConditionsEdit = () => {
  const navigation = useNavigation();
  const notifier = useNotifier();
  const { id } = useParams<TParams>();
  const [adminTermsAndConditions, setAdminTermsAndConditions] =
    useState<AdminTermsAndConditionsDto>();
  const [apiAdminTermsAndConditions] = useApiAdminProviderTermsAndConditions();

  useEffect(() => {
    if (!id) {
      return;
    }

    const loadAdminTermsAndConditions = async () => {
      setAdminTermsAndConditions(await apiAdminTermsAndConditions.getById(id));
    };
    loadAdminTermsAndConditions();
  }, [id]);

  const save = async (AdminTermsAndConditions: AdminTermsAndConditionsDto) => {
    await apiAdminTermsAndConditions.edit(AdminTermsAndConditions);
    notifier.showSuccess("Términos y condiciones editados con éxito");
    navigation.back();
  };

  if (!adminTermsAndConditions) {
    return <></>;
  }

  return (
    <Page title="Editar términos y condiciones">
      <StateProvider
        model={adminTermsAndConditions}
        onSubmit={save}
        validationSchema={useAdminTermsAndConditionsValidation()}>
        {(p) => <AdminTermsAndConditionsForm editMode={true} onSave={p.handleSubmit} />}
      </StateProvider>
    </Page>
  );
};

export default AdminTermsAndConditionsEdit;
