import { useState } from "react";
import { SearchResultItemDto } from "../../../api/shared/dtos/SearchResultItemDto";
import ButtonIcon from "../Button/ButtonIcon";
import ContextMenu, { ContextMenuItem } from "../ContextMenu/ContextMenu";
import { TypeIcon } from "../Icon";

const GridButtonMenu = (props: {
  pagedResultItem: SearchResultItemDto<any>;
  onDisplayItemMenu(item: any): ContextMenuItem[];
}) => {
  const [menuItems, setMenuItems] = useState<ContextMenuItem[]>();
  const [buttonRef, setButtonRef] = useState();

  return (
    <>
      <ButtonIcon
        icon={TypeIcon.menu}
        noPaddingX={true}
        className="grid-item-button-toggle-menu"
        onClick={(e) => {
          const items = props.onDisplayItemMenu(props.pagedResultItem.item);
          setButtonRef(e.currentTarget);
          setMenuItems(items);
        }}
      />
      {menuItems && (
        <ContextMenu
          items={menuItems}
          anchorElement={buttonRef}
          open={true}
          onClose={() => setMenuItems(undefined)}
        />
      )}
    </>
  );
};

export default GridButtonMenu;
