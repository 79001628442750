export class LocalizedUtilBoolean {
  public toYesOrNoText(
    value: boolean | null,
    defaultText?: string,
    yesText?: string,
    noText?: string
  ): string {
    if (value == null || value === undefined) {
      return defaultText ?? "";
    }
    return value ? yesText ?? "Sí" : noText ?? "No";
  }

  public toYesOrNoWithAsDefaultLabel(value: boolean | null): string {
    return "Por defecto (" + this.toYesOrNoText(value, "No") + ")";
  }
}
