import { TextField } from "@mui/material";
import React, { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { Constants } from "../../../Constants";
import { AppInfoContext } from "../../context/AppInfoProvider";
import useUtils from "../../hooks/useUtils";
import { ThemeSize } from "../../ThemeSize";
import { ThemeVariant } from "../../ThemeVariant";
import ButtonIcon from "../Button/ButtonIcon";
import { TypeIcon } from "../Icon";
import {
  LAYOUT_HINT_MARGIN_BOTTOM_FIX,
  LAYOUT_HINT_PADDING_BOTTOM,
} from "../Layout/LayoutConstants";
import PropsInputBase from "./PropsInputBase";

export interface PropsInputNumber extends PropsInputBase<number | null> {
  intOnly?: boolean;
  maxDecimals?: number;
  preventFormat?: boolean;
  showIncrementButtons?: boolean;
  showZeroAsEmpty?: boolean;
  onPartialChange?(value: number | null): void;
}

const InputNumber = React.forwardRef((props: PropsInputNumber, ref: any) => {
  const [delayTimeout, setDelayTimeout] = useState(null as any);
  const [currentValue, setCurrentValue] = useState(null as number | null);
  const [editingValue, setEditingValue] = useState(null as string | null);
  const [flagSelectText, setFlagSelectText] = useState(false);
  const inputRef = ref ?? useRef();
  const { appInfo } = useContext(AppInfoContext);
  const { utils } = useUtils();
  const [formattedNumber, setFormattedNumber] = useState(
    utils.number.toString(props.value, props.intOnly ? 0 : props.maxDecimals)
  );

  if (!editingValue && props.value !== null) {
    let newFormattedNumber = props.preventFormat
      ? props.value?.toString() ?? ""
      : utils.number.toString(props.value, props.intOnly ? 0 : props.maxDecimals);

    if (props.showZeroAsEmpty && props.value === 0) {
      newFormattedNumber = "";
    }

    if (newFormattedNumber !== formattedNumber) {
      setFormattedNumber(newFormattedNumber);
    }
  }

  const onPartialChange = (value: number | null, preventDelay?: boolean) => {
    if (props.onPartialChange) {
      if (delayTimeout) {
        clearTimeout(delayTimeout);
      }

      if (preventDelay) {
        props.onPartialChange(value);
      } else {
        const newTimeout = setTimeout(() => {
          if (props.onPartialChange) {
            props.onPartialChange(value);
          }
        }, 500);
        setDelayTimeout(newTimeout);
      }
    }
  };

  const incrementIcon = {
    startAdornment: (
      <ButtonIcon
        icon={TypeIcon.minus}
        style={{
          marginTop: appInfo?.muiVariant === ThemeVariant.filled ? 18 : 0,
          cursor: "pointer",
        }}
        noPadding={true}
        onClick={() => {
          const value = (props.value ?? 0) - 1;
          onPartialChange(value, true);
          props.onChange(value);
        }}
      />
    ),
    endAdornment: (
      <ButtonIcon
        icon={TypeIcon.plus}
        style={{
          marginTop: appInfo?.muiVariant === ThemeVariant.filled ? 18 : 0,
          cursor: "pointer",
        }}
        noPadding={true}
        onClick={() => {
          const value = (props.value ?? 0) + 1;
          onPartialChange(value, true);
          props.onChange(value);
        }}
      />
    ),
  };

  const icon = props.showIncrementButtons ? incrementIcon : undefined;

  let helperText = Constants.EMPTY_HINT;

  if (props.validation) {
    helperText = props.validation;
  } else if (props.hint) {
    helperText = props.hint;
  } else if (props.hintAutoHeight) {
    helperText = "";
  }

  const style: CSSProperties = {
    marginBottom: !props.hintAutoHeight
      ? -LAYOUT_HINT_MARGIN_BOTTOM_FIX
      : LAYOUT_HINT_PADDING_BOTTOM,
  };

  useEffect(() => {
    if (flagSelectText) {
      setFlagSelectText(false);
      inputRef?.current?.select();
    }
  }, [flagSelectText]);

  return (
    <TextField
      id={props.id}
      inputRef={inputRef}
      autoComplete="off"
      autoFocus={props.autoFocus}
      type="numeric"
      size={ThemeSize.small}
      variant={props.variant ?? appInfo?.muiVariant}
      inputProps={{ style: { textAlign: "right" }, inputMode: "decimal" }}
      label={props.label}
      disabled={props.readOnly}
      error={!!props.validation}
      helperText={helperText}
      multiline={props.multiline}
      rows={props.multilineRows ?? 4}
      value={editingValue != null ? editingValue : formattedNumber}
      fullWidth={true}
      InputProps={icon}
      style={style}
      onFocus={() => {
        if (props.showZeroAsEmpty && props.value === 0) {
          setEditingValue("");
        } else {
          setEditingValue(props.value?.toString() ?? "");
        }
        setCurrentValue(props.value);
        setFlagSelectText(true);
        setTimeout(() => {
          ref?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 500);
      }}
      onChange={(e) => {
        const newValue = e.target.value;
        if (newValue === "") {
          setEditingValue("");
          setCurrentValue(null);
          props.onChange(null);
          onPartialChange(null);
          return;
        }

        const parsedNumber = Number(newValue);
        if (isNaN(parsedNumber)) {
          setCurrentValue(null);
          return;
        }
        setCurrentValue(parsedNumber);

        setEditingValue(newValue);
        props.onChange(parsedNumber);
        onPartialChange(parsedNumber);
      }}
      onBlur={() => {
        setEditingValue(null);
        setFormattedNumber(utils.number.toString(props.value));
        onPartialChange(currentValue, true);
        if (props.onBlur) {
          props.onBlur();
        }
      }}
    />
  );
});

export default InputNumber;
