// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { VaultCertificateDto } from "../dtos/VaultCertificateDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { VaultCertificateInstallDto } from "../dtos/VaultCertificateInstallDto";

export class ApiVaultCertificate {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    vaultCertificateSearchDto: PagedSearchDto<FiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<VaultCertificateDto>> {
    return this.apiService.apiPost("/vault-certificates/paged", vaultCertificateSearchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<VaultCertificateDto> {
    return this.apiService.apiGet("/vault-certificates/" + id, apiOptions);
  }

  getInfoFromLocalFile(
    vaultCertificateInstallDto: VaultCertificateInstallDto,
    apiOptions?: ApiOptions
  ): Promise<VaultCertificateDto> {
    return this.apiService.apiPost("/vault-certificates/info-from-local-file", vaultCertificateInstallDto, apiOptions);
  }

  install(
    vaultCertificateInstallDto: VaultCertificateInstallDto,
    apiOptions?: ApiOptions
  ): Promise<VaultCertificateDto> {
    return this.apiService.apiPost("/vault-certificates/install", vaultCertificateInstallDto, apiOptions);
  }

  setAsDefault(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<VaultCertificateDto> {
    return this.apiService.apiPut("/vault-certificates/set-as-default/" + id, null, apiOptions);
  }

}
