import { useEffect, useState } from "react";
import { AuthSwitchPointOfSaleDto } from "../../api/app/dtos/AuthSwitchPointOfSaleDto";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAuth } from "../../shared/hooks/useApiAuth";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import { useModal } from "../../shared/hooks/useModal";
import { useNotifier } from "../../shared/hooks/useNotifier";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import ChangePointOfSalePopUpForm from "./SwitchPointOfSalePopUp.Form";
import { useSwitchPointOfSaleValidation } from "./Validations/useSwitchPointOfSaleValidation";

const SwitchPointOfSalePopUp = () => {
  const [modal] = useModal();
  const { loginInfo, login } = useContextLoginInfo();
  const [apiAuth] = useApiAuth();
  useNotifier;

  const [authSwitchPointOfSale] = useState<AuthSwitchPointOfSaleDto>(() => {
    const authSwitchPointOfSaleDto = new AuthSwitchPointOfSaleDto();
    authSwitchPointOfSaleDto.adminAccountId = loginInfo.authInfo.adminAccount.id;
    authSwitchPointOfSaleDto.adminAccount = loginInfo.authInfo.adminAccount;
    authSwitchPointOfSaleDto.accountBranchId = loginInfo.authInfo.accountBranch.id;
    authSwitchPointOfSaleDto.accountBranch = loginInfo.authInfo.accountBranch;
    authSwitchPointOfSaleDto.pointOfSaleId = loginInfo.authInfo.pointOfSale.id;
    authSwitchPointOfSaleDto.pointOfSale = loginInfo.authInfo.pointOfSale;
    return authSwitchPointOfSaleDto;
  });

  const save = (authSwitchPointOfSaleDto: AuthSwitchPointOfSaleDto) => {
    const request = async () => {
      apiAuth.apiService.auth.authToken = loginInfo.authToken;
      const loginResponse = await apiAuth.switchPointOfSale(authSwitchPointOfSaleDto);
      login(loginResponse.authInfo, loginResponse.authToken);
      modal.closeAsSuccess("");
    };
    request();
  };

  useEffect(() => {
    modal.setVisible("Cambiar punto de venta", ModalSize.small);
  }, [modal]);

  return (
    <Container>
      <StateProvider
        model={authSwitchPointOfSale}
        onSubmit={save}
        validationSchema={useSwitchPointOfSaleValidation()}>
        {(p) => <ChangePointOfSalePopUpForm onSave={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default SwitchPointOfSalePopUp;
