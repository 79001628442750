import { nameof } from "ts-simple-nameof";
import { SaleLineDto } from "../../api/app/dtos/SaleLineDto";
import { ProductFilterGroup } from "../../api/shared/enums/ProductFilterGroup";
import { useAppTheme } from "../../AppThemeProvider";
import FormAutocompleteProduct from "../../shared/components/forms/Autocomplete/FormAutocompleteProduct";
import FormText from "../../shared/components/forms/FormText";
import { AppThemeColor } from "../../styles/color";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import { ISaleLineColumnRenderProps } from "./SaleFormLines";

const SaleLineColumnProduct = (props: ISaleLineColumnRenderProps) => {
  const saleModelState = useContextModelStateSale();
  const appTheme = useAppTheme();

  if (!props.isEditing) {
    const showHint =
      props.saleLine.product && !props.saleLine.lineDetail?.startsWith(props.saleLine.product.name);

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>{props.saleLine.lineDetail}</div>
        {showHint && (
          <div style={{ color: appTheme.toHexColor(AppThemeColor.gray) }}>
            Producto: {props.saleLine.product.name}
          </div>
        )}
      </div>
    );
  }
  return (
    <>
      {!props.saleLine.productId && (
        <FormAutocompleteProduct
          arrayItemHelper={props.arrayItemHelper}
          label={props.showLabel ? props.saleLineColumn.header : ""}
          hintAutoHeight={false}
          productFilterGroup={ProductFilterGroup.ForSale}
          propertyName={nameof<SaleLineDto>((p) => p.product)}
          variant={props.variant}
          onValueChange={() => {
            saleModelState.onLineProductChange(props.arrayItemHelper.index);
            (props.quantityRef?.current as any)?.focus();
          }}
        />
      )}
      {props.saleLine.productId && (
        <FormText
          arrayItemHelper={props.arrayItemHelper}
          label={props.showLabel ? props.saleLineColumn.header : ""}
          hint={
            (!props.saleLine.lineDetail?.startsWith(props.saleLine.product.name)
              ? "Producto: " + props.saleLine.product.name + " - "
              : "") + props.saleLine.description
          }
          hintAutoHeight={false}
          variant={props.variant}
          propertyName={nameof<SaleLineDto>((p) => p.lineDetail)}
          onValueChange={() => saleModelState.onLineDetailChange(props.arrayItemHelper.index)}
        />
      )}
    </>
  );
};

export default SaleLineColumnProduct;
