// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { InvoiceType } from "../../shared/enums/InvoiceType";

export class InvoiceReferenceDto extends BaseEntityDto {
  invoiceType: InvoiceType;
  isGlobal: boolean = false;
  lineNumber: number = 0;
  number: number | null = null;
  reason: string = "";
  referenceDate: Date | null = null;
  serie: string = "";
}
