import { useEffect, useState } from "react";
import { AddressDto } from "../../api/app/dtos/AddressDto";
import { ContactDto } from "../../api/app/dtos/ContactDto";
import { ContactInfoDto } from "../../api/app/dtos/ContactInfoDto";
import { ContactType } from "../../api/shared/enums/ContactType";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiContact } from "../../api/shared/hooks/useApiApp";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import ContactForm from "./Contact.Form";
import { useContactValidation } from "./Validations/useContactValidation";

const ContactCreatePopup = (props: { initName?: string; initTaxPayerId?: string }) => {
  const { loginInfo } = useContextLoginInfo();
  const [contact] = useState<ContactDto>(() => {
    const contact = new ContactDto();
    contact.contactType = ContactType.Company;
    contact.name = props.initName ?? "";
    contact.taxPayerId = props.initTaxPayerId ?? "";
    contact.info = new ContactInfoDto();
    contact.info.address = new AddressDto();
    contact.info.address.state = loginInfo.authInfo.accountConfig.defaultState;
    contact.info.address.country = loginInfo.authInfo.accountConfig.defaultCountry;
    contact.isCustomer = true;
    return contact;
  });

  const [modal] = useModal();
  const [apiContact] = useApiContact();

  const save = async (contact: ContactDto) => {
    const created = await apiContact.create(contact);
    modal.closeAsSuccess(created, "El contacto se creó con éxito");
  };

  useEffect(() => modal.setVisible("Crear contacto", ModalSize.small), [modal]);

  if (!contact) {
    return <></>;
  }

  return (
    <Container>
      <StateProvider
        model={contact}
        validationSchema={useContactValidation(loginInfo)}
        onSubmit={save}>
        {(p) => <ContactForm onSubmit={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default ContactCreatePopup;
