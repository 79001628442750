import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import Icon from "../Icon";
import Props from "../Props";
import Tooltip from "../Tooltip";
import ComponentGroup from "./ComponentsGroup";

interface PropsLayoutSideMenuArea extends Props {
  activeMenu?: boolean;
  icon: any;
  isSelected: boolean;
  isExpanded: boolean;
  title: string;
  onClick(): void;
}

const LayoutSideMenuArea = (props: PropsLayoutSideMenuArea) => {
  const appTheme = useAppTheme();
  return (
    <Tooltip text={!props.isExpanded ? props.title : undefined}>
      <div
        className={"layout-side-menu-area " + (props.isSelected ? "selected" : "")}
        onClick={() => props.onClick()}>
        <ComponentGroup>
          <Icon
            type={props.icon}
            color={AppThemeColor.primaryContrast}
            className="layout-side-menu-area-icon"
          />
          {props.isExpanded && (
            <div
              className="layout-side-menu-area-title"
              style={{ color: appTheme.toHexColor(AppThemeColor.primaryContrast) }}>
              {props.title}
            </div>
          )}
        </ComponentGroup>
      </div>
    </Tooltip>
  );
};

export default LayoutSideMenuArea;
