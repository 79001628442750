import { useEffect, useState } from "react";
import { AccountBranchDto } from "../../api/app/dtos/AccountBranchDto";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminAccessAccountBranch } from "../../api/shared/hooks/useApiAdminAccess";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import { useAdminAccountBranchValidator } from "./Validations/useAdminAccountBranchValidations";
import AdminAccountBranchForm from "./AdminAccountBranchForm";
import { AddressDto } from "../../api/app/dtos/AddressDto";

const AdminAccountBranchCreatePopup = () => {
  const [accountBranchDto] = useState<AccountBranchDto>(() => {
    const branch = new AccountBranchDto();
    branch.address = new AddressDto();
    return branch;
  });
  const [modal] = useModal();
  const [apiAdminAccessAccountBranch] = useApiAdminAccessAccountBranch();
  const save = async (accountBranchDto: AccountBranchDto) => {
    const created = await apiAdminAccessAccountBranch.create(
      accountBranchDto.accountId,
      accountBranchDto
    );
    modal.closeAsSuccess(created, "La sucursal se creó con éxito");
  };

  useEffect(() => modal.setVisible("Crear sucursal", ModalSize.small), [modal]);

  if (!accountBranchDto) {
    return null;
  }

  return (
    <Container>
      <StateProvider
        model={accountBranchDto}
        onSubmit={save}
        validationSchema={useAdminAccountBranchValidator()}>
        {(p) => <AdminAccountBranchForm onSave={p.handleSubmit} isEdit={false} />}
      </StateProvider>
    </Container>
  );
};

export default AdminAccountBranchCreatePopup;
