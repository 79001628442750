import { AppSize } from "../AppSize";
import { useContextAppInfo } from "../hooks/useContextAppInfo";
import Img from "./Img";
import Props from "./Props";

export interface PropsAdminProviderIsoLogo extends Props {
  onlyWhite?: boolean;
  size?: AppSize;
  onClick?: () => void;
}

const PropsAdminProviderLogoIsoType = (props: PropsAdminProviderIsoLogo) => {
  const { appInfo } = useContextAppInfo();

  if (!appInfo?.adminProviderInfo.settings.logoUrl) {
    return <></>;
  }
  return (
    <Img
      {...props}
      src={
        props.onlyWhite
          ? appInfo?.adminProviderInfo.settings.logoIsoTypeWhiteUrl
          : appInfo?.adminProviderInfo.settings.logoIsoTypeUrl
      }
    />
  );
};

export default PropsAdminProviderLogoIsoType;
