import { useContext } from "react";
import { getObjectValue } from "../utils/utilObject";
import { ModelStateContext, ModelStateContextActionType } from "./ModelStateContext";

export function useContextModelState(customReducer?: any) {
  const { modelState, modelStateDispatcher } = useContext(ModelStateContext);

  const arrayItemAdd = (propertyFullName: string, item: any, insertAt?: number) => {
    modelStateDispatcher({
      type: ModelStateContextActionType.arrayItemAdd,
      propertyFullName: propertyFullName,
      insertAt: insertAt,
      item: item,
      customReducer: customReducer,
    });
  };

  const arrayItemRemove = (propertyFullName: string, deleteAt: number, deleteCount?: number) => {
    modelStateDispatcher({
      type: ModelStateContextActionType.arrayItemRemove,
      propertyFullName: propertyFullName,
      deleteAt: deleteAt,
      deleteCount: deleteCount,
      customReducer: customReducer,
    });
  };

  const arrayItemReplace = (propertyFullName: string, itemIndex: number, item: any) => {
    modelStateDispatcher({
      type: ModelStateContextActionType.arrayItemReplace,
      propertyFullName: propertyFullName,
      insertAt: itemIndex,
      item: item,
      customReducer: customReducer,
    });
  };

  const dispatchCustom = (typeCustom: number, payload?: any) => {
    modelStateDispatcher({
      type: ModelStateContextActionType.custom,
      typeCustom: typeCustom,
      payload: payload,
      customReducer: customReducer,
    });
  };

  const fieldTrackInit = (propertyFullName: string) => {
    modelStateDispatcher({
      type: ModelStateContextActionType.fieldTrackInit,
      propertyFullName: propertyFullName,
    });
  };

  const fieldTrackSetTouched = (propertyFullName: string) => {
    modelStateDispatcher({
      type: ModelStateContextActionType.fieldTrackSetTouched,
      propertyFullName: propertyFullName,
    });
  };

  const fieldTrackSetChildrenTouched = (propertyFullName: string) => {
    modelStateDispatcher({
      type: ModelStateContextActionType.fieldTrackSetChildrenTouched,
      propertyFullName: propertyFullName,
    });
  };

  const fieldTrackSetNotTouched = (propertyFullName: string) => {
    modelStateDispatcher({
      type: ModelStateContextActionType.fieldTrackSetNotTouched,
      propertyFullName: propertyFullName,
    });
  };

  const getValue = (propertyFullName: string): any => {
    return getObjectValue(modelState.model, propertyFullName);
  };

  const getTouchedValidation = (propertyFullName: string): string => {
    return modelState.validation?.getTouchedValidation(propertyFullName) ?? "";
  };

  const replaceModel = (model: any) => {
    modelStateDispatcher({
      type: ModelStateContextActionType.replaceModel,
      model: model,
    });
  };

  const submit = () => {
    modelStateDispatcher({
      type: ModelStateContextActionType.submit,
    });
  };

  const updateProperties = (
    properties: {
      propertyFullName: string;
      value: any;
      setTouched?: boolean;
      updateIdLinkedProperty?: boolean;
    }[]
  ) => {
    modelStateDispatcher({
      type: ModelStateContextActionType.updateProperties,
      properties: properties,
      customReducer: customReducer,
    });
  };

  const updateProperty = (
    propertyFullName: string,
    value: any,
    setTouched?: boolean,
    updateIdLinkedProperty?: boolean
  ) => {
    updateProperties([
      {
        propertyFullName: propertyFullName,
        value: value,
        setTouched: setTouched,
        updateIdLinkedProperty: updateIdLinkedProperty,
      },
    ]);
  };

  return {
    modelState,
    model: modelState.model,
    validation: modelState.validation,
    arrayItemAdd,
    arrayItemRemove,
    arrayItemReplace,
    dispatchCustom,
    fieldTrackInit,
    fieldTrackSetTouched,
    fieldTrackSetChildrenTouched,
    fieldTrackSetNotTouched,
    getValue,
    getTouchedValidation,
    replaceModel,
    submit,
    updateProperties,
    updateProperty,
  };
}
