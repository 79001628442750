// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { LongProcessStatusInfoDto } from "../../shared/dtos/LongProcessStatusInfoDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiLongProcess {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getByProcessId(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<LongProcessStatusInfoDto> {
    return this.apiService.apiGet("/long-process/" + id, apiOptions);
  }

  tryCancel(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/long-process/request-cancellation/" + id, null, apiOptions);
  }

}
