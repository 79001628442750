import { ReactElement, useState } from "react";
import SwitchPointOfSalePopUp from "../../../app/Login/SwitchPointOfSalePopUp";
import { AppRoute } from "../../../AppRoutes";
import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import { AppInfoSiteType } from "../../context/AppInfoProvider";
import { LoginInfo } from "../../context/LoginInfoProvider";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useContextAppInfo } from "../../hooks/useContextAppInfo";
import { useContextLoginInfo } from "../../hooks/useContextLoginInfo";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useModal } from "../../hooks/useModal";
import { useNavigation } from "../../hooks/useNavigation";
import PropsAdminProviderLogoIsoType from "../AdminProviderLogoIsoType";
import Icon, { TypeIcon } from "../Icon";
import ModalDialog from "../Modal/ModalDialog";
import LayoutHeaderLoginMenuItem from "./LayoutHeader.LoginMenuItem";
import StylePopup from "./StylePopup";

interface MenuOption {
  icon: any;
  name: string;
  navigateTo?: string;
  hide?(loginInfo: LoginInfo): boolean;
  onClick?(): void;
}

const LayoutHeaderLoginMenu = (props: { onClose(): void }): ReactElement => {
  const { loginInfo, logout } = useContextLoginInfo();
  const isMobile = useIsMobile();
  const [modal] = useModal();
  const [isClosing, setIsClosing] = useState(false);
  const navigation = useNavigation();
  const appTheme = useAppTheme();
  const { appInfo } = useContextAppInfo();
  const isAdmin =
    loginInfo.authInfo.isAccountAdmin ||
    loginInfo.authInfo.isSuperAdmin ||
    loginInfo.authInfo.hasAdminProviderAccess;

  const close = () => {
    setIsClosing(true);
    setTimeout(() => {
      props.onClose();
    }, 500);
  };

  const outerClickRef = useClickOutside(close) as any;

  const options: MenuOption[] = [
    {
      icon: TypeIcon.branch,
      name: "Cambiar punto de venta",
      hide: (loginInfo: LoginInfo) => !loginInfo.authInfo.hasMultiplePointOfSale,
      onClick: () => modal.open(<SwitchPointOfSalePopUp />),
    },
    { icon: TypeIcon.user, name: "Mi usuario", navigateTo: AppRoute.myAccountUser },
    {
      icon: TypeIcon.adminAccount,
      name: "Mi empresa",
      hide: () => !isAdmin,
      navigateTo: AppRoute.myAdminAccount,
    },
    {
      icon: TypeIcon.config,
      name: "Configuraciones",
      hide: () => !isAdmin,
      navigateTo: "/configuration",
    },
    {
      icon: TypeIcon.styles,
      name: "Estilos",
      hide: (loginInfo: LoginInfo) => !loginInfo.authInfo.isSuperAdmin,
      onClick: () => modal.open(<StylePopup />),
    },
    {
      icon: TypeIcon.user,
      name: "Usuarios",
      hide: () => !isAdmin,
      navigateTo: "/users",
    },
    {
      icon: TypeIcon.logout,
      name: "Salir",
      onClick: () =>
        modal.open(<ModalDialog text="¿Seguro querés salir?" />, () => {
          logout();
        }),
    },
  ];

  if (!appInfo) {
    return <></>;
  }

  const showAdminProviderOption =
    loginInfo.authInfo.hasAdminProviderAccess && appInfo.siteType != AppInfoSiteType.AdminProvider;

  return (
    <div
      ref={outerClickRef}
      className={"layout-header-login-menu " + (!isClosing ? "expanded" : "")}
      style={{ backgroundColor: appTheme.toHexColor(AppThemeColor.primaryLight) }}>
      {isMobile && (
        <div
          style={{
            background: AppThemeColor.white,
            borderRadius: 5,
            marginBottom: 10,
            padding: 15,
            color: appTheme.toHexColor(AppThemeColor.black),
            fontWeight: 600,
          }}>
          <div>
            <Icon type={TypeIcon.adminAccount} size={AppSize.small} />{" "}
            {loginInfo.authInfo.adminAccount.name}
          </div>
          <div>
            <Icon type={TypeIcon.user} size={AppSize.small} /> {loginInfo.authInfo.user.email}
          </div>
          {loginInfo.authInfo.hasMultiplePointOfSale && (
            <>
              <div>
                <Icon type={TypeIcon.branch} size={AppSize.small} />{" "}
                {loginInfo.authInfo.accountBranch.displayName}
              </div>
              <div>
                <Icon type={TypeIcon.pointOfSale} size={AppSize.small} />{" "}
                {loginInfo.authInfo.pointOfSale.name}
              </div>
            </>
          )}
        </div>
      )}
      {loginInfo.authInfo.isSuperAdmin && appInfo.siteType != AppInfoSiteType.SuperAdmin && (
        <div
          className="layout-header-login-menu-item gesta-admin"
          onClick={() => navigation.go(AppRoute.admin.base)}
          style={{
            backgroundColor: appTheme.toHexColor(AppThemeColor.black),
            color: appTheme.toHexColor(AppThemeColor.primaryContrast),
            textAlign: "center",
          }}>
          <PropsAdminProviderLogoIsoType style={{ marginRight: 16, verticalAlign: "middle" }} />
          GESTA: SUPER ADMIN
        </div>
      )}
      {showAdminProviderOption && (
        <div
          className="layout-header-login-menu-item gesta-admin"
          onClick={() => navigation.go(AppRoute.adminProvider.base)}
          style={{
            backgroundColor: appTheme.toHexColor(AppThemeColor.primaryContrast),
            color: appTheme.toHexColor(AppThemeColor.black),
          }}>
          <PropsAdminProviderLogoIsoType style={{ marginRight: 16, verticalAlign: "middle" }} />
          <div style={{ marginTop: 5 }}>
            Administración <strong>{appInfo?.adminProviderInfo.name}</strong>
          </div>
        </div>
      )}
      {appInfo?.siteType != AppInfoSiteType.Account && (
        <div
          className="layout-header-login-menu-item gesta-admin"
          onClick={() => navigation.go(AppRoute.base)}>
          <Icon type={TypeIcon.arrowLeft} />
          <div className="layout-header-login-menu-item-text">Volver al cliente</div>
        </div>
      )}

      {options.map((option) => {
        if (option.hide && option.hide(loginInfo)) {
          return null;
        }
        return (
          <LayoutHeaderLoginMenuItem
            key={option.name}
            name={option.name}
            icon={option.icon}
            onClick={() => {
              props.onClose();
              if (option.onClick) {
                option.onClick();
                return;
              }
              navigation.go(option.navigateTo ?? "");
            }}
          />
        );
      })}
    </div>
  );
};

export default LayoutHeaderLoginMenu;
