import { Collapse } from "@mui/material";
import { useState } from "react";
import { TextAlign } from "../../TextAlign";
import { ThemeSize } from "../../ThemeSize";
import ButtonLink from "../Button/ButtonLink";
import { TypeIcon } from "../Icon";
import Column from "../Layout/GColumn";
import Container from "../Layout/GContainer";
import Row from "../Layout/GRow";
import Section from "../Layout/GSection";
import { LAYOUT_DEFAULT_SPACING } from "../Layout/LayoutConstants";
import GridDefaultSearch from "./GridDefaultSearch";
import GridFiltersForm from "./GridFilters.Form";
import GridIncludeInactiveCheck from "./GridIncludeInactiveCheck";
import GridMobileSortBy from "./GridMobileSortBy";

const GridFiltersMobile = (props: {
  hideIncludeInactiveButton: boolean;
  preventShowDefaultSearch: boolean;
  onApplyFilters: () => void;
}) => {
  const [expanded, setExpanded] = useState(false);
  const [showCustomFilter, setShowCustomFilter] = useState(false);

  return (
    <Container>
      <Row paddingTop={0} align={TextAlign.center} minHeightIgnore={true}>
        <ButtonLink
          size={ThemeSize.medium}
          icon={expanded ? TypeIcon.chevronUp : TypeIcon.search}
          text={expanded ? "Ocultar" : "Buscar"}
          onClick={() => {
            setExpanded(!expanded);
          }}
        />
      </Row>

      <Row paddingTop={expanded ? LAYOUT_DEFAULT_SPACING : 0} minHeightIgnore={true}>
        <Collapse in={expanded}>
          <Container>
            <GridMobileSortBy />
          </Container>
        </Collapse>
      </Row>

      <Row
        paddingTop={expanded && !showCustomFilter ? LAYOUT_DEFAULT_SPACING : 0}
        minHeightIgnore={true}>
        <Collapse in={expanded && !showCustomFilter}>
          <Container>
            <Row>
              <GridDefaultSearch onDefaultSearchChange={props.onApplyFilters} />
            </Row>
            <Column xs={6} paddingTop={0} minHeightIgnore={true}>
              {!props.hideIncludeInactiveButton && <GridIncludeInactiveCheck />}
            </Column>
            <Column xs={6} align={TextAlign.right} paddingTop={0} minHeightIgnore={true}>
              <ButtonLink text="mostrar más filtros" onClick={() => setShowCustomFilter(true)} />
            </Column>
          </Container>
        </Collapse>
      </Row>

      <Row paddingTop={expanded ? LAYOUT_DEFAULT_SPACING : 0} minHeightIgnore={true}>
        <Collapse in={expanded && showCustomFilter}>
          <Container>
            <Row>
              <Section border={true}>
                <GridFiltersForm onApplyFilters={props.onApplyFilters} />
              </Section>
            </Row>
            <Column xs={6} minHeightIgnore={true}>
              {!props.hideIncludeInactiveButton && <GridIncludeInactiveCheck />}
            </Column>
            <Column xs={6} align={TextAlign.right} minHeightIgnore={true}>
              <ButtonLink text="ocultar filtros" onClick={() => setShowCustomFilter(false)} />
            </Column>
          </Container>
        </Collapse>
      </Row>
    </Container>
  );
};

export default GridFiltersMobile;
