import ValidationField from "../schema/ValidationField";
import ValidationFieldCollection from "../schema/ValidationFieldCollection";
import ValidationFieldSchema from "../schema/ValidationFieldSchema";
import ValidationContext from "./ValidationContext";
import ValidationContextCollection from "./ValidationContextCollection";
import ValidationContextField from "./ValidationContextField";
import ValidationFieldsTrack from "./ValidationFieldsTrack";
import ValidationFieldsTrackCollection from "./ValidationFieldsTrackCollection";
import ValidationFieldsTrackField from "./ValidationFieldsTrackField";
import ValidationResultBase from "./ValidationResultBase";
import ValidationResultMessage from "./ValidationResultMessage";
import ValidationResultSchema from "./ValidationResultSchema";

export default class ValidationContextSchema extends ValidationContext {
  fields: { [key: string]: ValidationContext } = {};

  constructor(
    parent: ValidationContext | undefined,
    propertyName: string,
    collectionItemIndex: number | undefined,
    public validationFieldSchema: ValidationFieldSchema,
    fieldsTrackGroup: ValidationFieldsTrack,
    modelValue: any
  ) {
    super(
      parent,
      propertyName,
      collectionItemIndex,
      validationFieldSchema.propertyNameFriendly,
      fieldsTrackGroup,
      modelValue
    );
    this.validationFieldSchema = validationFieldSchema;
    this.validateSchema();
  }

  private validateSchema() {
    this.validateExpressions(this.validationFieldSchema);

    let existsInvalidChild = false;
    if (this.modelValue) {
      const nestedSchema = this.validationFieldSchema.nestedSchema;

      Object.keys(nestedSchema).forEach((schemaPropertyName) => {
        const validationField: ValidationField = nestedSchema[schemaPropertyName];
        const nestedModelValue = this.modelValue[schemaPropertyName];
        const nestedFieldsTrack = (this.fieldsTrack as ValidationFieldsTrack)?.fields[schemaPropertyName];

        if (validationField instanceof ValidationFieldCollection) {
          this.fields[schemaPropertyName] = new ValidationContextCollection(
            this,
            schemaPropertyName,
            undefined,
            validationField,
            nestedFieldsTrack as ValidationFieldsTrackCollection,
            nestedModelValue
          );
        } else if (validationField instanceof ValidationFieldSchema) {
          this.fields[schemaPropertyName] = new ValidationContextSchema(
            this,
            schemaPropertyName,
            undefined,
            validationField,
            nestedFieldsTrack as ValidationFieldsTrack,
            nestedModelValue
          );
        } else if (validationField instanceof ValidationField) {
          const validationContextField = new ValidationContextField(
            this,
            schemaPropertyName,
            undefined,
            validationField,
            nestedFieldsTrack as ValidationFieldsTrackField,
            nestedModelValue
          );

          this.fields[schemaPropertyName] = validationContextField;
        }
        if (!this.fields[schemaPropertyName].isValid) {
          existsInvalidChild = true;
        }
      });
    }

    this.isValid = this.validations.length == 0 && !existsInvalidChild;
  }

  getValidationResult(trySubmitCount: number): ValidationResultBase {
    const result = new ValidationResultSchema();
    result.isValid = this.isValid;
    this.validations.forEach((validation) => {
      result.validations.push(
        new ValidationResultMessage(validation, this.parent?.getPropertyFullFriendlyName() ?? "", trySubmitCount > 0)
      );
    });

    Object.keys(this.fields).forEach((fieldName) => {
      const fieldResult = this.fields[fieldName].getValidationResult(trySubmitCount);

      if (fieldResult.validations.length > 0) {
        result.validations = result.validations.concat(fieldResult.validations);
      }
      result[fieldName] = fieldResult;
    });
    return result;
  }
}
