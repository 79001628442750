import { CSSProperties } from "react";
import { ISaleLineRetentionColumn } from "./SaleFormLines";

const SaleLineRetentionsHeader = (props: { saleLineRetentionColumn: ISaleLineRetentionColumn }) => {
  const style: CSSProperties = {
    flex: props.saleLineRetentionColumn.width,
    gap: 5,
    textAlign: props.saleLineRetentionColumn.align,
    whiteSpace: "nowrap",
  };

  return <div style={style}>{props.saleLineRetentionColumn.header}</div>;
};

export default SaleLineRetentionsHeader;
