import { forwardRef } from "react";
import { nameof } from "ts-simple-nameof";
import { PointOfSaleDto } from "../../../../api/app/dtos/PointOfSaleDto";
import { OmitFormAutocompleteProps } from "../../inputs/autocomplete/InputAutocompleteBase";
import InputAutocompleteSwitchPointOfSale, {
  PropsInputAutocompleteSwitchPointOfSale,
} from "../../inputs/autocomplete/InputAutocompleteSwitchPointOfSale";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormAutocompleteSwitchPointOfSale
  extends PropsFormInput<PointOfSaleDto>,
    Omit<PropsInputAutocompleteSwitchPointOfSale, OmitFormAutocompleteProps> {}

const FormAutocompleteSwitchPointOfSale = forwardRef(
  (props: PropsFormAutocompleteSwitchPointOfSale, ref?: any) => {
    const inputProps = useFormInputInit(props, ref, {
      updateIdLinkedProperty: true,
      inputTextValuePropertyName: nameof<PointOfSaleDto>((p) => p.name),
    });
    return (
      <InputAutocompleteSwitchPointOfSale preventClearButton={true} {...props} {...inputProps} />
    );
  }
);

export default FormAutocompleteSwitchPointOfSale;
