import {
  addDays,
  addHours,
  format,
  isValid,
  parseISO,
  startOfDay,
  differenceInMinutes,
} from "date-fns";
import { DateOnlyDto } from "../../../api/shared/dtos/DateOnlyDto";
import { AppLocale } from "../../../api/shared/enums/AppLocale";

export enum DateFormatType {
  OnlyDate = 1,
  DateHourMinutes = 2,
  DateHourMinutesSeconds = 3,
  DateHourMinutesSecondsMs = 4,
  HourMinutes = 5,
  HourMinutesSeconds = 6,
  HourMinutesSecondsMs = 7,
}

export enum DateOnlyFormatType {
  ddMMyyyy = 1,
  yyyyMMdd = 2,
}

export interface DateParts {
  month: number;
  day: number;
  year: number;
  hour: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}

export class LocalizedUtilDate {
  private _appLocale: AppLocale;

  async load(appLocale: AppLocale) {
    this._appLocale = appLocale;
  }

  private numberPad = (value: number): string => {
    return value < 10 ? "0" + value : "" + value;
  };

  private getLocaleDateFormat() {
    if (this._appLocale == AppLocale.EnUs) {
      return "MM/dd/yyyy";
    }
    return "dd/MM/yyyy";
  }

  private getDateFormat(formatType?: DateFormatType): string {
    const dateFormat = this.getLocaleDateFormat();

    if (!formatType) {
      return dateFormat;
    }

    switch (formatType) {
      case DateFormatType.OnlyDate:
        return dateFormat;
      case DateFormatType.DateHourMinutes:
        return dateFormat + " HH:mm";
      case DateFormatType.DateHourMinutesSeconds:
        return dateFormat + " HH:mm:ss";
      case DateFormatType.HourMinutes:
        return "HH:mm";
      case DateFormatType.HourMinutesSeconds:
        return "HH:mm:ss";
      case DateFormatType.HourMinutesSecondsMs:
        return "HH:mm:ss.SSS";
    }
    return dateFormat + " HH:mm:ss.SSS";
  }

  addDays = (date: Date, days: number): Date => {
    return addDays(date, days);
  };

  addHours = (date: Date, hours: number): Date => {
    return addHours(date, hours);
  };

  clearTimePart = (date: Date): Date => {
    return startOfDay(date);
  };

  /** Converts a string to a date */
  typeCheck = (date?: Date | null): Date | null => {
    if (!date) {
      return null;
    }

    if (date instanceof Date) {
      return date;
    }

    const convertedDate = parseISO(date);
    if (convertedDate.getFullYear() == 1) {
      return null;
    }
    return convertedDate;
  };

  dateOnlyToString = (
    value: DateOnlyDto | null | undefined,
    format?: DateOnlyFormatType
  ): string => {
    if (!value) {
      return "-";
    }

    if (format === DateOnlyFormatType.yyyyMMdd) {
      return value.year + "-" + this.numberPad(value.month) + "-" + this.numberPad(value.day);
    }

    return this.numberPad(value.day) + "-" + this.numberPad(value.month) + "-" + value.year;
  };

  today = (): Date => {
    return startOfDay(new Date());
  };

  toAgoString = (value: Date | null | undefined, lessThanMinuteMessage?: string): string => {
    value = this.typeCheck(value);
    const parts = this.getDateParts(value);
    if (!value || !parts) {
      return "";
    }

    const minutes = differenceInMinutes(new Date(), value);
    if (minutes < 1) {
      return lessThanMinuteMessage ?? "Hace menos de un minuto";
    }

    if (minutes < 60) {
      return "Hace " + minutes + " minuto" + (minutes > 1 ? "s" : "");
    }

    const hours = Math.round(minutes / 60);
    if (hours < 24) {
      return "Hace " + hours + " hora" + (minutes > 1 ? "s" : "");
    }
    return this.toString(value, DateFormatType.DateHourMinutes);
  };

  toFileNameString = (value: Date | null | undefined): string => {
    value = this.typeCheck(value);
    const parts = this.getDateParts(value);
    if (!value || !parts) {
      return "";
    }
    return (
      parts.year +
      "-" +
      this.numberPad(parts.month) +
      "-" +
      this.numberPad(parts.day) +
      " " +
      this.numberPad(parts.hour) +
      "-" +
      this.numberPad(parts.minutes)
    );
  };

  /** Converts a date to string, by default hiding time part */
  toString = (value: Date | null | undefined, formatType?: DateFormatType): string => {
    if (!value) {
      return "-";
    }

    if (typeof value === "string") {
      value = new Date(value);
    }

    if (!isValid(value)) {
      return value.toString();
    }

    return format(value, this.getDateFormat(formatType));
  };

  dateOnlyToDate = (dateOnly: DateOnlyDto): Date => {
    return new Date(dateOnly.year, dateOnly.month - 1, dateOnly.day);
  };

  stringToDateOnly = (value: string, format?: DateOnlyFormatType): DateOnlyDto => {
    const parts = value.split("-");
    const result = new DateOnlyDto();
    result.month = parseInt(parts[1]);
    if (format == DateOnlyFormatType.yyyyMMdd) {
      result.year = parseInt(parts[0]);
      result.day = parseInt(parts[2]);
    } else {
      result.day = parseInt(parts[0]);
      result.year = parseInt(parts[2]);
    }
    return result;
  };

  getDateParts(date: Date | null): DateParts | null {
    date = this.typeCheck(date);
    if (date == null) {
      return null;
    }

    return {
      month: (date?.getMonth() ?? 0) + 1,
      day: date?.getDate() ?? 0,
      year: date?.getFullYear() ?? 0,
      hour: date?.getHours() ?? 0,
      minutes: date?.getMinutes() ?? 0,
      seconds: date?.getSeconds() ?? 0,
      milliseconds: date?.getMilliseconds() ?? 0,
    } as DateParts;
  }
}
