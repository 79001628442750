import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminCertifiedProviderReportDto } from "../../../api/admin/dtos/AdminCertifiedProviderReportDto";
import { AdminCertifiedProviderReportFiltersDto } from "../../../api/admin/dtos/AdminCertifiedProviderReportFiltersDto";
import { AdminRetentionFiltersDto } from "../../../api/admin/dtos/AdminRetentionFiltersDto";
import { enumName } from "../../../api/shared/enums/_enumName";
import { useApiAdminCertifiedProviderReport } from "../../../api/shared/hooks/useApiAdmin";
import ButtonCreate from "../../../shared/components/Button/ButtonCreate";
import Grid from "../../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
} from "../../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import IconCheck from "../../../shared/components/IconCheck";
import IconError from "../../../shared/components/IconError";
import Page from "../../../shared/components/Layout/Page";
import { useModal } from "../../../shared/hooks/useModal";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import AdminCertifiedProviderCreatePopup from "./AdminCertifiedProviderReportCreatePopup";
import getAdminCertifiedProviderReportListItemMenu from "./AdminCertifiedProviderReportList.ItemMenu";

const AdminCertifiedProviderReportList = () => {
  const [modal] = useModal();
  const navigation = useNavigation();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [apiAdminCertifiedProviderReport] = useApiAdminCertifiedProviderReport();
  const [initialFilters] = useState(() => {
    return new AdminRetentionFiltersDto();
  });

  const itemTemplates: IGridItemTemplate<AdminCertifiedProviderReportDto>[] = [
    {
      header: "País",
      field: nameof<AdminCertifiedProviderReportDto>((p) => p.country),
      filter: {
        propertyName: nameof<AdminCertifiedProviderReportFiltersDto>((p) => p.country),
        type: GridFilterType.enum,
        enumName: enumName.Country,
      },
      width: 10,
      cellTypeEnumName: enumName.Country,
    },
    {
      header: "Fecha de envío",
      field: nameof<AdminCertifiedProviderReportDto>((p) => p.sentDate),
      filter: {
        propertyName: nameof<AdminCertifiedProviderReportFiltersDto>((p) => p.sendDate),
        type: GridFilterType.dateTime,
      },
      cellType: GridCellType.dateTime,
      width: 10,
    },
    {
      header: "Fecha de inicio",
      field: nameof<AdminCertifiedProviderReportDto>((p) => p.reportDateStart),
      filter: {
        propertyName: nameof<AdminCertifiedProviderReportFiltersDto>((p) => p.reportDateStart),
        type: GridFilterType.date,
      },
      cellType: GridCellType.date,
      width: 10,
    },
    {
      header: "Fecha de fin",
      field: nameof<AdminCertifiedProviderReportDto>((p) => p.reportDateEnd),
      filter: {
        propertyName: nameof<AdminCertifiedProviderReportFiltersDto>((p) => p.reportDateEnd),
        type: GridFilterType.date,
      },
      cellType: GridCellType.date,
      width: 10,
    },
    {
      header: "Id interno",
      field: nameof<AdminCertifiedProviderReportDto>((p) => p.internalId),
      width: 10,
      cellType: GridCellType.integer,
    },
    {
      header: "Secuencial",
      field: nameof<AdminCertifiedProviderReportDto>((p) => p.secuencialNumber),
      filter: {
        propertyName: nameof<AdminRetentionFiltersDto>((p) => p.code),
      },
      width: 10,
      cellType: GridCellType.integer,
    },
    {
      header: "Mensaje",
      field: nameof<AdminCertifiedProviderReportDto>((p) => p.errorMessage),
      width: 10,
    },
    {
      header: "Aceptado",
      field: nameof<AdminCertifiedProviderReportDto>((p) => p.accepted),
      filter: {
        propertyName: nameof<AdminCertifiedProviderReportDto>((p) => p.accepted),
      },
      width: 10,
      cellType: GridCellType.iconCheck,
      render: (item) =>
        item.accepted ? <IconCheck value={true} /> : <IconError tooltip={item.errorMessage} />,
    },
  ];

  return (
    <Page
      title="Reporte de proveedor habilitado"
      titleButton={
        <ButtonCreate
          text="Nuevo reporte de proveedor habilitado"
          onClick={() =>
            modal.open(<AdminCertifiedProviderCreatePopup />, () => setReloadGrid(new Date()))
          }
        />
      }>
      <Grid
        itemTemplates={itemTemplates}
        defaultSortBy={[
          { columnName: nameof<AdminCertifiedProviderReportDto>((p) => p.sentDate), asc: false },
        ]}
        mustIncludeFields={[nameof<AdminCertifiedProviderReportDto>((p) => p.xmlUrl)]}
        reloadGrid={reloadGrid}
        hideIncludeInactiveButton={true}
        preventShowDefaultSearch={true}
        initialFilters={initialFilters}
        onSearch={(search, options) => apiAdminCertifiedProviderReport.getPaged(search, options)}
        onDisplayItemMenu={(adminRetention: AdminCertifiedProviderReportDto) =>
          getAdminCertifiedProviderReportListItemMenu(adminRetention, modal, navigation)
        }
      />
    </Page>
  );
};

export default AdminCertifiedProviderReportList;
