// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminCertifiedProviderReportDto } from "../dtos/AdminCertifiedProviderReportDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { AdminCertifiedProviderReportFiltersDto } from "../dtos/AdminCertifiedProviderReportFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { AdminCertifiedProviderReportGenerateDto } from "../dtos/AdminCertifiedProviderReportGenerateDto";

export class ApiAdminCertifiedProviderReport {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<AdminCertifiedProviderReportFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AdminCertifiedProviderReportDto>> {
    return this.apiService.apiPost("/admin/certified-provider-report/paged", searchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AdminCertifiedProviderReportDto> {
    return this.apiService.apiGet("/admin/certified-provider-report/" + id, apiOptions);
  }

  generate(
    reportGenerateDto: AdminCertifiedProviderReportGenerateDto,
    apiOptions?: ApiOptions
  ): Promise<AdminCertifiedProviderReportDto> {
    return this.apiService.apiPost("/admin/certified-provider-report/generate", reportGenerateDto, apiOptions);
  }

}
