import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminProviderDto } from "../../api/admin/dtos/AdminProviderDto";
import { AdminProviderFiltersDto } from "../../api/admin/dtos/AdminProviderFiltersDto";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiAdminProvider } from "../../api/shared/hooks/useApiAdmin";
import { AppRoute } from "../../AppRoutes";
import { ButtonCreate } from "../../shared/components/Button/ButtonCreate";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import Grid from "../../shared/components/grid/Grid";
import {
  GridFilterType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import { TypeIcon } from "../../shared/components/Icon";
import Img from "../../shared/components/Img";
import RowButtonsToolbar from "../../shared/components/Layout/GRowButtonsToolbar";
import Page from "../../shared/components/Layout/Page";
import { useModal } from "../../shared/hooks/useModal";
import { useNavigation } from "../../shared/hooks/useNavigation";
import AdminProviderCreatePopup from "./AdminProviderCreatePopup";
import getAdminProviderListItemMenu from "./AdminProviderList.ItemMenu";

const AdminProviderList = () => {
  const [modal] = useModal();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [apiAdminProvider] = useApiAdminProvider();
  const navigation = useNavigation();

  const itemTemplates: IGridItemTemplate<AdminProviderDto>[] = [
    {
      header: "Logo",
      field: nameof<AdminProviderDto>((p) => p.providerSettings.logoIsoTypeUrl),
      width: 10,
      render: (adminProvider: AdminProviderDto) => (
        <Img src={adminProvider.providerSettings.logoIsoTypeUrl} />
      ),
    },
    {
      header: "Nombre",
      field: nameof<AdminProviderDto>((p) => p.name),
      width: 30,
    },

    {
      header: "País",
      field: nameof<AdminProviderDto>((p) => p.country),
      filter: {
        propertyName: nameof<AdminProviderFiltersDto>((p) => p.country),
        type: GridFilterType.countryAvailable,
      },
      width: 10,
      cellTypeEnumName: enumName.Country,
    },

    {
      header: "Cuenta asociada",
      field: nameof<AdminProviderDto>((p) => p.ownerAccount.name),
      width: 10,
    },
  ];

  return (
    <Page
      title="Proveedores de servicio"
      titleButton={
        <ButtonCreate
          text="Nuevo proveedor"
          onClick={() => modal.open(<AdminProviderCreatePopup />)}
        />
      }>
      <RowButtonsToolbar>
        <ButtonSecondary
          icon={TypeIcon.details}
          text="Ver usuarios vinculados"
          onClick={() => navigation.go(AppRoute.admin.providers.linkedUsers)}
        />
      </RowButtonsToolbar>
      <Grid
        itemTemplates={itemTemplates}
        reloadGrid={reloadGrid}
        onSearch={(search, options) => apiAdminProvider.getPaged(search, options)}
        onDisplayItemMenu={(adminProviderDto: AdminProviderDto) =>
          getAdminProviderListItemMenu(adminProviderDto, modal, navigation, () =>
            setReloadGrid(new Date())
          )
        }
      />
    </Page>
  );
};

export default AdminProviderList;
