// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { CurrencyExchangeRateDto } from "../dtos/CurrencyExchangeRateDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { dateToIsoString } from "../../shared/utils/apiUtilsDate";

export class ApiCurrencyExchangeRate {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getForCurrency(
    currency: number,
    dateUtc: Date,
    apiOptions?: ApiOptions
  ): Promise<CurrencyExchangeRateDto> {
    return this.apiService.apiGet("/currency-exchange-rates?" + 
      (currency ? "&currency=" + currency : "") + 
      (dateUtc ? "&dateUtc=" + dateToIsoString(dateUtc) : ""), apiOptions);
  }

}
