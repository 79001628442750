import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import Icon from "../Icon";

export interface ContextMenuItem {
  text: string;
  icon: any;
  className?: string;
  disabled?: any;
  hide?: boolean;
  onClick(): void;
}

const ContextMenu = (props: {
  anchorElement: any;
  items: ContextMenuItem[];
  open: boolean;
  onClose(): void;
}) => {
  return (
    <Menu
      anchorEl={props.anchorElement}
      open={props.open}
      onClose={props.onClose}
      style={{ minWidth: 320 }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}>
      {props.items.map((item: ContextMenuItem, index: number) => {
        if (item.hide) {
          return null;
        }
        return (
          <MenuItem
            key={index}
            style={{
              padding: 15,
              paddingLeft: 15,
              paddingRight: 45,
              minWidth: 280,
            }}
            disabled={item.disabled}
            onClick={() => {
              props.onClose();
              item.onClick();
            }}>
            <ListItemIcon style={{ paddingRight: 10 }}>
              <Icon type={item.icon} />
            </ListItemIcon>
            <ListItemText style={{ fontSize: 14 }}>{item.text}</ListItemText>
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default ContextMenu;
