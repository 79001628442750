import { ApiAdminAccessAccountBranch } from "../../api/admin/apis/ApiAdminAccessAccountBranch";
import { AccountBranchDto } from "../../api/app/dtos/AccountBranchDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import ModalDialog from "../../shared/components/Modal/ModalDialog";
import { ModalService } from "../../shared/hooks/useModal";
import { Notifier } from "../../shared/hooks/useNotifier";
import AdminAccountBranchDetailsPopup from "./AdminAccountBranchDetailsPopup";
import AdminAccountBranchEditPopup from "./AdminAccountBranchEditPopup";
import AdminPointOfSaleCreatePopup from "./AdminPointOfSale/AdminPointOfSaleCreatePopup";

export default function getAdminAccountBranchListItemMenu(
  accountBranchDto: AccountBranchDto,
  apiAdminAccessAccountBranch: ApiAdminAccessAccountBranch,
  modal: ModalService,
  notifier: Notifier,
  reloadGrid: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () =>
        modal.open(
          <AdminAccountBranchDetailsPopup
            accountId={accountBranchDto.accountId}
            accountBranchId={accountBranchDto.id}
          />,
          reloadGrid
        ),
    },
    {
      text: "Editar",
      icon: TypeIcon.edit,
      onClick: () =>
        modal.open(
          <AdminAccountBranchEditPopup
            accountId={accountBranchDto.accountId}
            accountBranchId={accountBranchDto.id}
          />,
          reloadGrid
        ),
    },
    {
      text: "Agregar punto de venta",
      icon: TypeIcon.create,
      onClick: () =>
        modal.open(
          <AdminPointOfSaleCreatePopup
            accountId={accountBranchDto.accountId}
            accountBranchId={accountBranchDto.id}
          />,
          reloadGrid
        ),
    },
    {
      text: "Inactivar",
      icon: TypeIcon.delete,
      hide: accountBranchDto.inactive,
      onClick: () => {
        modal.open(<ModalDialog text="¿Seguro desea inactivar la sucursal?" />, () => {
          const request = async () => {
            apiAdminAccessAccountBranch.inactivate(accountBranchDto.accountId, accountBranchDto.id);
            notifier.showSuccess("La sucursal se inactivó con éxito");
            reloadGrid();
          };
          request();
        });
      },
    },
    {
      text: "Reactivar",
      icon: TypeIcon.undo,
      hide: !accountBranchDto.inactive,
      onClick: () => {
        modal.open(<ModalDialog text="¿Seguro desea reactivar la sucursal?" />, () => {
          const request = async () => {
            apiAdminAccessAccountBranch.reactivate(accountBranchDto.accountId, accountBranchDto.id);
            notifier.showSuccess("La sucursal se reactivo con éxito");
            reloadGrid();
          };
          request();
        });
      },
    },
  ];
}
