// File generated by Gesta.Build.PostEvents
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { ProductFilterGroup } from "../../shared/enums/ProductFilterGroup";
import { ProductType } from "../../shared/enums/ProductType";

export class ProductFiltersDto extends FiltersDto {
  code: number | null = null;
  name: string = "";
  isForSales: boolean | null = null;
  isForPurchases: boolean | null = null;
  filterGroup: ProductFilterGroup;
  type: ProductType;
}
