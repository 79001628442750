// File generated by Gesta.Build.PostEvents
import { TemplateType } from "../../shared/enums/TemplateType";

export class TemplateConfigEmailTestDto  {
  isGlobal: boolean = false;
  templateId: string;
  templateType: TemplateType;
  testEmailTo: string = "";
  testEmailToName: string = "";
}
