import { useEffect, useState } from "react";
import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import Icon, { TypeIcon } from "../Icon";

function PasswordBuildHelperItem(props: { label: string; checked: boolean }) {
  const appTheme = useAppTheme();

  return (
    <div
      style={{
        display: "flex",
        marginBottom: 2,
        fontSize: 11,
        color: appTheme.toHexColor(props.checked ? AppThemeColor.success : AppThemeColor.gray),
      }}>
      <Icon
        type={props.checked ? TypeIcon.checkCircle : TypeIcon.check}
        color={props.checked ? AppThemeColor.success : AppThemeColor.gray}
        size={AppSize.small}
        style={{ marginRight: 10 }}
      />
      <div style={{ flex: 1, paddingTop: 2 }}>{props.label}</div>
    </div>
  );
}

const InputPasswordHelper = (props: { validateValue: string }) => {
  const [passwordHelperStatus, setPasswordHelperStatus] = useState<PasswordHelperStatus>(
    new PasswordHelperStatus()
  );

  const validatePassword = (value: string) => {
    const passwordHelperStatus = new PasswordHelperStatus();
    if (!value) {
      setPasswordHelperStatus(passwordHelperStatus);
      return;
    }

    const special = /[!¡@#$%^&*()_+={};':"|,.<>~ºª?~·-]/;
    passwordHelperStatus.capitalCase = value.toLowerCase() != value;
    passwordHelperStatus.lowerCase = value.toUpperCase() != value;
    passwordHelperStatus.numeric = /\d/.test(value);
    passwordHelperStatus.special = special.test(value);
    passwordHelperStatus.largerThan = value.length > 6;
    setPasswordHelperStatus(passwordHelperStatus);
  };

  useEffect(() => {
    validatePassword(props.validateValue);
  }, [props.validateValue]);

  return (
    <div>
      <PasswordBuildHelperItem label="Una mayúscula" checked={passwordHelperStatus.capitalCase} />
      <PasswordBuildHelperItem label="Una minúscula" checked={passwordHelperStatus.lowerCase} />
      <PasswordBuildHelperItem
        label="Un caracter especial"
        checked={passwordHelperStatus.special}
      />
      <PasswordBuildHelperItem label="Un número" checked={passwordHelperStatus.numeric} />
      <PasswordBuildHelperItem
        label="Al menos 6 caracteres"
        checked={passwordHelperStatus.largerThan}
      />
    </div>
  );
};

export class PasswordHelperStatus {
  capitalCase: boolean;
  lowerCase: boolean;
  numeric: boolean;
  special: boolean;
  largerThan: boolean;
}

export default InputPasswordHelper;
