import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { RequestedExportDto } from "../../api/app/dtos/RequestedExportDto";
import { RequestedExportStatus } from "../../api/shared/enums/RequestedExportStatus";
import { useApiRequestedExport } from "../../api/shared/hooks/useApiApp";
import { AppSize } from "../../shared/AppSize";
import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import { TypeIcon } from "../../shared/components/Icon";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { AppThemeColor } from "../../styles/color";
import RequestedExportHeaderList from "./RequestedExportHeaderList";

const AppRequestedExportHeaderIcon = () => {
  const { appInfo, requestedExportListOpen } = useContextAppInfo();
  const [apiRequestedExports] = useApiRequestedExport();
  const [requestedExports, setRequestedExports] = useState<RequestedExportDto[]>([]);
  const [includeDownloaded, setIncludeDownloaded] = useState(false);
  const [showIconProgress, setShowIconProgress] = useState(false);
  const [reload, setReload] = useState(new Date());

  const download = async (item: RequestedExportDto) => {
    await apiRequestedExports.download(item.id, { preventSpinner: true });
    load(true);
  };

  const inactivate = async (item: RequestedExportDto) => {
    await apiRequestedExports.inactivate(item.id, { preventSpinner: true });
    load(true);
  };

  const cancel = async (item: RequestedExportDto) => {
    await apiRequestedExports.cancel(item.id, { preventSpinner: true });
    load(true);
  };

  const load = async (closeIfEmpty: boolean) => {
    const result = await apiRequestedExports.getForDashboard(includeDownloaded, {
      preventNotifications: true,
      preventSpinner: true,
    });
    setRequestedExports(result);

    const existsPending = result.find((x) => x.status == RequestedExportStatus.Pending) != null;
    const pendingDownload = result.find(
      (x) => x.status == RequestedExportStatus.Done && !x.isDownloaded
    );
    const isOpen = appInfo?.requestedExportListIsOpen == true;
    if (pendingDownload != null) {
      if (!isOpen) {
        requestedExportListOpen(true);
      } else {
        download(pendingDownload);
      }
      return;
    }

    if (existsPending) {
      setShowIconProgress(!isOpen);
      setTimeout(() => {
        setReload(new Date());
      }, 2000);
      return;
    }

    setShowIconProgress(false);
    if (closeIfEmpty) {
      requestedExportListOpen(false);
    }
  };

  useEffect(() => {
    load(false);
  }, [reload, appInfo?.requestedExportListIsOpen, includeDownloaded]);

  return (
    <>
      <div style={{ paddingTop: 10 }}>
        <ButtonIcon
          icon={TypeIcon.download}
          size={AppSize.large}
          color={AppThemeColor.gray}
          noPadding={true}
          onClick={() => requestedExportListOpen(true)}
        />
        {showIconProgress && <LinearProgress />}
      </div>
      {appInfo?.requestedExportListIsOpen && (
        <RequestedExportHeaderList
          items={requestedExports}
          includeDownloaded={includeDownloaded}
          onCancel={(item) => cancel(item)}
          onClose={() => requestedExportListOpen(false)}
          onClick={(item) => {
            if (item.status == RequestedExportStatus.Error) {
              inactivate(item);
              return;
            }
            if (item.fileUrl) {
              download(item);
            }
          }}
          onIncludedDownloaded={(value) => setIncludeDownloaded(value)}
        />
      )}
    </>
  );
};

export default AppRequestedExportHeaderIcon;
