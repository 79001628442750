import { nameof } from "ts-simple-nameof";
import { UserCrudDto } from "../../../api/admin/dtos/UserCrudDto";
import { UserDto } from "../../../api/admin/dtos/UserDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import { validateSchema } from "../../../shared/validation/schema/ValidationFieldSchema";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

function useUserValidation() {
  const schema = new ValidationSchema();

  schema[nameof<UserDto>((p) => p.firstName)] = validate("Nombre").required();
  schema[nameof<UserDto>((p) => p.lastName)] = validate("Apellido").required();
  schema[nameof<UserDto>((p) => p.email)] = validate("Nombre").required();

  return schema;
}

export function useUserCrudValidation() {
  const userCrudSchema = new ValidationSchema();

  userCrudSchema[nameof<UserCrudDto>((p) => p.user)] = validateSchema(
    useUserValidation(),
    "Usuario"
  );

  return userCrudSchema;
}
