// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { EInvoiceEnvelopeStatusResponseSaleDto } from "../dtos/EInvoiceEnvelopeStatusResponseSaleDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiEInvoiceEnvelopeStatusResponseSale {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getAllByEnvelopeId(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<EInvoiceEnvelopeStatusResponseSaleDto[]> {
    return this.apiService.apiGet("/e-invoice-envelope-status-response-sale/for-envelope-all/" + id, apiOptions);
  }

}
