import { useEffect, useState } from "react";
import { GridSettingsDto } from "../../../api/app/dtos/GridSettingsDto";
import { ModalSize } from "../../context/ModalProvider";
import { useApiGridSettings } from "../../../api/shared/hooks/useApiApp";
import { useModal } from "../../hooks/useModal";
import { IdName } from "../../IdName";
import { TextAlign } from "../../TextAlign";
import { ButtonAccept } from "../Button/ButtonAccept";
import ButtonLink from "../Button/ButtonLink";
import { TypeIcon } from "../Icon";
import InputCheck from "../inputs/InputCheck";
import InputSelectEnumBase from "../inputs/select/InputSelectEnumBase";
import Column from "../Layout/GColumn";
import Container from "../Layout/GContainer";
import Row from "../Layout/GRow";
import RowButtons from "../Layout/GRowButtons";
import ScrollableArea from "../Layout/ScrollableArea";
import ModalDialog from "../Modal/ModalDialog";
import { gridSettingsStorageRemove } from "./GridSettingsStorage";
import { IGridItemTemplate, MobileSpecialType } from "./ItemTemplate/IGridItemTemplate";

class ColumnOption {
  field: string;
  header: string;
  visible: boolean;
  disabled: boolean;
}

const GridSettings = (props: {
  gridSettings: GridSettingsDto;
  visibleColumns: IGridItemTemplate<any>[];
  columnTemplates: IGridItemTemplate<any>[];
}) => {
  const [modal] = useModal();
  useEffect(() => {
    modal.setVisible("Configurar grilla", ModalSize.small);
  }, [modal]);

  const [apiGridSettings] = useApiGridSettings();

  const [pageSize, setPageSize] = useState(props.gridSettings.pageSize);

  const [columns, setColumns] = useState(() => {
    const visibleColumns = props.visibleColumns
      .filter((c) => !c.actionType)
      .map((c) => ({
        field: c.field,
        header: c.headerAlt ?? c.header,
        visible: true,
        disabled: c.mobileSpecialType == MobileSpecialType.Main || c.isMandatory,
      })) as ColumnOption[];

    const columnTemplate = props.columnTemplates
      .filter((c) => !c.actionType && !visibleColumns.find((v) => c.field === v.field))
      .map((c) => ({
        field: c.field,
        header: c.header,
        visible: false,
      })) as ColumnOption[];

    return visibleColumns.concat(columnTemplate);
  });

  return (
    <Container>
      <Column md={4}>
        <InputSelectEnumBase
          label="Registros por página"
          value={pageSize}
          options={[
            new IdName(1, "1"),
            new IdName(5, "5"),
            new IdName(10, "10"),
            new IdName(20, "20"),
            new IdName(30, "30"),
            new IdName(40, "40"),
            new IdName(50, "50"),
            new IdName(100, "100"),
          ]}
          onChange={(value) => setPageSize(value ?? 1)}
        />
      </Column>

      {props.gridSettings.id && (
        <Column md={8} align={TextAlign.right}>
          <ButtonLink
            text="Usar configuración por defecto"
            icon={TypeIcon.eraser}
            onClick={() => {
              modal.open(
                <ModalDialog text="¿Seguro desea utilizar la configuración por defecto?" />,
                () => {
                  const request = async () => {
                    if (props.gridSettings.accountUserId) {
                      await apiGridSettings.removeForUser(props.gridSettings.id);
                    } else {
                      await apiGridSettings.removeGlobal(props.gridSettings.id);
                    }
                    gridSettingsStorageRemove(props.gridSettings);
                    modal.closeAsSuccess(null, "");
                  };
                  request();
                }
              );
            }}
          />
        </Column>
      )}

      <Row label="Columnas">
        <ScrollableArea maxHeight={350}>
          {columns.map((column: ColumnOption, index: number) => (
            <Row key={index} minHeightIgnore={true}>
              <InputCheck
                value={column.visible}
                label={column.header ?? "-"}
                readOnly={column.disabled}
                onChange={(value) => {
                  columns[index].visible = value;
                  setColumns([...columns]);
                }}
              />
            </Row>
          ))}
        </ScrollableArea>
      </Row>

      <RowButtons preventValidation={true}>
        <ButtonAccept
          onClick={() => {
            const saveSettings = async () => {
              let gridSettings = {
                ...props.gridSettings,
                pageSize: pageSize,
                visibleColumns: columns
                  .filter((c) => c.visible)
                  .map((c) => c.field)
                  .join(),
              } as GridSettingsDto;

              gridSettingsStorageRemove(gridSettings);
              gridSettings = await apiGridSettings.createOrUpdateGlobal(gridSettings);
              modal.closeAsSuccess(gridSettings, "");
            };
            saveSettings();
          }}
        />
      </RowButtons>
    </Container>
  );
};

export default GridSettings;
