import { ApiAdminAccountPublicTemplateGeneric } from "../../../api/admin/apis/ApiAdminAccountPublicTemplateGeneric";
import { AdminTemplateGenericDto } from "../../../api/admin/dtos/AdminTemplateGenericDto";
import { AppRoute } from "../../../AppRoutes";
import { ContextMenuItem } from "../../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../../shared/components/Icon";
import ModalDialog from "../../../shared/components/Modal/ModalDialog";
import { ModalService } from "../../../shared/hooks/useModal";
import { Navigation } from "../../../shared/hooks/useNavigation";
import { Notifier } from "../../../shared/hooks/useNotifier";

export function getAdminTemplateGenericListItemMenu(
  adminTemplateGenericDto: AdminTemplateGenericDto,
  apiTemplateGeneric: ApiAdminAccountPublicTemplateGeneric,
  navigation: Navigation,
  modal: ModalService,
  notifier: Notifier,
  setReloadGrid: (date: Date) => void
): ContextMenuItem[] {
  return [
    {
      text: "Editar",
      icon: TypeIcon.edit,
      hide: adminTemplateGenericDto.inactive,
      onClick: () => {
        navigation.go(AppRoute.admin.templates.generic.edit, adminTemplateGenericDto.id);
      },
    },
    {
      text: "Inactivar",
      icon: TypeIcon.delete,
      hide: adminTemplateGenericDto.inactive,
      onClick: () => {
        modal.open(<ModalDialog text="¿Seguro desea eliminar la plantilla genérica?" />, () => {
          apiTemplateGeneric.inactivate(adminTemplateGenericDto.id);
          notifier.showSuccessFast("La plantilla genérica se inactivó con éxito");
          setReloadGrid(new Date());
        });
      },
    },
    {
      text: "Reactivar",
      icon: TypeIcon.undo,
      hide: !adminTemplateGenericDto.inactive,
      onClick: () => {
        modal.open(<ModalDialog text="¿Seguro desea reactivar la plantilla genérica?" />, () => {
          apiTemplateGeneric.reactivate(adminTemplateGenericDto.id);
          notifier.showSuccessFast("La plantilla genérica se reactivó con éxito");
          setReloadGrid(new Date());
        });
      },
    },
  ];
}
