import { nameof } from "ts-simple-nameof";
import { AdminProviderDto } from "../../api/admin/dtos/AdminProviderDto";
import { FileUploadExtensionsAllowed } from "../../api/shared/enums/FileUploadExtensionsAllowed";
import { StorageContainerType } from "../../api/shared/enums/StorageContainerType";
import { FileUploadType } from "../../shared/components/FileUpload";
import FormFile from "../../shared/components/forms/FormFile";
import Column from "../../shared/components/Layout/GColumn";
import Row from "../../shared/components/Layout/GRow";
import Section from "../../shared/components/Layout/GSection";
import { AppThemeColor } from "../../styles/color";

const AdminProviderFormLogos = () => {
  return (
    <>
      <Column>
        <FormFile
          label="Imagen vacía"
          hint="Se utiliza cuando no hay imagen disponible"
          propertyName={nameof<AdminProviderDto>((p) => p.providerSettings.emptyImageUrl)}
          extensionsAllowed={FileUploadExtensionsAllowed.Images}
          uploadType={FileUploadType.ToStorageAdminShared}
          storageContainerType={StorageContainerType.Images}
        />
      </Column>

      <Row minHeightIgnore={true} />

      <Column md={6}>
        <Section background={AppThemeColor.grayLight}>
          <Column md={6}>
            <FormFile
              label="Logo"
              hint="Usar una imagen transparente de 419px por 471px"
              propertyName={nameof<AdminProviderDto>((p) => p.providerSettings.logoUrl)}
              extensionsAllowed={FileUploadExtensionsAllowed.Images}
              uploadType={FileUploadType.ToStorageAdminShared}
              storageContainerType={StorageContainerType.Images}
            />
          </Column>
          <Column md={6}>
            <FormFile
              label="Logo blanco"
              hint="Usar una imagen transparente de un sólo color de 419px por 471px"
              propertyName={nameof<AdminProviderDto>((p) => p.providerSettings.logoUrl)}
              extensionsAllowed={FileUploadExtensionsAllowed.Images}
              uploadType={FileUploadType.ToStorageAdminShared}
              storageContainerType={StorageContainerType.Images}
            />
          </Column>
        </Section>
      </Column>

      <Column md={6}>
        <Section background={AppThemeColor.grayLight}>
          <Column md={6}>
            <FormFile
              label="IsoTipo"
              hint="Usar una imagen transparente color de 285px por 308px"
              propertyName={nameof<AdminProviderDto>((p) => p.providerSettings.logoIsoTypeUrl)}
              extensionsAllowed={FileUploadExtensionsAllowed.Images}
              uploadType={FileUploadType.ToStorageAdminShared}
              storageContainerType={StorageContainerType.Images}
            />
          </Column>
          <Column md={6}>
            <FormFile
              label="IsoTipo blanco"
              hint="Usar una imagen transparente de un sólo color de 285px por 308px"
              propertyName={nameof<AdminProviderDto>((p) => p.providerSettings.logoIsoTypeWhiteUrl)}
              extensionsAllowed={FileUploadExtensionsAllowed.Images}
              uploadType={FileUploadType.ToStorageAdminShared}
              storageContainerType={StorageContainerType.Images}
            />
          </Column>
        </Section>
      </Column>
      <Column md={6}>
        <Section background={AppThemeColor.grayLight}>
          <Column md={6}>
            <FormFile
              label="Logo nombre"
              hint="Usar una imagen transparente de un sólo color de 357px por 108px"
              propertyName={nameof<AdminProviderDto>((p) => p.providerSettings.logoProductNameUrl)}
              extensionsAllowed={FileUploadExtensionsAllowed.Images}
              uploadType={FileUploadType.ToStorageAdminShared}
              storageContainerType={StorageContainerType.Images}
            />
          </Column>
          <Column md={6}>
            <FormFile
              label="Logo nombre blanco"
              hint="Usar una imagen transparente de un sólo color de 357px por 108px"
              propertyName={nameof<AdminProviderDto>(
                (p) => p.providerSettings.logoProductNameWhiteUrl
              )}
              extensionsAllowed={FileUploadExtensionsAllowed.Images}
              uploadType={FileUploadType.ToStorageAdminShared}
              storageContainerType={StorageContainerType.Images}
            />
          </Column>
        </Section>
      </Column>
    </>
  );
};

export default AdminProviderFormLogos;
