import { useEffect, useState } from "react";
import { AccountConfigTaxesCrudDto } from "../../../api/app/dtos/AccountConfigTaxesCrudDto";
import { Tax } from "../../../api/shared/enums/Tax";
import { enumName } from "../../../api/shared/enums/_enumName";
import ButtonAccept from "../../../shared/components/Button/ButtonAccept";
import Grid, { IGridSelection } from "../../../shared/components/grid/Grid";
import InputRadioBoolean from "../../../shared/components/inputs/InputRadioBoolean";
import InputSelectEnum from "../../../shared/components/inputs/select/InputSelectEnum";
import Column from "../../../shared/components/Layout/GColumn";
import Container from "../../../shared/components/Layout/GContainer";
import Row from "../../../shared/components/Layout/GRow";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import Page from "../../../shared/components/Layout/Page";
import ModalMessage from "../../../shared/components/Modal/ModalMessage";
import { loginBroadCastChannel } from "../../../shared/context/LoginInfoProvider";
import { useApiAccountConfig, useApiTax } from "../../../api/shared/hooks/useApiApp";
import { useModal } from "../../../shared/hooks/useModal";
import { useNotifier } from "../../../shared/hooks/useNotifier";

const ConfigTaxes = () => {
  const notifier = useNotifier();
  const [apiAccountConfig] = useApiAccountConfig();
  const [modal] = useModal();
  const [allCountryTaxes, setAllCountryTaxes] = useState<Tax[]>();
  const [apiTax] = useApiTax();
  const [configDto, setConfigDto] = useState<AccountConfigTaxesCrudDto>();

  const save = async () => {
    if (!configDto) {
      return null;
    }
    await apiAccountConfig.configTaxes(configDto);
    notifier.showSuccessFast("La configuración de los impuestos se actualizó con éxito");
    modal.open(<ModalMessage text="Este cambio requiere recargar la página" />, () => {
      loginBroadCastChannel.postMessage("ReloadPage");
      window.location.reload();
    });
  };

  useEffect(() => {
    const load = async () => {
      setAllCountryTaxes(await apiTax.getAllForCurrentAccountCountry());
      const config = await apiAccountConfig.getForTaxes();
      setConfigDto(config);
    };
    load();
  }, []);

  if (!allCountryTaxes || !configDto) {
    return <></>;
  }

  return (
    <Page title="Configurar impuestos">
      <Column md={4}></Column>
      <Column md={4}>
        <Container>
          <Column md={6}>
            <InputSelectEnum
              label="Impuesto por defecto"
              enumTypeName={enumName.Tax}
              optionValues={allCountryTaxes}
              value={configDto.defaultTax}
              onChange={(value: Tax) => setConfigDto({ ...configDto, defaultTax: value })}
            />
          </Column>

          <Row>
            <InputRadioBoolean
              value={configDto.allTaxes}
              labelTrue="Mostrar todos los impuestos"
              labelFalse="Definir impuestos"
              onChange={(value: boolean) => setConfigDto({ ...configDto, allTaxes: value })}
            />
          </Row>

          <Row>
            {!configDto.allTaxes && (
              <Grid
                clientSideItems={allCountryTaxes}
                preventShowDefaultSearch={true}
                hideIncludeInactiveButton={true}
                showSelectionColumn={true}
                itemTemplates={[
                  {
                    field: "",
                    header: "Impuesto",
                    cellTypeEnumName: enumName.Tax,
                    width: 100,
                    orderByPrevent: true,
                  },
                ]}
                selectedItems={configDto.taxes}
                onSelectionChange={(gridSelection: IGridSelection<Tax>) => {
                  setConfigDto({ ...configDto, taxes: gridSelection.selectedList });
                }}
              />
            )}
            {configDto.allTaxes && (
              <Grid
                clientSideItems={allCountryTaxes}
                preventShowDefaultSearch={true}
                hideIncludeInactiveButton={true}
                itemTemplates={[
                  {
                    field: "",
                    header: "Impuesto",
                    cellTypeEnumName: enumName.Tax,
                    width: 100,
                    orderByPrevent: true,
                  },
                ]}
              />
            )}
          </Row>

          <RowButtons preventValidation={true}>
            <ButtonAccept onClick={save} />
          </RowButtons>
        </Container>
      </Column>
    </Page>
  );
};

export default ConfigTaxes;
