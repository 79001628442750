import TabStepper, { TabStep } from "../../shared/components/Layout/TabStepper";
import TabView from "../../shared/components/Layout/TabView";
import AdminAccountFormAccount from "./AdminAccountForm.Account";
import AdminAccountFormCompany from "./AdminAccountForm.Company";
import AdminAccountFormContact from "./AdminAccountForm.Contact";
import AdminAccountFormEInvoice from "./AdminAccountForm.EInvoice";

const TABS = {
  GENERAL: "GENERAL",
  COMPANY: "COMPANY",
  CONTACT: "CONTACT",
  EINVOICE: "EINVOICE",
};

const AdminAccountForm = (props: { onSave(): void; isEdit: boolean }) => {
  const tabs: TabStep[] = [
    { title: "General", value: TABS.GENERAL },
    { title: "Empresa", value: TABS.COMPANY },
    { title: "Contacto", value: TABS.CONTACT },
    { title: "eFactura", value: TABS.EINVOICE },
  ];

  return (
    <TabStepper tabs={tabs} onLastTabAccept={props.onSave} isEditMode={props.isEdit}>
      <TabView value={TABS.GENERAL}>
        <AdminAccountFormAccount isEdit={props.isEdit} />
      </TabView>
      <TabView value={TABS.COMPANY}>
        <AdminAccountFormCompany />
      </TabView>
      <TabView value={TABS.CONTACT}>
        <AdminAccountFormContact />
      </TabView>
      <TabView value={TABS.EINVOICE}>
        <AdminAccountFormEInvoice />
      </TabView>
    </TabStepper>
  );
};

export default AdminAccountForm;
