import { useEffect, useState } from "react";
import { PointOfSaleDto } from "../../../api/app/dtos/PointOfSaleDto";
import { useApiAdminProviderAccessPointOfSale } from "../../../api/shared/hooks/useApiAdminProviderAccess";
import IconCheck from "../../../shared/components/IconCheck";
import ColumnDisplay from "../../../shared/components/Layout/GColumnDisplay";
import Container from "../../../shared/components/Layout/GContainer";
import { ModalSize } from "../../../shared/context/ModalProvider";
import { useModal } from "../../../shared/hooks/useModal";

const AdminPointOfSaleDetailsPopup = (props: { accountId: string; pointOfSaleId: string }) => {
  const [modal] = useModal();
  const [pointOfSale, setPointOfSale] = useState<PointOfSaleDto>();
  const [apiAdminAccessPointOfSale] = useApiAdminProviderAccessPointOfSale();

  useEffect(() => {
    const loadAdminAccount = async () => {
      setPointOfSale(await apiAdminAccessPointOfSale.getById(props.accountId, props.pointOfSaleId));
      modal.setVisible("Detalles del punto de venta", ModalSize.small);
    };
    loadAdminAccount();
  }, []);

  if (!pointOfSale) {
    return <></>;
  }

  return (
    <Container>
      <ColumnDisplay label="Sucursal">{pointOfSale.accountBranch.displayName}</ColumnDisplay>
      <ColumnDisplay label="Nombre">{pointOfSale.name}</ColumnDisplay>
      <ColumnDisplay label="Por defecto">
        <IconCheck value={pointOfSale.isDefault} showHyphen={true} />
      </ColumnDisplay>
    </Container>
  );
};

export default AdminPointOfSaleDetailsPopup;
