import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import PropsAdminProviderLogoIsoType from "../AdminProviderLogoIsoType";
import ButtonIcon from "../Button/ButtonIcon";
import { TypeIcon } from "../Icon";

export interface IPropsModalHeader {
  className?: string | string[];
  isDialog: boolean;
  title?: string;
  onClickClose: () => void;
}

const ModalHeader = (props: IPropsModalHeader) => {
  const appTheme = useAppTheme();
  return (
    <div
      className={"layout-modal-header " + (props?.className ?? "")}
      style={{
        background: appTheme.toHexColor(AppThemeColor.header),
        color: appTheme.toHexColor(AppThemeColor.primaryContrast),
      }}>
      {props.isDialog && (
        <PropsAdminProviderLogoIsoType
          size={AppSize.large}
          onlyWhite={true}
          style={{ position: "absolute", top: 20, left: 20 }}
        />
      )}
      {props.title}
      {
        <ButtonIcon
          icon={TypeIcon.close}
          className="layout-close"
          size={AppSize.bigger}
          color={AppThemeColor.white}
          onClick={() => props.onClickClose()}
        />
      }
    </div>
  );
};

export default ModalHeader;
