import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import useUtils from "../../shared/hooks/useUtils";
import PurchaseDetailsColumn from "./PurchaseDetails.Column";

const PurchaseDetailsSender = (props: { purchase: PurchaseDto }) => {
  const { utils } = useUtils();
  const purchase = props.purchase;

  return (
    <>
      <PurchaseDetailsColumn label="RUC">{purchase.sender.taxPayerId}</PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Razón social">
        {purchase.sender.companyName}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Nombre">{purchase.sender.comercialName}</PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Giro">{purchase.sender.activity}</PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Teléfono">{purchase.sender.phone}</PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Correo">{purchase.sender.email}</PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Sucursal">
        {purchase.sender.branchCode} {purchase.sender.branchName}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Domicilio fiscal">
        {purchase.sender.address.addressLine}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Ciudad">{purchase.sender.address.city}</PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Departamento">
        {utils.enum.translateState(purchase.sender.address.state)}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Info adicional">
        {purchase.sender.extraInfo}
      </PurchaseDetailsColumn>
    </>
  );
};

export default PurchaseDetailsSender;
