import { nameof } from "ts-simple-nameof";
import { AdminTemplateGenericDto } from "../../../../api/admin/dtos/AdminTemplateGenericDto";
import { validate } from "../../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../../shared/validation/schema/ValidationSchema";

export function useAdminTemplateGenericValidation() {
  const schema = new ValidationSchema();

  schema[nameof<AdminTemplateGenericDto>((p) => p.type)] = validate("Tipo").required();
  schema[nameof<AdminTemplateGenericDto>((p) => p.name)] = validate("Nombre").required();
  schema[nameof<AdminTemplateGenericDto>((p) => p.code)] = validate("Código").required();

  return schema;
}
