// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { InvoiceSimpleDto } from "../dtos/InvoiceSimpleDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { SaleDto } from "../dtos/SaleDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { SaleFiltersDto } from "../dtos/SaleFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { SaleInvoiceTypeResultDto } from "../dtos/SaleInvoiceTypeResultDto";
import { SaleForCreateOptionsDto } from "../dtos/SaleForCreateOptionsDto";
import { SaleEmittedDto } from "../dtos/SaleEmittedDto";
import { SuggestResponseDto } from "../../shared/dtos/SuggestResponseDto";
import { SuggestSearchDto } from "../../shared/dtos/SuggestSearchDto";

export class ApiSale {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  cancel(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<InvoiceSimpleDto> {
    return this.apiService.apiPut("/sales/cancel/" + id, null, apiOptions);
  }

  cancelBeforeSend(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<InvoiceSimpleDto> {
    return this.apiService.apiPut("/sales/cancel-before-send/" + id, null, apiOptions);
  }

  eInvoiceForceNextStep(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<string> {
    return this.apiService.apiPut("/sales/e-invoice-force-next-step/" + id, null, apiOptions);
  }

  eInvoiceForceNextStepVerify(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<string> {
    return this.apiService.apiPut("/sales/e-invoice-force-next-step-verify/" + id, null, apiOptions);
  }

  getPaged(
    saleSearchDto: PagedSearchDto<SaleFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<SaleDto>> {
    return this.apiService.apiPost("/sales/paged", saleSearchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<SaleDto> {
    return this.apiService.apiGet("/sales/" + id, apiOptions);
  }

  getByIdSimple(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<InvoiceSimpleDto> {
    return this.apiService.apiGet("/sales/simple/" + id, apiOptions);
  }

  getInvoiceType(
    saleForCreateOptionsDto: SaleForCreateOptionsDto,
    apiOptions?: ApiOptions
  ): Promise<SaleInvoiceTypeResultDto> {
    return this.apiService.apiPost("/sales/invoice-type", saleForCreateOptionsDto, apiOptions);
  }

  getToCreate(
    saleForCreateOptionsDto: SaleForCreateOptionsDto,
    apiOptions?: ApiOptions
  ): Promise<SaleDto> {
    return this.apiService.apiPost("/sales/to-create", saleForCreateOptionsDto, apiOptions);
  }

  getToCreateCancellationFromSale(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<SaleDto> {
    return this.apiService.apiGet("/sales/to-create-cancellation-from-sale/" + id, apiOptions);
  }

  getToCreateClone(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<SaleDto> {
    return this.apiService.apiGet("/sales/to-create-clone/" + id, apiOptions);
  }

  getSimpleByNumber(
    type: number,
    serie: string,
    number: number,
    apiOptions?: ApiOptions
  ): Promise<InvoiceSimpleDto> {
    return this.apiService.apiGet("/sales/simple?" + 
      (type ? "&type=" + type : "") + 
      (serie ? "&serie=" + serie : "") + 
      (number ? "&number=" + number : ""), apiOptions);
  }

  draftSave(
    saleDto: SaleDto,
    apiOptions?: ApiOptions
  ): Promise<InvoiceSimpleDto> {
    return this.apiService.apiPost("/sales/draft-save", saleDto, apiOptions);
  }

  draftDiscard(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/sales/draft-discard/" + id, null, apiOptions);
  }

  draftDelete(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiDelete("/sales/draft-delete/" + id, apiOptions);
  }

  draftDiscardUndo(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/sales/draft-discard-undo/" + id, null, apiOptions);
  }

  emit(
    saleDto: SaleDto,
    apiOptions?: ApiOptions
  ): Promise<SaleEmittedDto> {
    return this.apiService.apiPost("/sales/emit", saleDto, apiOptions);
  }

  pdfRegenerate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<string> {
    return this.apiService.apiPost("/sales/pdf-regenerate/" + id, null, apiOptions);
  }

  retrySend(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/sales/retry-send/" + id, null, apiOptions);
  }

  suggest(
    suggestSearch: SuggestSearchDto<SaleFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/sales/suggest", suggestSearch, apiOptions);
  }

}
