import { useEffect, useState } from "react";
import { SaleSenderBehalfDto } from "../../api/app/dtos/SaleSenderBehalfDto";
import { useApiSaleSenderBehalf } from "../../api/shared/hooks/useApiApp";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import SaleSenderBehalfForm from "./SaleSenderBehalf.Form";
import { useSaleSenderBehalfValidation } from "./Validations/useSaleSenderBehalfValidation";

const SaleSenderBehalfEditPopup = (props: { id: string }) => {
  const [saleSenderBehalf, setSaleSenderBehalf] = useState<SaleSenderBehalfDto>();
  const [modal] = useModal();
  const [apiSaleSenderBehalf] = useApiSaleSenderBehalf();

  const save = async (saleSenderBehalf: SaleSenderBehalfDto) => {
    const create = await apiSaleSenderBehalf.edit(saleSenderBehalf);
    modal.closeAsSuccess(create, "El emisor por cuenta ajena se editó con éxito");
  };

  useEffect(() => {
    const loadProduct = async () => {
      setSaleSenderBehalf(await apiSaleSenderBehalf.getById(props.id));
      modal.setVisible("Editar emisor por cuenta ajena", ModalSize.small);
    };
    loadProduct();
  }, []);

  if (!saleSenderBehalf) {
    return <></>;
  }
  return (
    <StateProvider
      model={saleSenderBehalf}
      validationSchema={useSaleSenderBehalfValidation()}
      onSubmit={save}>
      {(p) => <SaleSenderBehalfForm onSubmit={p.handleSubmit} />}
    </StateProvider>
  );
};

export default SaleSenderBehalfEditPopup;
