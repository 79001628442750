import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import { enumName } from "../../api/shared/enums/_enumName";
import useUtils from "../../shared/hooks/useUtils";
import PurchaseDetailsColumn from "./PurchaseDetails.Column";

const PurchaseDetailsAuthorization = (props: { purchase: PurchaseDto }) => {
  const authorization = props.purchase.purchaseInfo.purchaseSenderAuthorization;
  const { utils } = useUtils();

  const specialType = authorization.specialType
    ? utils.enum.translate(enumName.EInvoiceAuthorizationSpecialType, authorization.specialType)
    : "-";

  return (
    <>
      <PurchaseDetailsColumn label="ID">{authorization.authorizationId}</PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Fecha de expiración">
        {utils.date.toString(authorization.expirationDate)}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Desde/Hasta">
        {utils.number.toIntString(authorization.numberFrom)}-
        {utils.number.toIntString(authorization.numberTo)}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Tipo especial">{specialType}</PurchaseDetailsColumn>
    </>
  );
};

export default PurchaseDetailsAuthorization;
