import { ApiAdminProviderUserLinkedAccount } from "../../api/admin/apis/ApiAdminProviderUserLinkedAccount";
import { UserLinkedAccountDto } from "../../api/admin/dtos/UserLinkedAccountDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import ModalDialog from "../../shared/components/Modal/ModalDialog";
import { ModalService } from "../../shared/hooks/useModal";
import { Notifier } from "../../shared/hooks/useNotifier";

export default function getUserLinkedAccountListItemMenu(
  userLinkedAccountDto: UserLinkedAccountDto,
  apiAdminUserLinkedAccount: ApiAdminProviderUserLinkedAccount,
  notifier: Notifier,
  modal: ModalService,
  onGridReload: (date: Date) => void
): ContextMenuItem[] {
  return [
    {
      text: "Hacer administrador",
      icon: TypeIcon.adminAccount,
      hide: userLinkedAccountDto.isAccountAdmin,
      onClick: () => {
        modal.open(<ModalDialog text="¿Está seguro de dar permisos de administrador?" />, () => {
          const request = async () => {
            await apiAdminUserLinkedAccount.setAsAccountAdmin(userLinkedAccountDto.id);
            notifier.showSuccess("El usuario es administrador de la cuenta");
            onGridReload(new Date());
          };
          request();
        });
      },
    },
    {
      text: "Deshacer administrador",
      icon: TypeIcon.undo,
      hide: !userLinkedAccountDto.isAccountAdmin,
      onClick: () => {
        modal.open(
          <ModalDialog text="¿Está seguro de sacar los permisos de administrador?" />,
          () => {
            const request = async () => {
              await apiAdminUserLinkedAccount.removeAccountAdmin(userLinkedAccountDto.id);
              notifier.showSuccess("El usuario ya no es administrador de la cuenta");
              onGridReload(new Date());
            };
            request();
          }
        );
      },
    },
    {
      text: "Inactivar",
      icon: TypeIcon.delete,
      hide: userLinkedAccountDto.inactive,
      onClick: () => {
        modal.open(<ModalDialog text="¿Está seguro de inactivar la asociación?" />, () => {
          const request = async () => {
            await apiAdminUserLinkedAccount.inactivate(userLinkedAccountDto.id);
            notifier.showSuccess("La asociación se inactivó con éxito");
            onGridReload(new Date());
          };
          request();
        });
      },
    },
    {
      text: "Reactivar",
      icon: TypeIcon.undo,
      hide: !userLinkedAccountDto.inactive,
      onClick: () => {
        const request = async () => {
          await apiAdminUserLinkedAccount.reactivate(userLinkedAccountDto.id);
          notifier.showSuccess("La asociación se reactivó con éxito");
          onGridReload(new Date());
        };
        request();
      },
    },
  ];
}
