import { nameof } from "ts-simple-nameof";
import { AdminAccountDto } from "../../api/admin/dtos/AdminAccountDto";
import FormDate from "../../shared/components/forms/FormDate";
import FormSwitch from "../../shared/components/forms/FormSwitch";
import FormText from "../../shared/components/forms/FormText";
import Column from "../../shared/components/Layout/GColumn";
import Row from "../../shared/components/Layout/GRow";

const AdminAccountFormEInvoice = () => {
  return (
    <>
      <Row minHeightIgnore={true}>
        <FormSwitch
          label="Habilitar"
          propertyName={nameof<AdminAccountDto>((p) => p.eInvoiceIsActive)}
        />
      </Row>
      <Column md={6}>
        <FormDate
          label="Fecha de inicio"
          propertyName={nameof<AdminAccountDto>((p) => p.eInvoiceStartDate)}
        />
      </Column>

      <Column md={6}>
        <FormDate
          label="Fecha inicio régimen"
          propertyName={nameof<AdminAccountDto>((p) => p.company.eInvoiceRegimenStartDate)}
        />
      </Column>
      <Row minHeightIgnore={true}>
        <FormSwitch
          label="IVA al día"
          propertyName={nameof<AdminAccountDto>((p) => p.company.eInvoiceIsTaxUpToDate)}
        />
      </Row>
      <Row minHeightIgnore={true}>
        <FormSwitch
          label="Contribuyente exento"
          propertyName={nameof<AdminAccountDto>((p) => p.company.eInvoiceIsExemptTaxPayer)}
        />
      </Row>
      <Row>
        <FormText
          label="Etiqueta de impresión de contribuyente exento o IVA mínimo"
          propertyName={nameof<AdminAccountDto>((p) => p.company.eInvoicePrintLegendExemptTaxPayer)}
        />
      </Row>
    </>
  );
};

export default AdminAccountFormEInvoice;
