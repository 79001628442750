import { useContext, useEffect, useState } from "react";
import { TaxValueDto } from "../../../../api/app/dtos/TaxValueDto";
import { enumName } from "../../../../api/shared/enums/_enumName";
import useUtils from "../../../hooks/useUtils";
import { IdName } from "../../../IdName";
import { TaxValueContext } from "../../TaxValue/TaxValueProvider";
import InputSelectEnumBase, { PropsInputSelectEnumBase } from "./InputSelectEnumBase";

export type PropsInputSelectTaxValue = Omit<PropsInputSelectEnumBase<TaxValueDto>, "options">;

const InputSelectTaxValue = (props: PropsInputSelectTaxValue) => {
  const { utils } = useUtils();
  const { taxValues } = useContext(TaxValueContext) as any;
  const [taxOptions, setTaxOptions] = useState<IdName[]>();

  useEffect(() => {
    setTaxOptions(
      (taxValues as TaxValueDto[])?.map(
        (t) =>
          new IdName(
            t.tax,
            utils.enum.translate(enumName.Tax, t.tax) +
              (t.value ? " %" + utils.number.toString(t.value, 0, 2) : "")
          )
      )
    );
  });

  return <InputSelectEnumBase {...props} options={taxOptions ?? []} showSpinner={!taxOptions} />;
};

export default InputSelectTaxValue;
