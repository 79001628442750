import { nameof } from "ts-simple-nameof";
import { LoginRequestDto } from "../../api/app/dtos/LoginRequestDto";
import { AppRoute } from "../../AppRoutes";
import Button from "../../shared/components/Button/Button";
import FormPassword from "../../shared/components/forms/FormPassword";
import FormText from "../../shared/components/forms/FormText";
import { useLoginLayout } from "./Layout/LoginLayoutContext";
import LoginLink from "./Layout/LoginLink";
import LoginRow from "./Layout/LoginRow";

const SignInForm = (props: { isLoading: boolean; onSubmit(): void }) => {
  const loginLayout = useLoginLayout();
  return (
    <>
      <LoginRow>
        <FormText
          label="Correo"
          propertyName={nameof<LoginRequestDto>((p) => p.email)}
          readOnly={props.isLoading}
        />
      </LoginRow>
      <LoginRow>
        <FormPassword
          label="Contraseña"
          propertyName={nameof<LoginRequestDto>((p) => p.password)}
          readOnly={props.isLoading}
          onKeyPressEnter={props.onSubmit}
        />
      </LoginRow>
      <LoginRow marginBottom={0}>
        <div style={{ display: "inline-block", width: "60%" }}>
          <LoginLink
            text="Olvidé mi contraseña"
            onClick={() => loginLayout.navigateTo(AppRoute.login.forgotPassword)}
          />
        </div>
        <div style={{ display: "inline-block", width: "40%", textAlign: "right" }}>
          <Button disabled={props.isLoading} text="Ingresar" onClick={props.onSubmit} />
        </div>
      </LoginRow>
    </>
  );
};

export default SignInForm;
