// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { UserLinkedAdminProviderDto } from "../dtos/UserLinkedAdminProviderDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { UserLinkedAdminProviderFiltersDto } from "../dtos/UserLinkedAdminProviderFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminUserLinkedAdminProvider {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchFilters: PagedSearchDto<UserLinkedAdminProviderFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<UserLinkedAdminProviderDto>> {
    return this.apiService.apiPost("/admin-provider/user-linked-admin-providers/paged", searchFilters, apiOptions);
  }

  create(
    userLinkedAdminProviderDto: UserLinkedAdminProviderDto,
    apiOptions?: ApiOptions
  ): Promise<UserLinkedAdminProviderDto> {
    return this.apiService.apiPost("/admin-provider/user-linked-admin-providers", userLinkedAdminProviderDto, apiOptions);
  }

  inactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/user-linked-admin-providers/inactivate/" + id, null, apiOptions);
  }

  reactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/user-linked-admin-providers/reactivate/" + id, null, apiOptions);
  }

}
