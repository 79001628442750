import { useIsMobile } from "../../shared/hooks/useIsMobile";
import { ModelStateArrayItemHelper } from "../../shared/modelState/ModelStateArray";
import { ThemeVariant } from "../../shared/ThemeVariant";
import { ISaleLineRetentionColumn } from "./SaleFormLines";
import SaleFormLineRow from "./SaleFormLines.Row";
import SaleLineRetentionColumn from "./SaleLineRetentionColumn";

const SaleLineRetentionsItem = (props: {
  arrayItemHelper: ModelStateArrayItemHelper;
  lineIndex: number;
  retentionsColumns: ISaleLineRetentionColumn[];
  showLabel: boolean;
  variant: ThemeVariant;
}) => {
  const isMobile = useIsMobile();
  return (
    <SaleFormLineRow key={props.arrayItemHelper.index}>
      <div style={{ width: "100%", display: "flex", gap: 15, flexDirection: "column" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: 5,
            flexDirection: isMobile ? "column" : "row",
          }}>
          {props.retentionsColumns.map((saleLineRetentionColumn, index) => (
            <SaleLineRetentionColumn
              key={index}
              arrayItemHelper={props.arrayItemHelper}
              lineIndex={props.lineIndex}
              saleLineRetentionColumn={saleLineRetentionColumn}
              showLabel={props.showLabel}
              variant={props.variant}
            />
          ))}
        </div>
      </div>
    </SaleFormLineRow>
  );
};

export default SaleLineRetentionsItem;
