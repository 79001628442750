// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { PointOfSaleDto } from "../../app/dtos/PointOfSaleDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { PointOfSaleFiltersDto } from "../../app/dtos/PointOfSaleFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiCrossShardPointOfSale {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<PointOfSaleFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<PointOfSaleDto>> {
    return this.apiService.apiPost("/cross-shard/point-of-sale/paged", searchDto, apiOptions);
  }

}
