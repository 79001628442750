import { useEffect, useState } from "react";
import { UserLinkedAccountDto } from "../../api/admin/dtos/UserLinkedAccountDto";
import Container from "../../shared/components/Layout/GContainer";
import RowDisplay from "../../shared/components/Layout/GRowDisplay";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminProviderAccountUserLinkedAccount } from "../../api/shared/hooks/useApiAdminProviders";
import { useModal } from "../../shared/hooks/useModal";
import useUtils from "../../shared/hooks/useUtils";
import { DateFormatType } from "../../shared/utils/localizedUtil/localizedUtilDates";

const AccountUserDetailsPopup = (props: { id: string }) => {
  const [modal] = useModal();
  const [apiAdminProviderAccountUserLinkedAccount] = useApiAdminProviderAccountUserLinkedAccount();
  const [userLinkedAccount, setUserLinkedAccount] = useState<UserLinkedAccountDto>();
  const { utils } = useUtils();
  useEffect(() => {
    const load = async () => {
      setUserLinkedAccount(await apiAdminProviderAccountUserLinkedAccount.getById(props.id));
      modal.setVisible("Detalles de usuario", ModalSize.small);
    };
    load();
  }, []);

  if (!userLinkedAccount) {
    return <></>;
  }

  return (
    <Container>
      <RowDisplay label="Correo">{userLinkedAccount.user.email}</RowDisplay>
      <RowDisplay label="Nombre">{userLinkedAccount.user.firstName}</RowDisplay>
      <RowDisplay label="Apellido">{userLinkedAccount.user.lastName}</RowDisplay>
      <RowDisplay label="Administrador">
        {userLinkedAccount.isAccountAdmin ? "Sí" : "No"}
      </RowDisplay>
      <RowDisplay label="Último login">
        {utils.date.toString(userLinkedAccount.accountLastLogin, DateFormatType.DateHourMinutes)}
      </RowDisplay>
    </Container>
  );
};

export default AccountUserDetailsPopup;
