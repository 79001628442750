import { nameof } from "ts-simple-nameof";
import { SaleLineRetentionDto } from "../../api/app/dtos/SaleLineRetentionDto";
import FormText from "../../shared/components/forms/FormText";
import { ISaleLineRetentionColumnRenderProps } from "./SaleFormLines";

const SaleLineRetentionColumnDetails = (props: ISaleLineRetentionColumnRenderProps) => {
  return (
    <FormText
      arrayItemHelper={props.arrayItemHelper}
      label={props.showLabel ? props.saleLineRetentionColumn.header : ""}
      variant={props.variant}
      propertyName={nameof<SaleLineRetentionDto>((p) => p.detail)}
    />
  );
};

export default SaleLineRetentionColumnDetails;
