import { Button as MuiButton } from "@mui/material";
import { CSSProperties } from "react";
import { AppThemeColor } from "../../../styles/color";
import { ButtonVariant } from "../../ButtonVariant";
import { ThemeSize } from "../../ThemeSize";
import { sizeFromTheme } from "../../utils/utilSize";
import Icon from "../Icon";
import Spinner from "../Spinner";
import Tooltip from "../Tooltip";

export interface PropsButton {
  className?: string;
  color?: AppThemeColor;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: any;
  iconColor?: AppThemeColor;
  loading?: boolean;
  size?: ThemeSize;
  styles?: CSSProperties;
  tooltip?: string;
  text?: string;
  variant?: ButtonVariant;
  onMouseDown?: (e: any) => void;
  onClick: (e: any) => void;
}

const Button = (props: PropsButton) => {
  const variant = props.variant ?? ButtonVariant.contained;
  return (
    <Tooltip text={props.tooltip}>
      <span>
        <MuiButton
          className={props.className}
          color={!props.color || props.color == AppThemeColor.primary ? "primary" : "secondary"}
          disabled={props.loading || props.disabled}
          fullWidth={props.fullWidth}
          size={props.size}
          style={props.styles}
          variant={variant}
          startIcon={
            <>
              {!props.loading && props.icon && (
                <Icon type={props.icon} size={sizeFromTheme(props.size)} color={props.iconColor} />
              )}
              {props.loading && <Spinner size={sizeFromTheme(props.size)} />}
            </>
          }
          onMouseDown={props.onMouseDown}
          onClick={(e) => props.onClick(e)}>
          {props.text}
        </MuiButton>
      </span>
    </Tooltip>
  );
};

export default Button;
