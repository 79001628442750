import { AppRoute } from "../../AppRoutes";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import Column from "../../shared/components/Layout/GColumn";
import Row from "../../shared/components/Layout/GRow";
import Page from "../../shared/components/Layout/Page";
import { useNavigation } from "../../shared/hooks/useNavigation";
import ReportPartialOperations from "./PartialOperations/ReportPartialOperations";
import ReportTaxes from "./Taxes/ReportTaxes";

const ReportDashboard = () => {
  const navigation = useNavigation();
  return (
    <Page
      title="Dashboard de reportes"
      titleButton={
        <ButtonSecondary
          text="Archivos exportados"
          onClick={() => {
            navigation.go(AppRoute.reports.requestedExport);
          }}
        />
      }>
      <Column>
        <ReportPartialOperations />
      </Column>
      <Row>
        <ReportTaxes />
      </Row>
    </Page>
  );
};

export default ReportDashboard;
