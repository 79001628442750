import { useContext } from "react";
import LayoutSideMenuArea from "../../shared/components/Layout/LayoutSideMenu.Area";
import { TypeIcon } from "../../shared/components/Icon";
import { AppInfoContext } from "../../shared/context/AppInfoProvider";
import { AppRoute } from "../../AppRoutes";
import Props from "../../shared/components/Props";

export interface PropsAdminMenu extends Props {
  isExpanded: boolean;
  onNavigate(url: string): void;
}

const AdminMenu = (props: PropsAdminMenu) => {
  const { appInfo } = useContext(AppInfoContext);

  const areas = [
    {
      name: "Inicio",
      icon: TypeIcon.home,
      navigateUrl: AppRoute.admin.base,
    },
    {
      name: "Cuentas",
      icon: TypeIcon.adminAccount,
      navigateUrl: AppRoute.admin.accounts,
    },
    {
      name: "Usuarios",
      icon: TypeIcon.user,
      navigateUrl: AppRoute.admin.users,
    },
    {
      name: "Plantillas",
      icon: TypeIcon.templates,
      navigateUrl: AppRoute.admin.templates.base,
    },
    {
      name: "Correo enviados",
      icon: TypeIcon.email,
      navigateUrl: AppRoute.admin.sentEmails,
    },
    {
      name: "eFactura",
      icon: TypeIcon.printer,
      navigateUrl: AppRoute.admin.eInvoices.base,
    },

    {
      name: "Logging",
      icon: TypeIcon.info,
      navigateUrl: AppRoute.admin.logViewer,
    },
  ];

  return (
    <>
      {areas.map((area) => (
        <LayoutSideMenuArea
          isExpanded={props.isExpanded}
          key={area.name}
          title={area.name}
          icon={area.icon}
          isSelected={appInfo?.currentArea === area.navigateUrl}
          onClick={() => {
            props.onNavigate(area.navigateUrl);
          }}
        />
      ))}
    </>
  );
};

export default AdminMenu;
