import { AppRoute } from "../../AppRoutes";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Row from "../../shared/components/Layout/GRow";
import Section from "../../shared/components/Layout/GSection";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import { useNavigation } from "../../shared/hooks/useNavigation";

const ConfigurationEInvoice = () => {
  const navigation = useNavigation();
  const { loginInfo } = useContextLoginInfo();

  return (
    <Row>
      <Section title="eFactura">
        <Row>
          <ComponentGroup wrap={true}>
            {loginInfo.authInfo.isSuperAdmin && (
              <ButtonSecondary
                icon={TypeIcon.config}
                text="Configuraciones (SuperAdmin)"
                onClick={() => navigation.go(AppRoute.configuration.eInvoice.general)}
              />
            )}
            <ButtonSecondary
              icon={TypeIcon.certificates}
              text="Certificados"
              onClick={() => navigation.go(AppRoute.configuration.eInvoice.vaultCertificates)}
            />
            <ButtonSecondary
              icon={TypeIcon.printer}
              text="CAEs"
              onClick={() => navigation.go(AppRoute.configuration.eInvoice.authorizations)}
            />
            <ButtonSecondary
              icon={TypeIcon.report}
              text="Reportes diarios"
              onClick={() => navigation.go(AppRoute.configuration.eInvoice.dailyReports)}
            />
          </ComponentGroup>
        </Row>
      </Section>
    </Row>
  );
};

export default ConfigurationEInvoice;
