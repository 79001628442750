import { ApiAdminProviderUser } from "../../api/admin/apis/ApiAdminProviderUser";
import { UserDto } from "../../api/admin/dtos/UserDto";
import { AppRoute } from "../../AppRoutes";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import ModalDialog from "../../shared/components/Modal/ModalDialog";
import { ModalService } from "../../shared/hooks/useModal";
import { Navigation } from "../../shared/hooks/useNavigation";
import { Notifier } from "../../shared/hooks/useNotifier";
import UserDetailsPopup from "./UserDetailsPopup";
import UserEditPopup from "./UserEditPopup";

export default function getUserListItemMenu(
  userDto: UserDto,
  apiAdminUser: ApiAdminProviderUser,
  modal: ModalService,
  notifier: Notifier,
  navigation: Navigation,
  onGridReload: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        modal.open(<UserDetailsPopup userId={userDto.id} />, onGridReload);
      },
    },
    {
      text: "Editar",
      icon: TypeIcon.edit,
      onClick: () => {
        modal.open(<UserEditPopup userId={userDto.id} />, onGridReload);
      },
    },
    {
      text: "Cuentas asociadas",
      icon: TypeIcon.adminAccount,
      onClick: () => {
        navigation.go(AppRoute.admin.userLinkedAccounts({ userId: userDto.id }));
      },
    },
    {
      text: "Reenviar correo de bienvenida",
      icon: TypeIcon.email,
      hide: !!userDto.lastLogin,
      onClick: () => {
        const resendWelcomeEmail = async () => {
          await apiAdminUser.resendWelcomeEmail(userDto.id);
          notifier.showSuccessFast("El correo de bienvenida se envió con éxito");
        };
        resendWelcomeEmail();
      },
    },
    {
      text: "Reenviar correo de reiniciar contraseña",
      icon: TypeIcon.email,
      hide: !userDto.lastLogin,
      onClick: () => {
        const sendForgotPasswordEmail = async () => {
          await apiAdminUser.sendForgotPasswordEmail(userDto.email);
          notifier.showSuccessFast("El correo de reiniciar contraseña se envió con éxito");
        };
        sendForgotPasswordEmail();
      },
    },
    {
      text: "Inactivar",
      icon: TypeIcon.delete,
      hide: userDto.inactive,
      onClick: () => {
        modal.open(<ModalDialog text="¿Seguro desea inactivar el usuario?" />, () => {
          const inactivate = async () => {
            await apiAdminUser.inactivate(userDto.id);
            notifier.showSuccessFast("El usuario se inactivó con éxito");
            onGridReload();
          };
          inactivate();
        });
      },
    },
    {
      text: "Reactivar",
      icon: TypeIcon.undo,
      hide: !userDto.inactive,
      onClick: () => {
        const reactivate = async () => {
          await apiAdminUser.reactivate(userDto.id);
          notifier.showSuccessFast("El usuario se reactivó con éxito");
          onGridReload();
        };
        reactivate();
      },
    },
  ];
}
