import { CSSProperties } from "react";
import { useAppTheme } from "../../AppThemeProvider";
import { LAYOUT_HINT_HEIGHT } from "../../shared/components/Layout/LayoutConstants";
import { useIsMobile } from "../../shared/hooks/useIsMobile";
import { AppThemeColor } from "../../styles/color";

const SaleFormLineRow = (props: { children: any; showAsModal?: boolean }) => {
  const isMobile = useIsMobile();
  const appTheme = useAppTheme();

  const desktopStyle: CSSProperties = {
    width: "100%",
    display: "flex",
    flexWrap: isMobile ? "wrap" : "nowrap",
    borderBottom: "1px solid " + appTheme.toHexColor(AppThemeColor.grayLight),
    paddingBottom: 8,
    marginBottom: LAYOUT_HINT_HEIGHT - 4,
    gap: 5,
  };

  const modalStyle: CSSProperties = {
    position: "fixed",
    backgroundColor: appTheme.toHexColor(AppThemeColor.white),
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 1000,
  };

  const style: CSSProperties = !props.showAsModal ? desktopStyle : modalStyle;
  return <div style={style}>{props.children}</div>;
};

export default SaleFormLineRow;
