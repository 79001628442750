import { ContactDto } from "../../api/app/dtos/ContactDto";
import { AppSize } from "../../shared/AppSize";
import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import { TypeIcon } from "../../shared/components/Icon";
import { useModal } from "../../shared/hooks/useModal";
import { AppThemeColor } from "../../styles/color";
import ContactCreatePopup from "./ContactCreatePopup";
import ContactDetailsPopup from "./ContactDetailsPopup";

const GridCellContact = (props: {
  contactName: string;
  contactId: string;
  taxPayerId?: string;
  onContactCreated?(contact: ContactDto): void;
}) => {
  const [modal] = useModal();

  return (
    <div
      className="clickable"
      onClick={() => {
        if (props.contactId) {
          modal.open(<ContactDetailsPopup id={props.contactId} />);
          return;
        }

        if (props.taxPayerId) {
          modal.open(<ContactCreatePopup initTaxPayerId={props.taxPayerId} />, (info?: any) => {
            if (props.onContactCreated) {
              props.onContactCreated(info);
            }
          });
        }
      }}>
      {props.contactId && (
        <ButtonIcon
          size={AppSize.small}
          icon={TypeIcon.openDetails}
          noPaddingY={true}
          onClick={() => {
            // Do nothing
          }}
        />
      )}
      {!props.contactId && props.taxPayerId && (
        <ButtonIcon
          color={AppThemeColor.primary}
          size={AppSize.small}
          icon={TypeIcon.plusCircle}
          noPaddingY={true}
          onClick={() => {
            // Do nothing
          }}
        />
      )}
      {props.contactName}
    </div>
  );
};

export default GridCellContact;
