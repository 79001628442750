import { nameof } from "ts-simple-nameof";
import { AccountConfigPreferencesDto } from "../../../api/app/dtos/AccountConfigPreferencesDto";
import { enumName } from "../../../api/shared/enums/_enumName";
import FormRadioBoolean from "../../../shared/components/forms/FormRadioBoolean";
import FormSelectCurrency from "../../../shared/components/forms/Select/FormSelectCurrency";
import FormSelectEnum from "../../../shared/components/forms/Select/FormSelectEnum";
import Column from "../../../shared/components/Layout/GColumn";
import useUtils from "../../../shared/hooks/useUtils";

const ConfigPreferencesSales = (props: { defaultPreferences: AccountConfigPreferencesDto }) => {
  const { utils } = useUtils();
  return (
    <>
      <Column md={3}>
        <FormRadioBoolean
          label="Definir fecha manualmente"
          showAsDefaultOption={true}
          labelAsDefault={utils.boolean.toYesOrNoWithAsDefaultLabel(
            props.defaultPreferences.salesDraftIsNotCurrentDate
          )}
          propertyName={nameof<AccountConfigPreferencesDto>((p) => p.salesDraftIsNotCurrentDate)}
        />
      </Column>

      <Column md={3}>
        <FormRadioBoolean
          label="Habilitar descuentos por línea"
          showAsDefaultOption={true}
          labelAsDefault={utils.boolean.toYesOrNoWithAsDefaultLabel(
            props.defaultPreferences.salesEnableLineDiscounts
          )}
          propertyName={nameof<AccountConfigPreferencesDto>((p) => p.salesEnableLineDiscounts)}
        />
      </Column>

      <Column md={3}>
        <FormRadioBoolean
          label="Habilitar recargos por línea"
          showAsDefaultOption={true}
          labelAsDefault={utils.boolean.toYesOrNoWithAsDefaultLabel(
            props.defaultPreferences.salesEnableLineSurcharges
          )}
          propertyName={nameof<AccountConfigPreferencesDto>((p) => p.salesEnableLineSurcharges)}
        />
      </Column>

      <Column md={3}>
        <FormRadioBoolean
          label="Habilitar retenciones por línea"
          showAsDefaultOption={true}
          labelAsDefault={utils.boolean.toYesOrNoWithAsDefaultLabel(
            props.defaultPreferences.salesEnableLineRetentions
          )}
          propertyName={nameof<AccountConfigPreferencesDto>((p) => p.salesEnableLineRetentions)}
        />
      </Column>

      <Column md={3}>
        <FormRadioBoolean
          label="Redondear automáticamente"
          showAsDefaultOption={true}
          labelAsDefault={utils.boolean.toYesOrNoWithAsDefaultLabel(
            props.defaultPreferences.salesRounding
          )}
          propertyName={nameof<AccountConfigPreferencesDto>((p) => p.salesRounding)}
        />
      </Column>

      <Column md={3}>
        <FormSelectEnum
          label="Tipo de pago"
          emptyText={utils.enum.translateWithAsDefaultLabel(
            enumName.PaymentType,
            props.defaultPreferences.salesPaymentType
          )}
          propertyName={nameof<AccountConfigPreferencesDto>((p) => p.salesPaymentType)}
          enumTypeName={enumName.PaymentType}
        />
      </Column>
      <Column md={3}>
        <FormSelectCurrency
          label="Moneda"
          emptyText={utils.enum.translateWithAsDefaultLabel(
            enumName.CurrencyName,
            props.defaultPreferences.salesDefaultCurrency
          )}
          propertyName={nameof<AccountConfigPreferencesDto>((p) => p.salesDefaultCurrency)}
        />
      </Column>
    </>
  );
};

export default ConfigPreferencesSales;
