import { AdminAccountDto } from "../../api/admin/dtos/AdminAccountDto";
import { AppRoute } from "../../AppRoutes";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import { AppInfoSiteType } from "../../shared/context/AppInfoProvider";
import { ModalService } from "../../shared/hooks/useModal";
import { Navigation } from "../../shared/hooks/useNavigation";
import AdminAccountDetailsPopup from "./AdminAccountDetailsPopup";
import AdminAccountEditPopup from "./AdminAccountEdit";

export default function getAdminAccountListItemMenu(
  adminAccountDto: AdminAccountDto,
  modal: ModalService,
  appInfoSiteType: AppInfoSiteType,
  navigation: Navigation,
  onGridReload: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => modal.open(<AdminAccountDetailsPopup id={adminAccountDto.id} />, onGridReload),
    },
    {
      text: "Editar",
      icon: TypeIcon.edit,
      onClick: () => modal.open(<AdminAccountEditPopup id={adminAccountDto.id} />, onGridReload),
    },
    {
      text: "Usuarios asociados",
      icon: TypeIcon.adminAccount,
      onClick: () => {
        if (appInfoSiteType == AppInfoSiteType.SuperAdmin) {
          navigation.go(
            AppRoute.adminProvider.userLinkedAccounts({ adminAccountId: adminAccountDto.id })
          );
        } else {
          navigation.go(
            AppRoute.adminProvider.userLinkedAccounts({
              adminAccountId: adminAccountDto.id,
            })
          );
        }
      },
    },
  ];
}
