// File generated by Gesta.Build.PostEvents
import { BaseCrossShardEntityDto } from "../../shared/dtos/BaseCrossShardEntityDto";
import { AccountBranchSimpleDto } from "./AccountBranchSimpleDto";

export class PointOfSaleDto extends BaseCrossShardEntityDto {
  accountBranchId: string;
  accountBranch: AccountBranchSimpleDto;
  name: string = "";
  isDefault: boolean = false;
}
