// File generated by Gesta.Build.PostEvents
import { AdminTemplateDto } from "./AdminTemplateDto";
import { PaperOrientation } from "../../shared/enums/PaperOrientation";
import { PaperSize } from "../../shared/enums/PaperSize";

export class AdminTemplatePdfDto extends AdminTemplateDto {
  displayPageNumber: boolean = false;
  orientation: PaperOrientation;
  isGrayScale: boolean = false;
  paperSize: PaperSize;
  paperCustomHeight: number | null = null;
  paperCustomWidth: number | null = null;
  footer: string = "";
  footerIgnoreFirstPage: boolean = false;
  header: string = "";
  headerIgnoreFirstPage: boolean = false;
  marginBottom: number | null = null;
  marginLeft: number | null = null;
  marginRight: number | null = null;
  marginTop: number | null = null;
}
