import { useEffect, useState } from "react";
import { AdminTemplateDesignDto } from "../../../api/admin/dtos/AdminTemplateDesignDto";
import { AdminTemplateDesignFieldDto } from "../../../api/admin/dtos/AdminTemplateDesignFieldDto";
import { TemplateDesignFieldType } from "../../../api/shared/enums/TemplateDesignFieldType";
import { ThemeVariant } from "../../ThemeVariant";
import { TypeIcon } from "../Icon";
import InfoText from "../InfoText";
import InputText from "../inputs/InputText";
import Row from "../Layout/GRow";
import ScrollableArea from "../Layout/ScrollableArea";
import TemplateEditorField from "./TemplateEditorField";
import "./TemplateEditorFields.scss";

export interface PropsTemplateEditorFields {
  canAddField: boolean;
  templateDesignDto: AdminTemplateDesignDto;
  onAddField(tag: string): void;
}

const TemplateEditorFields = (props: PropsTemplateEditorFields) => {
  const [clonedDesignDto, setClonedDesignDto] = useState(props.templateDesignDto);
  const [textSearch, setTextSearch] = useState("");
  const [filteredFields, setFilteredFields] = useState<AdminTemplateDesignFieldDto[]>([]);

  useEffect(() => {
    setClonedDesignDto(props.templateDesignDto);
    applyFilters(props.templateDesignDto, textSearch, setFilteredFields);
  }, [props.templateDesignDto]);

  return (
    <>
      <Row>
        <InfoText>Aquí se listan los valores disponibles para reemplazar en la plantilla</InfoText>
      </Row>
      <Row>
        <InputText
          value={textSearch}
          label="Buscar campo"
          endIcon={TypeIcon.search}
          variant={ThemeVariant.standard}
          onChange={(text) => {
            setTextSearch(text);
            if (clonedDesignDto) {
              applyFilters(clonedDesignDto, text, setFilteredFields);
            }
          }}
        />
      </Row>
      <Row paddingTop={4}>
        <ScrollableArea maxHeight={500}>
          {filteredFields.map((f) => (
            <TemplateEditorField
              canAddField={props.canAddField}
              key={f.name}
              field={f}
              onClick={props.onAddField}
              toggleExpand={(expanded: boolean) => {
                if (!clonedDesignDto) {
                  return;
                }
                const field = props.templateDesignDto.fields.find((c) => c.name === f.name);
                if (!field) {
                  return;
                }
                field.expanded = expanded;
                setClonedDesignDto({ ...clonedDesignDto });
                applyFilters({ ...clonedDesignDto }, textSearch, setFilteredFields);
              }}
            />
          ))}
        </ScrollableArea>
      </Row>
    </>
  );
};

export default TemplateEditorFields;

const applyFilters = (
  templateDesignDto: AdminTemplateDesignDto,
  textSearch: string,
  setFilterFields: (fields: AdminTemplateDesignFieldDto[]) => void
) => {
  if (!templateDesignDto) {
    return;
  }

  let fields = JSON.parse(
    JSON.stringify(templateDesignDto.fields)
  ) as AdminTemplateDesignFieldDto[];

  fields = fields.filter((c) => c.fieldType != TemplateDesignFieldType.EmailAttachment);

  if (textSearch) {
    fields.forEach((f) => {
      f.expanded = false;
      if (f.childFields) {
        f.childFields = f.childFields.filter(
          (c) =>
            c.name.toLocaleLowerCase().indexOf(textSearch.toLocaleLowerCase()) >= 0 ||
            c.code.toLowerCase().indexOf(textSearch.toLowerCase()) >= 0
        );
        f.expanded = f.childFields.length > 0;
      }
    });

    fields = fields.filter(
      (c) =>
        c.name.toLocaleLowerCase().indexOf(textSearch.toLocaleLowerCase()) >= 0 ||
        c.code.toLowerCase().indexOf(textSearch.toLowerCase()) >= 0 ||
        c.expanded
    );
  }
  setFilterFields(fields);
};
