import { UserLinkedAdminProviderDto } from "../../api/admin/dtos/UserLinkedAdminProviderDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import ModalDialog from "../../shared/components/Modal/ModalDialog";
import { ModalService } from "../../shared/hooks/useModal";

export default function getAdminProviderListItemMenu(
  userLinkedAdminProviderDto: UserLinkedAdminProviderDto,
  modal: ModalService,
  onGridReload: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () =>
        modal.open(<ModalDialog text="¿Seguro desea desvincular el usuario?" />, () => {
          alert("Desvincular usuario");
          onGridReload();
        }),
    },
  ];
}
