import { createContext, PropsWithChildren, ReactElement, useReducer } from "react";

export type ModalInfo = {
  status: ModalInfoStatus;
  content: ReactElement;
  preventCloseOnOutsideClick?: boolean;
  title?: string;
  size?: ModalSize;
  onCloseAsSuccess?(info?: any): void;
  onCloseAsCancel?(info?: any): void;
};

export enum ModalInfoStatus {
  loading,
  visible,
  replaced,
  closing,
}

export enum ModalSize {
  small,
  normal,
  big,
  dialog,
}

type ModalCollection = {
  modalList: ModalInfo[];
  isClosingAsSuccess: boolean;
  closingInfo?: any;
};

export enum ModalActionType {
  open,
  setVisible,
  closeAsSuccess,
  closeAsCancel,
  replace,
  confirmClose,
}

export type ModalAction =
  | {
      type: ModalActionType.open;
      content: ReactElement;
      onCloseAsSuccess?: (info?: any) => void;
      onCloseAsCancel?: (info?: any) => void;
    }
  | {
      type: ModalActionType.replace;
      content: ReactElement;
      onCloseAsSuccess?: (info?: any) => void;
      onCloseAsCancel?: (info?: any) => void;
    }
  | { type: ModalActionType.closeAsSuccess; info?: any }
  | { type: ModalActionType.closeAsCancel; info?: any }
  | { type: ModalActionType.confirmClose; info?: any }
  | {
      type: ModalActionType.setVisible;
      size: ModalSize;
      title: string;
      preventCloseOnOutsideClick?: boolean;
    };

const ModalInfoContext = createContext<{
  modalCollection: ModalCollection;
  modalDispatcher: React.Dispatch<ModalAction>;
}>({
  modalCollection: {
    modalList: [],
    isClosingAsSuccess: false,
  } as ModalCollection,
  modalDispatcher: () => null,
});

function newModalInfo(
  content: ReactElement,
  onCloseAsSuccess?: (info?: any) => void,
  onCloseAsCancel?: (info?: any) => void
): ModalInfo {
  return {
    content: content,
    status: ModalInfoStatus.loading,
    onCloseAsSuccess: onCloseAsSuccess,
    onCloseAsCancel: onCloseAsCancel,
  };
}

function modalInfoReducer(state: ModalCollection, action: ModalAction) {
  const currentModal = state.modalList[state.modalList.length - 1];
  switch (action.type) {
    case ModalActionType.open:
      return {
        ...state,
        modalList: [
          ...state.modalList,
          newModalInfo(action.content, action.onCloseAsSuccess, action.onCloseAsCancel),
        ],
      };
    case ModalActionType.closeAsSuccess:
      return {
        ...state,
        isClosingAsSuccess: true,
        closingInfo: action.info,
        modalList: state.modalList.map((m, index) =>
          index === state.modalList.length - 1 ? { ...m, status: ModalInfoStatus.closing } : m
        ),
      };
    case ModalActionType.closeAsCancel:
      return {
        ...state,
        isClosingAsSuccess: false,
        closingInfo: action.info,
        modalList: state.modalList.map((m, index) =>
          index === state.modalList.length - 1 ? { ...m, status: ModalInfoStatus.closing } : m
        ),
      };
    case ModalActionType.confirmClose:
      return {
        ...state,
        modalList: state.modalList.slice(0, -1),
      };
    case ModalActionType.replace:
      currentModal.status = ModalInfoStatus.replaced;
      return {
        ...state,
        modalList: [
          ...state.modalList,
          newModalInfo(action.content, action.onCloseAsSuccess, action.onCloseAsCancel),
        ],
      };
    case ModalActionType.setVisible:
      return {
        ...state,
        modalList: state.modalList.map((m) => {
          if (m.status !== ModalInfoStatus.loading) {
            return m;
          }

          return {
            ...m,
            status: ModalInfoStatus.visible,
            size: action.size,
            title: action.title,
            preventCloseOnOutsideClick: action.preventCloseOnOutsideClick,
          };
        }),
      };
  }
}

const ModalInfoProvider: React.FC<
  PropsWithChildren<{
    children: any;
  }>
> = ({ children }: any) => {
  const [modalCollection, modalDispatcher] = useReducer(modalInfoReducer, {
    modalList: [],
    isClosingAsSuccess: false,
  });

  return (
    <ModalInfoContext.Provider value={{ modalCollection, modalDispatcher }}>
      {children}
    </ModalInfoContext.Provider>
  );
};

export { ModalInfoContext, ModalInfoProvider };
