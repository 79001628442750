import { ApiAdminProviderUserTermsAccepted } from "../../api/admin/apis/ApiAdminProviderUserTermsAccepted";
import { UserTermsAcceptedDto } from "../../api/admin/dtos/UserTermsAcceptedDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import ModalDialog from "../../shared/components/Modal/ModalDialog";
import { ModalService } from "../../shared/hooks/useModal";
import { Notifier } from "../../shared/hooks/useNotifier";

export default function getUserTermsAcceptedListItemMenu(
  userTermsAcceptedDto: UserTermsAcceptedDto,
  apiAdminProviderUserTermsAccepted: ApiAdminProviderUserTermsAccepted,
  modal: ModalService,
  notifier: Notifier,
  reloadGrid: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Deshacer aceptación",
      icon: TypeIcon.undo,
      onClick: () => {
        modal.open(<ModalDialog text="¿Está seguro que desea deshacer la aceptación?" />, () => {
          const request = async () => {
            await apiAdminProviderUserTermsAccepted.acceptVersionUndo(userTermsAcceptedDto.id);
            notifier.showSuccessFast("La aceptación se revirtió con éxito");
            reloadGrid();
          };
          request();
        });
      },
    },
    {
      text: "Eliminar",
      icon: TypeIcon.delete,
      onClick: () => {
        modal.open(<ModalDialog text="¿Está seguro que desea eliminar la aceptación?" />, () => {
          const request = async () => {
            await apiAdminProviderUserTermsAccepted.acceptVersionUndo(userTermsAcceptedDto.id);
            notifier.showSuccessFast("La aceptación se eliminó con éxito");
            reloadGrid();
          };
          request();
        });
      },
    },
  ];
}
