import Icon from "../Icon";
import Row from "./GRow";

const RowTitle = (props: { children: any; icon?: any; paddingTop?: number }) => {
  return (
    <Row fontSize="1.5em" minHeightIgnore={true} paddingTop={props.paddingTop}>
      {props.icon && <Icon type={props.icon} />} {props.children}
    </Row>
  );
};

export default RowTitle;
