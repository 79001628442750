import { forwardRef } from "react";
import InputSelectEnum, { PropsInputSelectEnum } from "../..//inputs/select/InputSelectEnum";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormSelectEnum
  extends PropsFormInput<number | null>,
    Omit<PropsInputSelectEnum, "value" | "onChange"> {}

const FormSelectEnum = forwardRef((props: PropsFormSelectEnum, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputSelectEnum {...props} {...inputProps} />;
});

export default FormSelectEnum;
