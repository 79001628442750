// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiTest {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getNumberAllowAnonymous(
    apiOptions?: ApiOptions
  ): Promise<number> {
    return this.apiService.apiGet("/test/allow-anonymous-number", apiOptions);
  }

  getNumber(
    apiOptions?: ApiOptions
  ): Promise<number> {
    return this.apiService.apiGet("/test/number", apiOptions);
  }

  getBusinessException(
    apiOptions?: ApiOptions
  ): Promise<number> {
    return this.apiService.apiGet("/test/business-exception", apiOptions);
  }

  getBusinessExceptionLoggingError(
    apiOptions?: ApiOptions
  ): Promise<number> {
    return this.apiService.apiGet("/test/business-exception-logging-error", apiOptions);
  }

  getException(
    apiOptions?: ApiOptions
  ): Promise<number> {
    return this.apiService.apiGet("/test/exception", apiOptions);
  }

  downloadXml(
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiGet("/test/xml", apiOptions);
  }

}
