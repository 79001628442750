// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiCountry {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getAllAvailable(
    apiOptions?: ApiOptions
  ): Promise<number[]> {
    return this.apiService.apiGet("/countries/all-available", apiOptions);
  }

  getAll(
    apiOptions?: ApiOptions
  ): Promise<number[]> {
    return this.apiService.apiGet("/countries/all", apiOptions);
  }

  getDefaultLocale(
    country: number,
    apiOptions?: ApiOptions
  ): Promise<number> {
    return this.apiService.apiGet("/countries/default-locale/" + country, apiOptions);
  }

  getDefaultTimeZoneId(
    country: number,
    apiOptions?: ApiOptions
  ): Promise<string> {
    return this.apiService.apiGet("/countries/default-timezone-id/" + country, apiOptions);
  }

}
