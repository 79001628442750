// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { AdminTemplateDesignDto } from "../dtos/AdminTemplateDesignDto";
import { AdminTemplateDesignPdfTestDto } from "../dtos/AdminTemplateDesignPdfTestDto";
import { AdminTemplatePreviewHtmlResultDto } from "../dtos/AdminTemplatePreviewHtmlResultDto";
import { AdminTemplatePreviewPdfResultDto } from "../dtos/AdminTemplatePreviewPdfResultDto";
import { AdminTemplateDesignEmailTestDto } from "../dtos/AdminTemplateDesignEmailTestDto";

export class ApiAdminAccountPublicTemplateDesign {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getTypesByGroup(
    templateGroup: number,
    onlyAdmin: boolean,
    apiOptions?: ApiOptions
  ): Promise<number[]> {
    return this.apiService.apiGet("/admin/account-public/templates/design/types-by-group?" + 
      (templateGroup ? "&templateGroup=" + templateGroup : "") + 
      (onlyAdmin ? "&onlyAdmin=" + onlyAdmin : ""), apiOptions);
  }

  getTemplateDesign(
    templateType: number,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplateDesignDto> {
    return this.apiService.apiGet("/admin/account-public/templates/design/" + templateType, apiOptions);
  }

  pdfTestDownload(
    templatePdfDesignTestDto: AdminTemplateDesignPdfTestDto,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/admin/account-public/templates/design/pdf-test-download", templatePdfDesignTestDto, apiOptions);
  }

  previewPdfContent(
    templateDesignTestDto: AdminTemplateDesignPdfTestDto,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplatePreviewHtmlResultDto> {
    return this.apiService.apiPost("/admin/account-public/templates/design/preview-pdf-content", templateDesignTestDto, apiOptions);
  }

  previewPdfFile(
    templateDesignTestDto: AdminTemplateDesignPdfTestDto,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplatePreviewPdfResultDto> {
    return this.apiService.apiPost("/admin/account-public/templates/design/preview-pdf-file", templateDesignTestDto, apiOptions);
  }

  previewPdfFileAck(
    fileName: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin/account-public/templates/design/preview-pdf-file-ack?" + 
      (fileName ? "&fileName=" + fileName : ""), null, apiOptions);
  }

  previewEmail(
    templateDesignTestDto: AdminTemplateDesignEmailTestDto,
    apiOptions?: ApiOptions
  ): Promise<AdminTemplatePreviewHtmlResultDto> {
    return this.apiService.apiPost("/admin/account-public/templates/design/preview-html", templateDesignTestDto, apiOptions);
  }

  emailSendTest(
    templateDesignTestDto: AdminTemplateDesignEmailTestDto,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/admin/account-public/templates/design/email-send-test", templateDesignTestDto, apiOptions);
  }

}
