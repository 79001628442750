import { nameof } from "ts-simple-nameof";
import { ContactDto } from "../../../../api/app/dtos/ContactDto";
import { ContactFiltersDto } from "../../../../api/app/dtos/ContactFiltersDto";
import { ContactSimpleDto } from "../../../../api/app/dtos/ContactSimpleDto";
import { SuggestItemDto } from "../../../../api/shared/dtos/SuggestItemDto";
import { SuggestSearchDto } from "../../../../api/shared/dtos/SuggestSearchDto";
import { ContactFilterGroup } from "../../../../api/shared/enums/ContactFilterGroup";
import ContactCreatePopup from "../../../../app/Contact/ContactCreatePopup";
import ContactEditPopup from "../../../../app/Contact/ContactEditPopup";
import { useApiContact } from "../../../../api/shared/hooks/useApiApp";
import { useModal } from "../../../hooks/useModal";
import InputAutocompleteBase, { PropsInputAutocompleteBase } from "./InputAutocompleteBase";

export interface PropsInputAutocompleteContact
  extends Omit<PropsInputAutocompleteBase<ContactDto | null>, "onGetEntityById"> {
  contactFilterGroup: ContactFilterGroup;
}

const InputAutocompleteContact = (props: PropsInputAutocompleteContact) => {
  const [apiContact] = useApiContact();
  const [modal] = useModal();

  const apiRequest = (echoId: number, term: string, isInitialLoad: boolean) => {
    const searchDto = new SuggestSearchDto<ContactFiltersDto>();
    searchDto.echoId = echoId;
    searchDto.filters = new ContactFiltersDto();
    searchDto.filters.defaultTextSearch = term;
    searchDto.isInitialLoad = isInitialLoad;
    searchDto.orderBy = [{ columnName: nameof<ContactDto>((p) => p.name), asc: true }];
    const apiOptions = {
      preventSpinner: true,
    };

    if (props.contactFilterGroup == ContactFilterGroup.Customers) {
      return apiContact.suggestCustomers(searchDto, apiOptions);
    }
    if (props.contactFilterGroup == ContactFilterGroup.Providers) {
      return apiContact.suggestProviders(searchDto, apiOptions);
    }
    return apiContact.suggestAll(searchDto, apiOptions);
  };

  const openCreateContact = (searchTerm: string, createCallback: (id: string) => void) => {
    modal.open(<ContactCreatePopup initName={searchTerm} />, (contact: ContactDto) => {
      createCallback(contact.id);
    });
  };

  const openEditContact = (id: string, editCallback: (id: string) => void) => {
    modal.open(<ContactEditPopup id={id} />, (contact: ContactDto) => {
      editCallback(contact.id);
    });
  };

  return (
    <InputAutocompleteBase
      {...props}
      value={props.value}
      onCreateNewClick={openCreateContact}
      onEditClick={openEditContact}
      onGetEntityById={(id: string) => apiContact.getById(id, { preventSpinner: true })}
      onMapFromItemSuggest={(item: SuggestItemDto) => {
        const contactSimpleDto = new ContactSimpleDto();
        contactSimpleDto.id = item.id;
        contactSimpleDto.name = item.label;
        contactSimpleDto.companyName = item.label2;
        return contactSimpleDto;
      }}
      onServerSideSuggest={apiRequest}
    />
  );
};

export default InputAutocompleteContact;
