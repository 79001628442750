// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { RequestedExportDto } from "../dtos/RequestedExportDto";
import { SaleExportRequestDto } from "../dtos/SaleExportRequestDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiExportSale {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  default(
    exportRequest: SaleExportRequestDto,
    apiOptions?: ApiOptions
  ): Promise<RequestedExportDto> {
    return this.apiService.apiPost("/export/sale", exportRequest, apiOptions);
  }

}
