import { addMonths } from "date-fns";
import { CSSProperties, useEffect, useState } from "react";
import { ReportTaxesDto } from "../../../api/app/dtos/ReportTaxesDto";
import { ReportTaxesPeriodDto } from "../../../api/app/dtos/ReportTaxesPeriodDto";
import { DateOnlyDto } from "../../../api/shared/dtos/DateOnlyDto";
import { useApiReportTaxes } from "../../../api/shared/hooks/useApiApp";
import InputDateMonth from "../../../shared/components/inputs/InputDateMonth";
import InputSelectNumber from "../../../shared/components/inputs/select/InputSelectNumber";
import { useIsMobile } from "../../../shared/hooks/useIsMobile";
import { ThemeVariant } from "../../../shared/ThemeVariant";
import ReportCard from "../ReportCard";
import ReportTaxesPeriod from "./ReportTaxes.Period";

const ReportTaxes = () => {
  const [reportTaxes, setReportTaxes] = useState<ReportTaxesDto>();
  const [error, setError] = useState();
  const [apiTaxesReports] = useApiReportTaxes();
  const [startPeriod, setStartPeriod] = useState<DateOnlyDto | null>(() => {
    const startDate = addMonths(new Date(), -2);
    const dateOnly = new DateOnlyDto();
    dateOnly.year = startDate.getFullYear();
    dateOnly.month = startDate.getMonth() + 1;
    dateOnly.day = 1;
    return dateOnly;
  });
  const [hasPendingFilters, setHasPendingFilters] = useState(false);
  const [periods, setPeriods] = useState<number | null>(3);
  const isMobile = useIsMobile();

  const styleContainer: CSSProperties = {
    display: "flex",
    gap: 10,
    overflow: "hidden",
    flexWrap: "wrap",
  };

  const stylePeriod: CSSProperties = { flex: 1, minWidth: isMobile ? "100%" : "30%" };

  const loadReport = async () => {
    setReportTaxes(undefined);
    setError(undefined);
    setHasPendingFilters(false);

    const request = async () => {
      if (!startPeriod || !periods) {
        return;
      }

      try {
        setReportTaxes(
          await apiTaxesReports.getSimple(startPeriod.year, startPeriod.month, periods, {
            preventSpinner: true,
            preventNotifications: true,
          })
        );
      } catch (error) {
        setError((error as any)?.message ?? "Error al generar el reporte");
      }
    };
    request();
  };

  useEffect(() => {
    if (hasPendingFilters) {
      return;
    }
    loadReport();
  }, [startPeriod, periods, hasPendingFilters]);

  return (
    <ReportCard
      title="IVA a pagar"
      errorMessage={error}
      isLoading={!reportTaxes && !error}
      hasInvalidFilters={!startPeriod || !periods}
      hasPendingFilters={hasPendingFilters}
      onRefresh={loadReport}
      filtersComponent={
        <div style={{ width: isMobile ? "100%" : "30%", display: "flex", gap: 10 }}>
          <div style={{ flex: 2 }}>
            <InputDateMonth
              label="Desde"
              value={startPeriod}
              onChange={(value) => {
                setStartPeriod(value);
                setHasPendingFilters(true);
              }}
              variant={ThemeVariant.standard}
            />
          </div>
          <div style={{ flex: 1 }}>
            <InputSelectNumber
              label="Períodos"
              variant={ThemeVariant.standard}
              value={periods}
              optionValues={[1, 2, 3, 6, 9, 12]}
              onChange={(value) => {
                setPeriods(value ?? 3);
                setHasPendingFilters(true);
              }}
            />
          </div>
        </div>
      }>
      <div style={styleContainer}>
        {reportTaxes &&
          reportTaxes.periods.map((period: ReportTaxesPeriodDto, index: number) => (
            <ReportTaxesPeriod
              key={index}
              period={period}
              defaultCurrency={reportTaxes.defaultCurrency}
              style={stylePeriod}
            />
          ))}
      </div>
    </ReportCard>
  );
};

export default ReportTaxes;
