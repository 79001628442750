import { useEffect, useState } from "react";
import { Country } from "../../../../api/shared/enums/Country";
import { useApiAdminProviderTimeZone } from "../../../../api/shared/hooks/useApiAdminProviders";
import { useApiCountry } from "../../../../api/shared/hooks/useApiApp";
import { IdGuidName } from "../../../IdGuidName";
import InputSelectGuid, { PropsInputSelectGuid } from "./InputSelectGuid";

export interface PropsInputSelectTimeZone extends Omit<PropsInputSelectGuid, "options"> {
  country?: Country;
}

const InputSelectTimeZone = (props: PropsInputSelectTimeZone) => {
  const [apiAdminTimeZone] = useApiAdminProviderTimeZone();
  const [apiCountry] = useApiCountry();
  const [timeZones, setTimeZones] = useState<IdGuidName[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const load = async () => {
      const timeZonesResponse = await apiAdminTimeZone.getAll();
      setTimeZones(timeZonesResponse.map((t) => new IdGuidName(t.id, t.name)));
      setLoading(false);
    };
    load();
  }, []);

  useEffect(() => {
    setLoading(true);
    const load = async () => {
      if (!props.country) {
        setLoading(false);
        return;
      }
      const defaultTimeZoneId = await apiCountry.getDefaultTimeZoneId(props.country, {
        preventSpinner: true,
      });
      setLoading(false);
      props.onChange(defaultTimeZoneId);
    };
    load();
  }, [props.country]);

  return <InputSelectGuid {...props} options={timeZones} showSpinner={loading} />;
};

export default InputSelectTimeZone;
