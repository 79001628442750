import { useEffect, useState } from "react";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import { useApiEInvoiceEnvelopeSaleToCompany } from "../../api/shared/hooks/useApiApp";
import ButtonFileDownload from "../../shared/components/Button/ButtonFileDownload";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import InfoText from "../../shared/components/InfoText";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import TabGroup from "../../shared/components/Layout/TabGroup";
import TabView from "../../shared/components/Layout/TabView";
import { useNotifier } from "../../shared/hooks/useNotifier";
import EInvoiceEnvelopeDetailsSaleToCompany from "../EInvoice/Envelopes/EInvoiceEnvelopeDetails.SaleToCompany";
import SaleDetailsPopupEInvoiceEnvelopeSaleToCompanyProcessLog from "./SaleDetailsPopup.EInvoiceEInvoiceEnvelopeSaleToCompanyProcessLog";
import SaleDetailsPopupEInvoiceEInvoiceEnvelopeStatusResponseSaleToCompany from "./SaleDetailsPopup.EInvoiceEInvoiceEnvelopeStatusResponseSaleToCompany";

const TABS = {
  EVENTS: "EVENTS",
  ACK: "ACK",
};

const SaleDetailsPopupEInvoiceEnvelopeSaleToCompany = (props: { sale: SaleDto }) => {
  const [apiEInvoiceEnvelopeSaleToCompany] = useApiEInvoiceEnvelopeSaleToCompany();
  const [flagReloadLogs, setFlagReloadLogs] = useState(true);
  const notifier = useNotifier();

  const retrySend = async () => {
    await apiEInvoiceEnvelopeSaleToCompany.retrySend(props.sale.eInvoiceEnvelopeSaleToCompanyId);
    notifier.showSuccessFast("El sobre fue reenviado");
    setFlagReloadLogs(true);
  };

  useEffect(() => {
    setFlagReloadLogs(false);
  }, [flagReloadLogs]);

  if (!props.sale.eInvoiceEnvelopeSaleToCompany) {
    return <InfoText>No aplica el envío del sobre al receptor para este documento</InfoText>;
  }
  const tabs = [
    { title: "Eventos", value: TABS.EVENTS },
    { title: "Acuses de recibo", value: TABS.ACK },
  ];

  return (
    <>
      <EInvoiceEnvelopeDetailsSaleToCompany
        eInvoiceEnvelope={props.sale.eInvoiceEnvelopeSaleToCompany}
      />

      {!flagReloadLogs && (
        <TabGroup tabs={tabs} selectedTab={TABS.EVENTS}>
          <TabView value={TABS.EVENTS}>
            <SaleDetailsPopupEInvoiceEnvelopeSaleToCompanyProcessLog sale={props.sale} />
          </TabView>
          <TabView value={TABS.ACK}>
            <SaleDetailsPopupEInvoiceEInvoiceEnvelopeStatusResponseSaleToCompany
              sale={props.sale}
            />
          </TabView>
        </TabGroup>
      )}

      <RowButtons>
        <ButtonSecondary
          icon={TypeIcon.refresh}
          text="Reenviar sobre a receptor"
          onClick={retrySend}
        />
        <ButtonFileDownload
          fileUrl={props.sale.eInvoiceEnvelopeSaleToCompany.xmlUrl}
          text="Descargar sobre"
        />
        {props.sale.eInvoiceEnvelopeSaleToCompany.xmlRejectionUrl && (
          <ButtonFileDownload
            fileUrl={props.sale.eInvoiceEnvelopeSaleToCompany.xmlRejectionUrl}
            text="Descargar rechazo"
          />
        )}
      </RowButtons>
    </>
  );
};

export default SaleDetailsPopupEInvoiceEnvelopeSaleToCompany;
