import { forwardRef } from "react";
import { nameof } from "ts-simple-nameof";
import { UserSimpleDto } from "../../../../api/admin/dtos/UserSimpleDto";
import InputAutocompleteAdminUser, {
  PropsInputAutocompleteAdminUser,
} from "../../inputs/autocomplete/InputAutocompleteAdminUser";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormAutocompleteAdminUser
  extends PropsFormInput<UserSimpleDto>,
    Omit<
      PropsInputAutocompleteAdminUser,
      "value" | "onChange" | "onCreateNewClick" | "onGetEntity" | "onServerSideSuggest"
    > {}

const FormAutocompleteAdminUser = forwardRef((props: PropsFormAutocompleteAdminUser, ref?: any) => {
  const inputProps = useFormInputInit(props, ref, {
    updateIdLinkedProperty: true,
    inputTextValuePropertyName: nameof<UserSimpleDto>((p) => p.email),
  });
  return <InputAutocompleteAdminUser {...props} {...inputProps} />;
});

export default FormAutocompleteAdminUser;
