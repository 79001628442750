// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { RequestedExportDto } from "../dtos/RequestedExportDto";
import { PurchaseExportRequestDto } from "../dtos/PurchaseExportRequestDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiExportPurchase {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  default(
    exportRequest: PurchaseExportRequestDto,
    apiOptions?: ApiOptions
  ): Promise<RequestedExportDto> {
    return this.apiService.apiPost("/export/purchase", exportRequest, apiOptions);
  }

}
