import { useState } from "react";
import { Tax } from "../../../../api/shared/enums/Tax";
import { enumName } from "../../../../api/shared/enums/_enumName";
import { useContextLoginInfo } from "../../../hooks/useContextLoginInfo";
import useUtils from "../../../hooks/useUtils";
import InputSelectEnumBase, { PropsInputSelectEnumBase } from "./InputSelectEnumBase";

export type PropsInputSelectTax = Omit<PropsInputSelectEnumBase<Tax>, "options">;

const InputSelectTax = (props: PropsInputSelectTax) => {
  const { utils } = useUtils();
  const { loginInfo } = useContextLoginInfo();
  const [taxOptions] = useState(() => {
    return utils.enum.valuesToIdName(enumName.Tax, loginInfo.authInfo.accountConfig.taxes);
  });

  if (!taxOptions) {
    return <></>;
  }

  return <InputSelectEnumBase {...props} options={taxOptions ?? []} showSpinner={!taxOptions} />;
};

export default InputSelectTax;
