import { forwardRef } from "react";
import InputNumber, { PropsInputNumber } from "../inputs/InputNumber";
import { PropsFormInput, useFormInputInit } from "./_formInputProps";

export interface PropsFormNumber
  extends PropsFormInput<number | null>,
    Omit<PropsInputNumber, "value" | "onChange"> {}

const FormNumber = forwardRef((props: PropsFormNumber, ref?: any) => {
  const inputProps = useFormInputInit(props, ref, {
    updateOnPartialChange: true,
  });
  return <InputNumber {...props} {...inputProps} />;
});

export default FormNumber;
