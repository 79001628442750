import { arrayStringConcat } from "../../utils/utilArrays";

export interface IPropsModalFooter {
  children?: React.ReactNode | React.ReactNode[];
  className?: string | string[];
}

const ModalFooter = (props: IPropsModalFooter) => {
  if (!props.children) return <></>;

  return (
    <div className={arrayStringConcat("layout-modal-footer", props?.className)}>
      {props?.children instanceof Array ? (
        props.children.map((component: React.ReactNode, index: number) => (
          <div key={index}>{component}</div>
        ))
      ) : (
        <div>{props.children}</div>
      )}
    </div>
  );
};

export default ModalFooter;
