import { validate } from "../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

export function useLoginSignInRequestValidation() {
  const schema = new ValidationSchema();

  schema.email = validate().required("El correo es requerido");
  schema.password = validate().required("La contraseña es requerida");

  return schema;
}
