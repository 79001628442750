// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminProviderInfoDto } from "../dtos/AdminProviderInfoDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiPublicAdminProviderInfo {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getAdminProviderInfo(
    apiOptions?: ApiOptions
  ): Promise<AdminProviderInfoDto> {
    return this.apiService.apiGet("/public/admin-provider-info", apiOptions);
  }

}
