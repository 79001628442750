// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { PurchaseDto } from "../dtos/PurchaseDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { PurchaseFiltersDto } from "../dtos/PurchaseFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiPurchase {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    eInvoicePurchaseSearchDto: PagedSearchDto<PurchaseFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<PurchaseDto>> {
    return this.apiService.apiPost("/purchases/paged", eInvoicePurchaseSearchDto, apiOptions);
  }

  getPagedEInvoicePendingApprove(
    eInvoicePurchaseSearchDto: PagedSearchDto<PurchaseFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<PurchaseDto>> {
    return this.apiService.apiPost("/purchases/e-invoice-pending-approve", eInvoicePurchaseSearchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<PurchaseDto> {
    return this.apiService.apiGet("/purchases/" + id, apiOptions);
  }

  approve(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiGet("/purchases/approve/" + id, apiOptions);
  }

  reject(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiGet("/purchases/reject/" + id, apiOptions);
  }

}
