// File generated by Gesta.Build.PostEvents
import { UserDto } from "./UserDto";
import { AdminAccountSimpleDto } from "../../shared/dtos/AdminAccountSimpleDto";

export class UserCrudDto  {
  userId: string = "";
  user: UserDto;
  defaultAccountId: string;
  defaultAccount: AdminAccountSimpleDto;
  defaultAccountIsAdmin: boolean = false;
  isSuperAdmin: boolean = false;
  isOpenApi: boolean = false;
  openAuthKey: string = "";
}
