import { useEffect, useState } from "react";
import { UserCrudDto } from "../../api/admin/dtos/UserCrudDto";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminProviderUser } from "../../api/shared/hooks/useApiAdminProviders";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import UserForm from "./UserForm";
import { useUserCrudValidation } from "./Validations/useUserCrudValidation";

const UserEditPopup = (props: { userId: string }) => {
  const [userCrud, setUserCrud] = useState<UserCrudDto>();
  const [modal] = useModal();
  const [apiUser] = useApiAdminProviderUser();

  useEffect(() => {
    const load = async () => {
      setUserCrud(await apiUser.getToEditById(props.userId));
      modal.setVisible("Editar usuario", ModalSize.small);
    };
    load();
  }, []);

  if (!userCrud) {
    return null;
  }

  const save = async (userCrud: UserCrudDto) => {
    const created = await apiUser.edit(userCrud);
    modal.closeAsSuccess(created, "El usuario se editó con éxito");
  };

  return (
    <Container>
      <StateProvider model={userCrud} onSubmit={save} validationSchema={useUserCrudValidation()}>
        {(p) => <UserForm isEditing={true} onSave={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default UserEditPopup;
