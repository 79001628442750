import { useEffect } from "react";
import { ModelStateArrayItemHelper } from "../../modelState/ModelStateArray";
import { useContextModelState } from "../../modelState/useContextModelState";

export interface PropsFormInput<T> {
  arrayItemHelper?: ModelStateArrayItemHelper;
  propertyName: string;
  onBlur?(): void;
  onValueChange?(value: T): void;
}

const inputProps = (
  fullPropertyName: string,
  props: PropsFormInput<any>,
  ref?: any,
  options?: {
    updateOnPartialChange?: boolean;
    updateIdLinkedProperty?: boolean;
    inputTextValuePropertyName?: string;
    setTouchedOnChange?: boolean;
    delayTouchedOnBlur?: number;
  }
) => {
  const modelState = useContextModelState();
  let inputTextValue = undefined;
  if (options?.inputTextValuePropertyName) {
    inputTextValue = modelState.getValue(
      fullPropertyName + "." + options.inputTextValuePropertyName
    );
  }

  const onChange = (value: any) => {
    modelState.updateProperty(
      fullPropertyName,
      value,
      options?.setTouchedOnChange ?? false,
      options?.updateIdLinkedProperty
    );
    if (props.onValueChange) {
      props.onValueChange(value);
    }
  };

  const emptyHandler = () => {
    // empty
  };

  return {
    value: modelState.getValue(fullPropertyName),
    validation: modelState.getTouchedValidation(fullPropertyName),
    inputTextValue: inputTextValue,
    ref: ref,
    onBlur: () => {
      if (options?.delayTouchedOnBlur) {
        setTimeout(() => {
          modelState.fieldTrackSetTouched(fullPropertyName);
        }, options?.delayTouchedOnBlur);
      } else {
        modelState.fieldTrackSetTouched(fullPropertyName);
      }

      if (props.onBlur) {
        props.onBlur();
      }
    },
    onChange: !options?.updateOnPartialChange ? onChange : emptyHandler,
    onPartialChange: options?.updateOnPartialChange ? onChange : undefined,
  };
};

function getFullPropertyName(props: PropsFormInput<any>) {
  if (props.arrayItemHelper) {
    return (
      props.arrayItemHelper.collectionPropertyName +
      "[" +
      props.arrayItemHelper.index +
      "]." +
      props.propertyName
    );
  }
  return props.propertyName;
}

export function useFormInputInit(
  props: PropsFormInput<any>,
  ref: any,
  options?: {
    updateOnPartialChange?: boolean;
    updateIdLinkedProperty?: boolean;
    inputTextValuePropertyName?: string;
    setTouchedOnChange?: boolean;
    delayTouchedOnBlur?: number;
  }
) {
  const fullPropertyName = getFullPropertyName(props);
  const modelState = useContextModelState();
  const modelStateInputProps = inputProps(fullPropertyName, props, ref, options);

  useEffect(() => modelState.fieldTrackInit(fullPropertyName), []);

  return modelStateInputProps;
}
