import { nameof } from "ts-simple-nameof";
import { UserSimpleDto } from "../../../../api/admin/dtos/UserSimpleDto";
import { UserDto } from "../../../../api/admin/dtos/UserDto";
import { UserFiltersDto } from "../../../../api/admin/dtos/UserFiltersDto";
import { SuggestItemDto } from "../../../../api/shared/dtos/SuggestItemDto";
import { SuggestSearchDto } from "../../../../api/shared/dtos/SuggestSearchDto";
import InputAutocompleteBase, { PropsInputAutocompleteBase } from "./InputAutocompleteBase";
import { useApiAdminProviderUser } from "../../../../api/shared/hooks/useApiAdminProviders";

export type PropsInputAutocompleteAdminUser = Omit<
  PropsInputAutocompleteBase<UserDto | null>,
  "onGetEntityById"
>;

const InputAutocompleteAdminUser = (props: PropsInputAutocompleteAdminUser) => {
  const [apiAdminProviderUser] = useApiAdminProviderUser();

  const apiRequest = (echoId: number, term: string, isInitialLoad: boolean) => {
    const searchDto = new SuggestSearchDto<UserFiltersDto>();
    searchDto.echoId = echoId;
    searchDto.filters = new UserFiltersDto();
    searchDto.filters.defaultTextSearch = term;
    searchDto.isInitialLoad = isInitialLoad;
    searchDto.orderBy = [{ columnName: nameof<UserDto>((p) => p.email), asc: true }];
    const apiOptions = {
      preventSpinner: true,
    };

    return apiAdminProviderUser.suggest(searchDto, apiOptions);
  };

  return (
    <InputAutocompleteBase
      {...props}
      value={props.value}
      inputTextValue={props.inputTextValue ?? props.value?.email ?? ""}
      onGetEntityById={(id: string) => apiAdminProviderUser.getById(id, { preventSpinner: true })}
      onServerSideSuggest={apiRequest}
      onMapFromItemSuggest={(item: SuggestItemDto) => {
        const adminUserSimpleDto = new UserSimpleDto();
        adminUserSimpleDto.id = item.id;
        adminUserSimpleDto.email = item.label;
        return adminUserSimpleDto;
      }}
    />
  );
};

export default InputAutocompleteAdminUser;
