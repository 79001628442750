import { nameof } from "ts-simple-nameof";
import { AppNotificationDto } from "../../api/app/dtos/AppNotificationDto";
import { enumName } from "../../api/shared/enums/_enumName";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormText from "../../shared/components/forms/FormText";
import FormSelectEnum from "../../shared/components/forms/Select/FormSelectEnum";
import Column from "../../shared/components/Layout/GColumn";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";

const AppNotificationCreatePopupForm = (props: { onSubmit: () => void }) => {
  return (
    <>
      <Column>
        <FormSelectEnum
          label="Tipo"
          propertyName={nameof<AppNotificationDto>((p) => p.type)}
          enumTypeName={enumName.AppNotificationType}
        />
      </Column>
      <Column>
        <FormSelectEnum
          label="Grupo"
          propertyName={nameof<AppNotificationDto>((p) => p.group)}
          enumTypeName={enumName.AppNotificationGroup}
        />
      </Column>
      <Row>
        <FormText label="Título" propertyName={nameof<AppNotificationDto>((p) => p.title)} />
      </Row>
      <Row>
        <FormText
          label="Mensaje"
          multiline={true}
          propertyName={nameof<AppNotificationDto>((p) => p.body)}
        />
      </Row>
      <RowButtons>
        <ButtonAccept onClick={props.onSubmit} />
      </RowButtons>
    </>
  );
};

export default AppNotificationCreatePopupForm;
