import { useEffect, useState } from "react";
import { EInvoiceEnvelopeStatusResponseSaleDto } from "../../api/app/dtos/EInvoiceEnvelopeStatusResponseSaleDto";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import { useApiEInvoiceEnvelopeStatusResponseSale } from "../../api/shared/hooks/useApiApp";
import Row from "../../shared/components/Layout/GRow";
import Spinner from "../../shared/components/Spinner";
import { TextAlign } from "../../shared/TextAlign";
import SaleDetailsPopupEInvoiceEInvoiceEnvelopeStatusResponseBase from "./SaleDetailsPopup.EInvoiceEInvoiceEnvelopeStatusResponseBase";

const SaleDetailsPopupEInvoiceEInvoiceEnvelopeStatusResponseSale = (props: { sale: SaleDto }) => {
  const [apiEInvoiceEnvelopeStatusResponseSale] = useApiEInvoiceEnvelopeStatusResponseSale();
  const [items, setItems] = useState<EInvoiceEnvelopeStatusResponseSaleDto[]>();

  useEffect(() => {
    const request = async () => {
      setItems(
        await apiEInvoiceEnvelopeStatusResponseSale.getAllByEnvelopeId(
          props.sale.eInvoiceEnvelopeSaleId,
          { preventSpinner: true }
        )
      );
    };
    request();
  }, []);

  if (!items) {
    return (
      <Row align={TextAlign.center}>
        <Spinner />
      </Row>
    );
  }

  return <SaleDetailsPopupEInvoiceEInvoiceEnvelopeStatusResponseBase items={items} />;
};

export default SaleDetailsPopupEInvoiceEInvoiceEnvelopeStatusResponseSale;
