// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { PointOfSaleDto } from "../../app/dtos/PointOfSaleDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminProviderAccessPointOfSale {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getById(
    adminAccountId: string,
    id: string,
    apiOptions?: ApiOptions
  ): Promise<PointOfSaleDto> {
    return this.apiService.apiGet("/admin-provider/access/point-of-sales/" + adminAccountId + "/" + id, apiOptions);
  }

  create(
    adminAccountId: string,
    pointOfSaleDto: PointOfSaleDto,
    apiOptions?: ApiOptions
  ): Promise<PointOfSaleDto> {
    return this.apiService.apiPost("/admin-provider/access/point-of-sales/" + adminAccountId, pointOfSaleDto, apiOptions);
  }

  edit(
    adminAccountId: string,
    pointOfSaleDto: PointOfSaleDto,
    apiOptions?: ApiOptions
  ): Promise<PointOfSaleDto> {
    return this.apiService.apiPut("/admin-provider/access/point-of-sales/" + adminAccountId, pointOfSaleDto, apiOptions);
  }

  inactivate(
    adminAccountId: string,
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/access/point-of-sales/inactivate/" + adminAccountId + "/" + id, null, apiOptions);
  }

  reactivate(
    adminAccountId: string,
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/access/point-of-sales/reactivate/" + adminAccountId + "/" + id, null, apiOptions);
  }

}
