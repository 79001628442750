import { ThemeVariant } from "../../../ThemeVariant";
import FormSelectEnumMultiple, {
  PropsFormSelectEnumMultiple,
} from "../Select/FormSelectEnumMultiple";

const FilterSelectEnumMultiple = (props: PropsFormSelectEnumMultiple) => {
  return <FormSelectEnumMultiple {...props} variant={props.variant ?? ThemeVariant.standard} />;
};

export default FilterSelectEnumMultiple;
