import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { UserLinkedAdminProviderDto } from "../../api/admin/dtos/UserLinkedAdminProviderDto";
import { useApiAdminUserLinkedAdminProvider } from "../../api/shared/hooks/useApiAdmin";
import Grid from "../../shared/components/grid/Grid";
import { IGridItemTemplate } from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../shared/components/Layout/Page";
import { useModal } from "../../shared/hooks/useModal";
import getAdminProviderListItemMenu from "./AdminProviderLinkedUserList.ItemMenu";

const AdminProviderLinkedUserList = () => {
  const [modal] = useModal();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [apiAdminUserLinkedAdminProvider] = useApiAdminUserLinkedAdminProvider();

  const itemTemplates: IGridItemTemplate<UserLinkedAdminProviderDto>[] = [
    {
      header: "Proveedor",
      field: nameof<UserLinkedAdminProviderDto>((p) => p.adminProvider.name),
      width: 10,
    },
    {
      header: "Usuario",
      field: nameof<UserLinkedAdminProviderDto>((p) => p.user.email),
      width: 30,
    },
  ];

  return (
    <Page title="Usuarios vinculados a proveedores de servicio">
      <Grid
        itemTemplates={itemTemplates}
        reloadGrid={reloadGrid}
        onSearch={(search, options) => apiAdminUserLinkedAdminProvider.getPaged(search, options)}
        onDisplayItemMenu={(userLinkedAdminProviderDto: UserLinkedAdminProviderDto) =>
          getAdminProviderListItemMenu(userLinkedAdminProviderDto, modal, () =>
            setReloadGrid(new Date())
          )
        }
      />
    </Page>
  );
};

export default AdminProviderLinkedUserList;
