import { DateOnlyDto } from "../../../api/shared/dtos/DateOnlyDto";
import { enumName } from "../../../api/shared/enums/_enumName";
import PropsInputBase from "./PropsInputBase";
import InputSelectEnum from "./select/InputSelectEnum";
import InputSelectNumber from "./select/InputSelectNumber";

export interface PropsInputDateMonth extends PropsInputBase<DateOnlyDto | null> {
  minYear?: number;
  maxYear?: number;
}

const InputDateMonth = (props: PropsInputDateMonth) => {
  const minYear = props.minYear ?? new Date().getFullYear() - 10;
  const maxYear = props.maxYear ?? new Date().getFullYear();

  const years = [];
  for (let i = maxYear; i >= minYear; i--) {
    years.push(i);
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ flex: 2 }}>
        <InputSelectEnum
          label={props.label}
          enumTypeName={enumName.Month}
          value={props.value?.month ?? null}
          variant={props.variant}
          onChange={(value) => {
            if (!value) {
              props.onChange(null);
              return;
            }
            const date = new DateOnlyDto();
            date.year = props.value?.year ?? new Date().getFullYear();
            date.month = value;
            date.day = props.value?.day ?? 1;
            props.onChange(date);
          }}
        />
      </div>
      <div style={{ flex: 1 }}>
        <InputSelectNumber
          optionValues={years}
          value={props.value?.year ?? null}
          variant={props.variant}
          onChange={(value) => {
            if (!value) {
              props.onChange(null);
              return;
            }
            const date = new DateOnlyDto();
            date.year = value;
            date.month = props.value?.month ?? 1;
            date.day = props.value?.day ?? 1;
            props.onChange(date);
          }}
        />
      </div>
    </div>
  );
};

export default InputDateMonth;
