// File generated by Gesta.Build.PostEvents
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { Country } from "../../shared/enums/Country";
import { InvoiceRetentionType } from "../../shared/enums/InvoiceRetentionType";

export class AdminRetentionFiltersDto extends FiltersDto {
  country: Country;
  type: InvoiceRetentionType;
  code: string = "";
  details: string = "";
}
