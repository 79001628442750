import { forwardRef } from "react";
import InputSwitch, { PropsInputSwitch } from "../inputs/InputSwitch";
import { PropsFormInput, useFormInputInit } from "./_formInputProps";

export interface PropsFormSwitch
  extends PropsFormInput<boolean>,
    Omit<PropsInputSwitch, "value" | "onChange"> {}

const FormSwitch = forwardRef((props: PropsFormSwitch, ref?: any) => {
  const inputProps = useFormInputInit(props, ref);
  return <InputSwitch {...props} {...inputProps} />;
});

export default FormSwitch;
