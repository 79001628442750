import { AdminCertifiedProviderReportDto } from "../../../api/admin/dtos/AdminCertifiedProviderReportDto";
import { ContextMenuItem } from "../../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../../shared/components/Icon";
import { ModalService } from "../../../shared/hooks/useModal";
import { Navigation } from "../../../shared/hooks/useNavigation";
import AdminCertifiedProviderReportDetailsPopup from "./AdminCertifiedProviderReportDetailsPopup";

export default function getAdminCertifiedProviderReportListItemMenu(
  adminCertifiedProviderReportDto: AdminCertifiedProviderReportDto,
  modal: ModalService,
  navigation: Navigation
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        modal.open(
          <AdminCertifiedProviderReportDetailsPopup id={adminCertifiedProviderReportDto.id} />
        );
      },
    },
    {
      text: "Descargar XML",
      hide: !adminCertifiedProviderReportDto.xmlUrl,
      icon: TypeIcon.xml,
      onClick: () => {
        navigation.newTab(adminCertifiedProviderReportDto.xmlUrl);
      },
    },
  ];
}
