import { Tooltip as MuiTooltip } from "@mui/material";
import { ReactElement } from "react";

const Tooltip = (props: { text?: string | ReactElement; children: any }) => {
  return props.text ? (
    <MuiTooltip title={props.text} enterTouchDelay={0} placement="bottom-start">
      {props.children}
    </MuiTooltip>
  ) : (
    props.children
  );
};

export default Tooltip;
