import { useEffect } from "react";
import { nameof } from "ts-simple-nameof";
import { AppRoute } from "../../AppRoutes";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import ButtonLink from "../../shared/components/Button/ButtonLink";
import FormSwitch from "../../shared/components/forms/FormSwitch";
import { TypeIcon } from "../../shared/components/Icon";
import InfoText from "../../shared/components/InfoText";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useModal } from "../../shared/hooks/useModal";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import { SaleViewModel } from "./ViewModels/SaleViewModel";

const SaleFormLinesConfigPopup = (props: { saleViewModel: SaleViewModel }) => {
  const [modal] = useModal();
  const navigation = useNavigation();
  useEffect(() => {
    modal.setVisible("Configuración de líneas de venta", ModalSize.small);
  }, []);

  return (
    <Container>
      <StateProvider
        model={props.saleViewModel}
        onSubmit={(saleViewModel) => modal.closeAsSuccess(saleViewModel, "")}>
        {(p) => (
          <>
            <Row>
              <InfoText>
                Las configuraciones aplican sólo para esta factura. Para aplicar por defecto ir a
                <ButtonLink
                  icon={TypeIcon.config}
                  text="Preferencias"
                  onClick={() => navigation.newTab(AppRoute.configuration.preferences)}
                />
              </InfoText>
            </Row>
            <Row minHeightIgnore={true}>
              <FormSwitch
                label="Habilitar descuentos por línea"
                propertyName={nameof<SaleViewModel>((p) => p.enableLineDiscounts)}
              />
            </Row>
            <Row minHeightIgnore={true}>
              <FormSwitch
                label="Habilitar recargos por línea"
                propertyName={nameof<SaleViewModel>((p) => p.enableLineSurcharges)}
              />
            </Row>
            <Row minHeightIgnore={true}>
              <FormSwitch
                label="Habilitar retenciones"
                propertyName={nameof<SaleViewModel>((p) => p.enableLineRetentions)}
              />
            </Row>
            <RowButtons>
              <ButtonAccept onClick={p.handleSubmit} />
            </RowButtons>
          </>
        )}
      </StateProvider>
    </Container>
  );
};

export default SaleFormLinesConfigPopup;
