import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AdminTemplatePdfDto } from "../../../api/admin/dtos/AdminTemplatePdfDto";
import Page from "../../../shared/components/Layout/Page";
import { useApiAdminAccountPublicTemplatePdf } from "../../../api/shared/hooks/useApiAdmin";
import { useNotifier } from "../../../shared/hooks/useNotifier";
import { StateProvider } from "../../../shared/modelState/ModelStateContext";
import AdminTemplatePdfForm from "./AdminTemplatePdfForm";
import { useAdminTemplatePdfValidation } from "./Validations/useAdminTemplatePdfValidation";

type TParams = { id: string };

const AdminTemplatePdfEdit = () => {
  const { id } = useParams<TParams>();

  const [adminTemplatePdf, setAdminTemplatePdf] = useState<AdminTemplatePdfDto>();
  const [apiTemplatePdf] = useApiAdminAccountPublicTemplatePdf();
  const notifier = useNotifier();

  useEffect(() => {
    if (!id) {
      return;
    }

    const loadAdminTemplatePdf = async () => {
      setAdminTemplatePdf(await apiTemplatePdf.getById(id));
    };
    loadAdminTemplatePdf();
  }, [id]);

  const save = async (adminTemplatePdf: AdminTemplatePdfDto) => {
    await apiTemplatePdf.edit(adminTemplatePdf);
    notifier.showSuccess("La plantilla se editó con éxito");
  };

  if (!adminTemplatePdf) {
    return <></>;
  }

  return (
    <Page title="Editar plantilla de pdf">
      <StateProvider
        model={adminTemplatePdf}
        onSubmit={save}
        validationSchema={useAdminTemplatePdfValidation()}>
        {(p) => <AdminTemplatePdfForm onSave={p.handleSubmit} />}
      </StateProvider>
    </Page>
  );
};

export default AdminTemplatePdfEdit;
