import { CSSProperties } from "react";
import {
  BiAddToQueue,
  BiBell,
  BiBus,
  BiCertification,
  BiCheck,
  BiCheckCircle,
  BiChevronDown,
  BiChevronLeft,
  BiChevronRight,
  BiChevronUp,
  BiCloudDownload,
  BiCloudUpload,
  BiCode,
  BiCog,
  BiCoinStack,
  BiCommentX,
  BiCopy,
  BiCylinder,
  BiDetail,
  BiDollar,
  BiDotsVerticalRounded,
  BiDownArrowAlt,
  BiEdit,
  BiEnvelope,
  BiEraser,
  BiError,
  BiExit,
  BiFile,
  BiFilm,
  BiFilter,
  BiFont,
  BiGroup,
  BiHelpCircle,
  BiHide,
  BiHome,
  BiInfoCircle,
  BiLayerPlus,
  BiLeftArrowAlt,
  BiLineChart,
  BiLock,
  BiLogIn,
  BiMale,
  BiMap,
  BiMeh,
  BiMenu,
  BiMessageAdd,
  BiMinus,
  BiMinusCircle,
  BiPalette,
  BiPaperclip,
  BiPause,
  BiPhone,
  BiPlay,
  BiPlus,
  BiPlusCircle,
  BiPrinter,
  BiPurchaseTag,
  BiRecycle,
  BiRefresh,
  BiRightArrowAlt,
  BiSearch,
  BiServer,
  BiShoppingBag,
  BiShow,
  BiShuffle,
  BiSolidBookBookmark,
  BiSolidChip,
  BiSolidDiscount,
  BiSolidShip,
  BiSolidSpreadsheet,
  BiSolidTruck,
  BiSolidUserBadge,
  BiSort,
  BiStore,
  BiTable,
  BiTerminal,
  BiTime,
  BiTimeFive,
  BiTrash,
  BiUndo,
  BiUpArrowAlt,
  BiUpload,
  BiUser,
  BiUserCircle,
  BiWorld,
  BiX,
} from "react-icons/bi";
import { FaCheck, FaGoogle } from "react-icons/fa";
import { MdEdit, MdFilterAlt } from "react-icons/md";
import { VscFilePdf } from "react-icons/vsc";
import { useAppTheme } from "../../AppThemeProvider";
import { AppThemeColor } from "../../styles/color";
import { AppSize } from "../AppSize";
import Props from "./Props";
import Spinner from "./Spinner";
import Tooltip from "./Tooltip";

export const TypeIcon = {
  adminAccount: BiGroup,
  adminAccountLoginChange: BiRecycle,
  adminProvider: BiSolidChip,
  arrowRight: BiRightArrowAlt,
  arrowLeft: BiLeftArrowAlt,
  bell: BiBell,
  branch: BiStore,
  certificates: BiCertification,
  check: BiCheck,
  checkCircle: BiCheckCircle,
  checkBold: FaCheck,
  close: BiX,
  create: BiPlus,
  config: BiCog,
  contact: BiMale,
  copy: BiCopy,
  chevronDown: BiChevronDown,
  chevronLeft: BiChevronLeft,
  chevronRight: BiChevronRight,
  chevronUp: BiChevronUp,
  css: BiFilm,
  delete: BiTrash,
  deleteCross: BiX,
  details: BiDetail,
  dollar: BiDollar,
  download: BiCloudDownload,
  draft: BiEraser,
  emptyProductList: BiMessageAdd,
  edit: MdEdit,
  email: BiEnvelope,
  eraser: BiEraser,
  error: BiCommentX,
  filter: BiFilter,
  filterApplied: MdFilterAlt,
  google: FaGoogle,
  gridDetails: BiAddToQueue,
  help: BiHelpCircle,
  hide: BiHide,
  home: BiHome,
  html: BiCode,
  hyphen: BiMinus,
  eInvoice: BiPrinter,
  eInvoiceProcessAccepted: BiCheck,
  eInvoiceProcessEnvelopeSent: BiEnvelope,
  eInvoiceProcessEnvelopeCreated: BiEnvelope,
  eInvoiceProcessError: BiError,
  eInvoiceProcessPending: BiTime,
  eInvoiceProcessPendingVerification: BiCloudUpload,
  eInvoiceProcessXmlCreated: BiEnvelope,
  entranceTicket: BiSolidSpreadsheet,
  exportToExcel: BiCloudDownload,
  file: BiFile,
  info: BiInfoCircle,
  legal: BiPaperclip,
  lineChart: BiLineChart,
  locale: BiWorld,
  lock: BiLock,
  login: BiLogIn,
  logout: BiExit,
  mainMenu: BiMenu,
  marker: BiMap,
  menu: BiDotsVerticalRounded,
  minus: BiMinus,
  minusCircle: BiMinusCircle,
  noData: BiMeh,
  openDetails: BiDetail,
  pageBreak: BiLayerPlus,
  pause: BiPause,
  pending: BiTimeFive,
  printer: BiPrinter,
  pdf: VscFilePdf,
  phone: BiPhone,
  pointOfSale: BiSolidBookBookmark,
  play: BiPlay,
  plus: BiPlus,
  plusCircle: BiPlusCircle,
  products: BiPurchaseTag,
  purchase: BiShoppingBag,
  refresh: BiRefresh,
  report: BiLineChart,
  sales: BiDollar,
  salesAdjust: BiEdit,
  salesCancellation: BiUndo,
  salesExport: BiSolidShip,
  salesDeliveryNote: BiSolidTruck,
  salesInBehalf: BiSolidUserBadge,
  salesReceipt: BiSolidDiscount,
  shard: BiCylinder,
  search: BiSearch,
  serviceBus: BiBus,
  sort: BiSort,
  sortAsc: BiDownArrowAlt,
  sortDsc: BiUpArrowAlt,
  show: BiShow,
  stock: BiCoinStack,
  store: BiStore,
  styles: BiPalette,
  superAdmin: BiUser,
  server: BiServer,
  ship: BiSolidShip,
  switchPointOfSale: BiShuffle,
  table: BiTable,
  templates: BiTerminal,
  text: BiFont,
  truck: BiSolidTruck,
  uploadFile: BiUpload,
  undo: BiUndo,
  user: BiUserCircle,
  warning: BiError,
  whatsApp: BiPhone,
  xml: BiFile,
};

export interface PropsIcon extends Props {
  color?: AppThemeColor;
  disabled?: boolean;
  isLoading?: boolean;
  size?: AppSize;
  tooltip?: string;
  type: any;
  onClick?(evt: any): void;
}

const Icon = (props: PropsIcon) => {
  const size = props.size ?? AppSize.medium;
  const IconTag = props.type;
  const appTheme = useAppTheme();

  const defaultStyles: CSSProperties = {
    margin: "auto",
    width: size,
    height: size,
    color: appTheme.toHexColor(props.color),
    display: "inline-block",
    flex: 0,
  };

  if (props.disabled) {
    defaultStyles.fill = "gray";
  }

  const styles = { ...defaultStyles, ...props.style };

  const content = (
    <>
      {props.isLoading && <Spinner />}
      {!props.isLoading && (
        <div
          id={props.id}
          className={props.className}
          style={styles}
          onClick={(e) => {
            if (!props.disabled && props.onClick) {
              e.stopPropagation();
              props.onClick(e);
            }
          }}>
          <IconTag
            style={{
              width: size,
              height: size,
            }}
          />
        </div>
      )}
    </>
  );

  return props.tooltip ? (
    <Tooltip text={props.tooltip}>
      <span>{content}</span>
    </Tooltip>
  ) : (
    content
  );
};

export default Icon;
