import { useAppTheme } from "../../../AppThemeProvider";
import Icon, { TypeIcon } from "../../../shared/components/Icon";
import { useIsMobile } from "../../../shared/hooks/useIsMobile";
import { AppThemeColor } from "../../../styles/color";
import "./Login.scss";
import LoginRow from "./LoginRow";

const LoginRowError = (props: { message?: string }) => {
  const isMobile = useIsMobile();
  const appTheme = useAppTheme();
  return (
    <LoginRow
      className="login-layout-row-error"
      fontSize={14}
      marginBottom={0}
      style={{ color: appTheme.toHexColor(AppThemeColor.alert) }}>
      {props.message && (
        <>
          <Icon
            type={TypeIcon.error}
            color={isMobile ? AppThemeColor.black : AppThemeColor.alert}
            style={{ margin: 0, marginRight: 15 }}
          />
          <div className="login-error-message" title={props.message}>
            {props.message}
          </div>
        </>
      )}
    </LoginRow>
  );
};

export default LoginRowError;
