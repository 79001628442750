import { AppRoute } from "../../AppRoutes";
import Button from "../../shared/components/Button/Button";
import Icon, { TypeIcon } from "../../shared/components/Icon";
import { AppSize } from "../../shared/AppSize";
import { AppThemeColor } from "../../styles/color";
import LoginCard from "./Layout/LoginCard";
import { useLoginLayout } from "./Layout/LoginLayoutContext";
import LoginLink from "./Layout/LoginLink";
import LoginRow from "./Layout/LoginRow";
import LoginRowBottom from "./Layout/LoginRowBottom";
import LoginTitle from "./Layout/LoginTitle";

const ForgotPasswordEmailSent = () => {
  const loginLayout = useLoginLayout();

  return (
    <LoginCard>
      <LoginTitle>Correo enviado!</LoginTitle>
      <LoginRow style={{ textAlign: "center" }} marginBottom={3}>
        <Icon type={TypeIcon.check} size={AppSize.biggest} color={AppThemeColor.success} />
      </LoginRow>
      <LoginRow marginBottom={3}>
        En unos segundos revisá tu casilla de correo donde encontrás las indicaciones para
        restablecer tu contraseña.
      </LoginRow>
      <LoginRow>
        <Button
          fullWidth={true}
          text="Volver al login"
          onClick={() => {
            loginLayout.navigateTo(AppRoute.login.base);
          }}
        />
      </LoginRow>
      <LoginRowBottom>
        <div>
          Si tenés dudas adicionales,
          <LoginLink
            text="te contactamos"
            onClick={() => {
              loginLayout.navigateTo(AppRoute.login.register.base);
            }}
          />
        </div>
      </LoginRowBottom>
    </LoginCard>
  );
};

export default ForgotPasswordEmailSent;
