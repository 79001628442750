import { useEffect, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { FiltersDto } from "../../../api/shared/dtos/FiltersDto";
import { useContextModelState } from "../../modelState/useContextModelState";
import { TextAlign } from "../../TextAlign";
import { ThemeSize } from "../../ThemeSize";
import { deepEqual } from "../../utils/utilObject";
import Button from "../Button/Button";
import FilterText from "../forms/Filters/FilterText";
import { TypeIcon } from "../Icon";
import Column from "../Layout/GColumn";
import Row from "../Layout/GRow";
import GridFiltersItem from "./GridFilters.Item";
import { useContextGrid } from "./useContextGrid";

const GridFiltersForm = (props: { onApplyFilters: () => void }) => {
  const grid = useContextGrid();
  const filtersState = useContextModelState();
  const equals = deepEqual(grid.gridState.searchDto.filters, filtersState.model);
  const filters = filtersState.model as FiltersDto;
  const [displayDefaultSearch] = useState(!!filters.defaultTextSearch);

  useEffect(() => {
    if (equals && grid.gridState.filtersPendingApply && !grid.gridState.isLoading) {
      grid.filtersPendingApply(false);
    } else if (!equals && !grid.gridState.filtersPendingApply) {
      grid.filtersPendingApply(true);
    }
  });

  return (
    <>
      {displayDefaultSearch && (
        <Column md={2}>
          <FilterText
            label="Búsqueda genérica"
            propertyName={nameof<FiltersDto>((p) => p.defaultTextSearch)}
          />
        </Column>
      )}
      {grid.gridState.columnTemplates.map((column, key) => {
        if (column.filter) {
          return (
            <Column md={2} key={key}>
              <GridFiltersItem filter={column.filter} label={column.headerAlt ?? column.header} />
            </Column>
          );
        }
      })}
      <Row align={TextAlign.center} minHeightIgnore={true}>
        <Button
          disabled={equals}
          tooltip={
            equals
              ? "Seleccioná algún filtro para habilitar la búsqueda"
              : "Aplicar filtros de búsqueda"
          }
          size={ThemeSize.small}
          icon={TypeIcon.search}
          text="Buscar"
          onClick={props.onApplyFilters}
        />
      </Row>
    </>
  );
};

export default GridFiltersForm;
