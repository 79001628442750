import { useContext } from "react";
import "./Notification.scss";
import NotificationPop from "./NotificationPop";
import { NotificationsContextGet } from "./NotificationsProvider";

const NotificationArea = () => {
  const { notifications } = useContext(NotificationsContextGet);
  return (
    <div id="notification-area">
      {notifications.map((notification) => {
        return <NotificationPop key={notification.id} notification={notification} />;
      })}
    </div>
  );
};

export default NotificationArea;
