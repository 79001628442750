import { nameof } from "ts-simple-nameof";
import { AdminProviderWithSecureInfoDto } from "../../api/admin/dtos/AdminProviderWithSecureInfoDto";
import { EmailProviderType } from "../../api/shared/enums/EmailProviderType";
import { enumName } from "../../api/shared/enums/_enumName";
import FormText from "../../shared/components/forms/FormText";
import FormSelectEnum from "../../shared/components/forms/Select/FormSelectEnum";
import InfoText from "../../shared/components/InfoText";
import Column from "../../shared/components/Layout/GColumn";
import Row from "../../shared/components/Layout/GRow";
import Section from "../../shared/components/Layout/GSection";
import { AppInfoSiteType } from "../../shared/context/AppInfoProvider";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { useContextModelState } from "../../shared/modelState/useContextModelState";
import { AppThemeColor } from "../../styles/color";

const AdminProviderFormSecureValues = () => {
  const modelState = useContextModelState();
  const adminProvider = modelState.model as AdminProviderWithSecureInfoDto;
  const { appInfo } = useContextAppInfo();

  return (
    <>
      <Row>
        <FormText
          label="Origines permitidos"
          hint="Lista separada por punto y coma de los orígenes permitidos para el proveedor"
          propertyName={nameof<AdminProviderWithSecureInfoDto>((p) => p.allowedOrigin)}
        />
      </Row>
      <Column md={6}>
        <Section title="Configuración de correos salientes" background={AppThemeColor.grayLight}>
          <Column>
            <FormSelectEnum
              label="Tipo de proveedor de correo"
              propertyName={nameof<AdminProviderWithSecureInfoDto>(
                (p) => p.providerSecureSettings.emailProviderType
              )}
              enumTypeName={enumName.EmailProviderType}
            />
          </Column>
          {adminProvider.providerSecureSettings.emailProviderType ===
            EmailProviderType.SendGrid && (
            <Row>
              <FormText
                label="SendGrid API Key"
                propertyName={nameof<AdminProviderWithSecureInfoDto>(
                  (p) => p.providerSecureSettings.emailSendGridApiKey
                )}
              />
            </Row>
          )}
          {adminProvider.providerSecureSettings.emailProviderType === EmailProviderType.Smtp && (
            <Row>
              {appInfo?.siteType == AppInfoSiteType.SuperAdmin && (
                <InfoText>
                  Para configurar el servidor SMTP, se debe ingresar por la grilla de proveedores y
                  seleccionar 'Configurar SMTP' desde el menú contextual
                </InfoText>
              )}
              {appInfo?.siteType == AppInfoSiteType.AdminProvider && (
                <InfoText>
                  Para configurar el servidor SMTP, se debe ingresar dese la página de inicio en
                  "Configurar SMTP"
                </InfoText>
              )}
            </Row>
          )}

          <Column md={6}>
            <FormText
              label="Remitente por defecto (Correo)"
              propertyName={nameof<AdminProviderWithSecureInfoDto>(
                (p) => p.providerSecureSettings.emailDefaultFromEmail
              )}
            />
          </Column>
          <Column md={6}>
            <FormText
              label="Remitente por defecto (Nombre)"
              propertyName={nameof<AdminProviderWithSecureInfoDto>(
                (p) => p.providerSecureSettings.emailDefaultFromUser
              )}
            />
          </Column>
        </Section>
      </Column>
      <Column md={6}>
        <Section title="Configuración de GMail OAuth" background={AppThemeColor.grayLight}>
          <Row>
            <InfoText>
              Configuración para permitir a los clientes de correos de cada cuenta utilizar GMAIL
              como proveedor para el intercambio de comprobantes electrónicos.
            </InfoText>
          </Row>
          <Row>
            <FormText
              label="GMail OAuth Client Id"
              propertyName={nameof<AdminProviderWithSecureInfoDto>(
                (p) => p.providerSecureSettings.gMailOAuthClientId
              )}
            />
          </Row>
          <Row>
            <FormText
              label="GMail OAuth Client Secret"
              propertyName={nameof<AdminProviderWithSecureInfoDto>(
                (p) => p.providerSecureSettings.gMailOAuthClientSecret
              )}
            />
          </Row>
        </Section>
      </Column>
    </>
  );
};

export default AdminProviderFormSecureValues;
