export class ParamSetItemValues {
  isTouched?: boolean;
  incrementTryFieldSubmit?: boolean;
}

export default abstract class ValidationFieldsTrackBase {
  abstract getValue(propertyFullName: string): ValidationFieldsTrackBase | undefined;

  protected getRemainingPropertyPath(propertyPath: string[]): string {
    if (propertyPath.length <= 1) {
      return "";
    }
    return propertyPath.slice(1).join(".");
  }

  abstract incrementTrySubmitAll(): void;

  init(propertyFullName: string): void {
    this.setValue(propertyFullName, { isTouched: false });
  }

  setNotTouched(propertyFullName: string) {
    this.setValue(propertyFullName, { isTouched: false });
  }

  setTouched(propertyFullName: string) {
    this.setValue(propertyFullName, { isTouched: true });
  }

  setChildrenAsTouched(propertyFullName: string) {
    this.getValue(propertyFullName)?.setChildrenValue({ isTouched: true });
  }

  abstract setValue(propertyFullName: string, params: ParamSetItemValues): void;
  abstract setChildrenValue(params: ParamSetItemValues): void;

  abstract removeArrayItem(
    propertyFullName: string,
    startArrayIndex: number,
    deleteCount?: number
  ): void;
}
