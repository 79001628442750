// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { dateToIsoString } from "../../shared/utils/apiUtilsDate";
import { AdminEInvoiceDailyReportStartProcessResultDto } from "../dtos/AdminEInvoiceDailyReportStartProcessResultDto";

export class ApiAdminProviderEInvoiceDailyReportProcess {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  cancelDailyReport(
    accountId: string,
    dailyReportId: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/e-invoice-daily-report-process/cancel?" + 
      (accountId ? "&accountId=" + accountId : "") + 
      (dailyReportId ? "&dailyReportId=" + dailyReportId : ""), null, apiOptions);
  }

  regenerateDailyReport(
    accountId: string,
    dailyReportId: string,
    apiOptions?: ApiOptions
  ): Promise<string> {
    return this.apiService.apiPut("/admin-provider/e-invoice-daily-report-process/regenerate?" + 
      (accountId ? "&accountId=" + accountId : "") + 
      (dailyReportId ? "&dailyReportId=" + dailyReportId : ""), null, apiOptions);
  }

  startProcess(
    accountId: string,
    date: Date | null,
    isRegenerate: boolean,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/admin-provider/e-invoice-daily-report-process/start-for-account?" + 
      (accountId ? "&accountId=" + accountId : "") + 
      (date ? "&date=" + dateToIsoString(date) : "") + 
      (isRegenerate ? "&isRegenerate=" + isRegenerate : ""), null, apiOptions);
  }

  startForAll(
    date: Date | null,
    apiOptions?: ApiOptions
  ): Promise<AdminEInvoiceDailyReportStartProcessResultDto> {
    return this.apiService.apiPost("/admin-provider/e-invoice-daily-report-process/start-for-all?" + 
      (date ? "&date=" + dateToIsoString(date) : ""), null, apiOptions);
  }

}
