import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import { enumName } from "../../api/shared/enums/_enumName";
import Row from "../../shared/components/Layout/GRow";
import useUtils from "../../shared/hooks/useUtils";
import PurchaseDetailsColumn from "./PurchaseDetails.Column";

const PurchaseDetailsReceiver = (props: { purchase: PurchaseDto }) => {
  const { utils } = useUtils();
  const purchase = props.purchase;

  return (
    <>
      {!purchase.receiver && <Row>No hay información del receptor</Row>}
      {purchase.receiver && (
        <>
          <PurchaseDetailsColumn label="Tipo documento">
            {utils.enum.translate(enumName.IdCardType, purchase.receiver.idCardType)}
          </PurchaseDetailsColumn>
          <PurchaseDetailsColumn label="Número">
            {purchase.receiver.idCardNumber}
          </PurchaseDetailsColumn>
          <PurchaseDetailsColumn label="País">
            {utils.enum.translateCountry(purchase.receiver.address.country)}
          </PurchaseDetailsColumn>
          <PurchaseDetailsColumn label="Nombre o denominación">
            {purchase.receiver.companyName}
          </PurchaseDetailsColumn>
          <PurchaseDetailsColumn label="Dirección">
            {purchase.receiver.address.addressLine}
          </PurchaseDetailsColumn>
          <PurchaseDetailsColumn label="Ciudad">
            {purchase.receiver.address.city}
          </PurchaseDetailsColumn>
          <PurchaseDetailsColumn label="Departamento">
            {utils.enum.translate(enumName.State, purchase.receiver.address.state)}
          </PurchaseDetailsColumn>
          <PurchaseDetailsColumn label="Código postal">
            {purchase.receiver.address.zipCode}
          </PurchaseDetailsColumn>
          <PurchaseDetailsColumn label="Info adicional">
            {purchase.receiver.extraInfo}
          </PurchaseDetailsColumn>
          <PurchaseDetailsColumn label="Lugar de entrega">
            {purchase.receiver.address.addressLine2}
          </PurchaseDetailsColumn>
          <PurchaseDetailsColumn label="Identificación de compra">---</PurchaseDetailsColumn>
        </>
      )}
    </>
  );
};

export default PurchaseDetailsReceiver;
