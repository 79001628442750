import { LoggingConfigCategoryDto } from "../../api/admin/dtos/LoggingConfigCategoryDto";
import { LoggingLevel } from "../../api/shared/enums/LoggingLevel";
import { enumName } from "../../api/shared/enums/_enumName";
import InputSelectEnum from "../../shared/components/inputs/select/InputSelectEnum";
import Column from "../../shared/components/Layout/GColumn";

const AdminLoggingCategory = (props: {
  loggingCategory: LoggingConfigCategoryDto;
  onChange(value: LoggingConfigCategoryDto): void;
}) => {
  return (
    <Column label={props.loggingCategory.shortName} md={2}>
      <InputSelectEnum
        emptyText="Por defecto..."
        enumTypeName={enumName.LoggingLevel}
        value={props.loggingCategory.level}
        onChange={(value: LoggingLevel) => {
          props.onChange({ ...props.loggingCategory, level: value });
        }}
      />
    </Column>
  );
};

export default AdminLoggingCategory;
