import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { UserLinkedAccountDto } from "../../api/admin/dtos/UserLinkedAccountDto";
import { FiltersDto } from "../../api/shared/dtos/FiltersDto";
import { useApiAdminProviderAccountUserLinkedAccount } from "../../api/shared/hooks/useApiAdminProviders";
import { ButtonCreate } from "../../shared/components/Button/ButtonCreate";
import Grid from "../../shared/components/grid/Grid";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Icon, { TypeIcon } from "../../shared/components/Icon";
import IconCheck from "../../shared/components/IconCheck";
import Page from "../../shared/components/Layout/Page";
import { useModal } from "../../shared/hooks/useModal";
import { useNotifier } from "../../shared/hooks/useNotifier";
import { AppThemeColor } from "../../styles/color";
import AccountUserCreatePopup from "./AccountUserCreatePopup";
import getAccountUserListItemMenu from "./AccountUserList.ItemMenu";

const AccountUserList = () => {
  const [initialFilters] = useState(() => {
    const filters = new FiltersDto();
    return filters;
  });

  const [apiAdminProviderAccountUserLinkedAccount] = useApiAdminProviderAccountUserLinkedAccount();
  const notifier = useNotifier();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [modal] = useModal();

  const itemTemplates: IGridItemTemplate<UserLinkedAccountDto>[] = [
    {
      field: nameof<UserLinkedAccountDto>((p) => p.user.email),
      width: 20,
      header: "Usuario",
    },
    {
      field: nameof<UserLinkedAccountDto>((p) => p.user.firstName),
      width: 20,
      header: "Usuario",
    },
    {
      field: nameof<UserLinkedAccountDto>((p) => p.user.lastName),
      width: 20,
      header: "Usuario",
    },
    {
      field: nameof<UserLinkedAccountDto>((p) => p.accountLastLogin),
      width: 10,
      header: "Último login",
      cellType: GridCellType.dateTime,
    },
    {
      field: nameof<UserLinkedAccountDto>((p) => p.isAccountAdmin),
      width: 10,
      header: "Admin",
      render: (userLinkedAccount: UserLinkedAccountDto) => (
        <>
          {userLinkedAccount.user.inactive && (
            <Icon
              type={TypeIcon.warning}
              color={AppThemeColor.alert}
              tooltip="El usuario se encuentra inhabilitado de forma global para acceder al sistema"
            />
          )}
          {!userLinkedAccount.user.inactive && (
            <IconCheck value={userLinkedAccount.isAccountAdmin} />
          )}
        </>
      ),
    },
  ];

  return (
    <Page
      title="Usuarios"
      titleButton={
        <ButtonCreate
          text="Nuevo usuario"
          onClick={() => {
            modal.open(<AccountUserCreatePopup />, () => setReloadGrid(new Date()));
          }}
        />
      }>
      <Grid
        mustIncludeFields={[
          nameof<UserLinkedAccountDto>((p) => p.userId),
          nameof<UserLinkedAccountDto>((p) => p.user.inactive),
        ]}
        itemTemplates={itemTemplates}
        initialFilters={initialFilters}
        reloadGrid={reloadGrid}
        onSearch={(search, options) =>
          apiAdminProviderAccountUserLinkedAccount.getPagedForCurrentAccount(search, options)
        }
        onDisplayItemMenu={(accountUser: UserLinkedAccountDto) =>
          getAccountUserListItemMenu(
            accountUser,
            apiAdminProviderAccountUserLinkedAccount,
            modal,
            notifier,
            () => setReloadGrid(new Date())
          )
        }
      />
    </Page>
  );
};

export default AccountUserList;
