import { nameof } from "ts-simple-nameof";
import { UserSimpleDto } from "../../../../api/admin/dtos/UserSimpleDto";
import { UserDto } from "../../../../api/admin/dtos/UserDto";
import { SuggestItemDto } from "../../../../api/shared/dtos/SuggestItemDto";
import { SuggestSearchDto } from "../../../../api/shared/dtos/SuggestSearchDto";
import InputAutocompleteBase, { PropsInputAutocompleteBase } from "./InputAutocompleteBase";
import { AccountUserFiltersDto } from "../../../../api/app/dtos/AccountUserFiltersDto";
import { AccountUserDto } from "../../../../api/app/dtos/AccountUserDto";
import { useApiAccountUser } from "../../../../api/shared/hooks/useApiApp";

export type PropsInputAutocompleteAccountUser = Omit<
  PropsInputAutocompleteBase<AccountUserDto>,
  "onGetEntityById"
>;

const InputAutocompleteAccountUser = (props: PropsInputAutocompleteAccountUser) => {
  const [apiAccountUser] = useApiAccountUser();

  const apiRequest = (echoId: number, term: string, isInitialLoad: boolean) => {
    const searchDto = new SuggestSearchDto<AccountUserFiltersDto>();
    searchDto.echoId = echoId;
    searchDto.filters = new AccountUserFiltersDto();
    searchDto.filters.defaultTextSearch = term;
    searchDto.isInitialLoad = isInitialLoad;
    searchDto.orderBy = [{ columnName: nameof<UserDto>((p) => p.email), asc: true }];
    const apiOptions = {
      preventSpinner: true,
    };

    return apiAccountUser.suggestAll(searchDto, apiOptions);
  };

  return (
    <InputAutocompleteBase
      {...props}
      value={props.value}
      onGetEntityById={(id: string) => apiAccountUser.getById(id, { preventSpinner: true })}
      onServerSideSuggest={apiRequest}
      onMapFromItemSuggest={(item: SuggestItemDto) => {
        const adminUserSimpleDto = new UserSimpleDto();
        adminUserSimpleDto.id = item.id;
        adminUserSimpleDto.email = item.label;
        return adminUserSimpleDto;
      }}
    />
  );
};

export default InputAutocompleteAccountUser;
