// File generated by Gesta.Build.PostEvents
import { UserSimpleDto } from "./UserSimpleDto";
import { UserLinkedAccountDto } from "./UserLinkedAccountDto";

export class UserDto extends UserSimpleDto {
  emailConfirmed: boolean = false;
  firstName: string = "";
  lastName: string = "";
  imageUrl: string = "";
  inactive: boolean = false;
  userName: string = "";
  createdDate: Date | null = null;
  lastLogin: Date | null = null;
  accounts: UserLinkedAccountDto[] = [];
}
