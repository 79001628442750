import { useEffect, useRef, useState } from "react";
import { nameof } from "ts-simple-nameof";
import { UserLinkedAccountDto } from "../../api/admin/dtos/UserLinkedAccountDto";
import { UserLinkedAccountFiltersDto } from "../../api/admin/dtos/UserLinkedAccountFiltersDto";
import {
  useApiAdminProviderAdminAccount,
  useApiAdminProviderUser,
  useApiAdminProviderUserLinkedAccount,
} from "../../api/shared/hooks/useApiAdminProviders";
import ButtonCreate from "../../shared/components/Button/ButtonCreate";
import Grid from "../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../shared/components/Layout/Page";
import { AppInfoSiteType } from "../../shared/context/AppInfoProvider";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { useModal } from "../../shared/hooks/useModal";
import { useNotifier } from "../../shared/hooks/useNotifier";
import { usePathQueryString } from "../../shared/hooks/usePathQueryString";
import UserLinkedAccountCreatePopup from "./UserLinkedAccountCreatePopup";
import getUserLinkedAccountListItemMenu from "./UserLinkedAccountList.ItemMenu";

const UserLinkedAccountList = () => {
  const [modal] = useModal();
  const pathQueryString = usePathQueryString();
  const { appInfo } = useContextAppInfo();
  const [apiAdminUser] = useApiAdminProviderUser();
  const [apiAdminAccount] = useApiAdminProviderAdminAccount();
  const notifier = useNotifier();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [apiAdminUserLinkedAccount] = useApiAdminProviderUserLinkedAccount();
  const [isLoaded, setIsLoaded] = useState(false);
  const [filters, setFilters] = useState(() => {
    const filters = new UserLinkedAccountFiltersDto();
    filters.crossShardIncludeAllProviders = appInfo?.siteType == AppInfoSiteType.SuperAdmin;
    return filters;
  });
  const isInit = useRef(false);

  const itemTemplates = [
    {
      header: "Cuenta",
      field: nameof<UserLinkedAccountDto>((p) => p.account.name),
      filter: {
        propertyName: nameof<UserLinkedAccountFiltersDto>((p) => p.adminAccount),
        type: GridFilterType.account,
      },
      width: 40,
    },
    {
      header: "Usuario",
      field: nameof<UserLinkedAccountDto>((p) => p.user.email),
      filter: {
        propertyName: nameof<UserLinkedAccountFiltersDto>((p) => p.user),
        type: GridFilterType.text,
      },
      width: 40,
    },
    {
      header: "Nombre",
      field: nameof<UserLinkedAccountDto>((p) => p.user.firstName),
      width: 30,
    },
    {
      header: "Apellido",
      field: nameof<UserLinkedAccountDto>((p) => p.user.lastName),
      width: 30,
    },
    {
      header: "Admin",
      field: nameof<UserLinkedAccountDto>((p) => p.isAccountAdmin),
      filter: {
        propertyName: nameof<UserLinkedAccountFiltersDto>((p) => p.isAccountAdmin),
        type: GridFilterType.boolean,
      },
      width: 10,
      cellType: GridCellType.iconCheck,
    },
    {
      header: "Último login",
      field: nameof<UserLinkedAccountDto>((p) => p.accountLastLogin),
      filter: {
        propertyName: nameof<UserLinkedAccountFiltersDto>((p) => p.lastLogin),
        type: GridFilterType.dateTime,
      },
      width: 10,
      cellType: GridCellType.dateTime,
    },
  ];

  useEffect(() => {
    if (isInit.current) {
      return;
    }
    isInit.current = true;

    const userId = pathQueryString.get("userId");
    const adminAccountId = pathQueryString.get("adminAccountId");

    if (!userId && !adminAccountId) {
      setIsLoaded(true);
      return;
    }

    const loadSearchFilter = async () => {
      if (userId) {
        const user = await apiAdminUser.getById(userId);
        setFilters({ ...filters, userId: user.id, user: user });
      }

      if (adminAccountId) {
        const adminAccount = await apiAdminAccount.getById(adminAccountId);
        setFilters({
          ...filters,
          adminAccountId: adminAccount.id,
          adminAccount: adminAccount,
        });
      }
      setIsLoaded(true);
    };
    loadSearchFilter();
  }, []);

  if (!isLoaded) {
    return <></>;
  }

  return (
    <Page
      title="Asociaciones de usuarios a cuentas"
      titleButton={
        <ButtonCreate
          text="Nueva asociación"
          onClick={() => {
            modal.open(<UserLinkedAccountCreatePopup />, () => {
              setReloadGrid(new Date());
            });
          }}
        />
      }>
      <Grid
        initialFilters={filters}
        itemTemplates={itemTemplates}
        onDisplayItemMenu={(item) =>
          getUserLinkedAccountListItemMenu(
            item,
            apiAdminUserLinkedAccount,
            notifier,
            modal,
            setReloadGrid
          )
        }
        reloadGrid={reloadGrid}
        preventShowDefaultSearch={true}
        onSearch={(search, options) => apiAdminUserLinkedAccount.getPaged(search, options)}
      />
    </Page>
  );
};

export default UserLinkedAccountList;
