import { EInvoiceEnvelopePurchaseDto } from "../../../../api/app/dtos/EInvoiceEnvelopePurchaseDto";
import { ContextMenuItem } from "../../../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../../../shared/components/Icon";
import { ModalService } from "../../../../shared/hooks/useModal";
import EInvoiceEnvelopePurchaseDetailsPopup from "./EInvoiceEnvelopePurchaseDetailsPopup";

export function getEInvoiceEnvelopePurchaseListItemMenu(
  eInvoiceEnvelopePurchaseDto: EInvoiceEnvelopePurchaseDto,
  modal: ModalService,
  onReloadGrid: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        modal.open(
          <EInvoiceEnvelopePurchaseDetailsPopup
            envelopePurchaseId={eInvoiceEnvelopePurchaseDto.id}
          />,
          onReloadGrid
        );
      },
    },
  ];
}
