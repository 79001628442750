// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { EInvoiceAgentIndicatorType } from "../../shared/enums/EInvoiceAgentIndicatorType";
import { EInvoiceBillingIndicatorType } from "../../shared/enums/EInvoiceBillingIndicatorType";
import { ProductSimpleDto } from "./ProductSimpleDto";
import { Tax } from "../../shared/enums/Tax";
import { Currency } from "../../shared/enums/Currency";

export class InvoiceLineDto extends BaseEntityDto {
  description: string = "";
  discountAmount: number | null = null;
  discountPercentage: number | null = null;
  eInvoiceAgentIndicatorType: EInvoiceAgentIndicatorType;
  eInvoiceBillingIndicatorType: EInvoiceBillingIndicatorType;
  lineDetail: string = "";
  measureUnitCode: string = "";
  order: number = 0;
  quantity: number = 0;
  productId: string;
  product: ProductSimpleDto;
  unitPrice: number = 0;
  tax: Tax;
  taxPercentage: number | null = null;
  tariffPositionCode: string = "";
  subtotal: number = 0;
  surchargePercentage: number | null = null;
  surchargeAmount: number | null = null;
  calculatedTaxAmount: number = 0;
  calculatedTotal: number = 0;
  viewModelReferencePriceCurrency: Currency;
  viewModelReferencePriceAmount: number | null = null;
}
