import { forwardRef } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminAccountSimpleDto } from "../../../../api/shared/dtos/AdminAccountSimpleDto";
import InputAutocompleteSwitchAdminAccount, {
  PropsInputAutocompleteSwitchAdminAccount,
} from "../../inputs/autocomplete/InputAutocompleteSwitchAdminAccount";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormAutocompleteSwitchAdminAccount
  extends PropsFormInput<AdminAccountSimpleDto>,
    Omit<
      PropsInputAutocompleteSwitchAdminAccount,
      "value" | "onChange" | "onCreateNewClick" | "onGetEntity" | "onServerSideSuggest"
    > {}

const FormAutocompleteSwitchAdminAccount = forwardRef(
  (props: PropsFormAutocompleteSwitchAdminAccount, ref?: any) => {
    const inputProps = useFormInputInit(props, ref, {
      updateIdLinkedProperty: true,
      inputTextValuePropertyName: nameof<AdminAccountSimpleDto>((p) => p.name),
    });
    return (
      <InputAutocompleteSwitchAdminAccount preventClearButton={true} {...props} {...inputProps} />
    );
  }
);

export default FormAutocompleteSwitchAdminAccount;
