import { useEffect, useState } from "react";
import { AdminTaxValueDto } from "../../api/admin/dtos/AdminTaxValueDto";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminTaxValue } from "../../api/shared/hooks/useApiAdmin";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AdminTaxValueForm from "./AdminTaxValueForm";
import { useAdminTaxValueValidation } from "./Validations/useAdminTaxValueValidation";

const AdminTaxValueEditPopup = (props: { adminTaxValueId: string }) => {
  const [adminTaxValue, setAdminTaxValue] = useState<AdminTaxValueDto>();
  const [modal] = useModal();
  const [apiAdminTaxValue] = useApiAdminTaxValue();

  useEffect(() => {
    const loadAdminTaxValue = async () => {
      setAdminTaxValue(await apiAdminTaxValue.getById(props.adminTaxValueId));
      modal.setVisible("Editar impuesto", ModalSize.small);
    };
    loadAdminTaxValue();
  }, [props.adminTaxValueId, modal, apiAdminTaxValue]);

  const save = async (adminTaxValue: AdminTaxValueDto) => {
    const created = await apiAdminTaxValue.edit(adminTaxValue);
    modal.closeAsSuccess(created, "El impuesto se editó con éxito");
  };

  if (!adminTaxValue) {
    return <></>;
  }

  return (
    <Container>
      <StateProvider
        model={adminTaxValue}
        onSubmit={save}
        validationSchema={useAdminTaxValueValidation()}>
        {(p) => <AdminTaxValueForm editMode={true} onSave={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default AdminTaxValueEditPopup;
