import { nameof } from "ts-simple-nameof";
import { SaleLineDto } from "../../api/app/dtos/SaleLineDto";
import FormNumber from "../../shared/components/forms/FormNumber";
import useUtils from "../../shared/hooks/useUtils";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import { ISaleLineColumnRenderProps } from "./SaleFormLines";

const SaleLineColumnUnitPrice = (props: ISaleLineColumnRenderProps) => {
  const { utils } = useUtils();
  const saleModelState = useContextModelStateSale();

  if (!props.isEditing) {
    return <>{utils.number.toString(props.saleLine.unitPrice)}</>;
  }

  return (
    <FormNumber
      arrayItemHelper={props.arrayItemHelper}
      label={props.showLabel ? props.saleLineColumn.header : ""}
      hint={
        props.saleLine.viewModelReferencePriceAmount != props.saleLine.unitPrice &&
        props.saleLine.viewModelReferencePriceAmount
          ? utils.currency.symbol(props.saleLine.viewModelReferencePriceCurrency) +
            ": " +
            utils.number.toString(props.saleLine.viewModelReferencePriceAmount)
          : ""
      }
      propertyName={nameof<SaleLineDto>((p) => p.unitPrice)}
      showZeroAsEmpty={true}
      variant={props.variant}
      onValueChange={() => saleModelState.onLineUnitPriceChange(props.arrayItemHelper.index)}
    />
  );
};

export default SaleLineColumnUnitPrice;
