// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminEmailContactSupportDto } from "../dtos/AdminEmailContactSupportDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminAccountPublicEmailContactSupport {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  send(
    emailContactSupportDto: AdminEmailContactSupportDto,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/admin/account-public/email-contact-support", emailContactSupportDto, apiOptions);
  }

}
