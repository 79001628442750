// File generated by: Gesta.Build.PostEvents
export enum EInvoiceDailyReportStatus {
	Pending = 1,
	Processing = 2,
	Cancelled = 3,
	PendingSent = 4,
	Sent = 5,
	PendingVerification = 6,
	Accepted = 7,
	Rejected = 8,
	ErrorGenerate = 1000,
	ErrorSending = 10001,
}
