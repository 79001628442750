import { MutableRefObject, ReactElement } from "react";
import { AdminTemplateDto } from "../../../api/admin/dtos/AdminTemplateDto";
import { TemplateGroup } from "../../../api/shared/enums/TemplateGroup";
import { TemplateType } from "../../../api/shared/enums/TemplateType";
import { TextAlign } from "../../TextAlign";
import { templateGetGroup } from "../../utils/utilTemplate";
import CodeEditor, { CodeEditorLang } from "../CodeEditor";
import InfoText from "../InfoText";
import Column from "../Layout/GColumn";
import Row from "../Layout/GRow";

const isEmail = (template: AdminTemplateDto) => {
  if (templateGetGroup(template.type) == TemplateGroup.Email) {
    return true;
  }
  return (
    template.type == TemplateType.AdminGenericEmail || template.type == TemplateType.GenericEmail
  );
};

const TemplateEditorStyle = (props: {
  value: string;
  cssRef: MutableRefObject<any>;
  template: AdminTemplateDto;
  expandPreviewPanelButton?: ReactElement;
  scrollPosX?: number;
  scrollPosY?: number;
  onChange: (value: string) => void;
  onScroll?: (positionX: number, positionY: number) => void;
}) => {
  const helpText = isEmail(props.template)
    ? "Pero atención! te recomendamos para una mejor visualización, en " +
      "cualquier cliente de correo, utilizar <strong>estilos en línea</strong> en el html."
    : "Estos estilos afectan a todo el documento, por lo que te recomendamos usar prefijos en los nombres de las clases.";

  return (
    <>
      <Row>
        <InfoText>
          Aquí podes ingresar los estilos <strong>css</strong> del documento. {helpText}
        </InfoText>
      </Row>
      <Row>
        <CodeEditor
          value={props.value ?? ""}
          lang={CodeEditorLang.css}
          ref={props.cssRef}
          scrollPosX={props.scrollPosX}
          scrollPosY={props.scrollPosY}
          onChange={props.onChange}
          onScroll={props.onScroll}
        />
      </Row>
      <Column md={10}></Column>
      <Column md={2} align={TextAlign.right}>
        {props.expandPreviewPanelButton}
      </Column>
    </>
  );
};

export default TemplateEditorStyle;
