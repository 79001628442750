// File generated by: Gesta.Build.PostEvents
export enum enumName {
  AccountConfigPreferencesType = "AccountConfigPreferencesType",
  AppLocale = "AppLocale",
  AppNotificationGroup = "AppNotificationGroup",
  AppNotificationType = "AppNotificationType",
  CompanyType = "CompanyType",
  ConfigCurrencyMode = "ConfigCurrencyMode",
  ContactFilterGroup = "ContactFilterGroup",
  ContactType = "ContactType",
  Country = "Country",
  Currency = "Currency",
  CurrencyName = "CurrencyName",
  EditItemStatus = "EditItemStatus",
  EInvoiceAgentIndicatorType = "EInvoiceAgentIndicatorType",
  EInvoiceAmountIndicatorType = "EInvoiceAmountIndicatorType",
  EInvoiceAttachmentType = "EInvoiceAttachmentType",
  EInvoiceAuthorizationSpecialType = "EInvoiceAuthorizationSpecialType",
  EInvoiceBillingIndicatorType = "EInvoiceBillingIndicatorType",
  EInvoiceDailyReportStatus = "EInvoiceDailyReportStatus",
  EInvoiceEnvelopeStatus = "EInvoiceEnvelopeStatus",
  EInvoiceEnvelopeStatusResponseType = "EInvoiceEnvelopeStatusResponseType",
  EInvoiceProcessStatus = "EInvoiceProcessStatus",
  EInvoiceProfessionalSecret = "EInvoiceProfessionalSecret",
  EInvoicePurchaseApprovalStatus = "EInvoicePurchaseApprovalStatus",
  EInvoicePurchaseRejectReason = "EInvoicePurchaseRejectReason",
  EInvoiceSaleMode = "EInvoiceSaleMode",
  EInvoiceTransportationIndicatorType = "EInvoiceTransportationIndicatorType",
  EInvoiceTransportationType = "EInvoiceTransportationType",
  EmailClientType = "EmailClientType",
  EmailConfigType = "EmailConfigType",
  EmailConfigValidationStatus = "EmailConfigValidationStatus",
  EmailProviderType = "EmailProviderType",
  ExportGridColumnOption = "ExportGridColumnOption",
  FileUploadExtensionsAllowed = "FileUploadExtensionsAllowed",
  FilterDateRangeType = "FilterDateRangeType",
  GridName = "GridName",
  GridSettingType = "GridSettingType",
  IdCardType = "IdCardType",
  InvoiceChargeType = "InvoiceChargeType",
  InvoiceRetentionType = "InvoiceRetentionType",
  InvoiceType = "InvoiceType",
  InvoiceTypeGroup = "InvoiceTypeGroup",
  LocalFolderType = "LocalFolderType",
  LoggingLevel = "LoggingLevel",
  LongProcessStatus = "LongProcessStatus",
  LongProcessType = "LongProcessType",
  Month = "Month",
  PaperOrientation = "PaperOrientation",
  PaperSize = "PaperSize",
  PaymentType = "PaymentType",
  ProductFilterGroup = "ProductFilterGroup",
  ProductType = "ProductType",
  RequestedExportStatus = "RequestedExportStatus",
  SaleDraftStatus = "SaleDraftStatus",
  SentEmailDestinationType = "SentEmailDestinationType",
  SentEmailStatus = "SentEmailStatus",
  ServiceBusProcessorStatus = "ServiceBusProcessorStatus",
  State = "State",
  StorageBaseType = "StorageBaseType",
  StorageContainerType = "StorageContainerType",
  Tax = "Tax",
  TemplateDesignFieldType = "TemplateDesignFieldType",
  TemplateGenericType = "TemplateGenericType",
  TemplateGroup = "TemplateGroup",
  TemplateType = "TemplateType",
  UserCrudEmailVerificationStatus = "UserCrudEmailVerificationStatus",
  VaultCertificateStatus = "VaultCertificateStatus",
}
