import { ReactElement } from "react";
import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import { useContextLoginInfo } from "../../hooks/useContextLoginInfo";
import { LAYOUT_SPACING_PIXELS } from "./LayoutConstants";

const LayoutHeaderStickerAdmin = (): ReactElement => {
  const appTheme = useAppTheme();
  const { loginInfo } = useContextLoginInfo();
  return (
    <div
      style={{
        background: appTheme.toHexColor(AppThemeColor.alert),
        color: appTheme.toHexColor(AppThemeColor.white),
        fontSize: "3em",
        left: -70,
        padding: LAYOUT_SPACING_PIXELS,
        position: "fixed",
        top: 45,
        textAlign: "center",
        transform: "rotate(320deg)",
        width: 300,
        zIndex: 1001,
      }}>
      {loginInfo.authInfo.isSuperAdmin ? "SuperAdmin" : "Proveedor"}
    </div>
  );
};

export default LayoutHeaderStickerAdmin;
