import { TimePicker } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React, { CSSProperties, useContext } from "react";
import { Constants } from "../../../Constants";
import { AppInfoContext } from "../../context/AppInfoProvider";
import useUtils from "../../hooks/useUtils";
import { ThemeSize } from "../../ThemeSize";
import {
  LAYOUT_HINT_MARGIN_BOTTOM_FIX,
  LAYOUT_HINT_PADDING_BOTTOM,
} from "../Layout/LayoutConstants";
import PropsInputBase from "./PropsInputBase";

export interface PropsInputDate extends PropsInputBase<Date | null> {
  includeTime?: boolean;
  onlyTime?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const InputDate = React.forwardRef((props: PropsInputDate, ref: any) => {
  const { appInfo } = useContext(AppInfoContext);
  const { utils } = useUtils();

  let helperText = Constants.EMPTY_HINT;

  if (props.validation) {
    helperText = props.validation;
  } else if (props.hint) {
    helperText = props.hint;
  } else if (props.hintAutoHeight) {
    helperText = "";
  }

  const style: CSSProperties = {
    marginBottom: !props.hintAutoHeight
      ? -LAYOUT_HINT_MARGIN_BOTTOM_FIX
      : LAYOUT_HINT_PADDING_BOTTOM,
  };
  let dateValue: Date | null = null;
  if (props.includeTime || props.onlyTime) {
    dateValue = utils.date.typeCheck(props.value);
  } else if (props.value) {
    dateValue = utils.date.clearTimePart(utils.date.typeCheck(props.value) ?? new Date());
  }

  const onChange = (date: Date | null) => {
    if (!date) {
      return props.onChange(null);
    }

    if (props.includeTime || props.onlyTime) {
      props.onChange(date);
      return;
    }

    props.onChange(utils.date.clearTimePart(date));
  };

  const pickerProps = {
    label: props.label,
    value: dateValue,
    inputRef: ref,
    onChange: onChange,
    slotProps: { textField: { helperText } },
  };

  if (props.onlyTime) {
    return (
      <TimePicker
        {...pickerProps}
        value={dateValue}
        slotProps={{
          textField: {
            variant: props.variant ?? appInfo?.muiVariant,
            size: ThemeSize.small,
            error: !!props.validation,
            fullWidth: true,
            helperText: helperText,
            style: style,
            onBlur: props.onBlur,
          },
        }}
      />
    );
  }

  return props.includeTime ? (
    <DateTimePicker
      {...pickerProps}
      disabled={props.readOnly}
      value={dateValue}
      slotProps={{
        textField: {
          variant: props.variant ?? appInfo?.muiVariant,
          size: ThemeSize.small,
          error: !!props.validation,
          fullWidth: true,
          helperText: helperText,
          style: style,
          onBlur: props.onBlur,
        },
      }}
    />
  ) : (
    <DatePicker
      {...pickerProps}
      disabled={props.readOnly}
      value={dateValue}
      slotProps={{
        textField: {
          variant: props.variant ?? appInfo?.muiVariant,
          size: ThemeSize.small,
          error: !!props.validation,
          fullWidth: true,
          helperText: helperText,
          style: style,
          onBlur: props.onBlur,
        },
      }}
    />
  );
});

export default InputDate;
