import { AppLocale } from "../../../api/shared/enums/AppLocale";
import { appLocaleMapName } from "../../context/LocaleProvider";

export class LocalizedUtilNumber {
  private _appLocale: AppLocale;

  async load(appLocale: AppLocale) {
    this._appLocale = appLocale;
  }

  public toIntString(value: number) {
    return this.toString(value, 0, 0);
  }

  public toString(value: number | null, minDecimals?: number, maxDecimals?: number): string {
    if (value == null || value === undefined) {
      return "";
    }

    const formatter = new Intl.NumberFormat(appLocaleMapName[AppLocale[this._appLocale]], {
      minimumFractionDigits: minDecimals ?? maxDecimals ?? 2,
      maximumFractionDigits: maxDecimals ?? minDecimals ?? 2,
    });

    return formatter.format(value);
  }
}
