import { useContext } from "react";
import { AuthInfoDto } from "../../api/app/dtos/AuthInfoDto";
import { LoginInfoActionType, LoginInfoContext } from "../context/LoginInfoProvider";

export function useContextLoginInfo() {
  const { loginInfo, dispatchLoginInfo } = useContext(LoginInfoContext);

  const login = (authInfo: AuthInfoDto, authToken: string) => {
    dispatchLoginInfo({
      type: LoginInfoActionType.login,
      authInfo: authInfo,
      authToken: authToken,
    });
  };

  const logout = () => {
    dispatchLoginInfo({ type: LoginInfoActionType.logout });
  };

  const acceptTermsAndConditions = () => {
    dispatchLoginInfo({
      type: LoginInfoActionType.acceptTermsAndConditions,
    });
  };

  return {
    loginInfo,
    login,
    logout,
    acceptTermsAndConditions,
  };
}
