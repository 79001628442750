import { useEffect, useState } from "react";
import { AdminProviderDto } from "../../api/admin/dtos/AdminProviderDto";
import { useApiAdminProvider } from "../../api/shared/hooks/useApiAdmin";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useModal } from "../../shared/hooks/useModal";

const AdminProviderDetailsPopup = (props: { id: string }) => {
  const [modal] = useModal();
  const [adminProvider, setAdminProvider] = useState<AdminProviderDto>();
  const [apiAdminProvider] = useApiAdminProvider();

  useEffect(() => {
    const loadAdminProvider = async () => {
      setAdminProvider(await apiAdminProvider.getById(props.id));
      modal.setVisible("Detalles del proveedor", ModalSize.small);
    };
    loadAdminProvider();
  }, []);

  if (!adminProvider) {
    return <></>;
  }

  return (
    <Container>
      <ColumnDisplay label="Nombre">{adminProvider.name}</ColumnDisplay>
    </Container>
  );
};

export default AdminProviderDetailsPopup;
