import { nameof } from "ts-simple-nameof";
import { ProductSimpleDto } from "../../api/app/dtos/ProductSimpleDto";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import { SaleLineDto } from "../../api/app/dtos/SaleLineDto";
import { SaleLineRetentionDto } from "../../api/app/dtos/SaleLineRetentionDto";
import { SaleReferenceDto } from "../../api/app/dtos/SaleReferenceDto";
import { TaxValueDto } from "../../api/app/dtos/TaxValueDto";
import { InvoiceRetentionType } from "../../api/shared/enums/InvoiceRetentionType";
import { InvoiceType } from "../../api/shared/enums/InvoiceType";
import { InvoiceTypeGroup } from "../../api/shared/enums/InvoiceTypeGroup";
import { useContextModelState } from "../../shared/modelState/useContextModelState";
import { cloneObject } from "../../shared/utils/utilObject";
import { SaleModelStateActionType, saleReducer } from "./SaleForm.ModelStateReducer";
import { SaleViewModel } from "./ViewModels/SaleViewModel";

export function useContextModelStateSale() {
  const {
    modelState,
    arrayItemReplace,
    updateProperties,
    updateProperty,
    arrayItemRemove,
    fieldTrackSetChildrenTouched,
    arrayItemAdd,
    dispatchCustom,
    replaceModel,
  } = useContextModelState(saleReducer);

  const sale = modelState.model as SaleViewModel;

  const cancelEditLine = () => {
    if (sale.editingLineIndex !== null && sale.editingLineClonedBackup) {
      arrayItemReplace(
        nameof<SaleViewModel>((p) => p.lines),
        sale.editingLineIndex,
        sale.editingLineClonedBackup
      );

      updateProperties([
        { propertyFullName: nameof<SaleViewModel>((p) => p.editingLineClonedBackup), value: null },
        { propertyFullName: nameof<SaleViewModel>((p) => p.editingLineIndex), value: null },
      ]);
      return;
    }

    updateProperty(
      nameof<SaleViewModel>((p) => p.editingLineIndex),
      null
    );

    if (sale.editingLineIndex != null) {
      arrayItemRemove(
        nameof<SaleViewModel>((p) => p.lines),
        sale.editingLineIndex
      );
    }
  };

  const currentEditingLineIsValid = () => {
    if (sale.editingLineIndex === null) {
      return true;
    }

    if (modelState.validation?.lines.items[sale.editingLineIndex].isValid) {
      return true;
    }

    fieldTrackSetChildrenTouched(
      nameof<SaleDto>((p) => p.lines) + "[" + sale.editingLineIndex + "]"
    );
  };

  const lineAdd = (line: SaleLineDto) => {
    if (!currentEditingLineIsValid()) {
      return;
    }

    arrayItemAdd(
      nameof<SaleDto>((p) => p.lines),
      line
    );
    setEditLine(sale.lines.length);
  };

  const lineDelete = (lineIndex: number) => {
    let newEditingLineIndex = null;
    if (sale.editingLineIndex !== null) {
      if (sale.editingLineIndex < lineIndex) {
        newEditingLineIndex = sale.editingLineIndex;
      } else if (sale.editingLineIndex > lineIndex) {
        newEditingLineIndex = sale.editingLineIndex - 1;
      }
    }

    arrayItemRemove(
      nameof<SaleDto>((p) => p.lines),
      lineIndex
    );

    updateProperty(
      nameof<SaleViewModel>((p) => p.editingLineIndex),
      newEditingLineIndex
    );
  };

  const lineRetentionAdd = (lineIndex: number, saleLineRetention: SaleLineRetentionDto) => {
    arrayItemAdd(
      nameof<SaleViewModel>((p) => p.lines) +
        "[" +
        lineIndex +
        "]." +
        nameof<SaleViewModel>((p) => p.retentions),
      saleLineRetention
    );
  };

  const lineRetentionRemove = (lineIndex: number, saleLineRetentionIndex: number) => {
    arrayItemRemove(
      nameof<SaleViewModel>((p) => p.lines) +
        "[" +
        lineIndex +
        "]." +
        nameof<SaleViewModel>((p) => p.retentions),
      saleLineRetentionIndex
    );
  };

  const lineRetentionSetCode = (lineIndex: number, retentionIndex: number, value: string) => {
    updateProperty(
      nameof<SaleViewModel>((p) => p.lines) +
        "[" +
        lineIndex +
        "]." +
        nameof<SaleViewModel>((p) => p.retentions) +
        "[" +
        retentionIndex +
        "]." +
        nameof<SaleLineRetentionDto>((p) => p.code),
      value
    );
  };

  const lineRetentionSetDescription = (
    lineIndex: number,
    retentionIndex: number,
    value: string
  ) => {
    updateProperty(
      nameof<SaleViewModel>((p) => p.lines) +
        "[" +
        lineIndex +
        "]." +
        nameof<SaleViewModel>((p) => p.retentions) +
        "[" +
        retentionIndex +
        "]." +
        nameof<SaleLineRetentionDto>((p) => p.detail),
      value
    );
  };

  const lineRetentionSetType = (
    lineIndex: number,
    retentionIndex: number,
    type: InvoiceRetentionType
  ) => {
    updateProperty(
      nameof<SaleViewModel>((p) => p.lines) +
        "[" +
        lineIndex +
        "]." +
        nameof<SaleViewModel>((p) => p.retentions) +
        "[" +
        retentionIndex +
        "]." +
        nameof<SaleLineRetentionDto>((p) => p.type),
      type
    );

    dispatchCustom(SaleModelStateActionType.onLineRetentionTypeChange, {
      lineIndex,
      retentionIndex,
    });
  };

  const updateTaxValues = (taxValues: TaxValueDto[]) => {
    updateProperty(
      nameof<SaleViewModel>((p) => p.taxValuesDto),
      taxValues
    );
  };

  const onAutoRoundingChange = () => {
    dispatchCustom(SaleModelStateActionType.onAutoRoundingChange);
  };

  const onContactChange = () => {
    dispatchCustom(SaleModelStateActionType.onContactChange);
  };

  const onLineProductChange = (lineIndex: number) => {
    dispatchCustom(SaleModelStateActionType.onLineProductChange, lineIndex);
  };

  const onLineDetailChange = (lineIndex: number) => {
    dispatchCustom(SaleModelStateActionType.onLineDetailChange, lineIndex);
  };

  const onLineDiscountAmountChange = (lineIndex: number) => {
    dispatchCustom(SaleModelStateActionType.onLineDiscountAmountChange, lineIndex);
  };

  const onLineDiscountPercentageChange = (lineIndex: number) => {
    dispatchCustom(SaleModelStateActionType.onLineDiscountPercentageChange, lineIndex);
  };

  const onLineQuantityChange = (lineIndex: number) => {
    dispatchCustom(SaleModelStateActionType.onLineQuantityChange, lineIndex);
  };

  const onLineRetentionAmountChange = (lineIndex: number, retentionIndex: number) => {
    dispatchCustom(SaleModelStateActionType.onLineRetentionAmountChange, {
      lineIndex: lineIndex,
      retentionIndex: retentionIndex,
    });
  };

  const onLineRetentionRateChange = (lineIndex: number, retentionIndex: number) => {
    dispatchCustom(SaleModelStateActionType.onLineRetentionRateChange, {
      lineIndex: lineIndex,
      retentionIndex: retentionIndex,
    });
  };

  const onLineRetentionTotalAmountChange = (lineIndex: number, retentionIndex: number) => {
    dispatchCustom(SaleModelStateActionType.onLineRetentionTotalAmountChange, {
      lineIndex: lineIndex,
      retentionIndex: retentionIndex,
    });
  };

  const onLineSubtotalChange = (lineIndex: number) => {
    dispatchCustom(SaleModelStateActionType.onLineSubtotalChange, lineIndex);
  };

  const onLineSurchargeAmountChange = (lineIndex: number) => {
    dispatchCustom(SaleModelStateActionType.onLineSurchargeAmountChange, lineIndex);
  };

  const onLineSurchargePercentageChange = (lineIndex: number) => {
    dispatchCustom(SaleModelStateActionType.onLineSurchargePercentageChange, lineIndex);
  };

  const onLineTaxChange = (lineIndex: number) => {
    dispatchCustom(SaleModelStateActionType.onLineTaxChange, lineIndex);
  };

  const onLineTotalChange = (lineIndex: number) => {
    dispatchCustom(SaleModelStateActionType.onLineTotalChange, lineIndex);
  };

  const onLineUnitPriceChange = (lineIndex: number) => {
    dispatchCustom(SaleModelStateActionType.onLineUnitPriceChange, lineIndex);
  };

  const referenceAdd = (reference: SaleReferenceDto) => {
    arrayItemAdd(
      nameof<SaleDto>((p) => p.references),
      reference
    );
  };

  const referenceRemove = (referenceIndex: number) => {
    arrayItemRemove(
      nameof<SaleDto>((p) => p.references),
      referenceIndex
    );
  };

  const referenceRemoveAll = () => {
    updateProperty(
      nameof<SaleDto>((p) => p.references),
      []
    );
  };

  const referenceSetIsGlobal = (referenceIndex: number, value: boolean) => {
    updateProperty(
      nameof<SaleDto>((p) => p.references) +
        "[" +
        referenceIndex +
        "]." +
        nameof<SaleReferenceDto>((p) => p.isGlobal),
      value
    );
  };

  const referenceSetReferenceDate = (referenceIndex: number, value: Date | null) => {
    updateProperty(
      nameof<SaleDto>((p) => p.references) +
        "[" +
        referenceIndex +
        "]." +
        nameof<SaleReferenceDto>((p) => p.referenceDate),
      value
    );
  };

  const updatePaymentExpirationDate = (date: Date | null) => {
    updateProperty(
      nameof<SaleDto>((p) => p.paymentExpirationDate),
      date
    );
  };

  const updateDate = (date: Date | null) => {
    updateProperty(
      nameof<SaleDto>((p) => p.date),
      date
    );
  };

  const updateDraftIsNotCurrentDate = (value: boolean) => {
    updateProperty(
      nameof<SaleDto>((p) => p.draftIsNotCurrentDate),
      value
    );
  };

  const updateCurrencyExchangeRate = (value: number | null) => {
    updateProperty(
      nameof<SaleDto>((p) => p.currencyExchangeRate),
      value
    );
  };

  const updateInvoiceType = (value: InvoiceType | null) => {
    updateProperty(
      nameof<SaleDto>((p) => p.invoiceType),
      value
    );
  };

  const updateInvoiceTypeGroup = (value: InvoiceTypeGroup | null) => {
    updateProperty(
      nameof<SaleDto>((p) => p.invoiceTypeGroup),
      value
    );
  };

  const setEditLine = (lineIndex: number | null) => {
    if (!currentEditingLineIsValid()) {
      return;
    }

    if (lineIndex !== null) {
      sale.editingLineClonedBackup = cloneObject(sale.lines[lineIndex]);
    }

    updateProperty(
      nameof<SaleViewModel>((p) => p.editingLineIndex),
      lineIndex
    );
  };

  const setSaveAsDraft = (value: boolean) => {
    updateProperty(
      nameof<SaleViewModel>((p) => p.saveAsDraft),
      value
    );
  };

  const updateLineProduct = (lineIndex: number, product: ProductSimpleDto | null) => {
    updateProperty(
      nameof<SaleViewModel>((p) => p.lines) +
        "[" +
        lineIndex +
        "]." +
        nameof<SaleLineDto>((p) => p.product),
      product,
      true,
      true
    );
  };

  return {
    sale,
    modelState,
    cancelEditLine,
    currentEditingLineIsValid,
    dispatchCustom,
    lineAdd,
    lineDelete,
    lineRetentionAdd,
    lineRetentionRemove,
    lineRetentionSetCode,
    lineRetentionSetDescription,
    lineRetentionSetType,
    onAutoRoundingChange,
    onContactChange,
    onLineProductChange,
    onLineDetailChange,
    onLineDiscountAmountChange,
    onLineDiscountPercentageChange,
    onLineQuantityChange,
    onLineRetentionAmountChange,
    onLineRetentionRateChange,
    onLineRetentionTotalAmountChange,
    onLineSubtotalChange,
    onLineSurchargeAmountChange,
    onLineSurchargePercentageChange,
    onLineTaxChange,
    onLineTotalChange,
    onLineUnitPriceChange,
    referenceAdd,
    referenceRemove,
    referenceRemoveAll,
    referenceSetIsGlobal,
    referenceSetReferenceDate,
    replaceModel,
    setEditLine,
    setSaveAsDraft,
    updateCurrencyExchangeRate,
    updateDate,
    updateDraftIsNotCurrentDate,
    updateInvoiceType,
    updateInvoiceTypeGroup,
    updateLineProduct,
    updatePaymentExpirationDate,
    updateTaxValues,
  };
}
