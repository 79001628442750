import { AppRoute } from "../../AppRoutes";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import Row from "../../shared/components/Layout/GRow";
import Section from "../../shared/components/Layout/GSection";
import { useNavigation } from "../../shared/hooks/useNavigation";

const ConfigurationEmail = () => {
  const navigation = useNavigation();

  return (
    <Row>
      <Section title="Correo">
        <Row>
          <ComponentGroup wrap={true}>
            <ButtonSecondary
              text="Correo"
              icon={TypeIcon.email}
              onClick={() => navigation.go(AppRoute.configuration.email.base)}
            />
            <ButtonSecondary
              text="Correos enviados"
              icon={TypeIcon.email}
              onClick={() => navigation.go(AppRoute.sentEmails)}
            />
          </ComponentGroup>
        </Row>
      </Section>
    </Row>
  );
};

export default ConfigurationEmail;
