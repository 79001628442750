import { AdminEmailContactPublicSalesDto } from "../../../api/admin/dtos/AdminEmailContactPublicSalesDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

export function useLeadValidation() {
  const schema = new ValidationSchema();

  schema.name = validate("Nombre").required();
  schema.email = validate("Correo")
    .when((c: AdminEmailContactPublicSalesDto) => !c.contactByWhatsApp)
    .required()
    .email();
  schema.phone = validate("Teléfono")
    .when((c) => c.shouldCall)
    .required();

  return schema;
}
