import { AdminRetentionDto } from "../../../../api/admin/dtos/AdminRetentionDto";
import { AdminRetentionFiltersDto } from "../../../../api/admin/dtos/AdminRetentionFiltersDto";
import { SuggestSearchDto } from "../../../../api/shared/dtos/SuggestSearchDto";
import { useApiAdminAccountPublicAdminRetention } from "../../../../api/shared/hooks/useApiAdmin";
import InputAutocompleteBase, { PropsInputAutocompleteBase } from "./InputAutocompleteBase";

export type PropsInputAutocompleteAdminRetention = Omit<
  PropsInputAutocompleteBase<AdminRetentionDto | null>,
  "onGetEntityById"
>;

const InputSuggestAdminRetention = (props: PropsInputAutocompleteAdminRetention) => {
  const [apiAdminAccountPublicAdminRetention] = useApiAdminAccountPublicAdminRetention();

  const apiRequest = (echoId: number, term: string, isInitialLoad: boolean) => {
    const searchDto = new SuggestSearchDto<AdminRetentionFiltersDto>();
    searchDto.echoId = echoId;
    searchDto.filters = new AdminRetentionFiltersDto();
    searchDto.filters.defaultTextSearch = term;
    searchDto.isInitialLoad = isInitialLoad;

    const apiOptions = {
      preventSpinner: true,
    };

    return apiAdminAccountPublicAdminRetention.suggestByCountry(searchDto, apiOptions);
  };

  return (
    <InputAutocompleteBase
      {...props}
      inputTextValue={props.value?.code}
      isSuggestOnly={true}
      value={props.value}
      onGetEntityById={(id: string) =>
        apiAdminAccountPublicAdminRetention.getById(id, { preventSpinner: true })
      }
      onServerSideSuggest={apiRequest}
    />
  );
};

export default InputSuggestAdminRetention;
