// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { Country } from "../../shared/enums/Country";
import { Tax } from "../../shared/enums/Tax";
import { DateOnlyDto } from "../../shared/dtos/DateOnlyDto";

export class AdminTaxValueDto extends BaseEntityDto {
  country: Country;
  tax: Tax;
  value: number = 0;
  operationMustSpecify: boolean = false;
  validSince: DateOnlyDto;
  validUntil: DateOnlyDto;
}
