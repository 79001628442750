import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AccountConfigDto } from "../../api/app/dtos/AccountConfigDto";
import { ContactDto } from "../../api/app/dtos/ContactDto";
import { SaleDto } from "../../api/app/dtos/SaleDto";
import { SaleForCreateOptionsDto } from "../../api/app/dtos/SaleForCreateOptionsDto";
import { SaleReferenceDto } from "../../api/app/dtos/SaleReferenceDto";
import { InvoiceTypeGroup } from "../../api/shared/enums/InvoiceTypeGroup";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiSale, useApiServiceBus } from "../../api/shared/hooks/useApiApp";
import { AppEnvironment } from "../../AppEnvironment";
import Page from "../../shared/components/Layout/Page";
import { TaxValueProvider } from "../../shared/components/TaxValue/TaxValueProvider";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import { useIsMobile } from "../../shared/hooks/useIsMobile";
import { useNavigation } from "../../shared/hooks/useNavigation";
import { useNotifier } from "../../shared/hooks/useNotifier";
import useUtils from "../../shared/hooks/useUtils";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import SaleForm from "./SaleForm";
import { getNewLine } from "./SaleFormLines";
import { useSaleValidation } from "./Validations/useSaleValidation";
import { SaleViewModel } from "./ViewModels/SaleViewModel";

export const getSaleCreateOptionsDto = (
  pageType: SalePageType,
  accountConfig: AccountConfigDto,
  contact?: ContactDto
): SaleForCreateOptionsDto => {
  const saleForCreateOptionsDto = new SaleForCreateOptionsDto();

  saleForCreateOptionsDto.isExport =
    pageType == SalePageType.CreateExport ||
    pageType == SalePageType.CancellationExport ||
    pageType == SalePageType.CreateAdjustmentExport;

  saleForCreateOptionsDto.isSaleInBehalf =
    pageType == SalePageType.CreateSaleSenderInBehalf ||
    pageType == SalePageType.CancellationSenderInBehalf ||
    pageType == SalePageType.CreateAdjustmentSenderInBehalf;

  saleForCreateOptionsDto.isAdjustment =
    pageType == SalePageType.CreateAdjustment ||
    pageType == SalePageType.CreateAdjustmentExport ||
    pageType == SalePageType.CreateAdjustmentSenderInBehalf;

  saleForCreateOptionsDto.isCancellation =
    pageType == SalePageType.Cancellation ||
    pageType == SalePageType.CancellationExport ||
    pageType == SalePageType.CancellationSenderInBehalf;

  if (pageType == SalePageType.CreateDeliveryNote) {
    saleForCreateOptionsDto.invoiceTypeGroup = InvoiceTypeGroup.ERemito;
  } else if (pageType == SalePageType.CreateReceipt) {
    saleForCreateOptionsDto.invoiceTypeGroup = InvoiceTypeGroup.EResguardo;
  } else if (pageType == SalePageType.CreateEntranceTicket) {
    saleForCreateOptionsDto.invoiceTypeGroup = InvoiceTypeGroup.EBoleta;
  }

  saleForCreateOptionsDto.isContingency = accountConfig.eInvoiceIsContingencyMode;

  if (contact) {
    saleForCreateOptionsDto.contact = contact;
  }
  return saleForCreateOptionsDto;
};

export const getDefaultReference = (): SaleReferenceDto => {
  const reference = new SaleReferenceDto();
  reference.isGlobal = true;
  reference.reason = "A cuenta";
  reference.serie = "A";
  reference.number = 1;
  reference.referenceDate = new Date();
  return reference;
};

export const getEmptyReference = (): SaleReferenceDto => {
  const reference = new SaleReferenceDto();
  reference.isGlobal = true;
  reference.referenceDate = null;
  return reference;
};

export enum SalePageType {
  Create = 0,
  CreateExport = 1,
  CreateDeliveryNote = 2,
  CreateEntranceTicket = 3,
  CreateReceipt = 4,
  CreateSaleSenderInBehalf = 5,
  CreateClone = 6,
  EditDraft = 7,
  Cancellation = 8,
  CancellationExport = 9,
  CancellationSenderInBehalf = 10,
  CreateAdjustment = 11,
  CreateAdjustmentExport = 12,
  CreateAdjustmentSenderInBehalf = 13,
}

const SalePage = (props: { pageType: SalePageType }) => {
  const isMobile = useIsMobile();
  const { utils } = useUtils();
  const notifier = useNotifier();
  const navigation = useNavigation();
  const [apiSale] = useApiSale();
  const [apiServiceBus] = useApiServiceBus();
  const { id } = useParams<{ id: string }>();
  const { loginInfo } = useContextLoginInfo();
  const [saleViewModel, setSaleViewModel] = useState<SaleViewModel>();
  const [title, setTitle] = useState("Cargando...");

  const save = async (sale: SaleViewModel) => {
    if (sale.saveAsDraft) {
      await apiSale.draftSave(sale);
      notifier.showSuccessFast("La factura se guardó como borrador");
      navigation.back();
      return;
    }

    const emittedSale = await apiSale.emit(sale);

    const invoiceNumber =
      utils.enum.translate(enumName.InvoiceType, emittedSale.invoiceType) +
      " " +
      emittedSale.serieAndNumber;

    notifier.showSuccess("El comprobante se emitió con éxito: " + invoiceNumber);

    if (emittedSale.pdfUrl) {
      navigation.newTab(emittedSale.pdfUrl);
    }
    navigation.back();

    if (AppEnvironment.ENVIRONMENT === "Staging") {
      //At staging after a sale is emitted, all bus procesos are started
      await apiServiceBus.stagingStartAll();
    }
  };

  const loadSale = (sale: SaleDto) => {
    const saleVM = sale as SaleViewModel;
    saleVM.accountCountry = loginInfo.authInfo.accountConfig.companyCountry;
    saleVM.autoRounding = loginInfo.authInfo.accountConfigPreferences.salesRounding ?? false;
    saleVM.enableLineDiscounts =
      loginInfo.authInfo.accountConfigPreferences.salesEnableLineDiscounts ?? false;
    saleVM.enableLineSurcharges =
      loginInfo.authInfo.accountConfigPreferences.salesEnableLineSurcharges ?? false;
    saleVM.enableLineRetentions =
      loginInfo.authInfo.accountConfigPreferences.salesEnableLineRetentions ?? false;

    if (saleVM.invoiceTypeGroup == InvoiceTypeGroup.EResguardo) {
      saleVM.enableLineRetentions = true;
    } else if (saleVM.invoiceTypeGroup == InvoiceTypeGroup.ERemito) {
      saleVM.enableLineRetentions = false;
    }

    if (saleVM.draftIsNotCurrentDate && utils.date.typeCheck(saleVM.date) == null) {
      saleVM.date = new Date();
    }

    if (saleVM.references?.length > 0) {
      saleVM.enableReferenceTable = true;
    } else if (
      sale.invoiceTypeGroup == InvoiceTypeGroup.ERemito ||
      sale.invoiceTypeGroup == InvoiceTypeGroup.EResguardo ||
      sale.isCancellation ||
      sale.isAdjustment
    ) {
      saleVM.enableReferenceTable = true;
      if (saleVM.invoiceTypeGroup == InvoiceTypeGroup.EResguardo) {
        saleVM.retentionsAddDefault = true;
      }

      const reference = saleVM.retentionsAddDefault ? getDefaultReference() : getEmptyReference();
      saleVM.references = [reference];
    }

    saleVM.editingLineIndex = null;
    if (!isMobile && saleVM.lines?.length == 0) {
      saleVM.lines.push(getNewLine(saleVM));
      saleVM.editingLineIndex = 0;
    }

    if (
      props.pageType == SalePageType.CreateSaleSenderInBehalf ||
      props.pageType == SalePageType.CancellationSenderInBehalf ||
      props.pageType == SalePageType.CreateAdjustmentSenderInBehalf
    ) {
      saleVM.isSaleSenderBehalf = true;
    }

    setSaleViewModel(saleVM);

    switch (saleVM.invoiceTypeGroup) {
      case InvoiceTypeGroup.ERemito:
        setTitle("Nuevo remito");
        break;
      case InvoiceTypeGroup.EResguardo:
        setTitle("Nuevo resguardo");
        break;
      case InvoiceTypeGroup.EFactura:
      case InvoiceTypeGroup.ETicket:
      case InvoiceTypeGroup.EBoleta:
        if (saleVM.isCancellation) {
          if (saleVM.saleInfo.isExport) {
            setTitle("Nueva nota de crédito de factura de exportación");
          } else if (saleVM.isSaleSenderBehalf) {
            setTitle("Nueva nota de crédito  por cuenta ajena");
          } else {
            setTitle("Nueva nota de crédito");
          }
          return;
        }

        if (saleVM.saleInfo.isExport) {
          setTitle("Nueva factura de exportación");
        } else if (saleVM.isSaleSenderBehalf) {
          setTitle("Nueva factura por cuenta ajena");
        } else {
          setTitle("Nueva factura");
        }
        break;
    }
  };

  useEffect(() => {
    switch (props.pageType) {
      case SalePageType.EditDraft: {
        const request = async () => loadSale(await apiSale.getById(id ?? ""));
        request();
        return;
      }
      case SalePageType.CreateClone: {
        const request = async () => loadSale(await apiSale.getToCreateClone(id ?? ""));
        request();
        return;
      }
      default: {
        const request = async () =>
          loadSale(
            await apiSale.getToCreate(
              getSaleCreateOptionsDto(
                props.pageType,
                loginInfo.authInfo.accountConfig,
                saleViewModel?.contact
              )
            )
          );
        request();
        return;
      }
    }
  }, []);

  return (
    <Page title={title}>
      {saleViewModel && (
        <StateProvider
          model={saleViewModel}
          validationSchema={useSaleValidation(loginInfo)}
          onSubmit={save}>
          {(p) => (
            <TaxValueProvider currentDate={saleViewModel.date}>
              <SaleForm pageType={props.pageType} onSubmit={p.handleSubmit} />
            </TaxValueProvider>
          )}
        </StateProvider>
      )}
    </Page>
  );
};

export default SalePage;
