import ValidationField from "./ValidationField";
import ValidationSchema from "./ValidationSchema";

export default class ValidationFieldSchema extends ValidationField {
  constructor(public nestedSchema: ValidationSchema, propertyFriendlyName?: string) {
    super(propertyFriendlyName);
  }
}

export function validateSchema(
  itemSchema: ValidationSchema,
  propertyFriendlyName: string
): ValidationFieldSchema {
  return new ValidationFieldSchema(itemSchema, propertyFriendlyName);
}
