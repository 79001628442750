import Icon, { TypeIcon } from "../../shared/components/Icon";
import Column from "../../shared/components/Layout/GColumn";
import Page from "../../shared/components/Layout/Page";
import Row from "../../shared/components/Layout/GRow";
import Section from "../../shared/components/Layout/GSection";
import { TextAlign } from "../../shared/TextAlign";
import { AppThemeColor } from "../../styles/color";

const AdminIcons = () => {
  return (
    <Page title="Íconos">
      {Object.keys(TypeIcon as any).map((t: any) => {
        return (
          <Column key={t} md={2}>
            <Section background={AppThemeColor.info}>
              <Row align={TextAlign.center}>
                <Icon type={TypeIcon[t as keyof typeof TypeIcon]} />
              </Row>
              <Row align={TextAlign.center}>{t}</Row>
            </Section>
          </Column>
        );
      })}
    </Page>
  );
};

export default AdminIcons;
