// File generated by Gesta.Build.PostEvents
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { FilterRangeDateDto } from "../../shared/dtos/FilterRangeDateDto";

export class UserFiltersDto extends FiltersDto {
  adminAccountId: string;
  adminAccountName: string = "";
  createdDate: FilterRangeDateDto;
  email: string = "";
  isOpenApiIntegration: boolean | null = null;
  lastLogin: FilterRangeDateDto;
}
