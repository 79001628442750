// File generated by Gesta.Build.PostEvents
import { InvoiceLineDto } from "./InvoiceLineDto";
import { SaleLineProductCodeDto } from "./SaleLineProductCodeDto";
import { SaleLineChargeDto } from "./SaleLineChargeDto";
import { SaleLineRetentionDto } from "./SaleLineRetentionDto";

export class SaleLineDto extends InvoiceLineDto {
  productCodes: SaleLineProductCodeDto[] = [];
  charges: SaleLineChargeDto[] = [];
  retentions: SaleLineRetentionDto[] = [];
}
