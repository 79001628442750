import { useEffect, useState } from "react";
import { AdminRetentionDto } from "../../api/admin/dtos/AdminRetentionDto";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminRetention } from "../../api/shared/hooks/useApiAdmin";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import AdminRetentionForm from "./AdminRetentionForm";
import { useAdminRetentionValidation } from "./Validations/useAdminRetentionValidation";

const AdminRetentionEditPopup = (props: { adminRetentionId: string }) => {
  const [adminRetention, setAdminRetention] = useState<AdminRetentionDto>();
  const [modal] = useModal();
  const [apiAdminRetention] = useApiAdminRetention();

  useEffect(() => {
    const loadAdminRetention = async () => {
      setAdminRetention(await apiAdminRetention.getById(props.adminRetentionId));
      modal.setVisible("Editar retención/percepción", ModalSize.small);
    };
    loadAdminRetention();
  }, [props.adminRetentionId, modal, apiAdminRetention]);

  const save = async (adminRetention: AdminRetentionDto) => {
    const created = await apiAdminRetention.edit(adminRetention);
    modal.closeAsSuccess(created, "La retención/percepción se editó con éxito");
  };

  if (!adminRetention) {
    return <></>;
  }

  return (
    <Container>
      <StateProvider
        model={adminRetention}
        onSubmit={save}
        validationSchema={useAdminRetentionValidation()}>
        {(p) => <AdminRetentionForm editMode={true} onSave={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default AdminRetentionEditPopup;
