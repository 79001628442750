import { Tooltip } from "@mui/material";
import { SearchResultItemDto } from "../../../api/shared/dtos/SearchResultItemDto";
import { enumName } from "../../../api/shared/enums/_enumName";
import useUtils from "../../hooks/useUtils";
import { DateFormatType } from "../../utils/localizedUtil/localizedUtilDates";
import { LocalizedUtils } from "../../utils/localizedUtil/localizedUtils";
import { getObjectValue } from "../../utils/utilObject";
import ButtonIcon from "../Button/ButtonIcon";
import { ContextMenuItem } from "../ContextMenu/ContextMenu";
import { TypeIcon } from "../Icon";
import IconCheck from "../IconCheck";
import InputCheck from "../inputs/InputCheck";
import StatusIconColor from "../StatusIconColor";
import StatusTextColor from "../StatusTextColor";
import GridButtonExpand from "./GridButtonExpand";
import GridButtonMenu from "./GridButtonMenu";
import {
  GridCellType,
  GridColumnActionType,
  IGridItemTemplate,
} from "./ItemTemplate/IGridItemTemplate";
import { useContextGrid } from "./useContextGrid";

const GridCellValue = (props: {
  isRowExpanded: boolean;
  template: IGridItemTemplate<any>;
  pagedResultItem: SearchResultItemDto<any>;
  onDisplayItemMenu?(item: any): ContextMenuItem[];
  onExpandRowShouldDisableButton?(item: any): boolean;
  onRowToggleExpand?(item: any): void;
  onSelect(item: any): void;
}) => {
  const grid = useContextGrid();

  if (props.template.actionType == GridColumnActionType.Selection) {
    return (
      <div className="value" style={props.template.cellStyle}>
        <InputCheck
          value={props.pagedResultItem.selected ?? false}
          onChange={() => props.onSelect(props.pagedResultItem)}
        />
      </div>
    );
  }

  if (props.template.actionType == GridColumnActionType.MenuActionButton) {
    if (!props.onDisplayItemMenu) {
      throw Error(
        "onDisplayItemMenu must be defined if actionType is MenuActionButton rendering cell value"
      );
    }
    return (
      <GridButtonMenu
        pagedResultItem={props.pagedResultItem}
        onDisplayItemMenu={props.onDisplayItemMenu}
      />
    );
  }

  if (props.template.actionType == GridColumnActionType.ExpandRowButton) {
    if (!props.onRowToggleExpand) {
      throw Error(
        "onRowToggleExpand must be defined if actionType is ExpandRowButton rendering cell value"
      );
    }

    const disabled = props.onExpandRowShouldDisableButton
      ? props.onExpandRowShouldDisableButton(props.pagedResultItem.item)
      : false;

    return (
      <GridButtonExpand
        isExpanded={props.isRowExpanded}
        onClick={props.onRowToggleExpand}
        disabled={disabled}
      />
    );
  }

  if (props.template.actionType == GridColumnActionType.CustomActionButton) {
    if (!props.template?.render) {
      throw Error("Render must be defined for GridColumnActionType.CustomActionButton");
    }
  }

  const content = props.template.render
    ? props.template?.render(props.pagedResultItem.item, grid)
    : renderByCellType(props.pagedResultItem.item, props.template);

  let tooltip = "";

  if (props.template.tooltip) {
    tooltip = props.template.tooltip(props.pagedResultItem.item);
  } else if (typeof content === "string") {
    tooltip = content as string;
  }

  return (
    <Tooltip title={tooltip}>
      <div className="value" style={props.template.cellStyle}>
        {content}
      </div>
    </Tooltip>
  );
};

export default GridCellValue;

function currencyValue(
  item: any,
  value: any,
  utils: LocalizedUtils,
  template: IGridItemTemplate<any>
) {
  const currencySymbol = template.cellTypeCurrencyField
    ? utils.enum.translate(enumName.Currency, getObjectValue(item, template.cellTypeCurrencyField))
    : "";
  return currencySymbol + " " + utils.number.toString(value);
}

function renderByCellType(item: any, template: IGridItemTemplate<any>): any {
  const value = getObjectValue<any>(item, template.field);
  const cellType = template.cellType ?? (template.cellTypeEnumName ? GridCellType.enum : undefined);
  const { utils } = useUtils();

  if (cellType == undefined) {
    return value;
  }

  switch (cellType) {
    case GridCellType.date:
      return utils.date.toString(value);
    case GridCellType.dateTime:
      return utils.date.toString(value, DateFormatType.DateHourMinutes);
    case GridCellType.dateTimeWithSeconds:
      return utils.date.toString(value, DateFormatType.DateHourMinutesSeconds);
    case GridCellType.timeHourSecondsMs:
      return utils.date.toString(value, DateFormatType.HourMinutesSecondsMs);
    case GridCellType.dateOnlyLocal:
      return utils.date.dateOnlyToString(value);
    case GridCellType.decimal:
      return utils.number.toString(value, template.cellTypeDecimals);
    case GridCellType.integer:
      return utils.number.toString(value, 0);
    case GridCellType.enum:
      return utils.enum.translate(template.cellTypeEnumName ?? "", value);
    case GridCellType.iconCheck:
      return <IconCheck value={value} />;
    case GridCellType.statusWithColor:
      if (!template.cellTypeMapColor) {
        throw Error(
          "For cellType statusWithColor property cellTypeMapColor is mandatory to map status with proper color"
        );
      }
      return (
        <StatusTextColor
          enumKey={value}
          enumType={template.cellTypeEnumName ?? ""}
          color={template.cellTypeMapColor}
        />
      );
    case GridCellType.statusWithIcon:
      if (!template.cellTypeMapIcon) {
        throw Error(
          "For cellType statusWithIcon property cellTypeMapIconColor is mandatory to map status with proper icon"
        );
      }
      return (
        <StatusIconColor
          enumKey={value}
          enumType={template.cellTypeEnumName ?? ""}
          mapper={template.cellTypeMapIcon}
        />
      );
    case GridCellType.currencyValue:
      return <>{currencyValue(item, value, utils, template)}</>;
    case GridCellType.download:
      return value ? (
        <ButtonIcon
          icon={TypeIcon.download}
          tooltip="Descargar"
          noPadding={true}
          onClick={() => {
            window.open(value);
          }}
        />
      ) : null;
  }
}
