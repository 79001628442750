import { SearchResultItemDto } from "../../../api/shared/dtos/SearchResultItemDto";
import { ContextMenuItem } from "../ContextMenu/ContextMenu";
import GridRowCell from "./GridRow.Cell";
import { IGridItemTemplate } from "./ItemTemplate/IGridItemTemplate";
import { useContextGrid } from "./useContextGrid";

const GridRowColumns = (props: {
  isRowExpanded: boolean;
  item: SearchResultItemDto<any>;
  onDisplayItemMenu?(item: any): ContextMenuItem[];
  onExpandRowShouldDisableButton?(item: any): boolean;
  onRowToggleExpand?(item: any): void;
  onSelect(item: any): void;
}) => {
  const grid = useContextGrid();

  const totalWidth = grid.gridState.visibleColumns
    .map((c) => c.width)
    .reduce((sum, current) => sum + current);

  return (
    <>
      {grid.gridState.visibleColumns.map(
        (itemTemplate: IGridItemTemplate<any>, cellIndex: number) => (
          <GridRowCell
            key={cellIndex}
            isRowExpanded={props.isRowExpanded}
            maxHeightIgnore={itemTemplate.maxHeightIgnore}
            template={itemTemplate}
            width={(itemTemplate.width / totalWidth) * 100}
            pagedResultItem={props.item}
            onDisplayItemMenu={props.onDisplayItemMenu}
            onExpandRowShouldDisableButton={props.onExpandRowShouldDisableButton}
            onRowToggleExpand={props.onRowToggleExpand}
            onSelect={props.onSelect}
          />
        )
      )}
    </>
  );
};

export default GridRowColumns;
