import { ButtonLink } from "../../../shared/components/Button/ButtonLink";

const LoginLink = (props: { text: string; onClick(): void }) => {
  return (
    <ButtonLink
      text={props.text}
      styles={{ fontSize: 14 }}
      onClick={props.onClick}
    />
  );
};

export default LoginLink;
