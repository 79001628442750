// File generated by: Gesta.Build.PostEvents
import { useState } from "react";
import { useApi } from "../../../shared/hooks/useApi";
import { ApiCrossShardAccountBranch } from "../../admin/apis/ApiCrossShardAccountBranch";
import { ApiCrossShardEInvoiceDailyReport } from "../../admin/apis/ApiCrossShardEInvoiceDailyReport";
import { ApiCrossShardPointOfSale } from "../../admin/apis/ApiCrossShardPointOfSale";

export function useApiCrossShardAccountBranch() {
  return useState(new ApiCrossShardAccountBranch(useApi()));
}

export function useApiCrossShardEInvoiceDailyReport() {
  return useState(new ApiCrossShardEInvoiceDailyReport(useApi()));
}

export function useApiCrossShardPointOfSale() {
  return useState(new ApiCrossShardPointOfSale(useApi()));
}

