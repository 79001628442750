import { SaleDto } from "../../api/app/dtos/SaleDto";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiSale } from "../../api/shared/hooks/useApiApp";
import ButtonFileDownload from "../../shared/components/Button/ButtonFileDownload";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../shared/components/Icon";
import Column from "../../shared/components/Layout/GColumn";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";
import ModalDialog from "../../shared/components/Modal/ModalDialog";
import { useModal } from "../../shared/hooks/useModal";
import { useNavigation } from "../../shared/hooks/useNavigation";
import useUtils from "../../shared/hooks/useUtils";

const SaleDetailsPopupGeneral = (props: { sale: SaleDto }) => {
  const { utils } = useUtils();
  const sale = props.sale;
  const [modal] = useModal();
  const navigation = useNavigation();
  const [apiSale] = useApiSale();

  return (
    <>
      <ColumnDisplay label="Tipo">
        {utils.enum.translate(enumName.InvoiceType, sale.invoiceType)}
      </ColumnDisplay>
      <ColumnDisplay label="Número">
        {sale.serie} {sale.number}
      </ColumnDisplay>
      <Row>
        <Container>
          {sale.taxes.map((c, index) => (
            <Column isDisplay={true} key={index} label={utils.enum.translate(enumName.Tax, c.tax)}>
              Gravado {utils.currency.toString(sale.currency, c.linesTotalAmount)} <br />
              Monto {utils.currency.toString(sale.currency, c.amount)}
            </Column>
          ))}
        </Container>
      </Row>
      <RowButtons>
        <ButtonSecondary
          icon={TypeIcon.refresh}
          text="Regenerar PDF"
          onClick={() => {
            modal.open(
              <ModalDialog
                text="¿Estás seguro de regenerar el PDF?"
                helpText="Únicamente utilice esta función en caso de algún error debido a un cambio reciente en una plantilla. No se enviarán las notificaciones automáticamente."
              />,
              () => {
                const request = async () => {
                  const url = await apiSale.pdfRegenerate(sale.id);
                  navigation.newTab(url);
                  modal.closeAsSuccess(null, "PDF regenerado correctamente");
                };
                request();
              }
            );
          }}
        />
        <ButtonFileDownload icon={TypeIcon.pdf} text="Descargar PDF" fileUrl={props.sale.pdfUrl} />
      </RowButtons>
    </>
  );
};

export default SaleDetailsPopupGeneral;
