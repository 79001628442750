import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import { InvoiceTypeGroup } from "../../api/shared/enums/InvoiceTypeGroup";
import { enumName } from "../../api/shared/enums/_enumName";
import Row from "../../shared/components/Layout/GRow";
import RowTitle from "../../shared/components/Layout/GRowTitle";
import Section from "../../shared/components/Layout/GSection";
import useUtils from "../../shared/hooks/useUtils";
import PurchaseDetailsColumn from "./PurchaseDetails.Column";
import PurchaseDetailsStatusResponses from "./PurchaseDetails.StatusResponses";

const PurchaseDetailsMoreInfo = (props: { purchase: PurchaseDto }) => {
  const { utils } = useUtils();
  const purchase = props.purchase;

  return (
    <>
      <PurchaseDetailsColumn label="Número interno proveedor">
        {purchase.senderInternalNumber}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Período desde">
        {utils.date.toString(purchase.purchaseInfo.datePeriodSince)}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Período hasta">
        {utils.date.toString(purchase.purchaseInfo.datePeriodTo)}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Indicador de montos">
        {utils.enum.translate(
          enumName.EInvoiceAmountIndicatorType,
          purchase.purchaseInfo.amountIndicatorType
        )}
      </PurchaseDetailsColumn>
      {purchase.invoiceTypeGroup == InvoiceTypeGroup.EResguardo && (
        <PurchaseDetailsColumn label="Fecha retención/percepción">
          {utils.date.toString(purchase.purchaseInfo.retentionDate)}
        </PurchaseDetailsColumn>
      )}
      <PurchaseDetailsColumn label="Cláusula de venta">
        {purchase.purchaseInfo.saleClause}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Modalidad de venta">
        {utils.enum.translate(enumName.EInvoiceSaleMode, purchase.purchaseInfo.saleMode)}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Vía de transporte">
        {utils.enum.translate(
          enumName.EInvoiceTransportationType,
          purchase.purchaseInfo.transportationType
        )}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Información adicional">
        {purchase.purchaseInfo.extraInfo}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Secreto profesional">
        {utils.enum.translate(
          enumName.EInvoiceProfessionalSecret,
          purchase.purchaseInfo.professionalSecret
        )}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="IVA al día">
        {!purchase.purchaseInfo.senderTaxPaymentExpired ? "Sí" : "No"}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Es pago por cuenta de terceros">
        {purchase.purchaseInfo.isThirdPartyPayment ? "Sí" : "-"}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Es cobranza propia">
        {purchase.purchaseInfo.isOwnCollect ? "Sí" : "-"}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Indicador compra M/E para su reventa">
        {purchase.purchaseInfo.isForForeignExchangeRetail ? "Sí" : "-"}
      </PurchaseDetailsColumn>
      <PurchaseDetailsColumn label="Tipo de transporte">
        {utils.enum.translate(
          enumName.EInvoiceTransportationIndicatorType,
          purchase.purchaseInfo.transportationIndicatorType
        )}
      </PurchaseDetailsColumn>
      {purchase.invoiceTypeGroup == InvoiceTypeGroup.ERemito && (
        <Row>
          <Section title="Propietario mercadería">
            <PurchaseDetailsColumn label="Es de terceros">
              {purchase.purchaseInfo.isThirdPartyAssets ? "Sí" : ""}
            </PurchaseDetailsColumn>
            <PurchaseDetailsColumn label="Tipo documento">
              {utils.enum.translate(
                enumName.IdCardType,
                purchase.purchaseInfo.assetsOwnerIdCardType
              )}
            </PurchaseDetailsColumn>
            <PurchaseDetailsColumn label="Número documento">
              {purchase.purchaseInfo.assetsOwnerIdCardNumber}
            </PurchaseDetailsColumn>
            <PurchaseDetailsColumn label="País">
              {utils.enum.translate(enumName.Country, purchase.purchaseInfo.assetsOwnerCountry)}
            </PurchaseDetailsColumn>
            <PurchaseDetailsColumn label="Nombre">
              {purchase.purchaseInfo.assetsOwnerName}
            </PurchaseDetailsColumn>
          </Section>
        </Row>
      )}
      <RowTitle>Adenda</RowTitle>
      <Row>{props.purchase.purchaseInfo.addendum}</Row>
      <PurchaseDetailsStatusResponses purchase={props.purchase} />
    </>
  );
};

export default PurchaseDetailsMoreInfo;
