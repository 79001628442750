// File generated by: Gesta.Build.PostEvents
export enum EInvoiceEnvelopeStatus {
	NotApply = 1,
	Pending = 2,
	Sent = 3,
	ErrorSending = 4,
	SentAck = 5,
	Accepted = 6,
	Rejected = 7,
	VerificationError = 8,
	VerificationTimeout = 9,
}
