import { SearchResultItemDto } from "../../../api/shared/dtos/SearchResultItemDto";
import { ContextMenuItem } from "../ContextMenu/ContextMenu";
import GridRowCellValue from "./GridRow.CellValue";
import { getGridColumnAlign, IGridItemTemplate } from "./ItemTemplate/IGridItemTemplate";

const GridRowCell = (props: {
  isRowExpanded: boolean;
  maxHeightIgnore?: boolean;
  pagedResultItem: SearchResultItemDto<any>;
  template: IGridItemTemplate<any>;
  width: number;
  onDisplayItemMenu?(item: any): ContextMenuItem[];
  onExpandRowShouldDisableButton?(item: any): boolean;
  onRowToggleExpand?(item: any): void;
  onSelect(item: any): void;
}) => {
  return (
    <div
      style={{
        width: props.width + "%",
        minWidth: props.template.actionType ? 30 : undefined,
        textAlign: getGridColumnAlign(props.template),
        paddingTop: props.template.noPaddingY ? "0" : "",
        paddingBottom: props.template.noPaddingY ? "0" : "",
        maxHeight: props.maxHeightIgnore ? "none" : undefined,
        height: props.maxHeightIgnore ? "auto" : undefined,
      }}
      className={"grid-cell " + (props.template.className ?? "")}>
      <GridRowCellValue
        isRowExpanded={props.isRowExpanded}
        template={props.template}
        pagedResultItem={props.pagedResultItem}
        onDisplayItemMenu={props.onDisplayItemMenu}
        onExpandRowShouldDisableButton={props.onExpandRowShouldDisableButton}
        onRowToggleExpand={props.onRowToggleExpand}
        onSelect={props.onSelect}
      />
    </div>
  );
};

export default GridRowCell;
