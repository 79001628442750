import { CSSProperties } from "react";
import { useAppTheme } from "../../AppThemeProvider";
import { AppThemeColor } from "../../styles/color";
import { AppSize } from "../AppSize";
import Icon from "./Icon";

const IconText = (props: { text: string; icon: any; color?: AppThemeColor }) => {
  const appTheme = useAppTheme();

  const containerStyle: CSSProperties = {
    display: "flex",
  };
  return (
    <div style={containerStyle}>
      <Icon type={props.icon} color={props.color} size={AppSize.small} />
      <div
        style={{
          flex: 1,
          marginTop: 2,
          marginLeft: 4,
          color: appTheme.toHexColor(props.color),
          fontSize: "1.1em",
        }}>
        {props.text}
      </div>
    </div>
  );
};

export default IconText;
