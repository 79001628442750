import { nameof } from "ts-simple-nameof";
import { SaleLineRetentionDto } from "../../api/app/dtos/SaleLineRetentionDto";
import FormNumber from "../../shared/components/forms/FormNumber";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import { ISaleLineRetentionColumnRenderProps } from "./SaleFormLines";

const SaleLineRetentionColumnAmount = (props: ISaleLineRetentionColumnRenderProps) => {
  const saleModelState = useContextModelStateSale();
  return (
    <FormNumber
      arrayItemHelper={props.arrayItemHelper}
      showZeroAsEmpty={true}
      label={props.showLabel ? props.saleLineRetentionColumn.header : ""}
      variant={props.variant}
      propertyName={nameof<SaleLineRetentionDto>((p) => p.amount)}
      onValueChange={() =>
        saleModelState.onLineRetentionAmountChange(props.lineIndex, props.arrayItemHelper.index)
      }
    />
  );
};

export default SaleLineRetentionColumnAmount;
