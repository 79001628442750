import { useEffect, useState } from "react";
import { UserCrudDto } from "../../api/admin/dtos/UserCrudDto";
import { UserDto } from "../../api/admin/dtos/UserDto";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import { useApiAdminProviderUser } from "../../api/shared/hooks/useApiAdminProviders";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import UserForm from "./UserForm";
import { useUserCrudValidation } from "./Validations/useUserCrudValidation";

const UserCreate = () => {
  const [modal] = useModal();
  const [apiUser] = useApiAdminProviderUser();
  const [user] = useState(() => {
    const crud = new UserCrudDto();
    crud.user = new UserDto();
    return crud;
  });

  const save = async (user: UserCrudDto) => {
    const created = await apiUser.create(user);
    modal.closeAsSuccess(created, "El usuario se creó con éxito");
  };

  useEffect(() => modal.setVisible("Crear usuario", ModalSize.small), []);

  if (!user) {
    return <></>;
  }

  return (
    <Container>
      <StateProvider model={user} onSubmit={save} validationSchema={useUserCrudValidation()}>
        {(p) => <UserForm onSave={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default UserCreate;
