import { nameof } from "ts-simple-nameof";
import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import { PurchaseLineDto } from "../../api/app/dtos/PurchaseLineDto";
import { InvoiceChargeType } from "../../api/shared/enums/InvoiceChargeType";
import { enumName } from "../../api/shared/enums/_enumName";
import { GridColumnAlign } from "../../shared/components/grid/Grid";
import GridClientSide from "../../shared/components/grid/GridClientSide";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import ColumnDisplay from "../../shared/components/Layout/GColumnDisplay";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import RowTitle from "../../shared/components/Layout/GRowTitle";
import useUtils from "../../shared/hooks/useUtils";

const PurchaseDetailsLines = (props: { purchase: PurchaseDto }) => {
  const { utils } = useUtils();

  const templates: IGridItemTemplate<PurchaseLineDto>[] = [
    { header: "Detalle", field: nameof<PurchaseLineDto>((p) => p.lineDetail), width: 30 },
    {
      header: "Precio unitario",
      field: nameof<PurchaseLineDto>((p) => p.unitPrice),
      cellType: GridCellType.decimal,
      align: GridColumnAlign.right,
      width: 10,
    },
    {
      header: "Cantidad",
      field: nameof<PurchaseLineDto>((p) => p.quantity),
      cellType: GridCellType.decimal,
      align: GridColumnAlign.right,
      width: 10,
    },
    {
      header: "Unidad de medida",
      field: nameof<PurchaseLineDto>((p) => p.measureUnitCode),
      width: 10,
    },
    {
      header: "Descuento",
      field: nameof<PurchaseLineDto>((p) => p.discountAmount),
      cellType: GridCellType.decimal,
      align: GridColumnAlign.right,
      width: 10,
    },
    {
      header: "Recargo",
      field: nameof<PurchaseLineDto>((p) => p.surchargeAmount),
      cellType: GridCellType.decimal,
      align: GridColumnAlign.right,
      width: 10,
    },
    {
      header: "Subtotal",
      field: nameof<PurchaseLineDto>((p) => p.subtotal),
      cellType: GridCellType.decimal,
      align: GridColumnAlign.right,
      width: 10,
    },
    {
      header: "Total",
      field: nameof<PurchaseLineDto>((p) => p.calculatedTotal),
      cellType: GridCellType.decimal,
      align: GridColumnAlign.right,
      width: 10,
    },
  ];

  const expandRow = async (line: PurchaseLineDto) => {
    return (
      <Row>
        <Container>
          <ColumnDisplay label="Descripción">{line.description}</ColumnDisplay>
          <ColumnDisplay label="Indicador de facturación">
            {utils.enum.translate(
              enumName.EInvoiceBillingIndicatorType,
              line.eInvoiceBillingIndicatorType
            )}
          </ColumnDisplay>
          <ColumnDisplay md={2} label="Agente responsable">
            {utils.enum.translate(
              enumName.EInvoiceAgentIndicatorType,
              line.eInvoiceAgentIndicatorType
            )}
          </ColumnDisplay>
          <ColumnDisplay md={2} label="NCM">
            {line.tariffPositionCode}
          </ColumnDisplay>
          {line.productCodes?.length > 0 && (
            <Row label="Códigos de producto">
              {line.productCodes.map((code, ind) => (
                <Container key={ind}>
                  <ColumnDisplay label="Tipo">{code.codeType}</ColumnDisplay>
                  <ColumnDisplay label="Código">{code.code}</ColumnDisplay>
                </Container>
              ))}
            </Row>
          )}
          {line.charges?.length > 0 && (
            <Row label="Descuentos">
              {line.charges
                .filter((c) => c.type == InvoiceChargeType.Discount)
                .map((charge, ind) => (
                  <Container key={ind}>
                    <ColumnDisplay label="Descuento">
                      {(charge.isPercentage
                        ? "%"
                        : utils.enum.translate(enumName.Currency, props.purchase.currency)) +
                        " " +
                        utils.number.toString(charge.amount)}
                    </ColumnDisplay>
                  </Container>
                ))}
            </Row>
          )}
          {line.charges?.length > 0 && (
            <Row label="Recargos">
              {line.charges
                .filter((c) => c.type == InvoiceChargeType.Surcharge)
                .map((charge, ind) => (
                  <Container key={ind}>
                    <ColumnDisplay label="Recargo">
                      {(charge.isPercentage
                        ? "%"
                        : utils.enum.translate(enumName.Currency, props.purchase.currency)) +
                        " " +
                        utils.number.toString(charge.amount)}
                    </ColumnDisplay>
                  </Container>
                ))}
            </Row>
          )}
          {line.retentions?.length > 0 && (
            <Row label="Retenciones">
              {line.retentions.map((retention, ind) => (
                <Container key={ind}>
                  <ColumnDisplay label="Código">{retention.code}</ColumnDisplay>
                  <ColumnDisplay label="Tasa">
                    {utils.number.toString(retention.rate)}
                  </ColumnDisplay>
                  <ColumnDisplay label="Monto sujeto">
                    {utils.number.toString(retention.totalAmount)}
                  </ColumnDisplay>
                  <ColumnDisplay label="Info">{retention.detail}</ColumnDisplay>
                  <ColumnDisplay label="Monto">
                    {utils.number.toString(retention.amount)}
                  </ColumnDisplay>
                </Container>
              ))}
            </Row>
          )}
        </Container>
      </Row>
    );
  };

  return (
    <>
      <RowTitle>Productos</RowTitle>
      <Row>
        <GridClientSide
          hideFooter={true}
          items={props.purchase.lines}
          templates={templates}
          onExpandRow={expandRow}
        />
      </Row>
    </>
  );
};

export default PurchaseDetailsLines;
