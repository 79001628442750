import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import { PurchaseFiltersDto } from "../../api/app/dtos/PurchaseFiltersDto";
import { GridName } from "../../api/shared/enums/GridName";
import { enumName } from "../../api/shared/enums/_enumName";
import { useApiExportPurchase, useApiPurchase } from "../../api/shared/hooks/useApiApp";
import { AppRoute } from "../../AppRoutes";
import { ButtonCreate } from "../../shared/components/Button/ButtonCreate";
import Grid, { GridColumnAlign } from "../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
  MobileSpecialType,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../shared/components/Layout/Page";
import { useModal } from "../../shared/hooks/useModal";
import { useNavigation } from "../../shared/hooks/useNavigation";
import GridCellContact from "../Contact/GridCellContact";
import { getPurchaseListItemMenu } from "./PurchaseList.ItemMenu";

const PurchaseList = () => {
  const navigation = useNavigation();
  const [apiPurchase] = useApiPurchase();
  const [apiExportPurchase] = useApiExportPurchase();
  const [modal] = useModal();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [initialFilters] = useState(() => {
    const filters = new PurchaseFiltersDto();
    filters.onlyValidStatus = true;
    return filters;
  });

  const itemTemplates: IGridItemTemplate<PurchaseDto>[] = [
    {
      header: "Fecha",
      field: nameof<PurchaseDto>((p) => p.date),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.operationDate),
        type: GridFilterType.dateTime,
      },
      width: 10,
      cellType: GridCellType.date,
    },
    {
      header: "Fecha de creación",
      field: nameof<PurchaseDto>((p) => p.createdDate),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.createdDate),
        type: GridFilterType.dateTime,
      },
      width: 10,
      hideByDefault: true,
      cellType: GridCellType.dateTime,
    },
    {
      header: "Tipo",
      field: nameof<PurchaseDto>((p) => p.invoiceType),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.invoiceTypeList),
        type: GridFilterType.enumList,
        enumName: enumName.InvoiceType,
      },
      width: 10,
      cellTypeEnumName: enumName.InvoiceType,
    },
    {
      header: "Número",
      field: nameof<PurchaseDto>((p) => p.serieAndNumber),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.number),
        type: GridFilterType.numberRangeInteger,
      },
      mobileSpecialType: MobileSpecialType.Main,
      orderBy: nameof<PurchaseDto>((p) => p.serie) + " " + nameof<PurchaseDto>((p) => p.number),
      width: 10,
    },
    {
      field: nameof<PurchaseDto>((p) => p.sender.taxPayerId),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.taxPayerId),
        type: GridFilterType.text,
      },
      header: "RUT Proveedor",
      width: 10,
    },
    {
      field: nameof<PurchaseDto>((p) => p.sender.displayBothNames),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.contact),
        type: GridFilterType.providers,
      },
      orderBy:
        nameof<PurchaseDto>((p) => p.sender.comercialName) +
        " " +
        nameof<PurchaseDto>((p) => p.sender.companyName),
      header: "Proveedor",
      width: 30,
      render: (item: PurchaseDto) => {
        return (
          <GridCellContact
            contactId={item.contactId}
            contactName={item.contact?.name ?? item.sender?.displayBothNames}
            taxPayerId={item.sender.taxPayerId}
            onContactCreated={() => {
              setReloadGrid(new Date());
            }}
          />
        );
      },
    },
    {
      header: "Moneda",
      field: nameof<PurchaseDto>((p) => p.currency),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.currency),
        type: GridFilterType.currency,
      },
      width: 5,
      cellTypeEnumName: enumName.Currency,
    },
    {
      header: "A pagar",
      field: nameof<PurchaseDto>((p) => p.totalToPay),
      filter: {
        propertyName: nameof<PurchaseFiltersDto>((p) => p.totalToPay),
        type: GridFilterType.numberRange,
      },
      width: 10,
      align: GridColumnAlign.right,
      cellStyle: { fontWeight: 600 },
      cellType: GridCellType.decimal,
    },
  ];

  return (
    <Page
      title="Compras"
      titleButton={
        <ButtonCreate
          text="Ver CFE recibidos"
          onClick={() => navigation.go(AppRoute.purchases.eInvoicePendingApprove)}
        />
      }>
      <Grid
        hideIncludeInactiveButton={true}
        itemTemplates={itemTemplates}
        defaultSortBy={[{ columnName: nameof<PurchaseDto>((p) => p.date), asc: false }]}
        mustIncludeFields={[nameof<PurchaseDto>((p) => p.eInvoiceEnvelopePurchase.xmlUrl)]}
        initialFilters={initialFilters}
        gridName={GridName.EInvoicePurchases}
        reloadGrid={reloadGrid}
        onSearch={(search, options) => apiPurchase.getPaged(search, options)}
        onExport={(search, options) => apiExportPurchase.default(search as any, options)}
        onDisplayItemMenu={(PurchaseDto: PurchaseDto) =>
          getPurchaseListItemMenu(PurchaseDto, modal, () => setReloadGrid(new Date()))
        }
      />
    </Page>
  );
};

export default PurchaseList;
