import { CSSProperties } from "react";
import { useContextAppInfo } from "../hooks/useContextAppInfo";
import { useIsMobile } from "../hooks/useIsMobile";

const AdminProviderLogoLanding = () => {
  const isMobile = useIsMobile();
  const { appInfo } = useContextAppInfo();
  const style: CSSProperties = {
    width: isMobile ? 120 : 180,
    display: "block",
    margin: "auto",
    marginBottom: 30,
  };

  if (!appInfo?.adminProviderInfo?.settings) {
    return <></>;
  }

  return (
    <img
      alt={appInfo.adminProviderInfo.name}
      style={style}
      src={
        isMobile
          ? appInfo.adminProviderInfo.settings.logoIsoTypeWhiteUrl
          : appInfo.adminProviderInfo.settings.logoUrl
      }
    />
  );
};

export default AdminProviderLogoLanding;
