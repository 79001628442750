import Row from "../Layout/GRow";
import ErrorPageLayout from "./ErrorPageLayout";

const UnauthorizedArea = () => {
  return (
    <ErrorPageLayout>
      <Row>No tienes permisos para acceder a esta area restringida!!</Row>
    </ErrorPageLayout>
  );
};

export default UnauthorizedArea;
