import { nameof } from "ts-simple-nameof";
import { AdminTemplatePdfDto } from "../../../api/admin/dtos/AdminTemplatePdfDto";
import { PaperSize } from "../../../api/shared/enums/PaperSize";
import { enumName } from "../../../api/shared/enums/_enumName";
import FormNumber from "../../../shared/components/forms/FormNumber";
import FormSwitch from "../../../shared/components/forms/FormSwitch";
import FormSelectEnum from "../../../shared/components/forms/Select/FormSelectEnum";
import ComponentGroup from "../../../shared/components/Layout/ComponentsGroup";
import Column from "../../../shared/components/Layout/GColumn";
import Row from "../../../shared/components/Layout/GRow";
import Section from "../../../shared/components/Layout/GSection";
import { useContextModelState } from "../../../shared/modelState/useContextModelState";

const TemplatePdfFormSettings = () => {
  const modelState = useContextModelState();
  const pdfTemplate = modelState.model as AdminTemplatePdfDto;

  return (
    <Row>
      <Section title="Opciones PDF" border={true}>
        <Column md={2}>
          <FormSelectEnum
            label="Orientación"
            propertyName={nameof<AdminTemplatePdfDto>((p) => p.orientation)}
            enumTypeName={enumName.PaperOrientation}
          />
        </Column>
        <Column md={2}>
          <FormSelectEnum
            label="Tamaño de hoja"
            propertyName={nameof<AdminTemplatePdfDto>((p) => p.paperSize)}
            enumTypeName={enumName.PaperSize}
          />
        </Column>
        {pdfTemplate.paperSize == PaperSize.Custom && (
          <Column md={2}>
            <ComponentGroup>
              <FormNumber
                label="Ancho (mm)"
                propertyName={nameof<AdminTemplatePdfDto>((p) => p.paperCustomWidth)}
              />
              <FormNumber
                label="Alto (mm)"
                propertyName={nameof<AdminTemplatePdfDto>((p) => p.paperCustomHeight)}
              />
            </ComponentGroup>
          </Column>
        )}
        <Column md={2}>
          <FormSwitch
            label="Mostrar número de página"
            propertyName={nameof<AdminTemplatePdfDto>((p) => p.displayPageNumber)}
          />
        </Column>
        <Column md={2}>
          <FormSwitch
            label="Escala de grises"
            propertyName={nameof<AdminTemplatePdfDto>((p) => p.isGrayScale)}
          />
        </Column>
      </Section>
    </Row>
  );
};

export default TemplatePdfFormSettings;
