import { TypeIcon } from "../Icon";
import ButtonSecondary from "./ButtonSecondary";

const ButtonFileDownload = (props: { icon?: any; fileUrl: string; text: string }) => {
  return (
    <ButtonSecondary
      disabled={!props.fileUrl}
      icon={props.icon ?? TypeIcon.download}
      text={props.text}
      onClick={() => {
        if (props.fileUrl) {
          window.open(props.fileUrl);
        }
      }}
    />
  );
};

export default ButtonFileDownload;
