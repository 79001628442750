import { GridSettingsDto } from "../../../api/app/dtos/GridSettingsDto";
import { GridName } from "../../../api/shared/enums/GridName";
import { GridSettingType } from "../../../api/shared/enums/GridSettingType";

const gridSettingsStorage = {} as any;

function getKey(name: GridName, type: GridSettingType) {
  return name + "_" + type;
}

export function gridSettingsStorageGet(name: GridName, type: GridSettingType): GridSettingsDto {
  return gridSettingsStorage[getKey(name, type)];
}

export function gridSettingsStorageSet(gridSettingsDto: GridSettingsDto) {
  gridSettingsStorage[getKey(gridSettingsDto.name, gridSettingsDto.type)] = gridSettingsDto;
}

export function gridSettingsStorageRemove(gridSettingsDto: GridSettingsDto) {
  delete gridSettingsStorage[getKey(gridSettingsDto.name, gridSettingsDto.type)];
}
