import { useContext } from "react";
import { ErrorResultDto } from "../../api/shared/dtos/ErrorResultDto";
import { Constants } from "../../Constants";
import {
  NotificationActionType,
  NotificationsContextSet,
  NotificationType,
} from "../components/Notifications/NotificationsProvider";

export interface Notifier {
  showError: (error: ErrorResultDto, title?: string) => void;
  showErrorMessage: (message: string, title?: string) => void;
  showInfo: (message: string, title?: string) => void;
  showSuccess: (message: string, title?: string) => void;
  showSuccessFast: (message: string, title?: string) => void;
  showWarning: (message: string, title?: string) => void;
}

export function useNotifier(): Notifier {
  const { notificationDispatcher } = useContext(NotificationsContextSet);

  const callDispatcher = (
    message: string,
    type: NotificationType,
    title?: string,
    errorResult?: ErrorResultDto,
    closeSeconds?: number
  ) => {
    notificationDispatcher({
      type: NotificationActionType.showNotification,
      notification: {
        title: title,
        message: message,
        type: type,
        closeSeconds: closeSeconds,
        errorResult: errorResult,
      },
    });
  };

  const showError = (error: ErrorResultDto, title?: string) => {
    callDispatcher(error.message, NotificationType.error, title, error);
  };

  const showErrorMessage = (message: string, title?: string) => {
    callDispatcher(message, NotificationType.error, title);
  };

  const showInfo = (message: string, title?: string) => {
    callDispatcher(message, NotificationType.info, title);
  };

  const showSuccess = (message: string, title?: string) => {
    callDispatcher(message, NotificationType.success, title);
  };

  const showSuccessFast = (message: string, title?: string) => {
    callDispatcher(
      message,
      NotificationType.success,
      title,
      undefined,
      Constants.NOTIFICATION_FAST_SECONDS
    );
  };

  const showWarning = (message: string, title?: string) => {
    callDispatcher(message, NotificationType.warning, title);
  };

  return { showError, showErrorMessage, showInfo, showSuccess, showSuccessFast, showWarning };
}
