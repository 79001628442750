import { ApiVaultCertificate } from "../../../api/app/apis/ApiVaultCertificate";
import { VaultCertificateDto } from "../../../api/app/dtos/VaultCertificateDto";
import { ContextMenuItem } from "../../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../../shared/components/Icon";
import ModalDialog from "../../../shared/components/Modal/ModalDialog";
import { ModalService } from "../../../shared/hooks/useModal";
import VaultCertificateDetailsPopup from "./VaultCertificateDetailsPopup";

export default function getVaultCertificateListItemMenu(
  vaultCertificate: VaultCertificateDto,
  apiVaultCertificate: ApiVaultCertificate,
  modal: ModalService,
  onGridReload: () => void
): ContextMenuItem[] {
  const menu: ContextMenuItem[] = [
    {
      icon: TypeIcon.details,
      text: "Detalles",
      onClick: () =>
        modal.open(
          <VaultCertificateDetailsPopup id={vaultCertificate.id} />,
          onGridReload
        ),
    },
    {
      icon: TypeIcon.check,
      text: "Por defecto",
      hide: vaultCertificate.isDefault,
      onClick: () =>
        modal.open(
          <ModalDialog text="¿Usar este certificado por defecto?" />,
          () => {
            const setAsDefault = async () => {
              await apiVaultCertificate.setAsDefault(vaultCertificate.id);
              onGridReload();
            };
            setAsDefault();
          }
        ),
    },
  ];
  return menu;
}
