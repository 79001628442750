import ValidationResultBase from "./ValidationResultBase";

export default class ValidationResultField extends ValidationResultBase {
  isTouched: boolean;
  trySubmitCount: number;

  getTouchedValidation(propertyFullName: string): string {
    if (propertyFullName) {
      throw Error(
        "PropertyFullName at ValidationResultField for getTouchedValidation should be empty: " +
          propertyFullName
      );
    }

    return (this.isTouched || this.trySubmitCount > 0) && this.validations.length > 0
      ? this.validations[0].message
      : "";
  }
}
