// File generated by Gesta.Build.PostEvents
import { BaseCrossShardEntityDto } from "../../shared/dtos/BaseCrossShardEntityDto";
import { AddressDto } from "./AddressDto";
import { EInvoiceAccountBranchInfoDto } from "./EInvoiceAccountBranchInfoDto";
import { PointOfSaleDto } from "./PointOfSaleDto";

export class AccountBranchDto extends BaseCrossShardEntityDto {
  address: AddressDto;
  branchName: string = "";
  branchCode: string = "";
  displayName: string = "";
  isDefault: boolean = false;
  eInvoiceInfoHistory: EInvoiceAccountBranchInfoDto[] = [];
  pointOfSaleList: PointOfSaleDto[] = [];
}
