import { CSSProperties } from "react";
import { AdminAccountSimpleDto } from "../../api/shared/dtos/AdminAccountSimpleDto";
import { useAppTheme } from "../../AppThemeProvider";
import { AppThemeColor } from "../../styles/color";
import { AppSize } from "../AppSize";
import Img from "./Img";
import Props from "./Props";

export interface LogoAccountProps extends Props {
  adminAccount: AdminAccountSimpleDto;
  disabled?: boolean;
  size?: AppSize;
}

const LogoAccount = (props: LogoAccountProps) => {
  const size = props.size ?? AppSize.large;
  const appTheme = useAppTheme();

  const style = {
    ...props.style,
    background: appTheme.toHexColor(props.disabled ? AppThemeColor.gray : AppThemeColor.primary),
    textAlign: "center",
    color: appTheme.toHexColor(AppThemeColor.white),
    fontSize: size - 12,
    fontWeight: 600,
    padding: 4,
    border: 1,
    width: size,
    height: size,
    borderRadius: size / 2,
  } as CSSProperties;

  if (!props.adminAccount.logoSmallUrl) {
    return <div style={style}>{props.adminAccount.name.substring(0, 1)}</div>;
  }
  return <Img {...props} src={props.adminAccount.logoSmallUrl} disabled={props.disabled} />;
};

export default LogoAccount;
