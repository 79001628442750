import { CSSProperties } from "react";
import Field from "./Field";
import { FileUploadExtensionsAllowed } from "../../../api/shared/enums/FileUploadExtensionsAllowed";
import { LocalFolderType } from "../../../api/shared/enums/LocalFolderType";
import { StorageContainerType } from "../../../api/shared/enums/StorageContainerType";
import FileUpload, { FileUploadType } from "../FileUpload";
import PropsInputBase from "./PropsInputBase";

export interface PropsInputFile extends PropsInputBase<string> {
  label: string;
  storageContainerType?: StorageContainerType;
  localFolderType?: LocalFolderType;
  style?: CSSProperties;
  extensionsAllowed: FileUploadExtensionsAllowed;
  uploadType: FileUploadType;
  value: string;
  validation?: string;
  onChange: (value: string) => void;
}

const InputFile = (props: PropsInputFile) => {
  return (
    <Field childrenProps={props}>
      <FileUpload {...props} />
    </Field>
  );
};

export default InputFile;
