// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminLongProcessLogDto } from "../dtos/AdminLongProcessLogDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { AdminLongProcessLogFiltersDto } from "../dtos/AdminLongProcessLogFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminLongProcessLog {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<AdminLongProcessLogFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<AdminLongProcessLogDto>> {
    return this.apiService.apiPost("/admin/long-process-log/paged", searchDto, apiOptions);
  }

}
