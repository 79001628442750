import { SentEmailDto } from "../../api/app/dtos/SentEmailDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import { ModalService } from "../../shared/hooks/useModal";
import SentEmailDetailsPopup from "./SentEmailDetailsPopup";

export function getSentEmailListItemMenu(
  sentEmailDto: SentEmailDto,
  modal: ModalService
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        modal.open(<SentEmailDetailsPopup id={sentEmailDto.id} />);
      },
    },
  ];
}
