import { nameof } from "ts-simple-nameof";
import { AdminRetentionDto } from "../../api/admin/dtos/AdminRetentionDto";
import { enumName } from "../../api/shared/enums/_enumName";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormText from "../../shared/components/forms/FormText";
import FormSelectCountry from "../../shared/components/forms/Select/FormSelectCountry";
import FormSelectEnum from "../../shared/components/forms/Select/FormSelectEnum";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";

const AdminRetentionForm = (props: { editMode?: boolean; onSave(): void }) => {
  return (
    <>
      <Row>
        <FormSelectCountry
          label="País"
          onlyAvailable={true}
          propertyName={nameof<AdminRetentionDto>((p) => p.country)}
        />
      </Row>
      <Row>
        <FormSelectEnum
          label="Tipo"
          propertyName={nameof<AdminRetentionDto>((p) => p.type)}
          enumTypeName={enumName.InvoiceRetentionType}
        />
      </Row>
      <Row>
        <FormText label="Código" propertyName={nameof<AdminRetentionDto>((p) => p.code)} />
      </Row>
      <Row>
        <FormText label="Detalles" propertyName={nameof<AdminRetentionDto>((p) => p.details)} />
      </Row>

      <RowButtons>
        <ButtonAccept onClick={props.onSave} />
      </RowButtons>
    </>
  );
};

export default AdminRetentionForm;
