import { Popover } from "@mui/material";
import { useState } from "react";
import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import { useContextModelState } from "../../modelState/useContextModelState";
import { TextAlign } from "../../TextAlign";
import ButtonIcon from "../Button/ButtonIcon";
import { TypeIcon } from "../Icon";
import GridHeaderFilterPopup from "./GridHeader.FilterPopup";
import { getGridColumnAlign, IGridItemTemplate } from "./ItemTemplate/IGridItemTemplate";
import { useContextGrid } from "./useContextGrid";

const GridHeaderFilter = (props: { column: IGridItemTemplate<any>; onApplyFilter: () => void }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const modelState = useContextModelState();
  const grid = useContextGrid();
  if (!props.column.filter) {
    return <></>;
  }

  const filterValue = (grid.gridState.filtersEditing as any)[props.column.filter.propertyName];
  let hasValue = filterValue !== null && filterValue !== undefined && filterValue !== "";
  if (filterValue instanceof Array) {
    hasValue = filterValue.length > 0;
  }

  const align = getGridColumnAlign(props.column);

  return (
    <>
      <ButtonIcon
        icon={hasValue ? TypeIcon.filterApplied : TypeIcon.filter}
        size={AppSize.medium}
        color={hasValue ? AppThemeColor.success : AppThemeColor.gray}
        onClick={(e) => {
          setIsOpen(!isOpen);
          setAnchorEl(e.currentTarget);
          e.stopPropagation();
        }}
        style={{
          position: "absolute",
          left: align == TextAlign.right ? undefined : 0,
          right: align == TextAlign.right ? 0 : undefined,
          top: 5,
        }}
        noPadding={true}
      />
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClick={(e) => e.stopPropagation()}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <GridHeaderFilterPopup
          column={props.column}
          hasValue={hasValue}
          onClose={() => setIsOpen(false)}
          onClear={() => {
            if (props.column.filter?.propertyName) {
              modelState.updateProperty(props.column.filter.propertyName, null);
              if (filterValue.id) {
                modelState.updateProperty(props.column.filter.propertyName + "Id", null);
              }
              setIsOpen(false);
              props.onApplyFilter();
            }
          }}
          onApply={() => {
            setIsOpen(false);
            props.onApplyFilter();
          }}
        />
      </Popover>
    </>
  );
};

export default GridHeaderFilter;
