import { useState } from "react";
import { VaultCertificateDto } from "../../../api/app/dtos/VaultCertificateDto";
import { VaultCertificateInstallDto } from "../../../api/app/dtos/VaultCertificateInstallDto";
import { useApiVaultCertificate } from "../../../api/shared/hooks/useApiApp";
import Button from "../../../shared/components/Button/Button";
import InputSwitch from "../../../shared/components/inputs/InputSwitch";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import VaultCertificateInfo from "./VaultCertificateInfo";

const VaultCertificateInstallPopupConfirm = (props: {
  vaultCertificateInstallDto: VaultCertificateInstallDto;
  vaultCertificateDto: VaultCertificateDto;
  onInstallSuccess(): void;
}) => {
  const [apiVaultCertificate] = useApiVaultCertificate();
  const [setAsDefault, setSetAsDefault] = useState<boolean | null>(true);
  const installCertificate = async () => {
    props.vaultCertificateInstallDto.setAsDefault = setAsDefault ?? false;
    props.vaultCertificateInstallDto.preventVaultInstall = true;
    await apiVaultCertificate.install(props.vaultCertificateInstallDto);
    props.onInstallSuccess();
  };

  return (
    <>
      <VaultCertificateInfo vaultCertificate={props.vaultCertificateDto} />
      <RowButtons>
        <InputSwitch
          value={setAsDefault}
          onChange={setSetAsDefault}
          label="Utilizar este certificado por defecto"
        />
        <Button text="Instalar" onClick={() => installCertificate()} />
      </RowButtons>
    </>
  );
};

export default VaultCertificateInstallPopupConfirm;
