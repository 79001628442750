import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminTermAndConditionsFiltersDto } from "../../api/admin/dtos/AdminTermAndConditionsFiltersDto";
import { UserTermAcceptedFiltersDto } from "../../api/admin/dtos/UserTermAcceptedFiltersDto";
import { UserTermsAcceptedDto } from "../../api/admin/dtos/UserTermsAcceptedDto";
import { useApiAdminProviderUserTermsAccepted } from "../../api/shared/hooks/useApiAdminProviders";
import Grid from "../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../shared/components/Layout/Page";
import { AppInfoSiteType } from "../../shared/context/AppInfoProvider";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { useModal } from "../../shared/hooks/useModal";
import { useNotifier } from "../../shared/hooks/useNotifier";
import getUserTermsAcceptedListItemMenu from "./UserTermsAcceptedList.ItemMenu";

const UserTermsAcceptedList = () => {
  const [modal] = useModal();
  const notifier = useNotifier();
  const [reloadGrid, setReloadGrid] = useState<Date>();
  const [apiAdminUserTermsAccepted] = useApiAdminProviderUserTermsAccepted();
  const { appInfo } = useContextAppInfo();

  const [initialFilters] = useState(() => {
    const filters = new UserTermAcceptedFiltersDto();
    filters.crossShardIncludeAllProviders = appInfo?.siteType == AppInfoSiteType.SuperAdmin;
    return filters;
  });

  const itemTemplates: IGridItemTemplate<UserTermsAcceptedDto>[] = [
    {
      header: "Proveedor",
      field: nameof<UserTermsAcceptedDto>((p) => p.adminProvider.name),
      filter: {
        propertyName: nameof<AdminTermAndConditionsFiltersDto>((p) => p.adminProvider),
        type: GridFilterType.providers,
      },
      width: 10,
    },
    {
      header: "Usuario",
      field: nameof<UserTermsAcceptedDto>((p) => p.user.email),
      width: 40,
    },
    {
      header: "Fecha",
      field: nameof<UserTermsAcceptedDto>((p) => p.date),
      width: 10,
      cellType: GridCellType.dateTime,
    },
    {
      header: "Última versión aceptada",
      field: nameof<UserTermsAcceptedDto>((p) => p.lastVersionAccepted),
      width: 10,
      cellType: GridCellType.integer,
    },
  ];

  return (
    <Page title="Aceptación de términos y condiciones">
      <Grid
        itemTemplates={itemTemplates}
        defaultSortBy={[{ columnName: nameof<UserTermsAcceptedDto>((p) => p.user), asc: true }]}
        hideIncludeInactiveButton={true}
        preventShowDefaultSearch={true}
        initialFilters={initialFilters}
        reloadGrid={reloadGrid}
        onSearch={(search, options) => apiAdminUserTermsAccepted.getPaged(search, options)}
        onDisplayItemMenu={(item) =>
          getUserTermsAcceptedListItemMenu(item, apiAdminUserTermsAccepted, modal, notifier, () => {
            setReloadGrid(new Date());
          })
        }
      />
    </Page>
  );
};

export default UserTermsAcceptedList;
