import { ProductDto } from "../../api/app/dtos/ProductDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import { ModalService } from "../../shared/hooks/useModal";
import ProductCreatePopup from "./ProductCreatePopup";
import ProductDetailsPopup from "./ProductDetailsPopup";
import ProductEditPopup from "./ProductEditPopup";

export default function getProductListItemMenu(
  product: ProductDto,
  modal: ModalService,
  onGridReload: () => void
): ContextMenuItem[] {
  const menu: ContextMenuItem[] = [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () =>
        modal.open(
          <ProductDetailsPopup productId={product.id} />,
          onGridReload
        ),
    },
    {
      text: "Editar",
      icon: TypeIcon.edit,
      onClick: () =>
        modal.open(<ProductEditPopup productId={product.id} />, onGridReload),
    },
    {
      text: "Crear similar",
      icon: TypeIcon.copy,
      onClick: () =>
        modal.open(
          <ProductCreatePopup copyProductId={product.id} />,
          onGridReload
        ),
    },
  ];
  return menu;
}
