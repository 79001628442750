import { nameof } from "ts-simple-nameof";
import { PurchaseDto } from "../../api/app/dtos/PurchaseDto";
import { PurchaseTaxDto } from "../../api/app/dtos/PurchaseTaxDto";
import { enumName } from "../../api/shared/enums/_enumName";
import { GridColumnAlign } from "../../shared/components/grid/Grid";
import GridClientSide from "../../shared/components/grid/GridClientSide";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Row from "../../shared/components/Layout/GRow";
import RowTitle from "../../shared/components/Layout/GRowTitle";

const PurchaseDetailsTaxes = (props: { purchase: PurchaseDto }) => {
  const templates: IGridItemTemplate<PurchaseTaxDto>[] = [
    {
      header: "Impuesto",
      field: nameof<PurchaseTaxDto>((p) => p.tax),
      cellTypeEnumName: enumName.Tax,
      width: 30,
    },
    {
      header: "Gravado",
      field: nameof<PurchaseTaxDto>((p) => p.linesTotalAmount),
      cellType: GridCellType.decimal,
      align: GridColumnAlign.right,
      width: 10,
    },
    {
      header: "Porcentaje",
      field: nameof<PurchaseTaxDto>((p) => p.percentage),
      cellType: GridCellType.decimal,
      align: GridColumnAlign.right,
      width: 10,
    },
    {
      header: "Monto",
      field: nameof<PurchaseTaxDto>((p) => p.amount),
      cellType: GridCellType.decimal,
      align: GridColumnAlign.right,
      width: 10,
    },
  ];

  return (
    <>
      <RowTitle>Impuestos</RowTitle>
      <Row>
        <GridClientSide hideFooter={true} items={props.purchase.taxes} templates={templates} />
      </Row>
    </>
  );
};

export default PurchaseDetailsTaxes;
