export default class ValidationExpression {
  constructor(
    public validate: (
      value: any,
      baseModel: any,
      baseModelList: any[]
    ) => boolean,
    public validationMessage: string
  ) {}
}
