import { SearchResultItemDto } from "../../../api/shared/dtos/SearchResultItemDto";
import { ContextMenuItem } from "../ContextMenu/ContextMenu";
import GridRowCellMobile from "./GridRow.CellMobile";
import { IGridItemTemplate } from "./ItemTemplate/IGridItemTemplate";
import { useContextGrid } from "./useContextGrid";

const GridRowColumnsMobile = (props: {
  isRowExpanded: boolean;
  item: SearchResultItemDto<any>;
  onDisplayItemMenu?(item: any): ContextMenuItem[];
  onExpandRowShouldDisableButton?(item: any): boolean;
  onRowToggleExpand?(item: any): void;
  onSelect(item: any): void;
}) => {
  const grid = useContextGrid();

  const mainColumns = grid.gridState.visibleColumns.filter((c) => c.mobileSpecialType);
  const normalColumns = grid.gridState.visibleColumns.filter((c) => !c.mobileSpecialType);
  const sortedColumns = mainColumns.concat(normalColumns);

  return (
    <>
      {sortedColumns.map((itemTemplate: IGridItemTemplate<any>, itemIndex) => (
        <GridRowCellMobile
          key={itemIndex}
          isRowExpanded={props.isRowExpanded}
          template={itemTemplate}
          pagedResultItem={props.item}
          onDisplayItemMenu={props.onDisplayItemMenu}
          onExpandRowShouldDisableButton={props.onExpandRowShouldDisableButton}
          onRowToggleExpand={props.onRowToggleExpand}
          onSelect={props.onSelect}
        />
      ))}
    </>
  );
};

export default GridRowColumnsMobile;
