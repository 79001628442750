import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { EInvoiceDailyReportCrossShardDto } from "../../../api/app/dtos/EInvoiceDailyReportCrossShardDto";
import { EInvoiceDailyReportsFiltersDto } from "../../../api/app/dtos/EInvoiceDailyReportsFiltersDto";
import { enumName } from "../../../api/shared/enums/_enumName";
import { useApiAdminProviderEInvoiceDailyReportProcess } from "../../../api/shared/hooks/useApiAdminProviders";
import { useApiCrossShardEInvoiceDailyReport } from "../../../api/shared/hooks/useApiCrossShard";
import ButtonSecondary from "../../../shared/components/Button/ButtonSecondary";
import Grid from "../../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
} from "../../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import { TypeIcon } from "../../../shared/components/Icon";
import RowButtonsToolbar from "../../../shared/components/Layout/GRowButtonsToolbar";
import Section from "../../../shared/components/Layout/GSection";
import Page from "../../../shared/components/Layout/Page";
import { AppInfoSiteType } from "../../../shared/context/AppInfoProvider";
import { useContextAppInfo } from "../../../shared/hooks/useContextAppInfo";
import { useModal } from "../../../shared/hooks/useModal";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import { useNotifier } from "../../../shared/hooks/useNotifier";
import { enumColorEInvoiceDailyReportStatus } from "../../../shared/utils/utilEnumColors";
import AdminEInvoiceDailyReportGenerateAllPopup from "./AdminEInvoiceDailyReportGenerateAllPopup";
import AdminEInvoiceDailyReportGenerateForAccountPopup from "./AdminEInvoiceDailyReportGenerateForAccount";
import getAdminEInvoiceDailyReportListItemMenu from "./AdminEInvoiceDailyReportList.ItemMenu";

const AdminEInvoiceDailyReportList = () => {
  const [initialFilters] = useState(new EInvoiceDailyReportsFiltersDto());
  const [reloadGrid, setReloadGrid] = useState<Date>();
  const [apiCrossShardEInvoiceDailyReport] = useApiCrossShardEInvoiceDailyReport();
  const [apiAdminEInvoiceDailyReportProcess] = useApiAdminProviderEInvoiceDailyReportProcess();
  const [modal] = useModal();
  const { appInfo } = useContextAppInfo();
  const navigation = useNavigation();
  const notifier = useNotifier();

  const itemTemplates: IGridItemTemplate<EInvoiceDailyReportCrossShardDto>[] = [
    {
      header: "Proveedor",
      field: nameof<EInvoiceDailyReportCrossShardDto>((p) => p.account.providerId),
      hideByDefault: appInfo?.siteType != AppInfoSiteType.SuperAdmin,
      width: 10,
    },
    {
      header: "Fecha reporte",
      field: nameof<EInvoiceDailyReportCrossShardDto>((p) => p.date),
      filter: {
        propertyName: nameof<EInvoiceDailyReportsFiltersDto>((p) => p.reportDate),
        type: GridFilterType.dateTime,
      },
      width: 10,
      cellType: GridCellType.date,
    },
    {
      header: "Fecha envío",
      field: nameof<EInvoiceDailyReportCrossShardDto>((p) => p.sentDate),
      filter: {
        propertyName: nameof<EInvoiceDailyReportsFiltersDto>((p) => p.sentDate),
        type: GridFilterType.dateTime,
      },
      width: 10,
      cellType: GridCellType.dateTimeWithSeconds,
    },
    {
      header: "Cuenta",
      field: nameof<EInvoiceDailyReportCrossShardDto>((p) => p.account.name),
      filter: {
        propertyName: nameof<EInvoiceDailyReportsFiltersDto>((p) => p.account),
        type: GridFilterType.account,
      },
      width: 10,
      orderByPrevent: true,
    },
    {
      header: "Id interno",
      field: nameof<EInvoiceDailyReportCrossShardDto>((p) => p.internalSenderId),
      filter: {
        propertyName: nameof<EInvoiceDailyReportsFiltersDto>((p) => p.internalSenderId),
        type: GridFilterType.numberRangeInteger,
      },
      cellType: GridCellType.integer,
      width: 6,
    },
    {
      header: "Secuencia",
      field: nameof<EInvoiceDailyReportCrossShardDto>((p) => p.secuencialNumber),
      filter: {
        propertyName: nameof<EInvoiceDailyReportsFiltersDto>((p) => p.secuencialNumber),
        type: GridFilterType.numberRangeInteger,
      },
      cellType: GridCellType.integer,
      width: 6,
    },
    {
      header: "Error",
      field: nameof<EInvoiceDailyReportCrossShardDto>((p) => p.errorMessage),
      filter: {
        propertyName: nameof<EInvoiceDailyReportsFiltersDto>((p) => p.errorMessage),
        type: GridFilterType.text,
      },
      width: 20,
    },
    {
      header: "Estado",
      field: nameof<EInvoiceDailyReportCrossShardDto>((p) => p.status),
      filter: {
        propertyName: nameof<EInvoiceDailyReportsFiltersDto>((p) => p.statusList),
        type: GridFilterType.enumList,
        enumName: enumName.EInvoiceDailyReportStatus,
      },
      width: 10,
      cellType: GridCellType.statusWithColor,
      cellTypeEnumName: enumName.EInvoiceDailyReportStatus,
      cellTypeMapColor: enumColorEInvoiceDailyReportStatus,
    },
  ];

  return (
    <Page title="eFactura - Reportes diarios">
      <RowButtonsToolbar>
        <ButtonSecondary
          text="Generar para cuenta"
          icon={TypeIcon.report}
          styles={{ marginRight: 10 }}
          onClick={() =>
            modal.open(<AdminEInvoiceDailyReportGenerateForAccountPopup />, () =>
              setReloadGrid(new Date())
            )
          }
        />
        <ButtonSecondary
          text="Generar para todos"
          disabled={appInfo?.siteType == AppInfoSiteType.SuperAdmin}
          icon={TypeIcon.adminAccount}
          onClick={() =>
            modal.open(<AdminEInvoiceDailyReportGenerateAllPopup />, () =>
              setReloadGrid(new Date())
            )
          }
        />
      </RowButtonsToolbar>
      <Section>
        <Grid
          itemTemplates={itemTemplates}
          initialFilters={initialFilters}
          isCrossShard={true}
          reloadGrid={reloadGrid}
          onDisplayItemMenu={(item) =>
            getAdminEInvoiceDailyReportListItemMenu(
              item,
              apiAdminEInvoiceDailyReportProcess,
              modal,
              notifier,
              navigation,
              () => {
                setReloadGrid(new Date());
              }
            )
          }
          onSearch={(search, options) => apiCrossShardEInvoiceDailyReport.getPaged(search, options)}
        />
      </Section>
    </Page>
  );
};

export default AdminEInvoiceDailyReportList;
