import { AppThemeColor } from "../../styles/color";
import { AppSize } from "../AppSize";
import Icon, { TypeIcon } from "./Icon";

const IconError = (props: { size?: AppSize; tooltip?: string }) => {
  return (
    <Icon
      type={TypeIcon.error}
      color={AppThemeColor.alert}
      size={props.size}
      tooltip={props.tooltip}
    />
  );
};

export default IconError;
