import { AppRoute } from "../../AppRoutes";
import Button from "../../shared/components/Button/Button";
import LoginCard from "./Layout/LoginCard";
import { useLoginLayout } from "./Layout/LoginLayoutContext";
import LoginRow from "./Layout/LoginRow";
import LoginRowBottom from "./Layout/LoginRowBottom";
import LoginTitle from "./Layout/LoginTitle";
const RegisterSuccess = (props: { children: any }) => {
  const loginLayout = useLoginLayout();
  return (
    <LoginCard>
      <LoginTitle>Listo!</LoginTitle>
      <LoginRow>{props.children}</LoginRow>
      <LoginRowBottom>
        <Button
          fullWidth={true}
          text="Ir al login"
          onClick={() => {
            loginLayout.navigateTo(AppRoute.login.base);
          }}
        />
      </LoginRowBottom>
    </LoginCard>
  );
};

export default RegisterSuccess;
