import Icon, { TypeIcon } from "../../shared/components/Icon";
import { AppSize } from "../../shared/AppSize";
import { AppThemeColor } from "../../styles/color";
import LoginRow from "./Layout/LoginRow";
import RegisterSuccess from "./Register.Success";

const RegisterSuccessMobile = () => {
  return (
    <RegisterSuccess>
      <LoginRow style={{ textAlign: "center" }}>
        <Icon type={TypeIcon.whatsApp} size={AppSize.biggest} color={AppThemeColor.success} />
      </LoginRow>
      <LoginRow>Nos contactaremos por WhatsApp a la brevedad!</LoginRow>
    </RegisterSuccess>
  );
};

export default RegisterSuccessMobile;
