import { nameof } from "ts-simple-nameof";
import { ProductDto } from "../../../api/app/dtos/ProductDto";
import { ProductInfoDto } from "../../../api/app/dtos/ProductInfoDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import { validateSchema } from "../../../shared/validation/schema/ValidationFieldSchema";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

function validationInfo() {
  const productInfoSchema = new ValidationSchema();

  productInfoSchema[nameof<ProductInfoDto>((p) => p.defaultTax)] = validate("Impuesto").required();
  productInfoSchema[nameof<ProductInfoDto>((p) => p.defaultCurrency)] =
    validate("Moneda").required();

  return productInfoSchema;
}

export function useProductValidation() {
  const productSchema = new ValidationSchema();

  productSchema[nameof<ProductDto>((p) => p.code)] = validate("Código").required();
  productSchema[nameof<ProductDto>((p) => p.name)] = validate("Nombre").required();
  productSchema[nameof<ProductDto>((p) => p.info)] = validateSchema(
    validationInfo(),
    "Información"
  ).required();

  return productSchema;
}
