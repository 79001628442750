import { nameof } from "ts-simple-nameof";
import { AdminAccountDto } from "../../api/admin/dtos/AdminAccountDto";
import { CompanyInfoDto } from "../../api/app/dtos/CompanyInfoDto";
import { FileUploadExtensionsAllowed } from "../../api/shared/enums/FileUploadExtensionsAllowed";
import { StorageContainerType } from "../../api/shared/enums/StorageContainerType";
import { enumName } from "../../api/shared/enums/_enumName";
import { FileUploadType } from "../../shared/components/FileUpload";
import FormAddress from "../../shared/components/forms/FormAddress";
import FormFile from "../../shared/components/forms/FormFile";
import FormTaxPayerId from "../../shared/components/forms/FormTaxPayerId";
import FormText from "../../shared/components/forms/FormText";
import FormSelectEnum from "../../shared/components/forms/Select/FormSelectEnum";
import Row from "../../shared/components/Layout/GRow";
import Section from "../../shared/components/Layout/GSection";
import { useContextModelState } from "../../shared/modelState/useContextModelState";

const AdminAccountFormCompany = () => {
  const modelState = useContextModelState();

  const companyInfoFetched = async (companyInfo: CompanyInfoDto) => {
    const taxPayerInfoDto = companyInfo.taxPayerInfo;

    if (!taxPayerInfoDto.found) {
      return;
    }

    modelState.updateProperties([
      {
        propertyFullName: nameof<AdminAccountDto>((p) => p.name),
        value: taxPayerInfoDto.name ? taxPayerInfoDto.name : taxPayerInfoDto.companyName,
      },
      {
        propertyFullName: nameof<AdminAccountDto>((p) => p.company.name),
        value: taxPayerInfoDto.companyName,
      },
      {
        propertyFullName: nameof<AdminAccountDto>((p) => p.company.comercialName),
        value: taxPayerInfoDto.name ? taxPayerInfoDto.name : taxPayerInfoDto.companyName,
      },
      {
        propertyFullName: nameof<AdminAccountDto>((p) => p.company.activity),
        value: taxPayerInfoDto.activityCategory,
      },
      {
        propertyFullName: nameof<AdminAccountDto>((p) => p.contact.email),
        value: taxPayerInfoDto.contactEmail,
      },
      {
        propertyFullName: nameof<AdminAccountDto>((p) => p.company.phone),
        value: taxPayerInfoDto.contactPhone,
      },
      {
        propertyFullName: nameof<AdminAccountDto>((p) => p.contact.phone),
        value: taxPayerInfoDto.contactPhone,
      },
      {
        propertyFullName: nameof<AdminAccountDto>((p) => p.company.address),
        value: taxPayerInfoDto.address,
      },
    ]);
  };

  return (
    <>
      <Row>
        <FormTaxPayerId
          label="RUT"
          propertyName={nameof<AdminAccountDto>((p) => p.company.taxPayerId)}
          onCompanyInfoFetched={companyInfoFetched}
        />
      </Row>

      <Row>
        <FormText
          label="Nombre de la cuenta"
          propertyName={nameof<AdminAccountDto>((p) => p.name)}
        />
      </Row>

      <Row>
        <FormText
          label="Razón social"
          propertyName={nameof<AdminAccountDto>((p) => p.company.name)}
        />
      </Row>

      <Row>
        <FormText
          label="Nombre comercial"
          propertyName={nameof<AdminAccountDto>((p) => p.company.comercialName)}
        />
      </Row>

      <Row>
        <FormSelectEnum
          label="Tipo"
          enumTypeName={enumName.CompanyType}
          propertyName={nameof<AdminAccountDto>((p) => p.company.type)}
        />
      </Row>

      <Row>
        <FormText label="Giro" propertyName={nameof<AdminAccountDto>((p) => p.company.activity)} />
      </Row>

      <Row>
        <FormText label="Teléfono" propertyName={nameof<AdminAccountDto>((p) => p.company.phone)} />
      </Row>

      <Section title="Dirección" border={true}>
        <FormAddress
          propertyName={nameof<AdminAccountDto>((p) => p.company.address)}
          columnMd={3}
        />
      </Section>

      <Row>
        <FormFile
          label="Logo"
          propertyName={nameof<AdminAccountDto>((p) => p.logoUrl)}
          uploadType={FileUploadType.ToStorageAccount}
          storageContainerType={StorageContainerType.Images}
          extensionsAllowed={FileUploadExtensionsAllowed.Images}
        />
      </Row>

      <Row>
        <FormFile
          label="Logo (chico)"
          propertyName={nameof<AdminAccountDto>((p) => p.logoSmallUrl)}
          uploadType={FileUploadType.ToStorageAccount}
          storageContainerType={StorageContainerType.Images}
          extensionsAllowed={FileUploadExtensionsAllowed.Images}
        />
      </Row>
    </>
  );
};

export default AdminAccountFormCompany;
