import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { AdminTemplateGenericDto } from "../../../api/admin/dtos/AdminTemplateGenericDto";
import { enumName } from "../../../api/shared/enums/_enumName";
import { useApiAdminAccountPublicTemplateGeneric } from "../../../api/shared/hooks/useApiAdmin";
import { AppRoute } from "../../../AppRoutes";
import { ButtonCreate } from "../../../shared/components/Button/ButtonCreate";
import Grid from "../../../shared/components/grid/Grid";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../../shared/components/Layout/Page";
import { useModal } from "../../../shared/hooks/useModal";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import { useNotifier } from "../../../shared/hooks/useNotifier";
import { getAdminTemplateGenericListItemMenu } from "./AdminTemplateGenericList.ItemMenu";

const AdminTemplateGenericList = () => {
  const navigation = useNavigation();
  const [apiTemplateGeneric] = useApiAdminAccountPublicTemplateGeneric();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const [modal] = useModal();
  const notifier = useNotifier();

  const itemTemplates: IGridItemTemplate<AdminTemplateGenericDto>[] = [
    {
      header: "Cuenta",
      field: nameof<AdminTemplateGenericDto>((p) => p.adminAccount.name),
      width: 15,
    },
    {
      header: "Tipo",
      field: nameof<AdminTemplateGenericDto>((p) => p.type),
      width: 10,
      cellTypeEnumName: enumName.TemplateType,
    },
    {
      header: "Contenido",
      field: nameof<AdminTemplateGenericDto>((p) => p.genericType),
      width: 10,
      cellTypeEnumName: enumName.TemplateGenericType,
    },
    {
      header: "Nombre",
      field: nameof<AdminTemplateGenericDto>((p) => p.name),
      width: 25,
    },
    {
      header: "Código",
      field: nameof<AdminTemplateGenericDto>((p) => p.code),
      width: 10,
    },
    {
      header: "País",
      field: nameof<AdminTemplateGenericDto>((p) => p.country),
      width: 10,
      cellTypeEnumName: enumName.Country,
    },
    {
      header: "Admin",
      field: nameof<AdminTemplateGenericDto>((p) => p.isAdmin),
      width: 10,
      cellType: GridCellType.iconCheck,
    },
    {
      header: "Ayuda",
      field: nameof<AdminTemplateGenericDto>((p) => p.helpText),
      width: 30,
    },
  ];

  return (
    <Page
      title="Plantillas genéricas"
      titleButton={
        <ButtonCreate
          text="Nueva plantilla"
          onClick={() => navigation.go(AppRoute.admin.templates.generic.create)}
        />
      }>
      <Grid
        itemTemplates={itemTemplates}
        onSearch={(search, options) => apiTemplateGeneric.getPaged(search, options)}
        reloadGrid={reloadGrid}
        preventShowDefaultSearch={true}
        onDisplayItemMenu={(adminTemplateGenericDto: AdminTemplateGenericDto) =>
          getAdminTemplateGenericListItemMenu(
            adminTemplateGenericDto,
            apiTemplateGeneric,
            navigation,
            modal,
            notifier,
            setReloadGrid
          )
        }
      />
    </Page>
  );
};

export default AdminTemplateGenericList;
