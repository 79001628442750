import { forwardRef } from "react";
import { nameof } from "ts-simple-nameof";
import { SaleSenderBehalfDto } from "../../../../api/app/dtos/SaleSenderBehalfDto";
import InputAutocompleteSaleSenderBehalf, {
  PropsInputAutocompleteSaleSenderBehalf,
} from "../../inputs/autocomplete/InputAutocompleteSaleSenderBehalf";
import { PropsFormInput, useFormInputInit } from "../_formInputProps";

export interface PropsFormAutocompleteSaleSenderBehalf
  extends PropsFormInput<SaleSenderBehalfDto>,
    Omit<
      PropsInputAutocompleteSaleSenderBehalf,
      "value" | "onChange" | "onCreateNewClick" | "onGetEntityById" | "onServerSideSuggest"
    > {}

const FormAutocompleteSaleSenderBehalf = forwardRef(
  (props: PropsFormAutocompleteSaleSenderBehalf, ref?: any) => {
    const inputProps = useFormInputInit(props, ref, {
      updateIdLinkedProperty: true,
      inputTextValuePropertyName: nameof<SaleSenderBehalfDto>((p) => p.name),
    });
    return <InputAutocompleteSaleSenderBehalf {...props} {...inputProps} />;
  }
);

export default FormAutocompleteSaleSenderBehalf;
