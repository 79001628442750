import { nameof } from "ts-simple-nameof";
import { SaleSenderBehalfDto } from "../../api/app/dtos/SaleSenderBehalfDto";
import { enumName } from "../../api/shared/enums/_enumName";
import ButtonAccept from "../../shared/components/Button/ButtonAccept";
import FormText from "../../shared/components/forms/FormText";
import FormSelectCountry from "../../shared/components/forms/Select/FormSelectCountry";
import FormSelectEnum from "../../shared/components/forms/Select/FormSelectEnum";
import Container from "../../shared/components/Layout/GContainer";
import Row from "../../shared/components/Layout/GRow";
import RowButtons from "../../shared/components/Layout/GRowButtons";

const SaleSenderBehalfForm = (props: { onSubmit: () => void }) => {
  return (
    <Container>
      <Row>
        <FormSelectCountry
          label="País"
          propertyName={nameof<SaleSenderBehalfDto>((p) => p.country)}
        />
      </Row>

      <Row>
        <FormSelectEnum
          label="Tipo documento"
          enumTypeName={enumName.IdCardType}
          propertyName={nameof<SaleSenderBehalfDto>((p) => p.idCardType)}
        />
      </Row>

      <Row>
        <FormText
          label="Número de documento"
          propertyName={nameof<SaleSenderBehalfDto>((p) => p.idCardNumber)}
        />
      </Row>

      <Row>
        <FormText label="Nombre" propertyName={nameof<SaleSenderBehalfDto>((p) => p.name)} />
      </Row>

      <RowButtons>
        <ButtonAccept onClick={props.onSubmit} />
      </RowButtons>
    </Container>
  );
};

export default SaleSenderBehalfForm;
