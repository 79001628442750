import { CSSProperties } from "react";
import { ReportTaxesPeriodTaxDto } from "../../../api/app/dtos/ReportTaxesPeriodTaxDto";
import { Currency } from "../../../api/shared/enums/Currency";
import { enumName } from "../../../api/shared/enums/_enumName";
import useUtils from "../../../shared/hooks/useUtils";
import ReportLine from "../ReportLine";
import ReportTaxesPeriodTaxCurrency from "./ReportTaxes.PeriodTaxCurrency";

const ReportTaxesPeriodTax = (props: {
  periodTax: ReportTaxesPeriodTaxDto;
  defaultCurrency: Currency;
}) => {
  const { utils } = useUtils();
  const styleContainer: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: props.periodTax.tax ? 20 : 0,
    fontSize: props.periodTax.tax ? "1em" : "1.25em",
  };

  return (
    <div style={styleContainer}>
      <ReportLine
        label="Venta"
        values={[utils.currency.toString(props.defaultCurrency, props.periodTax.salesTotal)]}
        expandButtonShow={true}
        expandButtonDisabled={!props.periodTax.totalSalesByCurrencies.length}>
        {props.periodTax.totalSalesByCurrencies.map((p, index) => (
          <ReportTaxesPeriodTaxCurrency
            key={index}
            defaultCurrency={props.defaultCurrency}
            periodTaxCurrency={p}
          />
        ))}
      </ReportLine>

      <ReportLine
        label="Compra"
        values={[utils.currency.toString(props.defaultCurrency, props.periodTax.purchasesTotal)]}
        expandButtonShow={true}
        expandButtonDisabled={!props.periodTax.totalPurchasesByCurrencies.length}>
        {props.periodTax.totalPurchasesByCurrencies.map((p, index) => (
          <ReportTaxesPeriodTaxCurrency
            key={index}
            defaultCurrency={props.defaultCurrency}
            periodTaxCurrency={p}
          />
        ))}
      </ReportLine>

      <ReportLine
        isSubtotalSummary={true}
        label={utils.enum.translate(enumName.Tax, props.periodTax.tax)}
        values={[utils.currency.toString(props.defaultCurrency, props.periodTax.total)]}
      />
    </div>
  );
};

export default ReportTaxesPeriodTax;
