import { ApiRequestedExport } from "../../../api/app/apis/ApiRequestedExport";
import { RequestedExportDto } from "../../../api/app/dtos/RequestedExportDto";
import { ContextMenuItem } from "../../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../../shared/components/Icon";
import { Navigation } from "../../../shared/hooks/useNavigation";
import { Notifier } from "../../../shared/hooks/useNotifier";

export function getRequestedReportListItemMenu(
  requestedExportDto: RequestedExportDto,
  navigation: Navigation,
  apiRequestedExport: ApiRequestedExport,
  notifier: Notifier,
  setReload: (date: Date) => void
): ContextMenuItem[] {
  return [
    {
      text: "Descargar",
      icon: TypeIcon.download,
      hide: !requestedExportDto.fileUrl,
      onClick: () => {
        navigation.newTab(requestedExportDto.fileUrl);
      },
    },
    {
      text: "Inactivar",
      icon: TypeIcon.delete,
      onClick: async () => {
        await apiRequestedExport.inactivate(requestedExportDto.id);
        notifier.showSuccessFast("Descarga inactivada correctamente");
        setReload(new Date());
      },
    },
  ];
}
