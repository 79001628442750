import { nameof } from "ts-simple-nameof";
import { ContactDto } from "../../../../api/app/dtos/ContactDto";
import { ProductDto } from "../../../../api/app/dtos/ProductDto";
import { ProductFiltersDto } from "../../../../api/app/dtos/ProductFiltersDto";
import { ProductSimpleDto } from "../../../../api/app/dtos/ProductSimpleDto";
import { SuggestItemDto } from "../../../../api/shared/dtos/SuggestItemDto";
import { SuggestSearchDto } from "../../../../api/shared/dtos/SuggestSearchDto";
import { ProductFilterGroup } from "../../../../api/shared/enums/ProductFilterGroup";
import ProductCreatePopup from "../../../../app/Products/ProductCreatePopup";
import { useApiProduct } from "../../../../api/shared/hooks/useApiApp";
import { useModal } from "../../../hooks/useModal";
import InputAutocompleteBase, { PropsInputAutocompleteBase } from "./InputAutocompleteBase";

export interface PropsInputAutocompleteProduct
  extends Omit<PropsInputAutocompleteBase<ProductDto>, "onGetEntityById"> {
  productFilterGroup: ProductFilterGroup;
}

const InputAutocompleteProduct = (props: PropsInputAutocompleteProduct) => {
  const [apiProduct] = useApiProduct();
  const [modal] = useModal();

  const apiRequest = (echoId: number, term: string, isInitialLoad: boolean) => {
    const searchDto = new SuggestSearchDto<ProductFiltersDto>();
    searchDto.echoId = echoId;
    searchDto.filters = new ProductFiltersDto();
    searchDto.filters.defaultTextSearch = term;
    searchDto.isInitialLoad = isInitialLoad;
    searchDto.orderBy = [{ columnName: nameof<ContactDto>((p) => p.name), asc: true }];
    const apiOptions = {
      preventSpinner: true,
    };

    if (props.productFilterGroup == ProductFilterGroup.ForSale) {
      return apiProduct.suggestForSale(searchDto, apiOptions);
    }
    if (props.productFilterGroup == ProductFilterGroup.ForPurchase) {
      return apiProduct.suggestForPurchase(searchDto, apiOptions);
    }
    return apiProduct.suggestAll(searchDto, apiOptions);
  };

  const openCreateProduct = (searchTerm: string, createCallback: (id: string) => void) => {
    modal.open(<ProductCreatePopup initName={searchTerm} />, (product: ProductDto) => {
      createCallback(product.id);
    });
  };

  return (
    <InputAutocompleteBase
      {...props}
      value={props.value}
      onCreateNewClick={openCreateProduct}
      onGetEntityById={(id: string) => apiProduct.getById(id, { preventSpinner: true })}
      onMapFromItemSuggest={(item: SuggestItemDto) => {
        const productSimpleDto = new ProductSimpleDto();
        productSimpleDto.id = item.id;
        productSimpleDto.name = item.label;
        return productSimpleDto;
      }}
      onServerSideSuggest={apiRequest}
    />
  );
};

export default InputAutocompleteProduct;
