// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { TaxValueDto } from "../dtos/TaxValueDto";
import { dateToIsoString } from "../../shared/utils/apiUtilsDate";

export class ApiTax {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getAllForCurrentAccountCountry(
    apiOptions?: ApiOptions
  ): Promise<number[]> {
    return this.apiService.apiGet("/taxes/for-current-account-country", apiOptions);
  }

  getAllForCurrentAccount(
    apiOptions?: ApiOptions
  ): Promise<number[]> {
    return this.apiService.apiGet("/taxes/for-current-account", apiOptions);
  }

  getTaxValuesForCurrentAccount(
    date: Date,
    apiOptions?: ApiOptions
  ): Promise<TaxValueDto[]> {
    return this.apiService.apiGet("/taxes/values-for-current-account?" + 
      (date ? "&date=" + dateToIsoString(date) : ""), apiOptions);
  }

}
