import { useState } from "react";
import { TypeIcon } from "../Icon";
import InputPasswordHelper from "./InputPassword.Helper";
import InputText, { PropsInputText } from "./InputText";

export interface PropsInputPassword extends PropsInputText {
  builderHelper?: boolean;
  onKeyPressEnter?(): void;
}

const InputPassword = (props: PropsInputPassword) => {
  const [showText, setShowText] = useState(false);

  return (
    <>
      <InputText
        {...props}
        type={showText ? "text" : "password"}
        endIcon={showText ? TypeIcon.hide : TypeIcon.show}
        onEndIconClick={() => setShowText(!showText)}
        onKeyDown={(event) => {
          if (event.code == "Enter" && props.onKeyPressEnter) {
            props.onKeyPressEnter();
          }
        }}
      />
      {props.builderHelper && (
        <InputPasswordHelper validateValue={props.value} />
      )}
    </>
  );
};

export default InputPassword;
