// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { UserDto } from "../dtos/UserDto";
import { AccountUserCrudDto } from "../dtos/AccountUserCrudDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { UserLinkedAccountDto } from "../dtos/UserLinkedAccountDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { UserLinkedAccountFiltersDto } from "../dtos/UserLinkedAccountFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { UserCrudEmailVerificationStatusDto } from "../dtos/UserCrudEmailVerificationStatusDto";

export class ApiAdminProviderAccountUserLinkedAccount {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  createUser(
    accountUserCrudDto: AccountUserCrudDto,
    apiOptions?: ApiOptions
  ): Promise<UserDto> {
    return this.apiService.apiPost("/admin-provider/account-user-linked-accounts/create-user", accountUserCrudDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<UserLinkedAccountDto> {
    return this.apiService.apiGet("/admin-provider/account-user-linked-accounts/" + id, apiOptions);
  }

  getByAccountUserId(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<UserLinkedAccountDto> {
    return this.apiService.apiGet("/admin-provider/account-user-linked-accounts/by-account-user/" + id, apiOptions);
  }

  getByAccountIdAndUserId(
    accountId: string,
    userId: string,
    apiOptions?: ApiOptions
  ): Promise<UserLinkedAccountDto> {
    return this.apiService.apiGet("/admin-provider/account-user-linked-accounts/by-account-and-user-id?" + 
      (accountId ? "&accountId=" + accountId : "") + 
      (userId ? "&userId=" + userId : ""), apiOptions);
  }

  getPagedForCurrentAccount(
    searchFilters: PagedSearchDto<UserLinkedAccountFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<UserLinkedAccountDto>> {
    return this.apiService.apiPost("/admin-provider/account-user-linked-accounts/paged-for-current-account", searchFilters, apiOptions);
  }

  editUser(
    accountAccountUserCrudDto: AccountUserCrudDto,
    apiOptions?: ApiOptions
  ): Promise<UserDto> {
    return this.apiService.apiPut("/admin-provider/account-user-linked-accounts/edit-user", accountAccountUserCrudDto, apiOptions);
  }

  getToEdit(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<AccountUserCrudDto> {
    return this.apiService.apiGet("/admin-provider/account-user-linked-accounts/to-edit?" + 
      (id ? "&id=" + id : ""), apiOptions);
  }

  getUserAccountCrudEmailVerificationStatus(
    email: string,
    apiOptions?: ApiOptions
  ): Promise<UserCrudEmailVerificationStatusDto> {
    return this.apiService.apiGet("/admin-provider/account-user-linked-accounts/user-account-crud-email-verification-status?" + 
      (email ? "&email=" + email : ""), apiOptions);
  }

  inactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/account-user-linked-accounts/inactivate/" + id, null, apiOptions);
  }

  reactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/account-user-linked-accounts/reactivate/" + id, null, apiOptions);
  }

  removeAccountAdmin(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/account-user-linked-accounts/remove-account-admin/" + id, null, apiOptions);
  }

  setAsAccountAdmin(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/account-user-linked-accounts/set-as-account-admin/" + id, null, apiOptions);
  }

}
