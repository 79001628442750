import { MutableRefObject, useState } from "react";
import { AdminTemplateDto } from "../../../api/admin/dtos/AdminTemplateDto";
import { AdminTemplateGenericDto } from "../../../api/admin/dtos/AdminTemplateGenericDto";
import { AdminTemplatePdfDto } from "../../../api/admin/dtos/AdminTemplatePdfDto";
import { TemplateGenericType } from "../../../api/shared/enums/TemplateGenericType";
import { TemplateGroup } from "../../../api/shared/enums/TemplateGroup";
import { useContextModelState } from "../../modelState/useContextModelState";
import { templateGetGroup, templateTypeIsGeneric } from "../../utils/utilTemplate";
import ButtonIcon from "../Button/ButtonIcon";
import { TypeIcon } from "../Icon";
import TabGroup, { TabButton } from "../Layout/TabGroup";
import TabView from "../Layout/TabView";
import TemplateEditorFooter from "./TemplateEditorFooter";
import TemplateEditorHeader from "./TemplateEditorHeader";
import TemplateEditorHtml from "./TemplateEditorHtml";
import TemplateEditorPreviewData from "./TemplateEditorPreviewData";
import TemplateEditorStyle from "./TemplateEditorStyle";

interface ScrollPositions {
  htmlX?: number;
  htmlY?: number;
  cssX?: number;
  cssY?: number;
  footerX?: number;
  footerY?: number;
  headerX?: number;
  headerY?: number;
  previewDataX?: number;
  previewDataY?: number;
}

export const TEMPLATE_EDITOR_TAB = {
  BODY: "BODY",
  FOOTER: "FOOTER",
  HEADER: "HEADER",
  PREVIEW_DATA: "PREVIEW_DATA",
  STYLES: "STYLES",
};

const TemplateEditorPanelCenter = (props: {
  bodyRef: MutableRefObject<any>;
  cssRef: MutableRefObject<any>;
  headerRef: MutableRefObject<any>;
  footerRef: MutableRefObject<any>;
  previewData: any;
  showExpandPreviewPanelButton: boolean;
  onBodyChange: (value: string) => void;
  onCssChange: (value: string) => void;
  onHeaderChange: (value: string) => void;
  onFooterChange: (value: string) => void;
  onExpandPreviewPanel: () => void;
  onInsertPageBreak: () => void;
  onTabChange: (tabName: string) => void;
  onPreviewDataChange: (value: any) => void;
}) => {
  const [scrolls, setScrolls] = useState({} as ScrollPositions);

  const modelState = useContextModelState();
  const template = modelState.model as AdminTemplateDto;
  const templateGroup = templateGetGroup(template.type);

  const tabs = [
    {
      title: "Plantilla",
      value: TEMPLATE_EDITOR_TAB.BODY,
    },
    {
      title: "Estilos",
      value: TEMPLATE_EDITOR_TAB.STYLES,
      hide:
        template instanceof AdminTemplateGenericDto &&
        (template as AdminTemplateGenericDto).genericType == TemplateGenericType.InlineStyles,
    },
    {
      title: "Preview data",
      value: TEMPLATE_EDITOR_TAB.PREVIEW_DATA,
    },
  ] as TabButton[];

  if (templateGroup == TemplateGroup.Pdf) {
    tabs.splice(1, 0, { title: "Pie", value: TEMPLATE_EDITOR_TAB.FOOTER } as TabButton);
    tabs.splice(1, 0, { title: "Encabezado", value: TEMPLATE_EDITOR_TAB.HEADER } as TabButton);
  }

  const expandPreviewPanelButton = props.showExpandPreviewPanelButton ? (
    <ButtonIcon
      icon={TypeIcon.chevronLeft}
      onClick={props.onExpandPreviewPanel}
      tooltip="Mostrar panel de vista previa"
    />
  ) : undefined;

  return (
    <TabGroup
      tabs={tabs}
      selectedTab={TEMPLATE_EDITOR_TAB.BODY}
      onTabChange={(value) => props.onTabChange(value)}>
      <TabView value={TEMPLATE_EDITOR_TAB.BODY}>
        <TemplateEditorHtml
          bodyRef={props.bodyRef}
          expandPreviewPanelButton={
            !templateTypeIsGeneric(template.type) ? expandPreviewPanelButton : undefined
          }
          template={template}
          scrollPosX={scrolls.htmlX}
          scrollPosY={scrolls.htmlY}
          value={template.body}
          onInsertPageBreak={
            !templateTypeIsGeneric(template.type) ? props.onInsertPageBreak : undefined
          }
          onChange={props.onBodyChange}
          onScroll={(x, y) => setScrolls({ ...scrolls, htmlX: x, htmlY: y })}
        />
      </TabView>
      <TabView value={TEMPLATE_EDITOR_TAB.STYLES}>
        <TemplateEditorStyle
          cssRef={props.cssRef}
          expandPreviewPanelButton={expandPreviewPanelButton}
          scrollPosX={scrolls.cssX}
          scrollPosY={scrolls.cssY}
          template={template}
          value={template.styles}
          onChange={props.onCssChange}
          onScroll={(x, y) => setScrolls({ ...scrolls, cssX: x, cssY: y })}
        />
      </TabView>
      <TabView value={TEMPLATE_EDITOR_TAB.PREVIEW_DATA}>
        <TemplateEditorPreviewData
          expandPreviewPanelButton={expandPreviewPanelButton}
          value={props.previewData}
          onChange={props.onPreviewDataChange}
          scrollPosX={scrolls.previewDataX}
          scrollPosY={scrolls.previewDataY}
          onScroll={(x, y) => setScrolls({ ...scrolls, previewDataX: x, previewDataY: y })}
        />
      </TabView>
      <TabView value={TEMPLATE_EDITOR_TAB.HEADER}>
        <TemplateEditorHeader
          headerRef={props.headerRef}
          expandPreviewPanelButton={
            !templateTypeIsGeneric(template.type) ? expandPreviewPanelButton : undefined
          }
          value={(template as AdminTemplatePdfDto).header}
          scrollPosX={scrolls.headerX}
          scrollPosY={scrolls.headerY}
          onChange={props.onHeaderChange}
          onScroll={(x, y) => setScrolls({ ...scrolls, headerX: x, headerY: y })}
        />
      </TabView>
      <TabView value={TEMPLATE_EDITOR_TAB.FOOTER}>
        <TemplateEditorFooter
          footerRef={props.footerRef}
          expandPreviewPanelButton={
            !templateTypeIsGeneric(template.type) ? expandPreviewPanelButton : undefined
          }
          value={(template as AdminTemplatePdfDto).footer}
          scrollPosX={scrolls.footerX}
          scrollPosY={scrolls.footerY}
          onChange={props.onFooterChange}
          onScroll={(x, y) => setScrolls({ ...scrolls, footerX: x, footerY: y })}
        />
      </TabView>
    </TabGroup>
  );
};

export default TemplateEditorPanelCenter;
