import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ThemeSize } from "../../ThemeSize";
import Field from "./Field";
import PropsInputBase from "./PropsInputBase";

export interface PropsInputCheck extends PropsInputBase<boolean> {
  labelPlacement?: "start" | "end";
}

const InputCheck = (props: PropsInputCheck) => {
  if (props.hintAutoHeight === undefined) {
    props = { ...props, hintAutoHeight: true };
  }

  const checkBox = (
    <Checkbox
      id={props.id}
      title={props.label}
      size={ThemeSize.small}
      style={{ paddingTop: 0, paddingBottom: 3, ...props.style }}
      disabled={props.readOnly}
      checked={props.value}
      onClick={($event) => $event.stopPropagation()}
      onChange={(e: any) => {
        props.onChange(e.target.checked);
      }}
    />
  );

  return (
    <Field childrenProps={props} labelHide={true}>
      <FormControlLabel
        label={props.label ?? ""}
        control={checkBox}
        labelPlacement={props.labelPlacement}
        style={{ marginTop: 0 }}
      />
    </Field>
  );
};

export default InputCheck;
