import { ThemeProvider } from "@mui/material/styles";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router";
import { AppRoute } from "../../../AppRoutes";
import { loginMobileCreateThemeForAdminProvider } from "../../../AppThemeLogin";
import { useAppTheme } from "../../../AppThemeProvider";
import AdminProviderLogoLanding from "../../../shared/components/AdminProviderLogoLanding";
import NotificationArea from "../../../shared/components/Notifications/NotificationArea";
import { useContextAppInfo } from "../../../shared/hooks/useContextAppInfo";
import { useContextLoginInfo } from "../../../shared/hooks/useContextLoginInfo";
import { useIsMobile } from "../../../shared/hooks/useIsMobile";
import { ThemeVariant } from "../../../shared/ThemeVariant";
import { AppThemeColor } from "../../../styles/color";
import { LoginLayoutProvider } from "./LoginLayoutContext";

const LoginLayoutPage = () => {
  const isMobile = useIsMobile();
  const appTheme = useAppTheme();
  return (
    <div
      className="login-layout"
      style={{
        background: appTheme.toHexColor(
          isMobile ? AppThemeColor.primary : AppThemeColor.layoutBackground
        ),
      }}>
      <div className="login-layout-left">
        <AdminProviderLogoLanding />
      </div>
      <div className="login-layout-right">
        <LoginLayoutProvider>
          <Outlet />
        </LoginLayoutProvider>
      </div>
    </div>
  );
};

const LoginLayout = () => {
  const isMobile = useIsMobile();
  const { appInfo, appInfoChangeThemeVariant } = useContextAppInfo();
  const appTheme = useAppTheme();
  const { loginInfo } = useContextLoginInfo();

  useEffect(() => {
    if (isMobile && appInfo?.muiVariant != ThemeVariant.outlined) {
      appInfoChangeThemeVariant(ThemeVariant.outlined);
    }
  }, [isMobile]);

  if (loginInfo.isLoggedIn) {
    return <Navigate to={{ pathname: AppRoute.base }} />;
  }

  if (!appInfo) {
    return <></>;
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <NotificationArea />
      {!isMobile && <LoginLayoutPage />}
      {isMobile && (
        <ThemeProvider theme={loginMobileCreateThemeForAdminProvider(appTheme.appThemePalette)}>
          <LoginLayoutPage />
        </ThemeProvider>
      )}
    </div>
  );
};

export default LoginLayout;
