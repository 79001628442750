// File generated by Gesta.Build.PostEvents
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { FilterRangeDateDto } from "../../shared/dtos/FilterRangeDateDto";
import { ContactSimpleDto } from "./ContactSimpleDto";
import { Currency } from "../../shared/enums/Currency";
import { InvoiceType } from "../../shared/enums/InvoiceType";
import { FilterRangeNumberDto } from "../../shared/dtos/FilterRangeNumberDto";

export class InvoiceFiltersDto extends FiltersDto {
  onlyValidStatus: boolean = false;
  createdDate: FilterRangeDateDto;
  contactId: string;
  contact: ContactSimpleDto;
  currency: Currency;
  invoiceTypeList: InvoiceType[] = [];
  isCancellation: boolean | null = null;
  issuedDate: FilterRangeDateDto;
  subtotal: FilterRangeNumberDto;
  number: FilterRangeNumberDto;
  currencyExchangeRate: FilterRangeNumberDto;
  operationDate: FilterRangeDateDto;
  serie: string = "";
  total: FilterRangeNumberDto;
  totalToPay: FilterRangeNumberDto;
}
