import { ReactElement, useState } from "react";
import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import { useContextLoginInfo } from "../../hooks/useContextLoginInfo";
import { useIsMobile } from "../../hooks/useIsMobile";
import ButtonIcon from "../Button/ButtonIcon";
import { TypeIcon } from "../Icon";
import LogoAccount from "../LogoAccount";
import LayoutHeaderLoginMenu from "./LayoutHeader.LoginMenu";

const LayoutHeaderLogin = (): ReactElement => {
  const { loginInfo } = useContextLoginInfo();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const isMobile = useIsMobile();
  const appTheme = useAppTheme();

  if (!loginInfo) {
    return <></>;
  }

  return (
    <>
      {isMenuExpanded && <LayoutHeaderLoginMenu onClose={() => setIsMenuExpanded(false)} />}
      <div
        className={"layout-header-login " + (isMenuExpanded ? "menu-expanded" : "")}
        style={{ backgroundColor: appTheme.toHexColor(AppThemeColor.header) }}
        onClick={() => {
          if (!isMenuExpanded) {
            setIsMenuExpanded(true);
          }
        }}>
        {!isMobile && (
          <>
            <div className={"layout-header-login-info"}>
              <div className="layout-header-account-name">
                {loginInfo.authInfo.adminAccount.name}
              </div>
              <div className="layout-header-login-name">{loginInfo.authInfo.user.email}</div>
            </div>
          </>
        )}
        <div className="layout-header-login-account-picture">
          <LogoAccount adminAccount={loginInfo.authInfo.adminAccount} size={AppSize.large} />
        </div>

        <div className="layout-header-login-account-chevron">
          <ButtonIcon
            noPaddingY={true}
            size={AppSize.large}
            icon={isMenuExpanded ? TypeIcon.chevronUp : TypeIcon.chevronDown}
            color={AppThemeColor.white}
            onClick={() => {
              // Do nothing
            }}
          />
        </div>
      </div>
    </>
  );
};

export default LayoutHeaderLogin;
