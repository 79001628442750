import { useEffect, useState } from "react";
import { CompanyInfoDto } from "../../../api/app/dtos/CompanyInfoDto";
import { useApiEInvoiceCompanyInfo } from "../../../api/shared/hooks/useApiApp";
import ButtonIcon from "../Button/ButtonIcon";
import { TypeIcon } from "../Icon";
import ComponentGroup from "../Layout/ComponentsGroup";
import InputText, { PropsInputText } from "./InputText";

export interface PropsInputTaxPayerId extends PropsInputText {
  onCompanyInfoFetched: (companyInfo: CompanyInfoDto) => void;
}

const InputTaxPayerId = (props: PropsInputTaxPayerId) => {
  const [apiEInvoiceCompanyInfo] = useApiEInvoiceCompanyInfo();
  const [isCompanyInfoEnabled, setIsCompanyInfoEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hint, setHint] = useState(props.hint);
  const isValidTaxPayer = props.value && props.value.length === 12;

  const getCompanyInfo = (taxPayerId: string) => {
    setHint("Obteniendo información de la empresa...");
    const load = async () => {
      setIsLoading(true);

      try {
        const companyInfo = await apiEInvoiceCompanyInfo.getCompanyInfo(taxPayerId, {
          preventSpinner: true,
          preventNotifications: true,
        });

        if (companyInfo.errorMessage) {
          setHint(companyInfo.errorMessage);
          return;
        }

        if (!companyInfo.found) {
          setHint("No pudimos obtener la información de la empresa");
          return;
        }

        setHint(props.hint);
        props.onCompanyInfoFetched(companyInfo);
      } catch {
        setHint("Error al comunicarse con el servicio externo");
      } finally {
        setIsLoading(false);
      }
    };
    load();
  };

  useEffect(() => {
    const load = async () => {
      const isCountryAvailable = await apiEInvoiceCompanyInfo.isCountryAvailable();
      setIsCompanyInfoEnabled(isCountryAvailable);
      if (!props.hint && isCountryAvailable) {
        setHint("Hacé click en el botón de buscar para obtener la información desde DGI");
      }
    };
    load();
  }, []);

  return (
    <ComponentGroup>
      <InputText {...props} hint={hint} />
      <ButtonIcon
        disabled={!isValidTaxPayer || !isCompanyInfoEnabled}
        loading={isLoading}
        icon={TypeIcon.download}
        showBackground={true}
        tooltip="Actualizar información desde DGI"
        onClick={() => getCompanyInfo(props.value)}
      />
    </ComponentGroup>
  );
};

export default InputTaxPayerId;
