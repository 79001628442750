// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { GridSettingsDto } from "../dtos/GridSettingsDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiGridSettings {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<GridSettingsDto> {
    return this.apiService.apiGet("/grid-settings/" + id, apiOptions);
  }

  getForCurrentUser(
    name: number,
    type: number,
    apiOptions?: ApiOptions
  ): Promise<GridSettingsDto> {
    return this.apiService.apiGet("/grid-settings/current-user?" + 
      (name ? "&name=" + name : "") + 
      (type ? "&type=" + type : ""), apiOptions);
  }

  createOrUpdateGlobal(
    gridSettingsDto: GridSettingsDto,
    apiOptions?: ApiOptions
  ): Promise<GridSettingsDto> {
    return this.apiService.apiPost("/grid-settings/create-global", gridSettingsDto, apiOptions);
  }

  createOrUpdateForUser(
    gridSettingsDto: GridSettingsDto,
    apiOptions?: ApiOptions
  ): Promise<GridSettingsDto> {
    return this.apiService.apiPost("/grid-settings/create-for-user", gridSettingsDto, apiOptions);
  }

  removeGlobal(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiDelete("/grid-settings/remove-global/" + id, apiOptions);
  }

  removeForUser(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiDelete("/grid-settings/remove-user/" + id, apiOptions);
  }

}
