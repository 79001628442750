import Icon, { TypeIcon } from "../../shared/components/Icon";
import { AppSize } from "../../shared/AppSize";
import { AppThemeColor } from "../../styles/color";
import LoginRow from "./Layout/LoginRow";
import RegisterSuccess from "./Register.Success";

const RegisterSuccessEmail = () => {
  return (
    <RegisterSuccess>
      <LoginRow style={{ textAlign: "center" }}>
        <Icon type={TypeIcon.email} size={AppSize.biggest} color={AppThemeColor.success} />
      </LoginRow>
      <LoginRow>Te enviamos un correo con información de la aplicación.</LoginRow>
      <LoginRow>No dudes en ponerte en contacto con nosotros cuando quieras!</LoginRow>
    </RegisterSuccess>
  );
};

export default RegisterSuccessEmail;
