import { BroadcastChannel } from "broadcast-channel";
import { createContext, PropsWithChildren, useCallback, useEffect, useReducer } from "react";
import { AuthInfoDto } from "../../api/app/dtos/AuthInfoDto";
import { ApiErrorUserNotAuthenticated } from "../../api/shared/apis/ApiService";
import { useApiAuth } from "../../api/shared/hooks/useApiApp";
import { AppRoute } from "../../AppRoutes";
import ErrorGettingInfo from "../components/LoginFlow/ErrorGettingInfo";
import SessionExpired from "../components/LoginFlow/SessionExpired";
import SpinnerBlock from "../components/SpinnerBlock";
import { useNavigation } from "../hooks/useNavigation";
import UserTermsAndConditions from "./UserTermsAndConditions";

export const loginBroadCastChannel = new BroadcastChannel("gesta_login_channel");
loginBroadCastChannel.onmessage = function () {
  window.location.reload();
};

export enum LoginInfoStatus {
  Error,
  InvalidToken,
  LoggedIn,
  LoggedInProgress,
  LoggedOut,
  LoggedOutInProgress,
  PendingAcceptTerms,
  PendingLoadAuthInfo,
  PendingVerifyStoredToken,
}

export class LoginInfo {
  authInfo: AuthInfoDto;
  authToken: string;
  errorMessage: string;
  isLoggedIn: boolean;
  status: LoginInfoStatus = LoginInfoStatus.PendingVerifyStoredToken;
}

export enum LoginInfoActionType {
  acceptTermsAndConditions,
  login,
  loginConfirm,
  logout,
  logoutConfirm,
  setAsErrorGettingInfo,
  setAsInvalidToken,
  verifyStoredToken,
  switchPointOfSale,
}

export function checkStoredAuthToken(): string {
  const token = localStorage.getItem("auth-token") as string;
  return token;
}

const LoginInfoContext = createContext<{
  loginInfo: LoginInfo;
  dispatchLoginInfo: React.Dispatch<LoginInfoAction>;
}>({
  loginInfo: new LoginInfo(),
  dispatchLoginInfo: () => null,
});

export type LoginInfoAction =
  | { type: LoginInfoActionType.acceptTermsAndConditions }
  | {
      type: LoginInfoActionType.login;
      authInfo: AuthInfoDto;
      authToken: string;
      isLoginFromStoredToken?: boolean;
    }
  | { type: LoginInfoActionType.loginConfirm }
  | { type: LoginInfoActionType.logout }
  | { type: LoginInfoActionType.logoutConfirm }
  | { type: LoginInfoActionType.setAsErrorGettingInfo; errorMessage: string }
  | { type: LoginInfoActionType.setAsInvalidToken }
  | { type: LoginInfoActionType.setAsErrorGettingInfo; errorMessage: string }
  | { type: LoginInfoActionType.verifyStoredToken };

export const loginInfoReducer = (state: LoginInfo, action: LoginInfoAction): LoginInfo => {
  switch (action.type) {
    case LoginInfoActionType.acceptTermsAndConditions: {
      return {
        ...state,
        authInfo: { ...state.authInfo, notifyTermsAndConditions: false },
        status: LoginInfoStatus.LoggedIn,
      };
    }
    case LoginInfoActionType.login: {
      localStorage.setItem("auth-token", action.authToken);

      let status = LoginInfoStatus.LoggedInProgress;
      if (action.authInfo.notifyTermsAndConditions) {
        status = LoginInfoStatus.PendingAcceptTerms;
      } else if (action.isLoginFromStoredToken) {
        status = LoginInfoStatus.LoggedIn;
      }

      return {
        ...state,
        isLoggedIn: true,
        status: status,
        authToken: action.authToken,
        authInfo: action.authInfo,
      };
    }
    case LoginInfoActionType.loginConfirm: {
      return { ...state, status: LoginInfoStatus.LoggedIn };
    }
    case LoginInfoActionType.setAsInvalidToken: {
      localStorage.removeItem("auth-token");
      return {
        isLoggedIn: false,
        status: LoginInfoStatus.InvalidToken,
      } as LoginInfo;
    }
    case LoginInfoActionType.setAsErrorGettingInfo: {
      return {
        status: LoginInfoStatus.Error,
        errorMessage: action.errorMessage,
      } as LoginInfo;
    }
    case LoginInfoActionType.logout: {
      localStorage.removeItem("auth-token");
      return {
        isLoggedIn: false,
        status: LoginInfoStatus.LoggedOutInProgress,
      } as LoginInfo;
    }
    case LoginInfoActionType.logoutConfirm: {
      return {
        status: LoginInfoStatus.LoggedOut,
      } as LoginInfo;
    }
    case LoginInfoActionType.verifyStoredToken: {
      const storedAuthToken = checkStoredAuthToken();
      if (!storedAuthToken) {
        return {
          isLoggedIn: false,
          status: LoginInfoStatus.LoggedOut,
        } as LoginInfo;
      }
      return {
        ...state,
        authToken: storedAuthToken,
        status: LoginInfoStatus.PendingLoadAuthInfo,
      };
    }

    default:
      return state;
  }
};

const LoginInfoProvider: React.FC<
  PropsWithChildren<{
    children: any;
  }>
> = ({ children }: any) => {
  const [apiAuth] = useApiAuth();
  const [loginInfo, dispatchLoginInfo] = useReducer(loginInfoReducer, new LoginInfo());
  const navigation = useNavigation();

  const statusHandler = useCallback(() => {
    if (loginInfo.status === LoginInfoStatus.LoggedInProgress) {
      loginBroadCastChannel.postMessage("LoggedIn");

      dispatchLoginInfo({
        type: LoginInfoActionType.loginConfirm,
      });

      navigation.go(AppRoute.base);
      return;
    }

    if (loginInfo.status === LoginInfoStatus.PendingVerifyStoredToken) {
      setTimeout(() => {
        dispatchLoginInfo({ type: LoginInfoActionType.verifyStoredToken });
      }, 0);
      return;
    }

    if (loginInfo.status === LoginInfoStatus.PendingLoadAuthInfo) {
      setTimeout(() => {
        const loadInfoContext = async () => {
          apiAuth.apiService.auth.authToken = loginInfo.authToken;
          try {
            const authInfo = await apiAuth.getAuthInfo({
              preventSpinner: true,
              preventNotifications: true,
            });
            dispatchLoginInfo({
              type: LoginInfoActionType.login,
              authInfo: authInfo,
              authToken: loginInfo.authToken,
              isLoginFromStoredToken: true,
            });
          } catch (error: any) {
            if (error instanceof ApiErrorUserNotAuthenticated) {
              dispatchLoginInfo({
                type: LoginInfoActionType.setAsInvalidToken,
              });
              return;
            }
            dispatchLoginInfo({
              type: LoginInfoActionType.setAsErrorGettingInfo,
              errorMessage: error.toString(),
            });
          }
        };
        loadInfoContext();
      }, 0);
      return;
    }
  }, [loginInfo.status]);

  useEffect(() => {
    statusHandler();
  }, [statusHandler]);

  if (loginInfo.status === LoginInfoStatus.LoggedOutInProgress) {
    loginBroadCastChannel.postMessage("LoggedOut");
    dispatchLoginInfo({
      type: LoginInfoActionType.logoutConfirm,
    });
    setTimeout(() => {
      window.location = AppRoute.login.base as any;
    }, 10);
  }

  let content = null;
  switch (loginInfo.status) {
    case LoginInfoStatus.InvalidToken:
      content = <SessionExpired />;
      break;
    case LoginInfoStatus.Error:
      content = <ErrorGettingInfo />;
      break;
    case LoginInfoStatus.PendingAcceptTerms:
      content = <UserTermsAndConditions />;
      break;
    case LoginInfoStatus.PendingLoadAuthInfo:
      content = <SpinnerBlock label="Cargando info usuario..." />;
      break;
    default:
      content = children;
  }

  return (
    <LoginInfoContext.Provider value={{ loginInfo, dispatchLoginInfo }}>
      {content}
    </LoginInfoContext.Provider>
  );
};

export { LoginInfoContext, LoginInfoProvider };
