import { format } from "date-fns";
import { useState } from "react";
import { ReportPartialOperationRequestDto } from "../../../api/app/dtos/ReportPartialOperationRequestDto";
import { useApiReportPartialOperation } from "../../../api/shared/hooks/useApiApp";
import ButtonSecondary from "../../../shared/components/Button/ButtonSecondary";
import { TypeIcon } from "../../../shared/components/Icon";
import InputDate from "../../../shared/components/inputs/InputDate";
import Container from "../../../shared/components/Layout/GContainer";
import Row from "../../../shared/components/Layout/GRow";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import { useContextAppInfo } from "../../../shared/hooks/useContextAppInfo";
import ReportCard from "../ReportCard";

const ReportPartialOperations = () => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [reportDate, setReportDate] = useState<Date | null>(new Date());
  const [apiReportPartialOperation] = useApiReportPartialOperation();
  const { requestedExportListOpen } = useContextAppInfo();

  const downloadReport = async () => {
    setIsLoading(true);
    setError(undefined);

    const request = async () => {
      if (!reportDate) {
        return;
      }

      const dateText = format(reportDate, "yyyy-MM-dd");
      const reportRequest = new ReportPartialOperationRequestDto();
      reportRequest.date = reportDate;
      reportRequest.fileName = `Informe_cierre_parcial_de_operaciones_${dateText}.xlsx`;

      try {
        await apiReportPartialOperation.requestDownload(reportRequest, {
          preventSpinner: true,
          preventNotifications: true,
        });
        requestedExportListOpen(true);
      } catch (error) {
        setError((error as any)?.message ?? "Error al generar el reporte");
      }
      setIsLoading(false);
    };
    request();
  };

  return (
    <ReportCard title="Cierre de operaciones parciales" errorMessage={error}>
      <Container>
        <Row>
          <InputDate label="Fecha de cierre" value={reportDate} onChange={setReportDate} />
        </Row>
        <RowButtons>
          <ButtonSecondary
            text="Descargar reporte"
            loading={isLoading}
            icon={TypeIcon.download}
            onClick={downloadReport}
          />
        </RowButtons>
      </Container>
    </ReportCard>
  );
};

export default ReportPartialOperations;
