import { EInvoiceAuthorizationDto } from "../../../../api/app/dtos/EInvoiceAuthorizationDto";
import { enumName } from "../../../../api/shared/enums/_enumName";
import ColumnDisplay from "../../../../shared/components/Layout/GColumnDisplay";
import RowDisplay from "../../../../shared/components/Layout/GRowDisplay";
import useUtils from "../../../../shared/hooks/useUtils";

const EInvoiceAuthorizationDetails = (props: {
  eInvoiceAuthorization: EInvoiceAuthorizationDto;
}) => {
  const { utils } = useUtils();
  return (
    <>
      <RowDisplay label="Tipo">
        {utils.enum.translate(enumName.InvoiceType, props.eInvoiceAuthorization.invoiceType)}
      </RowDisplay>
      <ColumnDisplay label="Número">{props.eInvoiceAuthorization.authorizationId}</ColumnDisplay>
      <ColumnDisplay label="Fecha autorización">
        {utils.date.toString(props.eInvoiceAuthorization.authorizationDate)}
      </ColumnDisplay>
      <ColumnDisplay label="Fecha expiración">
        {utils.date.toString(props.eInvoiceAuthorization.expirationDate)}
      </ColumnDisplay>
      <ColumnDisplay label="Serie">{props.eInvoiceAuthorization.serie}</ColumnDisplay>
      <ColumnDisplay label="Número">
        {props.eInvoiceAuthorization.numberFrom + " - " + props.eInvoiceAuthorization.numberTo}
      </ColumnDisplay>
      <ColumnDisplay label="Especial">
        {utils.enum.translate(
          enumName.EInvoiceAuthorizationSpecialType,
          props.eInvoiceAuthorization.specialType
        )}
        {props.eInvoiceAuthorization.specialReason}
      </ColumnDisplay>
    </>
  );
};

export default EInvoiceAuthorizationDetails;
