import { useEffect, useState } from "react";
import { AdminRetentionDto } from "../../api/admin/dtos/AdminRetentionDto";
import { SaleLineRetentionDto } from "../../api/app/dtos/SaleLineRetentionDto";
import { InvoiceRetentionType } from "../../api/shared/enums/InvoiceRetentionType";
import { enumName } from "../../api/shared/enums/_enumName";
import InputSuggestAdminRetention from "../../shared/components/inputs/autocomplete/InputSuggestAdminRetention";
import useUtils from "../../shared/hooks/useUtils";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import { ISaleLineRetentionColumnRenderProps } from "./SaleFormLines";

const SaleLineRetentionColumnCode = (props: ISaleLineRetentionColumnRenderProps) => {
  const saleModelState = useContextModelStateSale();
  const saleLineRetention = props.arrayItemHelper.item as SaleLineRetentionDto;
  const { utils } = useUtils();
  const [adminRetentionDto, setAdminRetentionDto] = useState<AdminRetentionDto | null>(null);

  useEffect(() => {
    if (saleLineRetention.code) {
      setAdminRetentionDto({
        ...(adminRetentionDto ?? ({} as AdminRetentionDto)),
        code: saleLineRetention.code,
        details: saleLineRetention.detail,
        type: saleLineRetention.type,
      });
    }
  }, [saleLineRetention.code]);

  return (
    <InputSuggestAdminRetention
      label={props.showLabel ? props.saleLineRetentionColumn.header : ""}
      variant={props.variant}
      value={adminRetentionDto}
      onInputTextValueChange={(value: string) => {
        setAdminRetentionDto({ ...(adminRetentionDto ?? ({} as AdminRetentionDto)), code: value });
        saleModelState.lineRetentionSetCode(props.lineIndex, props.arrayItemHelper.index, value);
      }}
      hint={
        saleLineRetention.type != InvoiceRetentionType.RetentionOrPerception
          ? utils.enum.translate(enumName.InvoiceRetentionType, saleLineRetention.type)
          : ""
      }
      onChange={(value: AdminRetentionDto) => {
        setAdminRetentionDto(value);

        saleModelState.lineRetentionSetCode(
          props.lineIndex,
          props.arrayItemHelper.index,
          value?.code ?? ""
        );

        if (value) {
          saleModelState.lineRetentionSetDescription(
            props.lineIndex,
            props.arrayItemHelper.index,
            value?.details ?? ""
          );
          saleModelState.lineRetentionSetType(
            props.lineIndex,
            props.arrayItemHelper.index,
            value?.type
          );
        }
      }}
    />
  );
};

export default SaleLineRetentionColumnCode;
