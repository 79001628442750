// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { LogEventDto } from "../dtos/LogEventDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { LogEventFiltersDto } from "../dtos/LogEventFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { LogEventCompleteDto } from "../dtos/LogEventCompleteDto";

export class ApiAdminLoggingViewer {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<LogEventFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<LogEventDto>> {
    return this.apiService.apiPost("/admin/logging-viewer/paged", searchDto, apiOptions);
  }

  getTableName(
    impersonatedEnvironment: string,
    apiOptions?: ApiOptions
  ): Promise<string> {
    return this.apiService.apiGet("/admin/logging-viewer/table-name?" + 
      (impersonatedEnvironment ? "&impersonatedEnvironment=" + impersonatedEnvironment : ""), apiOptions);
  }

  getEventComplete(
    partitionKey: string,
    rowKey: string,
    impersonatedEnvironment: string,
    apiOptions?: ApiOptions
  ): Promise<LogEventCompleteDto> {
    return this.apiService.apiGet("/admin/logging-viewer/complete?" + 
      (partitionKey ? "&partitionKey=" + partitionKey : "") + 
      (rowKey ? "&rowKey=" + rowKey : "") + 
      (impersonatedEnvironment ? "&impersonatedEnvironment=" + impersonatedEnvironment : ""), apiOptions);
  }

}
