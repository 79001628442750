// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AppTermsAndConditionsDto } from "../dtos/AppTermsAndConditionsDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiUserTermsAndConditions {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  notifyVersion(
    apiOptions?: ApiOptions
  ): Promise<AppTermsAndConditionsDto> {
    return this.apiService.apiGet("/user-terms-accepted/notify-version", apiOptions);
  }

  accept(
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/user-terms-accepted/accept", null, apiOptions);
  }

}
