import { useEffect, useState } from "react";
import { EmailConfigDto } from "../../../api/app/dtos/EmailConfigDto";
import { EmailConfigType } from "../../../api/shared/enums/EmailConfigType";
import {
  useApiEInvoiceEnvelopePurchase,
  useApiEmailConfig,
} from "../../../api/shared/hooks/useApiApp";
import { AppRoute } from "../../../AppRoutes";
import Button from "../../../shared/components/Button/Button";
import { TypeIcon } from "../../../shared/components/Icon";
import InfoText, { InfoTextType } from "../../../shared/components/InfoText";
import InputDate from "../../../shared/components/inputs/InputDate";
import InputSwitch from "../../../shared/components/inputs/InputSwitch";
import Container from "../../../shared/components/Layout/GContainer";
import Row from "../../../shared/components/Layout/GRow";
import RowButtons from "../../../shared/components/Layout/GRowButtons";
import RowDisplay from "../../../shared/components/Layout/GRowDisplay";
import LongProcessBar from "../../../shared/components/LongProcess/LongProcessBar";
import { ModalSize } from "../../../shared/context/ModalProvider";
import { useModal } from "../../../shared/hooks/useModal";
import { useNavigation } from "../../../shared/hooks/useNavigation";
import { useNotifier } from "../../../shared/hooks/useNotifier";
import useUtils from "../../../shared/hooks/useUtils";
import { DateFormatType } from "../../../shared/utils/localizedUtil/localizedUtilDates";

const EInvoicePurchasePendingRefreshPopup = () => {
  const [modal] = useModal();
  const [emailConfig, setEmailConfig] = useState<EmailConfigDto>();
  const [specifyRange, setSpecifyRange] = useState<boolean | null>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [apiEInvoiceEnvelopePurchase] = useApiEInvoiceEnvelopePurchase();
  const [apiEmailConfig] = useApiEmailConfig();
  const [longProcessId, setLongProcessId] = useState("");
  const [showNoEmailConfiguredError, setShowNoEmailConfiguredError] = useState(false);
  const { utils } = useUtils();
  const navigation = useNavigation();
  const notifier = useNotifier();

  useEffect(() => {
    const request = async () => {
      const emailConfig = await apiEmailConfig.getByType(EmailConfigType.EInvoice);

      if (!emailConfig) {
        setShowNoEmailConfiguredError(true);
        return;
      }

      const date = utils.date.typeCheck(emailConfig.monitorLastRead);
      setEmailConfig(emailConfig);
      if (date) {
        setStartDate(date);
      }
    };
    request();
    modal.setVisible("Obtener CFE recibidos", ModalSize.small);
  }, [modal]);

  const refreshByDate = async () => {
    const result = await apiEInvoiceEnvelopePurchase.refreshFromEmailDateRange(
      startDate ?? utils.date.today(),
      endDate ?? new Date()
    );
    setLongProcessId(result);
  };

  const refreshFromLastUIRead = async () => {
    const result = await apiEInvoiceEnvelopePurchase.refreshFromEmailLastUIRead();
    setLongProcessId(result);
  };

  if (showNoEmailConfiguredError) {
    return (
      <Container>
        <Row>
          <InfoText type={InfoTextType.error}>
            No hay correo para facturación electrónica configurado
          </InfoText>
        </Row>
        <RowButtons>
          <Button
            text="Configurar correo"
            icon={TypeIcon.create}
            onClick={() => {
              modal.closeAsCancel();
              navigation.go(AppRoute.configuration.email.base);
            }}
          />
        </RowButtons>
      </Container>
    );
  }

  if (!emailConfig) {
    return <></>;
  }

  return (
    <Container>
      <RowDisplay label="Última lectura">
        {utils.date.toString(emailConfig.monitorLastRead, DateFormatType.DateHourMinutes)}
      </RowDisplay>
      <RowDisplay label="Último UID leído">{emailConfig.monitorLastUIRead}</RowDisplay>
      <Row>
        <InputSwitch
          label="Definir rango de fechas"
          value={specifyRange}
          onChange={setSpecifyRange}
        />
      </Row>
      {specifyRange && (
        <>
          <Row>
            <InputDate
              label="Desde"
              readOnly={!!longProcessId}
              value={startDate}
              includeTime={false}
              onChange={setStartDate}
            />
          </Row>
          <Row>
            <InputDate
              label="Hasta"
              readOnly={!!longProcessId}
              value={endDate}
              includeTime={false}
              onChange={setEndDate}
            />
          </Row>
          <RowButtons>
            <Button
              text="Actualizar"
              disabled={!!longProcessId}
              icon={TypeIcon.refresh}
              onClick={refreshByDate}
            />
          </RowButtons>
        </>
      )}
      {!specifyRange && (
        <RowButtons>
          <Button
            text="Actualizar"
            disabled={!!longProcessId}
            icon={TypeIcon.refresh}
            onClick={refreshFromLastUIRead}
          />
        </RowButtons>
      )}
      {longProcessId && (
        <LongProcessBar
          processId={longProcessId}
          onSuccess={() => modal.closeAsSuccess(null, "Correos procesados")}
          onError={(errorMessages: string) => {
            notifier.showErrorMessage(errorMessages);
            modal.closeAsCancel(null);
          }}
          onCancel={() => modal.closeAsCancel(null)}
        />
      )}
    </Container>
  );
};

export default EInvoicePurchasePendingRefreshPopup;
