import ButtonIcon from "../../shared/components/Button/ButtonIcon";
import { TypeIcon } from "../../shared/components/Icon";
import ComponentGroup from "../../shared/components/Layout/ComponentsGroup";
import { useContextModelStateSale } from "./SaleForm.ModelState";
import { ISaleReferenceColumnRenderProps } from "./SaleFormReferences";

const SaleFormReferenceColumnButtons = (props: ISaleReferenceColumnRenderProps) => {
  const saleModelState = useContextModelStateSale();
  return (
    <ComponentGroup align={props.saleReferenceColumn.align}>
      <ButtonIcon
        icon={TypeIcon.delete}
        onClick={() => {
          saleModelState.referenceRemove(props.arrayItemHelper.index);
        }}
      />
    </ComponentGroup>
  );
};

export default SaleFormReferenceColumnButtons;
