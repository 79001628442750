import { nameof } from "ts-simple-nameof";
import { AdminLoggingListQueryParams } from "../../admin/AdminLogging/AdminLogViewer";
import { EInvoiceProcessLogDto } from "../../api/app/dtos/EInvoiceProcessLogDto";
import { enumName } from "../../api/shared/enums/_enumName";
import { AppRoute } from "../../AppRoutes";
import ButtonLink from "../../shared/components/Button/ButtonLink";
import { GridColumnAlign } from "../../shared/components/grid/Grid";
import GridClientSide from "../../shared/components/grid/GridClientSide";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import { useNavigation } from "../../shared/hooks/useNavigation";
import useUtils from "../../shared/hooks/useUtils";
import { DateFormatType } from "../../shared/utils/localizedUtil/localizedUtilDates";

const SaleDetailsPopupEInvoiceProcessLogBase = (props: { logs: EInvoiceProcessLogDto[] }) => {
  const { utils } = useUtils();
  const { loginInfo } = useContextLoginInfo();
  const navigation = useNavigation();

  const itemTemplates: IGridItemTemplate<EInvoiceProcessLogDto>[] = [
    {
      header: "Fecha",
      field: nameof<EInvoiceProcessLogDto>((p) => p.date),
      render: (e: EInvoiceProcessLogDto) => {
        return utils.date.toString(e.date, DateFormatType.DateHourMinutesSeconds);
      },
      width: 15,
    },
    {
      header: "Estado",
      field: nameof<EInvoiceProcessLogDto>((p) => p.status),
      cellTypeEnumName: enumName.EInvoiceProcessStatus,
      width: 30,
    },
    {
      header: "Mensaje",
      field: nameof<EInvoiceProcessLogDto>((p) => p.message),
      width: 45,
    },
    {
      header: "Trace ID",
      field: nameof<EInvoiceProcessLogDto>((p) => p.errorTraceId),
      width: 10,
      render: (item: EInvoiceProcessLogDto) => {
        return item.errorTraceId && loginInfo.authInfo.isSuperAdmin ? (
          <ButtonLink
            text="Ver logs"
            styles={{ padding: 0 }}
            onClick={(e) => {
              e.preventDefault();
              navigation.newTab(AppRoute.admin.logViewer, {
                traceId: item.errorTraceId,
              } as AdminLoggingListQueryParams);
            }}
          />
        ) : (
          item.errorTraceId
        );
      },
    },
    {
      header: "",
      field: nameof<EInvoiceProcessLogDto>((p) => p.wsLogRequestUrl),
      align: GridColumnAlign.center,
      cellType: GridCellType.download,
      width: 5,
    },
    {
      header: "",
      field: nameof<EInvoiceProcessLogDto>((p) => p.wsLogResponseUrl),
      align: GridColumnAlign.center,
      cellType: GridCellType.download,
      width: 5,
    },
  ];

  return <GridClientSide hideFooter={true} templates={itemTemplates} items={props.logs} />;
};

export default SaleDetailsPopupEInvoiceProcessLogBase;
