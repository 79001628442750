// File generated by: Gesta.Build.PostEvents
export enum Tax {
	NoBillable = 1,
	UruguayExento = 100,
	UruguayIvaMinimo = 101,
	UruguayIvaBasico = 102,
	UruguayIvaOtraTasa = 103,
	UruguayExportacion = 104,
	UruguayPercibido = 105,
	UruguayIvaSuspenso = 106,
	ColombiaIva = 201,
}
