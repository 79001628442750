import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import "./Login.scss";
import { useLoginLayout } from "./LoginLayoutContext";

const LoginCard = (props: {
  isLoading?: boolean;
  children: any;
  loadingProgress?: number;
  isChangeAccountPopup?: boolean;
  isChangeBranchPopup?: boolean;
}) => {
  const loginLayout = useLoginLayout();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLeavingPage, setIsLeaving] = useState(false);

  useEffect(() => {
    if (loginLayout.loginLayoutInfo.isLeavingPage && isLoaded) {
      setIsLeaving(true);
      return;
    }

    setTimeout(() => {
      setIsLoaded(true);
      loginLayout.notifyPageLoaded();
    }, 0);
  }, [loginLayout.loginLayoutInfo.isLeavingPage]);

  return (
    <div
      className={
        "login-layout-card " +
        (props.isChangeAccountPopup || props.isChangeBranchPopup ? "change-account-popup" : "")
      }>
      <div
        className={
          "login-layout-card-content " +
          (isLeavingPage ? "login-transition-hide" : isLoaded ? "login-transition-show" : "")
        }>
        {props.children}
      </div>
      {(props.isLoading || props.loadingProgress) && (
        <LinearProgress
          className="linear-progress"
          variant={props.loadingProgress ? "determinate" : "indeterminate"}
          value={props.loadingProgress}
        />
      )}
    </div>
  );
};

export default LoginCard;
