import { nameof } from "ts-simple-nameof";
import { AccountBranchDto } from "../../../../api/app/dtos/AccountBranchDto";
import { AccountBranchSimpleDto } from "../../../../api/app/dtos/AccountBranchSimpleDto";
import { FiltersDto } from "../../../../api/shared/dtos/FiltersDto";
import { SuggestItemDto } from "../../../../api/shared/dtos/SuggestItemDto";
import { SuggestSearchDto } from "../../../../api/shared/dtos/SuggestSearchDto";
import { useApiSwitchPointOfSale } from "../../../../api/shared/hooks/useApiApp";
import InputAutocompleteBase, { PropsInputAutocompleteBase } from "./InputAutocompleteBase";

interface InternalPropsInputAutocompleteSwitchAccountBranch
  extends PropsInputAutocompleteBase<AccountBranchDto> {
  adminAccountId: string;
}

export type PropsInputAutocompleteSwitchAccountBranch = Omit<
  InternalPropsInputAutocompleteSwitchAccountBranch,
  "onGetEntityById"
>;

const InputAutocompleteSwitchAccountBranch = (props: PropsInputAutocompleteSwitchAccountBranch) => {
  const [apiSwitchPointOfSale] = useApiSwitchPointOfSale();

  const apiRequest = (echoId: number, term: string, isInitialLoad: boolean) => {
    const searchDto = new SuggestSearchDto<FiltersDto>();
    searchDto.echoId = echoId;
    searchDto.filters = new FiltersDto();
    searchDto.filters.defaultTextSearch = term;
    searchDto.isInitialLoad = isInitialLoad;
    searchDto.orderBy = [{ columnName: nameof<AccountBranchDto>((p) => p.branchName), asc: true }];
    const apiOptions = {
      preventSpinner: true,
    };
    return apiSwitchPointOfSale.suggestAccountBranch(props.adminAccountId, searchDto, apiOptions);
  };

  return (
    <InputAutocompleteBase
      {...props}
      value={props.value}
      onGetEntityById={(id: string) =>
        apiSwitchPointOfSale.getAccountBranchById(props.adminAccountId, id, {
          preventSpinner: true,
        })
      }
      onMapFromItemSuggest={(item: SuggestItemDto) => {
        const switchAccountBranchDto = new AccountBranchSimpleDto();
        switchAccountBranchDto.id = item.id;
        switchAccountBranchDto.displayName = item.label;
        return switchAccountBranchDto;
      }}
      onServerSideSuggest={apiRequest}
    />
  );
};

export default InputAutocompleteSwitchAccountBranch;
