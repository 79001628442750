import ValidationFieldsTrack from "./ValidationFieldsTrack";
import ValidationFieldsTrackBase, { ParamSetItemValues } from "./ValidationFieldsTrackBase";

export default class ValidationFieldsTrackCollection extends ValidationFieldsTrackBase {
  items: ValidationFieldsTrackBase[] = [];

  private getItemIndex(propertyPath: string[]): number {
    const itemIndexStart = propertyPath[0].indexOf("[");
    if (itemIndexStart === -1) {
      throw new Error(
        "ValidationFieldsTrackCollection expect itemIndex array in order to setup the value: " +
          propertyPath.join(".")
      );
    }

    const itemIndexEnd = propertyPath[0].indexOf("]");
    return parseInt(propertyPath[0].substring(itemIndexStart + 1, itemIndexEnd));
  }

  getValue(propertyFullName: string): ValidationFieldsTrackBase | undefined {
    const itemIndex = this.getItemIndex(propertyFullName.split("."));
    return this.items[itemIndex]?.getValue(
      this.getRemainingPropertyPath(propertyFullName.split("."))
    );
  }

  removeArrayItem(_propertyName: string, startArrayIndex: number, deleteCount?: number) {
    this.items.splice(startArrayIndex, deleteCount ?? 1);
  }

  setChildrenValue(params: ParamSetItemValues): void {
    this.items.forEach((i) => i.setChildrenValue(params));
  }

  public setValue(propertyFullName: string, params: ParamSetItemValues): void {
    const nestedPropertyPath = propertyFullName.split(".");
    const itemIndex = this.getItemIndex(nestedPropertyPath);

    if (this.items.length <= itemIndex) {
      for (let ind = this.items.length; ind < itemIndex + 1; ind++) {
        this.items.push(new ValidationFieldsTrack());
      }
    }

    this.items[itemIndex].setValue(this.getRemainingPropertyPath(nestedPropertyPath), params);
  }

  public incrementTrySubmitAll(): void {
    this.items.forEach((i) => i.incrementTrySubmitAll());
  }
}
