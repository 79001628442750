import { AdminTaxValueDto } from "../../api/admin/dtos/AdminTaxValueDto";
import { ContextMenuItem } from "../../shared/components/ContextMenu/ContextMenu";
import { TypeIcon } from "../../shared/components/Icon";
import { ModalService } from "../../shared/hooks/useModal";
import AdminTaxValueDetailsPopup from "./AdminTaxValueDetailsPopup";
import AdminTaxValueEditPopup from "./AdminTaxValueEditPopup";

export default function getAdminTaxValueListItemMenu(
  adminTaxValueDto: AdminTaxValueDto,
  modal: ModalService,
  onReloadGrid: () => void
): ContextMenuItem[] {
  return [
    {
      text: "Detalles",
      icon: TypeIcon.details,
      onClick: () => {
        modal.open(
          <AdminTaxValueDetailsPopup adminTaxValueId={adminTaxValueDto.id} />,
          onReloadGrid
        );
      },
    },
    {
      text: "Editar",
      icon: TypeIcon.edit,
      onClick: () => {
        modal.open(<AdminTaxValueEditPopup adminTaxValueId={adminTaxValueDto.id} />, onReloadGrid);
      },
    },
  ];
}
