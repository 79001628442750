// File generated by Gesta.Build.PostEvents
import { AdminTemplateDesignTestDto } from "./AdminTemplateDesignTestDto";
import { AdminTemplateEmailDto } from "./AdminTemplateEmailDto";
import { AdminTemplateDto } from "./AdminTemplateDto";

export class AdminTemplateDesignEmailTestDto extends AdminTemplateDesignTestDto {
  testToAdminUserId: string;
  testEmailTo: string = "";
  testEmailToName: string = "";
  templateEmail: AdminTemplateEmailDto;
  template: AdminTemplateDto;
}
