import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router";
import LayoutHeader from "../../shared/components/Layout/LayoutHeader";
import LayoutMainContainer from "../../shared/components/Layout/LayoutMainContainer";
import LayoutSideMenu from "../../shared/components/Layout/LayoutSideMenu";
import UnauthorizedArea from "../../shared/components/LoginFlow/UnauthorizedArea";
import NotificationArea from "../../shared/components/Notifications/NotificationArea";
import { AppInfoSiteType } from "../../shared/context/AppInfoProvider";
import { LoginInfoStatus } from "../../shared/context/LoginInfoProvider";
import { useContextAppInfo } from "../../shared/hooks/useContextAppInfo";
import { useContextLoginInfo } from "../../shared/hooks/useContextLoginInfo";
import { useIsMobile } from "../../shared/hooks/useIsMobile";
import { useNavigation } from "../../shared/hooks/useNavigation";
import AdminMenu from "./AdminMenu";

const AdminLayout = () => {
  const navigation = useNavigation();
  const { loginInfo } = useContextLoginInfo();
  const isMobile = useIsMobile();
  const { appInfo, setSiteType } = useContextAppInfo();
  const [menuIsExpanded, setMenuIsExpanded] = useState(!isMobile);

  if (loginInfo.status === LoginInfoStatus.LoggedOut) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  if (loginInfo.isLoggedIn && !loginInfo.authInfo.isSuperAdmin) {
    return <UnauthorizedArea />;
  }

  useEffect(() => {
    setSiteType(AppInfoSiteType.SuperAdmin);
  }, []);

  if (!appInfo || appInfo.siteType != AppInfoSiteType.SuperAdmin) {
    return <></>;
  }

  return (
    <div className="layout">
      <NotificationArea />
      <LayoutHeader
        onToggleMenu={() => setMenuIsExpanded(!menuIsExpanded)}
        sideMenuIsExpanded={menuIsExpanded}
      />
      <div className="layout-body">
        <LayoutSideMenu
          onCollapseRequested={() => setMenuIsExpanded(false)}
          onExpandRequested={() => setMenuIsExpanded(true)}
          expanded={menuIsExpanded}>
          <AdminMenu
            isExpanded={menuIsExpanded}
            onNavigate={(url) => {
              setMenuIsExpanded(false);
              navigation.go(url);
            }}
          />
        </LayoutSideMenu>
        <LayoutMainContainer>
          <Outlet />
        </LayoutMainContainer>
      </div>
    </div>
  );
};

export default AdminLayout;
