import { ThemeProvider } from "@mui/material/styles";
import { createContext, PropsWithChildren, useContext, useEffect, useReducer } from "react";
import { createThemeForAdminProvider, toHexColorFromPalette } from "./AppTheme";
import { AppThemePalette, getThemePaletteFromAdminProvider } from "./AppThemePalette";
import { appThemePaletteDefault } from "./AppThemePalette.Default";
import SpinnerBlock from "./shared/components/SpinnerBlock";
import { useContextAppInfo } from "./shared/hooks/useContextAppInfo";
import { AppThemeColor } from "./styles/color";

export const AppThemeContext = createContext<{
  appThemePalette: AppThemePalette;
  dispatchAdminProviderPalette: React.Dispatch<AppThemeAction>;
}>({
  appThemePalette: appThemePaletteDefault,
  dispatchAdminProviderPalette: () => null,
});

enum AppThemeActionType {
  setPalette,
}

export type AppThemeAction = {
  type: AppThemeActionType.setPalette;
  appThemePalette: AppThemePalette;
};

export const appThemeReducer = (
  _state: AppThemePalette,
  action: AppThemeAction
): AppThemePalette => {
  switch (action.type) {
    case AppThemeActionType.setPalette:
      return { ...action.appThemePalette } as AppThemePalette;
  }
};

function changeFavicon(src: string) {
  let link = document.querySelector("link[rel~='icon']") as any;
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = src;
}

const AppThemeProvider: React.FC<
  PropsWithChildren<{
    children: any;
  }>
> = ({ children }: any) => {
  const [appThemePalette, dispatchAdminProviderPalette] = useReducer(
    appThemeReducer,
    appThemePaletteDefault
  );
  const { appInfo } = useContextAppInfo();

  useEffect(() => {
    if (!appInfo || !appInfo.adminProviderInfo) {
      return;
    }

    if (appInfo.adminProviderInfo.name == "eXe") {
      changeFavicon("/favicon-exe.ico");
    } else {
      changeFavicon("/favicon-gesta.ico");
    }

    const palette = getThemePaletteFromAdminProvider(appInfo.adminProviderInfo);
    dispatchAdminProviderPalette({
      type: AppThemeActionType.setPalette,
      appThemePalette: palette,
    });
  }, [appInfo?.adminProviderInfo]);

  return (
    <AppThemeContext.Provider value={{ appThemePalette, dispatchAdminProviderPalette }}>
      {!appThemePalette && <SpinnerBlock />}
      {appThemePalette && (
        <ThemeProvider theme={createThemeForAdminProvider(appThemePalette)}>
          {children}
        </ThemeProvider>
      )}
    </AppThemeContext.Provider>
  );
};

export default AppThemeProvider;

export const useAppTheme = () => {
  const { appThemePalette } = useContext(AppThemeContext);
  const toHexColor = (color?: AppThemeColor) => {
    if (color == undefined || !appThemePalette) {
      return undefined;
    }
    return toHexColorFromPalette(color, appThemePalette);
  };

  return {
    toHexColor,
    appThemePalette,
  };
};
