// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { UserLinkedAccountDto } from "../dtos/UserLinkedAccountDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { UserLinkedAccountFiltersDto } from "../dtos/UserLinkedAccountFiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminProviderUserLinkedAccount {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    searchFilters: PagedSearchDto<UserLinkedAccountFiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<UserLinkedAccountDto>> {
    return this.apiService.apiPost("/admin-provider/user-linked-accounts/paged", searchFilters, apiOptions);
  }

  getAllForUser(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<UserLinkedAccountDto[]> {
    return this.apiService.apiGet("/admin-provider/user-linked-accounts/all-for-user/" + id, apiOptions);
  }

  create(
    userLinkedAccountDto: UserLinkedAccountDto,
    apiOptions?: ApiOptions
  ): Promise<UserLinkedAccountDto> {
    return this.apiService.apiPost("/admin-provider/user-linked-accounts", userLinkedAccountDto, apiOptions);
  }

  removeAccountAdmin(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/user-linked-accounts/remove-account-admin/" + id, null, apiOptions);
  }

  setAsAccountAdmin(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/user-linked-accounts/set-as-account-admin?" + 
      (id ? "&id=" + id : ""), null, apiOptions);
  }

  inactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/user-linked-accounts/inactivate/" + id, null, apiOptions);
  }

  reactivate(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPut("/admin-provider/user-linked-accounts/reactivate/" + id, null, apiOptions);
  }

}
