import { ReactElement, useState } from "react";
import { TextAlign } from "../../TextAlign";
import ButtonLink from "../Button/ButtonLink";
import CodeEditor, { CodeEditorLang } from "../CodeEditor";
import { TypeIcon } from "../Icon";
import InfoText, { InfoTextType } from "../InfoText";
import Column from "../Layout/GColumn";
import Container from "../Layout/GContainer";
import Row from "../Layout/GRow";

const storedImages: any = {};

const hideImages = (object: any, path: string) => {
  if (!object) {
    return;
  }
  const keys = Object.keys(object);
  keys.map((key: string) => {
    if (typeof object[key] === "string" && (object[key] as string).startsWith("data:image")) {
      storedImages[path + key] = object[key];
      object[key] = "-- IMAGE --";
    }
    if (typeof object[key] === "object") {
      hideImages(object[key], key + ".");
    }
    return key;
  });
};

const retrieveImages = (object: any, path: string) => {
  if (!object) {
    return;
  }
  const keys = Object.keys(object);
  keys.map((key) => {
    if (typeof object[key] === "string" && (object[key] as string).startsWith("-- IMAGE --")) {
      object[key] = storedImages[path + key];
    }
    if (typeof object[key] === "object") {
      retrieveImages(object[key], key + ".");
    }
    return key;
  });
};

const TemplateEditorPreviewData = (props: {
  value: any;
  expandPreviewPanelButton?: ReactElement;
  scrollPosX?: number;
  scrollPosY?: number;
  onChange: (value: any) => void;
  onScroll?: (positionX: number, positionY: number) => void;
}) => {
  const [isValidJson, setIsValidJson] = useState(true);
  const [stringData, setStringData] = useState<string>(() => {
    hideImages(props.value, "");
    return JSON.stringify(props.value, null, 2);
  });

  const toLatestValidJson = () => {
    hideImages(props.value, "");
    setStringData(JSON.stringify(props.value, null, 2));
    setIsValidJson(true);
  };

  return (
    <>
      <Row>
        <InfoText>
          Aquí podés ingresar la información, en formato <strong>JSON</strong>, para utilizar al
          momento de generar la vista previa. Por defecto se cargan con el nombre del campo.
        </InfoText>
      </Row>
      <Row>
        <CodeEditor
          value={stringData}
          lang={CodeEditorLang.json}
          scrollPosX={props.scrollPosX}
          scrollPosY={props.scrollPosY}
          onChange={(value) => {
            setStringData(value);
            try {
              const tryParse = JSON.parse(value);
              retrieveImages(tryParse, "");
              props.onChange(tryParse);
              setIsValidJson(true);
            } catch {
              setIsValidJson(false);
            }
          }}
          onScroll={props.onScroll}
        />
      </Row>
      <Column md={props.expandPreviewPanelButton ? 10 : 12}>
        {!isValidJson && (
          <Container>
            <Column md={10}>
              <InfoText type={InfoTextType.error}>El formato JSON no es válido</InfoText>
            </Column>
            <Column md={2} align={TextAlign.right}>
              <ButtonLink icon={TypeIcon.undo} text="Último válido" onClick={toLatestValidJson} />
            </Column>
          </Container>
        )}
      </Column>
      {props.expandPreviewPanelButton && (
        <Column md={2} align={TextAlign.right}>
          {props.expandPreviewPanelButton}
        </Column>
      )}
    </>
  );
};

export default TemplateEditorPreviewData;
