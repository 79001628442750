import { nameof } from "ts-simple-nameof";
import { AccountUserCrudDto } from "../../../api/admin/dtos/AccountUserCrudDto";
import { validate } from "../../../shared/validation/schema/ValidationField";
import ValidationSchema from "../../../shared/validation/schema/ValidationSchema";

export function useAccountUserCrudValidation() {
  const schema = new ValidationSchema();

  schema[nameof<AccountUserCrudDto>((p) => p.userEmail)] = validate("Correo").required();
  schema[nameof<AccountUserCrudDto>((p) => p.userFirstName)] = validate("Nombre").required();
  schema[nameof<AccountUserCrudDto>((p) => p.userLastName)] = validate("Apellido").required();

  return schema;
}
