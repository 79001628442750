import Row from "../../shared/components/Layout/GRow";
import Page from "../../shared/components/Layout/Page";
import "./Home.scss";

const Home = () => {
  return (
    <Page title="">
      <Row className="home-dashboard">Bienvenido</Row>
    </Page>
  );
};

export default Home;
