import { useEffect, useState } from "react";
import { UserLinkedAccountDto } from "../../api/admin/dtos/UserLinkedAccountDto";
import Container from "../../shared/components/Layout/GContainer";
import { ModalSize } from "../../shared/context/ModalProvider";
import {
  useApiAdminProviderUser,
  useApiAdminProviderUserLinkedAccount,
} from "../../api/shared/hooks/useApiAdminProviders";
import { useModal } from "../../shared/hooks/useModal";
import { StateProvider } from "../../shared/modelState/ModelStateContext";
import UserLinkedAccountForm from "./UserLinkedAccountForm";
import { useAdminUserLinkedAccountValidation } from "./Validations/useAdminUserLinkedAccountValidation";
import { useApiAdminProviderAdminAccount } from "../../api/shared/hooks/useApiAdminProviders";

const UserLinkedAccountCreatePopup = (props: { userId?: string; accountId?: string }) => {
  const [modal] = useModal();
  const [apiAdminProviderAdminAccount] = useApiAdminProviderAdminAccount();
  const [apiAdminUser] = useApiAdminProviderUser();
  const [apiAdminUserLinkedAccount] = useApiAdminProviderUserLinkedAccount();
  const [isLoaded, setIsLoaded] = useState(false);
  const [userLinkedAccount, setUserLinkedAccount] = useState(new UserLinkedAccountDto());
  const save = async (userLinkedAccount: UserLinkedAccountDto) => {
    const created = await apiAdminUserLinkedAccount.create(userLinkedAccount);
    modal.closeAsSuccess(created, "La asociación del usuario a la cuenta se creó con éxito");
  };

  const setModalVisible = () => {
    setIsLoaded(true);
    modal.setVisible("Crear asociación de usuario a cuenta", ModalSize.small);
  };

  useEffect(() => {
    if (props.userId) {
      const loadUser = async () => {
        const user = await apiAdminUser.getById(props.userId ?? "");
        setUserLinkedAccount({
          ...userLinkedAccount,
          userId: user.id,
          user: user,
        });
        setModalVisible();
      };
      loadUser();
      return;
    }

    if (props.accountId) {
      const loadAccount = async () => {
        const account = await apiAdminProviderAdminAccount.getById(props.accountId ?? "");
        setUserLinkedAccount({
          ...userLinkedAccount,
          accountId: account.id,
          account: account,
        });
        setModalVisible();
      };
      loadAccount();
      return;
    }

    setModalVisible();
  }, []);

  if (!isLoaded) {
    return <></>;
  }

  return (
    <Container>
      <StateProvider
        model={userLinkedAccount}
        onSubmit={save}
        validationSchema={useAdminUserLinkedAccountValidation()}>
        {(p) => <UserLinkedAccountForm onSave={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default UserLinkedAccountCreatePopup;
