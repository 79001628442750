// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { AdminEmailContactPublicSalesDto } from "../dtos/AdminEmailContactPublicSalesDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminPublicEmailContact {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  sendFromRegisterToSales(
    contactPublicSalesDto: AdminEmailContactPublicSalesDto,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiPost("/admin/public/email-contact/sales-from-register", contactPublicSalesDto, apiOptions);
  }

}
