import { useEffect, useState } from "react";
import { EInvoiceEnvelopePurchaseDto } from "../../../../api/app/dtos/EInvoiceEnvelopePurchaseDto";
import { enumName } from "../../../../api/shared/enums/_enumName";
import { useApiEInvoiceEnvelopePurchase } from "../../../../api/shared/hooks/useApiApp";
import ButtonFileDownload from "../../../../shared/components/Button/ButtonFileDownload";
import Container from "../../../../shared/components/Layout/GContainer";
import RowButtons from "../../../../shared/components/Layout/GRowButtons";
import RowDisplay from "../../../../shared/components/Layout/GRowDisplay";
import { useModal } from "../../../../shared/hooks/useModal";
import useUtils from "../../../../shared/hooks/useUtils";
import EInvoiceEnvelopeStatusResponseList from "../../../EInvoice/Envelopes/StatusResponse/EInvoiceEnvelopeStatusResponseDetailsSection";

const EInvoiceEnvelopePurchaseDetailsPopup = (props: { envelopePurchaseId: string }) => {
  const [modal] = useModal();
  const [apiEInvoiceEnvelopePurchase] = useApiEInvoiceEnvelopePurchase();
  const [eInvoiceEnvelopePurchase, setEInvoiceEnvelopePurchase] =
    useState<EInvoiceEnvelopePurchaseDto>();
  const { utils } = useUtils();

  useEffect(() => {
    const load = async () => {
      setEInvoiceEnvelopePurchase(
        await apiEInvoiceEnvelopePurchase.getById(props.envelopePurchaseId)
      );
      modal.setVisible("Sobre de comprobantes de compra");
    };
    load();
  }, []);

  if (!eInvoiceEnvelopePurchase) {
    return <></>;
  }

  return (
    <Container>
      <RowDisplay label="Id">{eInvoiceEnvelopePurchase.id}</RowDisplay>
      <RowDisplay label="Número">{eInvoiceEnvelopePurchase.internalSenderId}</RowDisplay>
      <RowDisplay label="Estado">
        {utils.enum.translate(enumName.EInvoiceEnvelopeStatus, eInvoiceEnvelopePurchase.status)}
      </RowDisplay>
      <EInvoiceEnvelopeStatusResponseList
        statusResponseList={eInvoiceEnvelopePurchase.statusResponses}
      />
      <RowButtons>
        <ButtonFileDownload text="Sobre" fileUrl={eInvoiceEnvelopePurchase.xmlUrl} />
      </RowButtons>
    </Container>
  );
};

export default EInvoiceEnvelopePurchaseDetailsPopup;
