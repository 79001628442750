import { useEffect, useState } from "react";
import { useContextLoginInfo } from "../../hooks/useContextLoginInfo";
import Row from "../Layout/GRow";
import ErrorPageLayout from "./ErrorPageLayout";

const SessionExpired = () => {
  const { logout } = useContextLoginInfo();
  const [seconds, setSeconds] = useState(4);

  useEffect(() => {
    if (seconds <= 1) {
      setTimeout(() => logout(), 1000);
      return;
    }

    setTimeout(() => {
      setSeconds(seconds - 1);
    }, 1000);
  }, [seconds]);

  return (
    <ErrorPageLayout>
      <Row>Parece que tu sesión expiró!</Row>
      <Row fontSize=".7em">
        Te mandaremos al login en {seconds} segundo{seconds == 1 ? "" : "s"} :(
      </Row>
    </ErrorPageLayout>
  );
};

export default SessionExpired;
