import { useEffect, useState } from "react";
import { Country } from "../../../../api/shared/enums/Country";
import { enumName } from "../../../../api/shared/enums/_enumName";
import { useApiCountry } from "../../../../api/shared/hooks/useApiApp";
import useUtils from "../../../hooks/useUtils";
import { IdName } from "../../../IdName";
import { PropsInputSelectBase } from "./InputSelectBase";
import InputSelectEnumBase from "./InputSelectEnumBase";

export interface PropsInputSelectCountry
  extends Omit<PropsInputSelectBase<Country | null>, "options"> {
  onlyAvailable?: boolean;
}

const InputSelectCountry = (props: PropsInputSelectCountry) => {
  const { utils } = useUtils();
  const [apiCountry] = useApiCountry();
  const [countries, setCountries] = useState<IdName[]>();

  useEffect(() => {
    if (!props.onlyAvailable) {
      setCountries(utils.enum.toIdName(enumName.Country));
      return;
    }
    const load = async () => {
      const availableCountries = await apiCountry.getAllAvailable({
        preventSpinner: true,
      });
      setCountries(utils.enum.valuesToIdName(enumName.Country, availableCountries));
    };
    load();
  }, []);

  return <InputSelectEnumBase {...props} options={countries ?? []} showSpinner={!countries} />;
};

export default InputSelectCountry;
