// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ServiceBusOrchestratorItemStatusDto } from "../dtos/ServiceBusOrchestratorItemStatusDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiAdminServiceBus {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getAllStatus(
    apiOptions?: ApiOptions
  ): Promise<ServiceBusOrchestratorItemStatusDto[]> {
    return this.apiService.apiGet("/admin-service-bus/dashboard", apiOptions);
  }

  resume(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiGet("/admin-service-bus/resume/" + id, apiOptions);
  }

}
