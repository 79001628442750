import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { EInvoiceAuthorizationDto } from "../../../../api/app/dtos/EInvoiceAuthorizationDto";
import { EInvoiceAuthorizationFiltersDto } from "../../../../api/app/dtos/EInvoiceAuthorizationFiltersDto";
import { enumName } from "../../../../api/shared/enums/_enumName";
import ButtonCreate from "../../../../shared/components/Button/ButtonCreate";
import Grid from "../../../../shared/components/grid/Grid";
import {
  GridCellType,
  IGridItemTemplate,
} from "../../../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../../../shared/components/Layout/Page";
import { useApiEInvoiceAuthorization } from "../../../../api/shared/hooks/useApiApp";
import { useModal } from "../../../../shared/hooks/useModal";
import { useNotifier } from "../../../../shared/hooks/useNotifier";
import EInvoiceAuthorizationImportPopup from "./EInvoiceAuthorizationImportPopup";
import getEInvoiceAuthorizationListItemMenu from "./EInvoiceAuthorizationList.ItemMenu";

const EInvoiceAuthorizationList = () => {
  const [modal] = useModal();
  const [apiEInvoiceAuthorization] = useApiEInvoiceAuthorization();
  const [reloadGrid, setReloadGrid] = useState(new Date());
  const notifier = useNotifier();
  const [defaultFilters] = useState(() => {
    return new EInvoiceAuthorizationFiltersDto();
  });

  const itemTemplates: IGridItemTemplate<EInvoiceAuthorizationDto>[] = [
    {
      header: "Id",
      field: nameof<EInvoiceAuthorizationDto>((p) => p.authorizationId),
      width: 15,
    },
    {
      header: "Tipo CFE",
      field: nameof<EInvoiceAuthorizationDto>((p) => p.invoiceType),
      width: 15,
      cellTypeEnumName: enumName.InvoiceType,
    },
    {
      header: "Desde",
      field: nameof<EInvoiceAuthorizationDto>((p) => p.authorizationDate),
      width: 15,
      cellType: GridCellType.date,
    },
    {
      header: "Hasta",
      field: nameof<EInvoiceAuthorizationDto>((p) => p.expirationDate),
      width: 15,
      cellType: GridCellType.date,
    },
    {
      header: "Serie",
      width: 15,
      field: nameof<EInvoiceAuthorizationDto>((p) => p.serie),
    },
    {
      header: "Desde",
      width: 15,
      field: nameof<EInvoiceAuthorizationDto>((p) => p.numberFrom),
    },
    {
      header: "Hasta",
      width: 15,
      field: nameof<EInvoiceAuthorizationDto>((p) => p.numberTo),
    },
    {
      header: "Último usado",
      width: 15,
      field: nameof<EInvoiceAuthorizationDto>((p) => p.lastUsedNumber),
    },
    {
      header: "En uso",
      width: 15,
      field: nameof<EInvoiceAuthorizationDto>((p) => p.isCurrent),
      cellType: GridCellType.iconCheck,
    },
  ];

  return (
    <Page
      title="Constancias de autorización"
      titleButton={
        <ButtonCreate
          text="Importar CAE"
          onClick={() =>
            modal.open(<EInvoiceAuthorizationImportPopup />, () => setReloadGrid(new Date()))
          }
        />
      }>
      <Grid
        itemTemplates={itemTemplates}
        initialFilters={defaultFilters}
        reloadGrid={reloadGrid}
        mustIncludeFields={[nameof<EInvoiceAuthorizationDto>((p) => p.xmlUrl)]}
        onSearch={(search, options) => apiEInvoiceAuthorization.getPaged(search, options)}
        onDisplayItemMenu={(eInvoiceAuthorizationDto: EInvoiceAuthorizationDto) =>
          getEInvoiceAuthorizationListItemMenu(
            eInvoiceAuthorizationDto,
            apiEInvoiceAuthorization,
            modal,
            notifier,
            () => {
              setReloadGrid(new Date());
            }
          )
        }
      />
    </Page>
  );
};

export default EInvoiceAuthorizationList;
