import { IconButton } from "@mui/material";
import { CSSProperties } from "react";
import { IconType } from "react-icons/lib";
import { useAppTheme } from "../../../AppThemeProvider";
import { AppThemeColor } from "../../../styles/color";
import { AppSize } from "../../AppSize";
import { toThemeSize } from "../../utils/utilSize";
import Icon from "../Icon";
import Spinner from "../Spinner";
import Tooltip from "../Tooltip";

export interface PropsButtonIcon {
  icon: IconType;
  color?: AppThemeColor;
  className?: string;
  disabled?: boolean;
  noPadding?: boolean;
  noPaddingY?: boolean;
  noPaddingX?: boolean;
  loading?: boolean;
  size?: AppSize;
  style?: CSSProperties;
  showBackground?: boolean;
  tooltip?: string;
  onClick(event?: any): void;
}

const ButtonIcon = (props: PropsButtonIcon) => {
  const appTheme = useAppTheme();

  const style: CSSProperties = {
    ...props.style,
    backgroundColor: appTheme.toHexColor(props.showBackground ? AppThemeColor.primary : undefined),
    borderRadius: props.showBackground ? 15 : undefined,
    paddingTop: props.noPaddingY || props.noPadding ? 0 : undefined,
    paddingBottom: props.noPaddingY || props.noPadding ? 0 : undefined,
    paddingLeft: props.noPaddingX || props.noPadding ? 0 : undefined,
    paddingRight: props.noPaddingX || props.noPadding ? 0 : undefined,
  };

  return (
    <Tooltip text={props.tooltip}>
      <span>
        <IconButton
          className={props.className}
          disabled={props.disabled || props.loading}
          size={toThemeSize(props.size)}
          style={style}
          onClick={props.onClick}>
          {props.loading && (
            <Spinner color={props.showBackground ? AppThemeColor.white : undefined} />
          )}
          {!props.loading && (
            <Icon
              type={props.icon}
              color={
                !props.disabled
                  ? props.color ?? (props.showBackground ? AppThemeColor.white : undefined)
                  : undefined
              }
              size={props.size}
            />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ButtonIcon;
