import { useState } from "react";
import { nameof } from "ts-simple-nameof";
import { EInvoiceDailyReportDto } from "../../../../api/app/dtos/EInvoiceDailyReportDto";
import { EInvoiceDailyReportsFiltersDto } from "../../../../api/app/dtos/EInvoiceDailyReportsFiltersDto";
import { GridName } from "../../../../api/shared/enums/GridName";
import { enumName } from "../../../../api/shared/enums/_enumName";
import { useApiEInvoiceDailyReport } from "../../../../api/shared/hooks/useApiApp";
import Grid, { GridColumnAlign } from "../../../../shared/components/grid/Grid";
import {
  GridCellType,
  GridFilterType,
  IGridItemTemplate,
} from "../../../../shared/components/grid/ItemTemplate/IGridItemTemplate";
import Page from "../../../../shared/components/Layout/Page";
import { useModal } from "../../../../shared/hooks/useModal";
import { useNavigation } from "../../../../shared/hooks/useNavigation";
import { enumColorEInvoiceDailyReportStatus } from "../../../../shared/utils/utilEnumColors";
import getEInvoiceDailyReportListItemMenu from "./EInvoiceDailyReportList.ItemMenu";

const EInvoiceDailyReportList = () => {
  const [modal] = useModal();
  const [apiEInvoiceDailyReport] = useApiEInvoiceDailyReport();
  const navigation = useNavigation();
  const [defaultFilters] = useState(() => {
    return new EInvoiceDailyReportsFiltersDto();
  });

  const itemTemplates: IGridItemTemplate<EInvoiceDailyReportDto>[] = [
    {
      header: "Fecha reporte",
      field: nameof<EInvoiceDailyReportDto>((p) => p.date),
      filter: {
        propertyName: nameof<EInvoiceDailyReportsFiltersDto>((p) => p.reportDate),
        type: GridFilterType.dateTime,
      },
      width: 10,
      cellType: GridCellType.date,
    },
    {
      header: "Fecha envío",
      field: nameof<EInvoiceDailyReportDto>((p) => p.sentDate),
      filter: {
        propertyName: nameof<EInvoiceDailyReportsFiltersDto>((p) => p.sentDate),
        type: GridFilterType.dateTime,
      },
      width: 10,
      cellType: GridCellType.dateTimeWithSeconds,
    },
    {
      header: "Id interno",
      field: nameof<EInvoiceDailyReportDto>((p) => p.internalSenderId),
      filter: {
        propertyName: nameof<EInvoiceDailyReportsFiltersDto>((p) => p.internalSenderId),
        type: GridFilterType.numberRangeInteger,
      },
      width: 10,
      cellType: GridCellType.integer,
    },
    {
      header: "Secuencia",
      field: nameof<EInvoiceDailyReportDto>((p) => p.secuencialNumber),
      filter: {
        propertyName: nameof<EInvoiceDailyReportsFiltersDto>((p) => p.secuencialNumber),
        type: GridFilterType.numberRangeInteger,
      },
      width: 10,
      cellType: GridCellType.integer,
    },
    {
      header: "Error",
      field: nameof<EInvoiceDailyReportDto>((p) => p.errorMessage),
      filter: {
        propertyName: nameof<EInvoiceDailyReportsFiltersDto>((p) => p.errorMessage),
        type: GridFilterType.text,
      },
      width: 20,
      hideByDefault: true,
    },
    {
      header: "Estado",
      field: nameof<EInvoiceDailyReportDto>((p) => p.status),
      filter: {
        propertyName: nameof<EInvoiceDailyReportsFiltersDto>((p) => p.statusList),
        type: GridFilterType.enumList,
        enumName: enumName.EInvoiceDailyReportStatus,
      },
      width: 10,
      cellType: GridCellType.statusWithColor,
      cellTypeEnumName: enumName.EInvoiceDailyReportStatus,
      cellTypeMapColor: enumColorEInvoiceDailyReportStatus,
      align: GridColumnAlign.right,
    },
  ];

  return (
    <Page title="Reportes diarios a DGI">
      <Grid
        itemTemplates={itemTemplates}
        initialFilters={defaultFilters}
        hideIncludeInactiveButton={true}
        gridName={GridName.EInvoiceDailyReport}
        mustIncludeFields={[nameof<EInvoiceDailyReportDto>((p) => p.xmlUrl)]}
        onSearch={(search, options) => apiEInvoiceDailyReport.getPaged(search, options)}
        onDisplayItemMenu={(eInvoiceDailyReportDto: EInvoiceDailyReportDto) =>
          getEInvoiceDailyReportListItemMenu(eInvoiceDailyReportDto, modal, navigation)
        }
      />
    </Page>
  );
};

export default EInvoiceDailyReportList;
