// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { ApiOptions } from "../../shared/apis/ApiOptions";

export class ApiServiceBus {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  stagingStartAll(
    apiOptions?: ApiOptions
  ): Promise<void> {
    return this.apiService.apiGet("/service-bus/staging-start-all", apiOptions);
  }

}
