// File generated by Gesta.Build.PostEvents
import { BaseEntityDto } from "../../shared/dtos/BaseEntityDto";
import { AdminProviderEmailConfigDto } from "./AdminProviderEmailConfigDto";
import { EmailProviderType } from "../../shared/enums/EmailProviderType";

export class AdminProviderSecureSettingsDto extends BaseEntityDto {
  emailConfigId: string;
  emailConfig: AdminProviderEmailConfigDto;
  emailDefaultFromUser: string = "";
  emailDefaultFromEmail: string = "";
  emailProviderType: EmailProviderType;
  emailSendGridApiKey: string = "";
  gMailOAuthClientId: string = "";
  gMailOAuthClientSecret: string = "";
}
