import { nameof } from "ts-simple-nameof";
import { AuthPasswordResetDto } from "../../api/app/dtos/AuthPasswordResetDto";
import Button from "../../shared/components/Button/Button";
import FormPassword from "../../shared/components/forms/FormPassword";
import LoginRow from "./Layout/LoginRow";

const LoginWelcomeForm = (props: {
  isLoading: boolean;
  onIsLoading(value: boolean): void;
  onSubmit(): void;
}) => {
  return (
    <>
      <LoginRow>
        <FormPassword
          label="Contraseña"
          propertyName={nameof<AuthPasswordResetDto>((p) => p.password)}
          readOnly={props.isLoading}
          builderHelper={true}
        />
      </LoginRow>

      <LoginRow>
        <FormPassword
          label="Confirmación"
          propertyName={nameof<AuthPasswordResetDto>((p) => p.confirmPassword)}
          readOnly={props.isLoading}
        />
      </LoginRow>
      <LoginRow>
        <Button
          text="Aceptar"
          fullWidth={true}
          disabled={props.isLoading}
          onClick={props.onSubmit}
        />
      </LoginRow>
    </>
  );
};

export default LoginWelcomeForm;
