import { nameof } from "ts-simple-nameof";
import { ContactDto } from "../../api/app/dtos/ContactDto";
import FormText from "../../shared/components/forms/FormText";
import Row from "../../shared/components/Layout/GRow";

const ContactFormNotes = () => {
  return (
    <Row>
      <FormText
        label="Notas"
        propertyName={nameof<ContactDto>((p) => p.info.notes)}
        multilineRows={4}
      />
    </Row>
  );
};

export default ContactFormNotes;
