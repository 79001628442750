import { CSSProperties, ReactElement } from "react";
import { RequestedExportDto } from "../../../api/app/dtos/RequestedExportDto";
import { ApiOptions } from "../../../api/shared/apis/ApiOptions";
import { ExportRequestDto } from "../../../api/shared/dtos/ExportRequestDto";
import { FiltersDto } from "../../../api/shared/dtos/FiltersDto";
import { PagedResultDto } from "../../../api/shared/dtos/PagedResultDto";
import { PagedSearchDto } from "../../../api/shared/dtos/PagedSearchDto";
import { SearchOrderDto } from "../../../api/shared/dtos/SearchOrderDto";
import { GridName } from "../../../api/shared/enums/GridName";
import { ContextMenuItem } from "../ContextMenu/ContextMenu";
import { IGridSelection } from "./Grid";
import { IGridItemTemplate } from "./ItemTemplate/IGridItemTemplate";

export interface PropsGrid<T> {
  clientSideItems?: T[];
  defaultSortBy?: SearchOrderDto[];
  hideIncludeInactiveButton?: boolean;
  hideFooter?: boolean;
  includeInactive?: boolean;
  initialFilters?: FiltersDto;
  isCrossShard?: boolean;
  itemTemplates: IGridItemTemplate<T>[];
  mustIncludeFields?: string[];
  mode?: GridMode;
  pageSize?: number;
  preventShowDefaultSearch?: boolean;
  reloadGrid?: Date;
  gridName?: GridName;
  selectedItems?: T;
  showSelectionColumn?: boolean;
  onClientSideApplyFilters?(allItems: T[], filters: PagedSearchDto<any>): T[];
  onClientSideReload?(): void;
  onDisplayItemMenu?(item: T): ContextMenuItem[];
  onGetRowStyle?(item: any): CSSProperties;
  onExpandRow?(item: T): Promise<ReactElement>;
  onExpandRowShouldDisableButton?(item: T): boolean;
  onExport?(filters: ExportRequestDto<any>, options: ApiOptions): Promise<RequestedExportDto>;
  onSearch?(filters: PagedSearchDto<any>, options: ApiOptions): Promise<PagedResultDto<T>>;
  onSelectionChange?(gridSelection: IGridSelection<T>): void;
}

export enum GridMode {
  ServerSide = 1,
  ClientSide = 2,
}
