import { Checkbox, ListItemText, MenuItem, Select } from "@mui/material";
import { useAppTheme } from "../../../../AppThemeProvider";
import { AppThemeColor } from "../../../../styles/color";
import { IdGuidName } from "../../../IdGuidName";
import Spinner from "../../Spinner";
import Field from "../Field";
import PropsInputBase from "../PropsInputBase";

export interface PropsInputSelectBaseMultiple<T extends any[]> extends PropsInputBase<T> {
  emptyText?: string;
  options: IdGuidName[];
  hideSelectAll?: boolean;
  removeEmpty?: boolean;
}

const InputSelectBaseMultiple = (props: PropsInputSelectBaseMultiple<any>) => {
  const spinner = props.showSpinner ? <Spinner /> : undefined;
  const value = props.value instanceof Array ? props.value : [];
  const allSelected = props.options.length == value.length;
  const appTheme = useAppTheme();

  return (
    <Field
      childrenProps={props}
      labelIsInput={true}
      variant={props.variant}
      labelInputShrink={!!props.value || !!props.emptyText}>
      <Select
        multiple
        autoFocus={props.autoFocus}
        value={value}
        error={!!props.validation}
        disabled={props.readOnly}
        startAdornment={spinner}
        renderValue={(selected: any[]) =>
          selected.map((c) => props.options.find((o) => o.id == c)?.name ?? c).join(", ")
        }
        onBlur={props.onBlur}
        onChange={(value: any) => {
          if (value.target.value) {
            const values = value.target.value as any[];
            if (values.find((o) => o === "*SelectAll*")) {
              if (allSelected) {
                value.target.value = [];
                props.onChange(value);
                return;
              }
              value.target.value = props.options.map((o) => o.id);
              props.onChange(value);
              return;
            }
          }
          props.onChange(value);
        }}
        onFocus={props.onFocus}>
        {!props.hideSelectAll && (
          <MenuItem
            value="*SelectAll*"
            style={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 5, paddingRight: 15 }}>
            <Checkbox
              checked={allSelected}
              style={{
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 5,
                color: appTheme.toHexColor(AppThemeColor.primary),
              }}
            />
            <ListItemText
              primary="Seleccionar todos"
              style={{ color: appTheme.toHexColor(AppThemeColor.primary) }}
            />
          </MenuItem>
        )}
        {props.options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            style={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 5, paddingRight: 15 }}>
            <Checkbox
              checked={value.find((v: any) => v == option.id) != null}
              style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 5 }}
            />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </Field>
  );
};

export default InputSelectBaseMultiple;
