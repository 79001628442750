import { useEffect, useState } from "react";
import { EmailConfigDto } from "../../../api/app/dtos/EmailConfigDto";
import { EmailConfigType } from "../../../api/shared/enums/EmailConfigType";
import Container from "../../../shared/components/Layout/GContainer";
import { ModalSize } from "../../../shared/context/ModalProvider";
import { useApiEmailConfig } from "../../../api/shared/hooks/useApiApp";
import { useModal } from "../../../shared/hooks/useModal";
import { StateProvider } from "../../../shared/modelState/ModelStateContext";
import ConfigEmailForm from "./ConfigEmailForm";
import { EmailConfigViewModel } from "./EmailConfigViewModel";
import { useEmailConfigValidations } from "./Validations/useEmailConfigValidations";

const ConfigEmailCreatePopup = () => {
  const [emailConfig] = useState(() => {
    const emailConfig = new EmailConfigViewModel();
    emailConfig.type = EmailConfigType.EInvoice;
    return emailConfig;
  });
  const [modal] = useModal();
  const [apiEmailConfig] = useApiEmailConfig();

  const save = async (emailConfig: EmailConfigDto) => {
    const created = await apiEmailConfig.create(emailConfig);
    modal.closeAsSuccess(created, "La configuración de correo se creó con éxito");
  };

  useEffect(() => modal.setVisible("Crear configuración de correo", ModalSize.normal), [modal]);

  return (
    <Container>
      <StateProvider
        model={emailConfig}
        onSubmit={save}
        validationSchema={useEmailConfigValidations()}>
        {(p) => <ConfigEmailForm onSubmit={p.handleSubmit} />}
      </StateProvider>
    </Container>
  );
};

export default ConfigEmailCreatePopup;
