// File generated by: Gesta.Build.PostEvents
import { ApiService } from "../../shared/apis/ApiService";
import { SaleSenderBehalfDto } from "../dtos/SaleSenderBehalfDto";
import { PagedResultDto } from "../../shared/dtos/PagedResultDto";
import { FiltersDto } from "../../shared/dtos/FiltersDto";
import { PagedSearchDto } from "../../shared/dtos/PagedSearchDto";
import { ApiOptions } from "../../shared/apis/ApiOptions";
import { SuggestResponseDto } from "../../shared/dtos/SuggestResponseDto";
import { SuggestSearchDto } from "../../shared/dtos/SuggestSearchDto";

export class ApiSaleSenderBehalf {
  constructor(public apiService: ApiService) {
    // Empty constructor
  }

  getPaged(
    saleSearchDto: PagedSearchDto<FiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<PagedResultDto<SaleSenderBehalfDto>> {
    return this.apiService.apiPost("/sale-sender-behalf/paged", saleSearchDto, apiOptions);
  }

  getById(
    id: string,
    apiOptions?: ApiOptions
  ): Promise<SaleSenderBehalfDto> {
    return this.apiService.apiGet("/sale-sender-behalf/" + id, apiOptions);
  }

  create(
    saleSenderBehalfDto: SaleSenderBehalfDto,
    apiOptions?: ApiOptions
  ): Promise<SaleSenderBehalfDto> {
    return this.apiService.apiPost("/sale-sender-behalf", saleSenderBehalfDto, apiOptions);
  }

  edit(
    saleSenderBehalfDto: SaleSenderBehalfDto,
    apiOptions?: ApiOptions
  ): Promise<SaleSenderBehalfDto> {
    return this.apiService.apiPut("/sale-sender-behalf", saleSenderBehalfDto, apiOptions);
  }

  suggest(
    suggestSearch: SuggestSearchDto<FiltersDto>,
    apiOptions?: ApiOptions
  ): Promise<SuggestResponseDto> {
    return this.apiService.apiPost("/sale-sender-behalf/suggest", suggestSearch, apiOptions);
  }

}
